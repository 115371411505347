import React from "react";
import { Blink } from "bild-ui";
import { Grid, Typography, List, ListItem, Badge } from "@mui/material";

// ORGANIZATION
function Organization({ organizations, reviewType }) {

  function getOrganizations() {
    const orgs = [];

    orgs.push(
      <ListItem key="00" className="review-header">
        <Grid container>
          <Grid item md={6} xs={6}>
            <Typography variant="subtitle1">Organizations</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="subtitle1">Assessments</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );

    for (let i = 0; i < organizations.length; i++) {
      const o = organizations[i];
      orgs.push(
        <Grid container item xs={12} component={Blink} dst={"review"} review_type={reviewType} organization_id={o.organizationId} key={i}>
          <ListItem className="review-item link">
            <Grid container>
              <Grid item md={6} xs={6}>
                <Typography variant="body1">{o.organizationName}</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Badge color={o.awaitingReviewCount > 0 ? "secondary" : "primary"} className="review-badge" badgeContent={o.awaitingReviewCount} overlap="rectangular">
                  <span />
                </Badge>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
      );
    }

    if (organizations.length < 1) {
      orgs.push(
        <ListItem key="1" className="review-empty">
          <Grid container>
            <Grid item md={12} xs={12}>
              <Typography variant="body1">Nothing left to review</Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    return <List disablePadding>{orgs}</List>;
  }

  return <div className="review-list">{getOrganizations()}</div>;
}

export default Organization;
