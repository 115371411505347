import React, { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { courseData, diskData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import queryString from "query-string";
import Course from "./course.js";

function CourseLoader({}) {
  const location = useLocation();
  const { course_id, enrollment_id, competency_proof_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(null);
  const [callingUser, setCallingUser] = useState(userData.getOverview() || {});
  const [enrollmentId, setEnrollmentId] = useState(null);
  const [competencyProofId, setCompetencyProofId] = useState(null);
  const [docId, setDocId] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    setPageTitle("Course");
    const searchParams = queryString.parse(location.search);
    // URL may specify proof_id, to request automatic scroll to and open
    if (searchParams && searchParams.competency_proof_id) {
      setCompetencyProofId(parseInt(searchParams.competency_proof_id));
    }

    // NOTE: This is a special page that can be created from one of two apis, depending on the values that are passed in
    if (enrollment_id) {
      setEnrollmentId(Number(enrollment_id));
      courseData.loadFromEnrollment(course_id, enrollment_id, setCourse, e => {
        setError(e.response.data.error);
      });
      setLoading(false);
    } else {
      setCompetencyProofId(parseInt(competency_proof_id));
      courseData.loadFromProof(course_id, competency_proof_id, setCourse, e => {
        setError(e.response.data.error);
      });
      setLoading(false);
    }

    // Load user_id and then Load files/folders from disk API
    userData.loadOverview(
      o => setCallingUser(o.user),
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  function _setDocId(id) {
    setDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setFileLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setFileLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setFileLoading(false);
        }
      );
    }
  }

  if (!course || loading || error) return <LoadingScreen error={error} />;
  return (
    <Course
      callingUser={callingUser}
      course={course}
      enrollmentId={enrollmentId}
      scrollToCompetencyProofId={competencyProofId}
      docId={docId}
      setDocId={_setDocId}
      fileLoading={fileLoading}
    />
  );
}

export default CourseLoader;
