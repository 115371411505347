import React, { useState } from "react";
import { colors, BTypography, NormalButton, MembersChecklist, FormAutocompleteField, FormField, Chip } from "bild-ui";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { ENUMS } from "bild-data";
import { getEnumSectionAsArray } from "bild-utils";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  title: { paddingBottom: "1rem" },
  field: { width: "100%" },
  items: {
    overflowY: "auto",
  },
  selectedItems: {
    maxHeight: "10vh",
    overflowY: "scroll",
    padding: "0.5rem",
    border: `0.1rem solid ${colors.darkGray}`,
    borderRadius: "0.25rem"
  },
  buttonWrapper: { padding: "1rem 0", justifyContent: "flex-end" }
});

function AddUserItemDialog({ open, closeDialog, title, items, addItems, shepherds }) {
  const theme = useTheme();
  const cls = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [shepherd, setShepherd] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const levels = getEnumSectionAsArray(ENUMS.COMPLETION_LEVELS).map(x => {return {"id": x.ID, "name": x.NAME}});

  function _addItems() {
    addItems(shepherd, selectedItems);
    _reset();
  }

  function _reset() {
    setShepherd(null);
    setSelectedItems([]);
    setSelectedLevel(null);
  }

  function _cancel() {
    _reset();
    closeDialog();
  }

  function changeItem(item) {
    if (selectedItems.some(x => x === item)) {
      setSelectedItems(selectedItems.filter(x => x !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  }

  if (open) {
    return (
      <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth={true} fullScreen={fullScreen}>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" className={cls.container}>
            <Grid container item alignItems={"center"} className={cls.title}>
              <Grid item xs>
                <BTypography variant="h3">{title}</BTypography>
              </Grid>
              <Grid item>
                <NormalButton onClick={closeDialog} variant={"simple"}>
                  <i className="fal fa-2x fa-times" />
                </NormalButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormAutocompleteField
                label="Level"
                items={levels}
                value={selectedLevel}
                setValue={x => {
                  setSelectedItems([]);
                  setSelectedLevel(x);
                }}
                required={true}
                showAvatar={true}
                showIcon={"fas fa-square"}
                iconColor={colors.gray}
              />
            </Grid>
            <Grid item xs={12} className={cls.items}>
              <FormField
                label={"Program"}
                required={true}
                child={
                  <MembersChecklist
                    filterable={true}
                    members={selectedLevel ? (selectedLevel ? items.filter(y => y.completionLevel.id === selectedLevel) : items).map(x => ({ ...x, name: x.title, programColor: x.programColor })) : []}
                    selectedMembers={selectedItems}
                    updateMember={changeItem}
                    icon={"fas fa-badge-check"}
                    variant={"tall"}
                    disabled={selectedLevel ? false : true}
                  />
                }
              />
            </Grid>
            <Grid container item xs={12} className={cls.shepherd}>
              <FormAutocompleteField label="Shepherd" value={shepherd} setValue={setShepherd} items={shepherds} required={true} showAvatar={true} />
            </Grid>
            <Grid container item xs={12} className={cls.buttonWrapper} alignItems="center">
              <Grid item>
                <NormalButton
                  onClick={_addItems}
                  disabled={(selectedItems && selectedItems.length <= 0) || shepherd === null}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBlue}
                  labelColor={colors.white}
                >
                  Assign Programs
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default AddUserItemDialog;
