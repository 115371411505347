import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, Fireworks } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  screen: { height: "100%", color: colors.green },
  message: { paddingTop: "2rem" }
});

function SuccessScreen({ message, error }) {
  const cls = useStyles();
  return (
    <Grid container className={cls.screen} justifyContent="center" alignItems="center" direction="column">
      <Fireworks>
        <span className="fa-stack fa-3x">
          <i className="fal fa-circle fa-stack-2x" />
          <i className="fas fa-check fa-stack-1x" />
        </span>
        {message && (
          <BTypography variant="h2" className={cls.message}>
            {message}
          </BTypography>
        )}
      </Fireworks>
    </Grid>
  );
}

export default SuccessScreen;
