import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const useStyles = makeStyles({
  wrapper: { minWidth: "200px", minHeight: "200px" },
  tooltipLabel: {
    background: "#ffffff7e",
    padding: "1rem 2rem",
    border: `1px solid ${colors.bildBlue}`,
    maxWidth: "200px"
  }
});

function PieChartBuilder({ data, data2, fillColor }) {
  const cls = useStyles();
  const defaultFillColor = fillColor ? fillColor : colors.bildBlue;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return <div className={cls.tooltipLabel}>{`${payload[0].name} : ${payload[0].value}`}</div>;
    }

    return null;
  };

  if (!data) return <></>;
  return (
    <Grid container item xs={12} className={cls.wrapper}>
      <ResponsiveContainer width="99%" height="99%">
        <PieChart width={400} height={400}>
          <Pie data={data} dataKey="value" cx="50%" cy="50%" outerRadius={70} fill="#8884d8" label={renderCustomizedLabel}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color ? entry.color : defaultFillColor} />
            ))}
          </Pie>
          {data2 && (
            <Pie data={data2} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#8884d8">
              {data2.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color ? entry.color : defaultFillColor} />
              ))}
            </Pie>
          )}
          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default PieChartBuilder;
