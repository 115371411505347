import React from "react";
import { setPageTitle } from "bild-utils";

// TRANSLATIONS LOADER
function Translations() {
  setPageTitle("Translations");
  // Simply load an iframe pointing to bild.org's translation page. Thanks @daviddear !
  return (
    <iframe
      src="https://partner.bild.org/resources/languages?layout=clean"
      style={{ width: "100%", height: "calc(100% - 7px)", border: "none" }}
      title="bild-translations"
    />
  );
}

export default Translations;
