import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, NormalButton, Blink, BTypography, FilledCircleProgress, Glower } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Collapse } from "@mui/material";
import LoadingScreen from "presentational/screens/loadingScreen.js";
import LearningGroupItem from "./learningGroupItem.js";

const useStyles = makeStyles({
  outer: { marginBottom: "2rem", backgroundColor: colors.white },
  container: { padding: "1rem 2rem 2rem 2rem" },
  row: { paddingBottom: "1rem" },
  icon: props => ({ paddingRight: "3rem", fontSize: "2rem", color: props.color }),
  name: props => ({ color: props.color, fontWeight: "bold" }),
  subtitle: props => ({ color: props.color }),
  buttons: { paddingTop: "1.5rem" },
  startButton: props => ({
    minWidth: "6rem",
    marginRight: "1rem",
    marginTop: "0.5rem"
  }),
  startButtonIcon: { paddingRight: "0.5rem" },
  detailsButton: props => ({
    minWidth: "10rem",
    border: `1px solid ${props.color}`,
    "& > span": { color: props.color },
    marginTop: "0.5rem"
  }),
  detailOpen: props => ({
    backgroundColor: props.color,
    opacity: "0.5",
    "& > span": { color: colors.white }
  }),
  drawer: { backgroundColor: colors.white }
});

function LearningGroup({ group, items, color, defaultOpen, coreGroup, bcTab, bcGroup, defaultGroup, defaultGroupItem, setDefaultItemLoaded, smallGroup }) {
  const cls = useStyles({ color, smallGroup });

  const [drawer, setDrawer] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(defaultOpen ? defaultOpen : false);
  const [drawerItems, setDrawerItems] = useState([]);
  const [startButton, setStartButton] = useState("");
  const [startColor, setStartColor] = useState(group.totalCompletedSteps === group.totalSteps ? colors.green : color);
  const [detailButton, setDetailButton] = useState("");

  // Initial Load
  useEffect(() => {
    let defaultGroupItemWasSet = false;
    items.length > 1 || coreGroup ? setDrawer(true) : setDrawer(false);
    setDrawerItems(
      items.map((x, i) => {
        let isDefaultGroupItem = defaultGroupItem !== null && defaultGroupItem === i ? true : false;
        if (isDefaultGroupItem) defaultGroupItemWasSet = true;
        return (
          <LearningGroupItem item={x} key={x.id} color={color} defaultGroupItem={isDefaultGroupItem} bcTab={bcTab} bcGroup={bcGroup} bcItem={i} />
        );
      })
    );

    if (group.totalCompletedSteps < 1 && group.totalInProgressSteps < 1) {
      setStartButton("Start");
    } else if (group.totalCompletedSteps === group.totalSteps) {
      setStartButton("Complete");
    } else {
      setStartButton("Resume");
    }

    // If this set contained the default open item
    if (defaultGroupItemWasSet || (defaultGroup && defaultGroupItem === null)) {
      setDefaultItemLoaded(true);
    }
  }, [group, items, color]);

  // Drawer Detail
  useEffect(() => {
    if (drawerOpen) {
      setDetailButton(
        <>
          <i className="fal fa-caret-up" />
          &nbsp; Hide Steps
        </>
      );
    } else {
      setDetailButton(
        <>
          <i className="fal fa-caret-down" />
          &nbsp; See Steps
        </>
      );
    }
  }, [drawerOpen]);

  if (!group) {
    return <LoadingScreen />;
  } else {
    return (
      <Grid container className={cls.outer}>
        <Glower glowOn={defaultGroup && defaultGroupItem === null ? true : false} color={color} darken={true}>
          <Grid container item className={cls.container}>
            <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.row}>
              <Grid item>
                <i className={`fal fa-fw ${group.learningGroupIcon.webFrontendValue} ${cls.icon}`} />
              </Grid>
              <Grid item xs>
                <FilledCircleProgress
                  total={group.totalSteps}
                  completed={group.totalCompletedSteps}
                  started={group.totalCompletedSteps + group.totalInProgressSteps}
                  color={color}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={cls.row}>
              <BTypography variant={smallGroup ? "body1" : "h5"} className={cls.name}>
                {ReactHtmlParser(group.name)}
              </BTypography>
              {group.subtitle && (
                <BTypography variant={smallGroup ? "body1" : "subtitle1"} className={cls.subtitle}>
                  {ReactHtmlParser(group.subtitle)}
                </BTypography>
              )}
            </Grid>
            {!smallGroup && group.description && (
              <Grid item xs={12} className={cls.row}>
                <BTypography variant="body1">{ReactHtmlParser(group.description)}</BTypography>
              </Grid>
            )}
            <Grid container item justifyContent="space-between" xs={12} className={`${cls.row} ${cls.buttons}`}>
              <Grid item>
                <NormalButton
                  className={cls.startButton}
                  color={startColor}
                  labelColor={colors.white}
                  variant="simple"
                  component={Blink}
                  dst="equip-user-path-item"
                  user_path_item_id={group.nextStep ? group.nextStep.id : group.userPathItems[group.userPathItems.length - 1].id}
                  breadcrumb={bcTab + "-" + bcGroup}
                  id={defaultGroup && defaultGroupItem === null ? "default-open-group-item" : ""}
                >
                  {items[0].userLearningItem.restricted && <i className={`fas fa-lock-alt ${cls.startButtonIcon}`} />}
                  {startButton}
                </NormalButton>
              </Grid>
              {drawer && (
                <Grid item xs>
                  <NormalButton
                    color={drawerOpen ? color : colors.white}
                    labelColor={drawerOpen ? colors.white : color}
                    hoverLabelColor={drawerOpen ? colors.white : color}
                    variant="simple"
                    onClick={() => {
                      setDrawerOpen(!drawerOpen);
                    }}
                    className={`${cls.detailsButton} ${drawerOpen ? cls.detailOpen : ""}`}
                  >
                    {detailButton}
                  </NormalButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          {drawer && (
            <Grid item xs={12} className={cls.row}>
              <Collapse in={drawerOpen}>
                <div className={cls.drawer}>{drawerItems}</div>
              </Collapse>
            </Grid>
          )}
        </Glower>
      </Grid>
    );
  }
}

export default LearningGroup;
