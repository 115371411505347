import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, NetworkNodeDetails, HeatBadge } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  nodeWrapper: {
    padding: "0.25rem",
    background: colors.white
  },
  node: {
    width: "16rem",
    display: "inline-block",
    position: "relative",
    top: "1px"
  },
  nodeInner: {
    background: colors.white,
    overflow: "hidden",
    whiteSpace: "normal",
    color: colors.bildGray
  },
  nodeName: {
    background: colors.darkerGray,
    border: "1px solid transparent",
    color: colors.white,
    fontWeight: "bold",
    /* Key fields allow for multiple lines before ellipsis */
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  badge: {
    position: "absolute",
    top: "-1rem",
    right: "-1rem",
    zIndex: "1000"
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    border: "2px solid transparent",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  brtname: { background: `${colors.brt} !important` },
  grtorganizationname: { background: `${colors.grt} !important` },
  prtorganizationname: { background: `${colors.prt} !important` },
  networkorganizationname: { background: `${colors.network} !important` },
  localorganizationname: { background: `${colors.localorganization} !important` },
  brt: { borderColor: `${colors.brt} !important` },
  grtorganization: { borderColor: `${colors.grt} !important` },
  prtorganization: { borderColor: `${colors.prt} !important` },
  networkorganization: { borderColor: `${colors.network} !important` },
  localorganization: { borderColor: `${colors.localorganization} !important` }
});

function SideTreeNode({ data, hideChildren, toggleNode, togglePopperData }) {
  const cls = useStyles();
  let oid = data.organization.organizationId;
  let users = data.subEqpUsersWithAnyProgram;
  let prog = data.subEqpUsersWithAnyProgress;
  let nodeClass = data.type.toLowerCase().replace(/\s+/g, "");
  let badgeLevel = prog > 500 ? 4 : prog > 100 ? 3 : prog > 25 ? 2 : 1;
  let badgeIcon = users > 500 ? "fire-4" : users > 100 ? "fire-3" : users > 25 ? "fire-2" : users > 0 ? "fire-1" : "";
  let hasChildren = data.children && data.children.length > 0;

  return (
    <td className={`${cls.nodeWrapper} ${hasChildren ? cls[nodeClass] : ""}`}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid container item xs={10} justifyContent="center" alignItems="center" className={`${cls.node} ${cls[nodeClass + "node"]}`}>
          <Grid item className={cls.badge}>
            {badgeIcon && <HeatBadge level={badgeLevel} icon={badgeIcon} side={true} />}
          </Grid>
          <Grid container item className={`${cls.nodeInner}`} justifyContent="center" alignItems="center">
            <Grid
              item
              xs
              onClick={e => {
                togglePopperData(e, data, colors[nodeClass]);
              }}
              className={`${cls.nodeName} ${cls[nodeClass + "name"]}`}
            >
              {data.organization.organizationName}
            </Grid>
          </Grid>
        </Grid>
        {!hasChildren && <Grid item xs={2}></Grid>}
        {hasChildren && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            xs={2}
            onClick={() => {
              toggleNode(oid);
            }}
            className={`${cls.hasChildren} ${cls[nodeClass]}`}
          >
            <Grid item>
              <i className={`fas fa-chevron-${hideChildren ? "left" : "right"}`} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </td>
  );
}

export default SideTreeNode;
