import React, { useState } from "react";
import { colors, NormalButton, Blink, BTypography, Glower, FilledCircleProgress } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, Grid } from "@mui/material";
import LearningGroup from "./learningGroup";

const useStyles = makeStyles({
  wrapper: { background: colors.white, padding: "2rem 1rem 4rem 1rem" },
  inner: props => ({ padding: "1rem", borderTop: `1px solid ${props.color}` }),
  name: props => ({ color: props.color }),
  buttonWrapper: { width: "100%", textAlign: "right" },
  button: props => ({ minWidth: "6rem" }),
  completeMark: { color: colors.green },
  overviewHeader: { paddingBottom: "1rem" },
  overviewTitle: { fontWeight: "bold" },
  group: props => ({
    padding: "1rem 0.5rem",
    "&:hover": { background: `${props.color}50 !important`, cursor: "pointer" }
  }),
  groupImageWrapper: { padding: "0.5rem 0", textAlign: "center" },
  groupImage: { maxHeight: "5rem", maxWidth: "100%" },
  groupName: props => ({
    padding: "0.1rem",
    color: props.color,
    textAlign: "center",
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "3",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
  }),
  groupCircles: { textAlign: "center", },
});

function LearningPathSteps({ color, groups }) {
  const cls = useStyles({ color });
  const [group, setGroup] = useState(null);

  function getSteps() {
    let s = [];
    let spacing = 12 / groups.length;
    for (let i=0; i<groups.length; i++) {
      let g = groups[i];
      s.push(
        <Grid
          container
          item
          xs={spacing}
          alignItems="stretch"
          key={i}
          className={cls.group}
          onClick={()=>{setGroup(g)}}
        >
          <Grid item xs={12} className={cls.groupImageWrapper}>
            <img src={g.resourceImageURL} className={cls.groupImage} />
            &nbsp;
          </Grid>
          <Grid item xs={12} className={cls.groupName}>
            {g.name}
          </Grid>
          <Grid container alignItems="flex-end" justifyContent="center" item xs={12}>
            <Grid item xs={12}>
              <FilledCircleProgress
                total={g.totalSteps}
                completed={g.totalCompletedSteps}
                started={g.totalCompletedSteps + g.totalInProgressSteps}
                color={color}
                justify={"center"}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return s;
  };

  return (
    <Grid container className={cls.wrapper}>
      <Grid item xs={12} className={cls.overviewHeader}>
        <BTypography variant="h6" className={cls.overviewTitle}>
          Program Overview
        </BTypography>
      </Grid>
      <Grid container item xs={12}>
        {getSteps()}
      </Grid>
      <Dialog
        open={group ? true : false}
        onClose={()=> {setGroup(null)}}
      >
        {group && (
          <LearningGroup
            group={group}
            items={group.userPathItems}
            key={group.name}
            bcTab={2}
            bcGroup={0}
            color={color}
            defaultOpen={true}
            defaultGroup={true}
          />
        )}
      </Dialog>
    </Grid>
  );
}

export default LearningPathSteps;
