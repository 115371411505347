import React from "react";
import { BTypography, colors, RadarChartBuilder } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  emptyMessage: { fontStyle: "italic", padding: "1rem" }
});

export default function UserProgramCompletionActivity({ data }) {
  const cls = useStyles();
  // value to offset the graph from the actual value. Items with 0 values do not appear well for radar charts
  const percentOffset = 5;

  function makeModule(content) {
    return (
      <Grid container item xs={12} className={cls.module}>
        {content}
      </Grid>
    );
  }

  function getTotals() {
    let chartData = [];
    let baseValue = 0 + percentOffset;
    let totalProgress = 0;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let p = data[i];
        let a = ((p.totalCompletedCoreSteps + p.totalInProgressCoreSteps) / p.totalCoreSteps) * 100 + percentOffset;
        let b = (p.totalCompletedCoreSteps / p.totalCoreSteps) * 100 + percentOffset;
        let c = ((p.totalCompletedSteps + p.totalInProgressSteps) / p.totalSteps) * 100 + percentOffset;
        let d = (p.totalCompletedSteps / p.totalSteps) * 100 + percentOffset;
        a = a ? a : baseValue;
        b = b ? b : baseValue;
        c = c ? c : baseValue;
        d = d ? d : baseValue;
        let subject = p.title;
        if (chartData.some(x => x.subject === subject)) {
          subject = subject + ` - ${i}`;
        }

        // Add chart to chartData array
        chartData.push({ subject: subject, A: a, B: b, C: c, D: d, fullMark: 100 + percentOffset});
        // Add any progress to totalProgress
        totalProgress += p.totalInProgressCoreSteps + p.totalCompletedCoreSteps + p.totalCompletedSteps + p.totalInProgressSteps;
      }
      // Minimum of 3 items for the graph to display correctly
      while (chartData.length < 3) {
        chartData.push({ subject: `___${chartData.length}`, A: baseValue, B: baseValue, C: baseValue, D: baseValue, fullMark: 100 + percentOffset});
      }
    }

    // If there is no progress on any item, set all data to 0
    if (totalProgress === 0) {
      chartData.map(x => {
        x.A = 0;
        x.B = 0;
        x.C = 0;
        x.D = 0;
        x.fullMark = 100;
      });
    }

    return (
      <Grid container item xs={12}>
        <RadarChartBuilder
          data={chartData}
          valAColor={colors.green}
          valBColor={colors.darkGreen}
          valCColor={colors.progressBlue}
          valDColor={colors.bildBlue}
          percentOffset={-percentOffset}
        />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        {data && data.length > 0 && makeModule(getTotals())}
        {!data || data.length < 1 && (
          <BTypography variant="h6" className={cls.emptyMessage}>No activity currently available.</BTypography>
        )}
      </Grid>
    </Grid>
  );
}
