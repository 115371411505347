import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import { Button } from "@mui/material";

import SCSPopper from "./switchCompetencySetPopper.js";
import SCSDialog from "./switchCompetencySetDialog.js";

const useStyles = makeStyles({
  button: {
    border: "1px solid",
    padding: "0px",
    minWidth: "34px",
    minHeight: "34px",
    fontSize: "23px"
  }
});

// This is the "flow" enabling users to change the competency set.
// It all starts here with the button (which also sneaks in a popper and
// a dialog).
// user clicks button ==> popper opens ==> user selects new competency set
// ==> dialog opens asking for confirmation ==> user confirms ==> callback to
// change competency set.

function SwitchCompetencySetButton({ groupColor, competencySetId, competencySets, onSwitch }) {
  const classes = useStyles();
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [newCompetencySetId, setNewCompetencySetId] = useState(null);

  return (
    <>
      <Button
        aria-describedby="competency-set-popper"
        style={{ borderColor: groupColor, color: groupColor }}
        onClick={e => setPopperAnchor(e.currentTarget)}
        className={classes.button}
      >
        <i className={"fas fa-sliders-h"} />
      </Button>

      <SCSPopper
        anchor={popperAnchor}
        open={!!popperAnchor}
        onClose={() => setPopperAnchor(null)}
        groupColor={groupColor}
        competencySetId={competencySetId}
        competencySets={competencySets}
        onSwitchIntent={setNewCompetencySetId}
      />

      <SCSDialog
        open={newCompetencySetId != null}
        onClose={() => setNewCompetencySetId(null)}
        onConfirm={() => {
          if (onSwitch) onSwitch(newCompetencySetId);
          setNewCompetencySetId(null);
        }}
      />
    </>
  );
}

export default SwitchCompetencySetButton;
