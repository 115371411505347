import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography, Grid, Button, Dialog, DialogContent } from "@mui/material";

import { colors } from "bild-ui";

const useStyles = makeStyles({
  container: { textAlign: "center" },
  content: { padding: "10px 0 25px 0" },
  problemTitle: { color: colors.orange },
  iconCircle: { fontSize: "3rem", lineHeight: "4rem" },
  gridItem: { padding: "7px 0px" },
  problemHint: {
    border: `1px solid ${colors.orange}`,
    background: "#f4f4f4",
    padding: "15px 5px",
    borderRadius: "5px",
    textAlign: "left",
    "& i": {
      color: colors.orange,
      paddingRight: "10px",
      paddingLeft: "4px"
    }
  },
  problemCaption: { opacity: 0.5 },
  confirmAgreeButton: {
    padding: "4px 16px",
    marginTop: "10px",
    width: "90%",
    "& *": {
      color: "#fff"
    },
    backgroundColor: colors.green,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: colors.green
    },
    "&.disabled": {
      border: `1px solid ${colors.green}`,
      backgroundColor: "white",
      "& span": {
        color: colors.green
      }
    }
  }
});

function SubmitProblemDialog({ open, title, message, hintMessage, caption, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.container} maxWidth="sm" fullWidth>
      <DialogContent className={classes.content}>
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.gridItem}>
            <span className={`fa-stack fa-2x ${classes.problemTitle}`}>
              <i className={`fas fa-circle fa-stack-2x ${classes.iconCircle}`} />
              <i className="fal fa-times fa-stack-1x fa-inverse" />
            </span>
          </Grid>

          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Typography variant="h5" className={classes.problemTitle}>
              {title}
            </Typography>
          </Grid>

          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Typography variant="subtitle1">{message}</Typography>
          </Grid>

          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Grid container justifyContent="center" alignItems="center" className={classes.problemHint}>
              <Grid item xs={1}>
                <i className="fal fa-lightbulb-exclamation fa-2x" />
              </Grid>

              <Grid item xs={11}>
                <Typography variant="body1">{hintMessage}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Typography variant="body1" className={classes.problemCaption}>
              {caption}
            </Typography>
          </Grid>

          <Grid item md={4} xs={12}>
            <Button className={classes.confirmAgreeButton} onClick={onClose}>
              Ok, I understand.
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SubmitProblemDialog;
