import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  block: { margin: "0 0 0.5em 0", wordBreak: "break-word" }
});

function Element({ attributes, children, element }) {
  const classes = useStyles();

  let style;
  if (element.align) {
    style = { textAlign: element.align };
  }

  switch (element.type) {
    case "block-quote":
      return (
        <blockquote {...attributes} className={classes.block}>
          {children}
        </blockquote>
      );
    case "heading-one":
      return (
        <h1 {...attributes} className={classes.block}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 {...attributes} className={classes.block}>
          {children}
        </h2>
      );
    case "bulleted-list":
      return (
        <ul {...attributes} className={classes.block}>
          {children}
        </ul>
      );
    case "list-item":
      return (
        <li {...attributes} className={classes.block}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol {...attributes} className={classes.block}>
          {children}
        </ol>
      );
    case "left":
      return (
        <p {...attributes} className={classes.block}>
          {children}
        </p>
      );
    case "link":
      return (
        <a {...attributes} href={element.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    default:
      return (
        <p {...attributes} style={style} className={classes.block}>
          {children}
        </p>
      );
  }
}

export default Element;
