import React, { useEffect, useState } from "react";
import { diskData, userData } from "bild-data";
import { colors, UserAvatar, NormalButton, BTypography, LoadingScreen, PopTip } from "bild-ui";
import { useTheme, makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, Grid } from "@mui/material";
import ArtifactWorkflow from "equip/artifact/artifactWorkflow.js";

const useStyles = makeStyles({
  header: { padding: "0.25rem 0.25rem" },
  fileName: {
    fontStyle: "italic",
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "2",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  previewNote: { fontStyle: "italic", textAlign: "center" },
  frame: {
    width: "100%",
    height: "80vh",
    border: `3px solid ${colors.veryLightBlue}`,
    borderRadius: "4px"
  },
  docDialogResize: {
    "& .MuiPaper-root": {
      resize: "horizontal",
      padding: "0 0.25rem 0 0",
      background: `linear-gradient(135deg, ${colors.white} 0, ${colors.white} 98.6%, ${colors.darkGray} 98.8%, ${colors.white} 99%, ${colors.white} 99.2%, ${colors.darkGray} 99.4%, ${colors.white} 99.6%)`,
      "&::-webkit-resizer": {
        display: "none"
      }
    }
  },
  docDialogWrapper: {
    minHeight: "90vh",
    padding: "1rem 0.5rem 0.5rem 0.5rem"
  },
  inAppPreviewWrapper: {
    padding: "0 0 5rem 0"
  },
  filePreviewWrapper: {
    padding: "0"
  },
  fsButton: {
    color: colors.darkerGray
  },
});

export default function PreviewFileDialog({ currentUser, docId, previewFile, fileLoading, setCurrentUser, setDocId, setPreviewFile, allPreviewFiles }) {
  const cls = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [fsOverride, setFsOverride] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentFilePos, setCurrentFilePos] = useState(0);

  useEffect(()=>{
    const userPreferences = userData.getUserPreferences();
    setFsOverride(userPreferences.filePreviewFullscreen);
  },[]);

  // Find current position of file in allPreviewFiles
  useEffect(()=>{
    if (allPreviewFiles) {
      let pos = 0;
      if (previewFile) {
        pos = allPreviewFiles.map(x => x.id).indexOf(previewFile.id);
      }
      if (docId) {
        pos = allPreviewFiles.map(x => x.inAppLocalId ? x.inAppLocalId : "NULL").indexOf(docId);
      }
      setCurrentFilePos(pos);
    }
  },[allPreviewFiles, previewFile, docId]);

  // Set loading on reload
  useEffect(()=>{
    if (docId || previewFile) {
      setIsLoading(false);
    }
  },[docId, previewFile]);

  // Set LEFT/RIGHT ARROW keybinds for navigation
  useEffect(() => {
    const keyPressHandler = e => {
      if (allPreviewFiles && allPreviewFiles.length > 0) {
        if (e.key === "ArrowLeft") {
          _prevFile();
        } else if (e.key === "ArrowRight") {
          _nextFile();
        }
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [allPreviewFiles, docId, previewFile]);

  function onClose() {
    if (typeof setCurrentUser === "function") setCurrentUser(null);
    if (typeof setDocId === "function") setDocId(null);
    if (typeof setPreviewFile === "function") setPreviewFile(null);
  }

  function _toggleFsOverride() {
    const userPreferences = userData.getUserPreferences();
    const newFsOverrideState = !fsOverride;
    userPreferences.filePreviewFullscreen = newFsOverrideState;
    userData.setUserPreferences(userPreferences);
    setFsOverride(newFsOverrideState);
  }

  function _changeFile(newPos) {
    if (allPreviewFiles) {
      setIsLoading(true);
      let currentFile = allPreviewFiles[newPos];
      setCurrentUser(currentFile.user);
      if (currentFile.inAppLocalId) {
        if (typeof setPreviewFile === "function") setPreviewFile(null);
        if (typeof setDocId === "function") setDocId(currentFile.inAppLocalId);
      } else {
        currentFile.isFile = true;
        if (typeof setDocId === "function") setDocId(null);
        if (typeof setPreviewFile === "function") setPreviewFile(currentFile);
      }
      setCurrentFilePos(newPos);
    }
  }

  function _nextFile() {
    let newPos = currentFilePos + 1;
    if (newPos < allPreviewFiles.length) {
      _changeFile(newPos);
    }
  }

  function _prevFile() {
    let newPos = currentFilePos - 1;
    if (newPos >= 0) {
      _changeFile(newPos);
    }
  }

  return (
    <Dialog
      open={docId || previewFile ? true : false}
      onClose={onClose}
      maxWidth={"xl"}
      fullWidth
      fullScreen={fsOverride ? fsOverride : fullScreen}
      className={(fsOverride ? fsOverride : fullScreen) ? "" : cls.docDialogResize}
    >
      <Grid container className={cls.docDialogWrapper} alignItems="flex-start" justifyContent="center">
        <Grid container item xs={12} alignItems="center" justifyContent="space-between">
          {currentUser && (
            <Grid container item lg={3} md={4} sm={4} xs={5}>
              <UserAvatar name={currentUser.name} src={currentUser.avatarURL} badgeSrc={currentUser.badgeURL}  size={50} nameVariant={"h5"} />
            </Grid>
          )}
          {allPreviewFiles && (
            <Grid container item lg={6} md={5} sm={4} xs={2} alignItems={"center"} justifyContent={"flex-end"}>
              {previewFile && !isMobile && (
                <Grid item xs={8}>
                  <PopTip text={previewFile.name}>
                    <BTypography variant="body1"><span className={cls.fileName}>{previewFile.name}</span></BTypography>
                  </PopTip>
                </Grid>
              )}
              <Grid container item xs={4} alignItems={"center"} justifyContent={"flex-end"}>
                <Grid item>
                  <NormalButton variant="simple" onClick={_prevFile} disabled={currentFilePos < 1}>
                    <i className={`fas fa-chevron-left`} />
                  </NormalButton>
                </Grid>
                <Grid item>
                  <NormalButton variant="simple" onClick={_nextFile} disabled={currentFilePos >= allPreviewFiles.length - 1}>
                    <i className={`fas fa-chevron-right`} />
                  </NormalButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container item lg={3} md={3} sm={4} xs={5} alignItems="center" justifyContent="flex-end">
            {previewFile && (
              <Grid item>
                <NormalButton variant="simple" color={colors.bildBlue} labelColor={colors.white} onClick={()=>{diskData.downloadItem(previewFile)}}>
                  <i className="fad fa-download fa-2x" />
                </NormalButton>
              </Grid>
            )}
            {!fullScreen && (
              <Grid item>
                <NormalButton variant={"simple"} onClick={_toggleFsOverride}>
                  <i className={`fal ${fsOverride ? "fa-compress-alt" : "fa-expand-alt"} fa-2x ${cls.fsButton}`} />
                </NormalButton>
              </Grid>
            )}
            <Grid item>
              <NormalButton variant={"simple"} onClick={onClose}>
                <i className="fal fa-times fa-2x" />
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${docId ? cls.inAppPreviewWrapper : cls.filePreviewWrapper}`}>
          {isLoading && (
            <LoadingScreen />
          )}
          {!isLoading && (
            <ArtifactWorkflow
              docId={docId}
              setDocId={(d)=>{
                if (typeof setCurrentUser === "function") setCurrentUser(null);
                setDocId(d);
              }}
              previewFile={previewFile}
              setPreviewFile={(p)=>{
                if (typeof setCurrentUser === "function") setCurrentUser(null);
                setPreviewFile(p);
              }}
              artifacts={[]}
              editable={false}
              fileLoading={fileLoading}
              hideBack={true}
              isFullScreen={fsOverride ? fsOverride : fullScreen}
              allPreviewFiles={allPreviewFiles}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
