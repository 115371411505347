import React, { useState, useEffect } from "react";
import { managerData } from "bild-data";
import { LoadingScreen } from "bild-ui";
import { useTheme } from "@mui/styles";
import { Dialog } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PreviewPaths from "./previewPaths.js";
import PreviewPath from "./previewPath.js";

function PreviewPathLoader({}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paths, setPaths] = useState(null);
  const [pathId, setPathId] = useState(null);
  const [path, setPath] = useState(null);

  useEffect(() => {
    function _setPaths(p) {
      setPaths(p.paths);
      setLoading(false);
    }

    managerData.getManagedPaths(_setPaths, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    function _setPath(p) {
      setPath(p);
      setLoading(false);
    }

    if (pathId) {
      managerData.getManagedPathOutline(pathId, _setPath, e => {
        setError(e.response.data.error);
      });
    } else {
      setPath(null);
    }
  }, [pathId]);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <>
      <PreviewPaths paths={paths} pathId={pathId} setPathId={setPathId} />
      <Dialog
        open={pathId ? true : false}
        onClose={() => {
          setPathId(null);
        }}
        fullWidth
        maxWidth={"xl"}
        fullScreen={fullScreen}
      >
        {pathId && path && (
          <PreviewPath
            path={path}
            onClose={() => {
              setPathId(null);
            }}
          />
        )}
      </Dialog>
    </>
  );
}

export default PreviewPathLoader;
