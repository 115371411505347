import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Blink, BTypography, colors, NormalButton, SortableList } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  title: { padding: "0 1rem 0 1rem" },
  thumbnailUrl: { height: "5rem" },
  resourceGenImage: {
    background: "linear-gradient(180deg, rgba(47,34,7,1) 0%, rgba(68,46,29,1) 13%, rgba(68,51,29,0.86) 100%)",
    listStyle: "none",
    border: "1px solid #888",
    padding: "0.5rem",
    height: "5rem",
    width: "4rem",
    fontSize: "0.4rem",
    display: "block",
    wordBreak: "break-word",
    marginBottom: "10px",
    color: "#fff",
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      opacity: ".8"
    }
  }
});

export default function StaffSearchBooks({ books, count }) {
  const cls = useStyles();
  const [items, setItems] = useState([]);

  useEffect(()=>{
    if (books) {
      setItems(books.map(x => [
        [
          <Grid container sortval={x.title} key={x.title} justifyContent="center" alignItems={"center"}>
            <Grid item>
              {x.thumbnailUrl && (
                <img src={x.thumbnailUrl} className={cls.thumbnailUrl} />
              )}
              {!x.thumbnailUrl && (
                <div className={cls.resourceGenImage}>
                  <div>
                    {x.title}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs className={cls.title}>
              <BTypography variant="body1">
                {x.title}
              </BTypography>
            </Grid>
          </Grid>
        ],
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              component={Blink}
              dst="library"
              book_id={x.id}
              variant="micro"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
            >
              <i className="fas fa-chevron-right" />
            </NormalButton>
          </Grid>
        </Grid>
      ]));
    }
  },[books]);

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
      <SortableList
        headers={["Name", ""]}
        items={items}
        itemPadding={"0.5rem 1rem"}
        itemType={"Books"}
        spacing={[10,2]}
        title={`Your Library (${count})`}
        fixedHeight={"55vh"}
      />
    </Grid>
  );
}
