import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { libraryData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import queryString from "query-string";
import Library from "containers/library/library.js";

function LibraryLoader() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(null);
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);
  const [library, setLibrary] = useState(userData.getUserLibrary());

  useEffect(() => {
    setPageTitle("Library BETA");
    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      setLanguage(searchParams.language);
    }

    if (library) {
      setBooks(library.libraryBooks);
      setLoading(false);
    }
  },[library]);

  useEffect(() => {
    function checkLibrary(newData) {
      let refresh = true;

      // Check if the localStorage library data is different from the server's
      if (library) {
        let sortedOldData = library.libraryBooks.map(x => { return {"id": x.id, "thumbnailUrl": x.thumbnailUrl} }).sort((a,b) => a.id - b.id);
        let sortedNewData = newData.libraryBooks.map(x => { return {"id": x.id, "thumbnailUrl": x.thumbnailUrl} }).sort((a,b) => a.id - b.id);
        let comparedData = JSON.stringify(sortedOldData) === JSON.stringify(sortedNewData);
        if (comparedData) {
          refresh = false;
        }
      }

      // Refresh the localStorage and page data
      if (refresh) {
        setLibrary(newData);
        userData.setUserLibrary(newData);
      }
    }

    // Load Library data
    libraryData.load(checkLibrary, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return <Library books={books} language={language} />;
  }
}

export default LibraryLoader;
