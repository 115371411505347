import React, { useState, useEffect } from "react";
import isEqual from "lodash.isequal";

import { ENUMS, userData, enrollmentData, userPathData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Dashboard from "./dashboard.js";

// DASHBOARD LOADER
function DashboardLoader({}) {
  const [error, setError] = useState(null);
  const [enrollments, setEnrollments] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [activities] = useState([]);
  const [equip, setEquip] = useState(false);
  const [equipPaths, setEquipPaths] = useState(null);
  const [newCertCount, setNewCertCount] = useState(false);

  const [overview] = useState(userData.getOverview());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPageTitle("Dashboard");

    function _setEnrollments(e) {
      setEnrollments(e ? e.sort((a, b) => a.id - b.id) : []);
      setLoading(false);
    }

    function _setPrograms(p) {
      setPrograms(p ? p.sort((a, b) => a.id - b.id) : []);
      setLoading(false);
    }

    // Load page with saved local data if available
    if (overview && overview.enrollments) {
      const filtered_enrollments = overview.enrollments.filter(e => e.enabled);
      _setEnrollments(filtered_enrollments);
      // Should already be filtered in most cases bc load only called for enabled enrollments (will be flushed elsewise)
      _setPrograms(enrollmentData.getDashboards(filtered_enrollments.map(e => e.id)));
      setEquip(overview.equipRoles && overview.equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.USER.ID));
      setNewCertCount(overview.notifyCertificateCount);
    }

    // Make initial call to get enrollment dashboards, update if local data does not match
    userData.loadOverview(
      o => {
        const new_filtered_enrollments = o.enrollments ? o.enrollments.filter(e => e.enabled) : [];

        if (!isEqual(new_filtered_enrollments, enrollments)) {
          _setEnrollments(new_filtered_enrollments);
        }

        enrollmentData.loadDashboards(
          new_filtered_enrollments.map(e => e.id),
          dashes => {
            if (!isEqual(dashes, programs)) {
              _setPrograms(dashes);
            }
          },
          e => {
            setError(e.response.data.error);
          }
        );

        setEquip(o.equipRoles && o.equipRoles.some(r => r.id === ENUMS.EQUIP_ROLES.USER.ID));
        setNewCertCount(o.notifyCertificateCount);
      },
      e => {
        setError(e.response.data.error);
      }
    );

    // Make initial call to get dashboard metrics
    userData.loadDashboardMetrics(r => {
      setMetrics(r);
    });

    // Get Equip Data
    userPathData.loadLearner((d) => { setEquipPaths(d.userPaths) }, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <Dashboard
      enrollments={enrollments}
      programs={programs}
      activities={activities}
      metrics={metrics}
      equip={equip}
      equipPaths={equipPaths}
      newCertCount={newCertCount}
    />
  );
}

export default DashboardLoader;
