import React from "react";
import { styled } from "@mui/styles";

import { blink } from "bild-utils";

const BlinkAnchor = styled("a")({
  color: "#000",
  textDecoration: "none",
  "&:hover": {
    color: "#000",
    textDecoration: "none",
    "-webkit-filter": "brightness(0.9)",
    filter: "brightness(0.9)"
  },
  "&:active": {
    "-webkit-filter": "brightness(0.7)",
    filter: "brightness(0.7)"
  }
});

const Blink = React.forwardRef(({ dst, children, href, ...props }, ref) => {
  if (!href) href = blink(dst, props);

  // We override unset href.
  const forwardProps = { ...props, href: href };

  return (
    <BlinkAnchor ref={ref} {...forwardProps}>
      {children}
    </BlinkAnchor>
  );
});

export default Blink;
