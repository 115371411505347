import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import { Typography, Badge, Grid } from "@mui/material";

const useStyles = makeStyles({
  badge: {
    "& span": {
      fontWeight: "bold",
      transform: "scale(1) translate(100%, 0%)"
    }
  },
  subBadge: {
    "& span": {
      background: colors.darkerGray
    }
  }
});

function TraineesReviewCount({
  showDetails,
  awaitingReviewCount,
  completedReviewCount,
  Days7Count,
  Days14Count,
  assessmentsWaiting,
  requiringCoach,
  notRequiringCoach
}) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      {!showDetails && (
        <>
          {/* PER jsents, this item is listed at the bottom OR top depending on if details are enabled */}
          <Grid item md={2} sm={3}>
            <Badge
              color={awaitingReviewCount === 0 ? "primary" : "secondary"}
              badgeContent={awaitingReviewCount}
              className={classes.badge}
              showZero={true}
            >
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Awaiting AFC Review</Typography>
          </Grid>
        </>
      )}
      <Grid item md={2} sm={3}>
        <Badge color="primary" badgeContent={assessmentsWaiting} className={classes.badge} showZero={true}>
          &nbsp;
        </Badge>
      </Grid>
      <Grid item md={10} sm={9}>
        <Typography variant="body1">Awaiting AFT Review</Typography>
      </Grid>
      {showDetails && (
        <>
          {/* Trainee reviews (waiting on coach) */}
          <Grid item md={2} sm={3}>
            <Badge color="primary" badgeContent={requiringCoach} className={`${classes.badge} ${classes.subBadge}`} showZero={true}>
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Awaiting AFT Review (requiring AFC Review)</Typography>
          </Grid>
          {/* Trainee reviews (NOT waiting on coach) */}
          <Grid item md={2} sm={3}>
            <Badge color="primary" badgeContent={notRequiringCoach} className={`${classes.badge} ${classes.subBadge}`} showZero={true}>
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Awaiting AFT Review (not requiring AFC Review)</Typography>
          </Grid>
          {/* Trainee Assessments in last 7 Days */}
          <Grid item md={2} sm={3}>
            <Badge color="primary" badgeContent={Days7Count} className={`${classes.badge} ${classes.subBadge}`} showZero={true}>
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Last 1 Week of AFT Assessments</Typography>
          </Grid>
          {/* Trainee Assessments in last 14 Days */}
          <Grid item md={2} sm={3}>
            <Badge color="primary" badgeContent={Days14Count} className={`${classes.badge} ${classes.subBadge}`} showZero={true}>
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Last 2 Weeks of AFT Assessments</Typography>
          </Grid>
          {/* PER jsents, this item is listed at the bottom OR top depending on if details are enabled */}
          <Grid item md={2} sm={3}>
            <Badge
              color={awaitingReviewCount === 0 ? "primary" : "secondary"}
              badgeContent={awaitingReviewCount}
              className={classes.badge}
              showZero={true}
            >
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Awaiting AFC Review</Typography>
          </Grid>
          {/* Completed Coach Reviews */}
          <Grid item md={2} sm={3}>
            <Badge color="primary" badgeContent={completedReviewCount} className={`${classes.badge} ${classes.subBadge}`} showZero={true}>
              &nbsp;
            </Badge>
          </Grid>
          <Grid item md={10} sm={9}>
            <Typography variant="body1">Completed AFC Reviews</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default TraineesReviewCount;
