import React from "react";
import { makeStyles } from "@mui/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  actionButton: {
    minHeight: "65px"
  },
  icon: {
    display: "inline-block",
    fontSize: "30px",
    paddingRight: "20px"
  },
  brandIcon: {
    display: "inline-block",
    fontSize: "30px"
  },
  chooseFolio: {
    cursor: "pointer",
    padding: "1rem",
    marginBottom: "1.5rem",
    backgroundColor: "#00458D"
  },
  chooseComputer: {
    cursor: "pointer",
    padding: "1rem",
    marginBottom: "1.5rem",
    backgroundColor: "#D97B1C"
  },
  chooseGoogle: {
    cursor: "pointer",
    padding: "1rem",
    marginBottom: "1.5rem",
    backgroundColor: "#DD4B39"
  },
  chooseDropbox: {
    cursor: "pointer",
    padding: "1rem",
    backgroundColor: "#007EE5"
  },
  typo: {
    color: "white"
  }
});

function FileSourceButton({ label, variant, onClick }) {
  const classes = useStyles();

  let cn, icon;
  switch (variant) {
    case "computer":
      cn = classes.chooseComputer;
      icon = (
        <span className={classes.icon}>
          <i className={`far fa-desktop`} />
        </span>
      );
      break;
    case "google":
      cn = classes.chooseGoogle;
      icon = (
        <span className={classes.brandIcon}>
          <i className={`fab fa-google-drive`} />
        </span>
      );
      break;
    case "dropbox":
      cn = classes.chooseDropbox;
      icon = (
        <span className={classes.brandIcon}>
          <i className={`fab fa-dropbox`} />
        </span>
      );
      break;
    default:
      // "folio"
      cn = classes.chooseFolio;
      icon = (
        <span className={classes.icon}>
          <i className={`fas fa-folder-open`} />
        </span>
      );
      break;
  }

  return (
    <Paper className={`${cn} actionButton`} onClick={onClick}>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item sm={2} xs={12}>
          <Typography className={classes.typo}>{icon}</Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography className={classes.typo}>{label}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default FileSourceButton;
