import React from "react";
import { access } from "bild-utils";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { Grid } from "@mui/material";

import { colors } from "bild-ui";

const useStyles = makeStyles({
  innerContainer: {
    textAlign: "center",
    display: "block",
    position: "relative",
    padding: "0 25%"
  },
  icon: {
    display: "inline-block",
    height: "40px",
    width: "40px",
    color: "white",
    lineHeight: "40px",
    verticalAlign: "center",
    border: "1px solid lightgrey",
    borderRadius: "50vh"
  },
  check: {
    backgroundColor: colors.green,
    fontSize: "25px"
  },
  times: {
    backgroundColor: colors.orange,
    fontSize: "28px"
  },
  sync: {
    backgroundColor: colors.blue,
    fontSize: "20px"
  },
  double: {
    display: "block",
    width: "auto",
    height: "40px",
    margin: "0 -20px 0 -20px",
    borderRadius: "50vh"
  },
  triple: {
    display: "block",
    width: "auto",
    height: "40px",
    margin: "0 -50px 0 -50px",
    borderRadius: "50vh"
  }
});

function AssessmentStatusIcon({ icon }) {
  const classes = useStyles();

  const frontendValues = (access(icon, "webFrontendValue") || []).split(" ");

  // There is some css magic that needs to happen, which is not compatible with
  // with the icon classes provided by the backend. Maybe this is not information
  // we want the backend to be responsible for.
  let iconWidth = 4;
  if (frontendValues.includes("fa-double")) {
    frontendValues[frontendValues.indexOf("fa-double")] = classes.double;
    iconWidth = 8;
  } else if (frontendValues.includes("fa-triple")) {
    frontendValues[frontendValues.indexOf("fa-triple")] = classes.triple;
    iconWidth = 12;
  }

  let iconElement;
  switch (true) {
    case frontendValues.includes("fa-check-circle"):
      frontendValues[frontendValues.indexOf("fa-check-circle")] = "fa-check";
      iconElement = <i className={clsx("fal", frontendValues, classes.check, classes.icon)} />;
      break;
    case frontendValues.includes("fa-times-circle"):
      frontendValues[frontendValues.indexOf("fa-times-circle")] = "fa-times";
      iconElement = <i className={clsx("fal", frontendValues, classes.times, classes.icon)} />;
      break;
    case frontendValues.includes("fa-sync"):
      iconElement = <i className={clsx("fal", frontendValues, classes.sync, classes.icon)} />;
      break;
    default:
      iconElement = <i className={clsx("fal", frontendValues, classes.icon)} />;
  }

  return (
    <Grid item xs={iconWidth} style={{ position: "relative" }}>
      <span className={classes.innerContainer}>{iconElement}</span>
    </Grid>
  );
}

export default AssessmentStatusIcon;
