import React from "react";
import { Blink } from "bild-ui";
import { Popper, ClickAwayListener, Paper, List, ListItemButton } from "@mui/material";

function OrganizationActionsPopper({ open, setOpen, anchorEl, orgId, isAntioch, isEquip, updateOrg }) {
  return (
    <Popper open={open} anchorEl={anchorEl}>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
        mouseEvent="onMouseDown"
      >
        <Paper>
          <List dense>
            <ListItemButton dense onClick={updateOrg}>
              Update Organization
            </ListItemButton>
            {isAntioch && (
              <>
                <ListItemButton dense component={Blink} dst={"reports-organization-student-progress"} organization_id={orgId}>
                  A.S. Student Progress
                </ListItemButton>
                <ListItemButton dense component={Blink} dst={"reports-organization-module-progress"} organization_id={orgId}>
                  A.S. Module Progress
                </ListItemButton>
              </>
            )}
            {isEquip && (
              <ListItemButton dense component={Blink} dst={"organization-user-certificates"} organization_id={orgId}>
                Completed Certificates
              </ListItemButton>
            )}
            <ListItemButton dense component={Blink} dst={"manage-organization-activity"} organization_id={orgId}>
              Recent Activity
            </ListItemButton>
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

export default OrganizationActionsPopper;
