import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  tab: {
    padding: "1rem",
    borderRadius: "0"
  },
  activeTab: {
    background: `${colors.bildBlue} !important`,
    "&:hover": {
      background: `${colors.darkBildBlue} !important`
    }
  }
});

function OrganizationAdminTabs({ tab, setTab }) {
  const cls = useStyles();

  function makeTab(i, name) {
    return (
      <NormalButton
        component={Grid}
        item
        sm
        xs={4}
        onClick={() => {
          setTab(i);
        }}
        className={`${cls.tab} ${i === tab ? cls.activeTab : ""}`}
        variant="primary"
        color={colors.gray}
        labelColor={colors.white}
        hoverColor={colors.darkGray}
      >
        {name}
      </NormalButton>
    );
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
      {makeTab(1, "Profile")}
      {makeTab(2, "Users")}
      {makeTab(3, "Antioch")}
      {makeTab(4, "Network")}
      {makeTab(5, "Certificates")}
      {makeTab(6, "Roles")}
    </Grid>
  );
}

export default OrganizationAdminTabs;
