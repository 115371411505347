import React, { useState, useEffect } from "react";
import { driveData } from "bild-data";
import { ArtifactEditor, ArtifactReader } from "modules";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  body: { minHeight: "300px" }
});

function Artifact({ artifactId, editable }) {
  const cls = useStyles();
  const [docObject, setDocObject] = useState(null);
  const [docHandle, setDocHandle] = useState(null);

  useEffect(() => {
    setDocObject(driveData.getDocument(artifactId));
    setDocHandle(driveData.getHandle(artifactId));
  }, [artifactId]);

  function processUploadQueue() {
    driveData.processUploadQueue();
  }

  if (!docObject) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} className={cls.body}>
        {editable && (
          <ArtifactEditor
            document={docObject}
            handle={docHandle}
            onHandleChange={driveData.updateHandle}
            onDocumentChange={driveData.updateDocument}
            processUploadQueue={processUploadQueue}
          />
        )}
        {!editable && <ArtifactReader document={docObject} handle={docHandle} />}
      </Grid>
    </Grid>
  );
}

export default Artifact;
