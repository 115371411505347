import React, { useState } from "react";
import { colors, BTypography, UserAvatar } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import UserPathItemNavigation from "equip/user-path-item/components/userPathItemNavigation.js";
import EvaluationLoader from "equip/evaluation/evaluationLoader.js";
import CompetencyLoader from "equip/competency/competencyLoader.js";
import DataReportLoader from "equip/dataReport/dataReportLoader.js";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    head: props => ({
      padding: "2.5rem",
      color: colors.white,
      backgroundColor: props.color,
      backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
      backgroundPositionY: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      transition: "all 5s ease"
    }),
    headTitle: { paddingBottom: "2rem" },
    body: {
      [theme.breakpoints.down('sm')]: {
        padding: "0 !important"
      }
    },
    viewOnlyTag: props => ({
      position: "absolute",
      backgroundColor: props.color,
      border: `1px solid ${colors.white}`,
      color: colors.white,
      padding: "0.5rem 1rem",
      marginTop: "2.5rem",
      fontWeight: "bold",
      zIndex: "1000",
      opacity: "0.9"
    }),
    avatarName: { paddingLeft: "0.25rem" }
  })
);

function UserLearningItem({
  userLearningItem,
  userPathItem,
  breadcrumb,
  changeLevel,
  editable,
  user,
  createDocument,
  attach,
  remove,
  docId,
  setDocId,
  fileLoading,
  submitScore,
  markCompleted,
  submitReport,
  completed,
  color,
  backgroundURL
}) {
  const cls = useStyles({ color, backgroundURL });
  const [evaluation, setEvaluation] = useState(userLearningItem.evaluation);

  return (
    <Grid container>
      {!editable && (
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item className={cls.viewOnlyTag}>
            <UserAvatar size={30} src={userLearningItem.user.avatarURL} name={userLearningItem.user.name} badgeSrc={userLearningItem.user.badgeURL} srcSize="small" />
          </Grid>
        </Grid>
      )}
      {userPathItem && (
        <Grid item xs={12}>
          <UserPathItemNavigation
            userPathId={userPathItem.userPathId}
            breadcrumb={breadcrumb}
            lastItem={userPathItem.lastItem}
            nextItem={userPathItem.nextItem}
          />
        </Grid>
      )}
      <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.head}>
        <Grid item xs={12} className={cls.headTitle}>
          <BTypography variant="h4">{ReactHtmlParser(userLearningItem.name)}</BTypography>
        </Grid>
        <Grid item xs={12}>
          <BTypography variant="subtitle1">{ReactHtmlParser(userLearningItem.description)}</BTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="flex-start" className={cls.body}>
        {evaluation ? (
          <EvaluationLoader
            userPathItemId={userPathItem ? userPathItem.id : null}
            userLearningItemId={userLearningItem.id}
            evaluation={evaluation}
            setEvaluation={setEvaluation}
          />
        ) : userLearningItem.dataReport ? (
          <DataReportLoader completed={completed} userLearningItem={userLearningItem} submitReport={submitReport} color={color} />
        ) : (
          <CompetencyLoader
            userPathItemId={userPathItem ? userPathItem.id : null}
            userLearningItem={userLearningItem}
            changeLevel={changeLevel}
            editable={editable}
            user={user}
            createDocument={createDocument}
            attach={attach}
            remove={remove}
            docId={docId}
            setDocId={setDocId}
            fileLoading={fileLoading}
            submitScore={submitScore}
            markCompleted={markCompleted}
            completed={completed}
            color={color}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default UserLearningItem;
