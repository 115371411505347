import React, { useEffect, useState } from "react";
import { enrollmentData } from "bild-data";
import { toaster } from "presentational/toasts/toasts.js";
import { NormalButton } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { Grid, Button, Dialog, DialogContent, Divider, List, ListItem, Typography, Tabs, Tab }  from "@mui/material";
import "./courseSwitch.css";

function CourseSwitchDialog({ open, enrollmentId, course, isNewCourse, setProgramReviewStatus, toggleSwitchCourse }) {
  const [ courseLoaded, setCourseLoaded ] = useState(false);
  const [ activeTab, setActiveTab ] = useState(0);
  const [ substituteCourseId, setSubstituteCourseId ] = useState(null);
  const [ courseGroups, setCourseGroups ] = useState([]);
  const [ inSubmission, setInSubmission ] = useState(false);


  useEffect(()=>{
    // Update the state of the competency if the course changes
    if (course) {
      enrollmentData.loadCourseSubstitutes(
        enrollmentId,
        course.courseListId,
        (data)=>{
          setCourseGroups(data);
          setCourseLoaded(true);
        }
      );
    } else {
      setCourseLoaded(false);
    }
  },[course]);

  function updateCB(data) {
    setProgramReviewStatus(data);
    let selectType = isNewCourse ? "add" : "switch";
    toaster.success("Course successfully " + selectType + "ed.");
    closeSwitchCourse();
  }

  function getTabs() {
    let tabs = [<p key="00">&nbsp;</p>]; // IE breaks if this is ever empty
    const t = courseGroups;

    if (t && t.length > 0) {
      tabs = [];
      for (let i = 0; i < t.length; i++) {
        const label = (
          <Typography variant="subtitle1" className={activeTab === i ? "course-switch-tab" : ""}>
            {t[i].shortName}
          </Typography>
        );
        tabs.push(<Tab key={i} value={i} label={label} disableRipple />);
      }
    }

    return (
      <Tabs
        value={activeTab}
        onChange={(e, v) => setActiveTab(v)}
        TabIndicatorProps={{
          className: "course-switch-indicator"
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs}
      </Tabs>
    );
  }

  function getContent() {
    const subGroups = courseGroups;
    let courses = [];

    if (subGroups && subGroups[activeTab] && subGroups[activeTab].courses) {
      const subs = subGroups[activeTab].courses;
      for (let i = 0; i < subs.length; i++) {
        let v = subs[i];
        let color = v.courseGroupColor.webFrontendValue;
        let selected = substituteCourseId === v.id ? "selected" : "";
        courses.push(
          <ListItem
            key={i}
            value={v.id}
            onClick={() => {
              setSubstituteCourseId(v.id);
            }}
            className={"course-item " + selected}
          >
            <Grid container>
              <Grid item md={1} sm={2} xs={3} className="list-icon-wrapper" style={{ color: color }}>
                <i className={"list-icon fas fa-lg " + v.familyGroupIcon.webFrontendValue} style={{ backgroundColor: color }} />
              </Grid>
              <Grid container item md={11} sm={10} xs={9}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: color }}>
                    {v.courseNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: color }}>
                    {ReactHtmlParser(v.name)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        );
      }
    }

    return <List>{courses}</List>;
  }

  function getCourse(subCourseId) {
    const subGroups = courseGroups;
    const subs = subGroups[activeTab].courses;
    let course = null;

    for (let i = 0; i < subs.length; i++) {
      let v = subs[i];
      let color = v.courseGroupColor.webFrontendValue;

      if (substituteCourseId === v.id) {
        let comps = [];
        for (let j = 0; j < v.competencies.length; j++) {
          let comp = v.competencies[j];
          comps.push(
            <Grid container alignItems="center" justifyContent="center" key={j}>
              <Grid item sm={1} xs={2} className="competency-icon">
                <span className="fa-stack">
                  <i className="fal fa-circle fa-stack-2x" style={{ color: color }} />
                  <i className="fal fa-check fa-stack-1x" style={{ color: color }} />
                </span>
              </Grid>
              <Grid item sm={11} xs={10} className="competency-description">
                <Typography variant="body1">{ReactHtmlParser(comp.description)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          );
        }

        course = (
          <Grid container>
            <Grid item md={1} sm={2} xs={12} className="list-icon-wrapper" style={{ color: color }}>
              <i className={"list-icon fas fa-2x " + v.familyGroupIcon.webFrontendValue} style={{ backgroundColor: color }} />
            </Grid>
            <Grid container item md={11} sm={10} xs={12}>
              <Grid container item md={10} sm={9} xs={8}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: color }}>
                    {v.courseNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: color }}>
                    {ReactHtmlParser(v.name)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={2} sm={3} xs={4} className="switch-course-button-container">
                <Button
                  disabled={inSubmission}
                  onClick={() => {
                    submitSwitchCourse();
                  }}
                  className={(inSubmission ? "in-submission" : "") + " switch-course-button"}
                >
                  <Typography variant="subtitle1">Choose course</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className="course-description">
                  {ReactHtmlParser(v.description)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ color: color }}>
                  Competencies
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {comps}
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }

    return course;
  }

  function closeSwitchCourse() {
    setActiveTab(0);
    setSubstituteCourseId(null);
    setInSubmission(false);
    setCourseLoaded(false);
    toggleSwitchCourse();
  }

  function submitSwitchCourse() {
    let sendData = { id: substituteCourseId };
    setInSubmission(true);

    enrollmentData.switchCourse(enrollmentId, course.courseListId, sendData, updateCB);
  }

  let content = "";

  if (!substituteCourseId) {
    content = (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Select a Course</Typography>
        </Grid>
        <Grid item xs={12}>
          {getTabs()}
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {getContent()}
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <Grid container>
        <Grid item xs={12}>
          {getCourse(substituteCourseId)}
        </Grid>
      </Grid>
    );
  }

  if (courseLoaded) {
    return (
      <Dialog
        fullScreen
        open={open === true}
        onClose={() => {
          closeSwitchCourse();
        }}
        className="course-switch-dialog"
      >
        <DialogContent>
          <Grid container wrap="wrap" justifyContent="space-around">
            <Grid item xs={6} className="back-button">
              {substituteCourseId && (
                <NormalButton variant="simple" onClick={() => setSubstituteCourseId(null)}>
                  <i className="fal fa-arrow-left" />
                </NormalButton>
              )}
            </Grid>
            <Grid item xs={6} className="close-button">
              <NormalButton variant="simple" onClick={() => closeSwitchCourse()}>
                <i className="fal fa-times" />
              </NormalButton>
            </Grid>
            <Grid item xs={11}>
              <Grid container>
                {!isNewCourse && (
                  <Grid item xs={12} className="course-switch-description">
                    <Typography>
                      <i className="fas fa-exchange" /> You are selecting a course to switch with {course.courseNumber} {course.name}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {content}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
  
}

export default CourseSwitchDialog;
