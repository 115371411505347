import React from "react";
import { AddUserLoader } from "modules";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid } from "@mui/material";

const useStyles = makeStyles({
  content: { minHeight: "15rem" }
});

function AddUserDialogLoader({ open, onClose, defaultOrgs, defaultOrg, defaultId, defaultName, defaultEmail, success_cb }) {
  const cls = useStyles();

  function _success_cb(data) {
    if (typeof success_cb === "function") {
      onClose();
      success_cb(data);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogContent className={cls.content}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={12}>
            <AddUserLoader
              defaultOrgs={defaultOrgs}
              defaultOrg={defaultOrg}
              defaultId={defaultId}
              defaultName={defaultName}
              defaultEmail={defaultEmail}
              success_cb={_success_cb}
              onCancel={onClose}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddUserDialogLoader;
