import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { diskData } from "bild-data";
import { Grid } from "@mui/material";
import { colors, BTypography, PieProgress, NormalButton, LoadingScreen } from "bild-ui";
import Folio from "containers/folio/folio.js";
import { PreviewFileDialog } from "modules";
import FolioListItem from "containers/folio/components/folioListItem";
import UserAdminStorageUpgradeDialog from "./userAdminStorageUpgradeDialog";

const useStyles = makeStyles({
  pie: { padding: "3rem 0 0 0" },
  folio: { minHeight: "70vh", maxHeight: "70vh", overflow: "auto" },
  largeFilesWrapper: { padding: "0 2rem" },
  storageButton: { padding: "2rem 0" }
});

function UserAdminDisk({ user, disk, updateUserStorage }) {
  const cls = useStyles();
  const [previewDocId, setPreviewDocId] = useState(null);
  const [previewDocLoading, setPreviewDocLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);
  const [largeFiles, setLargeFiles] = useState(null);
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);

  function _updateUserStorage(d) {
    setUpgradeLoading(true);
    updateUserStorage(d, ()=>{setUpgradeLoading(false)});
  }

  useEffect(()=>{
    if (disk && disk.files) {
      // files over 3MB
      setLargeFiles(
        disk.files.filter(x => x.fileSizeBytes > 3000000)
                  .sort((a,b) => b.fileSizeBytes - a.fileSizeBytes)
                  .map(x => {
                    x.raw = x;
                    x.size = x.fileSizeDisplay;
                    return x;
                  })
      );
    }
  },[disk]);

  function _setDocId(id) {
    setPreviewDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setPreviewDocLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setPreviewDocLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setPreviewDocLoading(false);
        }
      );
    }
  }

  function onPreviewIntent(item) {
    if (item) {
      if (item.raw.inAppLocalId) {
        _setDocId(item.raw.inAppLocalId);
      } else {
        setPreviewFile(item);
      }
    } else {
      setPreviewDocId(null);
      setPreviewFile(null);
    }
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-start" className={cls.wrapper}>
      {disk && (
        <>
          <Grid container item sm={2} xs={6} className={cls.pie}>
            <Grid item xs={12}>
              <PieProgress value={disk.totalSpaceUsed} total={disk.totalSpace} label={"MB"} />
            </Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <Grid item className={cls.storageButton}>
                {upgradeLoading && (<LoadingScreen />)}
                {!upgradeLoading && (
                  <NormalButton
                    onClick={()=>{setConfirmUpgrade(true)}}
                    variant="primary"
                    color={disk && disk.totalSpace >= 500000000 ? colors.darkOrange : colors.darkGreen}
                    hoverColor={colors.darkerGreen}
                    labelColor={colors.white}
                    borderColor={colors.darkGreen}
                    disabled={disk && disk.totalSpace >= 500000000}
                  >
                    {disk && disk.totalSpace >= 500000000 ? "Maximum Storage Reached" : "Upgrade Storage Tier (+50MB)"}
                  </NormalButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={10} xs={12} className={cls.folio}>
            {largeFiles && (
              <Grid container item xs={12} className={cls.largeFilesWrapper}>
                <Grid item xs={12}>
                  <BTypography variant="h5">{largeFiles.length > 0 ? "" : "No "} Large Files</BTypography>
                </Grid>
                <Grid item xs={12}>
                  {
                    largeFiles.map(x => { return (
                      <FolioListItem
                        key={x.id}
                        variant={"list-item"}
                        item={x}
                        onClick={()=>{}}
                        onSelect={()=>{}}
                        onOpenMenu={()=>{}}
                        selectable={false}
                      />
                    )})
                  }
                </Grid>
              </Grid>
            )}
            <Folio diskArrays={disk} onDownloadItem={diskData.downloadItem} onPreviewIntent={onPreviewIntent} hideMenu={true} />
          </Grid>
          <PreviewFileDialog
            currentUser={user}
            docId={previewDocId}
            fileLoading={previewDocLoading}
            previewFile={previewFile}
            setCurrentUser={()=>{onPreviewIntent(null)}}
            setDocId={()=>{onPreviewIntent(null)}}
            setPreviewFile={()=>{onPreviewIntent(null)}}
          />
        </>
      )}
      <UserAdminStorageUpgradeDialog
        open={confirmUpgrade}
        onClose={()=>{setConfirmUpgrade(false)}}
        user={user}
        updateUserStorage={_updateUserStorage}
      />
    </Grid>
  );
}

export default UserAdminDisk;
