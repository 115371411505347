import React, { useEffect, useState } from "react";
import { colors, BTypography, DynamicCircleButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import EvaluationCommentSection from "./evaluationCommentSection.js";

const useStyles = makeStyles({
  header: { padding: "1rem 0" },
  body: { padding: "1rem 0" },
  card: { padding: "0.5rem 0" },
  files: { paddingLeft: "0.5rem" },
  column: { padding: ".5rem", textAlign: "center", fontSize: ".8rem" },
  subgroupName: { background: colors.lightGray, padding: "0.25rem 0.75rem" },
  subgroup: { paddingLeft: "0.75rem" }
});

function EvaluationItem({ evaluationGroupId, evaluationItem, showSubgroupIds, submitEvaluationScore, addEvaluationComment }) {
  const classes = useStyles();
  const [scores, setScores] = useState(evaluationItem.scores);
  const [userScore, setUserScore] = useState(0);
  const [leaderScore, setLeaderScore] = useState(0);
  const [mentorScore, setMentorScore] = useState(0);

  useEffect(() => {
    setScores(evaluationItem.scores);
    const length = evaluationItem.scores.length;
    setUserScore(0);
    setLeaderScore(0);
    setMentorScore(0);
    if (length > 0) {
      setUserScore(evaluationItem.scores[0].value);
    }
    if (length > 1) {
      setLeaderScore(evaluationItem.scores[1].value);
    }
    if (length > 2) {
      setMentorScore(evaluationItem.scores[2].value);
    }
  }, [evaluationItem]);

  return (
    <Grid container>
      <Grid item sm={4} xs={12}>
        <Grid container>
          <Grid item xs={4} className={classes.column}>
            Major Problem
          </Grid>
          <Grid item xs={4} className={classes.column}>
            Slight Problem
          </Grid>
          <Grid item xs={4} className={classes.column}>
            Doing Well
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={4} className={classes.column}>
            <DynamicCircleButton
              clicked={scores.length}
              icon="check"
              color={userScore && userScore === 1 ? "lightgreen" : ""}
              color2={leaderScore && leaderScore === 1 ? "lightblue" : ""}
              color3={mentorScore && mentorScore === 1 ? "lightgrey" : ""}
              onClick={() => {
                submitEvaluationScore(evaluationGroupId, evaluationItem.id, 1);
              }}
            />
          </Grid>
          <Grid container item xs={4} className={classes.column}>
            <DynamicCircleButton
              clicked={scores.length}
              icon="check"
              color={userScore && userScore === 3 ? "lightgreen" : ""}
              color2={leaderScore && leaderScore === 3 ? "lightblue" : ""}
              color3={mentorScore && mentorScore === 3 ? "lightgrey" : ""}
              onClick={() => {
                submitEvaluationScore(evaluationGroupId, evaluationItem.id, 3);
              }}
            />
          </Grid>
          <Grid container item xs={4} className={classes.column}>
            <DynamicCircleButton
              clicked={scores.length}
              icon="check"
              color={userScore && userScore === 5 ? "lightgreen" : ""}
              color2={leaderScore && leaderScore === 5 ? "lightblue" : ""}
              color3={mentorScore && mentorScore === 5 ? "lightgrey" : ""}
              onClick={() => {
                submitEvaluationScore(evaluationGroupId, evaluationItem.id, 5);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sm={8} xs={12} style={{ padding: ".5rem" }}>
        {evaluationItem.subgroupName && showSubgroupIds.includes(evaluationItem.id) && (
          <Grid item xs={12} className={classes.subgroupName}>
            <BTypography variant="subtitle2" className="">
              {evaluationItem.subgroupName}
            </BTypography>
          </Grid>
        )}
        <Grid container direction="column" className={evaluationItem.subgroupName ? classes.subgroup : ""}>
          <Grid item>
            <BTypography variant="body1">{evaluationItem.description}</BTypography>
          </Grid>
          <Grid item>
            <EvaluationCommentSection
              comments={evaluationItem.comments}
              onAddComment={comment => addEvaluationComment(evaluationGroupId, evaluationItem.id, comment)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EvaluationItem;
