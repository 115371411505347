import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, BTypography, NormalButton, FormTextField, FormNumberField, UserAvatar } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    container: props => ({
      background: colors.white,
      padding: "1rem 1rem 3rem 1rem",
      border: `1px solid ${props.color}`
    }),
    formWrapper: { padding: "1rem" },
    oldEntryLabel: props => ({ color: props.color }),
    oldEntryValue: { border: `1px solid ${colors.lightGray}`, padding: "1rem 0.75rem", margin: "0.25rem 0" },
    userWrapper: { minHeight: "5rem" },
    avatar: { paddingRight: "0.25rem" },
    buttonWrapper: { minHeight: "5rem", padding: "0 1rem 1rem 1rem" }
  })
);

function DataReportForm({ form, color, oldEntry, formData, setFormData, submitReport }) {
  const cls = useStyles({ color });
  const [disabled, setDisabled] = useState(oldEntry ? true : false);

  useEffect(() => {
    setDisabled(oldEntry ? true : false);
  }, [oldEntry]);

  function _submitReport(data) {
    setDisabled(true);
    submitReport(data);
  }

  function getFields() {
    const d = form;
    let fields = [];

    for (let i = 1; i <= 5; i++) {
      // String fields
      if (d[`str${i}Enabled`]) {
        let fieldName = `str${i}Value`;
        fields.push(
          <Grid container item xs={12} key={`str${i}`} sortorder={d[`str${i}Order`]}>
            {oldEntry ? (
              <Grid container item xs={12}>
                <Grid item xs={12} className={cls.oldEntryLabel}>
                  {d[`str${i}Name`]}
                </Grid>
                <Grid item xs={12} className={cls.oldEntryValue}>
                  {oldEntry[`str${i}Value`] ? oldEntry[`str${i}Value`] : <span className={cls.emptyField}>&nbsp;</span>}
                </Grid>
              </Grid>
            ) : (
              <FormTextField
                label={d[`str${i}Name`]}
                color={color}
                value={formData[fieldName]}
                setValue={x => {
                  setFormData({ ...formData, [fieldName]: x });
                }}
                disabled={disabled}
                placeholder={oldEntry ? " " : ""}
              />
            )}
          </Grid>
        );
      }
      // Number Fields
      if (d[`num${i}Enabled`]) {
        let fieldName = `num${i}Value`;
        fields.push(
          <Grid container item xs={12} key={`num${i}`} sortorder={d[`num${i}Order`]}>
            {oldEntry ? (
              <Grid container item xs={12}>
                <Grid item xs={12} className={cls.oldEntryLabel}>
                  {d[`num${i}Name`]}:
                </Grid>
                <Grid item xs={12} className={cls.oldEntryValue}>
                  {oldEntry[`num${i}Value`] ? oldEntry[`num${i}Value`] : <span className={cls.emptyField}>&nbsp;</span>}
                </Grid>
              </Grid>
            ) : (
              <FormNumberField
                label={d[`num${i}Name`]}
                color={color}
                value={formData[fieldName]}
                setValue={x => {
                  setFormData({ ...formData, [fieldName]: x });
                }}
                disabled={disabled}
                placeholder={oldEntry ? " " : ""}
              />
            )}
          </Grid>
        );
      }
    }

    // Sort based on each field's sortorder
    fields.sort((a, b) => a.props.sortorder - b.props.sortorder);

    return fields;
  }

  return (
    <Grid container className={cls.container}>
      <Grid container item xs={12} className={cls.posWrapper} justifyContent="space-between" alignItems="center">
        <Grid container item xs={12} className={cls.formWrapper}>
          {getFields()}
        </Grid>
        {oldEntry && (
          <Grid container item xs={12} className={cls.userWrapper} justifyContent="center" alignItems="center" direction="column">
            <Grid container item xs justifyContent="center" alignItems="center">
              <UserAvatar size={30} src={oldEntry.user.avatarURL} name={oldEntry.user.name} srcSize="small" badgeSrc={oldEntry.user.badgeURL} />
            </Grid>
            <Grid item xs>
              <BTypography variant="body1" className={cls.name}>
                Submitted on {oldEntry.createdAt}
              </BTypography>
            </Grid>
          </Grid>
        )}
        {!oldEntry && (
          <Grid container item xs={12} justifyContent="flex-end" className={cls.buttonWrapper}>
            <Grid item>
              <NormalButton
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                onClick={() => {
                  _submitReport([formData]);
                }}
                className={cls.button}
                disabled={disabled}
              >
                Submit Report
              </NormalButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default DataReportForm;
