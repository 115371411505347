import { Node } from "slate";

// Interface with helpers.
// AttributeStubs allow for inserting text attributes at point selections, which is not an out of the box behaviour.
const AttributeStub = {
  isMarkStub(value) {
    return !!value && value.type === "attribute-stub";
  },

  createStubFromNode(node, props) {
    if (!Node.isNode(node) || !props) return {};

    return {
      ...node,
      ...props,
      text: "\0",
      type: "attribute-stub"
    };
  },

  createNodeFromStub(stub, props) {
    if (!AttributeStub.isMarkStub(stub) || !props) return {};

    return {
      ...stub,
      ...props,
      type: undefined
    };
  }
};

// function setTextAttributes(editor, attributes) {
//   const selection = editor.selection;
//   const isPoint = Range.isCollapsed(selection);
//
//   if (isPoint) {
//     // if the selection is a point, we insert a stub meant to take in the newly marked text, it will be erased if this does not happen
//     const { path } = selection.anchor;
//     const node = Node.get(editor, path);
//     console.log(node);
//     const stub = AttributeStub.createStubFromNode(node, { ...attributes });
//     console.log(stub);
//     Editor.insertNode(editor, stub, { split: true });
//     ReactEditor.focus(editor);
//   } else {
//     Editor.setNodes(editor, { ...attributes }, { match: "text", split: true });
//     ReactEditor.focus(editor);
//   }
// }

function withRichText(editor) {
  // const { exec, onChange } = editor;

  //// meb removed to work with slate 0.58
  // editor.onChange = e => {
  //   // Clean up method meant to filter out unused stubs, pass twice because I'm paranoid.
  //   const { selection } = editor;
  //   if (Range.isCollapsed(selection)) {
  //     const entries = Node.texts(editor);
  //     for (let [n, p] of entries) {
  //       if (AttributeStub.isMarkStub(n)) {
  //         if (!selection || Range.isExpanded(selection) || !Path.equals(selection.anchor.path, p)) {
  //           console.log("remove_node");
  //           editor.exec({ type: "remove_node", path: p });
  //           break;
  //         } else if (selection.anchor.offset === 0) {
  //           console.log("move_back_remove_node");
  //           editor.exec({ type: "move_back_remove_node", path: p });
  //           break;
  //         }
  //       }
  //     }
  //   }
  //
  //   onChange(e);
  // };

  // const { insertText } = editor
  //
  // editor.insertText = text => {
  //   const { selection } = editor;
  //   if (Range.isCollapsed(selection)) {
  //     // const { path } = selection.anchor;
  //     // const node = Node.get(editor, path);
  //     // if (AttributeStub.isMarkStub(node)) {
  //     //   Editor.insertNode(editor, AttributeStub.createNodeFromStub(node, { text: text }), { split: true });
  //     //
  //     //   return editor;
  //     // }
  //     insertText(text);
  //   }
  // }

  // meburns all these exec need to change :(
  // https://docs.slatejs.org/general/changelog#0-57-0-december-18-2019
  // editor.exec = command => {
  //   // Handle inserting text into stub.
  //   if (command.type === "insert_text") {
  //     const { selection } = editor;
  //     if (Range.isCollapsed(selection)) {
  //       const { path } = selection.anchor;
  //       const node = Node.get(editor, path);
  //       if (AttributeStub.isMarkStub(node)) {
  //         Editor.insertNodes(editor, AttributeStub.createNodeFromStub(node, { text: command.text }), { split: true });
  //
  //         return editor;
  //       }
  //     }
  //   }
  //
  //   if (command.type === "move_back_remove_node") {
  //     // We don't want to move back if we are at the start of the document.
  //     const editorStart = {
  //       path: Array.from([0, 0]),
  //       offset: 0
  //     };
  //     const { anchor, focus } = editor.selection;
  //     const selectionStart = Point.compare(anchor, focus) >= 0 ? anchor : focus;
  //     if (Point.compare(editorStart, selectionStart) > 0) {
  //       Editor.move(editor, { distance: 1, unit: "character", reverse: true });
  //       Editor.removeNodes(editor, { at: command.path });
  //     }
  //   }
  //
  //   if (command.type === "remove_node") {
  //     Editor.removeNodes(editor, { at: command.path });
  //   }
  //
  //   if (command.type === "set_font_size") {
  //     const { fontSize } = command;
  //     if (!constants.fontSizes.includes(fontSize)) return;
  //
  //     setTextAttributes(editor, { fontSize });
  //   }
  //
  //   if (command.type === "set_font_family") {
  //     const { fontFamily } = command;
  //     if (!constants.fonts.map(f => f.fontFamily).includes(fontFamily)) return;
  //
  //     setTextAttributes(editor, { fontFamily });
  //   }
  //
  //   if (command.type === "toggle_mark") {
  //     const { mark } = command;
  //     if (!constants.marks.includes(mark)) return;
  //
  //     const isActive = isMarkActive(editor, mark);
  //     setTextAttributes(editor, { [mark]: isActive ? undefined : true });
  //   }
  //
  //   return exec(command);
  // };

  return editor;
}

export default withRichText;
