import React, { useEffect, useState } from "react";
import { organizationsData } from "bild-data";
import { filterList } from "bild-utils";
import { toaster } from "../../../presentational/toasts/toasts.js";
import { Grid, Typography, Hidden, List, ListItem, Button, Accordion, AccordionDetails, AccordionSummary, Tooltip, ClickAwayListener, Popper, Paper } from "@mui/material";

import AdmissionsCommendDialog from "./admissionsCommendDialog.js";
import AdmissionsConfirmDialog from "./admissionsConfirmDialog.js";
import AdmissionsEditDialog from "./admissionsEditDialog.js";
import "./admissions.css";

// ORGANIZATION ADMISSIONS
function OrganizationAdmissions({ canCommend, admissionUsers, availablePrograms, organizationName, organizationId, filterValue, updateUsers }) {
  const [users, setUsers] = useState([]);
  const [commendDialog, setCommendDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentAppId, setCurrentAppId] = useState(null);
  const [currentName, setCurrentName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [currentDegreeId, setCurrentDegreeId] = useState(null);
  const [expansionGroup, setExpansionGroup] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openList, setOpenList] = useState(false);

  useEffect(()=>{
    if (filterValue) {
      setUsers(filterList(admissionUsers, "name", filterValue));
    } else {
      setUsers(admissionUsers);
    }
  },[filterValue]);

  function openUserList(e, userId, appId, name, email, degree) {
    setAnchorEl(e.currentTarget);
    setOpenList(!openList);
    setCurrentUserId(userId);
    setCurrentAppId(appId);
    setCurrentName(name);
    setCurrentEmail(email);
    setCurrentDegreeId(degree);
  }

  function toggleCommendDialog() {
    setCommendDialog(!commendDialog);
  }

  function toggleConfirmDialog() {
    setConfirmDialog(!confirmDialog);
  }

  function fillConfirm(userId, appId, name, email, degree) {
    toggleConfirmDialog();
    setCurrentUserId(userId);
    setCurrentAppId(appId);
    setCurrentName(name);
    setCurrentEmail(email);
    setCurrentDegreeId(degree);
  }

  function toggleEditDialog() {
    setOpenList(false);
    setEditDialog(!editDialog);
  }

  function updateExpanded(val) {
    let eg = [...expansionGroup];
    if (eg.includes(val)) {
      eg = eg.filter(el => el !== val);
    } else {
      eg.push(val);
    }
    setExpansionGroup(eg);
  }

  function submitCommendation(name, email, degreeId) {
    toggleCommendDialog();

    let sendData = {
      name: name,
      emailAddress: email,
      admissions: [
        {
          programId: degreeId
        }
      ]
    };

    organizationsData.modifyAdmissionsUser(organizationId, sendData, data => {
      updateUsers(data);
      toaster.success("Student successfully commended!");
    });
  }

  function submitConfirmation(userId, appId, degreeId) {
    toggleConfirmDialog();

    let sendData = {
      id: userId,
      admissions: [
        {
          id: appId,
          programId: degreeId
        }
      ]
    };

    organizationsData.modifyAdmissionsUser(organizationId, sendData, data => {
      updateUsers(data);
      toaster.success("Student successfully commended!");
    });
  }

  function resendEmail() {
    setOpenList(false);

    let sendData = {
      id: currentUserId,
      emailAddress: currentEmail,
      sendEmail: true
    };

    organizationsData.modifyAdmissionsUser(organizationId, sendData, data => {
      updateUsers(data);
      toaster.success("Student email successfully resent.");
    });
  }

  function submitEdit(userId, appId, name, email, degreeId) {
    toggleEditDialog();

    let sendData = {
      id: userId,
      name: name,
      emailAddress: email,
      admissions: [
        {
          id: appId,
          programId: degreeId
        }
      ]
    };

    organizationsData.modifyAdmissionsUser(organizationId, sendData, data => {
      updateUsers(data);
      toaster.success("Student successfully updated.");
    });
  }

  function getUsers() {
    const userRows = [];
    userRows.push(
      <ListItem key="00" className="admissions-table-header">
        <Grid container>
          <Grid container item xs={4}>
            <Grid item sm={5} xs={12}>
              <Typography variant="subtitle1">Student</Typography>
            </Grid>
            <Hidden lgDown>
              <Grid item lg={7}>
                <Typography variant="subtitle1">Email</Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container justifyContent="flex-start" item xs={8}>
            <Grid item md={5} sm={6} xs={6}>
              <Typography variant="subtitle1">Program</Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <Typography variant="subtitle1">Status</Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item sm={4}>
                <Typography variant="subtitle1" className="commendation-header">
                  Commendation
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </ListItem>
    );

    const asFields = ["applicationAssessment", "background", "proofOfAcademicAbility", "officialTranscripts", "initialPayments", "deanApproval"];
    // TODO: this should be coming from the backend
    const asText = ["Application Assessment", "Background", "Proof of Academic Ability", "Official Transcripts", "Initial Payments", "Dean Approval"];
    for (let i = 0; i < users.length; i++) {
      const u = users[i] || {};
      const userId = u.id;
      const name = u.name;
      const email = u.emailAddress;
      let programDetails = [];
      let schoolDetails = [];
      let hasComments = false;
      const programStatuses = [];
      for (let j = 0; j < u.admissions.length; j++) {
        const e = u.admissions[j];
        const appId = e["id"];
        const degree = e["programClassName"];
        const degreeId = e["programId"];
        const status = e["status"];

        let stProgressBar = e["personalInformation"] + e["degreeProgram"] + e["backGround"] + e["academicProof"] + e["identity"] + e["finalSteps"];
        let asProgressTotal = e["applicationAssessmentShow"] + e["backgroundShow"] + e["proofOfAcademicAbilityShow"] + e["officialTranscriptsShow"] + e["initialPaymentsShow"] + e["deanApprovalShow"];
        let asProgressCompleted = e["applicationAssessmentValue"] + e["backgroundValue"] + e["proofOfAcademicAbilityValue"] + e["officialTranscriptsValue"] + e["initialPaymentsValue"] + e["deanApprovalValue"];
        hasComments = e["applicationAssessmentComment"] || e["backgroundComment"] || e["proofOfAcademicAbilityComment"] || e["officialTranscriptsComment"] || e["initialPaymentsComment"] || e["deanApprovalComment"];
        // Try some clever math to get the percentage of available School steps completed
        let asProgressBar = Number(Math.floor((asProgressCompleted / asProgressTotal) * 10) / 10).toFixed(2);
        asProgressBar = isNaN(asProgressBar) ? 0 : (asProgressBar * 6).toFixed(0);

        let commendationStatus = e["commended"] ? (
          <i className="fas fa-check commended" />
        ) : (
          <Hidden xsUp={!canCommend}>
            <Button
              className="admissions-confirm"
              onClick={() => {
                fillConfirm(userId, appId, name, email, degreeId);
              }}
            >
              <Typography>Commend</Typography>
            </Button>
          </Hidden>
        );

        // If this is a "paper" application, only show if it has been submitted
        if (e["paperApplication"]) {
          // reset the stProgress bar to just the paper application fields
          stProgressBar = e["paperApplicationComplete"] * 6;
          programDetails.push(
            <Grid container key={j} className="admissions-detail" justifyContent="flex-start" alignItems="stretch">
              <Grid item className={"detail-item " + e["paperApplicationComplete"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Paper Application Received</Typography>
              </Grid>
            </Grid>
          );
        } else {
          // Otherwise, show normal online submission s
          programDetails.push(
            <Grid container key={j} className="admissions-detail" justifyContent="center" alignItems="stretch">
              <Grid item className={"detail-item " + e["personalInformation"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Personal Information</Typography>
              </Grid>
              <Grid item className={"detail-item " + e["degreeProgram"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Degree Program</Typography>
              </Grid>
              <Grid item className={"detail-item " + e["backGround"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Background</Typography>
              </Grid>
              <Grid item className={"detail-item " + e["academicProof"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Academic Proof</Typography>
              </Grid>
              <Grid item className={"detail-item " + e["identity"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Identity</Typography>
              </Grid>
              <Grid item className={"detail-item " + e["finalSteps"]} md={2} sm={4} xs={6}>
                <Typography variant="body2">Final Steps</Typography>
              </Grid>
            </Grid>
          );
        }

        for (let asf = 0; asf < asFields.length; asf++) {
          let field = asFields[asf];
          let fieldClass = "detail-item " + e[`${field}Value`] + " " + (e[`${field}Comment`] ? "with-comment" : "");

          let content = <Typography variant="body2">{asText[asf]}</Typography>;
          if (e[`${field}Comment`]) {
            content = (
              <Tooltip disableFocusListener title={e[`${field}Comment`]} placement="bottom" interactive>
                <Typography variant="body2">{asText[asf]}</Typography>
              </Tooltip>
            );
          }

          if (e[`${field}Show`]) {
            schoolDetails.push(
              <Grid item className={fieldClass} md={2} sm={4} xs={6} key={asf}>
                {content}
              </Grid>
            );
          }
        }

        let statusSection = null;
        if (u["applicationStarted"]) {
          let progressBar = parseInt(stProgressBar, 10) + parseInt(asProgressBar, 10);
          statusSection = (
            <Grid container>
              <Grid
                item
                xs={12}
                className={"status " + (hasComments ? "with-comment" : "")}
                onClick={() => {
                  updateExpanded(i);
                }}
              >
                <div className={`student-progress progress size-${progressBar} ${status}`}>
                  <Typography variant="caption">
                    {status} <i className={`fas fa-chevron-${expansionGroup.includes(i) ? "up" : "down"}`} />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          );
        } else {
          statusSection = (
            <div className="not-started">
              <Typography variant="caption">{status}</Typography>
            </div>
          );
        }

        programStatuses.push(
          <Grid item container key={j} alignItems="baseline">
            <Grid item container md={5} sm={6} xs={8} className="program">
              <Typography>{degree}</Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={4}>
              {statusSection}
            </Grid>
            <Grid item md={2} sm={6} xs={6} className="commendation-status">
              {commendationStatus}
            </Grid>
            <Grid item md={2} sm={6} xs={6} className="admissions-submenu">
              <Button
                onClick={ev => {
                  openUserList(ev, userId, appId, name, email, degreeId);
                }}
                className={e["editEnabled"] ? "" : "hidden"}
              >
                <i className="fal fa-ellipsis-h fa-2x" />
              </Button>
            </Grid>
          </Grid>
        );
      }

      let expansionDetails = [];
      if (programDetails.length > 0) {
        expansionDetails.push(
          <AccordionDetails key={0}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={1} xs={12}>
                <Typography variant="h6">Student:</Typography>
              </Grid>
              <Grid item md={11} xs={12}>
                {programDetails}
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }
      if (schoolDetails.length > 0) {
        expansionDetails.push(
          <AccordionDetails key={1}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={1} xs={12}>
                <Typography variant="h6">Antioch School:</Typography>
              </Grid>
              <Grid item md={11} xs={12}>
                <Grid container className="school-detail" justifyContent="flex-start" alignItems="stretch">
                  {schoolDetails}
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        );
      }

      userRows.push(
        <ListItem className="admissions-item" key={i}>
          <Grid container alignItems="baseline">
            <Grid item xs={12}>
              <Accordion className="admissions-panel" expanded={expansionGroup.includes(i)}>
                <AccordionSummary className="admissions-summary">
                  <Grid container alignItems="baseline" className="admissions-line">
                    <Grid item container alignItems="center" content="flex-start" sm={4} xs={12}>
                      <Grid item lg={5} md={12} sm={12} xs={12} className="student">
                        <Typography variant="body1">{name}</Typography>
                      </Grid>
                      <Grid item lg={7} md={12} sm={12} xs={12} className="email">
                        <Typography variant="body1">{email}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" sm={8} xs={12} className="program-statuses">
                      {programStatuses}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                {expansionDetails}
              </Accordion>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    if (userRows.length < 1) {
      userRows.push(
        <ListItem key="1" className="admissions-empty">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">No admissions found.</Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    return <List disablePadding>{userRows}</List>;
  }

  return (
    <Grid container className="admissions-organizations">
      <Grid item xs={12} className="list-actions">
        {canCommend && (
          <Button
            className="admissions-commend"
            onClick={() => {
              toggleCommendDialog();
            }}
          >
            <Typography>Commend a New Student</Typography>
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        {getUsers()}

        <AdmissionsCommendDialog
          open={commendDialog}
          availablePrograms={availablePrograms}
          onCancel={toggleCommendDialog}
          onSubmit={submitCommendation}
        />
        <AdmissionsConfirmDialog
          open={confirmDialog}
          availablePrograms={availablePrograms}
          defaultName={currentName}
          defaultEmail={currentEmail}
          userId={currentUserId}
          appId={currentAppId}
          defaultDegreeId={currentDegreeId}
          onCancel={toggleConfirmDialog}
          onSubmit={submitConfirmation}
        />
        <AdmissionsEditDialog
          open={editDialog}
          availablePrograms={availablePrograms}
          defaultName={currentName}
          defaultEmail={currentEmail}
          userId={currentUserId}
          appId={currentAppId}
          defaultDegreeId={currentDegreeId}
          onCancel={toggleEditDialog}
          onSubmit={submitEdit}
        />
        <Popper open={openList} anchorEl={anchorEl} placement="bottom">
          <ClickAwayListener
            onClickAway={() => {
              setOpenList(false);
            }}
          >
            <Paper>
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" className="admission-user-actions">
                <Grid item xs={12}>
                  <Button
                    className="admissions-popper-button"
                    onClick={() => {
                      toggleEditDialog();
                    }}
                  >
                    <Typography>Edit</Typography>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="admissions-popper-button"
                    onClick={() => {
                      resendEmail();
                    }}
                  >
                    <Typography>Resend Commendation Email</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Grid>
    </Grid>
  );
}

export default OrganizationAdmissions;
