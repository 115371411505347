import React, { useState } from "react";
import { colors, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Menu, MenuItem } from "@mui/material";
import AssessmentChangeLevelDialog from "./assessmentChangeLevelDialog.js";

const useStyles = makeStyles({});

function AssessmentDropdown({ user, userLearningItem, editable, changeLevel }) {
  const cls = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function _onClose() {
    setOpen(false);
    setAnchorEl(null);
  }

  return (
    <Grid container>
      <NormalButton
        variant="simple"
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
        disabled={!editable}
      >
        <i className="fal fa-ellipsis-h fa-2x" />
      </NormalButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
        >
          Change Assessment Level
        </MenuItem>
      </Menu>

      <AssessmentChangeLevelDialog open={open} onClose={_onClose} userLearningItem={userLearningItem} changeLevel={changeLevel} />
    </Grid>
  );
}

export default AssessmentDropdown;
