import React from "react";
import "./wrappers.css";
import classes from "./classes.js";

const c = classes.contentWrapper;

function ContentWrapper({ variant, className, noPadding, children}) {
  let rootClass = c.root;
  switch (variant) {
    case "flat":
      rootClass += " " + c.flat;
      break;
    default:
      break;
  }

  if (className) {
    rootClass += " " + className;
  }
  if (noPadding) {
    rootClass += " " + c.noPadding;
  }

  return <div className={rootClass}>{children}</div>;
}

export default ContentWrapper;
