import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, NormalButton, Blink } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: {
    border: `2px solid ${colors.white}`
  },
  dataSmall: {
    textAlign: "center"
  },
  nameWrapper: props => ({
    background: props.color,
    color: colors.white,
    fontWeight: "bold",
    padding: "0.5rem 0 0.5rem 1rem"
  }),
  name: {
    color: colors.white,
    fontWeight: "bold",
  },
  button: {
    padding: "0.5rem 0"
  },
  body: props => ({
    border: `2px solid ${props.color}`,
    padding: "0.5rem"
  })
});

function NetworkNodeDetails({ data }) {
  const color = data.color ? data.color : colors.gray;
  const cls = useStyles({ color });

  return (
    <Grid container className={cls.container}>
      <Grid container item xs={12} className={cls.nameWrapper} justifyContent="center" alignItems="center">
        <Grid item xs className={cls.name}>
          <BTypography variant="h6">{data.organization.organizationName}</BTypography>
        </Grid>
        {data.organization.groupLevel1Name && (
          <Grid item>
            <NormalButton component={Blink} dst="manage-organization" organization_id={data.organization.organizationId} tab={2} className={cls.button} variant="simple" labelColor={colors.white} hoverLabelColor={colors.white}>
              <i className="fas fa-users-class"/>
            </NormalButton>
          </Grid>
        )}
        <Grid item>
          <NormalButton component={Blink} dst="manage-organization" organization_id={data.organization.organizationId} tab={3} className={cls.button} variant="simple" labelColor={colors.white} hoverLabelColor={colors.white}>
            <i className="fas fa-external-link"/>
          </NormalButton>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <Grid item xs={8}></Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          Local
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          Total
        </Grid>
        <Grid item xs={8}>
          Users
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.eqpUsersWithAnyProgram}
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.subEqpUsersWithAnyProgram}
        </Grid>
        <Grid item xs={8}>
          Users w/ Progress
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.eqpUsersWithAnyProgress}
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.subEqpUsersWithAnyProgress}
        </Grid>
        <Grid item xs={8}>
          Users w/ 50% Progress
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.eqpUsersWith50PercentProgress}
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.subEqpUsersWith50PercentProgress}
        </Grid>
        <Grid item xs={8}>
          Users w/ (Lead/Entrust)
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.eqpUsersWithLeadOrEntrustProgram}
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.subEqpUsersWithLeadOrEntrustProgram}
        </Grid>
        <Grid item xs={8}>
          Users w/ Certificate
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.eqpUsersWithAnyCertificate}
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.subEqpUsersWithAnyCertificate}
        </Grid>
        <Grid item xs={8}>
          Shepherd Assessments Waiting
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.eqpShepherdAssessmentsWaiting}
        </Grid>
        <Grid item xs={2} className={cls.dataSmall}>
          {data.subEqpShepherdAssessmentsWaiting}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NetworkNodeDetails;
