import React, { useState } from "react";
import { AssessButton, LoadingWrapper } from "bild-ui";
import { Grid } from "@mui/material";

function CheckmarkCompletion({ completed, submitCompleted, editable }) {
  const [isReady, setIsReady] = useState(true);

  function _submitCompleted() {
    if (isReady) {
      setIsReady(false);
      submitCompleted();
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <LoadingWrapper loading={!isReady && !completed}>
          <AssessButton
            variant="squared"
            enabled={!completed && editable && isReady}
            content={
              <>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <i className="fal fa-check" style={{ fontWeight: "bold", paddingRight: ".5rem" }} />
                  </Grid>
                  <Grid item>
                    {completed ? "Completed!" : "Finish Step"}
                  </Grid>
                </Grid>
              </>
            }
            onClick={_submitCompleted}
          />
        </LoadingWrapper>
      </Grid>
    </Grid>
  );
}

export default CheckmarkCompletion;
