import React, { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { userLearningItemData, userData, driveData, diskData } from "bild-data";
import queryString from "query-string";
import { setPageTitle, showIntercom } from "bild-utils";
import { EquipWrapper, LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import UserLearningItem from "equip/user-learning-item/userLearningItem.js";

function UserPathItemLoader() {
  const location = useLocation();
  const { user_path_item_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [fileLoading, setFileLoading] = useState(false);
  const [error, setError] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [userLearningItemId, setUserLearningItemId] = useState(null);
  const [userLearningItem, setUserLearningItem] = useState(null);
  const [userPathItemId, setUserPathItemId] = useState(null);
  const [userPathItem, setUserPathItem] = useState(null);
  const [docId, setDocId] = useState(null);
  const [color, setColor] = useState(null);
  const [backgroundURL, setBackgroundURL] = useState(null);
  const [editable, setEditable] = useState(false);
  const [completed, setCompleted] = useState(false);
  const user = userData.getOverview().user;

  useEffect(() => {
    function _setError(e) {
      setError(e.response.data.error);
    }

    const sParams = queryString.parse(location.search);
    if (sParams && sParams.bc) {
      setBreadcrumb(sParams.bc);
    }

    // Need userPathItemId for all BE calls
    setUserPathItemId(parseInt(user_path_item_id));

    function _setItems(data) {
      let userLearningItem = data.userLearningItem;
      setPageTitle(userLearningItem.name);
      setUserPathItem(data);
      setUserLearningItemId(userLearningItem.id);
      setUserLearningItem(userLearningItem);
      setColor(data.programColor ? data.programColor.webFrontendValue : userLearningItem.userLearningItemColor.webFrontendValue);
      setBackgroundURL(data.backgroundImageURL ? data.backgroundImageURL : null);
      setEditable(user.id === userLearningItem.user.id);
      setCompleted(data.completed);
      setLoading(false);
    }

    userLearningItemData.loadFromUserPathItem(user_path_item_id, _setItems, _setError);
  }, []);

  function attach(selectedFiles) {
    if (editable) {
      const plural = selectedFiles.length > 1 ? "s" : "";

      userLearningItemData.attachArtifact(
        userLearningItemId,
        userPathItemId,
        selectedFiles,
        // Success
        data => {
          showIntercom();
          toaster.success("Artifact" + plural + " successfully attached.");

          setUserLearningItem(data);
        },
        // Failure
        () => {
          showIntercom();
        }
      );
    }
  }

  function remove(selectedFiles) {
    if (editable) {
      userLearningItemData.removeArtifact(
        userLearningItemId,
        userPathItemId,
        selectedFiles,
        // Success
        data => {
          toaster.success("Artifact successfully removed.");

          setUserLearningItem(data);
        },
        // Failure
        () => {}
      );
    }
  }

  function createDocument() {
    if (editable) {
      const fileName = userLearningItem ? userLearningItem.name : "~Untitled~";
      driveData.createDocument(
        fileName,
        tempLocalId => {
          setDocId(tempLocalId);
        },
        () => {
          console.log("Document was not able to be created locally");
        },
        // The new file needs to be attached to this userLearningItem
        (id, file) => {
          if (file.inAppLocalId === id) {
            attach([{ id: file.id }]);
          }
        }
      );
    }
  }

  function _setDocId(id) {
    setDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setFileLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setFileLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setFileLoading(false);
        }
      );
    }
  }

  function submitScore(score, comment, success_cb) {
    let scores = score.map(x => {
      return { criteria: { id: x.id }, isPassed: x.isPassed };
    });
    let assessment = {
      userLearningItemId: userLearningItemId,
      user: { id: user.id, name: user.name },
      completionLevel: { id: userLearningItem.completionLevel.id },
      rubric: { id: userLearningItem.rubric.id },
      assessmentScores: scores,
      comment: comment
    };
    userLearningItemData.submitAssessment(
      userLearningItemId,
      userPathItemId,
      assessment,
      // Success
      data => {
        toaster.success("Assessment Received!");
        setUserLearningItem(data);
        if (typeof success_cb === "function") success_cb();
      },
      // Failure
      () => {}
    );
  }

  function markCompleted(success_cb) {
    if (editable) {
      let newUserLearningItem = {
        id: userLearningItemId,
        userPathItemId: userPathItemId,
        checkoffs: [{ user: { id: user.id }, completionLevel: { id: userLearningItem.completionLevel.id }, isPassed: true }]
      };
      userLearningItemData.submitCompletion(
        userLearningItemId,
        newUserLearningItem,
        // Success
        data => {
          toaster.success("Marked As Complete!");
          setUserLearningItem(data);
          setCompleted(true); // TODO this should be returned from the userPathItem data
          if (typeof success_cb === "function") success_cb();
        },
        // Failure
        () => {}
      );
    }
  }

  function submitReport(values, success_cb) {
    let entries = values.map(x => {
      return {
        dataReportId: userLearningItem.dataReport.id,
        userLearningItemId: userLearningItemId,
        user: { id: user.id, name: user.name },
        str1Value: x.str1Value,
        str2Value: x.str2Value,
        str3Value: x.str3Value,
        str4Value: x.str4Value,
        str5Value: x.str5Value,
        num1Value: x.num1Value,
        num2Value: x.num2Value,
        num3Value: x.num3Value,
        num4Value: x.num4Value,
        num5Value: x.num5Value,
        comment: x.comment
      };
    });
    let data = {
      id: userLearningItemId,
      userPathItemId: userPathItemId,
      dataReport: {
        entries: entries
      }
    };
    userLearningItemData.submitReport(
      userLearningItemId,
      userPathItemId,
      data,
      // Success
      data => {
        toaster.success("Report Received!");
        setUserLearningItem(data);
        setCompleted(true); // TODO this should be returned from the userPathItem data
        if (typeof success_cb === "function") success_cb();
      },
      // Failure
      () => {}
    );
  }

  function changeLevel(userLearningItemId, level, success_cb) {
    userLearningItemData.sumbitChangeLevel(
      userLearningItemId,
      userPathItemId,
      level,
      // Success
      data => {
        toaster.success("Completion Level Updated!");
        setUserLearningItem(data);
        if (typeof success_cb === "function") success_cb();
      },
      // Failure
      () => {}
    );
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <EquipWrapper>
      <UserLearningItem
        userLearningItem={userLearningItem}
        userPathItem={userPathItem}
        breadcrumb={breadcrumb}
        changeLevel={changeLevel}
        editable={editable}
        createDocument={createDocument}
        attach={attach}
        remove={remove}
        user={user}
        docId={docId}
        setDocId={_setDocId}
        fileLoading={fileLoading}
        submitScore={submitScore}
        markCompleted={markCompleted}
        submitReport={submitReport}
        completed={completed}
        color={color}
        backgroundURL={backgroundURL}
      />
    </EquipWrapper>
  );
}

export default UserPathItemLoader;
