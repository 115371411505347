import { useState } from "react";

// Custom hook to manage the current root node, synchronize the breadcrumb values, and handle searching.
export const useSearchRoot = function(disk) {
  // State variable keeps track of root node, searchTerm, and breadcrumbs.
  const [_state, _setState] = useState({ rootID: disk.root.id, searchTerm: null });

  // Revealed setters.
  function setRoot(newRoot) {
    _setState({
      rootID: newRoot.id
    });
  }

  function setSearchTerm(newSearchTerm) {
    if (newSearchTerm === "") {
      _setState({
        rootID: disk.root.id
      });
      return;
    }

    _setState({
      rootID: disk.root.id,
      searchTerm: newSearchTerm
    });
  }

  const root = disk.folderIDLookup.get(_state.rootID);
  const crumbs = _state.searchTerm ? disk.searchCrumb(_state.searchTerm) : root.getCrumbs();

  return [root, setRoot, _state.searchTerm, setSearchTerm, crumbs];
};

export const useContextMenu = function() {
  const [contextMenuProps, setContextMenuProps] = useState({});

  const openContextMenu = (item, anchorEl, x, y) => {
    setContextMenuProps({ open: true, item: item, anchorEl: anchorEl, x: x, y: y, onClose: closeContextMenu });
  };

  const closeContextMenu = () => setContextMenuProps({ open: false });

  return [contextMenuProps.item, contextMenuProps, openContextMenu];
};

export const useRenameDialog = function(contextRoot, item, onRenameItem) {
  const [dialogProps, setDialogProps] = useState({ open: false });

  function _closeDialog() {
    setDialogProps({ open: false });
  }

  function _onRenameItemSubmit(name) {
    if (onRenameItem) {
      onRenameItem(item, name);
      _closeDialog();
    }
  }

  function onRenameItemIntent() {
    setDialogProps({ open: true, variant: "rename", item: item, onSubmit: _onRenameItemSubmit, onCancel: _closeDialog });
  }

  return [dialogProps, onRenameItemIntent];
};

export const useAddFolderDialog = function(contextRoot, onCreateFolder) {
  const [dialogProps, setDialogProps] = useState({ open: false });

  function _closeDialog() {
    setDialogProps({ open: false });
  }

  function _onAddFolderSubmit(name) {
    if (onCreateFolder) {
      onCreateFolder(contextRoot, name);
      _closeDialog();
    }
  }

  function onAddFolderIntent() {
    setDialogProps({ open: true, variant: "create-folder", onSubmit: _onAddFolderSubmit, onCancel: _closeDialog });
  }

  return [dialogProps, onAddFolderIntent];
};

export const useMoveDialog = function(item, onMoveItem) {
  const [dialogProps, setDialogProps] = useState({ open: false });

  function _close() {
    setDialogProps({ open: false });
  }

  function _submitMoveDest(destination) {
    if (onMoveItem) {
      onMoveItem(item, destination);
      _close();
    }
  }

  function onMoveIntent(item) {
    setDialogProps({ open: true, title: "Select the destination folder:", onSubmit: _submitMoveDest, onClose: _close });
  }

  return [dialogProps, onMoveIntent];
};
