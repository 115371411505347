import React from "react";
import { Dialog, Grid, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { BTypography, NormalButton, colors } from "bild-ui";

export default function UserAdminStorageUpgradeDialog({ open, onClose, user, updateUserStorage }) {

  function _upgradeUserStorage() {
    updateUserStorage(
      {
        "id": user.id,
        "upgradeStorageTier": true
      }
    );
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <BTypography variant="h5">Confirm Upgrade</BTypography>
      </DialogTitle>
      <DialogContent>
        <BTypography variant="subtitle1" style={{ textAlign: "center" }}>
          <br/>Upgrade <b>{user.name}</b>'s Storage
        </BTypography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              variant="secondary"
              color={colors.white}
              labelColor={colors.gray}
              borderColor={colors.gray}
              hoverColor={colors.darkGray}
              onClick={onClose}
            >
              Cancel
            </NormalButton>
          </Grid>
          <Grid item style={{ padding: "1rem" }}>
            <NormalButton
              variant="primary"
              color={colors.green}
              labelColor={colors.white}
              hoverColor={colors.green}
              onClick={_upgradeUserStorage}
            >
              Confirm Upgrade
            </NormalButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

}