import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { AreaChartBuilder, BTypography, FilterTextField, NormalButton, SortableList, UserAvatar, colors } from "bild-ui";

const useStyles = makeStyles({
  headerWrapper: { background: colors.white, padding: "0.5rem", position: "sticky", top: "0", zIndex: "201" },
  emptyHeader: { minWidth: "10rem" },
  sectionWrapper: {
    padding: "0.5rem 0",
  },
  sectionWrapperLeft: { paddingRight: "0.5rem" },
  section: {
    background: colors.white,
    borderRadius: "0.2rem"
  },
  sectionHeader: {
    padding: "0.5rem 1rem",
    textAlign: "center",
  },
  sectionContent: {},
  actionItems: {
  },
  itemButtonRight: { padding: "0 0.25rem" },
  lastDate: { fontWeight: "bold" },
  pathTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "2",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  groupTitle: { color: colors.bildGray },
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "2",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  middleColItem: { paddingLeft: "1rem" },
  filterTotal: { color: colors.darkerGray },
});

export default function ShepherdsReview({ actionItems, shepherds, assessmentData }) {
  const cls = useStyles();
  const [_actionItems, _setActionItems] = useState(null);
  const [_shepherdItems, _setShepherdItems] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    // map actionItems
    if (actionItems) {
      _setActionItems(
        actionItems
          .filter(x => !filter
            || x.userPath.title.toLowerCase().includes(filter.toLowerCase())
            || x.userLearningItem.name.toLowerCase().includes(filter.toLowerCase())
            || x.userLearningItem.shepherd.name.toLowerCase().includes(filter.toLowerCase())
            || x.user.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map(x => [
            [<BTypography variant="body2" className={cls.lastDate} sortval={x.userLearningItem.updatedAt} key={"00"}>{x.userLearningItem.updatedAt}</BTypography>],
            [
              <NormalButton
                variant="simple"
                dst="manage-user"
                user_id={x.userLearningItem.shepherd.id}
                sortval={x.userLearningItem.shepherd.name}
                key={x.userLearningItem.shepherd.id}
              >
                <UserAvatar size={30} src={x.userLearningItem.shepherd.avatarURL} name={x.userLearningItem.shepherd.name} nameVariant={"body2"} srcSize="small" badgeSrc={x.userLearningItem.shepherd.badgeURL} />
              </NormalButton>
            ],
            [
              <NormalButton
                variant="simple"
                dst="manage-user"
                user_id={x.user.id}
                sortval={x.user.name} 
                key={x.user.id} 
              >
                <UserAvatar size={30} src={x.user.avatarURL} name={x.user.name} nameVariant={"body2"} srcSize="small" badgeSrc={x.user.badgeURL}/>
              </NormalButton>
            ],
            [
              <Grid container justifyContent="flex-end" alignItems="flex-end" sortval={x.userLearningItem.name} key={"00"}>
                <Grid item>
                  <NormalButton
                    variant="simple"
                    dst="equip-user-path-item"
                    user_path_item_id={x.userLearningItem.userPathId}
                    className={cls.link}
                    sortval={`${x.learningGroup.name} - ${x.userLearningItem.name}`}
                    key={x.userLearningItem.userPathId}
                  >
                    <Grid container item xs>
                      <Grid item xs={12} className={cls.pathTitle} style={{ color: x.userPath.programColor ? x.userPath.programColor.webFrontendValue : colors.bildGray }}>
                        <BTypography variant="body2">{x.userPath.title}</BTypography>
                      </Grid>
                      <Grid item xs={12} className={cls.itemTitle}>
                        <BTypography variant="body1">{x.userLearningItem.name}</BTypography>
                      </Grid>
                    </Grid>
                    <Grid item className={cls.itemButtonRight}>
                      <i className="fas fa-chevron-right" />
                    </Grid>
                  </NormalButton>
                </Grid>
              </Grid>
            ],
          ])
      );
    }
  }, [filter, actionItems]);

  useEffect(() => {
    // map actionItems
    if (shepherds) {
      _setShepherdItems(
        shepherds
          .filter(x => !filter
            || x.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map(x => [
            [
              <NormalButton
                variant="simple"
                dst="manage-user"
                user_id={x.id}
                sortval={x.name}
                key={x.id}
              >
                <UserAvatar size={30} src={x.avatarURL} name={x.name} srcSize="small" badgeSrc={x.badgeURL} />
              </NormalButton>
            ],
            [<BTypography variant="body1" className={cls.middleColItem} sortval={x.outstandingAssessmentsCount} key={x.id}>{x.outstandingAssessmentsCount}</BTypography>],
            [<BTypography variant="body1" sortval={x.oldestWaitingDate ? x.oldestWaitingDate : "Dec 17, 1990"} key={"00"}>{x.oldestWaitingDate}</BTypography>]
          ])
      );
    }
  }, [filter, shepherds]);

  function makeModule(content) {
    return (
      <Grid container item xs={12} className={cls.module}>
        {content}
      </Grid>
    );
  }

  function getChartData() {
    let d = assessmentData;

    const tod = new Date();
    let month = tod.getMonth();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"];
    let hChartData = [];
    for (let i = 12; i > -1; i--) {
      let currentM = month - i < 1 ? month - i + 12 : month - i;
      let mon = months[currentM];
      let uvData = 0;
      let uvFilter = d.filter(x => x.type === "staff_any_shepherd_user_assessment_completion_history_totals" && parseInt(x.period) === currentM);
      if (uvFilter.length > 0) {
        uvData = uvFilter[0].periodCount;
      }
      hChartData.push({ name: mon, uv: uvData, uvName: "Shepherd Assessments Completed" });
    }

    return (<><AreaChartBuilder data={hChartData} fillColor={colors.green} pvFillColor={colors.lightBlue} xAxis="name" /></>);
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-start">
      <Grid container item xs={12} justifyContent="space-between" alignItems="center" className={cls.headerWrapper}>
        <Grid item className={cls.emptyHeader}>
          &nbsp;
        </Grid>
        <Grid item>
          <BTypography variant="h3">Shepherding Review</BTypography>
        </Grid>
        <Grid item>
          <FilterTextField
            value={filter}
            placeholder="Search"
            onChange={e => setFilter(e.target.value)}
            onClear={() => {
              setFilter("");
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xl={8} lg={7} md={6} sm={12}>
        <Grid container item xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          <Grid container item xs={12} className={cls.section}>
            <Grid item xs={12} className={cls.sectionHeader}>
              <BTypography variant="h6">
                Outstanding Assessments
                ({filter ? (<><i className={cls.filterTotal}>{_actionItems.length}</i>/</>) : ""}{actionItems.length})
              </BTypography>
            </Grid>
            {_actionItems && (
              <Grid container item xs={12} className={cls.actionItems}>
                <SortableList
                  perPage={6}
                  headers={_actionItems.length > 0 ? ["Waiting Since", "Shepherd", "Learner", "Item"] : []}
                  items={_actionItems}
                  spacing={[2, 2, 2, 6]}
                  itemPadding={"0.5rem 1rem"}
                  fixedHeight={"55vh"}
                  emptyMessage={<BTypography variant="h6">{actionItems.length > 0 ? "No Users/Items match the search criteria." : "There are no Outstanding Assessments to review."}</BTypography>}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xl={4} lg={5} md={6} sm={12}>
        <Grid container item xs={12} className={cls.sectionWrapper}>
          <Grid container item xs={12} className={cls.section}>
            <Grid item xs={12} className={cls.sectionHeader}>
              <BTypography variant="h6">
                Shepherds
                ({filter ? (<><i className={cls.filterTotal}>{_shepherdItems.length}</i>/</>) : ""}{shepherds.length})
              </BTypography>
            </Grid>
            {_shepherdItems && (
              <Grid container item xs={12} className={cls.sectionContent}>
                <SortableList
                  perPage={8}
                  headers={_shepherdItems.length > 0 ? ["Shepherd", "Total Waiting", "Oldest Waiting Date"] : []}
                  items={_shepherdItems}
                  itemPadding={"0.5rem 1rem"}
                  defaultSortCol={1}
                  defaultSortDir={1}
                  spacing={[5, 3, 4]}
                  fixedHeight={"55vh"}
                  emptyMessage={<BTypography variant="h6">{shepherds.length > 0 ? "No Shepherds match the search criteria." : "There are no Shepherds to review."}</BTypography>}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.section}>
        <Grid item xs={12} className={cls.sectionHeader}>
          <BTypography variant="h6">
            Shepherd Assessment History
          </BTypography>
        </Grid>
        <Grid item xs={12}>
          {makeModule(getChartData())}
        </Grid>
      </Grid>
    </Grid>
  );
}
