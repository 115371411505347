import React, { useEffect, useState } from "react";
import { Blink, BTypography, colors, LineChartBuilder, List, NormalButton, SortableList } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip } from "@mui/material";
import { ENUMS } from "bild-data";

const useStyles = makeStyles({
  org: { "&:hover": { cursor: "pointer !important" } },
  popperArrow: { color: colors.bildBlue },
  tooltip: { backgroundColor: colors.lightGray, color: colors.black, border: `1px solid ${colors.bildBlue}` },
  rolesIcon: { color: colors.bildBlue },
  roles: { margin: "0", padding: "0.1rem 0.1rem 0.1rem 0.5rem" },
});

export default function UserOrganizations({ user }) {
  const cls = useStyles();
  const [orgItems, setOrgItems] = useState([]);

  function _listRoles(roles) {
    if (roles && roles.length > 0) {
      return (
        <Grid container className={cls.roles} justifyContent="center" alignItems="center">
          <ul className={cls.roles}>
            {roles.map(z => (
              <li key={z.id}>
                <BTypography variant="body1">{z.description}</BTypography>
              </li>
            ))}
          </ul>
        </Grid>
      );
    }
  }

  useEffect(()=>{
    if (user && user.organizations) {
      setOrgItems(
        user.organizations.filter(x => !x.archivedByOrganization).map(x => {
          let roles = x.roles.filter(y => y.id !== ENUMS.ROLES.STUDENT.ID);
          return [
            <Tooltip
              title={_listRoles(roles)}
              arrow
              placement="right"
              classes={{ arrow: cls.popperArrow, tooltip: cls.tooltip }}
              className={cls.org}
            >
              <span>
                {x.organizationName}
                {roles && roles.length > 0 && (
                  <>&nbsp; <i className={`${cls.rolesIcon} fal fa-registered`} /></>
                )}
              </span>
            </Tooltip>,
            <Grid container justifyContent={"flex-end"} alignItems={"center"}>
              <Grid item>
                <NormalButton
                  component={Blink}
                  dst="manage-organization"
                  organization_id={x.organizationId}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  className={cls.detailButton}
                >
                  Manage
                </NormalButton>
              </Grid>
            </Grid>
          ]
      }));
    }
  },[user]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <SortableList
          headers={[]}
          items={orgItems}
          itemPadding={"0.25rem 0.5rem"}
          spacing={[8,4]}
          emptyMessage={
            <>
              Not an active member of any Organization
            </>
          }
        />
      </Grid>
    </Grid>
  );
}
