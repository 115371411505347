import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Checkbox } from "@mui/material";
import Bbutton from "presentational/input/bbutton.js";
import SortButton from "presentational/input/sortButton.js";
import List from "presentational/lists/list.js";

import TraineesReviewCount from "./traineesReviewCount.js";

const useStyles = makeStyles({
  buttonWrapper: { width: "100%", textAlign: "right" },
  circle: {
    height: "1rem",
    width: "1rem",
    borderRadius: "50%",
    display: "inline-flex",
    alignItems: "center",
    marginRight: "0.25rem",
    background: "#029e02"
  },
  warning: { background: "orange" },
  danger: { background: "red" }
});

function TraineesReview({ trainees, offTrack, setOffTrack }) {
  const [state, setState] = useState({
    traineeName: "ascending",
    coachName: "off",
    awaitingReviewCount: "off",
    traineeProgress: "off",
    lastCompleted: "off",
    oldestWaiting: "off"
  });
  const [details, setDetails] = useState(false);
  const classes = useStyles();

  function sortTrainee(val) {
    let newState = {
      traineeName: "off",
      coachName: "off",
      awaitingReviewCount: "off",
      traineeProgress: "off",
      lastCompleted: "off",
      oldestWaiting: "off"
    };
    newState[val] = "ascending";

    trainees.sort((a, b) => a[val] - b[val]);
    if (val === "traineeName") trainees.sort((a, b) => a.trainee.name.localeCompare(b.trainee.name));
    if (val === "coachName") trainees.sort((a, b) => a.coach.name.localeCompare(b.coach.name));
    if (val === "traineeProgress") trainees.sort((a, b) => a.icon.localeCompare(b.icon) || a.trainee.name.localeCompare(b.trainee.name));
    if (val === "lastCompleted") trainees.sort((a, b) => new Date(a.traineeAssessmentNewestDate) - new Date(b.traineeAssessmentNewestDate));
    if (val === "oldestWaiting")
      trainees.sort((a, b) => new Date(a.trainingAssessmentOldestActiveDate) - new Date(b.trainingAssessmentOldestActiveDate));

    if (state[val] === "ascending") {
      newState[val] = "descending";
      trainees.reverse();
    }

    setState(newState);
  }

  if (trainees) {
    const listHeadings = [
      <Typography variant="h6">
        <SortButton
          value={state.traineeProgress}
          onChange={() => {
            sortTrainee("traineeProgress");
          }}
        />
        Trainee
        <SortButton
          value={state.traineeName}
          onChange={() => {
            sortTrainee("traineeName");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Coach
        <SortButton
          value={state.coachName}
          onChange={() => {
            sortTrainee("coachName");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Assessments
        <SortButton
          value={state.awaitingReviewCount}
          onChange={() => {
            sortTrainee("awaitingReviewCount");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Last Trainee Assessment
        <SortButton
          value={state.lastCompleted}
          onChange={() => {
            sortTrainee("lastCompleted");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Oldest Waiting Training Assessment
        <SortButton
          value={state.oldestWaiting}
          onChange={() => {
            sortTrainee("oldestWaiting");
          }}
        />
      </Typography>,
      ""
    ];
    let detailsButton = (
      <Grid container>
        <Grid item xs={12}>
          <label>
            Off-Track <Checkbox checked={offTrack} onChange={() => setOffTrack(!offTrack)} />
          </label>
        </Grid>
        <Grid item xs={12}>
          <label>
            Details <Checkbox checked={details} onChange={() => setDetails(!details)} />
          </label>
        </Grid>
      </Grid>
    );
    let listItems = trainees.map((t, i) => [
      <Typography variant="body1">
        <span className={`${classes.circle} ${t.icon === "1" ? classes.danger : t.icon === "2" ? classes.warning : ""}`}>&nbsp;</span>
        {t.trainee.name}
      </Typography>,
      <Typography variant="body1">{t.coach.name}</Typography>,
      <TraineesReviewCount
        showDetails={details}
        awaitingReviewCount={t.awaitingReviewCount}
        completedReviewCount={t.completedReviewCount}
        Days7Count={t.traineeAssessments7DaysCount}
        Days14Count={t.traineeAssessments14DaysCount}
        assessmentsWaiting={t.totalAssessmentsWaitingOnTrainee}
        requiringCoach={t.assessmentWaitingOnTraineeRequiringCoach}
        notRequiringCoach={t.assessmentWaitingOnTraineeNotRequiringCoach}
      />,
      <Typography variant="body1">{t.traineeAssessmentNewestDate}</Typography>,
      <Typography variant="body1">{t.trainingAssessmentOldestActiveDate}</Typography>,
      <div className={classes.buttonWrapper}>
        <Bbutton variant="submit" dst={"trainee_review"} user_id={t.trainee.id}>
          View Assessments
        </Bbutton>
      </div>
    ]);

    return <List title="Associate Faculty Trainees" details={detailsButton} headings={listHeadings} items={listItems} />;
  } else {
    return null;
  }
}

export default TraineesReview;
