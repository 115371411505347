import React from "react";
import { colors, NormalButton, IndeterminateProgress } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Artifact from "equip/artifact/artifact.js";

const useStyles = makeStyles({
  wrapper: { padding: "1rem 0" },
  back: { border: `1px solid ${colors.darkGray}` },
  backIcon: { padding: "0.5rem 1rem;", color: colors.darkGray },
  backText: { fontWeight: "bold" },
  artifact: {},
  artifactPadding: { padding: "1rem 0" }
});

function ArtifactEditWrapper({ docId, back, hideBack, editable, loading }) {
  const cls = useStyles();

  if (loading) return <IndeterminateProgress />;
  return (
    <Grid container className={cls.wrapper}>
      {!hideBack && (
        <NormalButton variant="simple" className={cls.back} onClick={back}>
          <i className={`fas fa-arrow-left ${cls.backIcon}`} />
        </NormalButton>
      )}
      <Grid item xs={12} className={`${cls.artifact} ${!hideBack ? cls.artifactPadding : ""}`}>
        <Artifact artifactId={docId} editable={editable} />
      </Grid>
    </Grid>
  );
}

export default ArtifactEditWrapper;
