import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, TreeDiagram, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";

import UpdateLocalEntityDialog from "./updateLocalEntityDialog.js";

const useStyles = makeStyles({
  diagram: { backgroundColor: colors.white, marginBottom: "1rem" },
  missingDiagram: { padding: "5rem", textAlign: "center" },
  buttonWrapper: { padding: "1rem" }
});

export default function AdminOrganizationNetwork({ organization, orgTree, allOrganizations, updateOrganization }) {
  const cls = useStyles();
  const [localEntityDialog, setLocalEntityDialog] = useState(false);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} className={cls.diagram}>
        <Grid container item xs={12}>
          <Grid item className={cls.buttonWrapper}>
            <NormalButton
              onClick={()=>{setLocalEntityDialog(true)}}
              variant={"secondary"}
              color={colors.transparent}
              borderColor={colors.bildBlue}
              labelColor={colors.bildBlue}
              hoverColor={colors.bildBlue}
              hoverLabelColor={colors.white}
            >
              <i className={`fas fa-cogs`} />
            </NormalButton>
          </Grid>
        </Grid>
        {orgTree && orgTree.length > 0 && <TreeDiagram data={orgTree} legend={true} highlightId={organization.organizationId} updateOrganization={updateOrganization} />}
        {(!orgTree || orgTree.length < 1) && (
          <Grid item xs={12} className={cls.missingDiagram}>
            <BTypography variant="h5">This Organization is not yet a part of a network.</BTypography>
          </Grid>
        )}
      </Grid>
      <UpdateLocalEntityDialog
        open={localEntityDialog}
        onClose={()=>{setLocalEntityDialog(false)}}
        organization={organization}
        allOrganizations={allOrganizations}
        onSubmit={updateOrganization}
      />
    </Grid>
  );
}
