function withValueChangeListener(editor) {
  // const { exec } = editor;
  //
  // editor.exec = command => {
  //   if (typeof editor.onValueChange === "function") editor.onValueChange();
  //   exec(command);
  // };

  return editor;
}

export default withValueChangeListener;
