import React from "react";

import { Blink } from "bild-ui";
import { Grid, Divider, Typography, List, ListItem } from "@mui/material";

import "./sima.css";

// SIMA REVIEW
function SimaReview({ stories }) {
  function getUsers() {
    let userList = [
      <ListItem key="00" className="sima-list-header">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">User</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Date Updated</Typography>
          </Grid>
        </Grid>
      </ListItem>
    ];

    for (let i = 0; i < stories.length; i++) {
      let competency = stories[i].reviewCompetencies[0];
      let competencyProof = competency.competencyProof;
      let user = competencyProof.user;
      userList.push(
        <Blink dst="course" course_id={competency.course.id} competency_proof_id={competencyProof.id} key={i}>
          <ListItem className="sima-review-item">
            <Grid container>
              <Grid item xs={6}>
                {user.name}
              </Grid>
              <Grid item xs={6}>
                {competencyProof.updatedAt}
              </Grid>
            </Grid>
          </ListItem>
        </Blink>
      );
    }

    return <List>{userList}</List>;
  }

  return (
    <Grid container className="sima-outer-container">
      <Grid item xs={12}>
        <Grid container className="sima-container">
          <Grid item xs={12}>
            <Typography variant="h5">SIMA Review</Typography>
            <Divider className="sima-divider" />
          </Grid>
          <Grid item xs={12}>
            {getUsers()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SimaReview;
