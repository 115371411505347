import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";

import { toaster } from "presentational/toasts/toasts.js";
import { Blink, NormalButton } from "bild-ui";
import { Grid, Typography, Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import UploadProgressModal from "containers/folio/components/uploadProgressModal.js";
import AssessmentStatus from "presentational/assessmentStatus/assessmentStatus.js";
import SimaFiles from "./components/simaFiles.js";

import "./sima.css";

// SIMA USER
function SimaUser({
  enrollmentId,
  course,
  competency,
  competencyProof,
  user,
  files,
  onAttach,
  onFileUpload,
  onFileRemove,
  onFinalizeSima,
  mapCompetency
}) {
  const [state, setState] = useState({ dialogOpen: false });

  function cancelAssessment() {
    setState({ dialogOpen: false });
  }

  function _onAttach(data) {
    onAttach(competency.id, data);
  }

  function _onFileUpload(data) {
    onFileUpload(competency.id, enrollmentId, data);
  }

  function _onFileRemove(data) {
    onFileRemove(competency.id, enrollmentId, data);
  }

  function finalizeAssessment() {
    onFinalizeSima(
      competency,
      () => {
        setState({ dialogOpen: false });
        toaster.success("Competency Proof Finalized");
      },
      () => {
        toaster.error("Competency Proof was not Finalized");
      }
    );
  }

  return (
    <Grid container className="sima-outer-container" alignItems="center">
      <Grid item xs={12}>
        <Grid container className="sima-container" alignItems="flex-start">
          <Grid item md={6} sm={12} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5">{user.name}</Typography>
              </Grid>
              <Grid item xs={12} className="competency-title">
                <Typography variant="subtitle1">{ReactHtmlParser(course.name)}</Typography>
                <Blink dst="course" course_id={course.id} enrollment_id={enrollmentId}>
                  <NormalButton variant="simple" className="default-download-button">
                    <Typography variant="body2">
                      {ReactHtmlParser(competency.name && !competency.empty ? competency.name : competency.description)}
                    </Typography>
                  </NormalButton>
                </Blink>
              </Grid>
              <Grid item xs={12}>
                <SimaFiles
                  userId={user.id}
                  course={course}
                  competency={competency}
                  competencyProof={competencyProof}
                  files={files}
                  onAttach={_onAttach}
                  onFileUpload={_onFileUpload}
                  onFileRemove={_onFileRemove}
                  mapCompetency={mapCompetency}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="sima-right-header">
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-end">
              {competencyProof && (
                <Grid item xs={12} className="sima-row">
                  <AssessmentStatus status={competencyProof.status} groupColor={course.courseGroupColor.webFrontendValue} />
                </Grid>
              )}
              {competencyProof && !competencyProof.complete && (
                <Grid item xs={12} className="sima-row">
                  <Button
                    className="default-submit-button"
                    onClick={() => {
                      setState({ dialogOpen: true });
                    }}
                  >
                    Finalize SIMA
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={state.dialogOpen} onClose={cancelAssessment}>
        <DialogTitle>{"Finalize this SIMA?"}</DialogTitle>
        <DialogActions>
          <Button onClick={cancelAssessment} className="default-cancel-button">
            Cancel
          </Button>
          <Button onClick={finalizeAssessment} className="default-submit-button">
            Finalize
          </Button>
        </DialogActions>
      </Dialog>
      <UploadProgressModal />
    </Grid>
  );
}

export default SimaUser;
