import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { reviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Review from "./review.js";

// REVIEW LOADER
function ReviewLoader({}) {
  const { review_type } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalReviewCount, setTotalReviewCount] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [reviewType, setReviewType] = useState(review_type);

  useEffect(()=>{
    setPageTitle("Review");

    function _setReviewSummary(data) {
      setReviewType(review_type);
      setTotalReviewCount(data.totalReviewCount);
      setOrganizations(data.organizations);
      setLoading(false);
    }

    reviewData.loadOrganizations(review_type, _setReviewSummary, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return <Review totalReviewCount={totalReviewCount} organizations={organizations} reviewType={reviewType} />;
  }
}

export default ReviewLoader;
