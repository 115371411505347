import React, { useEffect, useState } from "react";
import { userData, organizationsData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Organizations from "./organizations.js";

// ORGANIZATIONS LOADER
function OrganizationsLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userOverview, setUserOverview] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useEffect(()=>{
    setPageTitle("Organizations");
    setUserOverview(userData.getOverview() || []);

    function _setOrganizations(d) {
      setOrganizations(d.Organizations);
      setLoading(false);
    }

    organizationsData.load(_setOrganizations, setError);
  },[])

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return <Organizations organizations={organizations} showReports={userOverview.showOrganizationReports} />;
  }
}

export default OrganizationsLoader;
