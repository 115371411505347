import React, { useState, useEffect } from "react";
import { equipReviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Review from "./review.js";

function SharedReviewLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Shared Programs");
      setData(d);
      setPrograms(
        Array.from(new Set(d.learnersWithUserPaths.map(l => l.userPaths.map(up => up.title)).flat())).map(x => {
          return { id: x, name: x };
        })
      );
      let userIds = d.learnersWithUserPaths.map(l => l.user.id);
      setGroups(d.sharingGroups.filter(x => x.users.map(y => y.id).some(z => userIds.includes(z))));
      setLoading(false);
    }

    equipReviewData.loadShared(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return <Review name={"Equip Peer Programs"} learners={data.learnersWithUserPaths} programs={programs} groups={groups} shared={true} />;
}

export default SharedReviewLoader;
