import React from "react";
import { makeStyles } from "@mui/styles";
import ArtifactTiles from "equip/artifact/artifactTiles.js";
import ArtifactEditWrapper from "equip/artifact/artifactEditWrapper.js";
import { Grid } from "@mui/material";
import { PreviewFile } from "modules";

const useStyles = makeStyles({
  header: {}
});

function ArtifactWorkflow({ pathItemId, artifacts, docId, setDocId, previewFile, setPreviewFile, remove, editable, fileLoading, slimTiles, hideBack, isFullScreen }) {
  const classes = useStyles();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={classes.header}>
        {!docId && !previewFile && <ArtifactTiles pathItemId={pathItemId} artifacts={artifacts} viewDocument={setDocId} viewPreview={setPreviewFile} remove={remove} editable={editable} slim={slimTiles} />}
        {docId && (
          <ArtifactEditWrapper
            docId={docId}
            back={() => {
              setDocId(null);
            }}
            hideBack={hideBack}
            editable={editable}
            loading={fileLoading}
          />
        )}
        {previewFile && (
          <PreviewFile file={previewFile} isFullScreen={isFullScreen} />
        )}
      </Grid>
    </Grid>
  );
}

export default ArtifactWorkflow;
