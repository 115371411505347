import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import "index.css";
import { NormalButton, colors } from "bild-ui";

const useStyles = makeStyles({
  loginContainer: { paddingBottom: "20vh", height: "100%" },
  loginBox: { padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF", maxWidth: "500px" },
  logoContainer: { textAlign: "center" },
  logo: { width: "300px", paddingBottom: "20px" },
  formItemContainer: { margin: "10px 0px" },
  formItem: { height: "45px" },
  formOutline: {
    color: "#004882",
    "&:focus": { outline: "-webkit-focus-ring-color auto 5px !important" }
  },
  policyLinkOutline: {
    color: colors.darkerGray,
    "&:focus": { outline: "-webkit-focus-ring-color auto 5px !important" }
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#004882",
    color: "#FFF",
    "&:hover": { backgroundColor: "#023b69" },
    "&:active": { backgroundColor: "#012542" }
  },
  supportLinks: { textAlign: "right", marginTop: "16px" },
  supportLink: { color: "#006dc3", padding: "0px", margin: "0px", fontSize: "15px !important" }
});

function Login({ handleSubmit, handleForgotPassword, handleSupportRequest, ...props }) {
  const classes = useStyles();
  const [form, setForm] = useState({ username: "", password: "", remember_me: false });
  const [error, setError] = useState({});

  function _handleSubmit() {
    if (_validateForm()) {
      handleSubmit(form);
    }
  }

  function _validateForm() {
    if (form.username.length < 1) {
      setError({ username: "Please enter your email address" });
      return false;
    } else if (form.password.length < 1) {
      setError({ password: "Please enter your password" });
      return false;
    } else {
      setError({});
      return true;
    }
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.loginContainer}>
      <Grid item xs={12} className={classes.loginBox}>
        <form onSubmit={_handleSubmit}>
          <Grid container>
            <Grid item xs={12} className={classes.logoContainer}>
              <img src="/bild_cloud_login_logo.png" alt="bc-login-logo" className={classes.logo} />
            </Grid>
            <Grid item xs={12} className={classes.formItemContainer}>
              <FormControl fullWidth error={error.username ? true : false}>
                <FormLabel>Email</FormLabel>
                <OutlinedInput
                  className={classes.formItem}
                  id="username"
                  fullWidth
                  value={form.username}
                  onChange={e => {
                    _handleChange("username", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                  autoFocus
                />
                {error.username && <FormHelperText>{error.username}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.formItemContainer}>
              <FormControl fullWidth error={error.password ? true : false}>
                <FormLabel>Password</FormLabel>
                <OutlinedInput
                  className={classes.formItem}
                  id="password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={form.password}
                  onChange={e => {
                    _handleChange("password", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.password && <FormHelperText>{error.password}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.formItemContainer}>
              <Button
                variant="contained"
                className={[classes.formItem, classes.submitButton, classes.formOutline].join(" ")}
                onClick={_handleSubmit}
                onKeyDown={_handleKeyDown}
              >
                Log In
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.formItemContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="remember_me"
                    onChange={() => {
                      _handleChange("remember_me", !form.remember_me);
                    }}
                    onKeyDown={_handleKeyDown}
                    disableRipple={false}
                  />
                }
                label="Stay signed in"
                className={classes.formOutline}
              />
            </Grid>
            <Grid item xs={6} className={[classes.formItemContainer, classes.supportLinks].join(" ")}>
              <Grid container>
                <Grid item xs={12}>
                  <Button tabIndex={0} className={[classes.formOutline, "default-button"].join(" ")} onClick={handleForgotPassword}>
                    <span className={classes.supportLink}>Don't Know your password?</span>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button tabIndex={0} className={[classes.formOutline, "default-button"].join(" ")} onClick={handleSupportRequest}>
                    <span className={classes.supportLink}>Submit a Support Request</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={[classes.formItemContainer, classes.policyLinks].join(" ")}>
              <Grid container item xs={6} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <NormalButton className={[classes.policyLinkOutline, "default-button"].join(" ")} dst="policy" policy="privacy_policy">
                    <span className={classes.supportLink}>Privacy Policy</span>
                  </NormalButton>
                </Grid>
              </Grid>
              <Grid container item xs={6} justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <NormalButton className={[classes.policyLinkOutline, "default-button"].join(" ")} dst="policy" policy="terms_of_use">
                    <span className={classes.supportLink}>Terms of Service</span>
                  </NormalButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.formItemContainer} justifyContent="center" alignItems="center">
              <Grid item>
                <NormalButton className={"default-button"} dst="home">
                  <span className={classes.supportLink}>Home</span>
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Login;
