import React, { useState, useEffect } from "react";
import { colors, BTypography, NormalButton, FormTextField, UpdateMembersChecklists, FormAutocompleteField, FormErrors, PopTip } from "bild-ui";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";

const useStyles = makeStyles({
  titleWrapper: { padding: "1rem 1.5rem 0 1.5rem" },
  bodyWrapper: { padding: "0 1.5rem 0 1.5rem", minHeight: "60vh", maxHeight: "60vh" },
  footerWrapper: { padding: "1rem 1.5rem" },
  tip: { paddingLeft: "0.5rem" },
  name: { padding: "0 0 1rem 0" },
  rowItem: { padding: "0 0.5rem 0 0" },
  addButton: {},
  removeButton: { padding: "0.5rem 0" },
  submitButton: { width: "8rem" }
});

function CreateUpdateCohortDialog({ open, closeDialog, group, allUsers, allPaths, createUpdateCohort }) {
  const theme = useTheme();
  const cls = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [groupId, setGroupId] = useState(group ? group.id : null);
  const [groupName, setGroupName] = useState(group ? group.name : "");
  const [rows, setRows] = useState([
    { userId: null, shepherdUserId: null, programId: null },
    { userId: null, shepherdUserId: null, programId: null },
    { userId: null, shepherdUserId: null, programId: null }
  ]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setGroupId(group ? group.id : null);
    setGroupName(group ? group.name : "");
    setRows([
      { userId: null, shepherdUserId: null, programId: null },
      { userId: null, shepherdUserId: null, programId: null },
      { userId: null, shepherdUserId: null, programId: null }
    ]);
    setErrors([]);
  }, [open]);

  function addRow() {
    setRows([...rows, { userId: null, shepherdUserId: null, programId: null }]);
  }

  function duplicateRow(index) {
    let newRows = JSON.parse(JSON.stringify(rows));
    let newRow = newRows[index];
    newRows.splice(index, 0, newRow);
    setRows(newRows);
    setErrors([]);
  }

  function removeRow(index) {
    let newRows = rows.filter((r, i) => i !== index);
    setRows(newRows);
    setErrors([]);
  }

  function updateRow(index, field, value) {
    let newRows = JSON.parse(JSON.stringify(rows));
    newRows[index][field] = value;
    setRows(newRows);
  }

  function _validate() {
    let valid = true;
    let newErrors = [];

    // New groups must have at least 1 user and program
    if (!group && groupName.length < 1) {
      newErrors["groupName"] = "Group Name not set.";
    }
    // Each row needs a learner, shepherd, and program
    for (let i = 0; i < rows.length; i++) {
      let r = rows[i];
      if (r["userId"] === null) {
        newErrors[`${i}userId`] = `Row ${i + 1}: Learner not set.`;
      }
      if (r["shepherdUserId"] === null) {
        newErrors[`${i}shepherdUserId`] = `Row ${i + 1}: Shepherd not set.`;
      }
      if (r["userId"] === r["shepherdUserId"]) {
        newErrors[`${i}shepherdUserId`] = `Row ${i + 1}: Learner cannot be their own Shepherd.`;
      }
      if (r["programId"] === null) {
        newErrors[`${i}programId`] = `Row ${i + 1}: Program not set.`;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }

  function _createUpdateCohort() {
    if (_validate()) {
      let groupId = group ? group.id : null; // Include id for update and null for create
      let newPaths = [...new Set(rows.map(x => x.programId))]; // map and remove duplicates
      let newUsers = [...new Set(rows.map(x => x.userId))]; // map and remove duplicates
      let newUserPaths = rows;
      createUpdateCohort(groupId, groupName, newPaths, newUsers, newUserPaths);
      closeDialog();
    }
  }

  if (open) {
    return (
      <Dialog open={open} maxWidth={"lg"} fullWidth fullScreen={fullScreen}>
        <DialogTitle className={cls.titleWrapper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <BTypography variant="h5">{group ? `Add Cohort to Group: ${groupName}` : "New Cohort Group"}</BTypography>
                </Grid>
                <Grid item className={cls.tip}>
                  <PopTip
                    text={
                      group
                        ? "Add People with assigned Programs to an existing Sharing Group."
                        : "Add People with assigned Programs to a new Sharing Group."
                    }
                  >
                    <i className="fal fa-question-circle" />
                  </PopTip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <NormalButton onClick={closeDialog} variant={"simple"}>
                <i className="fal fa-2x fa-times" />
              </NormalButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={cls.bodyWrapper}>
          <FormErrors errors={errors} />
          <Grid container justifyContent="center" alignItems="center" className={cls.form}>
            {!group && (
              <Grid container item xs={12} className={cls.name}>
                <Grid item>
                  <FormTextField
                    error={errors["groupName"]}
                    label="Group Name"
                    value={groupName}
                    setValue={setGroupName}
                    disabled={group ? true : false}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid container item xs={10} justifyContent="space-between" alignItems="center">
                <Grid item xs={4} className={cls.rowItem}>
                  Learner
                </Grid>
                <Grid item xs={4} className={cls.rowItem}>
                  Shepherd
                </Grid>
                <Grid item xs={4} className={cls.rowItem}>
                  Program
                </Grid>
              </Grid>
              <Grid item xs={2}>
                &nbsp;
              </Grid>
            </Grid>
            {rows.map((r, i) => (
              <Grid container item xs={12} justifyContent="space-between" alignItems="center" key={i}>
                <Grid container item xs={10} justifyContent="space-between" alignItems="center">
                  <Grid item xs={4} className={cls.rowItem}>
                    <FormAutocompleteField
                      error={errors[`${i}userId`]}
                      items={allUsers}
                      value={rows[i]["userId"]}
                      setValue={v => {
                        updateRow(i, "userId", v);
                      }}
                      showAvatar={true}
                    />
                  </Grid>
                  <Grid item xs={4} className={cls.rowItem}>
                    <FormAutocompleteField
                      error={errors[`${i}shepherdUserId`]}
                      items={allUsers}
                      value={rows[i]["shepherdUserId"]}
                      setValue={v => {
                        updateRow(i, "shepherdUserId", v);
                      }}
                      showAvatar={true}
                    />
                  </Grid>
                  <Grid item xs={4} className={cls.rowItem}>
                    <FormAutocompleteField
                      error={errors[`${i}programId`]}
                      items={allPaths}
                      value={rows[i]["programId"]}
                      setValue={v => {
                        updateRow(i, "programId", v);
                      }}
                      showAvatar={true}
                      showIcon={"fas fa-badge-check"}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={2} justifyContent="space-around" alignItems="center">
                  <Grid item>
                    <NormalButton
                      onClick={() => {
                        duplicateRow(i);
                      }}
                      variant="secondary"
                      color={colors.white}
                      hoverColor={colors.lightBlue}
                      labelColor={colors.bildBlue}
                      borderColor={colors.bildBlue}
                      className={cls.duplicateButton}
                    >
                      <i className="fal fa-clone" />
                    </NormalButton>
                  </Grid>
                  <Grid item>
                    <NormalButton
                      onClick={() => {
                        removeRow(i);
                      }}
                      variant="secondary"
                      color={colors.white}
                      hoverColor={colors.lightRed}
                      labelColor={colors.darkRed}
                      borderColor={colors.darkRed}
                      className={cls.removeButton}
                    >
                      <i className="fal fa-times" />
                    </NormalButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions className={cls.footerWrapper}>
          <NormalButton
            onClick={addRow}
            variant="secondary"
            color={colors.white}
            hoverColor={colors.darkBildBlue}
            labelColor={colors.bildBlue}
            borderColor={colors.bildBlue}
            className={cls.addButton}
          >
            Add Row
          </NormalButton>
          <NormalButton
            onClick={_createUpdateCohort}
            variant="primary"
            color={colors.bildBlue}
            hoverColor={colors.darkBildBlue}
            labelColor={colors.white}
            className={cls.submitButton}
          >
            {group ? "Update " : "Create "} Group
          </NormalButton>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default CreateUpdateCohortDialog;
