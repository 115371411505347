import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { BTypography, FormTextField, NormalButton, colors } from "bild-ui";

export default function NetworkPartnerCivilizationDialog ({ open, onClose, civilization, updateCivilization }) {
  const [form, setForm] = useState({});

  useEffect(()=>{
    if (civilization) {
      setForm({
        "id": civilization.id,
        "currentStatus": civilization.currentStatus,
      });
    }
  },[civilization]);

  function _isReady() {
    if (civilization) {
      if (form && (
        civilization.currentStatus === form.currentStatus
      )) {
        return false;
      }
    }
    
    if (form && (form.currentStatus && form.currentStatus.length > 0)) {
      return true;
    }
    
    return false;
  }

  function _updateCivilization() {
    if (form && form.currentStatus) {
      updateCivilization({
        "currentStatus": form.currentStatus
      });
      setForm({});
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <BTypography variant="h5">{civilization ? "Update " : "Create " } Civilization Partner</BTypography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormTextField
              label="Current Status"
              value={form.currentStatus || ""}
              setValue={(x)=>{setForm({...form, "currentStatus": x})}}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BTypography variant="body1">
              {civilization.currentStatusUpdatedAt}
            </BTypography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <NormalButton
          variant="primary"
          color={colors.bildBlue}
          hoverColor={colors.darkBildBlue}
          labelColor={colors.white}
          disabled={!_isReady()}
          onClick={_updateCivilization}
        >
          {civilization ? "Update" : "Create" }
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
}