import React, { useEffect, useState } from "react";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Folio from "containers/folio/folio.js";

import { load, uploadFiles, renameItem, createFolder, downloadItem, deleteItem, moveItem } from "bild-data/disk";
import { diskData } from "bild-data";

function FolioLoader({ userId }) {
  setPageTitle("Folio");
  const [error, setError] = useState(null);
  const [docId, setDocId] = useState(null);
  const [previewDocLoading, setPreviewDocLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);
  // Get rid of useless scroll bar.
  document.getElementById("root").style.overflow = "hidden";

  const [diskArrays, setDiskArrays] = useState(null);
  useEffect(
    () =>
      load(userId, setDiskArrays, e => {
        setError(e.response.data.error);
      }),
    []
  ); // Empty array == componentDidMount()

  const _uploadFiles = (files, destination) => uploadFiles(files, destination, setDiskArrays);
  const _renameItem = (item, newName) => renameItem(item, newName, userId, setDiskArrays);
  const _createFolder = (contextRoot, newFolderName) => createFolder(contextRoot, newFolderName, userId, setDiskArrays);
  const _deleteItem = item => deleteItem(item, setDiskArrays);
  const _moveItem = (item, destination) => moveItem(item, destination, userId, setDiskArrays);

  function _setDocId(id) {
    setDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setPreviewDocLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setPreviewDocLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setPreviewDocLoading(false);
        }
      );
    }
  }

  function onPreviewIntent(item) {
    if (item) {
      if (item.raw.inAppLocalId) {
        _setDocId(item.raw.inAppLocalId);
      } else {
        setPreviewFile(item);
      }
    } else {
      setDocId(null);
      setPreviewFile(null);
    }
  }

  if (!diskArrays || error) return <LoadingScreen error={error} />;
  return (
    <Folio
      diskArrays={diskArrays}
      onUploadFiles={_uploadFiles}
      onRenameItem={_renameItem}
      onCreateFolder={_createFolder}
      onPreviewIntent={onPreviewIntent}
      onDownloadItem={downloadItem}
      onDeleteItem={_deleteItem}
      onMoveItem={_moveItem}
      previewDocId={docId}
      previewDocLoading={previewDocLoading}
      previewFile={previewFile}
    />
  );
}

export default FolioLoader;
