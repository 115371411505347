export function getEnumByKeyValue(enumSection, searchKey, searchValue, valueKey) {
  let v = null;

  const arr = Object.keys(enumSection);

  for (let i=0; i<arr.length; i++) {
    let cObj = enumSection[arr[i]];
    if (cObj[searchKey] === searchValue) {
      v = cObj[valueKey];
    }
  }

  return v;
}

export function getEnumSectionAsArray(enumSection) {
  let v = [];

  const arr = Object.keys(enumSection);

  for (let i=0; i<arr.length; i++) {
    let cObj = enumSection[arr[i]];
    v.push(cObj);
  }

  return v;
}