import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { managerData, organizationsData, userData } from "bild-data";
import { getPath, setPageTitle, updateURLWithoutLoad } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { loadAdminOrganization } from "bild-data/admin";
import ManageOrganization from "./manageOrganization.js";
import queryString from "query-string";
import { toaster } from "presentational/toasts/toasts.js";

function ManageOrganizationLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [org, setOrg] = useState([]);
  const [orgTree, setOrgTree] = useState([]);
  const [users, setUsers] = useState([]);
  const [archivedUsers, setArchivedUsers] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [orgGroups, setOrgGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [inheritedUserRoles, setInheritedUserRoles] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [antiochOrg, setAntiochOrg] = useState({});
  const [defaultTab, setDefaultTab] = useState(1);

  useEffect(() => {
    setPageTitle("Organization Management");
    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      setDefaultTab(parseInt(searchParams.t));
    }
    function _setData(d) {
      setPageTitle(" Admin | " + d.organizationName);
      setOrg(d);
      setOrgTree(d.organizationRelationship.children);
      setUserRoles(d.organizationRoles);
      setInheritedUserRoles(d.inheritedOrganizationRoles);
      setUsers(d.managedUsers);
      setArchivedUsers(d.archivedManagedUsers);
      setLoading(false);
    }

    function _setSubData(m) {
      setOrgs(m.availableOrganizations);
      setRoles(m.availableRoles);
    }

    loadAdminOrganization(organization_id, _setData);
    managerData.getManagedOrganizationUserCertificates(organization_id, d => {
      setCertificates(d.certificates);
    });
    organizationsData.loadOrganization(organization_id, d => {
      setAntiochOrg(d);
    });
    organizationsData.loadOrganizationGroups(organization_id, d => {
      setOrgGroups(d);
    });
    managerData.getOrganizationManagedUsers(organization_id, _setSubData, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(()=>{
    if (defaultTab && defaultTab < 7) {
      updateURLWithoutLoad(getPath() + "?t=" + defaultTab);
    }
  },[defaultTab])

  function resendEmail(email, success_cb, error_cb) {
    userData.forgotPassword(email, success_cb, error_cb);
  }

  function archiveUser(userId, orgId, success_cb, error_cb) {
    function _success_cb(data) {
      loadAdminOrganization(
        organization_id,
        (d)=>{
          setUsers(d.managedUsers);
          setArchivedUsers(d.archivedManagedUsers);
          toaster.success("User Successfully Archived.");
        },
        e => {
          setError(e.response.data.error);
        }
      );
      if (typeof success_cb === "function") success_cb(data);
    }

    let data = {
      id: userId,
      organizationIdsToArchiveRelationship: [ orgId ]
    };
    managerData.updateManagedUser(userId, data, _success_cb, error_cb);
  }

  function orgUpdate(form, success_cb, error_cb) {
    function _success_cb(data) {
      setOrg(data);
      setOrgGroups({
        ...orgGroups,
        level3Name: data.groupLevel3Name,
        level2Name: data.groupLevel2Name,
        level1Name: data.groupLevel1Name
      })
      toaster.success("Organization Updated.")
      if (typeof success_cb === "function") success_cb(data);
    }

    managerData.updateManagedOrganization(organization_id, form, _success_cb, error_cb);
  }

  function orgGroupUpdate(action, form, success_cb, error_cb) {
    function _success_cb(data, message) {
      setOrgGroups(data);
      toaster.success(message);
      if (typeof success_cb === "function") success_cb(data);
    }

    let sendData;
    let message;

    console.log(form);
    if (action === "delete") {
      // If this is a "delete" then send the data to be removed
      sendData = {organizationGroupsToRemove: [{
        id: form.id,
        organizationId: organization_id,
        parentOrganizationGroupId: form.parentId
      }]};
      message = `${form.name} Removed.`;
    } else if (action === "massUpdate") {
      // If this is a "delete" then send the data to be removed
      form = form.map(x => {return {...x, organizationId: organization_id}});
      sendData = {organizationGroups: form};
      message = `User${form.length > 1 ? "s": ""} Assigned.`;
    } else {
      // Treat everything else as a CREATE/UPDATE
      sendData = {organizationGroups: [{
        id: form.id,
        name: form.name,
        organizationId: organization_id,
        parentOrganizationGroupId: form.parentId,
        levelId: form.levelId,
        usersToAdd: form.usersToAdd ? form.usersToAdd.map(x => ({ id: x })) : [],
        usersToRemove: form.usersToRemove ? form.usersToRemove.map(x => ({ id: x })) : []
      }]};
      message = `${form.name} Updated.`;
    }

    managerData.createUpdateManagedOrganizationGroups(sendData, (d)=>{_success_cb(d, message)}, error_cb);
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <ManageOrganization
      org={org}
      orgUpdate={orgUpdate}
      orgTree={orgTree}
      users={users}
      setUsers={setUsers}
      archivedUsers={archivedUsers}
      orgs={orgs}
      orgGroups={orgGroups}
      orgGroupUpdate={orgGroupUpdate}
      roles={roles}
      userRoles={userRoles}
      inheritedUserRoles={inheritedUserRoles}
      resendEmail={resendEmail}
      archiveUser={archiveUser}
      certs={certificates}
      antiochOrg={antiochOrg}
      setAntiochOrg={setAntiochOrg}
      defaultTab={defaultTab}
      setDefaultTab={setDefaultTab}
    />
  );
}

export default ManageOrganizationLoader;
