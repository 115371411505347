import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reviewData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import ShepherdingOverview from "./shepherdingOverview.js";

export default function ShepherdingOverviewLoader({}) {
  const { user_id } = useParams();
  const userOverview = userData.getOverview();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  

  useEffect(()=>{
    setPageTitle("Shepherding Overview");

    function _setData(d) {
      setUser(d.user);
      setData(d);
      setLoading(false);
    }

    if (user_id) {
      reviewData.getUserShepherdingOverview(user_id, _setData);
    } else if (userOverview && userOverview.user) {
      reviewData.getUserShepherdingOverview(userOverview.user.id, _setData);
    }
  },[]);

  function _onSearch(data, successCb) {
    setSearchValue(data);
    if (typeof successCb === "function") successCb();
  }

  if (loading) return <LoadingScreen />;
  return (
    <BaseWrapper>
      <ShepherdingOverview
        user={user}
        data={data}
      />
    </BaseWrapper>
  );
}