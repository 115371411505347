import React from "react";
import Competency from "./competency.js";

function CompetencyLoader({
  userPathItemId,
  userLearningItem,
  changeLevel,
  editable,
  createDocument,
  attach,
  remove,
  user,
  docId,
  setDocId,
  fileLoading,
  submitScore,
  markCompleted,
  completed,
  color
}) {
  return (
    <Competency
      userPathItemId={userPathItemId}
      userLearningItem={userLearningItem}
      changeLevel={changeLevel}
      editable={editable}
      createDocument={createDocument}
      attach={attach}
      remove={remove}
      user={user}
      docId={docId}
      setDocId={setDocId}
      fileLoading={fileLoading}
      submitScore={submitScore}
      markCompleted={markCompleted}
      completed={completed}
      color={color}
    />
  );
}

export default CompetencyLoader;
