const classes = {
  container: "enrollment container",
  header: "header",
  mainDivider: "main-divider",
  dashboardContainer: "dashboard-container",
  courseGroup: "enrollment-course-group",
  overview: {
    addCourse: "course-overview-add-course",
    groupIconContainer: "group-icon-container",
    courseBodyFlex: "course-overview-body-flex",
    circlesContainer: "course-competency-circles",
    row: "course-overview-row",
    button: "course-overview-button"
  },
  transcript: {
    competencyListItem: "competency-list-item"
  }
};

export default classes;
