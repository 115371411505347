import React from "react";
import { colors, BTypography, NormalButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  tabsWrapper: {},
  tabs: props => ({ backgroundColor: colors.white, borderRadius: "8px", border: `3px solid ${props.color}` }),
  tab: props => ({
    backgroundColor: colors.white,
    color: props.color,
    "&:hover": { backgroundColor: props.color, color: colors.white, opacity: "0.7" }
  }),
  activeTab: props => ({ backgroundColor: props.color, color: colors.white }),
  leftTab: { borderTopRightRadius: "0", borderBottomRightRadius: "0" },
  rightTab: { borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }
});

function TabButtons({ color, tab1Text, tab2text, value, setValue }) {
  const cls = useStyles({ color });

  return (
    <Grid container item xs={12} className={cls.tabsWrapper}>
      <Grid container item xs={12} className={cls.tabs}>
        <Grid item xs={6}>
          <NormalButton
            fullWidth
            onClick={() => {
              setValue(0);
            }}
            variant="simple"
            className={`${value === 0 ? cls.activeTab : ""} ${cls.tab} ${cls.leftTab}`}
            color={value === 0 ? color : colors.white}
            labelColor={value === 0 ? colors.white : color}
            hoverColor={color}
            hoverLabelColor={colors.white}
          >
            {tab1Text}
          </NormalButton>
        </Grid>
        <Grid item xs={6}>
          <NormalButton
            fullWidth
            onClick={() => {
              setValue(1);
            }}
            variant="simple"
            className={`${value === 1 ? cls.activeTab : ""} ${cls.tab} ${cls.rightTab}`}
            color={value === 1 ? color : colors.white}
            labelColor={value === 1 ? colors.white : color}
            hoverColor={color}
            hoverLabelColor={colors.white}
          >
            {tab2text}
          </NormalButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TabButtons;
