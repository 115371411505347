import React, { useState, useEffect } from "react";
import isEqual from "lodash.isequal";
import { userData } from "bild-data";
import { makeStyles } from "@mui/styles";

import TopMenu from "./components/topMenu.js";
import LeftMenu from "./components/leftMenu.js";
import MasqueradeMenu from "./components/masqueradeMenu.js";

// TODO: replace to MU provided hook when we update
import { useWidth } from "bild-utils";
import buildMenuData from "./menuData.js";

const useStyles = makeStyles(theme => ({
  leftMenu: { width: "250px", flexShrink: 0, overflowY: "auto" },
  leftMenuMini: { width: "60px", overflowY: "auto" },
  topMenu: { height: "48px", zIndex: theme.zIndex.drawer + 1 }
}));

function useMini(userPreferences) {
  if (!userPreferences) userPreferences = {};

  const [mini, _setMini] = useState(userPreferences.mini === true);

  function toggleMini() {
    const prefs = userData.getUserPreferences() || {};
    prefs.mini = !prefs.mini;
    userData.setUserPreferences(prefs);
    _setMini(prefs.mini);
  }

  return [mini, toggleMini];
}

function Menus({ root, className, ...props }) {
  const classes = useStyles();
  const [userOverview, setUserOverview] = useState(userData.getOverview());
  const [userPreferences, setUserPreferences] = useState(userData.getUserPreferences());
  useEffect(
    () =>
      userData.loadOverview(o => {
        if (!isEqual(o, userOverview)) setUserOverview(o);
      }),
    [userOverview]
  );

  const canMasquerade = userData.checkMasquerade();
  const isAdmin = userData.checkAdmin();
  const isBetaTester = userData.checkBetaTester();
  const isDemoAccount = userData.checkAdminDemoUser();
  const { topMenuProps, leftMenuSections } = buildMenuData(userOverview);

  const [open, setOpen] = useState(false);
  const w = useWidth();
  const mobile = w === "xs" || w === "sm";

  const [mini, toggleMini] = useMini(userPreferences);

  // NOTE: Changing the root padding from here should probably be happening somewhere else
  const rootContainer = document.getElementById("root");
  rootContainer.style.setProperty('padding',  mobile ? "48px 0 0 0" : mini ? "48px 0 0 60px" : "48px 0 0 250px"); // Make sure this matches the topmenu height & leftmenu width!

  return (
    <React.Fragment>
      <LeftMenu
        open={open}
        onClose={() => setOpen(false)}
        mini={mini && !mobile}
        onToggleMini={toggleMini}
        mobile={mobile}
        sections={leftMenuSections}
        root={root}
        className={mini && !mobile ? classes.leftMenuMini : classes.leftMenu}
      />

      <TopMenu
        onOpenLeftMenu={() => setOpen(true)}
        mini={mini && !mobile}
        onToggleMini={toggleMini}
        mobile={mobile}
        userOverview={userOverview}
        className={classes.topMenu}
        canMasquerade={canMasquerade}
        isAdmin={isAdmin}
        isBetaTester={isBetaTester}
        isDemoAccount={isDemoAccount}
        {...topMenuProps}
      />

      <MasqueradeMenu userOverview={userData.getOverview()} />
    </React.Fragment>
  );
}

export default Menus;
