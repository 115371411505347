import React, { useEffect, useState } from "react";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { simaData } from "bild-data";
import SimaReview from "./simaReview.js";

// SIMA REVIEW LOADER
function SimaReviewLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stories, setStories] = useState([]);

  useEffect(()=>{
    setPageTitle("SIMA Review");

    function _setStories(d) {
      setStories(d.reviewSummaryForStudentList);
      setLoading(false);
    }

    simaData.loadSimaReview(_setStories, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return <SimaReview stories={stories} />;
  }
}

export default SimaReviewLoader;
