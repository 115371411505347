import React, { useState, useEffect } from "react";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import BetaTesterLeaderboard from "./betaTesterLeaderboard";
import { userData } from "bild-data";

export default function BetaTesterLeaderboardLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [features, setFeatures] = useState([]);

  // NOTE: Hardcoded list of BETA Testers for now
  const userIds = [ 48049, 48051, 12184, 2609, 10643, 10484, 2230, 47972 ];

  useEffect(() => {
    setPageTitle("Beta Tester Leaderboard");

    function _addUser(d) {
      setUsers(old => [...old, d]);
    }
  
    for (let i=0; i<userIds.length; i++) {
      userData.loadUserProfile(userIds[i], _addUser);
    }

    setFeatures([
      {type: "feature", name: "Improve Library tab readability", userId: 12184, date: "Jul 22, 2024", points: 1 },
      {type: "feature", name: "Improve userPath default tab logic", userId: 2230, date: "Jul 16, 2024", points: 1 },
      {type: "bug", name: "Improve sharing group load time", userId: 2230, date: "Jul 15, 2024", points: 1 },
      {type: "feature", name: "Navigagte Shared Files", userId: 2609, date: "Jul 11, 2024", points: 2 },
      {type: "feature", name: "Improve Preview File window sizing", userId: 2230, date: "Jun 24, 2024", points: 2 },
      {type: "bug", name: "Fix Mini Menu scrollbar width", userId: 2230, date: "Jun 13, 2024", points: 1 },
      {type: "feature", name: "Add search filter to Library", userId: 10643, date: "May 10, 2024", points: 1.5 },
      {type: "feature", name: "Add Video section to Library", userId: 48049, date: "Apr 11, 2024", points: 3 },
      {type: "feature", name: "Add Civilizations to WorldMap", userId: 48049, date: "Feb 26, 2024", points: 9 },
      {type: "bug", name: "Fix Badge position in Menu", userId: 48051, date: "Feb 1, 2024", points: 1 },
      {type: "feature", name: "Create beta tester badge", userId: 48051, date: "Feb 4, 2024", points: 1 },
      {type: "feature", name: "Initial beta tester badge mockup", userId: 48049, date: "Jan 26, 2024", points: 1 },
      {type: "feature", name: "Add Left/Right/Center align to Editor", userId: 48051, date: "Dec 16, 2023", points: 1.5 },
      {type: "feature", name: "Add Heebo font to Editor", userId: 48049, date: "Dec 3, 2023", points: 1 },
    ]);
  }, []);

  useEffect(() => {
    if (users.length >= userIds.length) {
      setLoading(false);
    }
  },[users]);

  if (loading || error) return <LoadingScreen error={error} />;
  return <BetaTesterLeaderboard users={users} features={features} />;
}
