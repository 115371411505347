import React from "react";
import { updateURL } from "bild-utils";
import Organization from "./components/organization.js";
import { Grid, Typography, Divider } from "@mui/material";
import "./review.css";

// REVIEW
function Review({ organizations, reviewType }) {

  return (
    <Grid container className="review-wrapper">
      <Grid item xs className="review">
        <Grid item md={12} xs={12}>
          <Typography variant="h5" className="page-headline" gutterBottom>
            {reviewType} Review
          </Typography>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <Organization updateURL={updateURL} organizations={organizations} reviewType={reviewType} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Review;
