import React, { useState } from "react";
import { colors, CountrySelect, Avatar, Signature, NormalButton, SupportDialog, UserAvatar, Blink, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, FormControl, FormLabel, FormHelperText, Button, OutlinedInput, Typography } from "@mui/material";

import "./userProfile.css";
import "presentational/dialogs/dialogs.css";

const useStyles = makeStyles({
  updateButtons: { paddingBottom: "1rem" },
  avatar: { "&:hover": { cursor: "pointer", filter: "brightness(0.9)" } },
  signature: {
    marginTop: "1rem",
    border: "1px solid #dadada5c",
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.9)",
      opacity: "0.75"
    }
  },
  buttonWrapper: { padding: "1rem 1rem 1rem 0" }
});

function UserProfile({ handleSubmit, handleChangePassword, handleUploadAvatar, handleUploadSignature, userProfile, userId, userOverview, ...props }) {
  const cls = useStyles();
  // const country_options = [{ value: "US", label: "United States" }];
  const [form, setForm] = useState({
    id: userId,
    fullName: userProfile.fullName,
    name: userProfile.name,
    emailAddress: userProfile.emailAddress,
    phone: userProfile.phone,
    addressLine1: userProfile.addressLine1,
    addressLine2: userProfile.addressLine2,
    city: userProfile.city,
    state: userProfile.state,
    zip: userProfile.zip,
    country: userProfile.country
  });
  const [error, setError] = useState({}); // eslint-disable-line no-unused-vars
  const [changePartnerDialog, setChangePartnerDialog] = useState(false);
  // TODO setError is never used

  function _handleSubmit() {
    if (_validateForm()) {
      handleSubmit(form);
    }
  }

  function _validateForm() {
    return true;
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  }

  return (
    <Grid container alignItems="flex-start" className="form-page-container">
      <Grid item xs={12} className="">
        <form onSubmit={_handleSubmit}>
          <Grid container justifyContent="flex-start" className="form-item-container">
            <Grid container item sm={8} xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={12} className="">
                  <Typography variant="h5">
                    Profile
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={12} className="form-section-label">
                  Personal Information
                </Grid>
                <Grid item xs={12} className="form-item">
                  <FormControl fullWidth error={error.fullName ? true : false}>
                    <FormLabel>Formal Name (Name on Certificate)</FormLabel>
                    <OutlinedInput
                      className="form-field"
                      id="fullName"
                      value={form.fullName}
                      onChange={e => {
                        _handleChange("fullName", e.target.value);
                      }}
                      onKeyDown={_handleKeyDown}
                      autoFocus
                    />
                    {error.fullName && <FormHelperText>{error.fullName}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} className="form-item">
                  <FormControl fullWidth error={error.partnerName ? true : false}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <FormLabel>Partner Name (Name on Certificate)</FormLabel>
                      </Grid>
                      <Grid item>
                        <NormalButton
                          variant="simple"
                          labelColor={colors.bildBlue}
                          onClick={() => {
                            setChangePartnerDialog(true);
                          }}
                        >
                          Request Change
                        </NormalButton>
                      </Grid>
                    </Grid>
                    <OutlinedInput className="form-field" id="partnerName" value={userProfile.partnerName} disabled />
                    {error.partnerName && <FormHelperText>{error.partnerName}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} className="form-item">
                  <FormControl fullWidth error={error.name ? true : false}>
                    <FormLabel>Name</FormLabel>
                    <OutlinedInput
                      className="form-field"
                      id="name"
                      value={form.name}
                      onChange={e => {
                        _handleChange("name", e.target.value);
                      }}
                      onKeyDown={_handleKeyDown}
                    />
                    {error.name && <FormHelperText>{error.name}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={cls.updateButtons}>
                  <Button variant="contained" className="default-submit-button" onClick={_handleSubmit} onKeyDown={_handleKeyDown}>
                    Update
                  </Button>
                  &nbsp; &nbsp;
                  <Button variant="contained" className="change-password-button" onClick={handleChangePassword}>
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center" alignItems="center" item sm={4} xs={12}>
              <Grid item onClick={handleUploadAvatar} className={cls.avatar}>
                <UserAvatar size={200} name={userProfile.name} hideName src={userProfile.avatarURL} badgeSrc={userProfile.badgeURL} />
              </Grid>
              <Grid item onClick={handleUploadSignature} className={cls.signature}>
                {userProfile.signatureURL && <Signature width={300} src={userProfile.signatureURL} />}
                {!userProfile.signatureURL && (
                  <NormalButton
                    variant="primary"
                    color={colors.bildBlue}
                    hoverColor={colors.darkBildBlue}
                    labelColor={colors.white}
                    className={cls.detailButton}
                  >
                    Update Signature
                  </NormalButton>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center" item xs={12}></Grid>
          </Grid>

          <Grid container justifyContent="flex-start" className="form-item-container">
            <Grid item xs={8} className="form-section-label">
              <b>Contact Information</b>
            </Grid>
            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.emailAddress ? true : false}>
                <FormLabel>Email</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="emailAddress"
                  value={form.emailAddress}
                  onChange={e => {
                    _handleChange("emailAddress", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                  disabled
                />
                {error.emailAddress && <FormHelperText>{error.emailAddress}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.phone ? true : false}>
                <FormLabel>Phone</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="phone"
                  value={form.phone}
                  onChange={e => {
                    _handleChange("phone", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.phone && <FormHelperText>{error.phone}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.addressLine1 ? true : false}>
                <FormLabel>Address Line 1</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="addressLine1"
                  value={form.addressLine1}
                  onChange={e => {
                    _handleChange("addressLine1", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.addressLine1 && <FormHelperText>{error.addressLine1}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.addressLine2 ? true : false}>
                <FormLabel>Address Line 2</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="addressLine2"
                  value={form.addressLine2 || ""}
                  onChange={e => {
                    _handleChange("addressLine2", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.addressLine2 && <FormHelperText>{error.addressLine2}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.city ? true : false}>
                <FormLabel>City</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="city"
                  value={form.city}
                  onChange={e => {
                    _handleChange("city", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.city && <FormHelperText>{error.city}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.state ? true : false}>
                <FormLabel>State / Region</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="state"
                  value={form.state}
                  onChange={e => {
                    _handleChange("state", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.state && <FormHelperText>{error.state}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.zip ? true : false}>
                <FormLabel>Postal Code</FormLabel>
                <OutlinedInput
                  className="form-field"
                  id="zip"
                  value={form.zip}
                  onChange={e => {
                    _handleChange("zip", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.zip && <FormHelperText>{error.zip}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={8} className="form-item">
              <FormControl fullWidth error={error.country ? true : false}>
                <FormLabel>Country</FormLabel>
                <CountrySelect
                  value={form.country}
                  setValue={newValue => {
                    _handleChange("country", newValue);
                  }}
                />
                {error.country && <FormHelperText>{error.country}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} className="">
              <Button variant="contained" className="default-submit-button" onClick={_handleSubmit} onKeyDown={_handleKeyDown}>
                Update
              </Button>
            </Grid>
          </Grid>
          <SupportDialog
            open={changePartnerDialog}
            closeDialog={() => {
              setChangePartnerDialog(false);
            }}
            options={[`I would like to change the Partner Name that appears on my Certificates.`]}
          />
        </form>
      </Grid>
      {userOverview && userOverview.enrollments && userOverview.enrollments.length > 0 && (
        <Grid container item xs={12} className="form-item-container">
          <Grid item xs={12} className="form-section-label">
            <BTypography variant="h5">Antioch Program Work</BTypography>
          </Grid>
          <Grid item className={cls.buttonWrapper}>
            <NormalButton
              component={Blink}
              dst="user-audit-antioch-courses"
              className={cls.button}
              variant="secondary"
              borderColor={colors.antiochPurple}
              labelColor={colors.antiochPurple}
              hoverLabelColor={colors.darkPurple}
            >
              View All Course Work
            </NormalButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default UserProfile;
