import { getRequest, putRequest, deleteRequest } from "../core/comm.js";
import { updateURL, uploadManager } from "bild-utils";

//// SIMA
export function loadSimaSearch(searchTerm, success_cb, error_cb) {
  getRequest(`/search/sima/${searchTerm}`, success_cb, null, error_cb);
}

export function loadSimaUser(enrollmentId, success_cb, error_cb) {
  getRequest(`/courses/sima/enrollments/${enrollmentId}`, success_cb, null, error_cb);
}

export function loadSimaReview(success_cb, error_cb) {
  getRequest(`/review/validation/sima/story`, success_cb, null, error_cb);
}

export function simaSearch(searchTerm) {
  updateURL("/sima/search/" + searchTerm);
}

export function attachSimaArtifact(competency_id, enrollment_id, files, success_cb, error_cb) {
  let data = [];

  let fileListPromise = new Promise(function(resolve, reject) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadManager.add(file.name);
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // Wait for the file object to be read
      reader.onload = () => {
        // The result needs to be cleaned before it can be sent (removes the initial "data:*/*;base64,")
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        let result = reader.result.split(",")[1];

        data.push({
          name: file.name,
          folderId: null,
          contentType: file.type,
          fileSizeBytes: file.size,
          base64EncodedFile: result
        });

        if (data.length >= files.length) {
          resolve(data);
        }
      };
    }
  });

  // Creation request succeeded
  fileListPromise.then(data => {
    putRequest(
      `/competencies/${competency_id}/enrollments/${enrollment_id}/sima/files`,
      data,
      d => {
        for (let i = 0; i < files.length; i++) {
          uploadManager.conveySuccess(files[i].name);
        }
        success_cb(d);
      },
      null,
      error_cb
    );
  });

  // Creation request failed
  fileListPromise.catch(() => {
    for (let i = 0; i < files.length; i++) {
      uploadManager.conveyFail(files[i].name);
    }
    if (typeof error_cb === "function") error_cb();
  });
}

export function removeSimaArtifact(competency_id, enrollment_id, attachment, success_cb, error_cb) {
  let attachments = [{ id: attachment }];
  deleteRequest(`/competencies/${competency_id}/enrollments/${enrollment_id}/sima/files`, attachments, success_cb, null, error_cb);
}

export function onFinalizeSima(competency, callingUser, success_cb, error_cb) {
  // Map a passing score to the rubric
  const competencyProof = competency.competencyProof;
  const rubric = competency.rubric;
  const criteria = rubric.criteria;
  const criteriaData = [];
  for (let i = 0; i < criteria.length; i++) {
    const c = criteria[i];
    criteriaData.push({
      id: c.id,
      name: c.name,
      score: { scoreValue: 4 }
    });
  }
  let data = {
    user: callingUser,
    role: competencyProof.waitingOn,
    comment: null,
    rubric: {
      id: rubric.id,
      criteria: criteriaData
    }
  };
  putRequest(`/competencies/proofs/${competencyProof.id}/assessments`, data, success_cb, null, error_cb);
}
