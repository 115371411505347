import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { organizationsData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import AntiochOrganizationUsers from "./components/antiochOrganizationUsers.js";

export default function ManageAntiochOrganizationLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [antiochOrg, setAntiochOrg] = useState({});

  useEffect(() => {
    setPageTitle("Antioch Organization Management");

    organizationsData.loadOrganization(
      organization_id,
      d => {
        setAntiochOrg(d);
        setLoading(false);
      },
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <AntiochOrganizationUsers antiochOrg={antiochOrg} setAntiochOrg={setAntiochOrg} />
  );
}
