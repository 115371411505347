import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: "2rem"
  },
  backBar: {
    position: "relative",
    height: "0.75rem",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "100",
    backgroundColor: colors.veryLightBlue
  },
  bar: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.bildBlue,
    animation: `$indeterminateProgress 1s infinite linear`,
    transformOrigin: "0% 50%"
  },
  "@keyframes indeterminateProgress": {
    "0%": { transform: "translateX(0) scaleX(0)" },
    "40%": { transform: "translateX(0) scaleX(0.4)" },
    "100%": { transform: "translateX(100%) scaleX(0.5)" }
  }
}));

function IndeterminateProgress({}) {
  const cls = useStyles();

  return (
    <Grid container className={cls.wrapper}>
      <Grid container className={cls.backBar}>
        <Grid item xs={12} className={cls.bar}>
          &nbsp;
        </Grid>
      </Grid>
    </Grid>
  );
}

export default IndeterminateProgress;
