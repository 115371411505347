import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import { List, ListItemButton, ListItemIcon, ListItemText, Badge, Collapse, Portal, ClickAwayListener } from "@mui/material";

const useStyles = makeStyles({
  listItem: {
    whiteSpace: "nowrap",
    borderLeft: "5px solid transparent",
    height: "47px",
    "& *": { color: "#AAA", marginLeft: "-1px" },
    "&:hover *": { color: "#FFF" },
    "& svg": { width: "17px", height: "17px", marginLeft: "5px" }
  },
  listItemIcon: { minWidth: "44px", fontSize: "1rem" },
  listItemText: { "& *": { fontWeight: "bold" } },
  expandButtonIcon: { fontSize: "0.6rem", paddingLeft: "0.65rem" },
  subList: { padding: "0", borderLeft: "5px solid transparent", backgroundColor: "#2A3137", zIndex: "1200" },
  subListItem: {
    paddingLeft: "1.75rem",
    "& *": { color: "#AAA", fontWeight: "bold" },
    "&:hover *": { color: "#FFF" }
  },
  badge: {
    color: "#FFF !important",
    "& *": { color: "#FFF" }
  }
});

function LeftMenuItem({ header, mini, ...props }) {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(header.active && !mini);
  const ref = useRef(null);
  const [oldMini, setOldMini] = useState(mini);
  if (mini !== oldMini) {
    setOpen(false);
    setOldMini(mini);
  }

  const hasLinks = header.links && header.links.length > 0;
  const linkComponents = [];
  let linkPosition = {};
  if (hasLinks) {
    for (let i = 0; i < header.links.length; i++) {
      const l = header.links[i];
      linkComponents.push(
        <ListItemButton
          component="a"
          href={l.href}
          target={l.target}
          className={classes.subListItem}
          style={{
            borderColor: header.active || hovered || open ? header.accent : "transparent",
            backgroundColor: header.active ? "#3E434A" : "transparent"
          }}
          disableRipple
          key={i}
        >
          <ListItemText className={classes.subListItemText}>{ReactHtmlParser(l.title)}</ListItemText>
          <Badge max={999} badgeContent={l.badge ? l.badge : null} color={l.badgeClass ? l.badgeClass : "secondary"} overlap="rectangular">
            <span />
          </Badge>
        </ListItemButton>
      );
    }

    if (mini && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      linkPosition = { position: "absolute", left: rect.right, top: rect.top };
    }
  }
  const hideBadge = !(header.badge && header.badge > 0);

  return (
    <div ref={ref}>
      <ListItemButton
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={hasLinks ? () => setOpen(!open) : null}
        className={classes.listItem}
        component={hasLinks ? null : "a"}
        href={hasLinks ? null : header.href}
        style={{
          borderColor: header.active || hovered || open ? header.accent : "transparent",
          backgroundColor: header.active ? "#3E434A" : "transparent",
          width: "100%"
        }}
        disableRipple
        {...props}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <Badge invisible={!mini || hideBadge} color={header.badgeClass ? header.badgeClass : "secondary"} variant="dot" overlap="rectangular">
            <i className={header.icon} />
          </Badge>
        </ListItemIcon>

        <ListItemText className={classes.listItemText}>
          {ReactHtmlParser(header.title)}
          {hasLinks && (<i className={`fal fa-chevron-${open ? "up" : "down"} fa-xs ${classes.expandButtonIcon}`} />)}
        </ListItemText>

        <Badge
          max={999}
          className={classes.badge}
          badgeContent={header.badge ? header.badge : null}
          invisible={mini || hideBadge}
          color={header.badgeClass ? header.badgeClass : "secondary"}
          overlap="rectangular"
        >
          <span className={classes.badge} />
        </Badge>
      </ListItemButton>

      {hasLinks && (
        <Portal disablePortal={!mini} container={document.getElementById("root")}>
          <Collapse in={open} timeout={mini ? 0 : "auto"} style={{ ...linkPosition }}>
            <ClickAwayListener
              onClickAway={e => {
                const { x, y } = e;
                const { top, right, bottom, left } = ref.current.getBoundingClientRect();
                if (x < left || x > right || y < top || y > bottom) setOpen(false);
              }}
            >
              <List className={classes.subList} style={{ borderColor: header.accent }} disablePadding>
                {linkComponents}
              </List>
            </ClickAwayListener>
          </Collapse>
        </Portal>
      )}
    </div>
  );
}

export default LeftMenuItem;
