import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { ENUMS, reviewData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import TraineeDetails from "./components/traineeDetails.js";

function TraineeDetailsLoader({}) {
  const { user_id } = useParams();
  const [error, setError] = useState(null);
  const [trainee, setTrainee] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setPageTitle("Associate Faculty Trainee");
    // This page is either called by a coach with a trainee user_id or a trainee
    const userOverview = userData.getOverview();
    const userId = user_id || userOverview.user.id;
    setIsAdmin(userOverview.roles.some(r => r.id === ENUMS.ROLES.AF_COACH.ID || r.id === ENUMS.ROLES.ADMIN.ID));
    reviewData.loadAfTraineeDetails(userId, setTrainee, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (!trainee || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <TraineeDetails trainee={trainee} isAdmin={isAdmin} />
    </BaseWrapper>
  );
}

export default TraineeDetailsLoader;
