import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Blink } from "bild-ui";
import {Collapse, Grid, Typography} from "@mui/material";
import classes from "../classes.js";
import CourseWrapper from "presentational/wrappers/courseWrapper.js";

// PROGRAM ORDER GROUP (used in ProgramDashboard)
function ProgramOrderGroup({ programOrderGroup, groupColumns, enrollmentId, expanded }) {
  function renderCourses(courses) {
    const components = [];

    for (var i = 0; i < courses.length; i++) {
      const c = courses[i];
      let circle = <i className={`far fa-circle ${classes.pogCourseIcon}`} />;
      if (c.complete === true) {
        circle = <i className={`fas fa-circle ${classes.pogCourseIcon}`} />;
      }

      components.push(
        <Grid
          container
          className={classes.pogCourse}
          component={Blink}
          dst={"course"}
          course_id={c.id}
          enrollment_id={enrollmentId}
          key={i}
        >
          <Grid item>{circle}</Grid>
          <Grid item container xs alignItems="center">
            <Typography variant="body1" className={classes.pogCourseText}>
              {c.shortName == null ? ReactHtmlParser(c.name) : ReactHtmlParser(c.shortName)}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return components;
  }

  function renderCourseGroups() {
    const components = [];
    const courseGroups = programOrderGroup.courseGroups;

    // TODO: Antioch FE ?? -- The backend should send the full dashboard grid including empty spaces
    //      Since it does not... we need to figure out what spaces should be empty
    for (var i = 0; i < groupColumns.length; i++) {
      let cg = null;

      for (var j = 0; j < courseGroups.length; j++) {
        let g = courseGroups[j];
        if (g.name === groupColumns[i]) {
          cg = g;
        }
      }

      // Display the group if it exists, otherwise place an empty space
      if (cg) {
        // TODO: Antioch FE ?? -- courseGroup API should have a groupColor attribute
        const groupColor = cg.courses[0].courseGroupColor.webFrontendValue;
        const header = (
          <Typography variant="subtitle1" className={classes.pogCourseGroupHeaderText}>
            {cg.shortName ? cg.shortName : cg.name}
          </Typography>
        );

        components.push(
          <Grid item lg={2} md={4} sm={6} xs={12} key={i}>
            <CourseWrapper variant="dashboard" color={groupColor} header={header}>
              {renderCourses(cg.courses)}
            </CourseWrapper>
          </Grid>
        );
      }
      // Previously had "No Requirements" Section, removed per JLS
    }

    return (
      <Grid container spacing={2}>
        {components}
      </Grid>
    );
  }

  return (
    <Collapse in={expanded === true ? true : false} timeout="auto" unmountOnExit>
      <div className={classes.pogContainer}>
        <Typography variant="subtitle1" gutterBottom className={classes.pogTitle}>
          {programOrderGroup.name}
        </Typography>

        {renderCourseGroups()}
      </div>
    </Collapse>
  );
}

export default ProgramOrderGroup;
