import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Autocomplete } from "@mui/material";
import { countryData } from "bild-data";

const useStyles = makeStyles({});

function CountrySelect({ value, setValue, placeholder }) {
  const cls = useStyles();
  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    if (!localValue && value) {
      let values = options.filter(x => x.value === value);
      setLocalValue(values ? values[0] : null);
    }
  }, [value]);

  function _setValue(newValue) {
    setLocalValue(newValue);
    setValue(newValue !== null ? newValue.value : newValue);
  }

  function _getOptionSelected(option) {
    if (option && option.value && localValue && localValue.value) {
      return option.value === localValue.value;
    } else {
      return false;
    }
  }

  const options = countryData.getCountryOptions();

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={_getOptionSelected}
      renderInput={params => <TextField {...params} variant="outlined" placeholder={placeholder} />}
      getOptionLabel={options => options.label || ""}
      value={localValue}
      onChange={(_event, newValue) => {
        _setValue(newValue);
      }}
    />
  );
}

export default CountrySelect;
