import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { NormalButton, colors } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import Competency from "containers/competency/competency.js";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Dialog,
  DialogContent
} from "@mui/material";
import Bbutton from "presentational/input/bbutton.js";
import SortButton from "presentational/input/sortButton.js";
import List from "presentational/lists/list.js";

const useStyles = makeStyles({
  subHeader: { marginLeft: "0.5rem" },
  divider: { margin: "0.5rem 0 1rem 0" },
  table: { minWidth: "100%" },
  tableHeader: { backgroundColor: colors.lightGray, fontWeight: "bold", padding: "0.75rem" },
  filterWrapper: { textAlign: "right" },
  container: { maxHeight: "90vh" },
  green: { color: colors.green }
});

function TrainingModuleReview({ competencies, loadCompetencyProof, user, trainee, trainingModule, trainingAssessments }) {
  const classes = useStyles();
  const [rows, setRows] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [proofs, setProofs] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [sort, setSort] = useState([]);

  function createData(name, student, courseId, competencyProofId, trainingAssessmentId, goodExample) {
    return { name, student, courseId, competencyProofId, trainingAssessmentId, goodExample };
  }

  function expandRow(row, courseId, competencyProofId) {
    let arr = [...expanded];
    arr[row] = !arr[row];
    setExpanded(arr);

    if (!proofs[row]) {
      loadCompetencyProof(courseId, competencyProofId, data => {
        let competency = data.competencies.filter(d => d.competencyProof && d.competencyProof.id === competencyProofId)[0];
        let c = <Competency course={data} competency={competency} callingUser={user} />;
        let arr = [...proofs];
        arr[row] = c;
        setProofs(arr);
      });
    }
  }

  function sortRows(field) {
    let newRows = [...rows];
    let newSort = [];
    if (!sort[field] || sort[field] === "descending") {
      // Either sort by goodExample + trainingAssessmentId (id exists or not) OR by passed in field only
      newRows.sort((a, b) =>
        typeof a[field] === "boolean"
          ? a[field] - b[field] || (a["trainingAssessmentId"] ? 1 : 0) - (b["trainingAssessmentId"] ? 1 : 0)
          : a[field].localeCompare(b[field])
      );
      newSort[field] = "ascending";
    } else {
      // Either sort by goodExample + trainingAssessmentId (id exists or not) OR by passed in field only
      newRows.sort((a, b) =>
        typeof a[field] === "boolean"
          ? b[field] - a[field] || (b["trainingAssessmentId"] ? 1 : 0) - (a["trainingAssessmentId"] ? 1 : 0)
          : b[field].localeCompare(a[field])
      );
      newSort[field] = "descending";
    }
    setRows(newRows);
    setSort(newSort);
  }

  useEffect(() => {
    // Set arrays with empty values
    setExpanded(new Array(competencies.length).fill(false));
    setProofs(new Array(competencies.length).fill(null));

    // Create competencies table data
    let rs = [];
    for (let i = 0; i < competencies.length; i++) {
      let c = competencies[i];
      let taid = null;
      let ge = false;
      if (trainingAssessments) {
        let ta = trainingAssessments.reverse().find(x => x.competency.competencyProof.id === c.competencyProof.id) || null;
        taid = ta ? ta.id : null;
        ge = trainingAssessments.some(x => x.competency.competencyProof.id === c.competencyProof.id && x.goodExample) || false;
      }
      rs.push(createData(c.name, c.competencyProof.user.name, c.course.id, c.competencyProof.id, taid, ge));
    }
    setRows(rs);
  }, [competencies, trainingAssessments]);

  if (rows) {
    let comments = trainingAssessments.map(x => [
      <Grid container alignItems="center">
        <Grid item xs>
          <Bbutton variant="submit" dst="training_assessment" training_assessment_id={x.id}>
            <i className={`fal fa-chevron-right`} />
          </Bbutton>
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{x.createdAt}</Typography>
        </Grid>
        <Grid item md={10} sm={12}>
          <Typography variant="subtitle1">{ReactHtmlParser(x.competency.name)}</Typography>
          <Typography variant="body1">{x.coachComment ? x.coachComment : "N/A"}</Typography>
        </Grid>
      </Grid>
    ]);

    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item className={classes.header}>
            <Typography variant="h5">
              {trainee} &mdash; {ReactHtmlParser(trainingModule.name)}
            </Typography>
          </Grid>
          <Grid item>
            <NormalButton
              className="default-action-button"
              onClick={() => {
                setDialog(true);
              }}
              disabled={comments.length < 1}
              variant={"simple"}
            >
              Coach Comments
            </NormalButton>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} className={classes.filterWrapper}>
            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      <Typography variant="subtitle1">
                        Exemplary <br /> Review
                        <SortButton
                          value={sort["goodExample"] ? sort["goodExample"] : "descending"}
                          onChange={() => {
                            sortRows("goodExample");
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <Typography variant="subtitle1">
                        Competency
                        <SortButton
                          value={sort["name"] ? sort["name"] : "descending"}
                          onChange={() => {
                            sortRows("name");
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <Typography variant="subtitle1">
                        Student
                        <SortButton
                          value={sort["student"] ? sort["student"] : "descending"}
                          onChange={() => {
                            sortRows("student");
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableHeader}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    let rs = [];
                    rs.push(
                      <TableRow hover key={i}>
                        <TableCell component="th" scope="row">
                          {row.trainingAssessmentId && (
                            <Bbutton dst="training_assessment" training_assessment_id={row.trainingAssessmentId}>
                              <i className={`fa ${row.goodExample ? `fa-check ${classes.green}` : "fa-horizontal-rule"}`} />
                            </Bbutton>
                          )}
                        </TableCell>
                        <TableCell><Typography variant="body1">{ReactHtmlParser(row.name)}</Typography></TableCell>
                        <TableCell><Typography variant="body1">{row.student}</Typography></TableCell>
                        <TableCell align="right">
                          <NormalButton onClick={() => expandRow(i, row.courseId, row.competencyProofId)} variant={"simple"}>
                            <i className={`fal fa-chevron-${expanded[i] ? "up" : "down"}`} />
                          </NormalButton>
                        </TableCell>
                      </TableRow>
                    );
                    if (expanded[i] && proofs[i]) {
                      rs.push(
                        <TableRow hover key={i + "b"}>
                          <TableCell component="th" scope="row" colSpan={12}>
                            {proofs[i]}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return rs;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Dialog
          open={dialog}
          onClose={() => {
            setDialog(false);
          }}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <List title="Coach Comments" items={comments} />
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}

export default TrainingModuleReview;
