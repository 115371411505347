import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { colors } from "bild-ui";

import { Typography, Grid, Dialog, DialogContent, Button } from "@mui/material";

const useStyles = makeStyles({
  content: {
    textAlign: "center",
    padding: "10px 10px 25px 10px"
  },
  icon: {
    fontSize: "3rem",
    padding: "20px 0px",
    width: "100%",
    color: colors.red
  },
  question: {
    color: colors.red,
    paddingBottom: "20px"
  },
  text: {
    fontWeight: "400",
    paddingBottom: "20px"
  },
  button: {
    padding: "4px 16px",
    marginTop: "10px",
    width: "90%",
    "& *": { color: "#fff" },
    "&:hover": { opacity: "0.7" }
  },
  confirmButton: {
    "&:hover": { backgroundColor: colors.green },
    backgroundColor: colors.green
  },
  cancelButton: {
    "&:hover": { backgroundColor: colors.red },
    backgroundColor: colors.red
  }
});

function SwitchCompetencySetDialog({ open, onConfirm, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} aria-describedby="alert-dialog-description" maxWidth="sm" fullWidth>
      <DialogContent className={classes.content}>
        <i className={`fas fa-exclamation-triangle ${classes.icon}`} />

        <Typography variant="h5" className={classes.question}>
          Are you Sure?
        </Typography>

        <Typography variant="subtitle1" className={classes.text}>
          No artifacts, assessments or comments will be associated with the new Competency Set you are selecting.
        </Typography>

        <Typography variant="subtitle1" className={classes.text}>
          <b>Note:</b> Any artifacts, assessments or comments associated with the current Competency Set <u>will not</u> be permanently deleted. They
          will appear if you switch back to the current Competency Set.
        </Typography>

        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <Button className={clsx(classes.button, classes.confirmButton)} onClick={onConfirm}>
              Ok, I understand.
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button className={clsx(classes.button, classes.cancelButton)} onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SwitchCompetencySetDialog;
