// Callbacks interface
export const Callbacks = {};
Callbacks.validKeys = ["onLoad", "onError", "onFinish"];
Callbacks.isValid = callbacks => {
  // Ensure we are passing correct keys.
  if (!!callbacks) {
    const keys = Object.keys(callbacks);
    if (
      keys.length > Callbacks.validKeys.length ||
      keys.some(k => !Callbacks.validKeys.includes(k)) ||
      keys.some(k => typeof callbacks[k] !== "function")
    ) {
      return false;
    }
  }

  return true;
};
Callbacks.onLoad = (callbacks, payload) => {
  if (!!callbacks) {
    if (typeof callbacks.onLoad === "function") callbacks.onLoad(payload);
  }
};
Callbacks.onError = (callbacks, payload) => {
  if (!!callbacks) {
    if (typeof callbacks.onError === "function") callbacks.onError(payload);
  }
};
Callbacks.onFinish = (callbacks, payload) => {
  if (!!callbacks) {
    if (typeof callbacks.onFinish === "function") callbacks.onFinish(payload);
  }
};

export class CallbacksInvalidError extends Error {
  constructor(message) {
    super(message || `Invalid Callbacks object passed, the only accepted keys are ${Callbacks.validKeys.join(", ")}, values must be functions.`);
    this.name = "CallbacksInvalidError";
  }
}
