import React from "react";
import { colors } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    row: { borderBottom: `1px solid ${colors.lightGray}` },
    criteriaColumn: { textAlign: "left !important" },
    column: {
      padding: "0",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        overflowWrap: "break-word",
        fontSize: "0.65rem"
      }
    },
    criteriaRow: {
      padding: "1rem 0rem",
      backgroundSize: "60% 100%",
      backgroundPositionX: "50%",
      backgroundRepeat: "no-repeat"
    }
  })
);

function CriteriaHeader({}) {
  const cls = useStyles();
  const spacing = 6;

  return (
    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center" className={cls.row}>
      <Grid item xs={7} className={`${cls.criteriaColumn} ${cls.column}`}>
        Criteria
      </Grid>
      <Grid container item xs={5} className={cls.criteriaRow}>
        <Grid item xs={spacing} className={cls.column}>
          Does Not Meet Criteria
        </Grid>
        <Grid item xs={spacing} className={cls.column}>
          Meets Criteria
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CriteriaHeader;
