import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reviewData } from "bild-data";
import { setPageTitle, getParam } from "bild-utils";
import { BaseWrapper } from "bild-ui";
import LoadingScreen from "presentational/screens/loadingScreen.js";

import ReviewDelinquent from "./reviewDelinquent.js";

function ReviewDelinquentLoader({}) {
  const { user_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [afName, setAfName] = useState(null);

  useEffect(() => {
    setPageTitle("Delinquent Validation Reviews");
    function _setReviews(data) {
      setReviews(data.reviewSummaryForStudentList);
      if (getParam("name")) {
        setAfName(getParam("name"));
      }
      setLoading(false);
    }

    if (user_id) {
      reviewData.loadDelinquentValidationReviews(user_id, _setReviews);
    } else {
      reviewData.loadAllDelinquentValidationReviews(_setReviews);
    }
  }, []);

  if (loading) return <LoadingScreen />;
  return (
    <BaseWrapper>
      <ReviewDelinquent reviews={reviews} afName={afName} />
    </BaseWrapper>
  );
}

export default ReviewDelinquentLoader;
