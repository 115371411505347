import React, { useState, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

import { Editable, Slate } from "slate-react";
import { Transforms, Text, Editor } from "slate";

import { Grid } from "@mui/material";

import { ArtifactToolbar, Leaf, Element, constants, useFullPageEditor } from ".";
import ArtifactButtons from "equip/artifact/artifactButtons.js";

const useStyles = makeStyles({
  container: {
    minHeight: "450px",
    width: "100%",
    border: `1px solid ${colors.gray}`,
    borderRadius: "1px",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2), inset 0 0 2px rgba(0, 0, 0, 0.1)"
  },
  editable: {
    minHeight: "350px",
    maxHeight: "700px",
    resize: "vertical",
    padding: "1rem",
    overflowY: "auto"
  }
});

function useRefState(initialVal) {
  const [state, _setState] = useState(initialVal);
  const stateRef = useRef(state);
  function setState(newState) {
    stateRef.current = newState;
    _setState(newState);
  }

  return [stateRef, state, setState];
}

function ArtifactEditor({ document, handle, onDocumentChange, onHandleChange, processUploadQueue }) {
  const classes = useStyles();

  const [nameRef, name, setName] = useRefState(handle.name || "");
  const [valueRef, value, setValue] = useRefState(document.value || constants.initialValue);

  const [savedRef, saved, setSaved] = useRefState(true);
  const editor = useFullPageEditor();
  editor.onValueChange = () => setSaved(false);

  function _setValue(newValue) {
    setValue(newValue);
    setSaved(false);
  }
  function _changeHandle(docHandle) {
    if (typeof onHandleChange === "function") onHandleChange(docHandle);
  }
  function _changeName(newName) {
    setName(newName);
    setSaved(false);
    setTimeout(() => _save(), 1000);
  }

  function _changeDocument(docObject) {
    if (typeof onDocumentChange === "function") onDocumentChange(docObject);
  }

  function _save(success_cb) {
    if (!savedRef.current) {
      document.value = valueRef.current;
      _changeDocument(document);

      handle.name = nameRef.current;
      _changeHandle(handle);

      setSaved(true);
    }
    if (typeof success_cb === "function") success_cb();
  }

  function saveAndUpload() {
    _save(processUploadQueue);
  }

  useEffect(() => {
    setTimeout(() => _save(), 1000);
    const interval = setInterval(_save, 5000);
    return () => clearInterval(interval);
  }, []);

  const onKeyDown = (event, editor) => {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    // key shortcuts
    switch (event.key) {
      // bold
      case "b": {
        event.preventDefault();
        toggleMark(editor, "bold");
        break;
      }
      // italics
      case "i": {
        event.preventDefault();
        toggleMark(editor, "italic");
        break;
      }
      // underline
      case "u": {
        event.preventDefault();
        toggleMark(editor, "underline");
        break;
      }
      // save
      case "s": {
        event.preventDefault();
        saveAndUpload();
        break;
      }
    }
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  return (
    <>
      <Slate
        editor={editor}
        value={value}
        onChange={newValue => {
          _setValue(newValue);
        }}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <ArtifactToolbar
              name={name}
              marks={constants.marks}
              blocks={constants.blocks}
              saved={saved}
              onChangeName={_changeName}
              onSave={saveAndUpload}
              withFonts
            />
            <Editable
              id="bild-editor"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              autoFocus
              className={`${classes.editable} notranslate`}
              onKeyDown={e => {
                onKeyDown(e, editor);
              }}
            />
          </Grid>
        </Grid>
      </Slate>
      <ArtifactButtons save={saveAndUpload} />
    </>
  );
}

export default ArtifactEditor;
