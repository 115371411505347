import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

function FolioDialog({ open, contextRoot, variant, item, onCancel, onSubmit }) {
  const [name, setName] = useState("");

  function _onSubmit() {
    if (onSubmit && !_isDisabled()) onSubmit(name);
    setName("");
  }

  function _checkSubmit(e) {
    if (e.key === "Enter") {
      _onSubmit();
    }
  }

  function _isDisabled() {
    return name === "" || (variant === "rename" && name === item.name);
  }

  function _onCancel() {
    if (onCancel) onCancel();
    setName("");
  }

  let headerText, placeHolder, defaultValue;
  switch (variant) {
    case "rename":
      headerText = "Rename:";
      defaultValue = item.name;
      placeHolder = null;
      break;
    default:
      headerText = `Create a new folder in "${contextRoot.name}":`;
      placeHolder = "New Folder";
      defaultValue = null;
      break;
  }

  return (
    <Dialog keepMounted open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          placeholder={placeHolder}
          fullWidth
          margin="normal"
          onChange={e => setName(e.target.value.trim())}
          onKeyPress={e => _checkSubmit(e)}
          defaultValue={defaultValue}
          key={item && item.id}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button className="default-cancel-button" onClick={_onCancel}>
          Cancel
        </Button>
        <Button className="default-submit-button" onClick={() => _onSubmit(name)} disabled={_isDisabled()}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FolioDialog;
