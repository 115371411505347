import React, { useState, useEffect, useMemo, useCallback } from "react";
import { BTypography, Blink, NormalButton, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup, Circle, Polygon, GeoJSON } from 'react-leaflet';
import * as L from "leaflet";

import './leaflet/leaflet_1-9-4.css';
import icon from './leaflet/images/marker-icon.png';
import { CIVILIZATION_1, CIVILIZATION_2, CIVILIZATION_3, CIVILIZATION_4, CIVILIZATION_5, CIVILIZATION_6, CIVILIZATION_7, CIVILIZATION_8, CIVILIZATION_9 } from "./data/worldGeoJsonData";
import { useWidth } from "bild-utils";

const useStyles = makeStyles({
  containerWrapper: { height: "100%", minHeight: "75vh" },
  mapWrapper: { height: "100%", position: "relative", overflow: "hidden" },
  map: { height: "100%" },
  popup: { minWidth: "10rem" },
  popupTitle: { paddingBottom: "1rem" },
  titleWrapper: { position: "absolute", zIndex: "501", padding: "0.5rem" },
  title: { background: "#ffffffa6", padding: "0.25rem 0.5rem" },
  legendWrapper: {
    display: "block",
    position: "absolute",
    zIndex: "501",
    bottom: "1rem",
    left: "1rem",
    maxWidth: "10rem"
  },
  legend: {
    padding: "0.5rem",
    background: "#ffffffbd",
    maxWidth: "10rem"
  },
  legendSmall: { transformOrigin: "bottom left", transform: "scale(0.5)" },
  heatLegendEquip: { color: colors.bildBlue },
  heatLegendAntioch: { color: colors.antiochPurple }
});

function ZoomedMarker({ data }) {
  let markerIcon = L.icon({ iconUrl: icon, iconAnchor: [12, 41] });
  return (
    <Marker position={data.pos} icon={markerIcon}>
      <UserPopup data={data}/>
    </Marker>
  );
}

function UserPopup({ data }) {
  const cls = useStyles();
  return (
    <Popup>
      <Grid container className={cls.popup}>
        <Grid item xs={12} className={cls.popupTitle}>
          <BTypography variant="h6">{data.name}</BTypography>
        </Grid>
        {data.equipUserCount > 0 && (
          <Grid item xs={12}>
            <BTypography variant="body2">Equip Users: {data.equipUserCount.toLocaleString()}</BTypography>
          </Grid>
        )}
        {data.antiochUserCount > 0 && (
          <Grid item xs={12}>
            <BTypography variant="body2">Antioch School Students: {data.antiochUserCount.toLocaleString()}</BTypography>
          </Grid>
        )}
        {data.equipUserCount < 1 && data.antiochUserCount < 1 && (
          <Grid item xs={12}>
            <BTypography variant="body2">No active users.</BTypography>
          </Grid>
        )}
        <Grid container item xs={12} justifyContent={"flex-end"} alignItems={"flex-end"}>
          <Grid item>
            <NormalButton
              component={Blink}
              dst="manage-organization"
              organization_id={data.id}
              variant="simple"
            >
              <i className="fas fa-external-link"/>
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    </Popup>
  );
}

function HeatLegend({ map, defaultShow }) {
  const cls = useStyles();
  const [legend, setLegend] = useState(defaultShow ? defaultShow : false);

  const onOverlayAdd = useCallback((e) => {
    if (e.name === 'Heatmap Legend') setLegend(true);
  }, [map]);

  const onOverlayRemove = useCallback((e) => {
    if (e.name === 'Heatmap Legend') setLegend(false);
  }, [map]);

  useEffect(() => {
    map.on('overlayadd', onOverlayAdd)
    return () => {
      map.off('overlayadd', onOverlayAdd)
    }
  }, [map, onOverlayAdd]);

  useEffect(() => {
    map.on('overlayremove', onOverlayRemove)
    return () => {
      map.off('overlayremove', onOverlayRemove)
    }
  }, [map, onOverlayRemove]);

  return (
    <>
      {legend && (
        <Grid container className={cls.legend}>
          <Grid item xs={12}><b>Heatmap</b></Grid>
          <Grid item xs={12} className={cls.heatLegendEquip}>
            <i className="fad fa-circle fa-swap-opacity" /> Equip
          </Grid>
          <Grid item xs={12} className={cls.heatLegendAntioch}>
            <i className="fad fa-circle fa-swap-opacity" /> Antioch School
          </Grid>
        </Grid>
      )}
    </>
  )
}

function CivilizationLegend({ map, defaultShow }) {
  const cls = useStyles();
  const w = useWidth();
  const [legend, setLegend] = useState(defaultShow ? defaultShow : false);

  const onOverlayAdd = useCallback((e) => {
    if (e.name === 'Civilizations') setLegend(true);
  }, [map]);

  const onOverlayRemove = useCallback((e) => {
    if (e.name === 'Civilizations') setLegend(false);
  }, [map]);

  useEffect(() => {
    map.on('overlayadd', onOverlayAdd)
    return () => {
      map.off('overlayadd', onOverlayAdd)
    }
  }, [map, onOverlayAdd]);

  useEffect(() => {
    map.on('overlayremove', onOverlayRemove)
    return () => {
      map.off('overlayremove', onOverlayRemove)
    }
  }, [map, onOverlayRemove]);

  function lRow(name, clr) {
    return (
      <Grid item xs={12} className={cls.legendItem}>
        <i className="fad fa-square fa-swap-opacity" style={{ color: clr }}/> {name}
      </Grid>
    );
  }

  return (
    <>
      {legend && (
        <Grid container className={`${w === "xs" ? cls.legendSmall : ""} ${cls.legend}`}>
          <Grid item xs={12}><b>Civilizations</b></Grid>
          {lRow("Western", colors.bildBlue)}
          {lRow("Islamic", colors.green)}
          {lRow("Sinic", colors.darkRed)}
          {lRow("Orthodox", colors.darkPurple)}
          {lRow("African", colors.brown)}
          {lRow("Hindu", colors.veryLightBlue)}
          {lRow("Buddhist", colors.darkerYellow)}
          {lRow("Latin American", colors.orange)}
          {lRow("Japenese", colors.pink)}
        </Grid>
      )}
    </>
  );
}

function LayerWatcher({ map, addRemoveLayer }) {
  const onBaseLayerChange = useCallback((e) => {
    addRemoveLayer("BASE_" + e.name);
  }, [map]);

  const onOverlayAddRemove = useCallback((e) => {
    addRemoveLayer(e.name);
  }, [map]);

  useEffect(() => {
    map.on('baselayerchange', onBaseLayerChange)
    return () => {
      map.off('baselayerchange', onBaseLayerChange)
    }
  }, [map, onBaseLayerChange]);

  useEffect(() => {
    map.on('overlayadd', onOverlayAddRemove)
    return () => {
      map.off('overlayadd', onOverlayAddRemove)
    }
  }, [map, onOverlayAddRemove]);

  useEffect(() => {
    map.on('overlayremove', onOverlayAddRemove)
    return () => {
      map.off('overlayremove', onOverlayAddRemove)
    }
  }, [map, onOverlayAddRemove]);

  return null;
}

export default function WorldMap({ heatCircleData, markerData, startingLocation, startingZoom, title, defaultLayers, setLayers }) {
  const cls = useStyles();
  const [map, setMap] = useState(null);
  const defaultRadiusSize = 1000;
  const initialCenter = startingLocation ? startingLocation : [5.00000, 10.00000];
  const initialZoom = startingZoom ? startingZoom : 3;
  const initialLayers = defaultLayers ? defaultLayers : ["Light Gray Map", "Heatmap", "Heatmap Legend"];
  const BASE_LAYERS = ["Light Gray Map", "Basic Map", "Satellite Map"];

  function addRemoveLayer(layerName) {
    let currentLayers = defaultLayers;
    if (layerName.startsWith("BASE_")) {
      for (let i=0; i<BASE_LAYERS.length; i++) {
        if (currentLayers.includes(BASE_LAYERS[i])) {
          currentLayers.splice(currentLayers.indexOf(BASE_LAYERS[i]), 1);
        }
      }
      currentLayers.push(layerName.slice(5));
    } else {
      if (currentLayers.includes(layerName)) {
        currentLayers.splice(currentLayers.indexOf(layerName), 1);
      } else {
        currentLayers.push(layerName);
      }
    }

    setLayers(currentLayers);
  }

  let heatCircles = [];
  let markers = [];
  useEffect(()=>{
    if (heatCircleData) {
      for (let i=0; i < heatCircleData.length; i++) {
        let hc = heatCircleData[i];
        let radiusSize = (hc.radius < 1000 ? hc.radius : 1000) * defaultRadiusSize;
        heatCircles.push(
          <Circle center={hc.pos} radius={radiusSize} key={i} color={hc.color}>
            <UserPopup data={hc}/>
          </Circle>
        );
      }
    }

    if (markerData) {
      for (let i=0; i < markerData.length; i++) {
        markers.push(<ZoomedMarker data={markerData[i]} key={i} />);
      }
    }
  },[]);

  const civilizationsLayer = [
    <GeoJSON data={CIVILIZATION_1} key={"civ1"} style={{color: colors.bildBlue}} />,
    <GeoJSON data={CIVILIZATION_2} key={"civ2"} style={{color: colors.green}}  />,
    <GeoJSON data={CIVILIZATION_3} key={"civ3"} style={{color: colors.darkRed}}  />,
    <GeoJSON data={CIVILIZATION_4} key={"civ4"} style={{color: colors.darkPurple}}  />,
    <GeoJSON data={CIVILIZATION_5} key={"civ5"} style={{color: colors.brown}}  />,
    <GeoJSON data={CIVILIZATION_6} key={"civ6"} style={{color: colors.veryLightBlue}}  />,
    <GeoJSON data={CIVILIZATION_7} key={"civ7"} style={{color: colors.darkerYellow}}  />,
    <GeoJSON data={CIVILIZATION_8} key={"civ8"} style={{color: colors.orange}}  />,
    <GeoJSON data={CIVILIZATION_9} key={"civ9"} style={{color: colors.pink}}  />,
  ];

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={initialCenter}
        zoom={initialZoom}
        zoomSnap={1}
        zoomDelta={1.5}
        maxZoom={16}
        minZoom={2}
        scrollWheelZoom={true}
        className={cls.map}
        worldCopyJump={true}
        ref={setMap}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked={initialLayers.includes("Light Gray Map")} name="Light Gray Map">
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked={initialLayers.includes("Basic Map")} name="Basic Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked={initialLayers.includes("Satellite Map")} name="Satellite Map">
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.jpg"
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked={initialLayers.includes("Heatmap")} name="Heatmap">
            <LayerGroup>
              {heatCircles}
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={initialLayers.includes("Heatmap Legend")} name="Heatmap Legend">
            <LayerGroup />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={initialLayers.includes("Locations")} name="Locations">
            <LayerGroup>
              {markers}
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={initialLayers.includes("Civilizations")} name="Civilizations">
            <LayerGroup>
              {civilizationsLayer}
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    ),[],
  );

  return (
    <Grid container item xs={12} className={cls.containerWrapper}>
      <Grid item xs={12} className={cls.mapWrapper}>
        {title && (
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.titleWrapper}>
            <Grid item className={cls.title}>
              <BTypography variant="subtitle">
                <i>{title}</i> Map
              </BTypography>
            </Grid>
          </Grid>
        )}
        <Grid container item xs className={cls.legendWrapper}>
          {map ? <HeatLegend map={map} defaultShow={initialLayers.includes("Heatmap Legend")} /> : null}
          {map ? <CivilizationLegend map={map} defaultShow={initialLayers.includes("Civilizations")} /> : null}
        </Grid>
        {map ? <LayerWatcher map={map} addRemoveLayer={addRemoveLayer} /> : null}
        {displayMap}
      </Grid>
    </Grid>
  );
}
