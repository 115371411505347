import React from "react";

import { Grid, Typography, DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@mui/material";

import AssessmentHistoryRow from "./assessmentHistoryRow.js";

function AssessmentHistoryDialog({ open, onClose, assessments, competencyName, viewDocument, viewPreview }) {
  // Create an array of historical assessment rows
  let items;
  if (assessments) items = assessments.map((a, i) => <AssessmentHistoryRow {...a} key={i} viewDocument={viewDocument} viewPreview={viewPreview} />);
  else
    items = (
      <Typography variant="body2" key="orphan">
        No Assessment History data available.
      </Typography>
    );

  // return the modal with all the available historical assessments
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Assessment History</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          {competencyName}
        </Typography>

        <AssessmentHistoryRow isHeader />

        <Grid container>{items}</Grid>
      </DialogContent>
      <DialogActions>
        <Button className="default-cancel-button" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AssessmentHistoryDialog;
