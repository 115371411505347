import React, { useEffect, useRef, useState } from "react";
import { colors, PopTip } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  backBarWrapper: {
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "100",
    backgroundColor: colors.veryLightGray
  },
  backBar: {
    position: "relative",
    height: "0.75rem"
  },
  bar: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    bottom: "0",
    transition: "transform 0.2s linear",
    transformOrigin: "left"
  },
  subBar1: props => ({
    zIndex: "101",
    backgroundColor: props.val1Color ? `${props.val1Color}60` : colors.veryLightGreen,
    opacity: "0.75",
    "&:hover": { cursor: "pointer", filter: "brightness(0.75)" }
  }),
  subBar2: props => ({
    zIndex: "101",
    backgroundColor: props.val2Color ? `${props.val2Color}60` : colors.veryLightBlue,
    opacity: "0.75",
    "&:hover": { cursor: "pointer", filter: "brightness(0.75)" }
  }),
  bar1: props => ({
    zIndex: "102",
    backgroundColor: props.val1Color ? props.val1Color : colors.green,
    "&:hover": { cursor: "pointer", filter: "brightness(0.75)" }
  }),
  bar2: props => ({
    zIndex: "102",
    backgroundColor: props.val2Color ? props.val2Color : colors.blue,
    "&:hover": { cursor: "pointer", filter: "brightness(0.75)" }
  })
});

function DualProgress({ val1, subVal1, val1Color, val1Tip, subVal1Tip, val2, subVal2, val2Color, val2Tip, subVal2Tip }) {
  const cls = useStyles({ val1Color, val2Color });
  // Create an anchor reference element for us to pass to the popper
  const anchorRef = useRef();
  const [anchorEl, setAnchorEl] = useState(anchorRef.current);
  useEffect(() => {
    setAnchorEl(anchorRef.current);
  }, [anchorRef]);

  return (
    <Grid container className={cls.backBarWrapper}>
      <Grid container item xs={12} className={cls.backBar} ref={anchorRef}>
        {subVal1 !== null && (
          <PopTip text={subVal1Tip} disabled={!subVal1Tip} popperProps={{ anchorEl: anchorEl }}>
            <Grid
              item
              xs={12}
              className={`${cls.bar} ${cls.subBar1}`}
              style={{ transform: `translateX(-${100 - (parseInt(subVal1) > 0 ? parseInt(subVal1) : -1)}%)` }}
            >
              &nbsp;
            </Grid>
          </PopTip>
        )}
        <PopTip text={val1Tip} disabled={!val1Tip} popperProps={{ anchorEl: anchorEl }}>
          <Grid
            item
            xs={12}
            className={`${cls.bar} ${cls.bar1}`}
            style={{ transform: `translateX(-${100 - (parseInt(val1) > 0 ? parseInt(val1) : -1)}%)` }}
          >
            &nbsp;
          </Grid>
        </PopTip>
      </Grid>
      <Grid container item xs={12} className={cls.backBar}>
        {subVal2 !== null && (
          <PopTip text={subVal2Tip} disabled={!subVal2Tip} popperProps={{ anchorEl: anchorEl }}>
            <Grid
              item
              xs={12}
              className={`${cls.bar} ${cls.subBar2}`}
              style={{ transform: `translateX(-${100 - (parseInt(subVal2) > 0 ? parseInt(subVal2) : -1)}%)` }}
            >
              &nbsp;
            </Grid>
          </PopTip>
        )}
        <PopTip text={val2Tip} disabled={!val2Tip} popperProps={{ anchorEl: anchorEl }}>
          <Grid
            item
            xs={12}
            className={`${cls.bar} ${cls.bar2}`}
            style={{ transform: `translateX(-${100 - (parseInt(val2) > 0 ? parseInt(val2) : -1)}%)` }}
          >
            &nbsp;
          </Grid>
        </PopTip>
      </Grid>
    </Grid>
  );
}

export default DualProgress;
