import React from "react";
import { makeStyles } from "@mui/styles";

import { IconButton, Typography } from "@mui/material";

const useStyles = makeStyles({
  button: {
    height: "30px",
    padding: "0.1rem 0.75rem 0 0.75rem",
    borderRadius: 0,
    backgroundColor: "green",

    "&:hover, &:hover *": { backgroundColor: "darkgreen", transition: "unset" }
  },
  typo: { color: "white", fontSize: "1rem", paddingBottom: "2px" }
});

function SendButton({ className, onClick }) {
  const classes = useStyles();

  return (
    <IconButton
      className={`${classes.button} ${className}`}
      onClick={onClick}
      size="large">
      <Typography className={classes.typo}>Send</Typography>
    </IconButton>
  );
}

export default SendButton;
