import React from "react";

import { Blink } from "bild-ui";
import { Grid, Divider, Typography, List, ListItem } from "@mui/material";

import SearchBar from "presentational/input/searchBar.js";

import { simaData } from "bild-data";
import { toaster } from "../../presentational/toasts/toasts.js";

// SIMA SEARCH
function SimaSearch({ users, organizations, canMasquerade, value }) {
  function submitSearch(searchTerm) {
    if (searchTerm.length === 0 || (isNaN(searchTerm) && searchTerm.length < 2)) {
      toaster.error("Searchs must be at least 1 number or 2 characters long.");
    } else {
      simaData.simaSearch(searchTerm);
    }
  }

  function getUsers() {
    let userList = [
      <ListItem key="00" className="sima-list-header">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">User</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Enrollments</Typography>
          </Grid>
        </Grid>
      </ListItem>
    ];

    for (let i = 0; i < users.length; i++) {
      let user = users[i];
      let enrollments = user.enrollments || [];
      for (let j = 0; j < enrollments.length; j++) {
        let enrollment = enrollments[j];
        userList.push(
          <Blink href={"/sima/users/" + user.id + "/enrollments/" + enrollment.id} key={`${i}_${j}`}>
            <ListItem className="sima-review-item">
              <Grid container>
                <Grid item xs={6}>
                  {user.name}
                </Grid>
                <Grid item xs={6}>
                  {enrollment.programName}
                </Grid>
              </Grid>
            </ListItem>
          </Blink>
        );
      }
    }

    return <List>{userList}</List>;
  }

  return (
    <Grid container className="sima-outer-container">
      <Grid item xs={12}>
        <Grid container className="sima-container" justifyContent="center" alignItems="flex-start">
          <Grid item lg={6} md={8} sm={10} xs={12}>
            <SearchBar onSearch={submitSearch} defaultValue={value} className="sima-searchbar" />
          </Grid>
          <Grid item xs={12}>
            <Divider className="sima-divider" />
          </Grid>
          <Grid item xs={12}>
            {users && users.length > 0 && getUsers()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SimaSearch;
