import React, { useState, useEffect } from "react";
import { reportData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import TotalStudentProgress from "./totalStudentProgress.js";

function TotalStudentProgressLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Total Student Progress");
      setData(d);
      setLoading(false);
    }

    reportData.loadAllOrganizationProgress(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <TotalStudentProgress dateRan={data.dateRan} organizations={data.organizations} />
    </BaseWrapper>
  );
}

export default TotalStudentProgressLoader;
