import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import TraineesReview from "./components/traineesReview.js";

function TraineeReviewLoader({}) {
  const { scope } = useParams();
  const [error, setError] = useState(null);
  const [trainees, setTrainees] = useState(null);
  const [originalTrainees, setOriginalTrainees] = useState(null);
  const [offTrack, setOffTrack] = useState(false);

  useEffect(() => {
    function _setTrainees(data) {
      const newTrainees = data.map(t => ({
        ...t,
        icon:
          t.assessmentWaitingOnTraineeRequiringCoach > 0
            ? t.traineeAssessments14DaysCount === 0
              ? "1"
              : t.traineeAssessments7DaysCount === 0
              ? "2"
              : "3"
            : "3"
      }));
      setTrainees(newTrainees);
      setOriginalTrainees(newTrainees);
    }

    setPageTitle("Associate Faculty Trainees");
    if (scope && scope === "all") {
      reviewData.loadAllAfTrainees(
        data => {
          _setTrainees(data.trainees);
        },
        e => {
          setError(e.response.data.error);
        }
      );
    } else {
      reviewData.loadAfTrainees(
        data => {
          _setTrainees(data.trainees);
        },
        e => {
          setError(e.response.data.error);
        }
      );
    }
  }, []);

  function _setOffTrack(status) {
    let newTrainees = JSON.parse(JSON.stringify(originalTrainees));
    if (status) newTrainees = newTrainees.filter(t => t.icon === "1" || t.icon === "2");
    setTrainees(newTrainees);
    setOffTrack(status);
  }

  if (!trainees || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <TraineesReview trainees={trainees} offTrack={offTrack} setOffTrack={_setOffTrack} />
    </BaseWrapper>
  );
}

export default TraineeReviewLoader;
