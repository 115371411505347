import React, { useEffect } from "react";
import { loadScript } from "bild-utils";
import { Blink, NormalButton } from "bild-ui";

import Grid from "@mui/material/Grid";

import "./vendor.css";
function EbookstoreReader({ documentId, documentAuthToken, language, page, downloadable }) {
  var preview;

  useEffect(()=>{
    function _onScriptLoad() {
      preview = new window.Box.Preview();
      const FILE_ID = documentId;
      let previewOptions = { container: ".preview-container" };
      const fileOptions = {
        [FILE_ID]: {
          startAt: {
            value: page,
            unit: "pages"
          }
        }
      };

      if (page) {
        previewOptions.fileOptions = fileOptions;
      }

      if (downloadable) {
        previewOptions.showDownload = true;
      }

      preview.show(FILE_ID, documentAuthToken, previewOptions);
    }

    loadScript("https://cdn01.boxcdn.net/platform/preview/2.11.0/en-US/preview.js", "boxViewjs", null, _onScriptLoad);
  },[])

  return (
    <>
      <div
        className="page-controls"
        style={{ height: "50px", position: "absolute", top: "48px", zIndex: "999", background: "transparent !important" }}
      >
        <Grid container item xs>
          <Blink dst={"library"} language_code={language}>
            <p
              className="btn btn-default fa"
              title="Library"
              style={{
                margin: "5px",
                borderRadius: "10px",
                cursor: "pointer",
                background: "#4b4b4b",
                color: "#fff",
                textDecoration: "none",
                padding: "6px 12px",
                marginLeft: "10px",
                marginTop: "10px"
              }}
            >
              <i className="fa fa-chevron-left" aria-hidden="true" style={{ fontSize: "13px" }} />
              &nbsp;
              <i className="fa fa-book" />
            </p>
          </Blink>
        </Grid>
      </div>
      <div
        className="page-controls"
        style={{ height: "50px", position: "absolute", top: "58px", right: "40px", zIndex: "999", background: "transparent !important" }}
      >
        <Grid container item xs>
          <span className="beta-badge" style={{ fontSize: "1.2rem", opacity: "0.4" }}>
            BETA
          </span>
        </Grid>
      </div>
      {downloadable && (
        <div className="page-controls" style={{ position: "absolute", bottom: "10px", paddingLeft: "10px", zIndex: "999", opacity: "0.95" }}>
          <NormalButton
            variant="micro"
            onClick={() => {
              preview.download();
            }}
          >
            <i className="fas fa-download" style={{ padding: "4px 8px", color: "black" }} />
          </NormalButton>
        </div>
      )}
      <div style={{ width: "100%", height: "100vh", marginTop: "-48px" }}>
        <link rel="stylesheet" href="https://cdn01.boxcdn.net/platform/preview/2.11.0/en-US/preview.css" />
        <div className="preview-container" style={{ width: "100%", height: "100%" }} />
      </div>
    </>
  );
}

export default EbookstoreReader;
