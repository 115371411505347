import React, { useState } from "react";
import { localStorage } from "bild-data";
import { colors, BTypography, NormalButton, Expander, FormTextField } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const baseURL = process.env.REACT_APP_BC2_API_URL;

const useStyles = makeStyles({
  header: props => ({
    backgroundColor: props.color,
    backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  }),
  closeButton: {
    padding: "1rem"
  },
  programTitle: {
    padding: "1rem 0",
    color: colors.white,
    fontWeight: "bold",
    textAlign: "center"
  },
  programColorWrapper: {
    padding: "0"
  },
  programColorBlock: props => ({
    background: props.color,
    width: "25px",
    height: "25px",
    display: "inline-block",
    marginRight: "0.5rem"
  }),
  programColor: props => ({
    padding: "1rem",
    background: colors.white,
    color: props.color
  }),
  subHeader: { background: colors.white },
  badge: { border: `2px solid ${colors.lightGray}`, maxHeight: "5rem" },
  badgeImage: { height: "75px" },
  bodyWrapper: {},
  fields: { padding: "0.5rem" },
  pdfWrapper: { padding: "0.5rem 0 0.5rem 0.5rem" },
  embeddedPdf: { padding: "0.5rem", background: colors.white },
  fieldRow: { padding: "0.5rem", background: colors.white },
  label: { padding: "0 1rem 0 0" }
});

function PreviewCertificate({ certificate, onClose }) {
  const path = certificate.path;
  const backgroundURL = path.backgroundImageURL;
  const color = path.programColor.webFrontendValue;
  const cls = useStyles({ backgroundURL, color });
  const [locked, setLocked] = useState(true);

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header}>
        <Grid item>
          <NormalButton variant="primary" hoverColor={colors.darkGray} labelColor={colors.white} hoverLabelColor={colors.white} onClick={onClose} className={cls.closeButton}>
            <i className="fas fa-arrow-left fa-2x" />
          </NormalButton>
        </Grid>
        <Grid item xs>
          <BTypography variant="h3" className={cls.programTitle}>
            {ReactHtmlParser(path.name)}
          </BTypography>
        </Grid>
        <Grid container item xs={12} className={cls.subHeader} justifyContent="center" alignItems="center">
          <Grid container item sm={6} xs={12} className={cls.programColorWrapper}>
            <Grid container item xs className={cls.programColor} justifyContent="flex-start" alignItems="center">
              <Grid item xs={12}>
                <BTypography variant="h4">Program Color:</BTypography>
              </Grid>
              <Grid item>
                <span className={cls.programColorBlock}>&nbsp;</span>
              </Grid>
              <Grid item>
                <BTypography variant="h5">{color}</BTypography>
              </Grid>
            </Grid>
          </Grid>
          {path.badges && (
            <Grid container item sm={6} xs={12} className={cls.badgeWrapper} justifyContent="flex-end" alignItems="center">
              {JSON.parse(path.badges).map((b, i) => (
                <Grid item className={cls.badge} key={i}>
                  <img src={b.imageURL} className={cls.badgeImage} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.bodyWrapper} justifyContent="flex-start" alignItems="stretch">
        <Grid container item md={9} sm={12} xs={12} className={cls.pdfWrapper}>
          <Grid container item xs={12} className={cls.embeddedPdf}>
            <Grid item xs={12}>
              <iframe
                src={
                  baseURL +
                  certificate.certificateTemplateURL +
                  "?auth_token=" +
                  localStorage.getAPIToken() +
                  "&toolbar=0&navpanes=0&scrollbar=0&view=fitV"
                }
                width="100%"
                height="450px"
              />
            </Grid>
            <Grid container item xs={12}>
              <FormTextField label={"Name:"} value={certificate.name ? certificate.name : " "} setValue={() => {}} disabled={locked} color={color} />
            </Grid>
            <Grid container item xs={12}>
              <FormTextField
                label={"Location:"}
                value={certificate.certificateTemplateLocation ? certificate.certificateTemplateLocation : " "}
                setValue={() => {}}
                disabled={locked}
                color={color}
              />
            </Grid>
          </Grid>
        </Grid>
        {certificate.certificateFields.map((x, i) => {
          return (
            <Grid container item md={3} sm={12} xs={12} className={cls.fields} key={i}>
              <Grid container item xs={12} className={cls.fieldRow}>
                <Grid item xs={12}>
                  <BTypography variant="h5">Field #{i + 1}</BTypography>
                </Grid>
                <Grid container item xs={12}>
                  <FormTextField
                    label={"Description:"}
                    value={x.description ? x.description : " "}
                    setValue={() => {}}
                    disabled={locked}
                    color={color}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <FormTextField
                    label={"Backend Code:"}
                    value={x.backendCode ? x.backendCode : " "}
                    setValue={() => {}}
                    disabled={locked}
                    color={color}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <FormTextField
                    label={"Form Field Name:"}
                    value={x.formFieldName ? x.formFieldName : " "}
                    setValue={() => {}}
                    disabled={locked}
                    color={color}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <FormTextField label={"Type:"} value={x.type ? x.type : " "} setValue={() => {}} disabled={locked} color={color} />
                </Grid>
                <Grid container item xs={12}>
                  <FormTextField label={"Position X:"} value={x.positionX ? x.positionX : " "} setValue={() => {}} disabled={locked} color={color} />
                </Grid>
                <Grid container item xs={12}>
                  <FormTextField label={"Position Y:"} value={x.positionY ? x.positionY : " "} setValue={() => {}} disabled={locked} color={color} />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default PreviewCertificate;
