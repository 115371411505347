import React, { useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BTypography, BaseWrapper, NormalButton, UserAvatar, colors } from "bild-ui";
import ContentWrapper from "presentational/wrappers/contentWrapper.js";
import AuditAntiochTranscriptCourseCompetencyProofs from "./components/auditAntiochTranscriptCourseCompetencyProofs";
import AuditAntiochTranscriptCourse from "./components/auditAntiochTranscriptCourse";

const useStyles = makeStyles({
  headerWrapper: { position: "sticky", top: "0", zIndex: "10" },
  header: { background: colors.white, padding: "0.5rem 0 0 0" },
  headerBottom: { background: colors.white, opacity: "0.75", height: "1rem" },
  courseGroup: { width: "100%" },
  button: { padding: "0 1rem 0.25rem 0" },
  totals: { maxWidth: "20rem" },
  course: { height: "100%", width: "100%" },
  courseContent: { height: "100%" },
  competencySet: { paddingTop: "0.5rem" }
});

export default function AuditAntiochCourses({ user, courseGroups, totals }) {
  const cls = useStyles();
  const [courseView, setCourseView] = useState(true);

  // Memoize tabs to greatly increase performance
  function memoTab0() {
    function renderCourses(courses) {
      const components = [];
      for (var i = 0; i < courses.length; i++) {
        components.push(
          <AuditAntiochTranscriptCourse user={user} course={courses[i]} key={i} />
        );
      }
      return components;
    }

    const coursesComponent = [];
    for (let i = 0; i < courseGroups.length; i++) {
      const group = courseGroups[i];
      let courses = [];
      let groupColor = group.courses[0].courseGroupColor.webFrontendValue;

      courses = renderCourses(group.courses);

      coursesComponent.push(
        <ContentWrapper variant="flat" key={i} className={cls.courseGroup}>
          <Typography variant="h5" gutterBottom style={{ color: groupColor }}>
            {group.name}
          </Typography>
          <Grid container spacing={2}>
            {courses}
          </Grid>
        </ContentWrapper>
      );
    }

    return (coursesComponent);
  }
  function memoTab1() {
    function renderCompetencies(courses) {
      const components = [];
      for (var i = 0; i < courses.length; i++) {
        components.push(
          <AuditAntiochTranscriptCourseCompetencyProofs course={courses[i]} user={user} showCourseNumber={true} showHandbook={true} key={i} />
        );
      }
      return components;
    }
  
    const components = [];
    for (let i = 0; i < courseGroups.length; i++) {
      const group = courseGroups[i];
      let courses = [];
      let groupColor = group.courses[0].courseGroupColor.webFrontendValue;
  
      courses = renderCompetencies(group.courses);
      components.push(
        <ContentWrapper variant="flat" key={i} className={cls.courseGroup}>
          <Typography variant="h5" gutterBottom style={{ color: groupColor }}>
            {group.name}
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            {courses}
          </Grid>
        </ContentWrapper>
      );
    }

    return (components);
  }
  
  const tab0 = useMemo(() => memoTab0(), [user, courseGroups,]);
  const tab1 = useMemo(() => memoTab1(), [user, courseGroups,]);


  return (
    <BaseWrapper>
      <Grid container item xs={12} className={cls.wrapper}>
        <Grid container item xs={12} className={cls.headerWrapper}>
          <Grid container item xs={12} className={cls.header} justifyContent={"space-between"} alignItems={"center"}>
            <Grid item>
              <UserAvatar size={50} src={user && user.avatarURL} name={user && user.name} nameVariant="h3" badgeSrc={user && user.badgeURL} />
            </Grid>
            <Grid item>
              <BTypography variant="h3">&nbsp; {courseView ? "Courses" : "Competencies"}</BTypography>
            </Grid>
            <Grid container item xs justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Grid item className={cls.button}>
                <NormalButton
                  variant={"primary"}
                  color={colors.bildBlue}
                  labelColor={colors.white}
                  onClick={()=>{setCourseView(!courseView)}}
                >
                  <i className={courseView ? "fad fa-th-list" : "fad fa-th-large"} />
                </NormalButton>
              </Grid>
              <Grid item className={cls.totals}>
                <Grid item> <BTypography variant="h6">Completion Totals</BTypography> </Grid>
                <Grid item> <b>{totals.totalCoursesCompleted}</b> / {totals.totalCourses} Courses</Grid>
                <Grid item> <b>{totals.totalCompetenciesCompleted}</b> / {totals.totalCompetencies} Competencies</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={cls.headerBottom}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {courseView && tab0}
          {!courseView && tab1}
        </Grid>
      </Grid>
    </BaseWrapper>
  );
}
