import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reviewData, userData } from "bild-data";
import { hideIntercom, setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import TrainingModulesReview from "./components/trainingModulesReview.js";

function TrainingModulesReviewLoader({}) {
  const { user_id } = useParams();
  const [error, setError] = useState(null);
  const [trainingModules, setTrainingModules] = useState(null);
  const [trainee, setTrainee] = useState(null);

  useEffect(() => {
    setPageTitle("Associate Faculty Training Modules Overview");
    hideIntercom();

    userData.loadUserProfile(user_id, data => setTrainee(data.name));

    reviewData.loadAfTrainingModulesReview(
      user_id,
      data => {
        setTrainingModules(data.trainingModuleOverviews);
      },
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  if (!trainingModules || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <TrainingModulesReview trainingModules={trainingModules} trainee={trainee} />
    </BaseWrapper>
  );
}

export default TrainingModulesReviewLoader;
