import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { simaData, userData, courseData, ENUMS } from "bild-data";
import SimaUser from "./simaUser.js";

// SIMA USER LOADER
function SimaUserLoader({}) {
  const { user_id, enrollment_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [course, setCourse] = useState(null);
  const [competency, setCompetency] = useState(null);
  const [competencyProof, setCompetencyProof] = useState(null);
  const [files, setFiles] = useState(null);

  useEffect(()=>{
    setPageTitle("SIMA User");

    function _setCourse(data) {
      let competencies = data.competencies;
      let c = null;
      for (let i = 0; i < competencies.length; i++) {
        if (competencies[i].assessmentFlow.id === ENUMS.ASSESSMENT.FLOWS.AS_STAFF.ID) {
          c = competencies[i];
        }
      }

      if (c) {
        setCourse(data);
        setCompetency(c);
        setCompetencyProof(c.competencyProof);
        setFiles(c.competencyProof ? c.competencyProof.files : []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    userData.loadUserProfile(user_id, setUser);
    simaData.loadSimaUser(enrollment_id, _setCourse, setError);
  },[]);

  function onAttach(competency_id, attachments, success_cb, error_cb) {
    courseData.attachArtifact(
      competency_id,
      user.id,
      attachments,
      data => {
        mapCompetency(data);
        if (typeof success_cb === "function") success_cb();
      },
      data => {
        if (typeof error_cb === "function") error_cb(data);
      }
    );
  }

  function onFileUpload(competency_id, enrollment_id, attachments, success_cb, error_cb) {
    simaData.attachSimaArtifact(
      competency_id,
      enrollment_id,
      attachments,
      data => {
        mapCompetency(data);
        if (typeof success_cb === "function") success_cb();
      },
      data => {
        if (typeof error_cb === "function") error_cb(data);
      }
    );
  }

  function onFileRemove(competency_id, user_id, attachment, success_cb, error_cb) {
    simaData.removeSimaArtifact(
      competency_id,
      user_id,
      attachment,
      data => {
        mapCompetency(data);
        if (typeof success_cb === "function") success_cb();
      },
      data => {
        if (typeof error_cb === "function") error_cb(data);
      }
    );
  }

  function onFinalizeSima(competency, success_cb, error_cb) {
    const overview = userData.getOverview() || {};
    const callingUser = overview.user;
    simaData.onFinalizeSima(
      competency,
      callingUser,
      data => {
        mapCompetency(data);
        if (typeof success_cb === "function") success_cb();
      },
      data => {
        if (typeof error_cb === "function") error_cb(data);
      }
    );
  }

  function mapCompetency(data) {
    setCompetency(data);
    setCompetencyProof(data.competencyProof);
    setFiles(data.competencyProof.files);
  }

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <SimaUser
        enrollmentId={enrollment_id}
        course={course}
        competency={competency}
        competencyProof={competencyProof}
        user={user}
        files={files}
        onAttach={onAttach}
        onFileUpload={onFileUpload}
        onFileRemove={onFileRemove}
        onFinalizeSima={onFinalizeSima}
        mapCompetency={mapCompetency}
      />
    );
  }
}

export default SimaUserLoader;
