import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, FormTextField, FormField, CountrySelect, NormalButton, BTypography } from "bild-ui";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from "@mui/material";

const useStyles = makeStyles({
  submitButton: { margin: "0.5rem 1rem", padding: "1rem 2rem" },
  formWrapper: { paddingTop: "1rem", borderRadius: "0.25rem" },
  fieldWrapper: {
    padding: "0.5rem",
    " & input, & .MuiAutocomplete-inputRoot ": {
      background: colors.white
    }
  },
  titleWrapper: {
    color: colors.white,
    backgroundColor: colors.darkGray,
    padding: "1rem 1.5rem 1rem 2rem",
  },
  groupWrapper: { marginTop: "1rem", paddingTop: "1rem", borderTop: `0.2rem solid ${colors.veryLightGray}` }
});

export default function ManageOrganizationProfileDialog({ open, onClose, organization, updateOrganization }) {
  const cls = useStyles();
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm({
      organizationId: organization.organizationId,
      organizationName: organization.organizationName,
      nameOnCertificate: organization.nameOnCertificate,
      phone: organization.phone,
      addressLine1: organization.addressLine1,
      addressLine2: organization.addressLine2,
      city: organization.city,
      stateProvince: organization.stateProvince,
      zip: organization.zip,
      country: organization.country,
      groupLevel1Name: organization.groupLevel1Name,
      groupLevel2Name: organization.groupLevel2Name,
      groupLevel3Name: organization.groupLevel3Name
    });
  }, [organization]);

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleSubmit() {
    // NOTE: May want validation in the future
    updateOrganization(form);
    onClose();
  }

  function makeField(label, field, placeholder) {
    return (
      <Grid item sm={6} xs={12} className={cls.fieldWrapper}>
        <FormTextField
          label={label}
          placeholder={placeholder ? placeholder : " "}
          value={form[field]}
          setValue={newValue => {
            _handleChange(field, newValue);
          }}
        />
      </Grid>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle className={cls.titleWrapper}>
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs className={cls.title}>
            <BTypography variant="h4">{organization.organizationName}</BTypography>
          </Grid>
          <Grid item>
            <NormalButton
              onClick={onClose}
              variant={"micro"}
              color={colors.transparent}
              labelColor={colors.white}
              hoverColor={colors.darkerGray}
              hoverLabelColor={colors.white}
            >
              <i className="far fa-times fa-2x" />
            </NormalButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} className={cls.formWrapper}>
          {makeField("Name on Certificate", "nameOnCertificate")}
          {makeField("Address Line 1", "addressLine1")}
          {makeField("Address Line 2", "addressLine2")}
          {makeField("City", "city")}
          {makeField("State / Region", "stateProvince")}
          {makeField("Postal Code", "zip")}
          {makeField("Phone", "phone")}
          <Grid item sm={6} xs={12} className={cls.fieldWrapper}>
            <FormField
              label="Country"
              child={
                <CountrySelect
                  value={form.country}
                  setValue={d => {
                    setForm({ ...form, country: d });
                  }}
                />
              }
            />
          </Grid>
          <Grid container item xs={12} className={cls.groupWrapper}>
            {makeField("Hub Name", "groupLevel3Name", "Hub")}
            {makeField("Cluster Name", "groupLevel2Name", "Cluster")}
            {makeField("Node Name", "groupLevel1Name", "Node")}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
          <Grid item>
            <NormalButton
              variant="primary"
              color={colors.bildBlue}
              labelColor={colors.white}
              hoverColor={colors.darkBildBlue}
              className={cls.submitButton}
              onClick={_handleSubmit}
            >
                Update
            </NormalButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
