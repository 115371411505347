class UploadIndicator {
  constructor(name) {
    this.name = name;
    this.status = "active";

    this.success = this.success.bind(this);
    this.fail = this.fail.bind(this);
  }

  success = () => (this.status = "success");
  fail = () => (this.status = "fail");
}

class UploadManager {
  constructor() {
    this.indicators = new Map();
    this._change = this._change.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.conveySuccess = this.conveySuccess.bind(this);
    this.conveyFail = this.conveyFail.bind(this);
    this.getIndicators = this.getIndicators.bind(this);
  }

  _change() {
    if (this.onChange) this.onChange();
  }

  add(name) {
    if (!this.indicators.has(name)) this.indicators.set(name, new UploadIndicator(name));
    this._change();
  }

  remove(name) {
    if (this.indicators.has(name)) this.indicators.delete(name);
    this._change();
  }

  conveySuccess(name) {
    if (this.indicators.has(name)) this.indicators.get(name).success();
    setTimeout(() => this.remove(name), 3000);
    this._change();
  }

  conveyFail(name) {
    if (this.indicators.has(name)) this.indicators.get(name).fail();
    setTimeout(() => this.remove(name), 3000);
    this._change();
  }

  getIndicators() {
    return this.indicators.values();
  }
}
const uploadManager = new UploadManager();
export default uploadManager;
