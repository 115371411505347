import React from "react";
import Typography from "@mui/material/Typography";

import "./rolePucks.css";

const RolePucks = function({ prefixes }) {
  if (!prefixes || !prefixes.length) {
    return null;
  }

  const stPucks = [];
  const clPucks = [];
  const afPucks = [];
  for (let i = 0; i < prefixes.length; i++) {
    const prefix = prefixes[i].toUpperCase();

    // TODO: class badge will soon be colored based on the role, not pass/fail
    let puckClass = "puck ";
    if (prefix === "STLM" || prefix === "ASR") {
      puckClass += " double-puck ";
    }
    const puck = (
      <Typography className={puckClass} key={i}>
        {prefix}
      </Typography>
    );

    switch (prefix) {
      case "STLM":
      case "ST":
        stPucks.push(puck);
        break;
      case "LM":
        clPucks.push(puck);
        break;
      case "AF":
      case "AS":
      case "ASR":
        afPucks.push(puck);
        break;
      default:
        // skip
        break;
    }
  }

  return (
    <div className="role-pucks">
      <span className="st-pucks puck-stack">{stPucks}</span>
      <span className="cl-pucks puck-stack">{clPucks}</span>
      <span className="af-pucks puck-stack">{afPucks}</span>
    </div>
  );
};

export default RolePucks;
