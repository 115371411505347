import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

import { Slate } from "slate-react";

import { Grid } from "@mui/material";

import { ArtifactToolbar, constants, useFullPageEditor } from ".";

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "100%",
    border: `1px solid ${colors.gray}`,
    borderRadius: "1px",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2), inset 0 0 2px rgba(0, 0, 0, 0.1)"
  },
  editable: { height: "100%", padding: "1rem" }
});

function ArtifactEditorDummy({ placeholder }) {
  const classes = useStyles();
  const editor = useFullPageEditor();

  return (
    <Slate editor={editor}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <ArtifactToolbar placeholder={placeholder} marks={constants.marks} blocks={constants.blocks} saved={true} withFonts dummyMode />
        </Grid>
      </Grid>
    </Slate>
  );
}

export default ArtifactEditorDummy;
