import React, { useState, useEffect } from "react";
import { colors,  Blink, NormalButton, BTypography, TreeDiagram} from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import ManageUsers from "modules/management/manageUsers/manageUsers.js";
import ManageOrganizationTabs from "./components/manageOrganizationTabs.js";
import AntiochOrganizationUsers from "./components/antiochOrganizationUsers.js";
import OrgUserCertificates from "modules/certificates/userCertificates/orgUserCertificates.js";
import ManageOrganizationRoles from "./components/manageOrganizationRoles.js";
import ManageOrganizationProfileDialog from "./components/manageOrganizationProfileDialog.js";
import ManageOrganizationGroups from "./components/manageOrganizationGroups.js";

const useStyles = makeStyles({
  wrapper: {},
  header: { padding: "1rem", backgroundColor: colors.white },
  subHeader: { padding: "1rem 0 0 0" },
  orgs: { padding: "0.5rem 0" },
  org: { "&:hover": { cursor: "pointer !important" } },
  orgButton: { padding: "0 0.25rem" },
  popperArrow: { color: colors.bildBlue },
  tooltip: { backgroundColor: colors.lightGray, color: colors.black, border: `1px solid ${colors.bildBlue}` },
  rolesIcon: { color: colors.bildBlue },
  roles: { margin: "0", padding: "0.1rem 0.1rem 0.1rem 0.5rem" },
  button: { padding: "0.25rem 0 0.25rem 0.5rem" },
  diagramHeader: { background: colors.offWhite, padding: "1rem 1rem" },
  diagram: { backgroundColor: colors.white, marginBottom: "1rem" },
  missingDiagram: { padding: "5rem", textAlign: "center" },
  detailButton: { height: "100%", width: "100%", maxWidth: "15rem" },
  headerButton: { padding: "0.25rem" },
  addGroupWrapper: { background: colors.white, padding: "1rem" }
});

function ManageOrganization({
  org,
  orgUpdate,
  orgTree,
  users,
  setUsers,
  archivedUsers,
  orgs,
  orgGroups,
  orgGroupTypes,
  orgGroupUpdate,
  roles,
  userRoles,
  inheritedUserRoles,
  resendEmail,
  archiveUser,
  certs,
  antiochOrg,
  setAntiochOrg,
  defaultTab,
  setDefaultTab
}) {
  const cls = useStyles();
  const [tab, setTab] = useState(defaultTab && defaultTab <= 6 ? defaultTab : 1);
  const [profileDialog, setProfileDialog] = useState(false);

  useEffect(() => {
    const keyPressHandler = e => {
      if (e.altKey === true) {
        if (e.keyCode === 49) {
          setTab(0);
        } else if (e.keyCode === 50) {
          setTab(1);
        } else if (e.keyCode === 51) {
          setTab(2);
        } else if (e.keyCode === 52) {
          setTab(3);
        } else if (e.keyCode === 53) {
          setTab(4);
        } else if (e.keyCode === 54) {
          setTab(5);
        } else if (e.keyCode === 57) {
          setTab(5);
        }
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, []);

  function _setTab(t) {
    setTab(t);
    if (typeof setDefaultTab === "function") setDefaultTab(t);
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} justifyContent="center" alignItems="center">
        <Grid item xs={2}>
          &nbsp;
        </Grid>
        <Grid container item xs={7} justifyContent="center" alignItems="center">
          <Grid item className={`${cls.icon} fa-stack fa-2x`}>
            <i className="fal fa-square fa-stack-2x" />
            <i className="fal fa-users fa-stack-1x" />
          </Grid>
          <Grid item className={cls.name}>
            <BTypography variant="h3">{org.organizationName}</BTypography>
          </Grid>
        </Grid>
        <Grid container item xs={3}>
          <Grid item className={cls.headerButton}>
            <NormalButton
              variant="secondary"
              color={colors.white}
              labelColor={colors.bildGray}
              borderColor={colors.bildGray}
              hoverLabelColor={colors.bildGray}
              onClick={()=>{setProfileDialog(!profileDialog)}}
            >
              <i className="fas fa-cog" />
            </NormalButton>
          </Grid>
          <Grid item className={cls.headerButton}>
            <NormalButton
              component={Blink}
              dst={"manage-organization-activity"}
              organization_id={org.organizationId}
              variant="secondary"
              color={colors.white}
              labelColor={colors.bildGray}
              borderColor={colors.bildGray}
              hoverLabelColor={colors.bildGray}
            >
              <i className="fas fa-tachometer-alt-fast" />
            </NormalButton>
          </Grid>
          <Grid item className={cls.headerButton}>
            <NormalButton
              component={Blink}
              dst={"manage-organization-world-map"}
              organization_id={org.organizationId}
              variant="secondary"
              color={colors.white}
              labelColor={colors.bildGray}
              borderColor={colors.bildGray}
              hoverLabelColor={colors.bildGray}
            >
              <i className="fas fa-map" />
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.tabs}>
        <ManageOrganizationTabs tab={tab} setTab={_setTab} groupName={orgGroups && orgGroups.level1Name} />
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        {tab === 1 && (
          <ManageUsers
            users={users}
            setUsers={setUsers}
            archivedUsers={archivedUsers}
            orgs={orgs}
            org={org}
            roles={roles}
            resendEmail={resendEmail}
            archiveUser={archiveUser}
          />
        )}
        {tab === 2 && (
          <ManageOrganizationGroups
            org={org}
            orgGroups={orgGroups}
            orgGroupUpdate={orgGroupUpdate}
            users={users}

          />
        )}
        {tab === 3 && (
          <Grid container item xs={12}>
            <Grid item xs={12} className={cls.diagram}>
              {orgTree && orgTree.length > 0 && <TreeDiagram data={orgTree} legend={true} scalable={true} highlightId={org.organizationId}/>}
              {(!orgTree || orgTree.length < 1) && (
                <Grid item xs={12} className={cls.missingDiagram}>
                  <BTypography variant="h5">This Organization is not yet a part of a network.</BTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {tab === 4 && <OrgUserCertificates organization={org} certificates={certs} />}
        {tab === 5 && <AntiochOrganizationUsers antiochOrg={antiochOrg} setAntiochOrg={setAntiochOrg} />}
        {tab === 6 && <ManageOrganizationRoles userRoles={userRoles} inheritedUserRoles={inheritedUserRoles} />}
      </Grid>
      <ManageOrganizationProfileDialog
        open={profileDialog}
        onClose={()=>{setProfileDialog(false)}}
        organization={org}
        updateOrganization={orgUpdate}
      />
    </Grid>
  );
}

export default ManageOrganization;
