import React from "react";
import { Grid, Typography } from "@mui/material";
import CourseWrapper from "presentational/wrappers/courseWrapper.js";
import classes from "../classes.js";

// SELECT OVERVIEW COURSE
function SelectOverviewCourse ({ course, genSwitchCourse }) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={() => {
        genSwitchCourse(course, true);
      }}
    >
      <CourseWrapper variant="overview" className={classes.overview.addCourse}>
        <Grid container direction="column" justifyContent="center" style={{ height: "100%" }}>
          <Grid item xs={12} className={classes.overview.row}>
            <Typography variant="h1">
              <i className="fal fa-plus" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.overview.row}>
            <Typography variant="h6">Add a Course</Typography>
          </Grid>
        </Grid>
      </CourseWrapper>
    </Grid>
  );
}

export default SelectOverviewCourse;
