import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, FormTextField, FormField, CountrySelect, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  submitButton: { margin: "0.5rem", padding: "1rem" },
  formWrapper: { padding: "0.5rem", borderRadius: "0.25rem", background: colors.offWhite },
  fieldWrapper: {
    padding: "0.5rem",
    " & input, & .MuiAutocomplete-inputRoot ": {
      background: colors.white
    }
  }
});

function OrganizationAdminProfile({ organization, updateOrganization }) {
  const cls = useStyles();
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm({
      organizationId: organization.organizationId,
      organizationName: organization.organizationName,
      nameOnCertificate: organization.nameOnCertificate,
      phone: organization.phone,
      addressLine1: organization.addressLine1,
      addressLine2: organization.addressLine2,
      city: organization.city,
      stateProvince: organization.stateProvince,
      zip: organization.zip,
      country: organization.country,
      gpsLocation: organization.gpsLocation,
      groupLevel1Name: organization.groupLevel1Name,
      groupLevel2Name: organization.groupLevel2Name,
      groupLevel3Name: organization.groupLevel3Name
    });
  }, [organization]);

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleSubmit() {
    // NOTE: May want validation in the future
    updateOrganization(form);
  }

  function makeField(label, field, placeholder) {
    return (
      <Grid item sm={6} xs={12} className={cls.fieldWrapper}>
        <FormTextField
          label={label}
          placeholder={placeholder ? placeholder : " "}
          value={form[field]}
          setValue={newValue => {
            _handleChange(field, newValue);
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12} className={cls.formWrapper}>
      {makeField("Name", "organizationName")}
      {makeField("Partner Name (Name on Certificate)", "nameOnCertificate")}
      {makeField("Address Line 1", "addressLine1")}
      {makeField("Address Line 2", "addressLine2")}
      {makeField("City", "city")}
      {makeField("State / Region", "stateProvince")}
      {makeField("Postal Code", "zip")}
      {makeField("Phone", "phone")}
      <Grid item sm={6} xs={12} className={cls.fieldWrapper}>
        <FormField
          label="Country"
          child={
            <CountrySelect
              value={form.country}
              setValue={d => {
                setForm({ ...form, country: d });
              }}
            />
          }
        />
      </Grid>
      {makeField("GPS", "gpsLocation")}
      {makeField("Hub Name", "groupLevel3Name", "Hub")}
      {makeField("Cluster Name", "groupLevel2Name", "Cluster")}
      {makeField("Node Name", "groupLevel1Name", "Node")}
      <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <NormalButton
            variant="primary"
            color={colors.bildBlue}
            labelColor={colors.white}
            hoverColor={colors.darkBildBlue}
            className={cls.submitButton}
            onClick={_handleSubmit}
          >
            Update Profile
          </NormalButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrganizationAdminProfile;
