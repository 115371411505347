/* CIVILIZATIONS */
import civ1Min from "./civ1_min_data.json";
import civ2Min from "./civ2_min_data.json";
import civ3Min from "./civ3_min_data.json";
import civ4Min from "./civ4_min_data.json";
import civ5Min from "./civ5_min_data.json";
import civ6Min from "./civ6_min_data.json";
import civ7Min from "./civ7_min_data.json";
import civ8Min from "./civ8_min_data.json";
import civ9Min from "./civ9_min_data.json";

// NOTE: The data was collected first from the worldGeoJson JS library, then copied to https://mapshaper.org/ , the processed with dissolve and exported with precision=0.01 

// Western
const CIVILIZATION_1 = [
  civ1Min
];

// Islamic
const CIVILIZATION_2 = [
  civ2Min
];

// Sinic
const CIVILIZATION_3 = [
  civ3Min
];

// Orthodox
const CIVILIZATION_4 = [
  civ4Min
];

// African
const CIVILIZATION_5 = [
  civ5Min
];

// Hindu
const CIVILIZATION_6 = [
  civ6Min
];

// Buddhist
const CIVILIZATION_7 = [
  civ7Min
];

// Latin America
const CIVILIZATION_8 = [
  civ8Min
];

// Japenese
const CIVILIZATION_9 = [
  civ9Min
];


Object.freeze(CIVILIZATION_1);
Object.freeze(CIVILIZATION_2);
Object.freeze(CIVILIZATION_3);
Object.freeze(CIVILIZATION_4);
Object.freeze(CIVILIZATION_5);
Object.freeze(CIVILIZATION_6);
Object.freeze(CIVILIZATION_7);
Object.freeze(CIVILIZATION_8);
Object.freeze(CIVILIZATION_9);


export {
  CIVILIZATION_1,
  CIVILIZATION_2,
  CIVILIZATION_3,
  CIVILIZATION_4,
  CIVILIZATION_5,
  CIVILIZATION_6,
  CIVILIZATION_7,
  CIVILIZATION_8,
  CIVILIZATION_9
};
  