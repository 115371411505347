import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  in: props => ({
    transition: "all 1s ease, opacity 0.5s ease",
    maxHeight: "10000px",
    maxWidth: "10000px",
    opacity: "1",
    justifyContent: props.justify
  }),
  out: {
    transition: "all 0.5s ease, opacity 0.25s ease",
    maxHeight: "0",
    maxWidth: "0",
    opacity: "0"
  },
  first: {
    maxHeight: "0",
    maxWidth: "0",
    opacity: "0"
  }
});

function Expander({ showFirst, expandIn, children, justify }) {
  const cls = useStyles({ justify });
  const [expand, setExpand] = useState(null);
  const [firstLoad, setFirstLoad] = useState(!showFirst ? true : false);

  useEffect(() => {
    if (firstLoad) {
      // Quickly hide on first load only
      setFirstLoad(false);
      setExpand(cls.first);
    } else {
      expandIn ? setExpand(cls.in) : setExpand(cls.out);
    }
  }, [expandIn]);

  return (
    <Grid container item xs={12} className={expand}>
      {!firstLoad && children}
    </Grid>
  );
}

export default Expander;
