import React from "react";
import { makeStyles } from "@mui/styles";

import Droppable from "bild-utils/dragDrop/droppable.js";
import { parseFolioPayload } from "bild-utils/dragDrop/dragDrop.js";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  container: { marginBottom: "1rem" },
  icon: { margin: "0 0.5rem" },
  clickable: { cursor: "pointer", "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" } },
  crumbItem: { display: "inline-block", padding: "0.25rem 0.5rem", borderRadius: "4px" },
  crumbItemDraggedOver: { display: "inline-block", padding: "0.25rem 0.5rem", borderRadius: "4px", backgroundColor: "rgba(0,0,0,0.1)" }
});

function BreadCrumbs({ crumbs, onGoToFolder, onMoveItem, className, rootTitle }) {
  const classes = useStyles();

  // Needs to be own component for draggedOver to be accessible.
  function CrumbItem({ name, draggedOver, onClick, className: c }) {
    draggedOver = draggedOver === "true";
    let rootClass = c + " " + (draggedOver ? classes.crumbItemDraggedOver : classes.crumbItem);

    return (
      <Typography variant="h5" className={rootClass} onClick={onClick}>
        {name}
      </Typography>
    );
  }

  function _onDropItemFunc(destination) {
    return function(payload) {
      const [type, id] = parseFolioPayload(payload);
      if (onMoveItem) {
        onMoveItem(type, id, destination);
      }
    };
  }

  const components = [];
  for (let i = 0; i < crumbs.length; i++) {
    const c = crumbs[i];
    const crumbProps = c.artificial ? {} : { className: classes.clickable, onClick: () => onGoToFolder(c) };
    const cname = i === 0 && rootTitle ? rootTitle : c.name;
    components.push(
      <span key={i}>
        {i > 0 && <span className={classes.icon}>/</span>}
        <Droppable inline onDrop={_onDropItemFunc(c)} disabled={c.artificial}>
          <CrumbItem name={cname} {...crumbProps} />
        </Droppable>
      </span>
    );
  }

  return (
    <Grid container alignItems="baseline" className={`${classes.container} ${className}`}>
      {components}
    </Grid>
  );
}

export default BreadCrumbs;
