import React from "react";
import { colors, FormField } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Select, MenuItem } from "@mui/material";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  field: { width: "100%" },
  errorWrapper: { color: colors.red, "& fieldset": { borderColor: colors.red } }
});

function FormSelectField({ label, value, setValue, items, disabled, error, required, classes, color }) {
  const cls = useStyles();

  return (
    <FormField
      label={label}
      color={color}
      error={error}
      required={required}
      child={
        <Select
          variant="outlined"
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
          className={`${cls.field} ${classes}`}
          disabled={disabled}
        >
          {items.map((x, i) => (
            <MenuItem value={x.id} key={i}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
}

export default FormSelectField;
