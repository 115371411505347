import React, { useState, useEffect } from "react";
import { filterList, setPageTitle } from "bild-utils";
import { Blink } from "bild-ui";

import { Grid, Typography, Checkbox, List, ListItem, CircularProgress, Tooltip, Badge } from "@mui/material";
import "./organizations.css";

// ORGANIZATION USERS
function OrganizationUsers({ initialUsers, filterValue }) {
  const [users, setUsers] = useState([]);
  const [showInactive, setShowInactive] = useState(false);
  const [showMentor, setShowMentor] = useState(false);

  useEffect(() => {
    setPageTitle("Organization Users");
    let newUsers = initialUsers;

    if (filterValue) {
      newUsers = filterList(newUsers, "name", filterValue);
    }
    if (!showInactive) {
      newUsers = newUsers.filter(u => u.enrollments.some(u2 => u2.enabled === true));
    }

    setShowMentor(newUsers.some(x => x.enrollments[0].mentorUsers.length > 0));

    setUsers(newUsers);
  }, [initialUsers, filterValue, showInactive]);

  function getUsers() {
    const userList = [];

    userList.push(
      <ListItem key="00" className="organization-header">
        <Grid container>
          <Grid item md={showMentor ? 2 : 3} xs={showMentor ? 2 : 3}>
            <Typography variant="subtitle1">Student</Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography
              variant="subtitle1"
              onClick={() => {
                this.filterStatus();
              }}
            >
              Status
            </Typography>
          </Grid>
          <Grid item md={showMentor ? 2 : 3} xs={showMentor ? 2 : 3}>
            <Typography variant="subtitle1">Program</Typography>
          </Grid>
          {showMentor && (
            <Grid item md={2} xs={2}>
              <Typography variant="subtitle1">Mentor</Typography>
            </Grid>
          )}
          <Grid item md={2} xs={2}>
            <Typography variant="subtitle1">Local Mentor</Typography>
          </Grid>
          <Grid item md={2} xs={2}>
            <Typography variant="subtitle1">Associate Faculty</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );

    for (let i = 0; i < users.length; i++) {
      const u = users[i];
      let statuses = [];
      let enrollments = [];
      let cls = [];
      let afs = [];
      let mentors = [];
      for (let i = 0; i < u.enrollments.length; i++) {
        let e = u.enrollments[i];
        if (showInactive || e.enabled === true) {
          statuses.push(
            <ListItem key={i} dense disableGutters>
              <Tooltip disableFocusListener title={e.status} placement="top">
                <Typography className={e.enabled ? "green" : "red"}>
                  <i className="fas fa-circle" />
                  {e.enabled ? "Active" : "Inactive"}
                </Typography>
              </Tooltip>
            </ListItem>
          );
          enrollments.push(
            <ListItem className="link" dense disableGutters component={Blink} dst={"enrollment"} enrollment_id={e.id} key={i}>
              <Typography variant="body1">{e.programClassName}</Typography>
            </ListItem>
          );
          cls.push(
            <ListItem key={i} dense disableGutters>
              <Grid container alignItems="center">
                <Grid item className="badge-grid">
                  <Badge
                    color={e.awaitingInitialReviewCount > 0 ? "secondary" : "primary"}
                    className="review-badge"
                    badgeContent={e.awaitingInitialReviewCount}
                    overlap="rectangular"
                  >
                    <span />
                  </Badge>
                </Grid>
                <Grid item xs={true} component={Typography} variant="body1">
                  {e.clUser.name}
                </Grid>
              </Grid>
            </ListItem>
          );
          afs.push(
            <ListItem key={i} dense disableGutters>
              <Grid container alignItems="center">
                <Grid item className="badge-grid">
                  <Badge
                    color={e.awaitingValidationReviewCount > 0 ? "secondary" : "primary"}
                    className="review-badge"
                    badgeContent={e.awaitingValidationReviewCount}
                    overlap="rectangular"
                  >
                    <span />
                  </Badge>
                </Grid>
                <Grid item xs={true} component={Typography} variant="body1">
                  {e.afUser.name}
                </Grid>
              </Grid>
            </ListItem>
          );
          if (e.mentorUsers.length > 0) {
            for (let i = 0; i < e.mentorUsers.length; i++) {
              mentors.push(
                <ListItem key={i} dense disableGutters>
                  <Grid container alignItems="center">
                    <Grid item xs={true} component={Typography} variant="body1">
                      {e.mentorUsers[i].name}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }
          } else {
            mentors.push(
              <ListItem key={i} dense disableGutters>
                &nbsp;
              </ListItem>
            );
          }
        }
      }

      userList.push(
        <ListItem className="organization-item" key={i}>
          <Grid container>
            <Grid item md={showMentor ? 2 : 3} xs={showMentor ? 2 : 3} className="student">
              <Typography variant="body1">{u.name}</Typography>
            </Grid>
            <Grid item md={2} xs={2} className="status">
              <List disablePadding>{statuses}</List>
            </Grid>
            <Grid item md={showMentor ? 2 : 3} xs={showMentor ? 2 : 3} className="enrollments">
              <List disablePadding>{enrollments}</List>
            </Grid>
            {showMentor && (
              <Grid item md={2} xs={2} className="mentor">
                <List disablePadding>{mentors}</List>
              </Grid>
            )}
            <Grid item md={2} xs={2} className="local-mentor">
              <List disablePadding>{cls}</List>
            </Grid>
            <Grid item md={2} xs={2} className="associate-faculty">
              <List disablePadding>{afs}</List>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    if (users.length < 1) {
      userList.push(
        <ListItem key="1" className="organization-empty">
          <Grid container>
            <Grid item md={12} xs={12}>
              <Typography variant="body1">No users found.</Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    return <List disablePadding>{userList}</List>;
  }

  if (users) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" className="organization">
        <Grid container item xs={12} className="list-actions" justifyContent="flex-end">
          <Grid item>
            <label className="checkbox">
              Show Inactive <Checkbox checked={showInactive} onClick={() => setShowInactive(!showInactive)} />
            </label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {getUsers()}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: "100%" }}>
        <CircularProgress size={100} />
        <h3>Loading...</h3>
      </Grid>
    );
  }
}

export default OrganizationUsers;
