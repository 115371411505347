import { getRequest } from "bild-data/core/comm.js";

export function validateUserCertificate(lastName, validationCode, success_cb, error_cb) {
  // Special call to allow public or logged in access to certificate validation
  getRequest(
    `/certificates/validation?last_name=${lastName}&validation_code=${validationCode}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    },
    true, // skipRetry
    true // skipPreFlight
  );
}
