import React from "react";
import { colors, NormalButton, Blink, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  wrapper: { paddingBottom: "0.5rem", width: "100%" },
  pathButton: {
    borderRadius: "4px",
    border: `1px solid ${colors.darkGray}`,
    padding: "1rem"
  },
  button: {
    backgroundColor: colors.progressGreen,
    color: colors.white,
    borderRadius: "0px",
    padding: "1rem 2rem",
    "&:hover": { backgroundColor: colors.darkerGreen, color: colors.white }
  },
  buttonLeft: { paddingRight: "0.5rem" }
});

function UserPathItemNavigation({ userPathId, breadcrumb, lastItem, nextItem }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      <Grid container item sm={6} xs={12} justifyContent="flex-start" alignItems="center" className={classes.column}>
        <Grid item>
          <NormalButton
            variant="simple"
            labelColor={colors.darkGray}
            className={classes.pathButton}
            component={Blink}
            dst="equip-user-path"
            user_path_id={userPathId}
            breadcrumb={breadcrumb}
          >
            <i className={`fas fa-arrow-left`} />
          </NormalButton>
        </Grid>
        {lastItem && (
          <Grid container item xs>
            <NormalButton className={classes.button} component={Blink} dst="equip-user-path-item" user_path_item_id={lastItem.id}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item className={classes.buttonLeft}>
                  <i className={`fas fa-arrow-left ${classes.icon}`} />
                </Grid>
                <Grid item xs>
                  <BTypography vairant="button">{lastItem.name}</BTypography>
                </Grid>
              </Grid>
            </NormalButton>
          </Grid>
        )}
      </Grid>
      <Grid container item sm={6} xs={12} justifyContent="flex-end" alignItems="center" className={classes.column}>
        {nextItem && (
          <Grid item>
            <NormalButton className={classes.button} component={Blink} dst="equip-user-path-item" user_path_item_id={nextItem.id}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs className={classes.buttonLeft}>
                  <BTypography vairant="button">{nextItem.name}</BTypography>
                </Grid>
                <Grid item>
                  <i className={`fas fa-arrow-right ${classes.icon}`} />
                </Grid>
              </Grid>
            </NormalButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default UserPathItemNavigation;
