import React, { useState, useEffect } from "react";
import { managerData } from "bild-data";
import { checkAdmin } from "bild-data/user";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import ManageAllOrganizations from "./manageAllOrganizations.js";

function ManageAllOrganizationsLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [users, setUsers] = useState([]);
  const [orgTree, setOrgTree] = useState([]);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    setPageTitle("All Organization Management");

    function _setData(m) {
      setOrgTree(m.organizationRelationships);
      setOrgs(m.availableOrganizations);
      setLoading(false);
    }

    managerData.getAllManagedOrganizations(_setData, e => {
      setError(e.response.data.error);
    });

    managerData.getManagedUsers((m)=>{setUsers(m.users)}, e => {
      setError(e.response.data.error);
    });
  }, [reload]);

  function createOrganization(newOrg) {
    if (checkAdmin()) {
      let data = {
        organizations: [
          {
            organizationName: newOrg.name,
            addressLine1: newOrg.addressLine1,
            addressLine2: newOrg.addressLine2,
            city: newOrg.city,
            stateProvince: newOrg.state,
            zip: newOrg.zip,
            country: newOrg.country,
            phone: newOrg.phone,
            parentOrganization: { organizationId: newOrg.parentOrg },
            localEntityType: { id: newOrg.organizationType },
            primaryUser: { id: newOrg.primaryUser }
          }
        ]
      };
      managerData.createManagedOrganization(data, d => {
        toaster.success(`Organization Created:  ${newOrg.name}!`);
        setReload(reload + 1);
        setLoading(true);
      });
    }
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <ManageAllOrganizations
      orgTree={orgTree}
      orgs={orgs}
      users={users}
      setUsers={setUsers}
      createOrganization={createOrganization}
      isAdmin={checkAdmin()}
    />
  );
}

export default ManageAllOrganizationsLoader;
