import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors, NormalButton } from "bild-ui";

const useStyles = makeStyles({
  button: { marginBottom: "1rem" }
});

function CompetencyMessagePicker({ userLearningItem, openMessage }) {
  const cls = useStyles();

  return (
    <Grid container item xs={12}>
      <NormalButton
        variant="primary"
        labelColor={colors.black}
        borderColor={colors.black}
        className={cls.button}
        onClick={() => {
          openMessage(null);
        }}
        fullWidth
      >
        Discuss with others
      </NormalButton>
    </Grid>
  );
}

export default CompetencyMessagePicker;
