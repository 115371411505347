import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { useSlate } from "slate-react";

import { Icon, IconButton } from "@mui/material";

import { toggleBlock, isBlockActive } from "..";

const useStyles = makeStyles({
  icon: {
    fontSize: "1rem",
    color: colors.black
  },
  active: {
    backgroundColor: colors.lightGray
  },
  button: { height: "30px", width: "35px", padding: 0, borderRadius: 0 }
});

function BlockButton({ block, className, ...props }) {
  const classes = useStyles();
  const editor = useSlate();

  let fontAwesome;
  switch (block) {
    case "numbered-list":
      fontAwesome = "fas fa-list-ol";
      break;
    case "bulleted-list":
      fontAwesome = "fas fa-list-ul";
      break;
    case "left":
      fontAwesome = "fas fa-align-left";
      break;
    case "center":
      fontAwesome = "fas fa-align-center";
      break;
    case "right":
      fontAwesome = "fas fa-align-right";
      break;
    case "justify":
      fontAwesome = "fas fa-align-justify";
      break;
    default:
      return null;
  }

  const active = isBlockActive(editor, block);
  const icClass = `${classes.button} ${className} ${active ? classes.active : ""}`;
  const iClass = `${fontAwesome} ${classes.icon}`;

  return (
    <IconButton
      {...props}
      className={icClass}
      onClick={() => {
        toggleBlock(editor, block);
      }}
      size="large">
      <Icon className={iClass} />
    </IconButton>
  );
}

export default BlockButton;
