import React, { useState, useEffect, useMemo } from "react";
import { colors, BTypography, PathProgress, NormalButton, ProgramBadge, Expander, UserAvatar } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { Grid, Divider, useMediaQuery } from "@mui/material";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import LearningGroup from "./components/learningGroup.js";
import LearningPathSteps from "./components/learningPathSteps.js";
import { downloadRequest } from "bild-data/core/comm.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    path: { paddingBottom: "4rem" },
    pathColumnTop: {
      padding: "2rem 2rem 1rem 2rem",
      [theme.breakpoints.down('md')]: {
        padding: "1rem"
      }
    },
    pathColumnLeft: {
      padding: "1rem 0.75rem 2rem 2rem",
      [theme.breakpoints.down('md')]: {
        padding: "1rem"
      }
    },
    pathColumnRight: {
      padding: "1rem 2rem 2rem 0.75rem",
      [theme.breakpoints.down('md')]: {
        padding: "1rem"
      }
    },
    pathColumnCenter: {
      padding: "1rem 2rem 2rem 2rem",
      [theme.breakpoints.down('md')]: {
        padding: "1rem"
      }
    },
    programTitleWrapper: props => ({
      padding: "2rem",
      backgroundColor: props.color,
      backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
      backgroundPositionY: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      transition: "all 5s ease"
    }),
    programTitle: { color: colors.white, fontWeight: "bold" },
    programSubtitle: { color: colors.white },
    pathGroups: props => ({
      marginTop: "2rem",
      paddingBottom: "2rem",
      borderLeft: `3px solid ${props.color}`,
      maxWidth: "50rem",
    }),
    pathOverviewGroup: props => ({
      maxWidth: "150rem !important",
    }),
    pathBlock: { backgroundColor: colors.white, padding: "2rem 2rem 1rem 2rem" },
    progressWrapper: { marginTop: "0.5rem", padding: "0.5rem 2rem", background: colors.white },
    progressBar: { paddingRight: "0.5rem" },
    progressTitle: props => ({ fontWeight: "bold", color: props.color, padding: "0.5rem 1rem 0.5rem 0" }),
    pathPreTitle: { fontWeight: "bold" },
    pathTitle: props => ({ marginTop: "1rem", fontWeight: "bold", color: props.color }),
    pathSubtitle: props => ({ marginTop: "0.5rem", color: props.color }),
    shepherdUser: props => ({ padding: "1rem 0", color: props.color }),
    divider: { margin: "1.5rem 0" },
    pathObjectives: { padding: "0" },
    viewOnlyTag: props => ({
      position: "absolute",
      backgroundColor: props.color,
      border: `1px solid ${colors.white}`,
      color: colors.white,
      padding: "0.5rem 1rem",
      marginTop: "4.5rem",
      fontWeight: "bold",
      zIndex: "1000",
      opacity: "0.9"
    }),
    avatarName: { paddingLeft: "0.25rem" },
    tabsWrapper: {},
    tabs: props => ({ backgroundColor: colors.white, borderRadius: "8px", border: `3px solid ${props.color}` }),
    leftTab: { borderTopRightRadius: "0", borderBottomRightRadius: "0" },
    middleTab: { borderRadius: "0" },
    rightTab: { borderTopLeftRadius: "0", borderBottomLeftRadius: "0" },
    empty: { padding: "2rem", backgroundColor: colors.white },
    badgeWrapper: { padding: "0" },
    badge: { padding: "0.25rem 0" }
  })
);

function UserPath({ userPath, user, groups, objectives, color, backgroundURL, defaultTab, defaultGroup, defaultItem }) {
  const theme = useTheme();
  const cls = useStyles({ color, backgroundURL });
  const allTabNum = 0;
  const coreTabNum = 1;
  const coreGroupTabNum = 2;
  const descriptionTabNum = 3;
  const [showGroupTab, setShowGroupTab] = useState(false);
  const [tab, setTab] = useState(defaultTab !== null ? defaultTab : coreTabNum);
  const oneColumn = useMediaQuery(theme.breakpoints.down('md'));
  const twoColumn = useMediaQuery(theme.breakpoints.up("md"));
  const soloColumn = true; // NOTE: override for now, until we settle on new design
  const [defaultItemLoaded, setDefaultItemLoaded] = useState(false);

  useEffect(()=>{
    if (groups.length > 0) {
      // Collect items
      let items = [];
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        for (let j = 0; j < group.userPathItems.length; j++) {
          let item = group.userPathItems[j];
          if (item.required) {
            items.push(item);
          }
        }
      }
      if (items.length > 6) {
        setShowGroupTab(true);
      }
    }
  },[groups]);

  // Scroll to Location of Default Group if one is passed in
  useEffect(() => {
    // If there is a defaultItem to load and it exists in the DOM
    if (defaultItemLoaded) {
      let d = document.getElementById("default-open-group-item");
      // Verify the item was obtained
      if (d) {
        // Scroll the view to the item
        d.scrollIntoView({
          behavior: "instant",
          block: "center",
          inline: "nearest"
        });
      }
    }
  }, [defaultItemLoaded]);

  // Memoize tabs to greatly increase performance
  function memoTab0() {
    return groups.map((x, i) => {
      let isDefaultGroup = defaultGroup !== null && defaultTab === allTabNum && defaultGroup === i ? true : false;
      return (
        <LearningGroup
          group={x}
          items={x.userPathItems}
          key={x.name}
          color={color}
          bcTab={allTabNum}
          bcGroup={i}
          defaultOpen={isDefaultGroup || groups.length === 1}
          defaultGroup={isDefaultGroup ? true : false}
          defaultGroupItem={isDefaultGroup ? defaultItem : null}
          setDefaultItemLoaded={setDefaultItemLoaded}
        />
      );
    });
  }
  function memoTab1() {
    // per jsents: Go through each group and find any item that is required and that is concidered "core"
    if (groups.length > 0) {
      // Collect items
      let items = [];
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        for (let j = 0; j < group.userPathItems.length; j++) {
          let item = group.userPathItems[j];
          if (item.required) {
            let newItem = JSON.parse(JSON.stringify(item));
            newItem.userLearningItem.name = newItem.userLearningItem.name;
            items.push(newItem);
          }
        }
      }
      if (items.length > 0) {
        // Create group
        let newGroup = JSON.parse(JSON.stringify(groups[0]));
        newGroup.name = "Core Assessments";
        newGroup.subtitle = "";
        newGroup.description = "";
        newGroup.totalSteps = items.length;
        newGroup.totalCompletedSteps = items.filter(x => x.completed).length;
        newGroup.totalInProgressSteps = items.filter(x => !x.completed && x.userLearningItem.inProgress).length;
        newGroup.nextStep = items.filter(x => !x.completed)[0];
        newGroup.userPathItems = items;
        let isDefaultGroup = defaultGroup !== null && defaultTab === coreTabNum && defaultGroup === 0 ? true : false;
        return (
          <LearningGroup
            group={newGroup}
            items={items}
            color={color}
            defaultOpen={true}
            coreGroup={true}
            bcTab={coreTabNum}
            bcGroup={0}
            defaultGroup={isDefaultGroup ? true : false}
            defaultGroupItem={defaultTab === 1 ? defaultItem : null}
            setDefaultItemLoaded={setDefaultItemLoaded}
          />
        );
      } else {
        return (
          <BTypography variant="h6" className={cls.empty}>
            There are no core Assessments for this program.
          </BTypography>
        );
      }
    }
  }
  function memoTab2() {
    // per jsents: Go through each group and find any item that is required and that is concidered "core"
    let coreGroups = [];
    for (let i=0; i<groups.length; i++) {
      let group = groups[i];
      let isDefaultGroup = defaultGroup !== null && defaultTab === allTabNum && defaultGroup === i ? true : false;

      // Collect items
      let items = [];
      for (let k = 0; k < group.userPathItems.length; k++) {
        let item = group.userPathItems[k];
        if (item.required) {
          let newItem = JSON.parse(JSON.stringify(item));
          newItem.userLearningItem.name = newItem.userLearningItem.name;
          items.push(newItem);
        }
      }

      if (items.length > 0) {
        let newGroup = JSON.parse(JSON.stringify(groups[0]));
        newGroup.name = group.name;
        newGroup.subtitle = group.subtitle;
        newGroup.description = group.description;
        newGroup.resourceImageURL = group.resourceImageURL;
        newGroup.totalSteps = items.length;
        newGroup.totalCompletedSteps = items.filter(x => x.completed).length;
        newGroup.totalInProgressSteps = items.filter(x => !x.completed && x.userLearningItem.inProgress).length;
        newGroup.nextStep = items.filter(x => !x.completed)[0];
        newGroup.userPathItems = items;
        coreGroups.push(newGroup);
      }
    };
    
    return <LearningPathSteps groups={coreGroups} color={color}/>
  }
  function memoTab3() {
    return (
      <Grid container>
        <Grid item xs={12} className={cls.pathBlock}>
          <BTypography variant="h6" className={cls.pathPreTitle}>
            Program
          </BTypography>
          <BTypography variant="h3" className={cls.pathTitle}>
            {ReactHtmlParser(userPath.title)}
          </BTypography>
          <BTypography variant="subtitle1" className={cls.pathSubtitle}>
            {ReactHtmlParser(userPath.subtitle)}
          </BTypography>
          <Divider className={cls.divider} />
          <BTypography variant="body1">{ReactHtmlParser(userPath.description)}</BTypography>
          <ul className={cls.pathObjectives}>{ReactHtmlParser(objectives)}</ul>
        </Grid>
        <Grid container item xs={12} className={cls.pathBlock}>
          <Grid item xs={12}>
            <Divider className={cls.divider} />
            <BTypography variant="h6" className={cls.pathPreTitle}>
              Shepherd
            </BTypography>
          </Grid>
          <Grid item xs={12} className={cls.shepherdUser}>
            <UserAvatar size={50} src={userPath.shepherdUser.avatarURL} name={userPath.shepherdUser.name} badgeSrc={userPath.shepherdUser.badgeURL}  nameVariant={"h4"} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  const tab0 = useMemo(() => memoTab0(), [groups, color]);
  const tab1 = useMemo(() => memoTab1(), [groups, color]);
  const tab2 = useMemo(() => memoTab2(), [groups, color]);
  const tab3 = useMemo(() => memoTab3(), [groups, color]);
  const badges = userPath.badges && (
    <Grid container item xs={12} className={cls.badgeWrapper}>
      {JSON.parse(userPath.badges).map((b, i) => (
        <Grid item xs={12} className={cls.badge} key={i}>
          <ProgramBadge color={color} badgeURL={b.imageURL} completed={userPath.completed} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container justifyContent="center" alignItems="flex-start" className={cls.path}>
      {userPath.user.id !== user.id && (
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item className={cls.viewOnlyTag}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <UserAvatar size={30} src={userPath.user.avatarURL} name={userPath.user.name} badgeSrc={userPath.user.badgeURL} srcSize="small" />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} className={cls.pathColumnTop}>
        <Grid container item xs={12} className={cls.programTitleWrapper}>
          <Grid item xs={12}>
            <BTypography variant="h3" className={cls.programTitle}>
              {ReactHtmlParser(userPath.title)}
            </BTypography>
          </Grid>
          <Grid item xs={12}>
            <BTypography variant="subtitle1" className={cls.programSubtitle}>
              {ReactHtmlParser(userPath.subtitle)}
            </BTypography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={cls.progressWrapper} justifyContent="center" alignItems="center">
          <Grid container item sm={7} xs={12} justifyContent="center" alignItems="center">
            <Grid item>
              <BTypography variant="subtitle1" className={cls.progressTitle}>
                Progress
              </BTypography>
            </Grid>
            <Grid item xs className={cls.progressBar}>
              <PathProgress
                totalCompletedCoreSteps={userPath.totalCompletedCoreSteps}
                totalInProgressCoreSteps={userPath.totalInProgressCoreSteps}
                totalCoreSteps={userPath.totalCoreSteps}
                totalCompletedSteps={userPath.totalCompletedSteps}
                totalInProgressSteps={userPath.totalInProgressSteps}
                totalSteps={userPath.totalSteps}
              />
            </Grid>
          </Grid>
          <Grid container item sm={5} xs={12} justifyContent={"space-around"} alignItems={"center"}>
            <Grid item>
              {badges}
            </Grid>
            <Grid item>
              {userPath.user.id === user.id && userPath.certificates && userPath.certificates.map((c, i) => (
                <Grid item xs={12} className={cls.certificate} key={i}>
                  <NormalButton
                    variant={"secondary"}
                    color={colors.white}
                    labelColor={color}
                    borderColor={color}
                    hoverLabelColor={colors.white}
                    hoverColor={color}
                    onClick={() => {
                      downloadRequest(c.downloadURL, c.name);
                    }}
                  >
                    <i className="fas fa-download" />
                  </NormalButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sm={12} className={soloColumn ? cls.pathColumnCenter : cls.pathColumnLeft} justifyContent="center" alignItems="flex-start">
        <Grid container item xs={12} className={cls.tabsWrapper}>
          <Grid container item xs={12} className={cls.tabs}>
            <Grid
              item
              xs={showGroupTab ? 3 : 4}
            >
              <NormalButton
                fullWidth
                color={tab === coreTabNum ? color : colors.white}
                hoverColor={color}
                labelColor={tab === coreTabNum ? colors.white : color}
                hoverLabelColor={colors.white}
                onClick={() => {
                  setTab(coreTabNum);
                }}
                variant="simple"
                className={`${cls.leftTab}`}
              >
                Core
              </NormalButton>
            </Grid>
            <Grid
              item
              xs={showGroupTab ? 3 : 4}
            >
              <NormalButton
                fullWidth
                color={tab === allTabNum ? color : colors.white}
                hoverColor={color}
                labelColor={tab === allTabNum ? colors.white : color}
                hoverLabelColor={colors.white}
                onClick={() => {
                  setTab(allTabNum);
                }}
                variant="simple"
                className={`${cls.middleTab}`}
              >
                All
              </NormalButton>
            </Grid>
            {showGroupTab && (
              <Grid
                item
                xs={3}
              >
                <NormalButton
                  fullWidth
                  color={tab === coreGroupTabNum ? color : colors.white}
                  hoverColor={color}
                  labelColor={tab === coreGroupTabNum ? colors.white : color}
                  hoverLabelColor={colors.white}
                  onClick={() => {
                    setTab(coreGroupTabNum);
                  }}
                  variant="simple"
                  className={`${cls.middleTab}`}
                >
                  Overview
                </NormalButton>
              </Grid>
            )}
            <Grid
              item
              xs={showGroupTab ? 3 : 4}
            >
              <NormalButton
                fullWidth
                color={tab === descriptionTabNum ? color : colors.white}
                hoverColor={color}
                labelColor={tab === descriptionTabNum ? colors.white : color}
                hoverLabelColor={colors.white}
                onClick={() => {
                  setTab(descriptionTabNum);
                }}
                variant="simple"
                className={`${cls.rightTab}`}
              >
                Description
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${cls.pathGroups} ${tab === coreGroupTabNum ? cls.pathOverviewGroup : ""}`}>
          {tab === allTabNum && tab0}
          {tab === coreTabNum && tab1}
          {tab === coreGroupTabNum && tab2}
          {tab === descriptionTabNum && tab3}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserPath;
