import React, { useState, useEffect } from "react";
import { access } from "bild-utils";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Paper, Typography, Grid, Divider, Icon, Hidden } from "@mui/material";

import CompetencyProgressCircles from "containers/course//parts/competencyProgressCircles.js";
import CompetencyProofRoles from "containers/course/parts/competencyProofRoles.js";
import Competency from "containers/competency/competency";
import { BTypography, UserAvatar, colors } from "bild-ui";


const useStyles = makeStyles({
  container: { minHeight: "100%", padding: "1rem" },
  userAvatar: { paddingBottom: "1rem" },
  course: { marginBottom: "1rem", "& button:disabled": { opacity: "0.5" } },
  courseHeader: { color: "#fff", padding: "10px 15px", borderTopRightRadius: "4px", borderTopLeftRadius: "4px", fontSize: "18px" },
  courseNumber: { color: "#fff" },
  familyIcon: { textAlign: "right", marginLeft: "15px" },
  courseBody: { padding: "1rem" },
  courseDescription: { marginBottom: "1rem" },
  competencySet: { textAlign: "right" },
  competenciesHeader: { margin: "1rem 0", padding: "0 1rem" },
  competenciesHeaderTitle: {
    // Trickery to respect different alignments set by Nate's mockup.
    position: "relative",
    left: "-1rem",
    display: "flex",
    alignItems: "center"
  },
  checkInCircle: {
    // this could all just be replaced by a svg or something
    marginRight: "0.5rem",
    textAlign: "center",
    lineHeight: "50px",
    verticalAlign: "center",
    fontSize: "29px",
    height: "50px",
    width: "50px",
    borderRadius: "50vh",
    border: "2px solid black"
  },
  rolesContainer: {},
  programInfo: { marginBottom: "1rem", background: colors.white, padding: "1rem 1rem" },
  breadcrumb: { display: "flex", alignItems: "center" },
  breadcrumbCaret: { fontSize: "14px", margin: "5px" }
});

function prepareCourseData(data) {
  const {
    id,
    courseNumber,
    name,
    description,
    competencySet,
    callingUserRoles,
    enrollmentId,
    assessmentLevelId,
    competencies,
    localMentorUser,
    associateFacultyUser,

    color,
    icon,
    studentId,
    studentName
  } = {
    ...(data || {}),
    color: access(data, "courseGroupColor.webFrontendValue", ""),
    icon: access(data, "familyGroupIcon.webFrontendValue", ""),
    studentId: access(data, "competencies[0].competencyProof.user.id"), // fetch if in data
    studentName: access(data, "competencies[0].competencyProof.user.name") // fetch if in data
  };

  return {
    id,
    courseNumber,
    name,
    description,
    competencySet,
    callingUserRoles,
    enrollmentId,
    assessmentLevelId,
    competencies,
    localMentorUser,
    associateFacultyUser,

    color,
    icon,
    studentId,
    studentName
  };
}

export default function AuditAntiochCourse({ course: initialCourse, callingUser, studentUser, docId, setDocId, fileLoading, enrollmentId, scrollToCompetencyProofId }) {
  const cls = useStyles();

  const [course, _internalSetCourse] = useState(prepareCourseData(initialCourse));
  const setCourse = c => _internalSetCourse(prepareCourseData(c));

  // TODO: Antioch FE ?? -- Get degree info on course page (seperate call)
  const {
    studentName: nameFromData,
    studentId: idFromData,
    competencies,
    courseNumber,
    name,
    description,
    competencySet,
    callingUserRoles,
    color,
    icon
  } = course || {};

  const competencyComponents = course.competencies.map((c, i) => (
    <Competency
      course={course}
      competency={c}
      enrollmentId={enrollmentId}
      callingUser={callingUser}
      docId={docId}
      setDocId={setDocId}
      fileLoading={fileLoading}
      key={i}
      scrollTo={scrollToCompetencyProofId && c.competencyProof && c.competencyProof.id.toString() === scrollToCompetencyProofId.toString()}
      auditView={true}
    />
  ));

  return (
    <div className={cls.container}>
      <Grid container justifyContent="space-between" alignItems="center" className={cls.programInfo}>
        <Grid container item xs>
          <Grid item xs={12} className={cls.userAvatar}>
            <UserAvatar size={40} src={studentUser && studentUser.avatarURL} name={studentUser && studentUser.name} nameVariant="h3" badgeSrc={studentUser && studentUser.badgeURL} />
          </Grid>
          <Grid item xs={12}>
            <BTypography variant="h6">Handbook <b>{course.competencySet.handbookName}</b></BTypography>
          </Grid>
        </Grid>
        <Grid container item xs justifyContent={"flex-end"} alignItems={"flex-start"}>
          <Grid item>
            <img alt="School Logo" crop="fill" height="51" src={process.env.PUBLIC_URL + "/antioch_school_logo.png"} width="217" />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item>
          
        </Grid>
      </Grid>

      <Paper className={cls.course} key={1}>
        <Grid container justifyContent="space-between" className={cls.courseHeader} style={{ backgroundColor: color }}>
          <Grid item>
            <CompetencyProgressCircles competencies={competencies} />
          </Grid>
          <Grid item>
            <Typography variant="h6" className={cls.courseNumber}>
              {courseNumber}
              <span className={cls.familyIcon}>
                <i className={"fas " + icon} />
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <div className={cls.courseBody}>
          <Grid container>
            <Grid item xs className={cls.courseDescription}>
              <Typography variant="h5" gutterBottom style={{ color: color }}>
                {ReactHtmlParser(name)}
              </Typography>

              <Typography variant="body2">{ReactHtmlParser(description)}</Typography>
            </Grid>
          </Grid>

          <Divider />

          <div className={cls.competenciesHeader}>
            <Grid container alignItems="center">
              <Grid item lg={9} md={8} sm={7} xs={12}>
                <Typography variant="h6" className={cls.competenciesHeaderTitle} style={{ color: color }}>
                  <Icon className={`fal fa-check ${cls.checkInCircle}`} style={{ color: color, borderColor: color }} />
                  Instructions
                </Typography>
              </Grid>
              <Hidden smDown>
                <Grid item lg={3} md={4} sm={5} xs={12}>
                  <CompetencyProofRoles {...course} />
                </Grid>
              </Hidden>
            </Grid>
          </div>

          {competencyComponents}
        </div>
      </Paper>
    </div>
  );
}
