import { deleteRequest, getRequest, putRequest } from "bild-data/core/comm.js";

export function getManagedUsers(success_cb, error_cb) {
  getRequest(
    `/manage/users`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedUser(user_id, success_cb, error_cb) {
  getRequest(
    `/manage/users/${user_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedUserShepherding(user_id, success_cb, error_cb) {
  getRequest(
    `/manage/users/${user_id}/shepherding`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedUserRecentActivity(user_id, success_cb, error_cb) {
  getRequest(
    `/reports/users_recent_activity/${user_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedOrganizationRecentActivity(org_id, success_cb, error_cb) {
  getRequest(
    `/reports/organizations_recent_activity/${org_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getOrganizationManagedUsers(organization_id, success_cb, error_cb) {
  getRequest(
    `/manage/organizations/${organization_id}/users`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedOrganization(organization_id, success_cb, error_cb) {
  getRequest(
    `/manage/organizations/${organization_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function getManagedOrganizations(success_cb, error_cb) {
  getRequest(
    `/manage/organizations`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getAllManagedOrganizations(success_cb, error_cb) {
  getRequest(
    `/manage/all_organizations`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedOrganizationUserCertificates(orgId, success_cb, error_cb) {
  getRequest(
    `/certificates/organizations/${orgId}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedUserCertificates(userId, success_cb, error_cb) {
  getRequest(
    `/certificates/users/${userId}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedUsersExist(email, success_cb, error_cb) {
  getRequest(
    `/manage/users_exists/${encodeURIComponent(email)}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function createManagedUser(data, success_cb, error_cb) {
  putRequest(
    `/manage/users`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function updateManagedUser(user_id, data, success_cb, error_cb) {
  putRequest(
    `/manage/users/${user_id}`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedGroups(success_cb, error_cb) {
  getRequest(
    `/manage/sharing_groups`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function updateManagedSharingGroups(data, success_cb, error_cb) {
  putRequest(
    `/manage/sharing_groups`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedPaths(success_cb, error_cb) {
  getRequest(
    `/manage/paths`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getManagedPathOutline(path_id, success_cb, error_cb) {
  getRequest(
    `/manage/paths/${path_id}/outline`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getAvailableCertificates(success_cb, error_cb) {
  getRequest(
    `/certificates/available`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function getAllUserCertificates(success_cb, error_cb) {
  getRequest(
    `/certificates/all_user_certificates`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function updateUserCertificates(data, success_cb, error_cb) {
  putRequest(
    `/certificates`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function createUpdateManagedOrganizationGroups(data, success_cb, error_cb) {
  putRequest(
    `/manage/organization_groups`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function createManagedOrganization(data, success_cb, error_cb) {
  putRequest(
    `/manage/organizations`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function updateManagedOrganization(organization_id, data, success_cb, error_cb) {
  putRequest(`/manage/organizations/${organization_id}`, data, success_cb, null, error_cb);
}

export function deleteManagedOrganizations(data, success_cb, error_cb) {
  deleteRequest(`/manage/organizations`, data, success_cb, null, error_cb);
}
