import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { BTypography, LoadingScreen, NormalButton, colors } from "bild-ui";
import { Accordion, AccordionSummary, AccordionDetails, Divider, Tab, Tabs, Typography, Grid } from "@mui/material";
import DashboardMetrics from "./components/dashboardMetrics.js";
import DashboardProgram from "./components/dashboardProgram.js";
import QuickActionsDashboard from "./components/quickActionsDashboard.js";
import CertificateDashboard from "./components/certificateDashboard.js";

import classes from "./classes.js";
import "./dashboard.css";
import { UserPathThumbnails } from "equip/index.js";

const useStyles = makeStyles({
  systemMessage: { padding: "1rem", background: colors.bildBlue, color: colors.white, fontWeight: "bold" },
  panel: { boxShadow: "none", borderTop: "3px solid #d3d4d5", borderRadius: "0" },
  expandIcon: { padding: "1.15rem", fontWeight: "bold", fontSize: "0.85rem" },
  tab: { maxWidth: "100%", opacity: "0.4", color: `${colors.black} !important` },
  activeTab: { maxWidth: "100%", opacity: "1 !important" }
});

// DASHBOARD
function Dashboard({ enrollments, programs, activities, metrics, equip, equipPaths, newCertCount }) {
  const cls = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  function onTabChange(e, tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function renderProgramTabs() {
    // Build the tabs off of the sorted enrollments

    const tabs = [];
    for (let i = 0; i < enrollments.length; i++) {
      const e = enrollments[i];
      // TODO: Antioch FE ?? -- replace hard-coded dashboard program icon with value from future API
      let iconLabel = (
        <Typography variant="subtitle1">
          <img src={process.env.PUBLIC_URL + "/as_icon.png"} alt="as_icon" className={classes.tabIcon} />
          <span>{e.programClassName}</span>
        </Typography>
      );

      tabs.push(<Tab key={i} value={i} label={iconLabel} className={`${cls.tab} ${i === activeTab ? cls.activeTab : ""}`} />);
    }

    return (
      <Tabs value={activeTab} onChange={onTabChange} className={classes.tabContainer} variant="scrollable" scrollButtons={false}>
        {tabs}
      </Tabs>
    );
  }

  return (
    <div className={classes.container}>
      <Accordion defaultExpanded className={`${cls.panel}`}>
        <AccordionSummary expandIcon={<i className={`${cls.expandIcon} far fa-chevron-down fa-xs`} />} classes={classes.expansionPanelHeader}>
          <Typography variant="h5">Dashboard</Typography>
          <Typography variant="body2">View your information at a glance.</Typography>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={classes.expansionPanelBody}>
          <Grid container>
            {newCertCount > 0 && <CertificateDashboard newCertCount={newCertCount} />}
            {metrics && (metrics.studentMetrics || metrics.localMentorMetrics || metrics.associateFacultyMetrics || metrics.coachMetrics) && (
              <DashboardMetrics metrics={metrics} />
            )}
            {!metrics && (
              <LoadingScreen />
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {equip && (
        <Accordion defaultExpanded className={`${cls.panel} expanded-group`}>
          <AccordionSummary expandIcon={<i className={`${cls.expandIcon} far fa-chevron-down fa-xs`} />} classes={classes.expansionPanelHeader}>
            <Typography variant="h5">Equip Programs</Typography>
          </AccordionSummary>

          <Divider />

          <AccordionDetails className={classes.expansionPanelBody}>
            {equipPaths && (
              <UserPathThumbnails paths={equipPaths} dashboardView={true}/>
            )}
            {!equipPaths && (
              <LoadingScreen />
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {programs && programs.length > 0 && (
        <Accordion defaultExpanded className={`${cls.panel} ${classes.programs}`}>
          <AccordionSummary expandIcon={<i className={`${cls.expandIcon} far fa-chevron-down fa-xs`} />} classes={classes.expansionPanelHeader}>
            <Typography variant="h5">Programs</Typography>
            <Typography variant="body2">View your progress in your program at a glance.</Typography>
          </AccordionSummary>

          {renderProgramTabs()}

          <Divider />

          <AccordionDetails className={classes.expansionPanelBody}>
            <DashboardProgram program={programs[activeTab]} />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion defaultExpanded className={`${cls.panel} expanded-group`}>
        <AccordionSummary expandIcon={<i className={`${cls.expandIcon} far fa-chevron-down fa-xs`} />} classes={classes.expansionPanelHeader}>
          <Typography variant="h5">Quick Actions</Typography>
        </AccordionSummary>

        <Divider />

        <AccordionDetails className={classes.expansionPanelBody}>
          <QuickActionsDashboard enrollments={enrollments} equip={equip} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Dashboard;
