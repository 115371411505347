import React, { useState } from "react";
import { hideIntercom, showIntercom } from "bild-utils";
import { colors, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Menu, MenuItem } from "@mui/material";
import AttachArtifactDialog from "containers/folio/attachArtifactDialog.js";

const useStyles = makeStyles({
  button: { color: "blue" }
});

function AttachArtifactDropdown({ user, viewDocument, createDocument, attach, color, editable }) {
  const cls = useStyles();
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [source, setSource] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  function _onClose() {
    showIntercom();
    setSource(null);
    setOpen(false);
    setAnchorEl(null);
  }

  function setStep(step) {
    hideIntercom();
    setCount(count + 1);
    setSource(step);
    setOpen(true);
  }

  function _createDocument() {
    createDocument();
    _onClose();
  }

  function _attach(selectedFiles) {
    setSource(null);
    setOpen(false);
    attach(selectedFiles);
  }

  return (
    <Grid container>
      <NormalButton
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
        variant="simple"
        color={colors.green}
        labelColor={colors.white}
        disabled={!editable}
      >
        <i className="fal fa-plus" /> &nbsp; Add File
      </NormalButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem onClick={_createDocument}>Create</MenuItem>
        <MenuItem
          onClick={() => {
            setStep("folio");
          }}
        >
          Folio
        </MenuItem>
        <MenuItem
          onClick={() => {
            setStep("computer");
          }}
        >
          Device
        </MenuItem>
        <MenuItem
          onClick={() => {
            setStep("google");
          }}
        >
          Google
        </MenuItem>
        <MenuItem
          onClick={() => {
            setStep("dropbox");
          }}
        >
          Dropbox
        </MenuItem>
      </Menu>

      <AttachArtifactDialog
        userId={user.id}
        open={open}
        openCount={count}
        onAttach={_attach}
        initialStage={source ? 1 : 0}
        initialSource={source}
        onClose={_onClose}
      />
    </Grid>
  );
}

export default AttachArtifactDropdown;
