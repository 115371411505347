import React, { useState } from "react";
import { useSlate } from "slate-react";
import { makeStyles } from "@mui/styles";

import { Select, MenuItem } from "@mui/material";

import { setFontFamily } from "..";
import { constants, isFontFamilyActive } from "..";
const { fonts } = constants;
const fontFamilies = fonts.map(f => f.fontFamily);

const useStyles = makeStyles({
  select: { marginLeft: "0.25rem" }
});

function FontFamilySelect() {
  const classes = useStyles();
  const editor = useSlate();

  const [font, setFont] = useState(fonts[0].fontFamily);
  function _changeFontFamily(e) {
    const newFontFamily = e.target.value;
    setFont(newFontFamily);

    setFontFamily(editor, newFontFamily); // send command to editor
  }

  const activeFont = fontFamilies.find(ff => isFontFamilyActive(editor, ff)) || font;

  return (
    <Select value={activeFont} onChange={_changeFontFamily} className={classes.select}>
      {fonts.map((f, i) => (
        <MenuItem value={f.fontFamily} key={i}>
          <span style={{ fontFamily: f.fontFamily }}>{f.name}</span>
        </MenuItem>
      ))}
    </Select>
  );
}

export default FontFamilySelect;
