import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, FormAutocompleteField, FormTextField, NormalButton, UpdateMembersChecklists } from "bild-ui";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";

const useStyles = makeStyles({
  title: props => ({
    color: colors.white,
    backgroundColor: props.lic === 1 ? colors.level1Group : props.lic === 2 ? colors.level2Group : props.lic === 3 ? colors.level3Group : colors.darkGray,
  }),
  icon: {
    color: colors.white,
    fontWeight: "bold",
    padding: "0 0.5rem 0 0"
  },
  body: { padding: "1rem 0 0 0" },
  submitWrapper: { padding: "1rem 0" },
  submit: { padding: "1rem 2rem" }
});

export default function OrgGroupUsersEditDialog({ open, onClose, onSubmit, orgGroups, orgGroup, availableUsers }) {
  const lic = orgGroup ? orgGroup.levelId : 0;
  const cls = useStyles({ lic });
  const [form, setForm] = useState({
    id: null,
    name: "",
    levelId: null,
    parentId: null
  });
  const [members, setMembers] = useState(orgGroup ? orgGroup.users : []);
  const [selectedUsers, setSelectedUsers] = useState(orgGroup ? orgGroup.users.map(x => x.id) : []);
  const [groupedAvailableUsers, setGroupedAvailableUsers] = useState([]);

  useEffect(()=>{
    function getChildren(node) {
      let newAvailableUsers = node.users.map(x => {
        return {
          id: x.id,
          name: x.name,
          avatarURL: x.avatarURL,
          message: {
            icon: "fal fa-house-user",
            text: `This person is a member of <i>${node ? node.name : ""}</i> and will be moved to <i>${orgGroup ? orgGroup.name : ""}</i>`
          }
        }
      });
      if (node.children) {
        for (let i=0; i < node.children.length; i++) {
          newAvailableUsers.push(getChildren(node.children[i]));
        }
      }
      return newAvailableUsers.flat();
    }

    if (orgGroups) {
      if (orgGroups.children) {
        let newAvailableUsers = [];
        for (let i=0; i < orgGroups.children.length; i++) {
          newAvailableUsers.push(getChildren(orgGroups.children[i]));
        }
        newAvailableUsers = newAvailableUsers.flat();
        let groupedUserIds = newAvailableUsers.map(x => x.id);
        let oldAvailableUsers = availableUsers.map(x => {return {id: x.id, name: x.name, avatarURL: x.avatarURL}}).filter(x => !groupedUserIds.some(y => y===x.id));
        newAvailableUsers = newAvailableUsers.concat(oldAvailableUsers).flat().sort((a, b) => a.name.localeCompare(b.name));
        setGroupedAvailableUsers(newAvailableUsers);
      }
    }
  },[orgGroups, orgGroup, availableUsers]);

  useEffect(()=>{
    if (orgGroup) {
      setForm({
        ...form,
        id: orgGroup.id,
        name: orgGroup.name,
        levelId: orgGroup.levelId,
        parentId: orgGroup.parentOrganizationGroupId,
        usersToAdd: [],
        usersToRemove: []
      });
      setMembers(orgGroup ? orgGroup.users : []);
      setSelectedUsers(orgGroup ? orgGroup.users.map(x => x.id) : []);
    }
  },[orgGroup]);

  function _onSubmit() {
    let new_users = selectedUsers.filter(su => !members.map(m => m.id).includes(su));
    let remove_users = members.filter(m => !selectedUsers.map(su => su).includes(m.id)).map(x => x.id);

    let sendData = {
      ...form,
      usersToAdd: new_users,
      usersToRemove: remove_users
    };

    onSubmit("update", sendData);
    _onClose();
  }

  function changeUser(userId) {
    let index = selectedUsers.indexOf(userId);
    if (index === -1) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter(u => u !== userId));
    }
  }

  function _onClose() {
    setForm({
      id: null,
      name: "",
      levelId: null,
      parentId: null,
      usersToAdd: [],
      usersToRemove: []
    });
    onClose();
  }

  return (
    <Dialog open={open} onClose={_onClose} fullWidth maxWidth="sm">
      <DialogTitle className={cls.title}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
            <Grid item className={cls.icon}>
              {form.id ? <i className={lic === 3 ? "fal fa-chart-network" : lic === 2 ? "far fa-project-diagram" : lic === 1 ? "far fa-home-alt" : ""} /> : ""}
            </Grid>
            <Grid item>
              <BTypography variant="h5">
                {orgGroup && orgGroup.name}
              </BTypography>
            </Grid>
          </Grid>
          <Grid item>
            <NormalButton
              onClick={_onClose}
              variant={"micro"}
              color={colors.transparent}
              labelColor={colors.white}
              hoverColor={colors.darkGray}
              hoverLabelColor={colors.white}
            >
              <i className="far fa-times fa-2x" />
            </NormalButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} className={cls.body}>
          <UpdateMembersChecklists
            title="People"
            allMembers={groupedAvailableUsers}
            members={members}
            selectedMembers={selectedUsers}
            updateMember={changeUser}
          />
        </Grid>
        <Grid container item xs={12} className={cls.submitWrapper} justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              onClick={_onSubmit}
              variant={"primary"}
              color={colors.bildBlue}
              labelColor={colors.white}
              className={cls.submit}
            >
              Update
            </NormalButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
