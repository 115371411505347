import React, { useState, useEffect } from "react";
import { managerData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { useTheme } from "@mui/styles";
import { Dialog } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AvailableCertificates from "./availableCertificates.js";
import PreviewCertificate from "./previewCertificate.js";

function AvailableCertificatesLoader({}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [certificate, setCertificate] = useState(null);
  const [certificateId, setCertificateId] = useState(null);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Available Certificates");
      setCertificates(d.availableCertificates);
      setLoading(false);
    }

    managerData.getAvailableCertificates(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    if (certificateId) {
      setCertificate(certificates.filter(x => x.id === certificateId)[0]);
    } else {
      setCertificate(null);
    }
  }, [certificateId]);

  if ((loading && !certificates) || error) return <LoadingScreen error={error} />;
  return (
    <>
      <AvailableCertificates certificates={certificates} certificateId={certificateId} setCertificateId={setCertificateId} />
      <Dialog
        open={certificateId ? true : false}
        onClose={() => {
          setCertificateId(null);
        }}
        fullWidth
        maxWidth={"xl"}
        fullScreen={fullScreen}
      >
        {certificateId && certificate && (
          <PreviewCertificate
            certificate={certificate}
            onClose={() => {
              setCertificateId(null);
            }}
          />
        )}
      </Dialog>
    </>
  );
}

export default AvailableCertificatesLoader;
