import React, { useEffect } from "react";
import { PreviewPathLoader } from "modules";
import { hideIntercom, showIntercom } from "bild-utils";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid } from "@mui/material";

const useStyles = makeStyles({
  content: { maxHeight: "80vh", minHeight: "80vh", padding: "0 !important" }
});

function PreviewPathDialogLoader({ open, onClose }) {
  const cls = useStyles();

  useEffect(() => {
    if (open) {
      hideIntercom();
    } else {
      showIntercom();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogContent className={cls.content}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={12}>
            <PreviewPathLoader />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PreviewPathDialogLoader;
