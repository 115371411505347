import React, { useEffect, useState } from "react";
import { BTypography } from "bild-ui";
import { userData } from "bild-data";
import { makeStyles } from "@mui/styles";
import { Collapse, Grid } from "@mui/material";
import EvaluationComment from "./evaluationComment.js";

const useStyles = makeStyles({
  container: { marginTop: 10 },
  header: { cursor: "pointer", "& p": { fontWeight: "bold" } },
  commentsContainer: { margin: "5px 0 10px 0" },
  caret: { display: "inline-block", fontSize: "1.1em", margin: "1px 10px 0 10px" },
  caretRight: {
    transform: "rotate(0deg)",
    transition: "transform 200ms"
  },
  caretDown: {
    transform: "rotate(90deg)",
    transition: "transform 200ms"
  }
});

function EvaluationCommentSection({ comments: _comments, onAddComment }) {
  const { user } = userData.getOverview();
  const classes = useStyles();

  const [comments, setComments] = useState(_comments);
  const [hidden, setHidden] = useState(true);
  function _addComment(newComment) {
    if (comments.length === 0) setHidden(false);
    setComments([...comments, newComment]);
    if (typeof onAddComment === "function") onAddComment(newComment);
  }

  const [commentComponents, setCommentComponents] = useState();
  useEffect(() => {
    if (comments && comments.length > 0)
      setCommentComponents(
        comments.map(({ user, value, createdAt, pending }, i) => (
          <EvaluationComment user={user} value={value} createdAt={createdAt} pending={pending} key={i} />
        ))
      );
  }, [comments]);

  useEffect(() => {
    if (comments !== _comments) setComments(_comments);
  }, [_comments]); // eslint-disable-line

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        <Grid container className={classes.header} onClick={() => setHidden(!hidden)}>
          <BTypography variant="body2" display="inline">
            Comments ({comments.length})
          </BTypography>

          {comments.length > 0 && <i className={`${classes[hidden ? "caretRight" : "caretDown"]} ${classes.caret} fal fa-angle-right`} />}
        </Grid>

        <Collapse in={!hidden || comments.length < 1}>
          <div className={classes.commentsContainer}>{commentComponents}</div>
          <EvaluationComment isInput user={user} onAddComment={_addComment} isDiscrete={comments.length < 1} />
        </Collapse>
      </div>
    </div>
  );
}

export default EvaluationCommentSection;
