import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/dist/index.js";
import { equipReviewData, managerData, diskData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, EquipWrapper, LoadingScreen } from "bild-ui";
import ReviewSharedPath from "./reviewSharedPath.js";

function SharedPathLoader({}) {
  const { group_id, path_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [group, setGroup] = useState(null);
  const [path, setPath] = useState(null);
  const [users, setUsers] = useState([]);
  const [pathItem, setPathItem] = useState(null);
  const [pathItemId, setPathItemId] = useState(null);
  const [pathItemName, setPathItemName] = useState(null);
  const [pathItemDescription, setPathItemDescription] = useState(null);
  const [docId, setDocId] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    function _setGroupData(d) {
      setPageTitle("Shared Programs");
      setGroup(d.sharingGroups[0]);
    }

    function _setPathData(d) {
      function _setUsersData(p) {
        setUsers(p.userPathItems.map((x) => {
          let data = x.userLearningItem.user;
          data["userPathId"] = x.userPathId;
          return data;
        }));
        setLoading(false);
      }

      setPath(d);
      const initialPathItemId = d.learningGroups[0].pathItems[0].id;
      equipReviewData.loadSharingGroupPathItem(group_id, initialPathItemId, _setUsersData, e => {
        setError(e.response.data.error);
      });
    }

    equipReviewData.loadSharingGroup(group_id, _setGroupData, e => {
      setError(e.response.data.error);
    });
    managerData.getManagedPathOutline(path_id, _setPathData, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    function _setPathItemData(p) {
      let sortedData = p.userPathItems.sort((a,b) => a.userLearningItem.user.name.localeCompare(b.userLearningItem.user.name));
      p.userPathItems = sortedData;
      setPathItem(p);
    }

    if (pathItemId) {
      equipReviewData.loadSharingGroupPathItem(group_id, pathItemId, _setPathItemData, e => {
        setError(e.response.data.error);
      });
    } else {
      setPathItem(null);
    }
  }, [pathItemId]);

  function setCurrentItem(id, name, description) {
    setPathItemId(id);
    setPathItemName(name);
    setPathItemDescription(description);
  }

  function _setDocId(id) {
    setDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setFileLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setFileLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setFileLoading(false);
        }
      );
    }
  }

  if (!group || !path || loading || error) return <LoadingScreen error={error} />;
  return (
    <EquipWrapper>
      <ReviewSharedPath
        group={group}
        users={users}
        path={path}
        pathItem={pathItem}
        pathItemId={pathItemId}
        pathItemName={pathItemName}
        pathItemDescription={pathItemDescription}
        setCurrentItem={setCurrentItem}
        docId={docId}
        setDocId={_setDocId}
        fileLoading={fileLoading}
      />
    </EquipWrapper>
  );
}

export default SharedPathLoader;
