import React from "react";
import { colors } from "bild-ui";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Grid, Badge } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    menu: {},
    menuItem: {
      padding: "0.75rem 1rem",
      "&:hover": { backgroundColor: colors.gray, cursor: "pointer" }
    },
    name: {
      overflow: "hidden",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&> span": {
        top: "5px",
        right: "10px"
      }
    },
    active: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      fontWeight: "bold",
      "&:hover": { backgroundColor: colors.lightBlue, cursor: "pointer" }
    },
    newItem: { backgroundColor: colors.darkGray, color: colors.white, fontWeight: "bold", textAlign: "center", maxHeight: "4rem" },
    newItem2: { backgroundColor: colors.darkerGray },
    items: {
      overflowY: "auto",
      [theme.breakpoints.down('md')]: {
        maxHeight: "calc(88vh - 6rem) !important"
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: "calc(100vh - 6rem)"
      }
    }
  })
);

function ManagedMenu({ items, currentItem, setCurrentItem, newItem, newItemText, newItem2, newItem2Text }) {
  const cls = useStyles();

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" className={cls.menu}>
      <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" className={cls.items}>
        <Grid container item xs={12}>
          {items.map(x => {
            return (
              <Grid
                item
                xs={12}
                onClick={() => {
                  setCurrentItem(x.id);
                }}
                key={x.id}
                className={`${cls.menuItem} ${currentItem === x.id ? cls.active : ""}`}
              >
                <Badge color="secondary" variant="dot" invisible={x.read === undefined ? true : x.read} className={cls.name}>
                  {x.name}
                </Badge>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid container item xs={12}>
          <Grid item xs={12} onClick={newItem} className={`${cls.menuItem} ${cls.newItem}`}>
            <i className="fal fa-plus" /> {newItemText ? newItemText : "New Item"}
          </Grid>
        </Grid>
        {newItem2 && (
          <Grid container item xs={12}>
            <Grid item xs={12} onClick={newItem2} className={`${cls.menuItem} ${cls.newItem} ${cls.newItem2}`}>
              <i className="fal fa-plus" /> {newItem2Text}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ManagedMenu;
