import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { SortableList } from "bild-ui";
import { Grid } from "@mui/material";
import { UserPathThumbnail } from "equip";

const useStyles = makeStyles({});

export default function StaffSearchUserPaths({ userPaths, count }) {
  const cls = useStyles();
  const [items, setItems] = useState([]);

  useEffect(()=>{
    if (userPaths) {
      setItems(userPaths.map(x => [
        [<UserPathThumbnail path={x} color={x.programColor.webFrontendValue} key={x.id} sortval={x.title} showFull />]
      ]));
    }
  },[userPaths]);

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
      <SortableList
        headers={["Name"]}
        items={items}
        itemPadding={"0.5rem 1rem"}
        itemType={"Programs"}
        title={`Your Programs (${count})`}
        fixedHeight={"55vh"}
      />
    </Grid>
  );
}
