import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Blink, BTypography, colors, NormalButton } from "bild-ui";
import List from "presentational/lists/list.js";

const useStyles = makeStyles({
  reportDescription: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: "4px",
    background: colors.veryLightGray,
    padding: "0 1rem",
    margin: "1rem 0"
  },
  complete: { color: colors.green, fontWeight: "bold" }
});

function OrganizationStudentProgress({ dateRan, organizations, combined }) {
  const cls = useStyles();
  const headers = [
    "Name",
    "Acts",
    "Essentials",
    "Leaders",
    "Pauline",
    ReactHtmlParser("Life<SUP>n</SUP> Initial Plan"),
    "Ministry Practicum",
    "SIMA Identifying",
    "Teaching FPS I",
    "Teaching FPS II"
  ];
  const headerIds = [18, 20, 21, 19, 99, 61, 1, 113, 114];

  function getOrgs() {
    let orgHeaders = headers.map(h => (
      <Grid item xs key={h}>
        {h}
      </Grid>
    ));

    if (combined) {
      let combinedOrgs = { students: [] };
      combinedOrgs.students = organizations.map(x => combinedOrgs.students.concat(x.students)).flat();
      return <List title={`All Student Progress`} details={dateRan} headings={orgHeaders} items={getStudents(combinedOrgs.students)} />;
    } else {
      return organizations.map(o => [
        <List
          title={`${o.organizationName} Student Progress`}
          details={dateRan}
          headings={orgHeaders}
          items={getStudents(o.students)}
          key={o.organizationId}
        />
      ]);
    }
  }

  function getStudents(students) {
    return students.map(y => {
      return [
        <Grid container item key={y.userId}>
          <Grid item xs>
            {y.userName}
          </Grid>
          {getModules(y.modules).map(m => m)}
        </Grid>
      ];
    });
  }

  function getModules(modules) {
    return headerIds.map(id => (
      <Grid container item xs key={id}>
        {modules.map(z => getModuleById(z, id))}
      </Grid>
    ));
  }

  function getModuleById(mod, id) {
    if (mod.moduleId !== id) {
      return false;
    } else {
      let val = "N/A";
      if (mod.totalCompetencies) {
        val = `${mod.completedCompetencies ? mod.completedCompetencies : "0"}/${mod.totalCompetencies}`;
      }

      return (
        <Grid item xs key={id} className={`${mod.completedCompetencies && mod.completedCompetencies >= mod.totalCompetencies ? cls.complete : ""}`}>
          {val}
        </Grid>
      );
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Blink dst={"reports-organization-student-progress"} organization_id={`${combined ? "" : "all"}`}>
          <NormalButton variant={"simple"}>
            <i className="fal fa-clipboard-user" /> &nbsp; View {combined ? "Details" : "Combined"}
          </NormalButton>
        </Blink>
      </Grid>
      <Grid item xs={12} className={cls.reportDescription}>
        <p>
          <BTypography variant="h6">Report of student competencies validited in a course compared to number of comptencies in the course</BTypography>
        </p>
      </Grid>
      <Grid item xs={12}>
        {getOrgs()}
      </Grid>
    </Grid>
  );
}

export default OrganizationStudentProgress;
