import React from "react";
import { makeStyles } from "@mui/styles";

import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  sortIconUp: {
    fontSize: "1.1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "top",
    textAlign: "center",
    color: "black"
  },
  sortIconDown: {
    fontSize: "1.1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "bottom",
    textAlign: "center",
    color: "black"
  },
  sortIconMiddle: {
    fontSize: "1.1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "bottom",
    textAlign: "center",
    color: "black"
  },
  sortIconGroup: {
    position: "relative",
    width: "1.2rem",
    height: "1.2rem"
  },
  sortIconItem: {
    position: "absolute",
    top: "0",
    left: "0"
  },
  invisible: {
    fontSize: "1.1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "middle",
    textAlign: "center",
    visibility: "hidden"
  },
  sortButton: {
    padding: "0.4rem",
    margin: "-0.3rem 0.3rem -0.1rem 0.3rem"
  },
  sortButtonInvisible: {
    display: "none"
  }
});

function SortButton({ variant, value, onChange }) {
  const classes = useStyles();

  function _changeOrder() {
    if (onChange) {
      switch (value) {
        case "descending":
          onChange("ascending");
          break;
        case "ascending":
          onChange("descending");
          break;
        case "off":
          onChange("off");
          break;
        default:
          onChange("descending");
          break;
      }
    }
  }

  let button;
  switch (variant) {
    case "invisible":
      button = (
        <IconButton
          disabled
          className={classes.sortButtonInvisible}
          key="sort-button-invisible"
          size="large">
          <i className={`fal fa-arrow-up ${classes.invisible}`} key="arrow-invisible" />
        </IconButton>
      );
      break;
    default:
      switch (value) {
        case "ascending":
          button = (
            <IconButton
              className={classes.sortButton}
              onClick={_changeOrder}
              key="sort-button-up"
              size="large">
              <i className={`fal fa-arrow-up ${classes.sortIconUp}`} key="arrow-down" />
            </IconButton>
          );
          break;
        case "descending":
          button = (
            <IconButton
              className={classes.sortButton}
              onClick={_changeOrder}
              key="sort-button-down"
              size="large">
              <i className={`fal fa-arrow-down ${classes.sortIconDown}`} key="arrow-down" />
            </IconButton>
          );
          break;
        case "off":
          button = (
            <IconButton
              className={classes.sortButton}
              onClick={_changeOrder}
              key="sort-button-middle"
              size="large">
              <i className={`fal fa-horizontal-rule ${classes.sortIconMiddle}`} key="arrow-middle" />
            </IconButton>
          );
          break;
        default:
          button = (
            <IconButton
              className={classes.sortButton}
              onClick={_changeOrder}
              key="sort-button-down"
              size="large">
              <i className={`fal fa-arrow-down ${classes.sortIconDown}`} key="arrow-down" />
            </IconButton>
          );
          break;
      }
      break;
  }

  return button;
}

export default SortButton;
