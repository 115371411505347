import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Blink, BTypography, colors, NormalButton, SortableList } from "bild-ui";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  header: { background: colors.white },
  headerName: { padding: "1rem 0.5rem" },
  status: { fontWeight: "bold", textAlign: "center" },
  green: { color: colors.darkGreen },
  yellow: { color: colors.darkerYellow },
  red: { color: colors.darkRed },
  newButtonWrapper: { padding: "1rem", },
  filter: { minWidth: "10rem" },
  body: { padding: "0 0 2rem 0" },
});

export default function NetworkPartnerCivilizations({ civilizations }) {
  const cls = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(()=>{
    setRows(
      civilizations
      .map(x => {
        return [
          x.name,
          x.currentStatus,
          x.currentStatusUpdatedAt,
          x.networkPartnersCount ? x.networkPartnersCount : 0,
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <NormalButton
                component={Blink}
                dst="civilization"
                civilization_id={x.id}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
              >
                <i className="fas fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
        ];
      })
    );

  },[civilizations]);

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.header} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs className={cls.headerName}>
          <BTypography variant="h4">
            <i className="fad fa-mountains fa-fw fa-lg fa-swap-opacity" /> Civilizations
          </BTypography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cls.body}>
        <SortableList
          headers={["Name", "Status", "Status Date", "# of Partners", ""]}
          sortableColumns={[0,1,2,3]}
          spacing={[3,3,2,2,2]}
          items={rows}
          itemType={"Civilizations"}
        />
      </Grid>
    </Grid>
  );
}
