import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import FolioSelectorDialog from "./components/folioSelectorDialog.js";
import SourceChoiceDialog from "./components/sourceChoiceDialog.js";
import DropZoneDialogContent from "./components/dropZoneDialogContent.js";
import AttachArtifactStepper from "./components/attachArtifactStepper.js";

import { DropboxFilePicker, GoogleDriveFilePicker } from "bild-vendors";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import { load as loadDisk, createFolder } from "bild-data/disk";

import { useWidth } from "bild-utils";

const useStyles = makeStyles({
  dialog: { margin: "2rem" },
  dialogSmall: { margin: "0.5rem 0" },
  dialogPaper: { borderRadius: "4px" },
  dialogGrid: { height: "100%" },
  stepperPane: { borderRight: "1px solid lightgrey", padding: "1rem 0" },
  mainPane: { height: "100%" },
  mainPaneBody: { height: "100%", display: "flex", flexDirection: "column" },
  mainPaneContent: { flex: "1 1 auto" },
  confirmText2: { marginTop: "2rem" }
});

// FilesDialogLoader
// This container can be thought of as a collection of steps (in dialog form) that lead the
// user through the process of selecting files to attach to a competency.
//
// There are multiple stages that this dialog can find itself in:
// + 0. Initial stage, user chooses where the files are coming from. (Folio, computer, Google, Dropbox)
//
// + 1. Show Folio for file or destination folder selection.
//
// + 2. If source not Folio, show appropriate file picker
//
// + 3. Confirm selected files (+ destination) and target competency.
//
// + -1. Error state.

function AttachArtifactDialog({ userId, initialStage, initialSource, open, openCount, onClose, onAttach }) {
  const classes = useStyles();
  const [diskArrays, setDiskArrays] = useState(null);

  // Completely reload the disk from API everytime this dialog is called
  useEffect(() => loadDisk(userId, setDiskArrays), [openCount || 0]);

  const [state, setState] = useState({ stage: 0 });

  // Allow for overriding stage and source
  useEffect(() => {
    const baseStage = initialStage ? initialStage : 0;
    const baseSource = initialSource ? initialSource : null;
    setState({ stage: baseStage, source: baseSource });
  }, [initialStage, initialSource]);

  const w = useWidth();
  const xs = w === "xs";

  if (!diskArrays) {
    return null;
  }

  function _cancel() {
    const baseStage = initialStage ? initialStage : 0;
    setState({ stage: baseStage });
    if (onClose) onClose();
  }

  function _setStage(stage) {
    if (stage !== state.stage && stage >= 0 && stage < 4) {
      setState({ ...state, stage: stage });
    }
  }

  function _findRootFolder() {
    return diskArrays.folders.find(f => f.parentFolderId === null);
  }

  function _createFolder(contextRoot, name) {
    createFolder(contextRoot, name, userId, setDiskArrays);
  }

  function _remove(name) {
    let artifacts = state.artifacts;
    for (var i = 0; i < artifacts.length; i++) {
      let a = artifacts[i];
      if (a.name === name) {
        artifacts.splice(i, 1);
      }
    }
    setState({ ...state, artifacts: artifacts });
  }

  function _directAttach(artifacts) {
    const attachments = artifacts.map(a => {
      return { id: a.id };
    });
    if (onAttach) onAttach(attachments);
    _cancel();
  }

  function _attach() {
    let artifacts = state.artifacts;
    if (artifacts) {
      const attachments = artifacts.map(a => {
        if (a.id) {
          // File already exists in the system
          return { id: a.id };
        } else {
          // File is being imported/uploaded
          // Because we have changed around when the folder is selected, we need to update each artifact before moving on
          a.folderId = state.destination.id;
          return a;
        }
      });
      if (onAttach) onAttach(attachments);
    }
    _cancel();
  }

  // Initial stage, show choice of sources.
  if (state.stage === 0) {
    return (
      <SourceChoiceDialog
        open={open}
        onClose={_cancel}
        onChooseFolio={() => setState({ stage: 1, source: "folio" })}
        onChooseComputer={() => setState({ stage: 1, source: "computer" })}
        onChooseGoogle={() => setState({ stage: 1, source: "google" })}
        onChooseDropbox={() => setState({ stage: 1, source: "dropbox" })}
      />
    );
  }

  // If choose folio, "jump out" of stages, just show file selection dialog.
  if (state.source === "folio") {
    return (
      <FolioSelectorDialog
        title="Select files from your Folio:"
        diskArrays={diskArrays}
        onSubmit={selection => {
          _directAttach(selection);
        }}
        onClose={_cancel}
        open={open}
      />
    );
  }

  // Stage logic within a stepper dialog.
  return (
    <Dialog
      open={open === true}
      fullScreen
      onClose={_cancel}
      className={xs ? classes.dialogSmall : classes.dialog}
      PaperProps={{ className: classes.dialogPaper }}
      disableEnforceFocus
    >
      <Grid container className={classes.dialogGrid}>
        <Grid item md={3} sm={4} xs={12} className={classes.stepperPane}>
          <AttachArtifactStepper
            setStage={_setStage}
            activeStep={state.stage}
            activeSource={state.source}
            onCancel={_cancel}
            onFinal={_attach}
            hasArtifact={state.artifacts !== undefined && state.artifacts !== null}
            hasDestination={state.destination !== undefined && state.destination !== null}
          />
        </Grid>
        <Grid item xs className={classes.mainPane}>
          {state.stage === 1 && state.source === "computer" && (
            <DropZoneDialogContent
              onSelect={selection => {
                setState({
                  stage: 2,
                  source: "computer",
                  destination: state.destination ? state.destination : _findRootFolder(),
                  artifacts: selection
                });
              }}
              autoLoad={true}
              oldArtifacts={state.artifacts}
              removeArtifact={_remove}
              onClose={_cancel}
            />
          )}

          {state.stage === 1 && state.source === "google" && (
            <div className={classes.mainPaneBody}>
              <DialogTitle>Import From Google Drive</DialogTitle>
              <DialogContent className={classes.mainPaneContent}>
                <GoogleDriveFilePicker
                  onUploadFiles={selection => {
                    setState({
                      stage: 2,
                      source: "google",
                      destination: state.destination ? state.destination : _findRootFolder(),
                      artifacts: selection
                    });
                  }}
                  contextRoot={state.destination}
                  autoLoad={true}
                />
              </DialogContent>
            </div>
          )}

          {state.stage === 1 && state.source === "dropbox" && (
            <div className={classes.mainPaneBody}>
              <DialogTitle>Import From Dropbox</DialogTitle>
              <DialogContent className={classes.mainPaneContent}>
                <DropboxFilePicker
                  onUploadFiles={selection => {
                    setState({
                      stage: 2,
                      source: "dropbox",
                      destination: state.destination ? state.destination : _findRootFolder(),
                      artifacts: selection
                    });
                  }}
                  contextRoot={state.destination}
                  autoLoad={true}
                />
              </DialogContent>
            </div>
          )}

          {state.stage === 2 && (
            <FolioSelectorDialog
              bodyOnly
              folderSelector
              title="Select a location in your Folio:"
              diskArrays={diskArrays}
              onCreateFolder={_createFolder}
              onSelect={selection => {
                setState({ ...state, destination: selection });
              }}
              setDisk={setDiskArrays}
              onClose={_cancel}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AttachArtifactDialog;
