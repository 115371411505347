import React from "react";
import { BTypography, UserAvatar } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  wrapper: { justifyContent: "center" },
  row: { justifyContent: "center" },
  avatar: { paddingRight: "0.25rem" }
});

function AssessmentCardUser({ history }) {
  const cls = useStyles();

  return (
    <Grid container className={cls.wrapper} alignItems="center">
      <Grid container item xs={12} className={cls.row} alignItems="center">
        <Grid item>
          <BTypography variant="body1">Assessed By</BTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.row} alignItems="center">
        <Grid item>
          <UserAvatar size={30} src={history.user.avatarURL} name={history.user.name} icon="fas fa-user" srcSize="small" badgeSrc={history.user.badgeURL}  />
        </Grid>
      </Grid>
      <Grid item>
        <BTypography variant="body1">{history.createdAt}</BTypography>
      </Grid>
    </Grid>
  );
}

export default AssessmentCardUser;
