import React, { useState, useEffect } from "react";
import { reportData } from "bild-data";
import { setPageTitle, hideIntercom } from "bild-utils";
import { LoadingScreen, BaseWrapper } from "bild-ui";
import SystemRecentActivity from "./systemRecentActivity.js";

function SystemRecentActivityLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [oldData, setOldData] = useState(null);
  const [data, setData] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("System Activity Dashboard");
      hideIntercom();
      setData(d);
      setOldData(d);
      setLoading(false);
    }

    reportData.getSystemRecentActivity(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    if (data) {
      // Wait 5 seconds to set the oldData once new Data has come in
      setTimeout(() => setOldData(data), 5000);
    }
  }, [data]);

  useEffect(() => {
    // Every 30 seconds check for new data
    const interval = setInterval(function() {
      reportData.getSystemRecentActivity(setData, e => {
        setError(e.response.data.error);
      });
    }, 30000);
    // Clear interval on autoRefresh disabled
    if (!autoRefresh) {
      clearInterval(interval);
    }
    // Clear interval on unmount
    return () => clearInterval(interval);
  }, [autoRefresh]);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <SystemRecentActivity data={data} oldData={oldData} autoRefresh={autoRefresh} setAutoRefresh={setAutoRefresh} />
    </BaseWrapper>
  );
}

export default SystemRecentActivityLoader;
