import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { fundraisingData } from "bild-data";
import { setPageTitle, updateURL } from "bild-utils";
import { toaster } from "presentational/toasts/toasts.js";
import { LoadingScreen, SuccessScreen, BaseWrapper } from "bild-ui";
import FundraisingCompleteTask from "./fundraisingCompleteTask.js";

function FundraisingCompleteTaskLoader({}) {
  const { fundraising_task_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [final, setFinal] = useState(false);
  const [constituent, setConstituent] = useState({});
  const [task, setTask] = useState(false);
  const [fundraisingTaskId, setFundraisingTaskId] = useState(0);

  useEffect(() => {
    setPageTitle("Fundraising Complete Task");
    setFundraisingTaskId(fundraising_task_id);

    function _setData(m) {
      setConstituent(m);
      setTask(m.tasks[0]);
      setLoading(false);
    }

    fundraisingData.getFundraisingTask(fundraising_task_id, _setData);
  }, []);

  function completeTask(taskNote, interaction, newTask, phonesToRemove) {
    let data = {
      callAttemptTypeToAdd: interaction.type,
      taskToComplete: { bloomerangTaskId: fundraisingTaskId }
    };
    if (interaction.comm && interaction.comm.length > 0) {
      data["taskToComplete"]["interactionCommunication"] = interaction.comm;
      data["taskToComplete"]["interactionNote"] = taskNote + " \n\n\n " + interaction.note;
    }
    if (phonesToRemove && phonesToRemove.length > 0) {
      data["phonesToRemove"] = phonesToRemove.map(p => {
        return { bloomerangPhoneId: p.id, number: p.number, note: p.note };
      });
    }
    if (newTask) {
      data["taskToAdd"] = { dueDate: newTask.date, note: newTask.note, purpose: newTask.purpose };
    }
    setLoading(true);
    fundraisingData.updateFundraisingTask(fundraisingTaskId, data, success_cb);
  }

  function success_cb(data, _success_cb) {
    setTask(data);
    toaster.success("Task Completed! Redirecting to list...");
    setLoading(false);
    setFinal(true);

    setTimeout(() => {
      updateURL("/fundraising");
    }, 3000);
  }

  if (loading) return <LoadingScreen />;
  if (final) return <SuccessScreen message={"Task Completed!"} />;
  return (
    <BaseWrapper>
      <FundraisingCompleteTask constituent={constituent} task={task} completeTask={completeTask} />
    </BaseWrapper>
  );
}

export default FundraisingCompleteTaskLoader;
