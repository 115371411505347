import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import Bbutton from "presentational/input/bbutton.js";
import Competency from "containers/competency/competency.js";
import TrainingHistoryStepper from "./trainingHistoryStepper.js";

import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  innerContainer: { padding: "1rem", border: `1px solid ${colors.black}`, borderRadius: "4px" },
  course: { background: `${colors.white}` },
  section: { paddingBottom: "1rem" },
  link: { textDecoration: "underline", background: `${colors.veryLightBlue}` },
  approved: {
    background: colors.veryLightGreen,
    color: colors.darkerGreen,
    border: `1px solid ${colors.green}`,
    padding: "0.5rem",
    borderRadius: "4px"
  },
  notApproved: { background: colors.veryLightRed, color: colors.darkRed, border: `1px solid ${colors.red}`, padding: "0.5rem", borderRadius: "4px" }
});

function TrainingAssessment({ trainingCompetency, callingUser }) {
  const classes = useStyles();
  const course = trainingCompetency.competencyWithCourse.course;
  const competencyProof = trainingCompetency.competencyWithCourse.competencyProof;
  const [trainingAssessment, setTrainingAssessment] = useState(trainingCompetency.trainingAssessment);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.section}>
        <TrainingHistoryStepper currentAssessment={trainingAssessment} otherAssessments={trainingCompetency.historyTrainingAssessment} />
      </Grid>
      <Grid container item xs={12} className={classes.innerContainer}>
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h5">{ReactHtmlParser(course.name)}</Typography>
        </Grid>
        <Grid container item xs={12} jusity="space-between" alignItems="center">
          <Grid item xs className={classes.section}>
            <Bbutton dst="course" course_id={course.id} competency_proof_id={competencyProof.id} className={classes.link}>
              <Typography variant="h6">
                Go to {competencyProof.user.name}'s {ReactHtmlParser(trainingCompetency.competencyWithCourse.name)}
              </Typography>
            </Bbutton>
          </Grid>
          {trainingAssessment.coachHasAccepted !== null && (
            <Grid item xs className={classes.section}>
              {trainingAssessment.coachHasAccepted ? (
                <span className={classes.approved}>AFT Assessment Approved by Coach</span>
              ) : (
                <span className={classes.notApproved}>
                  AFT Assessment <u>Not</u> Approved by Coach
                </span>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={6} className={classes.section}>
          <Typography variant="body1">Trainee: {trainingAssessment.trainee.name}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.section}>
          <Typography variant="body1">Student: {competencyProof.user.name}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.course}>
          <Competency
            course={course}
            competency={trainingCompetency.competencyWithCourse}
            trainingAssessment={trainingAssessment}
            setTrainingAssessment={setTrainingAssessment}
            trainingCriteria={trainingAssessment.rubric.criteria}
            callingUser={callingUser}
            userRoles={trainingCompetency.callingUserRoles}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TrainingAssessment;
