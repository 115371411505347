import React, { useState, useEffect } from "react";
import { managerData } from "bild-data";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import AddUser from "./components/addUser.js";

function AddUserLoader({ defaultOrgs, defaultOrg, defaultId, defaultName, defaultEmail, success_cb, onCancel }) {
  const [loading, setLoading] = useState(true);
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [error, setError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    function _setData(m) {
      if (defaultOrgs) {
        setOrgs(defaultOrgs.sort().map(x => ({ ...x, id: x.organizationId, name: x.organizationName })));
      } else {
        setOrgs(m.availableOrganizations.sort().map(x => ({ ...x, id: x.organizationId, name: x.organizationName })));
      }
      setRoles(m.availableRoles.sort().map(x => ({ ...x, id: x.id, name: x.description })));
      setLoading(false);
    }

    managerData.getManagedUsers(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  function createUpdateUser(userId, name, offline, email, city, country, org, role) {
    setLoading(true);
    setLoadingTitle("Creating User...");
    let data = {
      users: [
        {
          name: name,
          preferredName: name,
          offline: offline,
          emailAddress: email,
          city: city,
          country: country,
          organizations: [
            {
              organizationId: org,
              roles: [{ id: role }]
            }
          ]
        }
      ]
    };
    managerData.createManagedUser(data, d => {
      if (userId) {
        toaster.success(`Role Added!`);
      } else {
        toaster.success(`Account Created for ${name}!`);
      }
      if (typeof success_cb === "function") {
        if (defaultOrgs) {
          // Hack to only show users that are in the defaultOrgs passed in
          success_cb(d.users.filter(x => x.organizations.some(y => defaultOrgs.some(z => z.organizationId === y.organizationId))));
        } else {
          success_cb(d.users);
        }
      }
      setLoading(false);
      setLoadingTitle(null);
    });
  }

  function checkEmail(email, success_cb, error_cb) {
    managerData.getManagedUsersExist(email, success_cb, error_cb);
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <AddUser
      orgs={orgs}
      roles={roles}
      defaultOrgs={defaultOrgs}
      defaultOrg={defaultOrg}
      defaultId={defaultId}
      defaultName={defaultName}
      defaultEmail={defaultEmail}
      createUpdateUser={createUpdateUser}
      checkEmail={checkEmail}
      onCancel={onCancel}
    />
  );
}

export default AddUserLoader;
