import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  header: { padding: "1rem 0" },
  body: { padding: "1rem 0" },
  card: { padding: "0.5rem 0" },
  files: { paddingLeft: "0.5rem" },
  progressItem: {
    backgroundColor: "lightgrey",
    borderRadius: "20px",
    padding: ".25rem",
    paddingLeft: "1rem",
    flexGrow: "1",
    cursor: "pointer"
  },
  selected: { cursor: "default !important" }
});

// TODO: Add zIndex for section bar
function EvaluationSectionBar({ sections, selectedSection, onClick }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.body}>
      <Grid container>
        {sections.map((section, index) => (
          <Grid
            item
            className={`${classes.progressItem} ${section.id === selectedSection.id ? classes.selected : ""}`}
            style={{ marginLeft: index === 0 ? "0px" : "-35px", backgroundColor: section.id === selectedSection.id ? "lightgreen" : "lightgrey" }}
            onClick={() => {
              onClick(index);
            }}
            key={index}
          >
            <i className="fal fa-circle" style={{ paddingRight: ".5rem" }} /> {section.name}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default EvaluationSectionBar;
