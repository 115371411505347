import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Blink, UserAvatar, BTypography, PathProgress, colors, NormalButton, SortableList, PopTip, FilterTextField } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  header: {
    position: "sticky",
    top: "0",
    zIndex: "101",
    background: colors.white
  },
  body: { padding: "0rem 0" },
  table: { width: "15rem", margin: "1rem" },
  col: {
    padding: "0.5rem 1rem",
    textAlign: "center"
  },
  emptyCol: { color: colors.lightGray },
  buttonCol: { padding: "0rem 1rem" },
  colHeading: { fontWeight: "bold" },
  progress: {
    minWidth: "10rem"
  },
  tabs: {
    padding: "0.25rem 0 0 0"
  },
  tab: {
    padding: "1rem 1rem",
    borderRadius: "0",
    textAlign: "center"
  },
  activeTab: {
    background: `${colors.bildBlue} !important`,
    "&:hover": {
      background: `${colors.darkBildBlue} !important`
    }
  }
});

export default function ShepherdingOverview({ user, data, dashboardView }) {
  const cls = useStyles();
  const [tab, setTab] = useState(1);
  const [filter, setFilter] = useState("");
  const [autoFilter, setAutoFilter] = useState(false);
  const [userRowItems, setUserRowItems] = useState([]);
  const [cohortRowItems, setCohortRowItems] = useState([]);
  const [orgRowItems, setOrgRowItems] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [cohortOrgsCount, setCohortOrgsCount] = useState(0);
  const [orgsCount, setOrgsCount] = useState(0);

  useEffect(() => {
    // ----------- 1) USER DATA
    setUserRowItems(
      data.shepherdingOverviews
      .filter(x => {
        if (filter.length > 0) {
          return (
            x.user && x.user.name.toLowerCase().includes(filter.toLowerCase()) ||
            x.mcceeCohortOrganization.organizationName && x.mcceeCohortOrganization.organizationName.toLowerCase().includes(filter.toLowerCase()) ||
            x.bildPrimaryOrganization.organizationName && x.bildPrimaryOrganization.organizationName.toLowerCase().includes(filter.toLowerCase())
          );
        } else {
          return 1;
        }
      })
      .sort((a,b) => a.user.name.localeCompare(b.user.name))
      .map((x,i) => [
        [
          <Grid container item xs={12} className={cls.buttonCol} justifyContent="flex-end" alignItems="center" sortval={x.user.name} key={x.user.id}>
            <Grid item>
              <NormalButton
                dst="equip-review-shepherding-tree"
                user_id={x.user.id}
                variant={"secondary"}
                color={colors.white}
                hoverColor={colors.veryLightGray}
                labelColor={colors.bildBlue}
                hoverLabelColor={colors.bildBlue}
                borderColor={colors.bildBlue}
              >
                <UserAvatar size={25} src={x.user.avatarURL} name={x.user.name} badgeSrc={x.user.badgeURL} />
                &nbsp;
                <i className="fal fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
        ],
        <NormalButton
          dst="manage-organization"
          organization_id={x.mcceeCohortOrganization.organizationId}
          variant={"secondary"}
          color={colors.white}
          hoverColor={colors.veryLightGray}
          labelColor={colors.bildBlue}
          hoverLabelColor={colors.bildBlue}
          borderColor={colors.bildBlue}
        >
          {x.mcceeCohortOrganization.organizationName}
          &nbsp;
          <i className="fal fa-chevron-right" />
        </NormalButton>,
        <NormalButton
          dst="manage-organization"
          organization_id={x.bildPrimaryOrganization.organizationId}
          variant={"secondary"}
          color={colors.white}
          hoverColor={colors.veryLightGray}
          labelColor={colors.bildBlue}
          hoverLabelColor={colors.bildBlue}
          borderColor={colors.bildBlue}
        >
          {x.bildPrimaryOrganization.organizationName}
          &nbsp;
          <i className="fal fa-chevron-right" />
        </NormalButton>,
        [
          <Grid
            item
            xs={12}
            className={cls.col}
            sortval={
              x.userPaths[0] ? (
                (x.userPaths[0].totalInProgressCoreSteps / x.userPaths[0].totalCoreSteps) + (x.userPaths[0].totalInProgressSteps / x.userPaths[0].totalSteps) + 
                (x.userPaths[0].totalCompletedCoreSteps / x.userPaths[0].totalCoreSteps) + (x.userPaths[0].totalCompletedSteps / x.userPaths[0].totalSteps) + 2
              ) : 1
            }
            key={"userPaths"}
          >
            {x.userPaths.map((path,j) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  key={j}
                  justifyContent={"center"}
                  alignItems={"center"}
                  component={Blink}
                  dst={"equip-user-path"}
                  user_path_id={path.id}
                  className={cls.userPath}
                >
                  <Grid container item xs={11} className={cls.progress}>
                    <PathProgress
                      totalCompletedCoreSteps={path.totalCompletedCoreSteps}
                      totalInProgressCoreSteps={path.totalInProgressCoreSteps}
                      totalCoreSteps={path.totalCoreSteps}
                      totalCompletedSteps={path.totalCompletedSteps}
                      totalInProgressSteps={path.totalInProgressSteps}
                      totalSteps={path.totalSteps}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ],
        [
          <Grid
            item
            xs={12}
            className={cls.col}
            sortval={x.userPaths[0] ? x.userPaths[0].lastItemUpdatedAt : 1}
            key={"userPaths"}
          >
            {x.userPaths.map((path,j) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  key={j}
                >
                  <Grid container item xs={11}>
                    {path.lastItemUpdatedAt}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${x.level1ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={x.level1ShepherdingTotal > 0 ? x.level1ShepherdingTotal : 1} key={"lvl1"}>
            {x.level1ShepherdingTotal.toLocaleString()}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${x.level2ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={x.level2ShepherdingTotal > 0 ? x.level2ShepherdingTotal : 1} key={"lvl2"}>
            {x.level2ShepherdingTotal.toLocaleString()}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${x.level3ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={x.level3ShepherdingTotal > 0 ? x.level3ShepherdingTotal : 1} key={"lvl3"}>
            {x.level3ShepherdingTotal.toLocaleString()}
          </Grid>
        ]
      ])
    );

    // ----------- 2) Pre-process COHORT DATA
    let tempCohortOrgIds = data.shepherdingOverviews.map(x => x.mcceeCohortOrganization.organizationId);
    let cohortOrgIds = Array.from(new Set(tempCohortOrgIds));
    let cohortOrgs = [];
    let uniqCohortUserIds = [];
    for (let i=0; i<cohortOrgIds.length; i++) {
      let cohortOrgId = cohortOrgIds[i];
      let cohortOrg = {"id": cohortOrgId, "name": "", "McceeTotal": 0, "level1ShepherdingTotal": 0, "level2ShepherdingTotal": 0, "level3ShepherdingTotal": 0};
      let cohortOverviews = data.shepherdingOverviews.filter(x => x.mcceeCohortOrganization.organizationId === cohortOrgId);
      for (let j=0; j<cohortOverviews.length; j++) {
        let cohortOverview = cohortOverviews[j];

        if (!uniqCohortUserIds.includes(cohortOverview.user.id)) {
          uniqCohortUserIds.push(cohortOverview.user.id);
          cohortOrg.name = cohortOverview.mcceeCohortOrganization.organizationName;
          cohortOrg.McceeTotal += 1;
          cohortOrg.level1ShepherdingTotal += cohortOverview.level1ShepherdingTotal;
          cohortOrg.level2ShepherdingTotal += cohortOverview.level2ShepherdingTotal;
          cohortOrg.level3ShepherdingTotal += cohortOverview.level3ShepherdingTotal;
        }
      }
      cohortOrgs.push(cohortOrg);
    }
    setCohortOrgsCount(cohortOrgIds.length);

    // SET ORG DATA
    setCohortRowItems(
      cohortOrgs
      .filter(x => {
        if (filter.length > 0) {
          return (
            x.name && x.name.toLowerCase().includes(filter.toLowerCase())
          );
        } else {
          return 1;
        }
      })
      .map((org,i) => [
        [
          <Grid item sortval={org.name} key={org.id}>
            <NormalButton
              variant={"secondary"}
              color={colors.white}
              hoverColor={colors.veryLightGray}
              labelColor={colors.bildBlue}
              hoverLabelColor={colors.bildBlue}
              borderColor={colors.bildBlue}
              onClick={()=>{_setFilterAndTab(org.name, 1)}}
            >
              {org.name}
              &nbsp;
              <i className="fal fa-filter" />
            </NormalButton>
          </Grid>
        ],
        [
          <Grid item sortval={org.McceeTotal} key={org.id}>{org.McceeTotal}</Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${org.level1ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={org.level1ShepherdingTotal > 0 ? org.level1ShepherdingTotal : 1} key={"lvl1"}>
            {org.level1ShepherdingTotal.toLocaleString()}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${org.level2ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={org.level2ShepherdingTotal > 0 ? org.level2ShepherdingTotal : 1} key={"lvl2"}>
            {org.level2ShepherdingTotal.toLocaleString()}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${org.level3ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={org.level3ShepherdingTotal > 0 ? org.level3ShepherdingTotal : 1} key={"lvl3"}>
            {org.level3ShepherdingTotal.toLocaleString()}
          </Grid>
        ]
      ])
    );


    // ----------- 3) Pre-process ORG DATA
    let tempOrgIds = data.shepherdingOverviews.map(x => x.bildPrimaryOrganization.organizationId);
    let orgIds = Array.from(new Set(tempOrgIds));
    let orgs = [];
    let uniqUserIds = [];
    for (let i=0; i<orgIds.length; i++) {
      let orgId = orgIds[i];
      let org = {"id": orgId, "name": "", "McceeTotal": 0, "level1ShepherdingTotal": 0, "level2ShepherdingTotal": 0, "level3ShepherdingTotal": 0};
      let overviews = data.shepherdingOverviews.filter(x => x.bildPrimaryOrganization.organizationId === orgId);
      for (let j=0; j<overviews.length; j++) {
        let overview = overviews[j];

        if (!uniqUserIds.includes(overview.user.id)) {
          uniqUserIds.push(overview.user.id);
          org.name = overview.bildPrimaryOrganization.organizationName;
          org.McceeTotal += 1;
          org.level1ShepherdingTotal += overview.level1ShepherdingTotal;
          org.level2ShepherdingTotal += overview.level2ShepherdingTotal;
          org.level3ShepherdingTotal += overview.level3ShepherdingTotal;
        }
      }
      orgs.push(org);
    }
    setUsersCount(uniqUserIds.length);
    setOrgsCount(orgIds.length);

    // SET ORG DATA
    setOrgRowItems(
      orgs
      .filter(x => {
        if (filter.length > 0) {
          return (
            x.name && x.name.toLowerCase().includes(filter.toLowerCase())
          );
        } else {
          return 1;
        }
      })
      .map((org,i) => [
        [
          <Grid item sortval={org.name} key={org.id}>
            <NormalButton
              variant={"secondary"}
              color={colors.white}
              hoverColor={colors.veryLightGray}
              labelColor={colors.bildBlue}
              hoverLabelColor={colors.bildBlue}
              borderColor={colors.bildBlue}
              onClick={()=>{_setFilterAndTab(org.name, 1)}}
            >
              {org.name}
              &nbsp;
              <i className="fal fa-filter" />
            </NormalButton>
          </Grid>
        ],
        [
          <Grid item sortval={org.McceeTotal} key={org.id}>{org.McceeTotal}</Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${org.level1ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={org.level1ShepherdingTotal > 0 ? org.level1ShepherdingTotal : 1} key={"lvl1"}>
            {org.level1ShepherdingTotal.toLocaleString()}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${org.level2ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={org.level2ShepherdingTotal > 0 ? org.level2ShepherdingTotal : 1} key={"lvl2"}>
            {org.level2ShepherdingTotal.toLocaleString()}
          </Grid>
        ],
        [
          <Grid item xs={12} className={`${cls.col} ${org.level3ShepherdingTotal <= 0 ? cls.emptyCol : ""}`} sortval={org.level3ShepherdingTotal > 0 ? org.level3ShepherdingTotal : 1} key={"lvl3"}>
            {org.level3ShepherdingTotal.toLocaleString()}
          </Grid>
        ]
      ])
    );
  }, [data, filter]);

  function _setFilterAndTab(val, tabNum) {
    setAutoFilter(true);
    setFilter(val);
    setTab(tabNum);
  }

  function makeTab(i, name, count) {
    return (
      <NormalButton
        component={Grid}
        item
        md={2}
        sm={3}
        xs={12}
        onClick={() => {
          if (autoFilter) {
            setFilter("");
            setAutoFilter(false);
          }
          setTab(i);
        }}
        className={`${cls.tab} ${i === tab ? cls.activeTab : ""}`}
        variant="primary"
        color={colors.gray}
        labelColor={colors.white}
        hoverColor={colors.darkGray}
      >
        {name} {count >= 0 && (<>({count})</>)}
      </NormalButton>
    );
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.header}>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          {!dashboardView && (
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <Grid item>
                <NormalButton component={Blink} dst="manage-user" user_id={user.id}>
                  <UserAvatar size={35} src={user.avatarURL} name={user.name} badgeSrc={user.badgeURL} nameVariant="subtitle1" />
                </NormalButton>
              </Grid>
              <Grid item>
                <BTypography variant="subtitle1">&nbsp; MCCEE Shepherding Overview &nbsp;</BTypography>
              </Grid>
              <Grid item>
                <PopTip
                  text={
                    <table className={cls.table}>
                      <tbody>
                        <tr><td>Users:</td><td>{usersCount.toLocaleString()}</td></tr>
                        <tr><td>Cohorts:</td><td>{cohortOrgsCount.toLocaleString()}</td></tr>
                        <tr><td>Partners:</td><td>{orgsCount.toLocaleString()}</td></tr>
                        <tr><td>Level 1:</td><td>{data.allLevel1ShepherdingTotal ? data.allLevel1ShepherdingTotal.toLocaleString() : "~"}</td></tr>
                        <tr><td>Level 2:</td><td>{data.allLevel2ShepherdingTotal ? data.allLevel2ShepherdingTotal.toLocaleString() : "~"}</td></tr>
                        <tr><td>Level 3:</td><td>{data.allLevel3ShepherdingTotal ? data.allLevel3ShepherdingTotal.toLocaleString() : "~"}</td></tr>
                      </tbody>
                    </table>
                  }
                >
                  <i className="fad fa-abacus" />
                </PopTip>
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.tabs}>
            <Grid item md={2} sm={3} xs={12}>
              &nbsp;
            </Grid>
            {makeTab(1, `Users`)}
            {makeTab(2, `Cohorts`)}
            {makeTab(3, `Partners`)}
            <Grid item md={2} sm={3} xs={12}>
              <FilterTextField
                value={filter}
                placeholder="Search"
                onChange={e => setFilter(e.target.value)}
                onClear={() => {
                  setFilter("");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center" className={cls.body}>
        {tab === 1 && (
          <SortableList
            perPage={dashboardView ? null : 10}
            filter={filter}
            headers={["Name", "Cohort", "Partner", "MCCEE Progress", "Last Updated", "People (Level 1)", "People (Level 2)", "People (Level 3)"]}
            items={userRowItems}
            itemPadding={"0.5rem 1rem"}
            spacing={[2,2,2,2,1,1,1,1]}
            fixedHeight={userRowItems > 0 ? "38rem" : false}
            itemType={"Users"}
            emptyMessage={"No Assigned Users"}
          />
        )}
        {tab === 2 && (
          <SortableList
            perPage={dashboardView ? null : 10}
            filter={filter}
            headers={["Cohort Partner Name", "MCCEE Learners", "People (Level 1)", "People (Level 2)", "People (Level 3)"]}
            items={cohortRowItems}
            itemPadding={"0.5rem 1rem"}
            spacing={[8,1,1,1,1]}
            fixedHeight={orgRowItems > 0 ? "38rem" : false}
            itemType={"Cohorts"}
            emptyMessage={"No Assigned Organizations"}
          />
        )}
        {tab === 3 && (
          <SortableList
            perPage={dashboardView ? null : 10}
            filter={filter}
            headers={["Name", "MCCEE Learners", "People (Level 1)", "People (Level 2)", "People (Level 3)"]}
            items={orgRowItems}
            itemPadding={"0.5rem 1rem"}
            spacing={[8,1,1,1,1]}
            fixedHeight={orgRowItems > 0 ? "38rem" : false}
            itemType={"Partners"}
            emptyMessage={"No Assigned Organizations"}
          />
        )}
      </Grid>
    </Grid>
  );
}
