import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { enrollmentData, ENUMS } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Enrollment from "./enrollment.js";

// ENROLLMENT LOADER
function EnrollmentLoader({}) {
  const { enrollment_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [programDashboard, setProgramDashboard] = useState(null);
  const [programReviewStatus, setProgramReviewStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(()=>{
    setPageTitle("Enrollment");

    // Load "overview" and "transcript" tabs
    enrollmentData.loadOverview(enrollment_id, _setProgramReviewStatus, setError);
  },[])

  function _setProgramReviewStatus(data) {
    // TODO: Antioch FE ?? -- Get titles from the API (st, cl, af) for transcript
    data["statusItemHeaders"] = [ENUMS.ROLES.STUDENT.NAME, ENUMS.ROLES.LOCAL_MENTOR.NAME, ENUMS.ROLES.ASSOCIATE_FACULTY.NAME];
    data["schoolImage"] = "/antioch_school_logo.png";

    setProgramReviewStatus(data);
    setLoading(false);

    // Load/reload "dashboard" tab
    enrollmentData.loadDashboard(enrollment_id, setProgramDashboard, setError);
  }

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <Enrollment
        programReviewStatus={programReviewStatus}
        programDashboard={programDashboard}
        setProgramReviewStatus={setProgramReviewStatus}
      />
    );
  }
}

export default EnrollmentLoader;
