import { getRequest, putRequest } from "bild-data/core/comm.js";

export function getChannels(success_cb, error_cb) {
  getRequest(
    `/messages/channels`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getChannelMessages(channel_id, success_cb, error_cb) {
  getRequest(
    `/messages/channels/${channel_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getAvailableMessageRecipients(success_cb, error_cb) {
  getRequest(
    `/messages/recipients`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getCheckNewMessages(success_cb, error_cb) {
  getRequest(
    `/messages/check_unread`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    },
    false, // skipRetry
    false, // skipPreFlight
    true  // hideAllErrors
  );
}

export function createMessages(channel_id, data, success_cb, error_cb) {
  putRequest(
    `/messages/channels/${channel_id}`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function createUpdateChannels(data, success_cb, error_cb) {
  putRequest(
    `/messages/channels`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}
