import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import UserRecordListItem from "./components/userRecordListItem.js";

const useStyles = makeStyles({
  documentWrapper: { background: "#FFF", minHeight: "100%", padding: "1rem 1rem 3rem 1rem" },
  heading: { paddingBottom: "0.5rem" },
  section: { padding: "1rem 0" }
});

function UserRecords({ records, ...props }) {
  const classes = useStyles();

  let emptyMessage;
  let eaList = [];
  for (let i = 0; i < records.length; i++) {
    let ea = records[i];
    eaList.push(<UserRecordListItem document={ea} key={i} />);
  }

  if (eaList.length < 1) {
    emptyMessage = (
      <>
        <Grid item xs={12}>
          <Typography variant="h6">No documents available yet.</Typography>
        </Grid>
      </>
    );
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.documentWrapper}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.heading}>
            Academic Records
          </Typography>
        </Grid>
        {!emptyMessage && eaList && (
          <>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="h4">Enrollment Agreement History</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {eaList}
          </>
        )}
        {emptyMessage}
      </Grid>
    </Grid>
  );
}

export default UserRecords;
