import React, { useState, useEffect } from "react";
import { localStorage, managerData } from "bild-data";
import { hideIntercom, setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import ManageGroup from "./manageGroup.js";

function ManageGroupLoader({ startGroupId }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [paths, setPaths] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(startGroupId ? startGroupId : null);

  useEffect(() => {
    hideIntercom();

    function _setData(d) {
      setPageTitle("Manage Groups");
      setGroups(d.sharingGroups);
      setLoading(false);
    }
    function _setAvailableUsers(m) {
      setUsers(m.users);
    }
    function _setAvailablePaths(p) {
      setPaths(p.paths);
    }

    managerData.getManagedGroups(_setData, e => {
      setError(e.response.data.error);
    });
    managerData.getManagedUsers(_setAvailableUsers, e => {
      setError(e.response.data.error);
    });
    managerData.getManagedPaths(_setAvailablePaths, e => {
      setError(e.response.data.error);
    });
  }, []);

  function createUpdateGroup(group_id, name, new_paths, remove_paths, new_users, remove_users, assignPrograms, createGroup, shepherdId) {
    let data = {
      sharingGroups: [
        {
          id: group_id,
          name: name,
          paths: new_paths.map(x => ({ id: x })),
          pathsToRemove: remove_paths.map(x => ({ id: x })),
          users: new_users.map(x => ({ id: x })),
          usersToRemove: remove_users.map(x => ({ id: x })),
          assignProgramsToMembers: assignPrograms,
          createSharingGroup: createGroup,
          shepherdUserId: shepherdId
        }
      ]
    };
    managerData.updateManagedSharingGroups(data, d => {
      if (group_id) {
        toaster.success(`${name} Updated!`);
      } else {
        toaster.success(`${name} Created!`);
      }
      setGroups(d.sharingGroups);
    });
  }

  function createUpdateCohort(group_id, name, new_paths, new_users, new_user_paths, createGroup) {
    let data = {
      sharingGroups: [
        {
          id: group_id,
          name: name,
          paths: new_paths.map(x => ({ id: x })),
          users: new_users.map(x => ({ id: x })),
          userPaths: new_user_paths.map(x => ({ pathId: x.programId, user: { id: x.userId }, shepherdUser: { id: x.shepherdUserId } })),
          createSharingGroup: true
        }
      ]
    };
    managerData.updateManagedSharingGroups(data, d => {
      if (group_id) {
        toaster.success(`${name} Updated!`);
      } else {
        toaster.success(`${name} Created!`);
      }
      setGroups(d.sharingGroups);
    });
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <ManageGroup
      users={users}
      paths={paths}
      groups={groups}
      startGroupId={groupId}
      createUpdateGroup={createUpdateGroup}
      createUpdateCohort={createUpdateCohort}
    />
  );
}

export default ManageGroupLoader;
