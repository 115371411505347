const classes = {
  container: "dashboard",
  programs: "dashboard-programs",
  expansionPanelHeader: {
    root: "dashboard-ep-header-root",
    expanded: "dashboard-ep-header-content",
    content: "dashboard-ep-header-content"
  },
  expansionPanelBody: "dashboard-ep-body",

  tabContainer: "dashboard-tab-container",
  tabIcon: "dashboard-tab-icon",

  programContainer: "dashboard-program-container",
  negativeMarginDivider: "negative-margin-divider",
  expandButton: "expand-all-years-button",

  pogContainer: "dashboard-pog-container",
  pogTitle: "dashboard-pog-title",
  pogCourseGroup: "dashboard-pog-course-group",
  pogCourseGroupHeader: "dashboard-pog-course-group-header",
  pogCourseGroupHeaderText: "dashboard-pog-course-group-header-text",
  pogCourseGroupBody: "dashboard-pog-course-group-body",
  pogCourse: "dashboard-pog-course",
  pogCourseIcon: "dashboard-pog-course-icon",
  pogCourseText: "dashboard-pog-course-text",

  quickActions: "dashboard-quick-actions",
  quickActionTile: "dashboard-qa-tile",
  quickActionIcon: "dashboard-qa-icon",
  quickActionGridItem: "dashboard-qa-grid-item",
  quickActionGridItemLink: "dashboard-qa-grid-item-link",
  quickActionTitle: "dashboard-qa-title"
};

export default classes;
