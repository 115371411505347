import React, { useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";

import { Editable, Slate } from "slate-react";

import { Grid } from "@mui/material";

import { CommentToolbar, Leaf, Element, constants, useCommentEditor } from ".";

const useStyles = makeStyles({
  container: { height: "8rem", width: "100%", borderRadius: "4px", border: "1px solid darkgrey", backgroundColor: "white" },
  editableContainer: { padding: "0.75rem", overflowX: "hidden", overFlowY: "auto" },
  editable: { minHeight: "100%" }
});

function CommentEditor({ onSend }) {
  const classes = useStyles();
  const [value, setValue] = useState(constants.initialValue);
  const editor = useCommentEditor();

  function _sendComment() {
    if (typeof onSend === "function") onSend(value);
    setValue(constants.initialValue);
  }
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  return (
    <Slate editor={editor} value={value} onChange={newValue => setValue(newValue)}>
      <Grid container className={classes.container} direction="column" wrap="nowrap">
        <Grid item xs className={classes.editableContainer}>
          <Editable
            id="bild-editor"
            placeholder="Add a comment..."
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            className={`${classes.editable} notranslate`}
          />
        </Grid>
        <Grid item>
          <CommentToolbar marks={constants.marks} blocks={constants.blocks} onSendIntent={_sendComment} />
        </Grid>
      </Grid>
    </Slate>
  );
}

export default CommentEditor;
