import React from "react";
import { makeStyles } from "@mui/styles";
import { BTypography, NormalButton, colors } from "bild-ui";

import TranscriptCourse from "../enrollment/components/transcriptCourse.js";
import ContentWrapper from "presentational/wrappers/contentWrapper.js";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "../enrollment/enrollment.css";

const useStyles = makeStyles({
  header: { padding: "0.5rem 0", position: "sticky", top: "0", background: colors.white, zIndex: "10" },
  title: { paddingBottom: "0.5rem" },
  enrollmentWrapper: { padding: "1rem", background: "#fff", minHeight: "100%", width: "100%" },
  courseGroup: { width: "100%" },
  totals: { padding: "0.5rem 0 1rem 0" }
});

function UserAdminCompetency({ user, courseGroups, totals, statusItemHeaders, filterCompetencies, filter }) {
  const cls = useStyles();

  function renderCourses(courses) {
    const components = [];
    for (var i = 0; i < courses.length; i++) {
      components.push(
        <TranscriptCourse course={courses[i]} statusItemHeaders={statusItemHeaders} showCourseNumber={true} showHandbook={true} key={i} />
      );
    }
    return components;
  }

  const totalComponents = [];
  Object.keys(totals).forEach(function(item) {
    totalComponents.push(
      <Grid container item xs key={item} justifyContent="center" alignItems="center">
        <Grid item>
          <NormalButton
            onClick={() => {
              filterCompetencies(totals[item].filter);
            }}
            variant="primary"
            color={filter === totals[item].filter ? colors.bildBlue : colors.white}
            labelColor={filter === totals[item].filter ? colors.white : colors.bildBlue}
            hoverColor={colors.darkBildBlue}
            borderColor={colors.bildBlue}
          >
            {totals[item].value} {item}
          </NormalButton>
        </Grid>
      </Grid>
    );
  });

  const components = [];
  for (let i = 0; i < courseGroups.length; i++) {
    const group = courseGroups[i];
    let courses = [];
    let groupColor = group.courses[0].courseGroupColor.webFrontendValue;

    courses = renderCourses(group.courses);
    components.push(
      <ContentWrapper variant="flat" key={i} className={cls.courseGroup}>
        <Typography variant="h5" gutterBottom style={{ color: groupColor }}>
          {group.name}
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          {courses}
        </Grid>
      </ContentWrapper>
    );
  }

  return (
    <div className={cls.enrollmentWrapper}>
      <Grid container item xs={12} className="enrollment">
        <Grid container item xs={12} className={cls.header}>
          <Grid item xs={12} className={cls.title}>
            <BTypography variant="h3">{user && user.name}'s Competency Proofs</BTypography>
          </Grid>
          <Grid container item xs={12} className={cls.totals}>
            {totalComponents}
          </Grid>
        </Grid>
        {components}
      </Grid>
    </div>
  );
}

export default UserAdminCompetency;
