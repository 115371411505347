import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { loadPasswordReset, passwordReset } from "bild-data/user";
import PasswordReset from "./passwordReset.js";
import LoadingScreen from "presentational/screens/loadingScreen.js";

// PASSWORD RESET LOADER
function PasswordResetLoader({}) {
  const { reset_digest } = useParams();
  setPageTitle("Password");
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => loadPasswordReset(reset_digest, setState), []); // Empty array == componentDidMount()

  function handleReset(formData) {
    // format the form data into the data needed for the API
    let data = {
      emailAddress: state.username,
      resetToken: reset_digest,
      newPassword: formData.password,
      newPasswordConfirm: formData.confirm_password
    };
    passwordReset(reset_digest, data, setState);
  }

  if (state.isLoading || state.error) {
    return <LoadingScreen error={state.error} />;
  } else {
    return <PasswordReset handleSubmit={handleReset} username={state.username} />;
  }
}

export default PasswordResetLoader;
