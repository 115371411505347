import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography, Grid } from "@mui/material";

import RolePucks from "presentational/rolePucks/rolePucks.js";

const useStyles = makeStyles({
  row: { borderBottom: "1px solid lightgrey" },
  item: { padding: "0.25rem" }
});

function Comment({ role, user, comment, createdAt }) {
  const rolePrefix = (role || {}).prefix;
  const name = (user || {}).name;

  const classes = useStyles();
  return (
    <Grid container className={classes.row} alignItems="center">
      <Grid item lg={1} md={2} sm={2} xs={3} className={classes.item}>
        <RolePucks prefixes={[rolePrefix]} />
      </Grid>
      <Grid item sm={2} xs={9} className={classes.item}>
        <Typography variant="body1">{name}</Typography>
      </Grid>
      <Grid item xs className={classes.item}>
        <Typography variant="body1">{comment}</Typography>
      </Grid>
      <Grid item sm={2} xs={5} className={classes.item}>
        <Typography variant="body1">{createdAt}</Typography>
      </Grid>
    </Grid>
  );
}

export default Comment;
