import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { toaster } from "presentational/toasts/toasts.js";
import { colors, Avatar, LoadingScreen, BTypography, NormalButton } from "bild-ui";
import { Grid, Dialog, DialogTitle, DialogContent, FormControl, FormLabel, FormHelperText, Hidden } from "@mui/material";

const useStyles = makeStyles({
  container: { padding: "2rem" },
  avatars: { paddingTop: "1rem" },
  ready: { color: colors.bildBlue },
  arrow: { color: colors.darkerGray },
  hint: { padding: "1rem", fontStyle: "italic" },
  newAvatar: { "&:hover": { cursor: "pointer", filter: "brightness(0.9)" } }
});

function UploadAvatarDialog({ open, closeDialog, uploadNewAvatar, userProfile, handleFileUpload, handleUploadAvatarFile, classes, ...props }) {
  const cls = useStyles();
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [avatarImageSource, setAvatarImageSource] = useState(null);
  const [avatarOriginalImageSource, setAvatarOriginalImageSource] = useState(null);
  const [avatarSmallImageSource, setAvatarSmallImageSource] = useState(null);
  const inputFile = useRef(null);
  const canvasRef = useRef(null);
  const canvasSmallRef = useRef(null);

  function _handleSubmit() {
    if (_validateForm()) {
      setForm({ ...form, isLoading: true });
    }
    // Upload normal, cropped avatar and unmodified original and small, cropped avatar
    uploadNewAvatar(avatarImageSource, avatarOriginalImageSource, avatarSmallImageSource, _handleSuccess, _handleFailure);
  }

  function _handleSuccess() {
    // Request successfully recieved, let the user know and then close the dialog
    toaster.success("Your profile image has been successfully updated.");
    setForm({ ...form, isLoading: false });
    closeDialog();
  }

  function _handleFailure(e) {
    // Things have failed, let the user know to try again or email us directly
    toaster.error("There was a problem updating your profile image.");
    setForm({ ...form, isLoading: false });
  }

  function _validateForm() {
    // make sure that the file being upload is an actual image
    return true;
  }

  function _handleImageChange() {
    // get image that was selected to be uploaded
    var file = inputFile.current.files[0];
    // is this an image file or not?
    if (file && file.type.match("image.*")) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      // set the src of the preview image to be the newly selected file
      reader.onloadend = function(e) {
        this.url = e.target.result;
        setAvatarOriginalImageSource(e.target.result);
        const context = canvasRef.current.getContext("2d");
        const image = new Image();
        image.src = this.url;
        image.onload = () => {
          // set cropping dimension
          const dimensions = 400;
          const smallDimensions = 50;
          // set output default dimensions to cropping size
          let outW = dimensions;
          let outH = dimensions;
          let imageW = image.width;
          let imageH = image.height;
          let mx = 1;
          // If the image is not square, find the shorter side to set as our resizing anchor
          if (imageW >= imageH) {
            mx = imageH / dimensions;
          }
          if (imageW < imageH) {
            mx = imageW / dimensions;
          }
          // resize the image, keeping the original scale
          outW = outW * mx;
          outH = outH * mx;
          // center the source x,y
          const sx = Math.max(0, (imageW - outW) / 2);
          const sy = Math.max(0, (imageH - outH) / 2);
          // clear the canvas
          context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          // draw the resized image in the center of the cropping zone
          context.drawImage(image, sx, sy, outW, outH, 0, 0, dimensions, dimensions);
          // get the base64 string to pass to the display
          var dataURL = canvasRef.current.toDataURL("image/png");
          setAvatarImageSource(dataURL);

          // Store small version as well
          const smallCtx = canvasSmallRef.current.getContext("2d");
          // clear the canvas
          smallCtx.clearRect(0, 0, canvasSmallRef.current.width, canvasSmallRef.current.height);
          // draw the resized image in the center of the cropping zone
          smallCtx.drawImage(image, sx, sy, outW, outH, 0, 0, smallDimensions, smallDimensions);
          // get the base64 string to pass to the display
          var smallDataURL = canvasSmallRef.current.toDataURL("image/png");
          setAvatarSmallImageSource(smallDataURL);
        };
      };
      setError({ avatar_image: null });
    } else {
      setError({ avatar_image: "Only image files are allowed. Please select a different file." });
      setAvatarImageSource(null);
    }
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  let content = (
    <form onSubmit={_handleSubmit}>
      <canvas ref={canvasRef} width="400" height="400" style={{ display: "none" }} />
      <canvas ref={canvasSmallRef} width="50" height="50" style={{ display: "none" }} />
      <Grid container justifyContent="space-around" alignItems="stretch" className="avatar-upload">
        <Grid container item xs={12} justifyContent="space-around" alignItems="center" className={cls.avatars}>
          <Grid item>
            <Avatar size={150} name={userProfile.name} src={userProfile.avatarURL} />
          </Grid>
          <Hidden smDown>
            <Grid item className={cls.arrow}>
              <i className={`${avatarImageSource ? cls.ready : ""} fas fa-long-arrow-right fa-4x`} />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <Grid item className={cls.arrow}>
                <i className={`${avatarImageSource ? cls.ready : ""} fas fa-long-arrow-down fa-4x`} />
              </Grid>
            </Grid>
          </Hidden>
          <Grid
            item
            onClick={() => {
              inputFile.current.click();
            }}
            className={cls.newAvatar}
          >
            <Avatar color={colors.lightGray} name="+" size={150} src={avatarImageSource} />
            <input type="file" accept=".png, .jpg, .jpeg, .gif" onChange={_handleImageChange} ref={inputFile} style={{ display: "none" }} />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item className={cls.hint}>
            <BTypography variant="body1">For best results upload an image that is 400x400 resolution</BTypography>
          </Grid>
          <Grid item className={cls.button}>
            <NormalButton
              disabled={avatarImageSource ? false : true}
              onClick={_handleSubmit}
              variant="primary"
              color={colors.bildBlue}
              labelColor={colors.white}
            >
              Upload Picture
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
  if (form.isLoading) {
    content = <LoadingScreen />;
  }
  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogContent className={cls.container}>{content}</DialogContent>
    </Dialog>
  );
}
export default UploadAvatarDialog;
