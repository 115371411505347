import React from "react";
import { Route } from "react-router-dom";

// LOADERS
import AuditAntiochCoursesLoader from "./audit/auditAntiochCoursesLoader.js";
import AuditAntiochCourseLoader from "./audit/auditAntiochCourseLoader.js";


////// ANTIOCH ROUTES
export default [
  <Route path="/audit_courses" element={<AuditAntiochCoursesLoader/>} key={"ant-0"} />,
  <Route path="/audit_courses/users/:user_id" element={<AuditAntiochCoursesLoader/>} key={"ant-1"} />,
  <Route path="/audit_courses/:course_id/users/:user_id/competency_sets/:competency_set_id" element={<AuditAntiochCourseLoader/>} key={"ant-2"} />,
];
