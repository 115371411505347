import React, { useState, useEffect } from "react";
import { fundraisingData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import FundraisingItems from "./fundraisingItems.js";

function FundraisingItemsLoader({}) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    function _setData(m) {
      setPageTitle("Fundraising");
      setItems(m.fundraisingItems);
      setTasks(m.fundraisingTasks);
      setLoading(false);
    }

    fundraisingData.getFundraisingItems(_setData);
  }, []);

  if (loading) return <LoadingScreen />;
  return <FundraisingItems items={items} tasks={tasks} />;
}

export default FundraisingItemsLoader;
