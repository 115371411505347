import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { colors, Avatar, PopTip, BTypography } from "bild-ui";
import { Grid, TextField, List, ListItemButton, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox } from "@mui/material";
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles({
  filterWrapper: { width: "100%", padding: "0.25rem 0.5rem", borderBottom: `1px solid ${colors.lightGray}` },
  filter: { width: "100%" },
  clearFilter: { textAlign: "center", "&:hover": { cursor: "pointer" } },
  itemButton: { padding: "0px" },
  membersWrapper: { border: `1px solid ${colors.veryLightBlue}`, borderRadius: "4px" },
  members: { overflowY: "auto", minHeight: "22vh", maxHeight: "22vh" },
  membersTall: { minHeight: "40vh !important", maxHeight: "40vh !important" },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "2",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  messageIcon: { color: colors.darkGray },
  disabled: { opacity: "0.75", pointerEvents: "none" }
});

function MembersChecklist({ filterable, user, members, selectedMembers, updateMember, variant, icon, disabled }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [_members, _setMembers] = useState([]);

  useEffect(()=>{
    if (filter) {
      _setMembers(members.filter(r => r.name.toLowerCase().includes(filter.toLowerCase()) || (r.subtitle && r.subtitle.toLowerCase().includes(filter.toLowerCase())) ));
    } else {
      _setMembers(members ? members : []);
    }
  },[members]);

  function updateFilter(val) {
    setFilter(val);
    _setMembers(members.filter(r => r.name.toLowerCase().includes(val.toLowerCase()) || (r.subtitle && r.subtitle.toLowerCase().includes(val.toLowerCase())) ));
  }

  // Virtualized Row Renderer
  const RowRenderer = ({ index, style }) => {
    const m = _members[index];
    let id = m.user ? m.user.id : m.id;
    let name = m.user ? m.user.name : m.name;
    let avatarURL = m.user ? m.user.avatarURL : m.avatarURL;
    let color = m.programColor ? m.programColor.webFrontendValue : m.color;
    let disabled = (m.memberType && m.memberType.id === 1) || (user && user.id === id);
    let subtitle = m.subtitle ? m.subtitle : null;
    let message = m.message ? m.message : null;
    
    return (
      <ListItemButton
        key={id}
        onClick={() => {
          if (!disabled) updateMember(id);
        }}
        disabled={disabled}
        style={style}
        className={cls.itemButton}
      >
        <ListItemAvatar>
          <Avatar size={40} src={avatarURL} icon={icon} name={name} color={color} srcSize="small" />
        </ListItemAvatar>
        <ListItemText primary={name} secondary={ReactHtmlParser(subtitle)} className={cls.name}/>
        <ListItemSecondaryAction
          onClick={() => {
            if (!disabled) updateMember(id);
          }}
        >
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            {message && (
              <Grid item>
                <PopTip text={ReactHtmlParser(message.text)} delay={1000}>
                  <BTypography variant={"subtitle1"} className={cls.messageIcon}>
                    <i className={message.icon} />
                  </BTypography>
                </PopTip>
              </Grid>
            )}
            <Grid item xs>
              <Checkbox edge="end" checked={selectedMembers.indexOf(id) !== -1} />
            </Grid>
          </Grid>
        </ListItemSecondaryAction>
      </ListItemButton>
    );
  };

  return (
    <Grid container className={`${cls.membersWrapper} ${disabled ? cls.disabled : ""}`}>
      {filterable && (
        <Grid container item xs={12} className={cls.filterWrapper} justifyContent="space-between" alignItems="center">
          <Grid item xs={11}>
            <TextField
              placeholder="Filter"
              value={filter}
              onChange={e => {
                updateFilter(e.target.value);
              }}
              className={cls.filter}
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          {filter !== "" && (
            <Grid
              item
              xs={1}
              className={cls.clearFilter}
              onClick={() => {
                updateFilter("");
              }}
            >
              <i className="fas fa-times" />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12} className={`${cls.members} ${variant && variant === "tall" ? cls.membersTall : ""}`}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className="List"
              height={height}
              width={width}
              itemCount={_members.length}
              itemSize={55}
            >
              {RowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Grid>
    </Grid>
  );
}

export default MembersChecklist;
