import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, NetworkNodeDetails, HeatBadge } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  node: {
    maxWidth: "20rem",
    minWidth: "7rem",
    display: "inline-block",
    position: "relative",
    top: "1px"
  },
  nodeInner: {
    border: `2px solid ${colors.bildGray}`,
    background: colors.white,
    overflow: "hidden",
    whiteSpace: "normal",
    color: colors.bildGray
  },
  nodeName: {
    background: colors.darkerGray,
    color: colors.white,
    fontWeight: "bold",
    /* Key fields allow for multiple lines before ellipsis */
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    padding: "0.25rem 0.5rem",
    minHeight: "2rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  badge: {
    position: "absolute",
    top: "-1rem",
    right: "-1rem",
    zIndex: "1000"
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  highlighted: { boxShadow: `${colors.yellow} 0px 0px 0px 3px, ${colors.yellow} 0px 0px 15px 1px`, },
  noChildren: { maxWidth: "10rem !important" },
  brtnode: { width: "20rem !important" },
  grtnode: { width: "15rem !important" },
  prtnode: { width: "15rem !important" },
  networkofnetworksnode: { width: "14rem !important" },
  networknode: { width: "12rem !important" },
  localnetworknode: { width: "10rem !important" },
  localorganizationnode: { width: "8rem !important" },
  brtname: { background: `${colors.brt} !important` },
  grtname: { background: `${colors.grt} !important` },
  prtname: { background: `${colors.prt} !important` },
  networkofnetworksname: { background: `${colors.networkofnetworks} !important` },
  networkname: { background: `${colors.network} !important` },
  localnetworkname: { background: `${colors.localnetwork} !important` },
  localorganizationname: { minHeight: "3.5rem", background: `${colors.localorganization} !important` },
  brt: { borderColor: `${colors.brt} !important` },
  grt: { borderColor: `${colors.grt} !important` },
  prt: { borderColor: `${colors.prt} !important` },
  networkofnetworks: { borderColor: `${colors.networkofnetworks} !important` },
  network: { borderColor: `${colors.network} !important` },
  localnetwork: { borderColor: `${colors.localnetwork} !important` },
  localorganization: { borderColor: `${colors.localorganization} !important` }
});

function TreeNode({ data, hideChildren, toggleNode, togglePopperData, highlightNode }) {
  const cls = useStyles();
  let oid = data.organization.organizationId;
  let users = data.subEqpUsersWithAnyProgram;
  let prog = data.subEqpUsersWithAnyProgress;
  let nodeClass = data.type.toLowerCase().replace(/\s+/g, "");
  let badgeLevel = prog > 500 ? 4 : prog > 100 ? 3 : prog > 25 ? 2 : 1;
  let badgeIcon = users > 500 ? "fire-4" : users > 100 ? "fire-3" : users > 25 ? "fire-2" : users > 0 ? "fire-1" : "";
  let hasChildren = data.children && data.children.length > 0;

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center" className={`${cls.node} ${cls[nodeClass + "node"]} ${highlightNode ? cls.highlighted : ""} ${!hasChildren ? cls.noChildren : ""}`}>
      <Grid item className={cls.badge}>
        {badgeIcon && <HeatBadge level={badgeLevel} icon={badgeIcon} />}
      </Grid>
      <Grid container item className={`${cls.nodeInner} ${cls[nodeClass]}`} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          onClick={e => {
            togglePopperData(e, data, colors[nodeClass]);
          }}
          className={`${cls.nodeName} ${cls[nodeClass + "name"]}`}
        >
          {data.organization.organizationName}
        </Grid>
        {hasChildren && (
          <Grid
            item
            xs={12}
            onClick={() => {
              toggleNode(oid);
            }}
            className={cls.hasChildren}
          >
            <i className={`fas fa-chevron-${hideChildren ? "up" : "down"}`} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default TreeNode;
