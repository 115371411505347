import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { colors, NormalButton } from "bild-ui";

const useStyles = makeStyles({
  wrapper: { padding: "1rem 0" },
  saveButton: { backgroundColor: colors.darkGray, width: "100%", "& > span": { color: colors.white } }
});

function ArtifactButtons({ save }) {
  const cls = useStyles();

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center" className={cls.wrapper}>
      <Grid item md={3} xs={6}>
        <NormalButton
          variant="big"
          onClick={() => {
            save();
          }}
          className={cls.saveButton}
        >
          Save Draft
        </NormalButton>
      </Grid>
    </Grid>
  );
}

export default ArtifactButtons;
