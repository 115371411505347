import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, Grid, DialogContent, DialogTitle, FormControlLabel, Checkbox } from "@mui/material";
import { BTypography, FormAutocompleteField, NormalButton, colors } from "bild-ui";

const useStyles = makeStyles({
  submitWrapper: { padding: "1rem 0" },
  submit: { padding: "1rem 2rem" },
  destroyTitle: {
    color: colors.white,
    backgroundColor: colors.darkRed,
  },
  destroyRowWarnings: { },
  destroyRow: { textAlign: "center", padding: "0.25rem 1rem"},
  checkboxLabel: { color: colors.darkRed },
  parentTip: { color: colors.darkGray, fontStyle: "italic" }
});

export default function AdminOrganizationDeleteDialog({ open, onClose, organization, allOrganizations, deleteOrganization }) {
  const cls = useStyles();
  const [understandDelete, setUnderstandDelete] = useState(false);
  const [newOrgId, setNewOrgId] = useState(null);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={cls.destroyTitle}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
            <BTypography variant="h5">
              Delete <i>{organization.organizationName}</i> Organization
            </BTypography>
          </Grid>
          <Grid item>
            <NormalButton
              onClick={onClose}
              variant={"micro"}
              color={colors.transparent}
              labelColor={colors.white}
              hoverColor={colors.darkGray}
              hoverLabelColor={colors.white}
            >
              <i className="far fa-times fa-2x" />
            </NormalButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} className={cls.submitWrapper} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} className={cls.destroyRowWarnings}>
            <BTypography variant="body1">
              These will be updated:
              <ul>
                <li>User's Primary Organization</li>
                <li>Enrollment's Organization</li>
              </ul>
              These will be deleted:
              <ul>
                <li>User Roles</li>
                <li>Organization Groups (hubs/clusters/nodes)</li>
                <li>Sharing Groups</li>
                <li>Local Entity and Learners</li>
                <li>Organization-specific Paths and Path Roles</li>
              </ul>
            </BTypography>
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="New Organization for Updates"
              value={newOrgId}
              setValue={newValue => {
                setNewOrgId(newValue);
              }}
              items={allOrganizations.filter(x => x.id !== organization.organizationId)}
            />
          </Grid>
          <Grid item xs={12} className={cls.destroyRow}>
            <FormControlLabel
              control={
                <Checkbox
                  value={understandDelete}
                  onClick={()=>{setUnderstandDelete(!understandDelete)}}
                  disableRipple={true}
                  color="error"
                />
              }
              label="I understand and want to continue."
              className={cls.checkboxLabel}
            />
          </Grid>
          <Grid item xs={12} className={cls.destroyRow}>
            <NormalButton
              onClick={()=>{deleteOrganization(newOrgId)}}
              variant={"primary"}
              color={colors.darkRed}
              labelColor={colors.white}
              hoverColor={colors.darkerRed}
              className={cls.submit}
              disabled={!understandDelete || !newOrgId}
            >
              Permanently Delete
            </NormalButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

}