import React from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { colors, BTypography } from "bild-ui";

const useStyles = makeStyles({
  container: { padding: "1rem", height: "100%" },
  body: { padding: "1rem", background: colors.white },
  logo: { height: "10rem", filter: "invert(1)", paddingRight: "2rem" },
  valid: { color: colors.green },
  invalid: { color: colors.red },
  voided: { color: colors.orange },
  header: { paddingLeft: "0.5rem", fontWeight: "bold" },
  label: { minWidth: "8rem", textAlign: "right" },
  value: { paddingLeft: "1rem", fontWeight: "bold" }
});

function ValidatedUserCertificate({ certificate }) {
  const cls = useStyles();
  const validated = certificate.validated;
  const voided = certificate.voided;

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" className={cls.container}>
      <Grid container item xs={12} className={cls.body} justifyContent="flex-start" alignItems="center">
        <Grid item>
          <img src="/bild-logo.png" className={cls.logo} />
        </Grid>
        <Grid container item xs justifyContent="flex-start" alignItems="flex-start">
          {!validated && !voided && (
            <Grid container item xs={12} className={cls.invalid} alignItems="center">
              <Grid item>
                <i className={`${cls.invalid} fal fa-times-circle fa-2x`} />
              </Grid>
              <Grid item xs>
                <BTypography variant="h6" className={cls.header}>
                  Invalid Certificate
                </BTypography>
              </Grid>
              <Grid item xs={12}>
                <BTypography variant="subtitle1" className={cls.subheader1}>
                  The passed in information does not match any certificate in our system.
                </BTypography>
              </Grid>
              <Grid item xs={12}>
                <BTypography variant="subtitle1" className={cls.subheader2}>
                  If you have any questions, please contact BILD Cloud support.
                </BTypography>
              </Grid>
            </Grid>
          )}
          {!validated && voided && (
            <Grid container item xs={12} className={cls.voided} alignItems="center">
              <Grid item>
                <i className={`${cls.voided} fal fa-ban fa-2x`} />
              </Grid>
              <Grid item xs>
                <BTypography variant="h6" className={cls.header}>
                  Voided Certificate
                </BTypography>
              </Grid>
              <Grid item xs={12}>
                <BTypography variant="subtitle1" className={cls.subheader1}>
                  The passed in information matches a voided certificate in our system.
                </BTypography>
              </Grid>
              <Grid item xs={12}>
                <BTypography variant="subtitle1" className={cls.subheader2}>
                  If you have any questions, please contact BILD Cloud support.
                </BTypography>
              </Grid>
            </Grid>
          )}
          {validated && !voided && (
            <Grid container item xs={12}>
              <Grid container item xs={12} className={cls.valid} alignItems="center">
                <Grid item>
                  <i className={`${cls.valid} fal fa-check-circle fa-2x`} />
                </Grid>
                <Grid item xs>
                  <BTypography variant="h6" className={cls.header}>
                    Valid Certificate
                  </BTypography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item className={cls.label}>
                  <BTypography variant="body1">Certificate: </BTypography>
                </Grid>
                <Grid item xs className={cls.value}>
                  {certificate.certificateName}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item className={cls.label}>
                  <BTypography variant="body1">Recipient: </BTypography>
                </Grid>
                <Grid item xs className={cls.value}>
                  {certificate.userNameOnCertificate}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item className={cls.label}>
                  <BTypography variant="body1">Validation Code: </BTypography>
                </Grid>
                <Grid item xs className={cls.value}>
                  {certificate.validationHash}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item className={cls.label}>
                  <BTypography variant="body1">Issue Date: </BTypography>
                </Grid>
                <Grid item xs className={cls.value}>
                  {certificate.completedOn}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ValidatedUserCertificate;
