import React from "react";
import { makeStyles } from "@mui/styles";

import { Grid } from "@mui/material";
import QuestionField from "./questionField.js";

const useStyles = makeStyles({
  questionsWrapper: {
    margin: "1rem 0"
  }
});

function AfTrainingQuestions({ answers, setAnswers, readOnly }) {
  const classes = useStyles();

  function _setAnswer(answer) {
    let newAnswers = Object.assign([], answers);
    for (let i = 0; i < newAnswers.length; i++) {
      let a = newAnswers[i];
      if (a.description === answer.description) {
        a.response = answer.response;
      }
    }
    setAnswers(newAnswers);
  }

  let questionList = [];
  for (let i = 0; i < answers.length; i++) {
    questionList.push(<QuestionField answer={answers[i]} setAnswer={_setAnswer} readOnly={readOnly} key={i} />);
  }

  return (
    <Grid container className={classes.questionsWrapper}>
      {questionList}
    </Grid>
  );
}

export default AfTrainingQuestions;
