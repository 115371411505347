import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { reviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";

import ReviewOrganization from "./reviewOrganization.js";

// REVIEW ORGANIZATION LOADER
function ReviewOrganizationLoader({}) {
  const { review_type, organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviewType, setReviewType] = useState(review_type);
  const [organizationId, setOrganizationId] = useState(organization_id);
  const [totalReviewCount, setTotalReviewCount] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [enrollments, setEnrollments] = useState(null);

  useEffect(()=>{
    setPageTitle("Review Organization");

    function _setReviewSummary(d) {
      setOrganizationId(organization_id);
      setReviewType(review_type);
      setTotalReviewCount(d.totalReviewCount);
      setOrganizationName(d.organizationName);
      setEnrollments(d.enrollments);
      setLoading(false);
    }

    // Load "review" data
    reviewData.loadUsers(review_type, organization_id, _setReviewSummary, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <ReviewOrganization
        totalReviewCount={totalReviewCount}
        organizationId={organizationId}
        organizationName={organizationName}
        enrollments={enrollments}
        reviewType={reviewType}
      />
    );
  }
}

export default ReviewOrganizationLoader;
