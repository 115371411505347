import React, { useState } from "react";
import { colors, NormalButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Badge, Typography } from "@mui/material";
import SortButton from "presentational/input/sortButton.js";
import List from "presentational/lists/list.js";
import TraineeDetailItem from "./traineeDetailItem.js";

const useStyles = makeStyles({
  buttonsWrapper: { borderTop: `1px solid ${colors.black}`, borderBottom: "1px solid black" },
  date: { textAlign: "center" },
  button: { padding: "0.5rem 2rem !important", "&:hover": { color: colors.black }, borderRadius: "0px !important", lineHeight: "1.5em" },
  active: { background: colors.lightBlue, color: `${colors.white} !important`, borderRadius: "0px !important" },
  badge: { "& span": { right: "-15px", top: "10px" } }
});

function TraineeDetails({ trainee, isAdmin }) {
  const classes = useStyles();
  const [state, setState] = useState({ student: "off", competency: "off", date: "ascending", status: "off" });
  const [tab, setTab] = useState(0);

  function sortTrainee(val) {
    let newState = { student: "off", competency: "off", date: "off", status: "off" };
    newState[val] = "ascending";
    let details = [trainee.revisionCompetencies, trainee.traineeDetails, trainee.traineeDetailsComplete];
    for (let i = 0; i < details.length; i++) {
      let td = details[i];

      if (val === "student")
        td.sort((a, b) => a.reviewCompetency.competencyProof.user.name.localeCompare(b.reviewCompetency.competencyProof.user.name));
      if (val === "competency") td.sort((a, b) => a.reviewCompetency.name.localeCompare(b.reviewCompetency.name));
      if (val === "date")
        td.sort((a, b) => new Date(a.reviewCompetency.competencyProof.updatedAt) - new Date(b.reviewCompetency.competencyProof.updatedAt));
      if (val === "status") td.sort((a, b) => a.coachApproved - b.coachApproved);

      if (state[val] === "ascending") {
        newState[val] = "descending";
        td.reverse();
      }
    }

    setState(newState);
  }

  let listItems = [];
  let listHeadings = [
    <Typography variant="h6">
      Student
      <SortButton
        value={state.student}
        onChange={() => {
          sortTrainee("student");
        }}
      />
    </Typography>,
    <Typography variant="h6">
      Competency
      <SortButton
        value={state.competency}
        onChange={() => {
          sortTrainee("competency");
        }}
      />
    </Typography>,
    <Typography variant="h6" className={classes.date}>
      Date of Coach Review
      <SortButton
        value={state.date}
        onChange={() => {
          sortTrainee("date");
        }}
      />
    </Typography>,
    <Typography variant="h6" className={classes.status}>
      Pass/Revise
      <SortButton
        value={state.status}
        onChange={() => {
          sortTrainee("status");
        }}
      />
    </Typography>,
    ""
  ];

  let list1Items = trainee.revisionCompetencies.map((td, i) => [
    <TraineeDetailItem
      courseName={td.reviewCompetency.name}
      studentName={td.reviewCompetency.competencyProof.user.name}
      updatedAt={td.reviewCompetency.competencyProof.updatedAt}
      competency={td.reviewCompetency}
      status={null}
      key={i}
    />
  ]);
  let list2Items = trainee.traineeDetails.map((td, i) => [
    <TraineeDetailItem
      courseName={td.reviewCompetency.name}
      studentName={td.reviewCompetency.competencyProof.user.name}
      updatedAt={td.reviewCompetency.competencyProof.updatedAt}
      trainingAssessmentId={td.afTrainingAssessmentId}
      status={null}
      key={i}
    />
  ]);
  let list3Items = trainee.traineeDetailsComplete.map((td, i) => [
    <TraineeDetailItem
      courseName={td.reviewCompetency.name}
      studentName={td.reviewCompetency.competencyProof.user.name}
      updatedAt={td.reviewCompetency.competencyProof.updatedAt}
      trainingAssessmentId={td.afTrainingAssessmentId}
      status={td.coachApproved}
      key={i}
    />
  ]);

  listItems = tab === 0 ? list1Items : list2Items;
  if (tab === 2) listItems = list3Items;
  // Only show "pass/revise" column on completed tab
  if (tab === 0 || tab === 1) listHeadings.splice(3, 1);

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {trainee.trainee.name}
        </Typography>
      </Grid>
      {isAdmin && (
        <>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              <b>{trainee.totalCoachFailed}</b> Revised | <b>{trainee.totalCoachPassed}</b> Passed
            </Typography>
          </Grid>
          <Grid item>
            <NormalButton variant="secondary" borderColor={colors.darkerGray} hoverColor={colors.veryLightGray} hoverLabelColor={colors.black} dst="training_modules_review" user_id={trainee.trainee.id}>
              Training Modules Report
            </NormalButton>
          </Grid>
        </>
      )}
      <Grid item xs={12} className={classes.buttonsWrapper}>
        <NormalButton variant="simple" hoverLabelColor={colors.darkerGray} onClick={() => setTab(0)} className={`${classes.button} ${tab === 0 ? classes.active : ""}`}>
          <Badge color="primary" badgeContent={trainee.awaitingRevisionCount} className={classes.badge}>
            Awaiting Trainee Revision
          </Badge>
        </NormalButton>
        <NormalButton variant="simple" hoverLabelColor={colors.darkerGray} onClick={() => setTab(1)} className={`${classes.button} ${tab === 1 ? classes.active : ""}`}>
          Awaiting Coach Review
        </NormalButton>
        <NormalButton variant="simple" hoverLabelColor={colors.darkerGray} onClick={() => setTab(2)} className={`${classes.button} ${tab === 2 ? classes.active : ""}`}>
          Completed Coach Reviews
        </NormalButton>
      </Grid>
      <Grid item xs={12}>
        <List headings={listHeadings} items={listItems} />
      </Grid>
    </Grid>
  );
}

export default TraineeDetails;
