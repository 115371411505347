import React, { useState } from "react";
import { ENUMS } from "bild-data";
import { makeStyles } from "@mui/styles";

import { Button, Container } from "@mui/material";

import SubmitProblemDialog from "./submitProblemDialog.js";
import SubmitReviewDialog from "./submitReviewDialog.js";

import AfTraining from "../afTraining/afTraining.js";

import { colors } from "bild-ui";
import { usePadlock } from "bild-utils";
import { courseData } from "bild-data";
import { toaster } from "presentational/toasts/toasts.js";

const useStyles = makeStyles({
  button: {
    backgroundColor: colors.green,
    width: "calc(100% - 2rem)",
    margin: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    color: "white",
    "&:hover": {
      backgroundColor: colors.green
    }
  }
});

function SubmitFlowButton({
  isStudent,
  canAssess,
  assignsCredits,
  creditsInRange,
  onShowCreditsHint,
  callingUser,
  competency,
  score,
  commentRef,
  credits,
  onCompetencyChange,
  rubric,
  trainingAssessment,
  waitingOnTrainingRole,
  isAfTrainee,
  isAfCoach,
  previousTrainingAssessmentId,
  setPreviousTrainingAssessmentId
}) {
  const classes = useStyles();

  const padlock = usePadlock();
  const [showInvalidReviewDialog, setShowInvalidReviewDialog] = useState(false);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [afTrainingData, setAfTrainingData] = useState({});

  // Ensure val is float.
  credits = credits ? parseFloat(credits) : 0;

  function _submitIntent(afData) {
    if (afData) setAfTrainingData(afData);
    if (isStudent) _submitProof();
    else {
      if (!score || !score.valid) setShowInvalidReviewDialog(true);
      // Credits are not assigned when a competency is reviewed as not passed.
      // However, if reviewPassed, then credits value must be valid.
      else if (assignsCredits && score.reviewPassed && !creditsInRange) {
        if (typeof onShowCreditsHint === "function") onShowCreditsHint();
        setShowReviewDialog(false); // make sure it's closed, do nothing
      } else setShowReviewDialog(true);
    }
  }

  function _submitProof() {
    if (padlock.isLocked()) setShowReviewDialog(false);
    if (!score || !score.valid) setShowInvalidReviewDialog(true);
    else {
      if (!padlock.lock()) return;

      const criteria = competency.rubric.criteria;
      const criteriaData = criteria.map(c => ({ id: c.id, name: c.name, score: { scoreValue: score[c.id] || 0 } }));

      if (canAssess) {
        const data = {
          // TODO: Antioch FE ?? -- use waitingOn role object or somehow decide which role this user is?
          user: callingUser,
          role: competency.proof.waitingOn,
          comment: commentRef.current ? commentRef.current : undefined,
          rubric: {
            id: competency.rubric.id,
            criteria: criteriaData
          }
        };

        if (waitingOnTrainingRole) {
          if (isAfTrainee) {
            data.role.id = ENUMS.ROLES.AF_TRAINEE.ID;
            data.role.description = ENUMS.ROLES.AF_TRAINEE.NAME;
            data.role.prefix = ENUMS.ROLES.AF_TRAINEE.PREFIX;

            data.traineeQuestionsData = afTrainingData.traineeQuestionsData;
          } else if (isAfCoach) {
            data.role.id = ENUMS.ROLES.AF_COACH.ID;
            data.role.description = ENUMS.ROLES.AF_COACH.NAME;
            data.role.prefix = ENUMS.ROLES.AF_COACH.PREFIX;

            data.trainingAssessmentId = afTrainingData.trainingAssessmentId;
            data.coachHasAccepted = afTrainingData.coachHasAccepted;
            data.coachComment = afTrainingData.coachComment;
            data.goodExample = afTrainingData.goodExample;
          }
        }

        // Add creditsGiven if assignCredits and this is a "passing" score
        if (assignsCredits && score.reviewPassed) data.creditsGiven = credits;

        courseData.submitProof(
          competency.proof.id,
          data,
          waitingOnTrainingRole,
          res => {
            setShowReviewDialog(false);
            if (typeof onCompetencyChange === "function") onCompetencyChange(res);
            padlock.unlock();

            toaster.success("We've recorded your assessment.");
          },
          f => padlock.unlock() // Always executed, "unlock" assessment process
        );
      }
    }
  }

  return (
    <>
      {waitingOnTrainingRole && (
        <AfTraining
          submitIntent={_submitIntent}
          rubric={rubric}
          competencyProof={competency.proof}
          trainingAssessment={trainingAssessment}
          isAfTrainee={isAfTrainee}
          isAfCoach={isAfCoach}
          previousTrainingAssessmentId={previousTrainingAssessmentId}
          setPreviousTrainingAssessmentId={setPreviousTrainingAssessmentId}
        />
      )}

      {!waitingOnTrainingRole && (
        <Container maxWidth="sm">
          <Button onClick={_submitIntent} className={classes.button}>
            Submit Assessment
          </Button>
        </Container>
      )}

      <SubmitProblemDialog
        {...score}
        open={showInvalidReviewDialog}
        onClose={() => setShowInvalidReviewDialog(false)}
        inSubmission={padlock.isLocked()}
      />

      <SubmitReviewDialog
        open={showReviewDialog}
        onClose={() => setShowReviewDialog(false)}
        title={score.reviewTitle}
        message={score.reviewMessage}
        onSubmit={_submitProof}
        caption={score.reviewCaption}
        direction={score.reviewPassed ? "validate" : "reassess"}
        icon={score.reviewPassed ? "check" : "sync"}
        inSubmission={padlock.isLocked()}
      />
    </>
  );
}

export default SubmitFlowButton;
