import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  pageError: {
    marginTop: "50px",
    textAlign: "center",
    bildIconCloud: {
      width: "200px"
    }
  }
});

function PageErrorScreen({ error }) {
  const cls = useStyles();
  return (
    <Grid container className={cls.pageError} justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <svg
          className={cls.bildIconCloud}
          style={{ maxWidth: "300px" }}
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          preserveAspectRatio="xMidYMid meet"
        >
          <g transform="translate(0.8,7) scale(0.01,-0.01)" fill="#004384" stroke="none">
            <path
              className="bild-cloud-logo"
              d="M290 469 c-59 -24 -100 -85 -100 -149 0 -26 -4 -40 -13 -40 -7 0 -25 -13 -40 -28 -73 -77 -42 -193 59 -221 51 -14 407 -14 458 0 75 21 120 100 96 165 -13 35 -58 84 -77 84 -8 0 -13 12 -13 31 0 41 -15 66 -50 84 -33 17 -73 19 -99 5 -14 -7 -22 -3 -39 19 -38 52 -120 75 -182 50z m120 -24 c15 -8 39 -29 53 -46 21 -27 27 -30 45 -20 58 31 132 -5 132 -65 0 -29 6 -38 35 -56 39 -22 65 -63 65 -100 0 -36 -37 -88 -75 -104 -49 -20 -431 -20 -480 0 -89 38 -92 170 -5 206 27 11 30 17 30 57 0 24 7 57 15 74 32 61 121 87 185 54z"
            />

            <circle className="face" cx="425" cy="210" r="150" />
            <path className="sadMouth" d="M360,160 Q425,195 490,160" fill="#014f98" stroke="#fff" strokeWidth="15" />
            <rect className="leftEye" x="385" y="230" width="18" height="60" rx="3" ry="3" fill="#fff" />
            <rect className="rightEye" x="445" y="230" width="18" height="60" rx="3" ry="3" fill="#fff" />

            <line className="side-rain-line" x1="200" y1="-135" x2="240" y2="25" stroke="#005bb1" strokeWidth="10" />
            <line className="side-rain-line" x1="320" y1="-65" x2="340" y2="20" stroke="#005bb1" strokeWidth="10" />
            <line className="side-rain-line" x1="390" y1="-200" x2="430" y2="-25" stroke="#005bb1" strokeWidth="10" />
            <line className="side-rain-line" x1="515" y1="-115" x2="545" y2="21" stroke="#005bb1" strokeWidth="10" />
            <line className="side-rain-line" x1="625" y1="-35" x2="640" y2="28" stroke="#005bb1" strokeWidth="10" />
          </g>
        </svg>
        <Typography variant="h5">Whoops! Something went wrong.</Typography>
        <br />
        <Typography variant="body1">{error.displayMessage}</Typography>
      </Grid>
    </Grid>
  );
}

export default PageErrorScreen;
