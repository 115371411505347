import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { reviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import queryString from "query-string";
import { LoadingScreen } from "bild-ui";

import ReviewUser from "./reviewUser.js";

// REVIEW USER LOADER
function ReviewUserLoader({}) {
  const location = useLocation();
  const { review_type, user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviewType, setReviewType] = useState(review_type);
  const [organizationId, setOrganizationId] = useState(null);
  const [totalReviewCount, setTotalReviewCount] = useState(null);
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [competencies, setCompetencies] = useState([]);

  useEffect(()=>{
    setPageTitle("Review User");
    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      setOrganizationId(searchParams.org_id);
    }

    function _setReviewSummary(d) {
      setReviewType(review_type);
      setTotalReviewCount(d.totalReviewCount);
      setUser(d.user);
      setCompetencies(d.reviewCompetencies);
      setOrganization(d.organization);
      setLoading(false);
    }

    reviewData.load(review_type, user_id, _setReviewSummary, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <ReviewUser
        totalReviewCount={totalReviewCount}
        organization={organization}
        orgId={organizationId}
        user={user}
        competencies={competencies}
        reviewType={reviewType}
      />
    );
  }
}

export default ReviewUserLoader;
