import { useMemo } from "react";
import { createEditor } from "slate";
import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import { jsx } from "slate-hyperscript";

import withRichText from "./withRichText.js";
import withValueChangeListener from "./withValueChangeListener";
import withLinks from "./withLinks.js";
// NOTE: withHTML is causing more problems than it are fixing. More research is needed before either can be reinstated
// import withHtml from "./withHtml.js";

export { withRichText, withValueChangeListener, withLinks };
export function useFullPageEditor() {
  // withValueChangeListener needs to be above withRichText
  return useMemo(() => withLinks(withValueChangeListener(withRichText(withHistory(withReact(createEditor())))), jsx), []);
}

export function useFullPageReader() {
  return useMemo(() => withLinks(withReact(createEditor()), jsx), []);
}

export function useCommentEditor() {
  return useMemo(() => withLinks(withRichText(withHistory(withReact(createEditor()))), jsx), []);
}

export function useMessageEditor() {
  return useMemo(() => withLinks(withRichText(withHistory(withReact(createEditor()))), jsx), []);
}
