import { Dialog, DialogContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { libraryData } from "bild-data";
import { BTypography, LoadingScreen, NormalButton, colors } from "bild-ui";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles({
  header: { padding: "0 1rem 0 1rem" },
  videos: {
    padding: "1rem",
    background: colors.white,
  },
  videosList: { maxHeight: "79vh", overflow: "scroll", padding: "1rem 0" },
  linkWrapper: { padding: "0.5rem 1rem" },
  thumbnailLink: { height: "7rem", cursor: "pointer", "&:hover": { opacity: "0.8" } },
  videoWrapper: { },
  video: { width: "100%", height: "75vh" },
  videoMeta: { padding: "0 1rem 1rem 1rem" }
});

export default function VideoLibrary({}) {
  const cls = useStyles();
  const [video, setVideo] = useState(null);
  const [videosData, setVideosData] = useState();
  const [loading, setLoading] = useState(true);
  
  useEffect(()=>{
    if (!videosData) {
      libraryData.getYouTubeVideos(setVideosData);
      setLoading(true);
    } else {
      setLoading(false);
    }
  },[videosData]);

  return (
    <Grid container className={cls.videos}>
      <Grid container item xs={12} className={cls.videosList}>
        {!videosData && (
          <LoadingScreen />
        )}
        {videosData && videosData.items && videosData.items.filter(x => x.id.kind === "youtube#video").map(x => {
          const vd = new Date(x.snippet.publishTime);
          const vid = x.id.videoId;
          const vt = x.snippet.title;
          const vth = x.snippet.thumbnails.high.url;
          return (
            <Grid container item className={cls.linkWrapper} >
              <Grid item>
                <img
                  src={vth}
                  onClick={()=>{setVideo(`https://www.youtube.com/embed/${vid}`)}}
                  className={cls.thumbnailLink}
                />
              </Grid>
              <Grid container item xs className={cls.videoMeta} direction={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <Grid item>
                  <BTypography variant="h6">{vt}</BTypography>
                  <BTypography variant="body2">Published <i>{vd.toDateString()}</i></BTypography>
                </Grid>
                <Grid item>
                  <NormalButton
                    onClick={()=>{setVideo(`https://www.youtube.com/embed/${vid}`)}}
                    variant={"secondary"}
                    color={colors.white}
                    borderColor={colors.bildBlue}
                    hoverColor={colors.bildBlue}
                    labelColor={colors.bildBlue}
                  >
                    View
                  </NormalButton>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Dialog open={video ? true : false} onClose={()=>{setVideo(null)}} fullWidth maxWidth="xl">
        <DialogContent className={cls.videoWrapper}>
          <iframe className={cls.video} src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
