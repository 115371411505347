import { getRequest, putRequest } from "bild-data/core/comm.js";

export function getAllNetworkPartners(success_cb, error_cb) {
  getRequest(
    `/network_partners`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function getAllNetworkPartnerCivilizations(success_cb, error_cb) {
  getRequest(
    `/network_partners/civilizations`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function getNetworkPartnerCivilization(civilization_id, success_cb, error_cb) {
  getRequest(
    `/network_partners/civilizations/${civilization_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function getNetworkPartner(network_partner_id, success_cb, error_cb) {
  getRequest(
    `/network_partners/${network_partner_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function createUpdateNetworkPartner(data, success_cb, error_cb) {
  putRequest(
    `/network_partners`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function createUpdateCivilizationPartner(data, success_cb, error_cb) {
  putRequest(
    `/network_partners/civilizations`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}