import React, { useState, useEffect } from "react";
import { colors, SortableList, Blink, NormalButton, BTypography } from "bild-ui";
import { daysElapsed } from "bild-utils";
import { makeStyles } from "@mui/styles";
import { Grid, Button } from "@mui/material";

const useStyles = makeStyles({
  header: { padding: "1rem" },
  active: { backgroundColor: `${colors.lightGray} !important` },
  tabButton: { backgroundColor: colors.darkGray, borderRadius: "4px 4px 0 0", padding: "0.5rem 1rem" },
  tabs: { padding: "0 1rem" }
});

function FundraisingItems({ items, tasks }) {
  const cls = useStyles();
  const [listItems, setListItems] = useState([]);
  const [completedListItems, setCompletedListItems] = useState([]);
  const [futureListItems, setFutureListItems] = useState([]);
  const [listTasks, setListTasks] = useState([]);
  const [futureListTasks, setFutureListTasks] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const today = Date.parse(new Date().toISOString().slice(0, 10));

    function getButtons(x, showCall) {
      return (
        <Grid container alignItems="center">
          <Grid item xs={showCall ? 6 : 12}>
            <NormalButton
              component={Blink}
              href={`https://crm.bloomerang.co/Constituent/${x.bloomerangConstituentId}/Timeline`}
              variant="simple"
              target={"_blank"}
              color={colors.green}
              labelColor={colors.white}
            >
              Bloomerang
            </NormalButton>
          </Grid>
          {showCall && (
            <Grid item xs={6}>
              <NormalButton
                component={Blink}
                dst="fundraising-items"
                fundraising_item_id={x.id}
                variant="simple"
                color={colors.lightBlue}
                labelColor={colors.white}
              >
                Record Call Attempt
              </NormalButton>
            </Grid>
          )}
        </Grid>
      );
    }

    function getTaskButtons(x) {
      return (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <NormalButton
              component={Blink}
              href={`https://crm.bloomerang.co/Home/Task/Edit/${x.bloomerangTaskId}`}
              variant="simple"
              target={"_blank"}
              color={colors.green}
              labelColor={colors.white}
            >
              Bloomerang
            </NormalButton>
          </Grid>
          <Grid item xs={6}>
            <NormalButton
              component={Blink}
              dst="complete-fundraising-task"
              fundraising_task_id={x.bloomerangTaskId}
              variant="simple"
              color={colors.lightBlue}
              labelColor={colors.white}
            >
              Complete Task
            </NormalButton>
          </Grid>
        </Grid>
      );
    }

    setListItems(
      items
        .filter(x => !x.completed && daysElapsed(today, Date.parse(x.callByDate)) < 7)
        .map(x => [x.bloomerangConstituentName, x.bloomerangConstituentGroupName, x.description, x.callByDate, getButtons(x, true)])
    );
    setListTasks(
      tasks
        .filter(x => x.status === "Active" && daysElapsed(today, Date.parse(x.dueDate)) < 7)
        .map(x => [x.purpose, x.note, x.dueDate, getTaskButtons(x)])
    );
    setFutureListItems(
      items
        .filter(x => !x.completed && daysElapsed(today, Date.parse(x.callByDate)) >= 7)
        .map(x => [x.bloomerangConstituentName, x.bloomerangConstituentGroupName, x.description, x.callByDate, getButtons(x, true)])
    );
    setCompletedListItems(
      items
        .filter(x => x.completed)
        .map(x => [x.bloomerangConstituentName, x.bloomerangConstituentGroupName, x.description, x.callByDate, x.completedAt, getButtons(x, false)])
    );
    setFutureListTasks(
      tasks
        .filter(x => x.status === "Active" && daysElapsed(today, Date.parse(x.dueDate)) >= 7)
        .map(x => [x.purpose, x.note, x.dueDate, getTaskButtons(x)])
    );
  }, [items]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={cls.header}>
        <BTypography variant="h3">Fundraising Interactions</BTypography>
      </Grid>
      <Grid item xs={12} className={cls.tabs}>
        <Button
          className={`${tab === 0 ? cls.active : ""} ${cls.tabButton}`}
          onClick={() => {
            setTab(0);
          }}
        >
          Up Next Calls
        </Button>
        <Button
          className={`${tab === 1 ? cls.active : ""} ${cls.tabButton}`}
          onClick={() => {
            setTab(1);
          }}
        >
          Up Next Tasks
        </Button>
        <Button
          className={`${tab === 2 ? cls.active : ""} ${cls.tabButton}`}
          onClick={() => {
            setTab(2);
          }}
        >
          Completed
        </Button>
        <Button
          className={`${tab === 3 ? cls.active : ""} ${cls.tabButton}`}
          onClick={() => {
            setTab(3);
          }}
        >
          Future Calls
        </Button>
        <Button
          className={`${tab === 4 ? cls.active : ""} ${cls.tabButton}`}
          onClick={() => {
            setTab(4);
          }}
        >
          Future Tasks
        </Button>
      </Grid>
      <Grid item xs={12}>
        {tab === 0 && (
          <SortableList
            headers={["Name", "Group", "Description", "Call By Date", ""]}
            items={listItems}
            emptyMessage={
              <BTypography variant="h5">
                <i className="fad fa-mug-hot" />
                <br />
                There is nothing more to do here!
              </BTypography>
            }
          />
        )}
        {tab === 1 && (
          <SortableList
            headers={["Purpose", "Note", "Due Date", ""]}
            items={listTasks}
            emptyMessage={
              <BTypography variant="h5">
                <i className="fad fa-clipboard-list-check" />
                <br />
                Hooray! No more tasks for now!
              </BTypography>
            }
          />
        )}
        {tab === 2 && (
          <SortableList
            headers={["Name", "Group", "Description", "Call By Date", "Completed On", ""]}
            items={completedListItems}
            emptyMessage={
              <BTypography variant="h5">
                <i className="fad fa-seedling" />
                <br />
                Nothing has been completed, yet!
              </BTypography>
            }
          />
        )}
        {tab === 3 && (
          <SortableList
            headers={["Name", "Group", "Description", "Call By Date", ""]}
            items={futureListItems}
            emptyMessage={
              <BTypography variant="h5">
                <i className="fad fa-telescope" />
                <br />
                Nothing in your future, yet!
              </BTypography>
            }
          />
        )}
        {tab === 4 && (
          <SortableList
            headers={["Purpose", "Note", "Due Date", ""]}
            items={futureListTasks}
            emptyMessage={
              <BTypography variant="h5">
                <i className="fad fa-newspaper" />
                <br />
                No tasks in your future, for now!
              </BTypography>
            }
          />
        )}
      </Grid>
    </Grid>
  );
}

export default FundraisingItems;
