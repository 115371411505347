import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, NormalButton, Blink, LoadingScreen } from "bild-ui";
import { Grid } from "@mui/material";
import OrganizationUsers from "containers/organizations/organizationUsers.js";
import OrganizationAdmissions from "containers/organizations/admissions/organizationAdmissions.js";

const useStyles = makeStyles({
  wrapper: { padding: "1rem 0.5rem !important" },
  body: { padding: "0 1rem" },
  tab: {
    padding: "1rem",
    borderRadius: "0",
    minWidth: "10rem"
  },
  activeTab: {
    background: `${colors.bildBlue} !important`,
    "&:hover": {
      background: `${colors.darkBildBlue} !important`
    }
  }
});

function AntiochOrganizationUsers({ antiochOrg, setAntiochOrg }) {
  const cls = useStyles();
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState("");

  function makeTab(i, name) {
    return (
      <NormalButton
        component={Grid}
        item
        onClick={() => {
          setTab(i);
        }}
        className={`${cls.tab} ${i === tab ? cls.activeTab : ""}`}
        variant="primary"
        color={colors.gray}
        labelColor={colors.white}
        hoverColor={colors.darkGray}
      >
        {name}
      </NormalButton>
    );
  }

  if (!antiochOrg || !antiochOrg.users) return <LoadingScreen />;
  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end" className={`${cls.wrapper} organizations`}>
      <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
        {makeTab(0, "Users")}
        {makeTab(1, "Admissions")}
      </Grid>
      <Grid item xs={12} className={cls.body}>
        {tab === 0 && <OrganizationUsers initialUsers={antiochOrg.users} filterValue={filter} />}
        {tab === 1 && (
          <OrganizationAdmissions
            canCommend={antiochOrg.callingUserCanCommendUsers}
            admissionUsers={antiochOrg.admissionUsers ? antiochOrg.admissionUsers : []}
            availablePrograms={antiochOrg.availablePrograms ? antiochOrg.availablePrograms : []}
            organizationName={antiochOrg.organizationName}
            organizationId={antiochOrg.organizationId + ""}
            filterValue={filter}
            updateUsers={setAntiochOrg}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default AntiochOrganizationUsers;
