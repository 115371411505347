class DropBase {
  constructor() {
    this.setup = this.setup.bind(this);
    this.clear = this.clear.bind(this);
    this.locked = false;
  }

  _preventDefault(e) {
    e.preventDefault();
  }

  setup() {
    if (!this.locked) {
      document.body.addEventListener("dragenter", this._preventDefault, false);
      document.body.addEventListener("dragover", this._preventDefault, false);
      document.body.addEventListener("drop", this._preventDefault, false);
      this.locked = true;
    }
  }

  clear() {
    if (this.locked) {
      document.body.removeEventListener("dragenter", this._preventDefault);
      document.body.removeEventListener("dragover", this._preventDefault);
      document.body.removeEventListener("drop", this._preventDefault);
      this.locked = false;
    }
  }
}
const dropBase = new DropBase();
export default dropBase;

export const renderFolioPayload = (type, id) => `${type} ${id}`;
export const parseFolioPayload = payload => {
  const tokens = payload.split(" ");
  return [tokens[0], Number(tokens[1])];
};
