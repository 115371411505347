import React, { useState } from "react";
import { BTypography, AssessButton, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import EvaluationSection from "./components/evaluationSection";
import EvaluationSectionBar from "./components/evaluationSectionBar";

const useStyles = makeStyles({
  header: { padding: "1rem 0" },
  body: { padding: "1rem 0", background: colors.white },
  footer: { padding: "1rem 0" },
  card: { padding: "0.5rem 0" },
  files: { paddingLeft: "0.5rem" },
  progressItem: {
    backgroundColor: "lightgrey",
    borderRadius: "20px",
    padding: ".25rem",
    paddingLeft: "1rem",
    flexGrow: "1"
  }
});

function Evaluation({ evaluation, submitEvaluationScore, addEvaluationComment }) {
  const classes = useStyles();
  const [sectionIndex, setSectionIndex] = useState(0);

  function handleNextSectionClick() {
    let _sectionIndex = sectionIndex;
    if (_sectionIndex < evaluation.evaluationGroups.length - 1) {
      _sectionIndex++;
      setSectionIndex(_sectionIndex);
    }
  }

  function handlePrevSectionClick() {
    let _sectionIndex = sectionIndex;
    if (!(_sectionIndex - 1 < 0)) {
      _sectionIndex--;
      setSectionIndex(_sectionIndex);
    }
  }

  return (
    <Grid container>
      <EvaluationSectionBar
        sections={evaluation.evaluationGroups}
        selectedSection={evaluation.evaluationGroups[sectionIndex]}
        onClick={setSectionIndex}
      />
      <Grid item xs={12} className={classes.body}>
        <EvaluationSection
          evaluationSection={evaluation.evaluationGroups[sectionIndex]}
          submitEvaluationScore={submitEvaluationScore}
          addEvaluationComment={addEvaluationComment}
        />
      </Grid>
      <Grid container item xs={12} spacing={2} justifyContent="center" className={classes.footer}>
        <Grid item xs={4}>
          <AssessButton
            enabled={!(sectionIndex - 1 < 0)}
            text={
              <React.Fragment>
                <i className="fal fa-chevron-left" style={{ fontWeight: "bold", paddingRight: ".5rem" }} />
                Prev Section
              </React.Fragment>
            }
            tipText={<BTypography variant="subtitle1">No More Sections</BTypography>}
            onClick={() => {
              handlePrevSectionClick();
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AssessButton
            enabled={sectionIndex < evaluation.evaluationGroups.length - 1}
            text={
              <React.Fragment>
                Next Section
                <i className="fal fa-chevron-right" style={{ fontWeight: "bold", paddingLeft: ".5rem" }} />
              </React.Fragment>
            }
            tipText={<BTypography variant="subtitle1">No More Sections</BTypography>}
            onClick={() => {
              handleNextSectionClick();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Evaluation;
