import React, { useCallback } from "react";

import { Editable, Slate } from "slate-react";
import { Grid } from "@mui/material";
import { Leaf, Element, useCommentEditor } from ".";

function AssessmentComment({ value }) {
  const editor = useCommentEditor();
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  if (value) {
    return (
      <Slate editor={editor} value={JSON.parse(value)} selection={null} key={value}>
        <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
          <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly />
        </Grid>
      </Slate>
    );
  } else {
    return <></>;
  }
}

export default AssessmentComment;
