import React from "react";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import PageErrorScreen from "presentational/screens/pageErrorScreen.js";
import PageUnauthorizedScreen from "presentational/screens/pageUnauthorizedScreen.js";

import "./styles.css";

const LoadingScreen = props => {
  const error = props.error;
  if (error) {
    switch (error.errorNumber) {
      case 901:
        return <PageUnauthorizedScreen error={error} />;
        break;
      default:
        return <PageErrorScreen error={error} />;
        break;
    }
  } else {
    return (
      <Grid container className="loading-screen" justifyContent="center" alignItems="center" direction="column">
        <CircularProgress size={100} />
      </Grid>
    );
  }
};

export default LoadingScreen;
