import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, NetworkNodeDetails, HeatBadge, Chip, Blink, UserAvatar, BTypography, PathProgress, DualProgress, Progress } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  node: {
    maxWidth: "5rem",
    minWidth: "5rem",
    display: "inline-block",
    position: "relative",
    top: "1px"
  },
  nodeInner: {
    borderRadius: "0.25rem",
    background: colors.white,
    overflow: "hidden",
    whiteSpace: "normal",
    color: colors.bildGray
  },
  nodeName: {
    color: colors.black,
    fontWeight: "bold",
    /* Key fields allow for multiple lines before ellipsis */
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    padding: "0.25rem 0.5rem",
    minHeight: "2rem",
  },
  badge: {
    position: "absolute",
    top: "-1rem",
    right: "-1rem",
    zIndex: "1000"
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  userPaths: { maxWidth: "5rem" },
  pathTitle: { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
  userPath: {
    textAlign: "center",
    padding: "0.5rem 0",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(1)",
      background: colors.lightGray
    }
  },
  userName: {
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(1)",
      background: colors.lightGray
    }
  },
  level1: { color: colors.darkGreen },
  level2: { color: colors.blue },
  level3: { color: colors.purple },
});

export default function ShepherdingTreeNode({ data, depth, hideChildren, toggleNode, togglePopperData }) {
  const cls = useStyles();
  let oid = data.organizationId ? data.organizationId : data.id;
  let users = data.subEqpUsersWithAnyProgram;
  let prog = data.subEqpUsersWithAnyProgress;
  let nodeClass = "";
  let levelClass = depth === 0 ? cls.level1 : (depth === 1 ? cls.level2 : (depth === 2 ? cls.level3 : ""));
  let mmLevelClass = depth === 0 ? "mmlevel1" : (depth === 1 ? "mmlevel2" : (depth === 2 ? "mmlevel3" : ""));
  let badgeLevel = prog > 500 ? 4 : prog > 100 ? 3 : prog > 25 ? 2 : 1;
  let badgeIcon = users > 500 ? "fire-4" : users > 100 ? "fire-3" : users > 25 ? "fire-2" : users > 0 ? "fire-1" : "";
  let hasChildren = data.subShepherdingTree && data.subShepherdingTree.length > 0;

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center" className={`${cls.node} ${cls[nodeClass + "node"]}`}>
      <Grid item className={cls.badge}>
        {badgeIcon && <HeatBadge level={badgeLevel} icon={badgeIcon} />}
      </Grid>
      <Grid container item className={`${cls.nodeInner} ${cls[nodeClass]}`} justifyContent="center" alignItems="center">
        <Grid
          container
          item
          xs={12}
          component={Blink}
          dst={"manage-user"}
          user_id={data.user.id}
          className={`mm-content ${mmLevelClass} ${cls.nodeName} ${cls[nodeClass + "name"]} ${cls.userName}`}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={levelClass}>
            {data.user.name}
          </Grid>
        </Grid>
        {data.userPaths && data.userPaths.length > 0 && (
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.userPaths}>
            <>
              {data.userPaths.sort((a,b) => (b.totalCompletedCoreSteps + b.totalCompletedSteps + b.totalInProgressCoreSteps + b.totalInProgressSteps) - (a.totalCompletedCoreSteps + a.totalCompletedSteps + a.totalInProgressCoreSteps + a.totalInProgressSteps)).slice(0, 2).map((path,j) => { return (
                <Grid
                  container
                  item
                  xs={12}
                  key={j}
                  justifyContent={"center"}
                  alignItems={"center"}
                  component={Blink}
                  dst={"equip-user-path"}
                  user_path_id={path.id}
                  className={`${cls.userPath}`}
                >
                  <Grid item xs={11}>
                    <BTypography variant={"body1"} className={`${cls.pathTitle}`}>{path.title.split(" ").map(x=>x[0])}</BTypography>
                  </Grid>
                  <Grid container item xs={11} className={`mm-content`}>
                    <PathProgress
                      totalCompletedCoreSteps={path.totalCompletedCoreSteps}
                      totalInProgressCoreSteps={path.totalInProgressCoreSteps}
                      totalCoreSteps={path.totalCoreSteps}
                      totalCompletedSteps={path.totalCompletedSteps}
                      totalInProgressSteps={path.totalInProgressSteps}
                      totalSteps={path.totalSteps}
                      thin
                    />
                  </Grid>
                </Grid>
              ); })}
              {data.userPaths.length > 2 ? `+${data.userPaths.length - 2} Paths` : ""}
            </>
          </Grid>
        )}
        {hasChildren && (
          <Grid
            item
            xs={12}
            onClick={() => {
              toggleNode(oid);
            }}
            className={cls.hasChildren}
          >
            <i className={`fas fa-chevron-${hideChildren ? "up" : "down"}`} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
