import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reportData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import OrganizationStudentProgress from "./organizationStudentProgress.js";

function OrganizationStudentProgressLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [combined, setCombined] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const orgId = organization_id;
    function _setData(d) {
      setPageTitle("Organization Progress");
      if (orgId && orgId === "all") {
        setCombined(true);
      }
      setData(d);
      setLoading(false);
    }

    if (orgId && orgId !== "all") {
      reportData.loadOrganizationProgress(orgId, _setData, e => {
        setError(e.response.data.error);
      });
    } else {
      reportData.loadAllOrganizationProgress(_setData, e => {
        setError(e.response.data.error);
      });
    }
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <OrganizationStudentProgress dateRan={data.dateRan} organizations={data.organizations} combined={combined} />
    </BaseWrapper>
  );
}

export default OrganizationStudentProgressLoader;
