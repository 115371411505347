import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { userPathData } from "bild-data";
import { LoadingScreen } from "bild-ui";
import Learner from "./learner.js";

function LearnerLoader() {
  const { user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [paths, setPaths] = useState([]);

  // Get data needed for the page
  useEffect(() => {
    function _setLearner(data) {
      if (data.user) {
        setPageTitle(`Learner: ${data.user.name}`);
        setUser(data.user);
        setPaths(data.userPaths);
      }
      setLoading(false);
    }
    if (user_id) {
      userPathData.loadLearnerByUserId(user_id, _setLearner, e => {
        setError(e.response.data.error);
      });
    } else {
      userPathData.loadLearner(_setLearner, e => {
        setError(e.response.data.error);
      });
    }
  }, []);

  // Show the loading screen until everything is ready
  if (loading || error) return <LoadingScreen error={error} />;
  return <Learner user={user} paths={paths} />;
}

export default LearnerLoader;
