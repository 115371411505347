import React, { useEffect, useState } from "react";
import { ENUMS } from "bild-data";
import { colors, BTypography, AssessButton, NormalButton, LoadingWrapper } from "bild-ui";
import { AssessmentComment, AssessmentCommentEditor } from "modules";
import { makeStyles } from "@mui/styles";
import { Grid, Select, MenuItem } from "@mui/material";
import CriteriaHeader from "./components/criteriaHeader.js";
import EquipCriteria from "equip/rubric/components/equipCriteria.js";

const useStyles = makeStyles({
  divider: { paddingTop: "0.5rem", marginBottom: "1rem" },
  footer: { minHeight: "5rem" },
  commentWrapper: { padding: "1rem 0.5rem" },
  commentLabel: { fontWeight: "bold" },
  comment: { width: "100%", minHeight: "4rem", padding: "1rem", border: `1px solid ${colors.bildBlue}`, borderRadius: "4px", resize: "vertical" },
  submit: { padding: "2rem", textAlign: "center" }
});

function EquipRubric({ rubric, hasFiles, readOnly, history, onSubmit }) {
  const cls = useStyles();
  const [loading, setLoading] = useState(false);
  const [currentRubric, setCurrentRubric] = useState(null);
  const [score, setScore] = useState([]);
  const [comment, setComment] = useState(null);
  const [commentReady, setCommentReady] = useState(false);
  const [currentScore, setCurrentScore] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [activeCriteria, setActiveCriteria] = useState([]);

  useEffect(() => {
    // handle rubric changes
    if (!readOnly) {
      if (!currentRubric) {
        // Initialize new active, score-able rubric
        setCurrentRubric(rubric);
      } else {
        // Level changed, update currentRubric
        if (currentRubric.completionLevel.id !== rubric.completionLevel.id) {
          _clear();
          setCurrentRubric(rubric);
        }
      }
    }
  }, [rubric]);

  useEffect(() => {
    // Set Scores
    if (history) {
      setScore(history);
    } else {
      if (currentScore) {
        setScore(currentScore);
      } else {
        setScore(
          rubric.criteria.map(c => {
            return { id: c.id, isPassed: null };
          })
        );
      }
    }

    checkReady();
  }, [rubric, history, currentScore]);

  useEffect(() => {
    checkReady();
  }, [currentScore, hasFiles, comment, commentReady]);

  function checkReady() {
    // Only allow submission if every criteria has a score and if the learningItem has at least a file or comment
    if (currentScore && currentScore.every(x => x.isPassed != null) && (hasFiles || commentReady)) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }

  function _submitScore() {
    if (isReady) {
      setLoading(true);
      setIsReady(false);
      // Only send the comment if it is not in the initialized state
      let commentToSave = commentReady ? JSON.stringify(comment) : null;
      onSubmit(currentScore, commentToSave, _clear);
    }
  }

  function _clear() {
    setCurrentScore(null);
    setScore(
      rubric.criteria.map(c => {
        return { id: c.id, isPassed: null };
      })
    );
    setComment(null);
    setCommentReady(false);
    setIsReady(false);
    setLoading(false);
  }

  function updateScore(id, newVal) {
    if (!readOnly) {
      let newScore = score;
      let i = newScore.findIndex(x => x.id === id);
      newScore[i].isPassed = newVal;
      setScore(newScore);
      setCurrentScore(newScore);
      checkReady();
    }
  }

  function getDefaultScore(c) {
    let dscore = null;

    if (history) {
      if (history.assessmentScores.filter(x => x.criteria.id === c.id)[0]) {
        let aScore = history.assessmentScores.filter(x => x.criteria.id === c.id)[0];
        dscore = aScore ? aScore.isPassed : null;
      }
    } else {
      if (currentScore) {
        let cScore = currentScore.filter(x => x.id === c.id)[0];
        dscore = cScore ? cScore.isPassed : null;
      }
    }

    return dscore;
  }

  return (
    <LoadingWrapper loading={loading}>
      <Grid container>
        <Grid container item xs={12} className={cls.header}>
          <CriteriaHeader />
        </Grid>
        <Grid container item xs={12} className={cls.body}>
          {rubric.criteria.map(c => (
            <Grid item xs={12} key={c.id}>
              <EquipCriteria
                id={c.id}
                name={c.name}
                description={c.description}
                updateScore={updateScore}
                readOnly={readOnly}
                defaultScore={getDefaultScore(c)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} className={cls.commentWrapper}>
          <Grid item xs={12}>
            <BTypography className={cls.commentLabel}>Comment:</BTypography>
          </Grid>
          <Grid container item xs={12}>
            {readOnly && (
              <Grid item xs={12} className={cls.comment}>
                <AssessmentComment value={history.comment} />
              </Grid>
            )}
            {!readOnly && <AssessmentCommentEditor className={cls.comment} value={comment} setValue={setComment} setReady={setCommentReady} />}
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.footer}>
          <Grid item xs={10} className={cls.divider} />
          <Grid item xs={12} className={cls.submitWrapper}>
            {!readOnly && (
              <AssessButton
                enabled={isReady}
                text={"Submit"}
                tipText={
                  <BTypography variant="subtitle1">
                    Before you can assess, please score each criteria and either attach a file or leave a comment
                  </BTypography>
                }
                onClick={_submitScore}
                className={cls.submit}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </LoadingWrapper>
  );
}

export default EquipRubric;
