class DiskItem {
  constructor({ id, name, size, createdAt, updatedAt, deletable, type, isFolder, isFile, order, raw }) {
    this.setName = this.setName.bind(this);
    this.linkParent = this.linkParent.bind(this);
    this.addFolder = this.addFolder.bind(this);
    this.addFile = this.addFile.bind(this);
    this.getCrumbs = this.getCrumbs.bind(this);
    this.getPath = this.getPath.bind(this);

    this.id = id;
    this.name = name;
    this.size = size;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletable = deletable;
    this.type = type;
    this.isFolder = isFolder;
    this.isFile = isFile;
    this.order = order;
    this.raw = raw; // the object straight from API

    this.isRoot = isFolder && !raw.parentFolderId ? true : false;
    this.searchableName = name.toLowerCase();
    this.selected = false;
    this.parentId = isFolder ? raw.parentFolderId : raw.folderId;
    // children, only used with folders
    this.parent = null;
    this.folders = [];
    this.files = [];
  }

  setName(name) {
    this.name = name;
    this.searchableName = name.toLowerCase();
  }

  linkParent(parent) {
    this.parent = parent;
    if (parent) {
      if (this.isFolder && parent.addFolder) parent.addFolder(this);
      else if (this.isFile && parent.addFile) parent.addFile(this);
    }
  }
  addFolder(folder) {
    if (this.isFolder) this.folders.push(folder);
  }
  addFile(file) {
    if (this.isFolder) this.files.push(file);
  }

  getCrumbs() {
    const crumbs = [];
    let current = this;
    for (let i = 0; i < 20 && current; i++) {
      // I mean, who would build a 20 deep folder struct, right?...
      crumbs.push(current);
      if (current.isRoot) break;
      current = current.parent;
    }

    return crumbs.reverse();
  }

  getPath() {
    let path = this.getCrumbs()
      .map(c => c.name)
      .join(" / ");
    if (this.isFolder) path += " /";

    return path;
  }
}

class DiskFile extends DiskItem {
  constructor(file, order) {
    super({
      id: file.id,
      name: file.name,
      size: file.fileSizeDisplay,
      createdAt: file.createdAt,
      updatedAt: file.updatedAt,
      deletable: file.deletable,
      type: "file",
      isFolder: false,
      isFile: true,
      order: order,
      raw: file
    });
  }
}

class DiskFolder extends DiskItem {
  constructor(folder, order) {
    super({
      id: folder.id,
      name: folder.name,
      size: "-",
      createdAt: "-",
      updatedAt: "-",
      deletable: true,
      type: "folder",
      isFolder: true,
      isFile: false,
      order: order,
      raw: folder
    });
  }
}

class Disk {
  constructor(rawFolders, rawFiles) {
    this.buildData = this.buildData.bind(this);
    this.search = this.search.bind(this);

    this.buildData(rawFolders, rawFiles);
  }

  buildData(rawFolders, rawFiles) {
    this.folders = [];
    this.files = [];

    this.folderIDLookup = new Map();
    this.folderSearchLookup = new Map();
    this.folderNames = [];

    this.fileIDLookup = new Map();
    this.fileSearchLookup = new Map();
    this.fileNames = [];

    for (let i = 0; i < rawFolders.length; i++) {
      const f = new DiskFolder(rawFolders[i], i);
      this.folders.push(f);

      if (f.isRoot) {
        this.root = f;
        f.setName("My Folio");
      }

      this.folderIDLookup.set(f.id, f);
      if (this.folderSearchLookup.has(f.searchableName)) this.folderSearchLookup.get(f.searchableName).push(f);
      else {
        this.folderNames.push(f.searchableName);
        this.folderSearchLookup.set(f.searchableName, [f]);
      }
    }
    for (let i = 0; i < this.folders.length; i++) {
      const f = this.folders[i];
      if (f.parentId) f.linkParent(this.folderIDLookup.get(f.parentId));
    }

    for (let i = 0; i < rawFiles.length; i++) {
      const f = new DiskFile(rawFiles[i], i);
      this.files.push(f);

      if (f.parentId) f.linkParent(this.folderIDLookup.get(f.parentId));

      this.fileIDLookup.set(f.id, f);
      if (this.fileSearchLookup.has(f.searchableName)) this.fileSearchLookup.get(f.searchableName).push(f);
      else {
        this.fileNames.push(f.searchableName);
        this.fileSearchLookup.set(f.searchableName, [f]);
      }
    }
  }

  search(searchTerm, contextRoot) {
    if (!searchTerm || searchTerm.length < 1) {
      return contextRoot;
    }

    searchTerm = searchTerm.toLowerCase();

    const folderNameMatches = this.folderNames.filter(name => name.includes(searchTerm));
    const folderMatches = [];
    folderNameMatches.forEach(name => this.folderSearchLookup.get(name).forEach(f => folderMatches.push(f)));

    const fileNameMatches = this.fileNames.filter(name => name.includes(searchTerm));
    const fileMatches = [];
    fileNameMatches.forEach(name => this.fileSearchLookup.get(name).forEach(f => fileMatches.push(f)));

    return { folders: folderMatches, files: fileMatches, artificial: true };
  }

  searchCrumb(label) {
    return [...this.root.getCrumbs(), { name: `Showing results for "${label}"`, artificial: true }];
  }
}

export default Disk;

export const sort = (root, order) => {
  const { folders, files } = root;
  let sortedFolders, sortedFiles;
  switch (order) {
    case "ascending":
      sortedFolders = folders.sort((b, a) => a.name.localeCompare(b.name));
      sortedFiles = files.sort((b, a) => a.name.localeCompare(b.name));
      break;
    case "descending":
      sortedFolders = folders.sort((a, b) => a.name.localeCompare(b.name));
      sortedFiles = files.sort((a, b) => a.name.localeCompare(b.name));
      break;
    default:
      sortedFolders = folders.sort((a, b) => a.order - b.order);
      sortedFiles = files.sort((a, b) => a.order - b.order);
      break;
  }
  return { folders: sortedFolders, files: sortedFiles };
};
