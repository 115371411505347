import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  h1: { fontWeight: "400", fontSize: "3.5rem", display: "block" },
  h2: { fontWeight: "400", fontSize: "2.5rem", display: "block" },
  h3: { fontWeight: "400", fontSize: "2rem", lineHeight: "1.13333em", display: "block" },
  h4: { fontWeight: "400", fontSize: "1.75rem", lineHeight: "1.20588em", display: "block" },
  h5: { fontWeight: "400", fontSize: "1.5rem", lineHeight: "1.35417em", display: "block" },
  h6: { fontWeight: "400", fontSize: "1.25rem", lineHeight: "1.16667em", display: "block" },
  subtitle1: { fontWeight: "400", fontSize: "1.25rem", display: "block" },
  subtitle2: { fontWeight: "400", fontSize: "1.2rem", display: "block" },
  body1: { fontSize: "1rem", fontWeight: "400", lineHeight: "1.46429em", display: "block" },
  body2: { fontSize: "0.75rem", display: "block" },
  button: { fontSize: "1.25rem", display: "block" },
  caption: { fontSize: "0.75rem", display: "block" },
  overline: { fontSize: "1rem", display: "block" }
});

const BTypography = React.forwardRef((props, ref) => {
  const cls = useStyles();

  return (
    <span ref={ref} {...props} className={`${props.variant ? cls[props.variant] : cls.body1} ${props.className ? props.className : ""}`}>
      {props.children}
    </span>
  );
});

export default BTypography;
