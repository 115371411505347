import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import { Grid } from "@mui/material";

// Special Animation wrapper to use to insert props into @keyframes, thanks https://stackoverflow.com/a/69629316/4966156
const useAnimationStyles = ({ color, darken }: Props) => {
  const lighterColor = darken ? color : color + "50"; // Add alpha value to hex to make it lighter
  const classes = makeStyles({
    glow: {
      boxShadow: `0 0 1rem 3px transparent, 0 0 0.5rem 2px transparent`,
      animation: `$glower 5s 1 ease` // change to infinite?
    },
    "@keyframes glower": {
      "0%": { boxShadow: `0 0 1rem 3px transparent, 0 0 0.5rem 2px transparent` },
      "50%": { boxShadow: `0 0 1rem 3px ${lighterColor}, 0 0 0.5rem 2px ${lighterColor}` },
      "100%": { boxShadow: `0 0 1rem 3px transparent, 0 0 0.5rem 2px transparent` }
    }
  });

  return classes();
};

function Glower({ glowOn, color, darken, children }) {
  const cls = useAnimationStyles({ color, darken });

  return (
    <Grid container item xs={12} className={glowOn ? cls.glow : ""}>
      {children}
    </Grid>
  );
}

export default Glower;
