import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const useStyles = makeStyles({
  closeButton: {
    color: "#f50057"
  },
  submitButton: {
    color: "#3f51b5"
  }
});

function AdmissionsEditDialog({ userId, appId, defaultName, defaultEmail, defaultDegreeId, availablePrograms, open, onSubmit, onCancel }) {
  const cls = useStyles();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [degreeId, setDegreeId] = useState(-1);

  useEffect(()=>{
    if (defaultName) {
      setName(defaultName);
    }
    if (defaultEmail) {
      setEmail(defaultEmail);
    }
    if (defaultDegreeId) {
      setDegreeId(defaultDegreeId);
    }
  },[defaultName, defaultEmail, defaultDegreeId]);

  function checkDisabled() {
    const validName = name && name.length > 0;
    const rfc2822Address = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
    const validEmail = email && email.length > 0 && email.match(rfc2822Address);

    const hasChanged = name !== defaultName || email !== defaultEmail || (degreeId && defaultDegreeId && Number(degreeId) !== Number(defaultDegreeId));

    if (validName && validEmail && hasChanged && degreeId >= 0) {
      return false;
    } else {
      return true;
    }
  }

  function submit() {
    if (onSubmit && !checkDisabled()) {
      onSubmit(userId, appId, name, email, degreeId);
    }
  }

  const programOptions = [
    <option value={-1} key={-1}>
      Select a degree...
    </option>
  ];
  const degreeList = availablePrograms;
  for (let i = 0; i < degreeList.length; i++) {
    const d = degreeList[i];
    programOptions.push(
      <option value={d.programId} key={d.programId}>
        {d.programClassName}
      </option>
    );
  }

  return (
    <Dialog open={open === true} onClose={() => {onCancel()}} maxWidth="xl" fullWidth>
      <DialogTitle>Edit Application</DialogTitle>
      <DialogContent>
        <Grid container wrap="wrap" spacing={4} justifyContent="space-around">
          <Grid item md={4} xs={12} className="dialog-field">
            <TextField
              label="Name"
              value={name}
              margin="dense"
              variant="outlined"
              onChange={e => {setName(e.target.value)}}
              fullWidth
              xs={12}
            />
          </Grid>
          <Grid item md={4} xs={12} className="dialog-field">
            <TextField
              label="Email"
              value={email}
              margin="dense"
              variant="outlined"
              onChange={e => {setEmail(e.target.value)}}
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12} className="dialog-field">
            <TextField
              select
              label="Degree Program"
              fullWidth
              value={degreeId}
              SelectProps={{
                native: true
              }}
              margin="dense"
              variant="outlined"
              onChange={e => {setDegreeId(e.target.value)}}
            >
              {programOptions}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={cls.closeButton} onClick={onCancel}>
          Cancel
        </Button>
        <Button className={cls.submitButton} disabled={checkDisabled()} onClick={submit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdmissionsEditDialog;
