import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  locked: {
    fontSize: "1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "middle",
    textAlign: "left",
    margin: "0 0.5rem",
    color: "darkgrey"
  },
  unlocked: {
    fontSize: "1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "middle",
    textAlign: "left",
    margin: "0 0.5rem",
    color: "darkgrey"
  },
  invisible: {
    fontSize: "1.33rem",
    width: "1.4rem",
    height: "1.4rem",
    lineHeight: "1.4rem",
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0 0.5rem",
    visibility: "hidden"
  }
});

function LockIcon({ variant }) {
  const classes = useStyles();

  let iconClass;
  switch (variant) {
    case "unlocked":
      iconClass = `fas fa-lock-open ${classes.unlocked}`;
      break;
    case "invisible":
      iconClass = `fas fa-lock-open ${classes.unlocked} ${classes.invisible}`;
      break;
    default:
      iconClass = `fas fa-lock ${classes.locked}`;
      break;
  }

  return <i className={iconClass} />;
}

export default LockIcon;
