import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Grid, Hidden, Typography } from "@mui/material";
import CourseWrapper from "presentational/wrappers/courseWrapper.js";
import TranscriptCompetency from "./transcriptCompetency.js";
import classes from "../classes.js";


// TRANSCRIPT REVIEW COURSE GROUPS
function TranscriptCourse({ statusItemHeaders, showCourseNumber, showHandbook, course, contentType, enrollmentId  }) {
  const groupColor = course.courseGroupColor.webFrontendValue;
  const competencies = course.competencies;

  const header = (
    <Grid container>
      {showCourseNumber && (
        <Grid item xs={12}>
          <Typography variant="h5">{course.courseNumber}</Typography>
        </Grid>
      )}
      {showHandbook && (
        <Grid item xs={12}>
          <Typography variant="body1">Handbook: {course.competencySet.handbookId}</Typography>
        </Grid>
      )}
      <Grid item sm={7} xs={12}>
        <Typography variant="h6">{ReactHtmlParser(course.name)}</Typography>
      </Grid>
      <Hidden smDown>
        <Grid item xs={5} container>
          {statusItemHeaders.map((x, i) => 
            <Grid item className="status-item" xs={4} key={i}>
              <Typography align="center" variant="subtitle1">
                {x}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );

  const competencyRows = competencies.map((x, i) => 
    <div className={classes.transcript.competencyListItem} key={i}>
      <TranscriptCompetency
        competency={x}
        contentType={contentType}
        enrollmentId={enrollmentId}
        courseId={course.id}
      />
    </div>
  );

  return (
    <Grid item xs={12}>
      <CourseWrapper variant="transcript" header={header} color={groupColor}>
        {competencyRows}
      </CourseWrapper>
    </Grid>
  );
}

export default TranscriptCourse;
