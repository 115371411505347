import axios from "axios";
import { localStorage } from "bild-data";
import { logoutUser, deleteOverview } from "bild-data/user";

import * as toast from "presentational/toasts/toasts.js";
import { refreshPage, updateURL } from "bild-utils";

const baseURL = process.env.REACT_APP_BC2_API_URL;

// Manually request new token (for masquerading, etc)
export function refreshToken(redirectURL, masquerade_user_id) {
  // Try to get a new api_token using the user's digest
  let hackedFormParams = "username=" + encodeURI(localStorage.getUsername()) + "&user_token=" + encodeURI(localStorage.getDigestToken());
  // Check if this user is trying to masquerade as another user
  if (masquerade_user_id) {
    localStorage.setMasqueradeInfo({ user_id: masquerade_user_id });
    hackedFormParams += "&masquerade_user_id=" + masquerade_user_id;
  } else {
    localStorage.deleteMasqueradeInfo();
  }

  // Special call to re-get user token and then on success re-call the calling function or send to the login page
  axios({
    method: "POST",
    url: `${baseURL}/auth/api_token/init`,
    data: hackedFormParams,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
    .then(res => {
      // Remove currently loaded user overview AFTER we have a new token, otherwise we run into issues as users start/stop masquerading
      deleteOverview();
      // delete intercom info on any token refresh
      localStorage.deleteIntercomState();
      // Get the new token and save it
      let api_token = res.headers.authorization;
      localStorage.setAPIToken(api_token);
      // After the token is set, call updateURL with the passed in redirectURL
      updateURL(redirectURL);
    })
    .catch(e => {
      // Things have failed, call the escapeFlight routine
      escapeFlight(e);
    });
}

// Before each call, run a pre-flight routine to check that things are basically in order, return should resolve a promise
function preFlight(resolve, reject) {
  if (localStorage.getAPIToken()) {
    // we will try to use the token we have, resolve passed in promise
    resolve();
  } else {
    // Try to get a new api_token using the user's digest
    let hackedFormParams = "username=" + encodeURI(localStorage.getUsername()) + "&user_token=" + encodeURI(localStorage.getDigestToken());
    // Check to see if this is a masquerading user
    let masquerade_info = localStorage.getMasqueradeInfo();
    if (masquerade_info) {
      hackedFormParams += "&masquerade_user_id=" + masquerade_info.user_id;
    }
    // Special call to re-get user token and then on success re-call the calling function or send to the login page
    axios({
      method: "POST",
      url: `${baseURL}/auth/api_token/init`,
      data: hackedFormParams,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        // Get the new token and save it
        let api_token = res.headers.authorization;
        localStorage.setAPIToken(api_token);
        // After the token is set, resolve passed in promise
        resolve();
      })
      .catch(e => {
        // Things have failed, call the escapeFlight routine
        escapeFlight(e);
      });
  }
}

// If a call fails with a 401 unauthorized, try to request a new api_token using their digest and then retry the failed call (only do this once)
function saveFlight(request_type, url, headers, sendData, success_callback, finish_callback, error_callback, bypass_callback) {
  let hackedFormParams = "username=" + encodeURI(localStorage.getUsername()) + "&user_token=" + encodeURI(localStorage.getDigestToken());
  // Check to see if this is a masquerading user
  let masquerade_info = localStorage.getMasqueradeInfo();
  if (masquerade_info) {
    hackedFormParams += "&masquerade_user_id=" + masquerade_info.user_id;
  }
  // Special call to re-get user token and then on success re-call the calling function or send to the login page
  axios({
    method: "POST",
    url: `${baseURL}/auth/api_token/init`,
    data: hackedFormParams,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
    .then(res => {
      // Get the new token and save it
      let api_token = res.headers.authorization;
      localStorage.setAPIToken(api_token);

      if (bypass_callback) {
        bypass_callback();
      } else {
        // retry the calling function passing back the original params
        axiosRequest(request_type, url, headers, sendData, success_callback, finish_callback, error_callback, true, true, false);
      }
    })
    .catch(e => {
      // Things have failed, call the escapeFlight routine
      escapeFlight(e);
    });
}

// Everything else has failed, delete all the user's saved credentials and kick them out to the login page
// TODO Firefox throws some did not complete errors that hit this function incorrectly (e.g. masquerade as a user and you get logged out)
function escapeFlight(error) {
  // For now just logout the user, we may add more logic in the future
  logoutUser();
}

// Perform a passed-in HTTP request against BackEnd API using given URL and returning callback with data
function axiosRequest(request_type, url, headers, sendData, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors) {
  // Create promise for preflight routine
  let preflightPromise = new Promise(function(resolve, reject) {
    if (skipPreFlight) {
      resolve();
    } else {
      preFlight(resolve, reject);
    }
  });

  // Once the preflight routine is completed, make our call
  preflightPromise.then(() => {
    let errorCode;
    // Most calls use the same headers and will always need the most up-to-date api token, but still let calls pass in overriding headers
    // Note: we only want to override existing values, we do not want to lose non-overrided values
    let defaultHeaders = { "Content-Type": "application/json; charset=utf-8", Authorization: localStorage.getAPIToken() };
    let mergedHeaders = Object.assign(defaultHeaders, headers);
    axios({
      method: request_type,
      url: `${baseURL}${url}`,
      data: sendData,
      headers: mergedHeaders,
      crossDomain: true
    })
      .then(res => {
        let data;
        if (request_type === "POST") {
          // For now, POST calls expect the whole response, headers may be needed downstream
          data = res;
        } else {
          // TODO: The backend should not be sending data back in two patterns (res.data.data or res.data), but if it does, handle it
          data = res.data;
          if (data["data"] !== undefined) {
            data = data.data;
          }
        }
        if (typeof success_callback === "function") success_callback(data);
      })
      .catch(e => {
        // Something went wrong with the call, but don't worry, we will try to save it once
        errorCode = e.response ? e.response.status || -1 : -1;
        if (skipRetry !== true && errorCode === 401) {
          console.log("Invalid API Token, now trying to refresh.");
          saveFlight(request_type, url, headers, sendData, success_callback, finish_callback, error_callback);
        } else {
          if (!suppressErrors) {
            if (errorCode === 401 && url === "/auth/user_token/init") {
              // 401 errors fail silently except when returned during token initialization, then let the user know they are entering bad info
              toast.toaster.error("The email address or password you entered is incorrect. Please try again or contact support.");
            } else if (errorCode === 500 && url.substring(0, 21) === "/auth/forgot_password") {
              // Supress Error Message
            } else {
              // Normal errors (non-401) just get displayed to the user
              try {
                toast.toaster.error(e.response.data.error.displayMessage);
              } catch {
                // If the server could not be reached, send a custom toast
                toast.toaster.error("There was an error while contacting the server. Please try again or contact support.");
                // TODO create a custom error message object and return it
              }
            }
          }

          if (typeof error_callback === "function") error_callback(e);
        }
      })
      .then(() => {
        if (skipRetry !== true && errorCode === 401) {
          // do nothing, this call will be re-executed on the saveFlight
        } else {
          // if this call has completed normally (including non-401 errors) call final callback
          if (typeof finish_callback === "function") finish_callback();
        }
      });
  });
}

// Perform a GET request against BackEnd API using given URL and returning callback with data
export function getRequest(url, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors) {
  axiosRequest("GET", url, {}, null, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors);
}

// Perform a PUT request against BackEnd API using given URL/data and returning callback with data
export function putRequest(url, sendData, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors) {
  axiosRequest("PUT", url, {}, JSON.stringify(sendData), success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors);
}

// Perform a POST request against BackEnd API using given URL/data and returning callback with data
export function postRequest(url, sendData, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors) {
  let headers = { "Content-Type": "application/x-www-form-urlencoded" };
  axiosRequest("POST", url, headers, sendData, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors);
}

// Perform a DELETE request against BackEnd API using given URL/data and returning callback with data
export function deleteRequest(url, sendData, success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors) {
  axiosRequest("DELETE", url, {}, JSON.stringify(sendData), success_callback, finish_callback, error_callback, skipRetry, skipPreFlight, suppressErrors);
}

// Perform a special GET request against BackEnd API to get a preview URL for a file
export function previewRequest(fileId, success_cb) {
  let expired = true;
  const url = `${baseURL}/disks/files/${fileId}/preview`;

  // First check to see if the current Token has expired
  try {
    const currentToken = localStorage.getAPIToken().substring(6).split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    expired = Date.now() >= JSON.parse(window.atob(currentToken)).exp * 1000;
  } catch (e) {
    console.log("Token expired, refreshing");
  }

  // If it has, refresh it and return the URL. Otherwise just return the URL
  if (expired) {
    saveFlight(null, null, null, null, null, null, null, () => {
      success_cb(() => { return `${url}?auth_token=${localStorage.getAPIToken()}` });
    });
  } else {
    success_cb(() => { return `${url}?auth_token=${localStorage.getAPIToken()}` });
  }
}

export function previewNetworkPartnerRequest(fileId, success_cb) {
  let expired = true;
  const url = `${baseURL}/disks/network_partner_files/${fileId}/preview`;

  // First check to see if the current Token has expired
  try {
    const currentToken = localStorage.getAPIToken().substring(6).split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    expired = Date.now() >= JSON.parse(window.atob(currentToken)).exp * 1000;
  } catch (e) {
    console.log("Token expired, refreshing");
  }

  // If it has, refresh it and return the URL. Otherwise just return the URL
  if (expired) {
    saveFlight(null, null, null, null, null, null, null, () => {
      success_cb(() => { return `${url}?auth_token=${localStorage.getAPIToken()}` });
    });
  } else {
    success_cb(() => { return `${url}?auth_token=${localStorage.getAPIToken()}` });
  }
}

// Perform a special GET request against BackEnd API to download a file
export function downloadRequest(url, filename, skipSaveFlight, saveFlightFpid) {
  let fname = filename ? filename : "File";
  let fpid = saveFlightFpid ? saveFlightFpid : Date.now();
  if (skipSaveFlight !== true) {
    localStorage.addToFileDownloadStatus(fpid, fname);
  }
  axios({
    method: "GET",
    url: `${baseURL}${url}?auth_token=${localStorage.getAPIToken()}`,
    headers: { "Content-Type": "application/json; charset=utf-8" },
    responseType: "blob"
  })
    .then(res => {
      localStorage.removeFromFileDownloadStatus(fpid);
      let filename = res.headers["x-suggested-filename"];
      // The BackEnd API should send us a custom header "suggested-filename"
      if (filename) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } else {
        throw new Error();
      }
    })
    .catch(e => {
      // Something went wrong with the call, but don't worry, we will try to save it once
      let errorCode = e.response ? e.response.status || -1 : -1;
      if (skipSaveFlight !== true && errorCode === 401) {
        console.log("Invalid API Token, now trying to refresh.");
        saveFlight(null, null, null, null, null, null, null, () => {
          downloadRequest(url, filename, true, fpid);
        });
      } else {
        try {
          localStorage.removeFromFileDownloadStatus(fpid);
          toast.toaster.error(e.response.data.error.displayMessage);
        } catch {
          toast.toaster.error("There was an error while downloading the file. Please try again or contact support.");
        }
      }
    });
}

export function checkContractStatus() {
  // Special call to see if this user needs to sign a contract (don't wait on this, it can fail)
  try {
    // Only make this call if the user is NOT being masqueraded as
    if (!localStorage.getMasqueradeInfo()) {
      // Get date fields
      let contract_checked_at = localStorage.getContractLastChecked();
      let date = new Date();
      let now = date.getTime();
      let time_to_check = false;
      // If the user has successfully checked for a status in the past, see if enough time has passed to check again
      if (contract_checked_at) {
        let delta = now - contract_checked_at;
        // If it's been more than 20 minutes (milliseconds)
        if (delta > 1200000) {
          time_to_check = true;
        }
      } else {
        time_to_check = true;
      }
      // If it's time to check again, make a call to see if the user needs to update their contract
      if (time_to_check) {
        localStorage.setContractLastChecked(now);
        getRequest(
          `/users/contract/status`,
          data => {
            if (data.signingRequired) {
              // If they need to re-sign, set data and reload the page
              localStorage.setContractStatus(data);
              refreshPage();
            } else {
              localStorage.setContractStatus(false);
            }
          },
          null,
          {}
        );
      }
    }
  } catch (e) {
    // do nothing
  }
}
