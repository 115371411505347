import React, { useState } from "react";
import { toaster } from "presentational/toasts/toasts.js";
import { updatePassword } from "bild-data/user";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";

import LoadingScreen from "presentational/screens/loadingScreen.js";
import "./dialogs.css";

function ChangePasswordDialog({ open, closeDialog, classes, ...props }) {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});

  function _handleSubmit() {
    if (_validateForm()) {
      setForm({ ...form, isLoading: true });
      updatePassword(form, _handleSuccess, closeDialog, _handleFailure);
    }
  }

  function _handleSuccess() {
    // Request successfully recieved, let the user know and then close the dialog
    toaster.success("Your password has been successfully changed.");
    setForm({ ...form, isLoading: false });
  }

  function _handleFailure(e) {
    // Things have failed, let the user know to try again or email us directly
    setForm({ ...form, isLoading: false });
    console.log(e);
  }

  function _validateForm() {
    if (form.new_password !== form.confirm_password) {
      setError({ confirm_password: "Passwords must match" });
      return false;
    } else {
      return true;
    }
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      _handleSubmit();
    }
  }

  let content = (
    <form onSubmit={_handleSubmit}>
      <Grid container direction="column" justifyContent="space-around" alignItems="stretch" className="forgot-password">
        <Grid item xs={12} className="form-row">
          <FormControl fullWidth error={error.old_password ? true : false}>
            <FormLabel>Old Password</FormLabel>
            <OutlinedInput
              id="old_password"
              type="password"
              fullWidth
              value={form.old_password}
              onChange={e => {
                _handleChange("old_password", e.target.value);
              }}
              onKeyDown={_handleKeyDown}
              autoFocus
            />
            {error.old_password && <FormHelperText>{error.old_password}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} className="form-row">
          <FormControl fullWidth error={error.new_password ? true : false}>
            <FormLabel>New Password</FormLabel>
            <OutlinedInput
              id="new_password"
              type="password"
              fullWidth
              value={form.new_password}
              onChange={e => {
                _handleChange("new_password", e.target.value);
              }}
              onKeyDown={_handleKeyDown}
            />
            {error.new_password && <FormHelperText>{error.new_password}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} className="form-row">
          <FormControl fullWidth error={error.confirm_password ? true : false}>
            <FormLabel>Confirm New Password</FormLabel>
            <OutlinedInput
              id="confirm_password"
              type="password"
              fullWidth
              value={form.confirm_password}
              onChange={e => {
                _handleChange("confirm_password", e.target.value);
              }}
              onKeyDown={_handleKeyDown}
            />
            {error.confirm_password && <FormHelperText>{error.confirm_password}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} className="form-row">
          <Button variant="contained" className="submit-button" onClick={_handleSubmit} onKeyDown={_handleKeyDown}>
            Change Password
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  if (form.isLoading) {
    content = <LoadingScreen />;
  }

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent className="forgot-password-content">{content}</DialogContent>
    </Dialog>
  );
}

export default ChangePasswordDialog;
