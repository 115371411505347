import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { diskData, managerData, organizationsData, partnersData } from "bild-data";
import { uploadNetworkPartnerFiles, downloadItem } from "bild-data/disk";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import NetworkPartner from "./networkPartner";
import { toaster } from "presentational/toasts/toasts";

export default function NetworkPartnerLoader({}) {
  const { network_partner_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [partner, setPartner] = useState({});
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [allCivilizations, setAllCivilizations] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [disk, setDisk] = useState(null);
  const [previewDocId, setPreviewDocId] = useState(null);
  const [previewDocLoading, setPreviewDocLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);

  const _uploadFiles = (files, destination) => uploadNetworkPartnerFiles(files, destination, network_partner_id, setDisk);

  useEffect(() => {
    setPageTitle("Network Partner");
    function _setAllOrgs(d) {
      setAllOrganizations(d.organizations);
    }

    partnersData.getNetworkPartner(network_partner_id, _setData);
    organizationsData.loadCombinedOrganizations(_setAllOrgs);
    managerData.getManagedUsers(
      x => {
        setAllUsers(x.users);
      },
      e => {
        setError(e.response.data.error);
      }
    );
    diskData.loadNetworkPartnerDisk(network_partner_id, setDisk);
    partnersData.getAllNetworkPartnerCivilizations(
      x => {
        setAllCivilizations(x.civilizations);
      },
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  function _setData(d) {
    setPartner(d);
    setLoading(false);
  }

  function _setDocId(id) {
    setPreviewDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setPreviewDocLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setPreviewDocLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setPreviewDocLoading(false);
        }
      );
    }
  }

  function onPreviewIntent(item) {
    if (item) {
      if (item.raw.inAppLocalId) {
        _setDocId(item.raw.inAppLocalId);
      } else {
        setPreviewFile(item);
      }
    } else {
      setPreviewDocId(null);
      setPreviewFile(null);
    }
  }

  function _createFolder() {
    toaster.error("No folder creation for Network Partners.")
  }

  function updatePartner(d) {
    let sendData = d;
    sendData.id = network_partner_id;
    partnersData.createUpdateNetworkPartner(sendData,
      ()=>{
        partnersData.getNetworkPartner(network_partner_id, _setData);
        toaster.success("Partner Updated!")
      }
    );
  }

  if ((loading) || error) return <LoadingScreen error={error} />;
  return (
    <NetworkPartner
      partner={partner}
      updatePartner={updatePartner}
      allOrganizations={allOrganizations}
      allCivilizations={allCivilizations}
      allUsers={allUsers}
      disk={disk}
      onCreateFolder={_createFolder}
      onUploadFiles={_uploadFiles}
      onDownloadItem={downloadItem}
      onPreviewIntent={onPreviewIntent}
      previewDocId={previewDocId}
      previewDocLoading={previewDocLoading}
      previewFile={previewFile}  
    />
  );
}
