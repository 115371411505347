import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "./styles.css";

const PageUnauthorizedScreen = props => {
  return (
    <Grid container className="page-error" justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h5">
          <i className="fad fa-lock-alt fa-4x" />
        </Typography>
        <br />
        <Typography variant="h5">You are not currently authorized to view this content.</Typography>
      </Grid>
    </Grid>
  );
};

export default PageUnauthorizedScreen;
