import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import EvaluationItem from "./evaluationItem";

function EvaluationSection({ evaluationSection, scores, submitEvaluationScore, addEvaluationComment }) {
  const [showSubgroupIds, setShowSubgroupIds] = useState([]);

  // Frontend way of showing items in subgroups, possibly should change to backend in the future if we keep subgroups
  useEffect(() => {
    let eis = evaluationSection.evaluationItems;
    let subgroups = [];
    let showIds = [];
    for (let i = 0; i < eis.length; i++) {
      let ei = eis[i];
      if (!subgroups.includes(ei.subgroupName)) {
        subgroups.push(ei.subgroupName);
        showIds.push(ei.id);
      }
    }
    setShowSubgroupIds(showIds);
  }, [evaluationSection]);

  return (
    <Grid container>
      {evaluationSection.evaluationItems.map(i => (
        <EvaluationItem
          evaluationGroupId={evaluationSection.id}
          evaluationItem={i}
          scores={scores}
          submitEvaluationScore={submitEvaluationScore}
          addEvaluationComment={addEvaluationComment}
          showSubgroupIds={showSubgroupIds}
          key={i.id}
        />
      ))}
    </Grid>
  );
}

export default EvaluationSection;
