import React from "react";
import { Blink } from "bild-ui";
import { Paper, Grid, Typography } from "@mui/material";
import classes from "../classes.js";

// QUICK ACTIONS DASHBOARD
function QuickActionsDashboard({ enrollments, equip }) {
  const actions = [];

  // Every user will get some default actions
  let action_list = [
    {
      text: "Upload Artifacts",
      url: "/folio",
      icon: "fa-file-alt",
      class: "upload-artifact",
      enabled: "true"
    },
    {
      text: "View Resources",
      url: "/library",
      icon: "fa-book",
      class: "view-resource",
      enabled: "true"
    },
    {
      text: "Implementation Pathway",
      url: "/implement",
      icon: "bfont bf-ip",
      class: "implamentation-pathway",
      enabled: "true"
    }
  ];

  // Add equip link for equip users
  if (equip) {
    action_list.unshift({
      text: "Equip",
      url: "/equip/learner",
      icon: "bfont bf-equip",
      class: "equip-learner",
      enabled: "true"
    });
  }

  // Add portfolio links for enrolled users
  for (let i = 0; i < enrollments.length; i++) {
    const e = enrollments[i];
    action_list.push({
      text: e.programClassAbbreviation + " Portfolio",
      url: "/enrollments/" + e.id,
      icon: "fa-folder-open",
      class: "view-portfolio",
      enabled: e.enabled
    });
  }

  // Create a card for each of this user's actions
  for (let i = 0; i < action_list.length; i++) {
    const a = action_list[i];
    actions.push(
      <Grid item lg={2} md={3} sm={4} xs={6} key={i} className={classes.quickActionGridItem} component={Blink} href={a.enabled ? a.url : "#"}>
        <Paper className={`${classes.quickActionTile}  ${a.class}`}>
          <i className={`${classes.quickActionIcon} fal ${a.icon}`} />
          <Typography variant="h4" className={classes.quickActionTitle}>
            {a.text}
          </Typography>
        </Paper>
      </Grid>
    );
  }

  return (
    <div className={classes.quickActions}>
      <Grid container spacing={2}>
        {actions}
      </Grid>
    </div>
  );
}

export default QuickActionsDashboard;
