import React, { useState, useEffect } from "react";
import { managerData, adminData } from "bild-data";
import { setPageTitle, hideIntercom } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import AllUserCertificates from "./allUserCertificates.js";

function AllUserCertificatesLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("All User Certificates");
      hideIntercom();
      setCertificates(d.certificates);
      setLoading(false);
    }

    managerData.getAllUserCertificates(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  function removeCert(id) {
    function _setCertData(d) {
      // Just set current cert data to every cert but the ones removed
      setCertificates(certificates.filter(x => x.id !== id));
    }
    let data = { certificatesToRemove: [{ id: id }] };
    managerData.updateUserCertificates(data, _setCertData, e => {
      setError(e.response.data.error);
    });
  }

  function processBatchCertificates() {
    adminData.processBatchCertificates(
      d => {
        toaster.success("Certificate Batch ran successfully.");
      },
      e => {
        toaster.error("New batch of Certificates failed to process.");
      }
    );
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return <AllUserCertificates certificates={certificates} processBatchCertificates={processBatchCertificates} removeCert={removeCert} />;
}

export default AllUserCertificatesLoader;
