import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import toaster from "../utils/toaster.js";

function ToastBanner({ id, type, message, timeout, onTimeout }) {
  const [paused, setPaused] = useState(false);
  const [completed, setCompleted] = useState(false);
  let [timerValue, setTimerValue] = useState(100);
  const [delayIncr, setDelayIncr] = useState(timeout ? timeout : 50); // 50 ends up being around 8 seconds

  useEffect(()=>{
    if (completed) {
      onTimeout(id);
    }
  },[completed]);

  // Thanks Dan Abramov => https://overreacted.io/making-setinterval-declarative-with-react-hooks
  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let intervalId = setInterval(tick, delay);
        return () => clearInterval(intervalId);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (!paused && !completed) {
      const _timerValue = timerValue - 1;
      if (_timerValue >= 0) {
        setTimerValue(_timerValue);
      } else {
        setCompleted(true);
      }
    }
  }, delayIncr);

  let icon, pretext;
  switch (type) {
    case toaster.toastTypes.SUCCESS:
      icon = <i className="fal fa-check" />;
      pretext = <b>SUCCESS: </b>;
      break;
    case toaster.toastTypes.ERROR:
      icon = <i className="fal fa-exclamation-circle" />;
      break;
    case toaster.toastTypes.WARNING:
      icon = <i className="fal fa-exclamation-triangle" />;
      break;
    default:
      // info
      icon = <i className="fal fa-info-circle" />;
      break;
  }

  if (completed) {
    return (null);
  } else {
    return (
      <div className={"toast " + type} onMouseEnter={()=>{setPaused(true)}} onMouseLeave={()=>{setPaused(false)}}>
        <div className="toast-body">
          <div className="toast-icon">{icon}</div>
          <div className="toast-message">
            {pretext}
            {ReactHtmlParser(message)}
          </div>
          <div className="toast-dismiss">
            <i
              className="fal fa-times"
              onClick={() => {
                setCompleted(true);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ToastBanner;
