import React from "react";
import { makeStyles } from "@mui/styles";

import FileIcon from "presentational/icons/fileIcon.js";
import LockIcon from "presentational/icons/lockIcon.js";
import MenuButton from "presentational/input/menuButton.js";
import SortButton from "presentational/input/sortButton.js";

import { Grid, Typography, Checkbox, Hidden } from "@mui/material";

const useStyles = makeStyles({
  headerContainer: {
    padding: "0.25rem 1rem",
    display: "flex"
  },
  itemContainer: {
    backgroundColor: "white",
    padding: "0.5rem 1rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": { backgroundColor: "rgba(0,0,0,0.07)" }
  },
  itemContainerActive: {
    backgroundColor: "rgba(0,0,0,0.07)",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  checkbox: {
    padding: 0
  },
  leftIconWrapper: {
    textAlign: "left",
    width: "55px"
  },
  lockIconWrapper: {
    textAlign: "right",
    width: "50px"
  },
  rightIconWrapper: {
    textAlign: "right",
    width: "35px"
  },
  typo: {
    marginBottom: "-4px",
    wordBreak: "break-all"
  },
  date: {
    marginBottom: "-4px",
    wordBreak: "break-word"
  },
  dragged: {
    height: "0px",
    transition: "height 1s"
  }
});

function FolioListItem({
  name,
  size,
  type,
  deletable,
  selected,
  item,
  variant,
  selectable,
  onClick,
  onSelect,
  onOpenMenu,
  sortOrder,
  onChangeSortOrder,
  draggedOver
}) {
  const classes = useStyles();

  const _isFile = (type && type !== "folder") || (item && !item.isFolder);
  const _isLocked = item ? item.deletable === false : deletable === false;
  const _inAppId = item && item.raw ? item.raw.inAppLocalId : "";
  const _name = item ? (item.name === _inAppId ? "~Untitled~" : item.name) : name;
  const _size = _isFile ? (item ? item.size : size) : "-";
  const _createdAt = _isFile ? (item ? item.createdAt : "-") : "-";
  const _updatedAt = _isFile ? (item ? item.updatedAt : "-") : "-";
  const _isSelected = item ? item.selected === true : selected === true;
  draggedOver = draggedOver === "true";

  function _contextMenu(e) {
    const { pageX, pageY } = e;
    onOpenMenu({
      clientWidth: 0,
      clientHeight: 0,
      getBoundingClientRect: function getRect() {
        return { top: pageY, bottom: pageY, left: pageX, right: pageX, width: 0, height: 0 };
      }
    });
    e.preventDefault();
  }

  if (variant === "dialog-list-item") {
    return (
      <Grid container className={selectable && _isSelected ? classes.itemContainerActive : classes.itemContainer} onClick={onClick}>
        <Grid item className={classes.leftIconWrapper}>
          <FileIcon variant={_isFile ? "file" : "folder"} />
        </Grid>
        <Grid item xs>
          <Typography className={classes.typo}>{_name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.date} align="right">
            {_updatedAt}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item className={classes.lockIconWrapper}>
            {_isFile && <LockIcon variant={_isLocked ? "locked" : "unlocked"} />}
          </Grid>
        </Hidden>
        <Grid item className={classes.rightIconWrapper}>
          {selectable && <Checkbox checked={_isSelected} onClick={onSelect} className={classes.checkbox} />}
        </Grid>
      </Grid>
    );
  } else if (variant === "list-header") {
    return (
      <Grid container className={classes.headerContainer}>
        <Grid item className={classes.leftIconWrapper} />
        <Grid item xs>
          <Typography className={classes.typo} variant="subtitle1">
            Name
            <SortButton value={sortOrder} onChange={onChangeSortOrder} />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.date} variant="subtitle1" align="right">
            Updated At
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2}>
            <Typography className={classes.typo} variant="subtitle1" align="right">
              Size
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item className={classes.lockIconWrapper} />
        </Hidden>
        <Grid item className={classes.rightIconWrapper} />
      </Grid>
    );
  } else {
    // File Icon
    let typeIcon = "file"
    const ct = item.raw.contentType ? item.raw.contentType : "";
    if (ct.substring(0, 6) === "image/") {
      typeIcon = "image-polaroid";
    } else if (ct.substring(0, 6) === "video/") {
      typeIcon = "film";
    } else if (ct.substring(0, 6) === "audio/") {
      typeIcon = "waveform";
    } else if (ct.substring(0, 5) === "bild/") {
      typeIcon = "pencil";
    } else if (ct === "application/msword" || ct === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      typeIcon = "file-word"
    } else if (ct === "application/vnd.ms-excel" || ct === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      typeIcon = "file-excel"
    } else if (ct === "application/vnd.ms-powerpoint" || ct === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      typeIcon = "file-powerpoint"
    } else if (ct === "application/pdf") {
      typeIcon = "file-pdf"
    }

    return (
      <Grid container className={draggedOver ? classes.itemContainerActive : classes.itemContainer} onClick={onClick} onContextMenu={_contextMenu}>
        <Grid item className={classes.leftIconWrapper}>
          <FileIcon variant={_isFile ? typeIcon : draggedOver ? "folder-open" : "folder"} />
        </Grid>
        <Grid item xs>
          <Typography className={classes.typo}>{_name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.date} align="right">
            {_updatedAt}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2}>
            <Typography variant="body2" className={classes.typo} align="right">
              {_size}
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item className={classes.lockIconWrapper}>
            {_isFile && <LockIcon variant={_isLocked ? "locked" : "unlocked"} />}
          </Grid>
        </Hidden>
        <Grid item className={classes.rightIconWrapper}>
          <MenuButton onClick={onOpenMenu} />
        </Grid>
      </Grid>
    );
  }
}

export default FolioListItem;
