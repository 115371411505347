import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormAutocompleteField, NormalButton, BTypography, LoadingScreen } from "bild-ui";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { ENUMS } from "bild-data";
import { getEnumSectionAsArray } from "bild-utils";

const useStyles = makeStyles({
  labelWrapper: { paddingTop: "1rem", paddingRight: "1rem" },
  fieldWrapper: { paddingTop: "1rem", paddingLeft: "1rem" },
  badgeContent: { padding: "0.25rem", color: "#9d9d9d" },
  dialog: { padding: "1rem" }
});

export default function UpdateLocalEntityDialog({ open, onClose, organization, allOrganizations, onSubmit }) {
  const cls = useStyles();
  const [form, setForm] = useState(null);
  const [originalForm, setOriginalForm] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    const le = organization.localEntity;
    const defaultForm = {
      localEntityTypeId: le && le.localEntityTypeId ? le.localEntityTypeId : null,
      networkLevel1Organization: { organizationId: le && le.networkLevel1Organization ? le.networkLevel1Organization.organizationId : null },
      networkLevel2Organization: { organizationId: le && le.networkLevel2Organization ? le.networkLevel2Organization.organizationId : null },
      networkLevel3Organization: { organizationId: le && le.networkLevel3Organization ? le.networkLevel3Organization.organizationId : null },
      prtOrganization: { organizationId: le && le.prtOrganization ? le.prtOrganization.organizationId : null },
      grtOrganization: { organizationId: le && le.grtOrganization ? le.grtOrganization.organizationId : null },
    };
    setForm(defaultForm);
    setOriginalForm(defaultForm);
  }, [organization, refreshCount]);

  function _validate() {
    if (form && originalForm && JSON.stringify(form) !== JSON.stringify(originalForm)) {
      return true;
    } else {
      return false;
    }
  }

  function _onSubmit() {
    if (_validate()) {
      const sendData = {
        "organizationId": organization.organizationId,
      };
      sendData["localEntity"] = form;
      onSubmit(sendData);
      _onClose();
    }
  }

  function _onClose() {
    setRefreshCount(refreshCount + 1);
    onClose();
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: { "organizationId": value }
    });
  }
  if (form === null || !allOrganizations || allOrganizations.length < 1) {
    return (
      <Dialog open={open} onClose={_onClose} maxWidth="md" fullWidth>
        <DialogContent className={cls.dialog}>
          <LoadingScreen />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog open={open} onClose={_onClose} maxWidth="md" fullWidth>
      <DialogContent className={cls.dialog}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="h5">{organization.organizationName}: Local Entity</Typography>
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="Organization Entity Type"
              value={form.localEntityTypeId}
              setValue={newValue => {
                setForm({
                  ...form,
                  "localEntityTypeId": newValue
                });
              }}
              items={getEnumSectionAsArray(ENUMS.LOCAL_ENTITY_TYPES).map(x => {
                return { id: x.ID, name: x.NAME };
              })}
              required={true}
            />
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="GRT Parent"
              value={form.grtOrganization.organizationId}
              setValue={newValue => {
                _handleChange("grtOrganization", newValue);
              }}
              items={allOrganizations.filter(x => x.localEntityTypeId === ENUMS.LOCAL_ENTITY_TYPES.GRT.ID)}
            />
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="PRT Parent"
              value={form.prtOrganization.organizationId}
              setValue={newValue => {
                _handleChange("prtOrganization", newValue);
              }}
              items={allOrganizations.filter(x => x.localEntityTypeId === ENUMS.LOCAL_ENTITY_TYPES.PRT.ID)}
            />
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="Network Level 3 Parent"
              value={form.networkLevel3Organization.organizationId}
              setValue={newValue => {
                _handleChange("networkLevel3Organization", newValue);
              }}
              items={allOrganizations.filter(x => x.localEntityTypeId === ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_3.ID)}
            />
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="Network Level 2 Parent"
              value={form.networkLevel2Organization.organizationId}
              setValue={newValue => {
                _handleChange("networkLevel2Organization", newValue);
              }}
              items={allOrganizations.filter(x => x.localEntityTypeId === ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_2.ID)}
            />
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="Network Level 1 Parent"
              value={form.networkLevel1Organization.organizationId}
              setValue={newValue => {
                _handleChange("networkLevel1Organization", newValue);
              }}
              items={allOrganizations.filter(x => x.localEntityTypeId === ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_1.ID)}
            />
          </Grid>
          <Grid item xs={12}>
            <BTypography variant="subtitle1">WARNING: THIS WILL CHANGE ALL CHILDREN'S UPSTREAM RELATIONSHIPS</BTypography>
          </Grid>
          <Grid item className={cls.fieldWrapper}>
            <NormalButton
              className="default-submit-button"
              onClick={_onSubmit}
              disabled={!_validate()}
            >
              Change
            </NormalButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
