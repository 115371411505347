import React from "react";
import { Blink } from "bild-ui";
import { Grid, Typography, List, ListItem, Badge } from "@mui/material";

// USER
function User({ orgId, enrollments, reviewType }) {

  function getEnrollments() {
    let students = [];

    students.push(
      <ListItem key="00" className="review-header">
        <Grid container>
          <Grid item md={6} xs={6}>
            <Typography variant="subtitle1">Students</Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="subtitle1">Assessments</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );

    for (let i = 0; i < enrollments.length; i++) {
      const e = enrollments[i];
      students.push(
        <Grid
          container
          item
          xs={12}
          component={Blink}
          dst={"review"}
          review_type={reviewType}
          user_id={e.userId}
          return_org_id={orgId}
          key={i}
        >
          <ListItem className="review-item link">
            <Grid container>
              <Grid item md={6} xs={6}>
                <Typography variant="body1">{e.userName}</Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Badge color="secondary" className="review-badge" badgeContent={e.awaitingReviewCount} overlap="rectangular">
                  <span />
                </Badge>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
      );
    }

    if (enrollments.length < 1) {
      students.push(
        <ListItem key="1" className="review-empty">
          <Grid container>
            <Grid item md={12} xs={12}>
              <Typography variant="body1">Nothing left to review</Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    return <List disablePadding>{students}</List>;
  }

  return <div className="review-list">{getEnrollments()}</div>;
}

export default User;
