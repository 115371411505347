import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BTypography, PathProgress, UserAvatar, colors, Expander, ZoomButtons, PopTip, NormalButton, Blink } from "bild-ui";
import ShepherdingTreeNode from "./shepherdingTreeNode";
import Minimap from 'react-minimap';
import 'react-minimap/dist/react-minimap.css';
import './react-minimap-overrides.css';
import { useWidth } from "bild-utils";

const useStyles = makeStyles({
  // Huge thanks to https://codepen.io/philippkuehn/pen/QbrOaN
  header: {
    position: "sticky",
    top: "0",
    zIndex: "101",
    background: colors.white,
    padding: "0.25rem 0 1rem 0"
  },
  table: { width: "15rem", margin: "1rem" },
  tree: {
    background: colors.white,
    maxHeight: "60vh",
    padding: "0 1rem",
    transition: "all 1s ease",
    "& ul": {
      position: "relative",
      padding: "2vh 0",
      top: "1px",
      whiteSpace: "nowrap",
      textAlign: "center"
    },
    "& ul::after": { content: "''", display: "table", clear: "both" },
    "& ul ul::before": {
      content: "''",
      position: "absolute",
      top: "0",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`,
      width: "0",
      height: "2vh"
    }
    // HOVER CODE
    // "& li span:hover, & li span:hover + ul li span": {
    //   background: colors.bildBlue,
    //   color: colors.white,
    //   border: `1px solid ${colors.lightBlue}`
    // },
    // "& li span:hover + ul li::after, & li span:hover + ul li::before, & li span:hover + ul::before, & li span:hover + ul ul::before": {
    //   borderColor: colors.lightBlue
    // }
  },

  limb: {
    display: "table-cell",
    verticalAlign: "top",
    textAlign: "center",
    listStyleType: "none",
    position: "relative",
    padding: "2vh 0.5rem 0 0.5rem",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: "0",
      right: "50%",
      borderTop: `1px solid ${colors.gray}`,
      width: "50%",
      height: "2vh"
    },
    "&::after": {
      right: "auto",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`
    },
    "&:only-child::after, &:only-child::before": {
      display: "none"
    },
    "&:only-child": { paddingTop: "0" },
    "&:first-child::before, &:last-child::after": {
      border: "0 none"
    },
    "&:last-child::before": {
      borderRight: `1px solid ${colors.gray}`,
      borderRadius: "0 5px 0 0"
    },
    "&:first-child::after": { borderRadius: "5px 0 0 0" }
  },
  lastLimb: {
    "&::before": {
      borderRadius: "0 5px 0 0 !important",
      borderLeft: `none !important`
    },
    "&::after": {
      borderTop: "none !important",
      borderRadius: "0 5px 0 0 !important",
      left: "auto !important",
      right: "50% !important",
      borderLeft: "0 !important",
      borderRight: "1px solid #bdbdbd !important"
    }
  },
  subLimb: {
    display: "table-cell",
    verticalAlign: "top",
    textAlign: "center",
    listStyleType: "none",
    position: "relative",
    padding: "1vh 0.5rem 0 0.5rem",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: "0",
      right: "50%",
      borderTop: `1px solid ${colors.gray}`,
      borderRight: "0",
      borderBottom: "0",
      borderLeft: "0",
      borderStyle: "dashed",
      width: "50%",
      height: "2vh",
    },
    "&::after": {
      right: "auto",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`,
    },
    "&:only-child::after, &:only-child::before": {
      display: "none"
    },
    "&:only-child": { paddingTop: "0" },
    "&:first-child::before, &:last-child::after, &:nth-child(7)::before, &:nth-child(6n-5)::before": {
      border: "0 none"
    },
    "&:last-child::before": {
      borderRight: `1px solid ${colors.gray}`,
      borderRadius: "0 5px 0 0"
    },
    "&:first-child::after, &:nth-child(7)::after, &:nth-child(6n-5)::after": { borderRadius: "5px 0 0 0" }
  },
  subLimbBranch: {
  },
  shrinkNodeInner: {
    border: `2px solid ${colors.bildBlue}`,
    padding: "0.5rem 0",
    background: colors.white,
    color: colors.bildBlue,
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  shrinkNodeWrapper: {
    padding: "1rem 0.5rem",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  shrinkNode: {
    maxWidth: "8rem"
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  // Scale Levels
  trunk: { transformOrigin: "top left", transform: "scale(1)" },
  scale0: { transform: "scale(1)" },
  scale1: { transform: "scale(0.80)" },
  scale2: { transform: "scale(0.60)" },
  scale3: { transform: "scale(0.50)" },
  scale4: { transform: "scale(0.40)" },
  scale5: { transform: "scale(0.35)" },
  popper: {
    maxWidth: "27rem",
    background: colors.white,
    borderRadius: "0.5rem",
    padding: "0rem"
  },
  emptyMessage: { padding: "2rem" }
});

export default function ShepherdingTree({ user, data, scalable, defaultScale }) {
  const cls = useStyles();
  const w = useWidth();
  const mmWidth = w === "xs" ? 300 : w === "sm" ? 550 : w === "md" ? 800 : w === "lg" ? 1200 : 1600;
  const [showNodes, setShowNodes] = useState([]);
  const [showShrunk, setShowShrunk] = useState([]);
  const [scaleLevel, setScaleLevel] = useState(defaultScale ? defaultScale : 0);

  function _toggleNode(id) {
    if (showNodes.some(x => x === id)) {
      setShowNodes(showNodes.filter(x => x !== id));
    } else {
      setShowNodes([...showNodes, id]);
    }
  }

  function _toggleShrunkNode(id) {
    if (showShrunk.some(x => x === id)) {
      setShowShrunk(showShrunk.filter(x => x !== id));
    } else {
      setShowShrunk([...showShrunk, id]);
    }
  }

  function getTree(parent, nodes, depth) {
    let limbs = [];

    let pid = parent.user ? parent.user.id : parent.id;
    for (let i = 0; i < nodes.length; i++) {
      let n = nodes[i];
      let oid = n.user.id;
      let showAllChildren = showShrunk.some(x => x === pid) ? true : false;
      let hideChildren = showNodes.some(x => x === oid) ? true : false;
      let hasChildren = n.subShepherdingTrees && n.subShepherdingTrees.length > 0;
      let isSubLimb = depth > 0 && i >= 5 && !hasChildren;
      let islastLimb = false;

      // Decide if this is a main limb or a subLimb
      if (depth === 0 || (depth < 2 && i < 5) || hasChildren || showAllChildren || (depth > 1 && i < 5)) {
        if (isSubLimb && i % 5 === 0) {
          limbs.push(<div className={cls.subLimbBranch} key={oid + "subLimb"}>&nbsp;</div>);
        }
        if (
          (depth > 0 && nodes.length >= 5 && i === 4 && !hasChildren) ||
          (depth > 0 && i > 1 && (i + 1) % 5 === 0 && !hasChildren) ||
          (nodes.length - 1 === i && !hasChildren)
        ) {
          islastLimb = true;
        }
        limbs.push(
          <li
            key={i}
            className={`${islastLimb ? cls.lastLimb : ""} ${isSubLimb ? cls.subLimb : cls.limb}`}
          >
            <ShepherdingTreeNode data={n} depth={depth} hideChildren={hideChildren} toggleNode={_toggleNode} />
            {hasChildren && (
              <Expander showFirst={true} expandIn={!hideChildren}>
                <ul>{getTree(n, n.subShepherdingTrees, depth + 1)}</ul>
              </Expander>
            )}
          </li>
        );
      }
      // If this has subLimbs, add a show/hide button
      if ((!showAllChildren && isSubLimb && i === 6) || (showAllChildren && i === nodes.length - 1)) {
        limbs.push(
          <div key={"extra"} className={`${cls.shrinkNodeWrapper}`}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={`${cls.shrinkNode}`}
              onClick={() => {
                _toggleShrunkNode(pid);
              }}
            >
              <Grid container item className={`${cls.shrinkNodeInner}`} justifyContent="center" alignItems="center">
                <Grid item xs={12} className={"mm-content mm-expander"}>
                  <i className={`fas fa-chevron-${showAllChildren ? "up" : "down"}`}></i>
                  &nbsp;
                  {showAllChildren ? `Hide` : `Show`} ({nodes.length - 5})
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
    return limbs;
  }

  function miniMapChild({ width, height, left, top, node }) {
    let mmBgClr = colors.darkGray;
    let mmBorderClr = colors.transparent;
    node.classList.forEach(className => {
      if (className==="mmlevel1")
      {
        mmBgClr = colors.darkGreen
        return false
      } else if (className==="mmlevel2")
      {
        mmBgClr = colors.darkBlue
        return false
      } else if (className==="mmlevel3")
      {
        mmBgClr = colors.purple
        return false
      } else if (className==="mm-expander")
      {
        mmBorderClr = colors.lightBlue
        return false
      }
    })
    return (
      <div
        style={{
          position: 'absolute',
          width,
          height,
          left,
          top,
          backgroundColor: mmBgClr,
          border: `1px solid ${mmBorderClr}`
        }}
      />
    )
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.header}>
        <Grid container item justifyContent={"center"} alignItems={"center"}>
          <Grid item>
            <NormalButton component={Blink} dst="manage-user" user_id={user.id}>
              <UserAvatar size={35} src={user.avatarURL} name={user.name} badgeSrc={user.badgeURL} nameVariant="subtitle1" />
            </NormalButton>
          </Grid>
          <Grid item>
            <BTypography variant="subtitle1">'s &nbsp; Shepherding Tree &nbsp;</BTypography>
          </Grid>
          <Grid item>
            <PopTip
              text={
                <table className={cls.table}>
                  <tbody>
                    <tr><td>Users:</td><td>{(data.level1ShepherdingTotal + data.level2ShepherdingTotal + data.level3ShepherdingTotal)}</td></tr>
                    <tr><td>Level 1:</td><td>{data.level1ShepherdingTotal.toLocaleString()}</td></tr>
                    <tr><td>Level 2:</td><td>{data.level2ShepherdingTotal.toLocaleString()}</td></tr>
                    <tr><td>Level 3:</td><td>{data.level3ShepherdingTotal.toLocaleString()}</td></tr>
                  </tbody>
                </table>
              }
            >
              <i className="fad fa-abacus" />
            </PopTip>
          </Grid>
        </Grid>
      </Grid>
      {data.subShepherdingTrees.length < 1 && (
        <Grid item className={cls.emptyMessage}>
          <BTypography variant="body1">No Users under this Shepherd.</BTypography>
        </Grid>
      )}
      {data.subShepherdingTrees.length > 0 && (
        <>
          <Minimap
            selector=".mm-content"
            width={mmWidth}
            keepAspectRatio
            childComponent={miniMapChild}
          >
            <Grid item className={cls.tree}>
              <ul className={`${cls.trunk} ${cls[`scale${scaleLevel}`]}`}>
                {getTree(data, data.subShepherdingTrees, 0)}
              </ul>
            </Grid>
          </Minimap>
          <Grid container item xs={12}>
            {scalable && (
              <Grid container item xs justifyContent="flex-end">
                <Grid item className={cls.subItem}>
                  <ZoomButtons scale={scaleLevel} setScale={setScaleLevel} max={5} min={0} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}