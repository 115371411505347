import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Bbutton from "presentational/input/bbutton.js";

const useStyles = makeStyles({
  wrapper: { textAlign: "center" },

  button: {
    background: colors.white,
    color: colors.white,
    fontWeight: "bold",
    height: "2.5rem",
    width: "2.5rem",
    minWidth: "2.5rem !important",
    borderRadius: "50%",
    border: `1px solid ${colors.gray}`,
    "&:hover": {
      background: colors.white
    }
  },
  active: props => ({
    "&:hover": {
      background: props.color
    }
  }),
  clicked: props => ({
    background: props.color
  })
});

function CircleButton({ icon, color, clicked, onClick, enabled, children }) {
  const classes = useStyles({ color });

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.wrapper}>
      <Grid item xs={12}>
        <Bbutton
          className={`${classes.button} ${clicked ? classes.clicked : ""} ${enabled ? classes.active : ""}`}
          onClick={onClick}
          enabled={enabled ? "true" : "false"}
        >
          {children}
          {!children && <i className={`fas fa-${icon}`} />}
        </Bbutton>
      </Grid>
    </Grid>
  );
}

export default CircleButton;
