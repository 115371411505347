import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchBar from "presentational/input/searchBar";
import StaffSearchUsers from "./components/staffSearchUsers";
import StaffSearchOrganizations from "./components/staffSearchOrganizations";
import { BTypography, LoadingScreen, PopTip, colors } from "bild-ui";
import StaffSearchBooks from "./components/staffSearchBooks";
import StaffSearchUserPaths from "./components/staffSearchUserPaths";
import StaffSearchGroups from "./components/staffSearchGroups";

const useStyles = makeStyles({
  wrapper: {},
  header: {
    background: colors.white,
    position: "sticky",
    top: "0",
    zIndex: "20",
    padding: "1rem 1rem 1rem 1rem"
  },
  totalCount: { padding: "1rem 1rem 0 1rem", fontStyle: "italic" },
  body: {
    background: colors.white,
  },
  sectionWrapper: { padding: "0.25rem" },
  section: {
    border: `0.1rem solid ${colors.darkBildBlue}`,
  }
});

export default function StaffSearch({ searchValue, totalCount, users, orgs, groups, userPaths, books, onSearch, refreshing }) {
  const cls = useStyles();

  let missingSections = [];
  if (!users || users.length < 1) { missingSections.push("Users") }
  if (!orgs || orgs.length < 1) { missingSections.push("Organizations") }
  if (!groups || groups.length < 1) { missingSections.push("Groups") }
  if (!userPaths || userPaths.length < 1) { missingSections.push("Programs") }
  if (!books || books.length < 1) { missingSections.push("Books") }

  let missingSectionText = "";
  for (let i=0; i<missingSections.length; i++) {
    if (i > 0 && missingSections.length > 2) {
      missingSectionText += ", ";
    }
    if (i > 0 && i===missingSections.length - 1) {
      missingSectionText += " or "
    }
    missingSectionText += missingSections[i];
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.header}>
        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
          <Grid item sm={6} xs={12}>
            <SearchBar onSearch={onSearch} defaultValue={searchValue} fullWidth autoFocus={true}/>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
          <Grid item className={cls.totalCount}>
            <BTypography variant="body1">
              <b>{searchValue ? totalCount ? `${totalCount} ` : "" : ""}</b> Results Found.
              {totalCount > 90 && (
                <PopTip
                  text="More results may be available if you refine your search criteria."
                  arrow
                  placement="top"
                >
                  &nbsp; <i className="far fa-info-circle" />
                </PopTip>
              )}
              {searchValue && missingSections.length > 0 && (
                <> &nbsp; <i>No <b>{missingSectionText}</b> match the search.</i></>
              )}
            </BTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.bodyWrapper}>
        <Grid container item xs={12} className={cls.body}>
          {refreshing ? (<LoadingScreen />) : (
            <Grid container item xs={12}>
              {users && users.length > 0 && (
                <Grid container item lg md sm={4} xs={12} className={cls.sectionWrapper}>
                  <Grid item xs={12} className={cls.section}>
                    <StaffSearchUsers users={users} count={users.length} />
                  </Grid>
                </Grid>
              )}
              {orgs && orgs.length > 0 && (
                <Grid container item lg md sm={4} xs={12} className={cls.sectionWrapper}>
                  <Grid item xs={12} className={cls.section}>
                    <StaffSearchOrganizations orgs={orgs} count={orgs.length} />
                  </Grid>
                </Grid>
              )}
              {groups && groups.length > 0 && (
                <Grid container item lg md sm={4} xs={12} className={cls.sectionWrapper}>
                  <Grid item xs={12} className={cls.section}>
                    <StaffSearchGroups groups={groups} count={groups.length} />
                  </Grid>
                </Grid>
              )}
              {userPaths && userPaths.length > 0 && (
                <Grid container item lg md sm={4} xs={12} className={cls.sectionWrapper}>
                  <Grid item xs={12} className={cls.section}>
                    <StaffSearchUserPaths userPaths={userPaths} count={userPaths.length} />
                  </Grid>
                </Grid>
              )}
              {books && books.length > 0 && (
                <Grid container item lg md sm={4} xs={12} className={cls.sectionWrapper}>
                  <Grid item xs={12} className={cls.section}>
                    <StaffSearchBooks books={books} count={books.length} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}