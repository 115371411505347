import React from "react";
import { colors, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: { paddingBottom: "1rem" },
  itemWrapper: { padding: "0.5rem" }
});

function BlockList({ name, details, items, blockSize }) {
  const cls = useStyles();

  return (
    <Grid container item xs={12} className={cls.container}>
      {name && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <BTypography variant="h5">{name}</BTypography>
          </Grid>
          {details && (
            <Grid item xs className={cls.details}>
              {details}
            </Grid>
          )}
        </Grid>
      )}
      {items.map((x, i) => {
        return (
          <Grid
            container
            item
            md={blockSize ? blockSize : 4}
            sm={blockSize ? blockSize : 6}
            xs={blockSize ? blockSize : 12}
            className={cls.itemWrapper}
            alignItems="stretch"
            key={i}
          >
            <Grid item xs={12} className={cls.item}>
              {x}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
export default BlockList;
