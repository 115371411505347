import React, { useState, useEffect } from "react";
import WorldMap from "./worldMap";
import { managerData, userData } from "bild-data";
import { LoadingScreen, colors } from "bild-ui";
import { setPageTitle } from "bild-utils";
import { useParams } from "react-router-dom";


export default function OrganizationWorldMapLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [heatCircleData, setHeatCircleData] = useState([]);
  const [markerData, setMarkerData] = useState([]);
  const [startingLocation, setStartingLocation] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [defaultLayers, setDefaultLayers] = useState(null);

  useEffect(() => {
    setPageTitle("World Map");

    function _setData(m) {
      setPageTitle(`${m.organizationName} World Map`);
      setOrgName(m.organizationName);
      let allOrgs = [];
      let orgPosistions = [];
      let latPos = [];
      let lonPos = [];
      function findOrgs(node) {
        let children = node.children;
        if (children) {
          for (let i=0; i<children.length; i++) {
            findOrgs(children[i]);
          }
        }
        allOrgs.push(node.organization);
        if (node.organization.gpsLocation) {
          orgPosistions.push(node.organization.gpsLocation);
          latPos.push(node.organization.gpsLocation.split(", ")[0]);
          lonPos.push(node.organization.gpsLocation.split(", ")[1]);
        }
      }
      findOrgs(m.organizationRelationship);

      // Aggregate all latPos and lonPos values and then divide by the total number to get an average center to start the map on
      if (latPos.length > 0 && lonPos.length > 0) {
        let lat = latPos.reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },0);
        let lon = lonPos.reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },0);
        setStartingLocation([lat / latPos.length, lon / lonPos.length]);
      }

      // Filter out orgs that have no GPS data, and sort by radius to keep smaller items on a higher z-index for popups
      let orgs = allOrgs.filter(x => x.gpsLocation).sort((a,b) => b.gpsRadius - a.gpsRadius);
      // Heatmap Circles
      let heatCircles = [];
      // General Location Marker
      let markers = [];
      for (let i=0; i < orgs.length; i++) {
        let org = orgs[i];
        // Only add orgs with at least one Equip or Antioch User
        if (org.equipUserCount || org.antiochUserCount) {
          let gps = org.gpsLocation.split(", ");
          let color = org.equipUserCount > 0 ? colors.bildBlue : org.antiochUserCount > 0 ? colors.antiochPurple : colors.darkGray;
          let item = {
            pos: [parseFloat(gps[0]),
            parseFloat(gps[1])],
            id: org.organizationId,
            name: org.organizationName,
            radius: org.gpsRadius,
            color: color,
            equipUserCount: org.equipUserCount,
            antiochUserCount: org.antiochUserCount,
          };
          heatCircles.push(item);
          markers.push(item);
        }
      }
      setHeatCircleData(heatCircles);
      setMarkerData(markers);
      setLoading(false);
    }

    // Initialize Layers
    const userPreferences = userData.getUserPreferences();
    _updateLayers(userPreferences.worldMapLayers);

    managerData.getManagedOrganization(organization_id, _setData, e => {
      console.log(e);
      setError(e.response.data.error);
    });
  }, []);

  function _updateLayers(newLayers) {
    const userPreferences = userData.getUserPreferences();
    userPreferences.worldMapLayers = newLayers;
    userData.setUserPreferences(userPreferences);
    setDefaultLayers(newLayers);
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <WorldMap
      heatCircleData={heatCircleData}
      markerData={markerData}
      startingLocation={startingLocation}
      startingZoom={4}
      title={orgName}
      defaultLayers={defaultLayers}
      setLayers={_updateLayers}
    />
  );
}