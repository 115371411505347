import React, { useEffect, useState } from "react";
import { checkAdmin } from "bild-data/user";
import { makeStyles } from "@mui/styles";
import { Grid, Dialog, FormControl, FormLabel, OutlinedInput, FormHelperText, FormControlLabel, Checkbox } from "@mui/material";
import { BTypography, Blink, FormTextField, NormalButton, colors } from "bild-ui";

const isAdmin = checkAdmin();

const useStyles = makeStyles({
  wrapper: {
    border: `0.25rem solid ${colors.bildBlue}`,
    padding: "2rem",
    background: colors.white,
    minWidth: "10rem",
    maxWidth: "31rem"
  },
  submitWrapper: { padding: "1rem 0" },
  submit: { width: "100%", padding: "1rem 2rem" },
  formItemContainer: { padding: "0.25rem 0" },
  formOutline: {
    color: "#004882",
    "&:focus": { outline: "-webkit-focus-ring-color auto 5px !important" }
  },
  supportLinks: { textAlign: "right" },
  supportLink: { color: "#006dc3", padding: "0px", margin: "0px", fontSize: "15px !important", fontWeight: "bold" }
});

export default function HomeLogin({ handleLogin }) {
  const cls = useStyles();
  const [form, setForm] = useState({ username: "", password: "", remember_me: false });
  const [error, setError] = useState({});

  function _handleSubmit() {
    if (_validateForm()) {
      handleLogin(form);
    }
  }

  function _validateForm() {
    if (form.username.length < 1) {
      setError({ username: "Please enter your email address" });
      return false;
    } else if (form.password.length < 1) {
      setError({ password: "Please enter your password" });
      return false;
    } else {
      setError({});
      return true;
    }
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12}>
        <Grid item xs={12} className={cls.formItemContainer}>
          <FormControl fullWidth error={error.username ? true : false}>
            <FormLabel>Email</FormLabel>
            <OutlinedInput
              className={cls.formItem}
              id="username"
              fullWidth
              value={form.username}
              onChange={e => {
                _handleChange("username", e.target.value);
              }}
              onKeyDown={_handleKeyDown}
              autoFocus
            />
            {error.username && <FormHelperText>{error.username}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} className={cls.formItemContainer}>
          <FormControl fullWidth error={error.password ? true : false}>
            <FormLabel>Password</FormLabel>
            <OutlinedInput
              className={cls.formItem}
              id="password"
              variant="outlined"
              type="password"
              fullWidth
              value={form.password}
              onChange={e => {
                _handleChange("password", e.target.value);
              }}
              onKeyDown={_handleKeyDown}
            />
            {error.password && <FormHelperText>{error.password}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cls.submitWrapper}>
        <NormalButton
          variant="primary"
          borderColor={colors.bildBlue}
          color={colors.bildBlue}
          labelColor={colors.white}
          className={cls.submit}
          onClick={_handleSubmit}
          onKeyDown={_handleKeyDown}
        >
          Log In
        </NormalButton>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              id="remember_me"
              onChange={() => {
                _handleChange("remember_me", !form.remember_me);
              }}
              onKeyDown={_handleKeyDown}
              disableRipple={false}
            />
          }
          label="Stay signed in"
          className={cls.formOutline}
        />
      </Grid>
      <Grid container item xs={6} className={cls.links}>
        <Grid item xs={12}>
          <NormalButton
            variant="simple"
            component={Blink}
            dst="login"
            support_password="true"
            labelColor={colors.darkerGray}
          >
            <BTypography variant="body1" className={cls.supportLink}>Don't Know your password?</BTypography>
          </NormalButton>
        </Grid>
        <Grid item xs={12}>
          <NormalButton
            variant="simple"
            component={Blink}
            dst="login"
            support_request="true"
            labelColor={colors.darkerGray}
          >
            <BTypography variant="body1" className={cls.supportLink}>Submit a Support Request</BTypography>
          </NormalButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
