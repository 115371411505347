import React, { useEffect, useState } from "react";
import { localStorage } from "bild-data";
import { colors, Glower } from "bild-ui";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  fileProgressContainer: {
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "0 0.5rem",
    zIndex: "10000"
  },
  fileProgress: {
    margin: "0rem 0"
  },
  fileProgressInner: {
    background: colors.white,
    padding: "1rem"
  },
  spinner: {
    color: colors.bildBlue
  },
  fileName: {
    fontStyle: "italic"
  },
  fadeOut: { transition: "opacity 1s ease", opacity: "0" }
});

function FileProgressContainer() {
  const cls = useStyles();
  const [initialized, setIntialized] = useState(false);
  const [files, setFiles] = useState([]);
  const [localFiles, setLocalFiles] = useState([]);

  useEffect(() => {
    if (!initialized) {
      // Clear any previously set file downloads from a different page
      setFiles(localStorage.initializeFileDownloadStatus());
      setIntialized(true);
    }
  }, [initialized]);

  useEffect(() => {
    if ((files && localFiles && files.length >= localFiles.length) || !localFiles) {
      setLocalFiles(files);
    } else {
      setTimeout(() => setLocalFiles(files), 1000);
    }
  }, [files]);

  useEffect(() => {
    function checkFileDownloadStatus() {
      const files = localStorage.getFileDownloadStatus();
      if (files) {
        setFiles(files);
      }
    }
    // Create Event Listener for changes to localStorage, needs to pair with window.dispatchEvent(new Event("storage"))
    window.addEventListener("storage", checkFileDownloadStatus);

    return () => {
      // Remove Event Listener
      window.removeEventListener("storage", checkFileDownloadStatus);
    };
  }, []);

  let components;
  if (localFiles && localFiles.length > 0) {
    components = localFiles
      .sort((a, b) => b.id - a.id)
      .map(x => (
        <Glower glowOn={true} color={colors.bildBlue} key={x.id}>
          <div className={`${cls.fileProgress} ${files.some(y => y.id === x.id) ? "" : cls.fadeOut}`} key={x.id}>
            <div className={cls.fileProgressInner} key={x.id}>
              <i className={`${cls.spinner} fas ${files.some(y => y.id === x.id) ? "fa-circle-notch fa-spin" : "fa-download"}`} /> &nbsp; Downloading{" "}
              <span className={cls.fileName}>{x.name}</span>
            </div>
          </div>
        </Glower>
      ));
  }

  return <div className={cls.fileProgressContainer}>{components}</div>;
}

export default FileProgressContainer;
