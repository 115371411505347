export function updateURL(url) {
  window.location = url;
}

export function updateURLWithoutLoad(url) {
  window.history.replaceState("", "", url);
}

export function refreshPage(hardRefresh) {
  window.location.reload(hardRefresh);
}

export function getPath() {
  return window.location.pathname;
}

export function getPathSearch() {
  return window.location.search;
}

export function getParam(name) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}
