import React, { useEffect, useState, useRef } from "react";
import ToastBanner from "./toastBanner.js";
import toaster from "../utils/toaster.js";

function ToastContainer({ timeout }) {
  const [toasts, setToasts] = useState({}); // Local Toasts (not accessible from callback)
  const toastsRef = useRef(); // callback-safe local toasts ref
  const toastTypes = toaster.toastTypes;
  const toastTypeKeys = Object.keys(toastTypes);
  toastsRef.current = toasts; // make ref current on each render

  useEffect(()=>{
    // Initialize Toast "on" functions to add local onToast as a callback for each toastType
    for (let i = 0; i < toastTypeKeys.length; i++) {
      const type = toastTypes[toastTypeKeys[i]];
      toaster.on(type, (message)=> {onToast(type, message)});
    }
  },[]);

  // Local callback function to display toast
  function onToast(type, message) {
    const _toasts = {...toastsRef.current};
    let id = Date.now();
    let count = 0;
    while (_toasts[id] !== null && count < 10) {
      id += 1;
      count++;
    }

    const newToast = {
      id: id,
      type: type,
      message: message,
      fade: true
    };

    _toasts[id] = newToast;
    setToasts(_toasts);
  }

  // Local callback function to remove toast
  function removeToast(id) {
    const _toasts = {...toastsRef.current};
    const keys = Object.keys(_toasts);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (Number(k) === id) {
        _toasts[k].fade = false;
        setToasts(_toasts);

        setTimeout(() => {
          delete _toasts[k];
          setToasts(_toasts);
        }, 1000);

        break;
      }
    }
  }

  const components = [];
  const keys = Object.keys(toasts);
  for (let i = 0; i < keys.length; i++) {
    const t = toasts[keys[i]];

    components.push(
      <div className={t.fade === true ? "fade-in" : "fade-out"} key={t.id}>
        <ToastBanner
          id={t.id}
          message={t.message}
          type={t.type}
          timeout={timeout}
          onTimeout={removeToast}
        />
      </div>
    );
  }

  return <div className="toast-container">{components}</div>;
}

export default ToastContainer;
