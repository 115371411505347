import React from "react";
import { colors, BBadge } from "bild-ui";

function CircleProgress({ level, criteria, criteria1, content }) {
  const pass = colors.green;
  const warn = colors.blue;
  const fail = colors.orange;

  let clr = pass;
  switch (level) {
    case "warning":
      if (!criteria) clr = warn;
      break;
    case "danger":
      if (!criteria) clr = fail;
      break;
    case "warning-danger":
      if (!criteria) clr = warn;
      if (!criteria1) clr = fail;
      break;
    default:
      break;
  }

  return <BBadge backgroundColor={clr} content={content} />;
}

export default CircleProgress;
