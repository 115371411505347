import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, Blink, UserAvatar, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";

import OrganizationAdminTabs from "./components/organizations/organizationAdminTabs.js";
import OrganizationAdminProfile from "./components/organizations/organizationAdminProfile.js";
import OrgUserCertificates from "modules/certificates/userCertificates/orgUserCertificates.js";
import AntiochOrganizationUsers from "modules/management/manageOrganizations/components/antiochOrganizationUsers.js";
import ManageUsers from "modules/management/manageUsers/manageUsers.js";
import ManageOrganizationRoles from "modules/management/manageOrganizations/components/manageOrganizationRoles.js";
import AdminOrganizationNetwork from "./components/adminOrganizationNetwork.js";
import AdminOrganizationDeleteDialog from "./components/adminOrganizationDeleteDialog.js";

const useStyles = makeStyles({
  wrapper: { background: colors.white, minHeight: "100%", padding: "0 0 4rem 0" },
  header: {
    padding: "1rem"
  },
  icon: { color: colors.lightBlue },
  createdBy: { textAlign: "center" },
  avatar: { paddingRight: "0.5rem" },
  tabs: { position: "sticky", top: "0", zIndex: "20" },
  name: { paddingLeft: "0.5rem" },
  body: {},
  diagram: { backgroundColor: colors.white, marginBottom: "1rem" },
  missingDiagram: { padding: "5rem", textAlign: "center" }
});

function OrganizationAdmin({
  organization,
  updateOrganization,
  orgTree,
  subOrgs,
  subRoles,
  userRoles,
  inheritedUserRoles,
  certificates,
  antiochOrg,
  setAntiochOrg,
  resendEmail,
  archiveUser,
  allOrganizations,
  defaultTab,
  setDefaultTab,
  deleteOrganization
}) {
  const cls = useStyles();
  const [tab, setTab] = useState(defaultTab && defaultTab <= 6 ? defaultTab : 1);
  const [confirmDelete, setConfirmDelete] = useState(false);

  function _setTab(t) {
    setTab(t);
    if (typeof setDefaultTab === "function") setDefaultTab(t);
  }

  return (
    <Grid container className={cls.wrapper} direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid container item xs={12} direction="row" justifyContent="space-around" alignItems="center" className={cls.header}>
        <Grid container justifyContent="center" alignItems="center" item md={2} sm={2} xs={12}>
          {organization.createdByUser && (
            <Grid container item xs justifyContent="center" alignItems="center" className={cls.createdBy}>
              <Grid item xs={12}>
                Created By:
              </Grid>
              <Grid
                component={Blink}
                dst={"user-admin"}
                user_id={organization.createdByUser.id}
                container
                justifyContent="center"
                alignItems="center"
                item
                xs
              >
                <Grid item className={cls.avatar}>
                  <UserAvatar size={25} src={organization.createdByUser.avatarURL} name={organization.createdByUser.name} nameVariant="subtitle1" badgeSrc={organization.createdByUser.badgeURL} />
                </Grid>
              </Grid>
            </Grid>
          )}
          &nbsp;
        </Grid>
        <Grid container item md={8} sm={8} xs={12} justifyContent="center" alignItems="center">
          <Grid item className={`${cls.icon} fa-stack fa-2x`}>
            <i className="fal fa-square fa-stack-2x" />
            <i className="fal fa-users fa-stack-1x" />
          </Grid>
          <Grid item className={cls.name}>
            <BTypography variant="h3">{organization.organizationName}</BTypography>
          </Grid>
        </Grid>
        <Grid container item md={2} sm={2} xs={12} className={cls.headerButton} justifyContent="space-around" alignItems="center">
          <Grid item>
            <NormalButton
              component={Blink}
              dst={"manage-organization"}
              organization_id={organization.organizationId}
              variant="secondary"
              color={colors.white}
              labelColor={colors.bildGray}
              borderColor={colors.bildGray}
              hoverLabelColor={colors.bildGray}
            >
              Manager View
            </NormalButton>
          </Grid>
          <Grid item>
            <NormalButton
              variant="secondary"
              color={colors.white}
              labelColor={colors.darkRed}
              borderColor={colors.darkRed}
              hoverLabelColor={colors.darkRed}
              onClick={()=>{setConfirmDelete(true)}}
            >
              <i className="far fa-store-alt-slash" /> &nbsp; Delete
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.tabs}>
        <OrganizationAdminTabs tab={tab} setTab={_setTab} />
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        {tab === 1 && <OrganizationAdminProfile organization={organization} updateOrganization={updateOrganization} />}
        {tab === 2 && <ManageUsers users={organization.managedUsers} setUsers={null} orgs={subOrgs} org={organization} roles={subRoles} resendEmail={resendEmail} archiveUser={archiveUser} />}
        {tab === 3 && <AntiochOrganizationUsers antiochOrg={antiochOrg} setAntiochOrg={setAntiochOrg} />}
        {tab === 4 && (
          <AdminOrganizationNetwork organization={organization} orgTree={orgTree} allOrganizations={allOrganizations} updateOrganization={updateOrganization} />
        )}
        {tab === 5 && <OrgUserCertificates organization={organization} certificates={certificates} />}
        {tab === 6 && <ManageOrganizationRoles userRoles={userRoles} inheritedUserRoles={inheritedUserRoles} />}
      </Grid>
      <AdminOrganizationDeleteDialog
        open={confirmDelete}
        onClose={()=>{setConfirmDelete(false)}}
        organization={organization}
        allOrganizations={allOrganizations}
        deleteOrganization={deleteOrganization}
      />
    </Grid>
  );
}

export default OrganizationAdmin;
