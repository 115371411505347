import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

import { Button } from "@mui/material";
import { Blink } from "bild-ui";

const useStyles = makeStyles({
  microRoot: props => ({
    minWidth: "0",
    fontSize: "0.75rem",
    fontWeight: "normal",
    backgroundColor: `${props.color ? props.color : colors.lightGray} !important`,
    margin: "0 0.25rem",
    "&:hover": { backgroundColor: `${props.hoverColor ? props.hoverColor : "none"} !important` },
    "&:hover > span, &:active > span": {
      color: `${props.hoverLabelColor ? props.hoverLabelColor : colors.white} !important`
    },
  }),
  microLabel: props => ({
    lineHeight: "0.75rem",
    color: `${props.labelColor ? props.labelColor : colors.black} !important`,
    "&:hover": { color: `${props.hoverLabelColor ? props.hoverLabelColor : (props.color ? colors.white : colors.black)} !important` }
  }),
  slimRoot: {
    fontWeight: "normal",
    backgroundColor: colors.lightGray,
    margin: "0.25rem",
    padding: "0.5rem 0.5rem"
  },
  slimLabel: props => ({
    lineHeight: "1rem",
    color: `${props.labelColor ? props.labelColor : colors.black} !important`
  }),
  submitRoot: {
    fontWeight: "normal",
    backgroundColor: colors.green,
    margin: "0.25rem",
    padding: "0.5rem 0.5rem"
  },
  submitLabel: {
    lineHeight: "1rem",
    color: colors.white,
    "&:hover": {
      color: colors.black
    }
  },
  bigRoot: {
    fontWeight: "normal",
    width: "100%",
    lineHeight: "2rem",
    border: `1px solid ${colors.gray}`,
    "&:hover": {
      background: colors.darkerGray
    },
    "&:active": {
      background: colors.bildGray
    },
    "&:hover > span, &:active > span": {
      color: colors.white
    }
  },
  bigLabel: {
    lineHeight: "2rem",
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    color: colors.white
  },
  simpleRoot: props => ({
    fontWeight: "bold",
    backgroundColor: `${props.color} !important`,
    padding: "0.5rem 0.5rem",
    width: props.fullWidth ? "100% !important" : "",
    "&:hover": { backgroundColor: `${props.hoverColor ? props.hoverColor : props.color} !important`, filter: "brightness(0.9)" },
    "&:active": { backgroundColor: `${props.hoverColor ? props.hoverColor : props.color} !important`, filter: "brightness(0.8)" },
    "&:disabled": { opacity: "0.5" }
  }),
  simpleLabel: props => ({
    lineHeight: "1rem",
    color: `${props.labelColor ? props.labelColor : colors.black} !important`,
    "&:hover": { color: `${props.hoverLabelColor ? props.hoverLabelColor : (props.color ? colors.white : colors.black)} !important` }
  }),
  primaryRoot: props => ({
    fontWeight: "bold",
    backgroundColor: `${props.color} !important`,
    border: `1px solid ${props.borderColor ? props.borderColor : "transparent"} !important`,
    borderRadius: "4px",
    padding: "0.5rem 0.5rem",
    width: props.fullWidth ? "100% !important" : "",
    "&:hover": { backgroundColor: `${props.hoverColor} !important` },
    "&:hover > span, &:active > span": {
      color: `${props.hoverLabelColor ? props.hoverLabelColor : colors.white} !important`
    },
    "&:disabled": { opacity: "0.5" }
  }),
  primaryLabel: props => ({
    lineHeight: "1rem",
    color: `${props.labelColor ? props.labelColor : colors.black} !important`,
    "&:hover": { color: `${props.hoverLabelColor ? props.hoverLabelColor : (props.color ? colors.white : colors.black)} !important` }
  }),
  secondaryRoot: props => ({
    fontWeight: "bold",
    backgroundColor: `${props.color} !important`,
    border: `1px solid ${props.borderColor ? props.borderColor : "transparent"}`,
    borderRadius: "4px",
    padding: "0.5rem 0.5rem",
    width: props.fullWidth ? "100% !important" : "",
    "&:hover": { backgroundColor: `${props.hoverColor} !important` },
    "&:hover > span, &:active > span": {
      color: `${props.hoverLabelColor ? props.hoverLabelColor : colors.white} !important`
    },
    "&:disabled": { opacity: "0.5" }
  }),
  secondaryLabel: props => ({
    lineHeight: "1rem",
    color: `${props.labelColor ? props.labelColor : colors.black} !important`,
    "&:hover": { color: `${props.hoverLabelColor ? props.hoverLabelColor : (props.color ? colors.white : colors.black)} !important` }
  })
});

function NormalButton({ variant, color, labelColor, hoverColor, hoverLabelColor, borderColor, fullWidth, ...props }) {
  let classes = useStyles({ color, labelColor, hoverColor, hoverLabelColor, borderColor, fullWidth });
  const isLink = typeof props.dst === "string";

  let cls = null;
  if (variant === "micro") cls = { root: classes.microRoot, text: classes.microLabel };
  else if (variant === "slim") cls = { root: classes.slimRoot, text: classes.slimLabel };
  else if (variant === "submit") cls = { root: classes.submitRoot, text: classes.submitLabel };
  else if (variant === "big") cls = { root: classes.bigRoot, text: classes.bigLabel };
  else if (variant === "simple") cls = { root: classes.simpleRoot, text: classes.simpleLabel };
  else if (variant === "primary") cls = { root: classes.primaryRoot, text: classes.primaryLabel };
  else if (variant === "secondary") cls = { root: classes.secondaryRoot, text: classes.secondaryLabel };

  return <Button component={isLink ? Blink : undefined} classes={cls} {...props}/>;
}

export default NormalButton;
