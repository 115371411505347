import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { ENUMS, userData } from "bild-data";
import { loadAdminUserCompetency, loadAdminUser } from "bild-data/admin";

import LoadingScreen from "presentational/screens/loadingScreen.js";
import UserAdminCompetency from "containers/admin/userAdminCompetency.js";

function UserAdminCompetencyLoader({}) {
  const { user_id } = useParams();
  const userOverview = userData.getOverview();
  const userId = user_id || userOverview.user.id;
  setPageTitle("User Competency Admin");
  let [state, setState] = useState({ isLoading: true, filter: null });
  let [user, setUser] = useState(null);
  let statusItemHeaders = [ENUMS.ROLES.STUDENT.NAME, ENUMS.ROLES.LOCAL_MENTOR.NAME, ENUMS.ROLES.ASSOCIATE_FACULTY.NAME];

  useEffect(() => {
    if (!state.courseGroups && state.isLoading) {
      // Get All Course Work
      loadAdminUserCompetency(
        userId,
        data => {
          let totals = {
            competencyProofs: { filter: null, value: 0 },
            complete: { filter: "complete", value: 0 },
            waitST: { filter: "st", value: 0 },
            waitCL: { filter: "cl_", value: 0 },
            waitAF: { filter: "af_", value: 0 }
          };
          for (let i = 0; i < data.length; i++) {
            let courses = data[i].courses;
            for (let j = 0; j < courses.length; j++) {
              let competencies = courses[j].competencies;
              for (let k = 0; k < competencies.length; k++) {
                let cp = competencies[k].competencyProof;
                totals.competencyProofs.value++;
                if (cp.complete) totals.complete.value++;
                if (cp.statusCode.startsWith("st")) totals.waitST.value++;
                if (cp.statusCode.startsWith("cl_")) totals.waitCL.value++;
                if (cp.statusCode.startsWith("af_")) totals.waitAF.value++;
              }
            }
          }
          setState({ ...state, isLoading: false, orgCourseGroups: data, courseGroups: data, totals: totals });
        },
        data => {
          setState({ ...state, isLoading: false, pageError: data });
        }
      );
    }
  }, [userId, state]);

  useEffect(() => {
    if (!user) {
      // Get UserOverview
      loadAdminUser(userId, data => {
        setUser(data.user);
      });
    }
  }, [userId, user]);

  function filterCompetencies(filter) {
    // Deep copy of courses so we can manipulate this without losing data
    let courseGroups = JSON.parse(JSON.stringify(state.orgCourseGroups));

    // Iterate over a full course group structure, removing whatever does not match the competency filter
    // CourseGroup -> Courses -> Competencies -> CompetencyProof
    let removeGroups = [];
    for (let i = 0; i < courseGroups.length; i++) {
      let courses = courseGroups[i].courses;
      let removeCourses = [];
      for (let j = 0; j < courses.length; j++) {
        let competencies = courses[j].competencies;
        let removeItems = [];
        for (let k = 0; k < competencies.length; k++) {
          // Compare competencyProof status to filter
          let status = competencies[k].competencyProof.statusCode;
          if (filter !== null && !status.startsWith(filter)) removeItems.push([k]);
        }
        // If we will remove all competencies, remove this course. Then remove the competencies in reverse order
        if (competencies.length === removeItems.length) removeCourses.push([j]);
        removeItems.reverse().forEach(x => {
          courseGroups[i].courses[j].competencies.splice(x, 1);
        });
      }
      // If we will remove all courses, remove this courseGroup. Then remove the courses in reverse order
      if (courses.length === removeCourses.length) removeGroups.push([i]);
      removeCourses.reverse().forEach(x => {
        courseGroups[i].courses.splice(x, 1);
      });
    }
    // Finally remove marked courseGroups in reverse order
    removeGroups.reverse().forEach(x => {
      courseGroups.splice(x, 1);
    });

    setState({ ...state, courseGroups: courseGroups, filter: filter });
  }

  if (state.isLoading || state.pageError) {
    return <LoadingScreen error={state.pageError} />;
  } else {
    return (
      <UserAdminCompetency
        user={user}
        courseGroups={state.courseGroups}
        totals={state.totals}
        statusItemHeaders={statusItemHeaders}
        filterCompetencies={filterCompetencies}
        filter={state.filter}
      />
    );
  }
}

export default UserAdminCompetencyLoader;
