import React, { useEffect, useState } from "react";
import { BTypography, NormalButton, SortableList, UserAvatar, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  title: { paddingBottom: "1rem", background: colors.darkRed, color: colors.white },
  body: { textAlign: "center", padding: "2rem" },
  text: { padding: "2rem" },
  button: { padding: "1rem 2rem" }
});

export default function ArchiveUserDialog({ open, onClose, org, user, archiveUser }) {
  const cls = useStyles();

  function _archiveUser() {
    archiveUser();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle className={cls.title}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
            <Grid item>
              <BTypography variant="h5"><i className="fal fa-archive"/>&nbsp; Archive User</BTypography>
            </Grid>
          </Grid>
          <Grid item>
            <NormalButton
              onClick={onClose}
              variant={"micro"}
              color={colors.transparent}
              labelColor={colors.white}
              hoverColor={colors.lightGray}
              hoverLabelColor={colors.black}
            >
              <i className="far fa-times fa-2x" />
            </NormalButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" className={cls.body}>
          <Grid item xs={12} className={cls.text}>
            {user && org && (
              <BTypography variant="subtitle1">Archive &nbsp; <b>{user.name}</b> &nbsp; for &nbsp; <b>{org.organizationName}</b>?</BTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <NormalButton
              onClick={_archiveUser}
              variant="primary"
              color={colors.darkRed}
              hoverColor={colors.darkerRed}
              labelColor={colors.white}
              className={cls.button}
            >
              Archive User
            </NormalButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
