import React, { useState, useEffect } from "react";
import { colors, SortableList, List, Blink, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, TextField, Checkbox } from "@mui/material";
import FundraisingPhones from "./components/fundraisingPhones.js";
import FundraisingInteraction from "./components/fundraisingInteraction.js";
import FundraisingTask from "./components/fundraisingTask.js";

const useStyles = makeStyles({
  header: { paddingLeft: "1rem" },
  row: { padding: "0.25rem 1rem" },
  backButton: { borderRadius: "4px", border: `1px solid ${colors.darkGray}`, marginLeft: "1rem", padding: "1rem" },
  label: { textAlign: "right", paddingRight: "1rem" },
  required: { color: colors.red, fontSize: "1.5rem" },
  disabled: { opacity: "0.5" },
  submitWrapper: { justifyContent: "flex-end" },
  task: { padding: "0.25rem" },
  submit: { padding: "2rem" }
});

function FundraisingConstituent({ constituent, updateConstituent }) {
  const cls = useStyles();
  const [phonesToRemove, setPhonesToRemove] = useState([]);
  const [phonesValid, setPhonesValid] = useState(false);
  const [interaction, setInteraction] = useState(null);
  const [interactionValid, setInteractionValid] = useState(false);
  const [task, setTask] = useState(null);
  const [taskValid, setTaskValid] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    // validate all fields are set properly
    if (interactionValid && taskValid && phonesValid) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [interactionValid, taskValid, phonesValid]);

  function _updateConstituent() {
    // If enabled, run update
    if (isEnabled) {
      let taskToAdd = task && task.date !== "" ? task : null;
      updateConstituent(interaction, taskToAdd, phonesToRemove);
    }
  }

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <NormalButton variant="simple" labelColor={colors.darkGray} className={cls.backButton} component={Blink} dst="fundraising">
            <i className={`fas fa-arrow-left`} /> &nbsp; Cancel
          </NormalButton>
        </Grid>
        <Grid item xs className={cls.header}>
          <BTypography variant="h3">{constituent.name}</BTypography>
          <BTypography variant="h6">{constituent.groupName}</BTypography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cls.row}>
        <FundraisingPhones
          phones={constituent.phones}
          phonesToRemove={phonesToRemove}
          setPhonesToRemove={setPhonesToRemove}
          setIsValid={setPhonesValid}
        />
      </Grid>
      <Grid container item xs={12} className={cls.task}>
        <FundraisingInteraction updateInteraction={setInteraction} setIsValid={setInteractionValid} />
      </Grid>
      <Grid container item xs={12} alignItems="flex-end" justifyContent="flex-end" className={`${cls.submitWrapper}`}>
        {interaction && (interaction.comm === "Complete Ask" || interaction.comm === "Incomplete Ask") && (
          <Grid container item lg={10} md={9} sm={8} xs={12} className={cls.task}>
            <FundraisingTask updateTask={setTask} setIsValid={setTaskValid} />
          </Grid>
        )}
        <Grid container item lg={2} md={3} sm={4} xs={12} className={`${cls.row} ${cls.submitWrapper}`}>
          <Grid item>
            <NormalButton
              disabled={!isEnabled}
              onClick={_updateConstituent}
              variant="simple"
              color={colors.green}
              labelColor={colors.white}
              className={`${cls.submit} ${!isEnabled ? cls.disabled : ""}`}
            >
              Update Constituent
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FundraisingConstituent;
