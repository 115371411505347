import React, { useState, useEffect } from "react";
import { ENUMS } from "bild-data";
import { convertNetworkTreeJSONToCSV, createAndDownloadFile } from "bild-utils";
import { colors, Blink, BTypography, NormalButton, TreeDiagram, SideTreeDiagram } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import AddOrganizationDialog from "./components/addOrganizationDialog.js";

const useStyles = makeStyles({
  wrapper: { padding: "1rem 1rem 5rem 1rem" },
  header: { padding: "1rem", marginBottom: "1rem", backgroundColor: colors.white },
  button: { padding: "0.25rem 0 0.25rem 0.5rem" },
  diagram: { background: colors.white }
});

function ManageAllOrganizations({ orgs, orgTree, users, setUsers, createOrganization, isAdmin }) {
  const cls = useStyles();
  const [showTree, setShowTree] = useState(true);
  const [orgDialog, setOrgDialog] = useState(false);

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} justifyContent="space-between" className={cls.header}>
        <Grid item md={4} sm={12} xs={12}>
          <BTypography variant="h4">Equip Network</BTypography>
        </Grid>
        <Grid container item md={8} sm={12} xs={12} justifyContent="flex-end">
          <Grid item className={cls.button}>
            <NormalButton
              component={Blink}
              dst="available-certificates"
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              borderColor={colors.bildBlue}
            >
              Available Certificates
            </NormalButton>
          </Grid>
          <Grid item className={cls.button}>
            <NormalButton
              onClick={() => {
                const today = new Date()
                  .toJSON()
                  .slice(0, 10)
                  .replace(/-/g, "");
                let csvData = convertNetworkTreeJSONToCSV(orgTree);
                createAndDownloadFile(`Equip_Organizations_${today}.csv`, csvData);
              }}
              variant="secondary"
              color={colors.white}
              hoverColor={colors.bildBlue}
              labelColor={colors.bildBlue}
              borderColor={colors.bildBlue}
            >
              <i className="fal fa-file-excel" /> &nbsp; Download Data
            </NormalButton>
          </Grid>
          <Grid item className={cls.button}>
            <NormalButton
              onClick={() => {
                setShowTree(!showTree);
              }}
              variant="secondary"
              color={colors.white}
              hoverColor={colors.bildBlue}
              labelColor={colors.bildBlue}
              borderColor={colors.bildBlue}
            >
              {showTree ? "Show Side Tree" : "Show Tree"} Diagram
            </NormalButton>
          </Grid>
          {isAdmin && (
            <Grid item className={cls.button}>
              <NormalButton
                onClick={() => {
                  setOrgDialog(!orgDialog);
                }}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                borderColor={colors.bildBlue}
              >
                Add Organization
              </NormalButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.diagramWrapper}>
        <Grid item xs={12} className={cls.diagram}>
          {showTree && <TreeDiagram data={orgTree} scalable={true} legend={true} />}
          {!showTree && <SideTreeDiagram data={orgTree} scalable={true} legend={true} />}
        </Grid>
      </Grid>

      <AddOrganizationDialog
        open={orgDialog}
        closeDialog={() => {
          setOrgDialog(false);
        }}
        orgs={orgs.filter(x => x.canHaveChildren === true)}
        users={users}
        setUsers={setUsers}
        createOrganization={createOrganization}
        organizationTypes={[
          ENUMS.LOCAL_ENTITY_TYPES.LOCAL_ORGANIZATION,
          ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_1,
          ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_2,
          ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_3,
          ENUMS.LOCAL_ENTITY_TYPES.PRT,
          ENUMS.LOCAL_ENTITY_TYPES.GRT
        ]}
      />
    </Grid>
  );
}

export default ManageAllOrganizations;
