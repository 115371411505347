import React from "react";
import { CircleButton } from "bild-ui";
import { Grid } from "@mui/material";

function DynamicCircleButton({ icon, color, color2, color3, clicked, onClick, enabled, size }) {
  let iconComp = <i className={`fas fa-${icon}`} />;
  let colorArr = [];
  if (color) {
    colorArr.push(color);
  }
  if (color2) {
    colorArr.push(color2);
  }
  if (color3) {
    colorArr.push(color3);
  }

  let colorVal = colorArr[0];
  if (colorArr.length > 1) {
    colorVal = `linear-gradient(90deg, ${colorArr[0]} 50%, ${colorArr[1]} 50%)`;
    if (colorArr.length > 2) {
      colorVal = `linear-gradient(160deg, ${colorArr[0]} 50%, transparent 50%) 0px 0px / 50% 125% no-repeat, linear-gradient(200deg, ${colorArr[1]} 50%, transparent 50%) 100% 0px / 50% 125% no-repeat, linear-gradient(90deg, ${colorArr[2]} 0%, ${colorArr[2]} 100%) 0px 0px / 100% 115% no-repeat`;
    }
  }

  return (
    <CircleButton size={size} clicked={clicked} onClick={onClick} enabled={enabled ? "true" : "false"} color={colorVal}>
      <Grid container item justifyContent="space-between" alignItems="center">
        {colorArr.length === 1 && (
          <Grid item xs={12}>
            {iconComp}
          </Grid>
        )}
        {colorArr.length === 2 && (
          <>
            <Grid item xs={4}>
              {iconComp}
            </Grid>
            <Grid item xs={4}>
              {iconComp}
            </Grid>
          </>
        )}
        {colorArr.length === 3 && (
          <Grid container justifyContent="space-between" alignItems="center" style={{ paddingTop: "10px" }}>
            <Grid item xs={4}>
              {iconComp}
            </Grid>
            <Grid item xs={4}>
              {iconComp}
            </Grid>
            <Grid item xs={12}>
              {iconComp}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CircleButton>
  );
}

export default DynamicCircleButton;
