import { useEffect } from "react";
import { localStorage } from "bild-data/core";
import * as toast from "presentational/toasts/toasts.js";
// this.toastTypes = { SUCCESS: "success", ERROR: "error", WARNING: "warning", INFO: "info" };

// FLASH
function Flash() {
  // This function performs very similarly to the flash component in Ruby on Rails. A message is sent to the "flash_messages" and then loaded on the
  // following page load. This is useful for functions that also need to redirect to a different page (login, password_reset, etc). Then it clears
  // out the flash_messages after sending them to the toaster

  useEffect(() => {
    // Load the flash_messages from localStorage and see if anything needs to be sent to the toaster
    let messages = localStorage.getFlashMessages();
    if (messages) {
      for (let i = 0; i < messages.length; i++) {
        let type = messages[i].type;
        let message = messages[i].message;
        switch (type) {
          case "success":
            toast.toaster.success(message);
            break;
          case "error":
            toast.toaster.error(message);
            break;
          case "warning":
            toast.toaster.warning(message);
            break;
          default:
            toast.toaster.info(message);
            break;
        }
      }
      // Finally destory all flash_messages
      localStorage.deleteFlashMessages();
    }
  }, []); // Empty array == componentDidMount()

  return null;
}

export default Flash;
