import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Blink, BTypography, colors, NormalButton, SortableList, UserAvatar } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({});

export default function StaffSearchUsers({ users, count }) {
  const cls = useStyles();
  const [items, setItems] = useState([]);

  useEffect(()=>{
    if (users) {
      setItems(users.map(x => [
        [<UserAvatar size={35} src={x.avatarURL} name={x.name} badgeSrc={x.badgeURL} sortval={x.name} key={x.name}/>],
        [<BTypography variant="body1" sortval={x.emailAddress} key={x.emailAddress}>
          {x.emailAddress}
        </BTypography>],
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <NormalButton
                component={Blink}
                dst="manage-user"
                user_id={x.id}
                variant="micro"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                <i className="fas fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
      ]));
    }
  },[users]);

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
      <SortableList
        headers={["Name", "Email", ""]}
        items={items}
        itemPadding={"0.5rem 1rem"}
        itemType={"Users"}
        title={`Users (${count})`}
        fixedHeight={"55vh"}
      />
    </Grid>
  );
}
