import React, { useState, useEffect } from "react";
import { getUsername } from "bild-data/core/localStorage";
import { supportData } from "bild-data";
import { toaster } from "presentational/toasts/toasts.js";
import LoadingDialog from "presentational/dialogs/loadingDialog.js";

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  OutlinedInput,
  Radio,
  RadioGroup
} from "@mui/material";

function SupportDialog({ open, closeDialog, options }) {
  const [state, setState] = useState({ isLoading: false });
  const [username, setUsername] = useState(getUsername() ? getUsername() : null);
  const [error, setError] = useState({});

  useEffect(() => {
    // if options don't contain the state's subject, reset the form
    if (!options.some(o => o === state.subject)) {
      setState({ isLoading: false });
    }
  }, [options]);

  function _handleChange(field, value) {
    setState({ ...state, [field]: value });
  }

  function _handleSubmit() {
    if (!state.subject) {
      setError({ subject: "Please select one of the options." });
    } else {
      let data = {
        supportCategory: state.subject,
        email: username,
        message: state.message ? state.message : "~No message content~",
        userUrlLocation: window.location.href,
        deviceInfo: navigator.userAgent
      };

      // TODO add intercom event for this
      if (!state.isLoading) {
        setState({ ...state, isLoading: true });
        supportData.createSupportTicket(data, _handleSuccess, _handleFailure);
      }
    }
  }

  function _handleSuccess() {
    // Request successfully recieved, let the user know and then close the dialog
    toaster.success("Your request has been successfully sent.");
    setState({ isLoading: false });
    closeDialog();
  }

  function _handleFailure(e) {
    // Things have failed, let the user know to try again or email us directly
    setState({ ...state, isLoading: false });
    console.log(e);
    toaster.error("There was an error while trying to contact the server. Please try again or email cloudsupport@bild.org directly.");
  }

  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle>Submit a Support Request</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={error.subject ? true : false}>
                <RadioGroup
                  value={state.subject}
                  defaultValue="Something seems to be broken."
                  onChange={e => {
                    _handleChange("subject", e.target.value);
                  }}
                >
                  {options && options.map(x => <FormControlLabel value={x} control={<Radio />} label={x} key={x} />)}
                  {error.subject && <FormHelperText>{error.subject}</FormHelperText>}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ margin: "10px 0px" }}>
              <FormControl fullWidth>
                <FormLabel>Comments/Questions</FormLabel>
                <OutlinedInput
                  id="support-message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.message ? state.message : ""}
                  onChange={e => {
                    _handleChange("message", e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} className="default-cancel-button">
            Cancel
          </Button>
          <Button onClick={_handleSubmit} disabled={state.isLoading} className="default-submit-button">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={state.isLoading} />
    </>
  );
}

export default SupportDialog;
