import { localStorage as ls, diskData } from "..";
import { Callbacks, CallbacksInvalidError } from "./callbacks";

// setItem(item_key, value)
// getItem(item_key)

const keys = {
  DOC_HANDLES: "bild-doc-handles",
  DOC_OBJECT_PREFIX: "bild-doc-object"
};

export function getHandles(callbacks) {
  if (!Callbacks.isValid(callbacks)) throw new CallbacksInvalidError();

  return ls.getItem(keys.DOC_HANDLES) || [];
}

export function getHandle(id) {
  const handles = getHandles();
  const target = handles.find(h => h.id === id);
  if (target && target.name === target.id) {
    target.name = "~Untitled~";
  }
  return target;
}

export function setHandles(handles, callbacks) {
  if (!Callbacks.isValid(callbacks)) throw new CallbacksInvalidError();

  ls.setItem(keys.DOC_HANDLES, handles);
}

export function updateHandle(updatedHandle) {
  const handles = getHandles();
  const target = handles.find(h => h.id === updatedHandle.id);

  if (target && updatedHandle.name) {
    target.name = updatedHandle.name;
    setHandles(handles);
  }
}

export function addHandleToUpload(updatedHandleId) {
  let uploadHandles = ls.getDocumentUploadQueue() || [];
  const exists = uploadHandles.find(h => h === updatedHandleId);

  if (!exists) {
    uploadHandles.push(updatedHandleId);
    ls.setDocumentUploadQueue(uploadHandles);
  }
}

export function removeHandleToUpload(updatedHandleId) {
  let uploadHandles = ls.getDocumentUploadQueue() || [];
  const index = uploadHandles.indexOf(updatedHandleId);

  if (index !== -1) {
    uploadHandles.splice(index, 1);
    ls.setDocumentUploadQueue(uploadHandles);
  }
}

// Return id of new document
export function createDocument(fileName, success_cb, error_cb, final_cb, callbacks) {
  if (!Callbacks.isValid(callbacks)) throw new CallbacksInvalidError();

  // use time stamp as new id
  const date = new Date();
  const timeStamp = date.getTime();
  const id = `${keys.DOC_OBJECT_PREFIX}-${timeStamp}`;

  // create new handle and push it to local storage, create doc object id.
  const handles = getHandles();
  handles.push({ name: fileName ? fileName : null, id });
  setHandles(handles, callbacks);

  // create new document object and save in local storage
  const docObject = { id, value: [{ type: "paragraph", children: [{ text: "" }] }] };
  ls.setItem(id, docObject);

  if (id) {
    success_cb(id);
    addHandleToUpload(id);
    processUploadQueue(d => {
      final_cb(id, d);
    });
  } else {
    error_cb();
  }
}

export function deleteDocument(id) {
  const handles = getHandles();
  const newHandles = handles.filter(h => h.id !== id);
  setHandles(newHandles);

  ls.removeItem(id);
}

export function getDocument(id) {
  return ls.getItem(id);
}

export function updateDocument(docObject) {
  const { id, value } = docObject;
  const currentObject = ls.getItem(id).value;
  // If the data has changed since last time, send this data up to the BE
  if (JSON.stringify(value) !== JSON.stringify(currentObject)) {
    // Add to list of items that need to be uploaded
    addHandleToUpload(id);
  }
  // Update the localStorage data every time
  ls.setItem(id, docObject);
}

export function processUploadQueue(success_cb) {
  // Get the list of files that are stored locally and need to update their remote counterpart
  let queue = ls.getDocumentUploadQueue() || [];
  if (queue.length > 0) {
    console.log("Running Upload Queue");
  }

  // Process each item that needs to be updated
  for (let i = 0; i < queue.length; i++) {
    let id = queue[i];
    const value = ls.getItem(id).value;
    const file = {
      id: 1, // TODO need to replace placeholder when BE gives file id
      name: getHandle(id),
      inAppLocalId: id
    };
    diskData.createOrUpdateArtifact(file, value, success_cb);
    removeHandleToUpload(id);
  }
}
