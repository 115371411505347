import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const useStyles = makeStyles({
  wrapper: { minWidth: "200px", minHeight: "200px" },
  tooltipLabel: {
    fontSize: "0.75rem",
    background: "#ffffffc4",
    padding: "0.5rem",
    border: `1px solid ${colors.bildBlue}`,
    maxWidth: "200px"
  }
});

function LineChartBuilder({ data, xAxis, fillColor }) {
  const cls = useStyles();
  const defaultFillColor = fillColor ? fillColor : colors.bildBlue;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let p = payload[0].payload;
      return (
        <Grid container className={cls.tooltipLabel}>
          <Grid item xs={12}>
            <b>{p.name}</b>
          </Grid>
          <Grid item xs={12}>
            {p.uv && p.pv && (
              <>
                {p.uv.toLocaleString()} {p.uvName} / {(p.uv + p.pv).toLocaleString()} {p.pvName}
              </>
            )}
            {p.uv && !p.pv && (
              <>
                {p.uv.toLocaleString()} {p.uvName}
              </>
            )}
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  if (!data) return <></>;
  return (
    <Grid container item xs={12} className={cls.wrapper}>
      <ResponsiveContainer width="99%" height="99%">
        <LineChart width={400} height={400} data={data} margin={{ top: 5, right: 50, bottom: 5, left: 5 }} barSize={20}>
          <CartesianGrid strokeDasharray="3 3" />
          {xAxis && <XAxis dataKey={xAxis} />}
          <YAxis />
          <Tooltip wrapperStyle={{ outline: "none" }} content={CustomTooltip} />
          <Line type="monotone" dataKey="uv" stackId="a" stroke={defaultFillColor}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} stroke={entry.color ? entry.color : defaultFillColor} />
            ))}
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default LineChartBuilder;
