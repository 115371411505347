import { getRequest } from "bild-data/core/comm.js";

export function loadAllOrganizationProgress(success_cb, error_cb) {
  getRequest(
    `/reports/organization_progress`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function loadOrganizationProgress(organization_id, success_cb, error_cb) {
  getRequest(
    `/reports/organization_progress/${organization_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function getSystemRecentActivity(success_cb, error_cb) {
  getRequest(
    `/reports/system_recent_activity`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}
