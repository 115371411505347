import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, UserAvatar, BTypography, Blink, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";
import UserAdminTabs from "./components/userAdminTabs.js";
import ChangeUsernameDialog from "./components/changeUsernameDialog.js";
import AddUserRoleDialog from "./components/addUserRoleDialog.js";
import ChangeOrganizationDialog from "./components/changeOrganizationDialog.js";
import UserAdminProfile from "./components/userAdminProfile.js";
import UserAdminDisk from "./components/userAdminDisk.js";
import UserAdminOrganizations from "./components/userAdminOrganizations.js";
import UserAdminAntioch from "./components/userAdminAntioch.js";
import UserAdminEquip from "./components/userAdminEquip.js";

const useStyles = makeStyles({
  wrapper: { background: colors.white, minHeight: "100%", padding: "0 0 4rem 0" },
  header: {
    padding: "1rem"
  },
  createdBy: { textAlign: "center" },
  headerButton: { padding: "0.5rem" },
  tabs: { position: "sticky", top: "0", zIndex: "201" },
  body: { padding: "1rem" },
  buttonText: { textAlign: "center" },
});

function UserAdmin({
  overview,
  user,
  allUsers,
  userProfile,
  disk,
  organizations,
  allOrganizations,
  orgTree,
  availableRoles,
  generalRoles,
  userRoles,
  userPaths,
  certificates,
  updateUserProfile,
  updateUsername,
  updateUserStorage,
  createUserRole,
  onRoleSuccess,
  updateOrg,
  onOrgSuccess,
  refreshUserPath,
  updateUserPathShepherd,
  removeUserPath,
  removeCert,
  removeRole,
  defaultTab,
  setDefaultTab
}) {
  const cls = useStyles();
  const [emailOpen, setEmailOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [orgOpen, setOrgOpen] = useState(false);
  const [orgType, setOrgType] = useState(0);
  const [org, setOrg] = useState({});
  const [tab, setTab] = useState(defaultTab && defaultTab <= 6 ? defaultTab : 1);

  function _setTab(t) {
    setTab(t);
    if (typeof setDefaultTab === "function") setDefaultTab(t);
  }

  function _setOrgOpen(state, type, oldOrg) {
    setOrgOpen(state);
    setOrgType(type);
    setOrg(oldOrg);
  }

  return (
    <Grid container className={cls.wrapper} direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid container item xs={12} direction="row" justifyContent="space-around" alignItems="center" className={cls.header}>
        <Grid container justifyContent="center" alignItems="center" item md={2} sm={2} xs={12}>
          {overview.createdByUser && (
            <Grid container item xs justifyContent="center" alignItems="center" className={cls.createdBy}>
              <Grid item xs={12}>
                Created By:
              </Grid>
              <Grid component={Blink} dst={"user-admin"} user_id={overview.createdByUser.id} container justifyContent="center" alignItems="center" item xs>
                <Grid item className={cls.createdBy}>
                  <UserAvatar size={25} src={overview.createdByUser.avatarURL} name={overview.createdByUser.name} nameVariant="subtitle1" badgeSrc={overview.createdByUser.badgeURL} />
                </Grid>
              </Grid>
            </Grid>
          )}
          &nbsp;
        </Grid>
        <Grid container item md={8} sm={8} xs={12} justifyContent="center" alignItems="center">
          <Grid item>
            <UserAvatar size={100} src={user.avatarURL} name={user.name} nameVariant="h3" badgeSrc={user.badgeURL} />
          </Grid>
        </Grid>
        <Grid container item md={2} sm={2} xs={12} justifyContent="center" alignItems="center">
          <Grid item className={cls.headerButton}>
            <NormalButton component={Blink} dst={"masquerade"} user_id={user.id} variant="primary" color={colors.bildGray} labelColor={colors.white}>
              <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <i className={"fas fa-mask"} />
                </Grid>
                <Grid item xs className={cls.buttonText}>
                  &nbsp; Become User
                </Grid>
              </Grid>
            </NormalButton>
          </Grid>
          <Grid item className={cls.headerButton}>
            <NormalButton
              component={Blink}
              dst={"manage-user"}
              user_id={user.id}
              variant="secondary"
              color={colors.white}
              labelColor={colors.bildGray}
              borderColor={colors.bildGray}
              hoverLabelColor={colors.bildGray}
            >
              <span className={cls.buttonText}>
                Manager View
              </span>
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.tabs}>
        <UserAdminTabs tab={tab} setTab={_setTab} />
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        {tab === 1 && (
          <UserAdminProfile
            user={user}
            userProfile={userProfile}
            overview={overview}
            setOrgOpen={_setOrgOpen}
            setEmailOpen={setEmailOpen}
            handleSubmit={updateUserProfile}
          />
        )}
        {tab === 2 && <UserAdminDisk user={user} disk={disk} updateUserStorage={updateUserStorage} />}
        {tab === 3 && (
          <UserAdminOrganizations
            orgTree={orgTree}
            user={user}
            generalRoles={generalRoles}
            userRoles={userRoles}
            setRoleOpen={setRoleOpen}
            removeRole={removeRole}
          />
        )}
        {tab === 4 && <UserAdminAntioch user={user} overview={overview} />}
        {tab === 5 && (
          <UserAdminEquip
            user={user}
            allUsers={allUsers}
            userPaths={userPaths}
            updateUserPathShepherd={updateUserPathShepherd}
            refreshUserPath={refreshUserPath}
            removeUserPath={removeUserPath}
            certificates={certificates}
            removeCert={removeCert}
          />
        )}
      </Grid>
      <ChangeOrganizationDialog
        open={orgOpen}
        currentOrg={org}
        orgType={orgType}
        overview={overview}
        organizations={allOrganizations}
        onClose={() => {
          setOrgOpen(false);
          setOrgType(0);
          setOrg({});
        }}
        onSubmit={updateOrg}
        onSuccess={onOrgSuccess}
      />
      <ChangeUsernameDialog open={emailOpen} user={user} onClose={setEmailOpen} onSubmit={updateUsername} classes={cls} />
      <AddUserRoleDialog
        open={roleOpen}
        user={user}
        organizations={allOrganizations}
        roles={availableRoles}
        onClose={setRoleOpen}
        onSubmit={createUserRole}
        onSuccess={onRoleSuccess}
        classes={cls}
      />
    </Grid>
  );
}

export default UserAdmin;
