import React, { useState, useEffect } from "react";
import { userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import UserCertificates from "./userCertificates.js";

function UserCertificatesLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("User Certificates");
      setUser(d.user);
      setCertificates(d.certificates);
      setLoading(false);

      // Mark Certificate Notifications Read on pageload, if any are unread
      if (d.certificates.some(x => x.userNotified === false)) {
        userData.markCertificateNotificationsRead();
      }
    }

    userData.getUserCertificates(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return <UserCertificates user={user} certificates={certificates} />;
}

export default UserCertificatesLoader;
