import React, { useState, useEffect } from "react";
import { colors, NormalButton, BTypography, OrgGroupsDiagram, OrgGroupEditDialog, OrgGroupUsersEditDialog, OrgGroupMassUsersDialog } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Badge, Grid } from "@mui/material";

const useStyles = makeStyles({
  wrapper: {},
  diagramHeader: { background: colors.offWhite, padding: "1rem 1rem" },
  diagram: { backgroundColor: colors.white, marginBottom: "1rem" },
  missingDiagram: { padding: "5rem", textAlign: "center" },
  buttonWrapper: { padding: "0 1rem" },
});

export default function ManageOrganizationGroups({ org, orgGroups, orgGroupUpdate, users }) {
  const cls = useStyles();
  const [orgGroup, setOrgGroup] = useState(null);
  const [orgGroupUsers, setOrgGroupUsers] = useState(null);
  const [orgGroupMassUsers, setOrgGroupMassUsers] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [unassignedUsers, setUnassignedUsers] = useState([]);

  // Available Users
  useEffect(()=>{
    setAvailableUsers(users.filter(x => x.organizations.some(y => y.organizationId === org.organizationId)));
  },[users]);

  // Unassigned Users
  useEffect(()=>{
    function getChildren(node) {
      let newAvailableUsers = node.users.map(x => {
        return {
          id: x.id,
          name: x.name,
          avatarURL: x.avatarURL,
          message: {
            icon: "fal fa-house-user",
            text: `This person is a member of <i>${node ? node.name : ""}</i>`
          }
        }
      });
      if (node.children) {
        for (let i=0; i < node.children.length; i++) {
          newAvailableUsers.push(getChildren(node.children[i]));
        }
      }
      return newAvailableUsers.flat();
    }

    if (orgGroups) {
      if (orgGroups.children) {
        let newAvailableUsers = [];
        for (let i=0; i < orgGroups.children.length; i++) {
          newAvailableUsers.push(getChildren(orgGroups.children[i]));
        }
        newAvailableUsers = newAvailableUsers.flat();
        let groupedUserIds = newAvailableUsers.map(x => x.id);
        let oldAvailableUsers = availableUsers.map(x => {return {id: x.id, name: x.name, avatarURL: x.avatarURL}}).filter(x => !groupedUserIds.some(y => y===x.id));
        setUnassignedUsers(oldAvailableUsers);
      }
    }
  },[orgGroups, availableUsers]);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} className={cls.diagramHeader} alignItems={"center"} justifyContent={"flex-end"}>
        <Grid item className={cls.buttonWrapper}>
          <Badge color={"secondary"} badgeContent={unassignedUsers.length}>
            <NormalButton
              onClick={()=>{setOrgGroupMassUsers({})}}
              variant={"primary"}
              color={colors.bildBlue}
              labelColor={colors.white}
            >
              Unassigned People
            </NormalButton>
          </Badge>
        </Grid>
        <Grid item>
          <NormalButton
            onClick={()=>{setOrgGroup({})}}
            variant={"primary"}
            color={colors.bildBlue}
            labelColor={colors.white}
          >
            Add Node
          </NormalButton>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cls.diagram}>
        {orgGroups && orgGroups.children.length > 0 && (
          <OrgGroupsDiagram
            data={orgGroups}
            legend={true}
            scalable={true}
            setOrgGroup={setOrgGroup}
            setOrgGroupUsers={setOrgGroupUsers}
          />
        )}
        {(!orgGroups || orgGroups.children.length < 1) && (
          <Grid item xs={12} className={cls.missingDiagram}>
            <BTypography variant="h5">This Organization does not have any nodes yet, add a node to get started.</BTypography>
          </Grid>
        )}
      </Grid>
      <OrgGroupEditDialog
        open={Boolean(orgGroup)}
        onClose={()=>{setOrgGroup(null)}}
        onSubmit={orgGroupUpdate}
        orgGroups={orgGroups}
        defaultOrgGroup={orgGroup}
      />
      <OrgGroupUsersEditDialog
        open={Boolean(orgGroupUsers)}
        onClose={()=>{setOrgGroupUsers(null)}}
        onSubmit={orgGroupUpdate}
        orgGroups={orgGroups}
        orgGroup={orgGroupUsers}
        availableUsers={availableUsers}
      />
      <OrgGroupMassUsersDialog
        open={Boolean(orgGroupMassUsers)}
        onClose={()=>{setOrgGroupMassUsers(null)}}
        onSubmit={orgGroupUpdate}
        orgGroups={orgGroups}
        unassignedUsers={unassignedUsers}
      />
    </Grid>
  );
}
