import { getRequest } from "../core/comm.js";

//// REVIEW
export function load(review_type, user_id, success_cb, error_cb) {
  getRequest(`/review/${review_type}/enrollments/${user_id}`, success_cb, null, error_cb);
}

export function loadUsers(review_type, organization_id, success_cb, error_cb) {
  getRequest(`/review/${review_type}/organizations/${organization_id}`, success_cb, null, error_cb);
}

export function loadOrganizations(review_type, success_cb, error_cb) {
  getRequest(`/review/${review_type}/`, success_cb, null, error_cb);
}

export function loadAllAfTrainees(success_cb, error_cb) {
  getRequest(`/review/all_trainees/`, success_cb, null, error_cb);
}

export function loadAfTrainees(success_cb, error_cb) {
  getRequest(`/review/trainees/`, success_cb, null, error_cb);
}

export function loadAfTraineeDetails(user_id, success_cb, error_cb) {
  getRequest(`/review/trainees/${user_id}`, success_cb, null, error_cb);
}

export function loadAfTrainingAssessment(training_assessment_id, success_cb, error_cb) {
  getRequest(`/review/trainees/training_assessments/${training_assessment_id}`, success_cb, null, error_cb);
}

export function loadAfTrainingModulesReview(user_id, success_cb, error_cb) {
  getRequest(`/review/trainees/${user_id}/training_modules/overview`, success_cb, null, error_cb);
}

export function loadAfTrainingModuleReview(user_id, training_module_id, success_cb, error_cb) {
  getRequest(`/review/trainees/${user_id}/training_modules/${training_module_id}`, success_cb, null, error_cb);
}

export function loadAllDelinquentValidationReviews(success_cb, error_cb) {
  getRequest(`/review/validation/delinquent`, success_cb, null, error_cb);
}

export function loadDelinquentValidationReviews(user_id, success_cb, error_cb) {
  getRequest(`/review/validation/delinquent/${user_id}`, success_cb, null, error_cb);
}

export function getUserShepherdingOverview(user_id, success_cb, error_cb) {
  getRequest(
    `/review/users/${user_id}/shepherding_overview`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getUserShepherdingTree(user_id, success_cb, error_cb) {
  getRequest(
    `/review/users/${user_id}/shepherding_tree`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}