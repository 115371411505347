import React, { useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import FileIcon from "presentational/icons/fileIcon.js";

import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";

const useStyles = makeStyles({
  root: {
    textAlign: "left",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&:hover .label-text": { textDecoration: "underline" }
  },
  label: {
    fontWeight: "normal",
    color: "#004789"
  }
});

function FileActionButton({ variant, buttonVariant, onClick, onUploadFiles, contextRoot, autoLoad, additionalFileTypes }) {
  const classes = useStyles();
  const inputRef = useRef(null);

  // Only call once after render
  useEffect(() => {
    // "Click" the button if autoload
    if (autoLoad) {
      _onClick();
    }
  }, []);

  function _onUploadFiles(e) {
    if (onUploadFiles && e.target.files) {
      onUploadFiles(e.target.files, contextRoot);
    }
  }

  let content, _onClick;
  switch (variant) {
    case "add-from-google":
      _onClick = onClick;
      content = (
        <React.Fragment>
          <FileIcon variant="add-from-google" className={classes.label} /> <span className={`${classes.label} label-text`}>Add from Google Drive</span>
        </React.Fragment>
      );
      break;
    case "add-from-dropbox":
      _onClick = onClick;
      content = (
        <React.Fragment>
          <FileIcon variant="add-from-dropbox" className={classes.label} /> <span className={`${classes.label} label-text`}>Add from Dropbox</span>
        </React.Fragment>
      );
      break;
    case "upload-file":
      _onClick = () => {
        if (inputRef.current) {
          inputRef.current.click();
        }
      };

      // Prepare list of accepted file extensions to pass to native file input element
      let fileTypes = additionalFileTypes ? ", " + additionalFileTypes : "";
      const inputProps = {
        multiple: true,
        accept:
          ".docx, .doc, .dot, .odt, .txt, .rtf, .pdf, .pptx, .ppt, .odp, .xlsx, .xls, .csv, .ods, .gif, .jpeg, .jpg, .png, .svg, .tiff " + fileTypes
      };

      content = (
        <React.Fragment>
          <label htmlFor="file-upload" className={classes.label}>
            <FileIcon variant="upload-file" /> <span className="label-text">Upload a File</span>
          </label>
          <InputBase id="file-upload" type="file" ref={inputRef} onChange={_onUploadFiles} style={{ display: "none" }} inputProps={inputProps} />
        </React.Fragment>
      );
      break;
    default:
      _onClick = onClick;
      content = (
        <React.Fragment>
          <FileIcon variant="add-folder" className={classes.label} /> <span className={`${classes.label} label-text`}>New Folder</span>
        </React.Fragment>
      );
      break;
  }

  return (
    <Button variant={buttonVariant} classes={{ root: classes.root }} onClick={_onClick}>
      {content}
    </Button>
  );
}

export default FileActionButton;
