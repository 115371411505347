import React, { useState, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";

import { Editable, Slate } from "slate-react";
import { Transforms, Text, Editor } from "slate";

import { Grid } from "@mui/material";

import { FullPageToolbar, Leaf, Element, constants, useFullPageEditor } from ".";

const useStyles = makeStyles({
  container: { height: "100%", width: "100%", backgroundColor: "verylightgrey" },
  pageContainer: { height: "100%" },
  page: {
    width: "210mm",
    minHeight: "calc(100% - 2rem)",
    margin: "1rem",
    padding: "19mm",
    fontFamily: "georgia, serif",
    backgroundColor: "white",
    display: "inline-block",
    border: "1px solid lightgrey",
    boxShadow: "1px 1px grey"
  },
  editable: { height: "100%" }
});

function useRefState(initialVal) {
  const [state, _setState] = useState(initialVal);
  const stateRef = useRef(state);
  function setState(newState) {
    stateRef.current = newState;
    _setState(newState);
  }

  return [stateRef, state, setState];
}

function FullPageEditor({ document, handle, onDocumentChange, onHandleChange }) {
  const classes = useStyles();

  const [nameRef, name, setName] = useRefState(handle.name || null);
  const [valueRef, value, setValue] = useRefState(document.value || constants.initialValue);

  const [savedRef, saved, setSaved] = useRefState(true);
  const editor = useFullPageEditor();
  editor.onValueChange = () => setSaved(false);

  function _setValue(newValue) {
    setValue(newValue);
    setSaved(false);
  }
  function _changeHandle(docHandle) {
    if (typeof onHandleChange === "function") onHandleChange(docHandle);
  }
  function _changeName(newName) {
    setName(newName);
    setSaved(false);
  }

  function _changeDocument(docObject) {
    if (typeof onDocumentChange === "function") onDocumentChange(docObject);
  }

  function _save() {
    if (!savedRef.current) {
      document.value = valueRef.current;
      _changeDocument(document);

      handle.name = nameRef.current;
      _changeHandle(handle);

      setSaved(true);
    }
  }
  useEffect(() => {
    const interval = setInterval(_save, 5000);
    return () => clearInterval(interval);
  }, []);

  const onKeyDown = (event, editor) => {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    // key shortcuts
    switch (event.key) {
      // bold
      case "b": {
        event.preventDefault();
        toggleMark(editor, "bold");
        break;
      }
      // italics
      case "i": {
        event.preventDefault();
        toggleMark(editor, "italic");
        break;
      }
      // underline
      case "u": {
        event.preventDefault();
        toggleMark(editor, "underline");
        break;
      }
      // save
      case "s": {
        event.preventDefault();
        _save();
        break;
      }
    }
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  return (
    <Slate
      editor={editor}
      value={value}
      onChange={newValue => {
        _setValue(newValue);
      }}
    >
      <Grid container className={classes.container} direction="column" wrap="nowrap">
        <Grid item>
          <FullPageToolbar name={name} marks={constants.marks} blocks={constants.blocks} saved={saved} onChangeName={_changeName} withFonts />
        </Grid>

        <Grid item xs style={{ overflow: "auto" }}>
          <Grid container justifyContent="center" className={classes.pageContainer}>
            <Grid item className={classes.page}>
              <Editable
                id="bild-editor"
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                spellCheck
                autoFocus
                className={`${classes.editable} notranslate`}
                onKeyDown={e => {
                  onKeyDown(e, editor);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slate>
  );
}

export default FullPageEditor;
