import React, { useState } from "react";
import { ClickAwayListener, Dialog, Grid, Popper } from "@mui/material";
import { BTypography, Blink, NormalButton, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import HomeLogin from "./components/homeLogin.js";

const useStyles = makeStyles({
  wrapper: props => ({
    backgroundColor: colors.white,
    height: "100vh",
    padding: `${props.isLoggedIn ? "0rem" : "0"}`
  }),
  header: props => ({
    backgroundColor: colors.white,
    height: "5rem",
    padding: `${props.isLoggedIn ? (props.isMenuMini ? "4rem 5rem 2rem 1rem" : "4rem 16.5rem 2rem 1rem") : "1rem 1rem 0.5rem 1rem"}`,
    position: "fixed",
    top: "0",
    width: "100%"
  }),
  menuHeaderMini: { height: "48px", width: "60px" },
  logoContainer: {  },
  logo: { height: "34px", width: "auto" },
  body: { background: colors.white, paddingTop: "5rem" },
  hero: { backgroundColor: colors.white, padding: "5rem 2rem", textAlign: "center", "& *": { color: colors.white } },
  subHero: { backgroundColor: colors.white, padding: "4rem 1rem", textAlign: "center", "& *": { color: colors.darkBildBlue } },
  subHeroAlt: { backgroundColor: colors.darkBildBlue, padding: "4rem 1rem", textAlign: "center", "& *": { color: colors.white } },
  heroText: { textAlign: "center", padding: "0 1rem" },
  subHeroText: { textAlign: "center", padding: "0.5rem 2rem" },
  heroList: { padding: "0", listStyle: "none" },
  linkWrapper: { padding: "5rem 2rem", textAlign: "center" },
  loginButton: { padding: "1rem 2rem" },
  loginButtonActive: { borderBottomLeftRadius: "0px !important", borderBottomRightRadius: "0px !important" },
  popperLoginWrapper: { padding: "0rem 1rem" },
  linkText: { fontWeight: "bold" },
  footer: { padding: "1rem", textAlign: "center" },
  footerLinkWrapper: { padding: "0rem 0.25rem" },
  footerLink: { padding: "0.5rem 1rem" },
  screenshot: { height: "auto", width: "auto", maxWidth: "40vw" }
});

// HOME
export default function Home({ userOverview, userPreferences, handleLogin }) {
  const isLoggedIn = Boolean(userOverview);
  const isMenuMini = isLoggedIn ? userPreferences.mini : false;
  const cls = useStyles({ isLoggedIn, isMenuMini });
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);

  function _handleLoginClick(event) {
    setAnchorEl(event.currentTarget);
    setLoginOpen(true);
  }

  return (
    <Grid container className={cls.wrapper} justifyContent="flex-start" alignItems="flex-start">
      <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"} className={cls.header}>
        <Grid item className={cls.logoContainer}>
          <img src="/bild_cloud_login_logo.png" alt="bc-login-logo" className={cls.logo} />
        </Grid>
        <Grid item className={cls.loginContainer}>
          <NormalButton
            variant="primary"
            borderColor={colors.bildBlue}
            color={colors.bildBlue}
            labelColor={colors.white}
            className={`${cls.loginButton} ${loginOpen ? cls.loginButtonActive : ""}`}
            onClick={(e)=>{_handleLoginClick(e)}}
          >
            <BTypography variant="h6" className={cls.linkText}>
              {isLoggedIn ? "View Your Dashboard" : "Log In"}
            </BTypography>
          </NormalButton>
          <Popper open={loginOpen} anchorEl={anchorEl}>
            <ClickAwayListener
              onClickAway={() => {
                setLoginOpen(false);
              }}
              mouseEvent="onMouseDown"
            >
              <Grid container item xs={12} className={cls.popperLoginWrapper}>
                <HomeLogin handleLogin={handleLogin} />
              </Grid>
            </ClickAwayListener>
          </Popper>
        </Grid>
      </Grid>
      <Grid container className={cls.body}>
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"} className={cls.subHero}>
          <Grid container item sm={6} xs={7}>
            <Grid item xs={12}>
              <BTypography variant="h3" className={cls.heroText}>
                Software Designed to Help You <br/> Train Church Leaders
              </BTypography>
            </Grid>
            <Grid item xs={12}>
              <BTypography variant="subtitle1" className={cls.subHeroText}>
              </BTypography>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={5}>
            <img src="https://cloud-production.s3.amazonaws.com/public/cloud-dashboard-screenshot_20231118.png" className={cls.screenshot} />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"} className={cls.subHeroAlt}>
          <Grid item sm={6} xs={5}>
            <img src="https://cloud-production.s3.amazonaws.com/public/cloud-program-screenshot_20231118.png" className={cls.screenshot} />
          </Grid>
          <Grid container item sm={6} xs={7}>
            <Grid item xs={12}>
              <BTypography variant="h3" className={cls.heroText}>
                Programs + Pathways
              </BTypography>
            </Grid>
            <Grid item xs={12}>
              <BTypography variant="subtitle1" className={cls.subHeroText}>
                <ul className={cls.heroList}>
                  <li>Choose from multiple levels of ordered learning programs designed for a variety of individuals from leaders of networks all the way to individuals within a church community.</li>
                </ul>
              </BTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"} className={cls.subHero}>
          <Grid container item sm={6} xs={7}>
            <Grid item xs={12}>
              <BTypography variant="h3" className={cls.heroText}>
                Create Anyway You Like
              </BTypography>
            </Grid>
            <Grid item xs={12}>
              <BTypography variant="subtitle1" className={cls.subHeroText}>
                <ul className={cls.heroList}>
                  <li>Create within BILD Cloud, in Google Drive, or on your own device.</li>
                  <li>Import from Google Drive, Dropbox, or your device.</li>
                  <li>Manage your files by storing and organizing your artifacts in your own folio within BILD Cloud.</li>
                </ul>
              </BTypography>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={5}>
            <img src="https://cloud-production.s3.amazonaws.com/public/cloud-create-screenshot_20231118.png" className={cls.screenshot} />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"} className={cls.subHeroAlt}>
          <Grid item sm={6} xs={5}>
            <img src="https://cloud-production.s3.amazonaws.com/public/cloud-user-management-and-chat-screenshot_20231118.png" className={cls.screenshot} />
          </Grid>
          <Grid container item sm={6} xs={7}>
            <Grid item xs={12}>
              <BTypography variant="h3" className={cls.heroText}>
                Engage + Assess
              </BTypography>
            </Grid>
            <Grid item xs={12}>
              <BTypography variant="subtitle1" className={cls.subHeroText}>
                <ul className={cls.heroList}>
                  <li>Manage your leaders through tracking, assessing, and communicating with them about their work and/or development happening within their network.</li>
                  <li>Provide acknowledgment and certification upon completion of programs.</li>
                </ul>
              </BTypography>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid container item xs={12} className={cls.footer} justifyContent="center" alignItems="center">
          <Grid item className={cls.footerLinkWrapper}>
            <NormalButton
              className={cls.footerLink}
              dst="policy"
              policy="privacy_policy"
              variant="secondary"
              color={colors.white}
              labelColor={colors.darkerGray}
              hoverLabelColor={colors.black}
            >
              Privacy Policy
            </NormalButton>
          </Grid>
          <Grid item className={cls.footerLinkWrapper}>
            <NormalButton
              className={cls.footerLink}
              dst="policy"
              policy="terms_of_use"
              variant="secondary"
              color={colors.white}
              labelColor={colors.darkerGray}
              hoverLabelColor={colors.black}
            >
              Terms of Service
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
