import React, { useState, useEffect } from "react";
import { access } from "bild-utils";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";

import { Paper, Typography, Grid, Divider, Icon, Hidden } from "@mui/material";

import Competency from "../competency/competency.js";
import SwitchCompetencySetButton from "./switchCompetencySetFlow/switchCompetencySetButton.js";
import CompetencyProgressCircles from "./parts/competencyProgressCircles.js";
import CompetencyProofRoles from "./parts/competencyProofRoles.js";

import { courseData, ENUMS } from "bild-data";
import { toaster } from "presentational/toasts/toasts.js";

const useStyles = makeStyles({
  container: { minHeight: "100%", padding: "1rem" },
  course: { marginBottom: "1rem", "& button:disabled": { opacity: "0.5" } },
  courseHeader: { color: "#fff", padding: "10px 15px", borderTopRightRadius: "4px", borderTopLeftRadius: "4px", fontSize: "18px" },
  courseNumber: { color: "#fff" },
  familyIcon: { textAlign: "right", marginLeft: "15px" },
  courseBody: { padding: "1rem" },
  courseDescription: { marginBottom: "1rem" },
  competencySet: { textAlign: "right" },
  competenciesHeader: { margin: "1rem 0", padding: "0 1rem" },
  competenciesHeaderTitle: {
    // Trickery to respect different alignments set by Nate's mockup.
    position: "relative",
    left: "-1rem",
    display: "flex",
    alignItems: "center"
  },
  checkInCircle: {
    // this could all just be replaced by a svg or something
    marginRight: "0.5rem",
    textAlign: "center",
    lineHeight: "50px",
    verticalAlign: "center",
    fontSize: "29px",
    height: "50px",
    width: "50px",
    borderRadius: "50vh",
    border: "2px solid black"
  },
  rolesContainer: {},
  programInfo: { marginBottom: "1rem" },
  breadcrumb: { display: "flex", alignItems: "center" },
  breadcrumbCaret: { fontSize: "14px", margin: "5px" }
});

function prepareCourseData(data) {
  const {
    id,
    courseNumber,
    name,
    description,
    competencySet,
    callingUserRoles,
    enrollmentId,
    assessmentLevelId,
    competencies,
    localMentorUser,
    associateFacultyUser,

    color,
    icon,
    studentId,
    studentName
  } = {
    ...(data || {}),
    color: access(data, "courseGroupColor.webFrontendValue", ""),
    icon: access(data, "familyGroupIcon.webFrontendValue", ""),
    studentId: access(data, "competencies[0].competencyProof.user.id"), // fetch if in data
    studentName: access(data, "competencies[0].competencyProof.user.name") // fetch if in data
  };

  return {
    id,
    courseNumber,
    name,
    description,
    competencySet,
    callingUserRoles,
    enrollmentId,
    assessmentLevelId,
    competencies,
    localMentorUser,
    associateFacultyUser,

    color,
    icon,
    studentId,
    studentName
  };
}

function Course({ course: initialCourse, callingUser, docId, setDocId, fileLoading, enrollmentId, scrollToCompetencyProofId }) {
  const classes = useStyles();

  const [course, _internalSetCourse] = useState(prepareCourseData(initialCourse));
  const setCourse = c => _internalSetCourse(prepareCourseData(c));

  const [competencySets, setCompetencySets] = useState([]);

  useEffect(() => {
    // Load competency sets from API
    courseData.loadCompetencySets(course.id, data => {
      setCompetencySets(data.competencySets);
    });
  }, [course.id]);

  function switchCompetencySet(newId) {
    // Get the new competency set
    const newCompetencySet = competencySets.find(cs => String(cs.id) === String(newId));

    // Set the data for the API update call
    const data = {
      id: newCompetencySet.id,
      name: newCompetencySet.name,
      handbookId: newCompetencySet.handbookId,
      handbookName: newCompetencySet.handbookName,
      priorCompetencySetId: course.competencySet.id
    };

    // Make the api update call and set the state from the returned data
    courseData.switchCompetencySet(course.id, data, result => {
      setCourse(result);
      toaster.success("Competency Set successfully changed.");
    });
  }

  // TODO: Antioch FE ?? -- Get degree info on course page (seperate call)
  const {
    studentName: nameFromData,
    studentId: idFromData,
    competencies,
    courseNumber,
    name,
    description,
    competencySet,
    callingUserRoles,
    color,
    icon
  } = course || {};

  const [studentName, setStudentName] = useState(nameFromData);
  const [studentId, setStudentId] = useState(idFromData);
  const isStudent = callingUserRoles.some(r => r.id === ENUMS.ROLES.STUDENT.ID);
  // If no competencyProofs exist, fetch enrollment and grab student name, not needed if student.
  if (!isStudent && !studentName && !!enrollmentId) {
    courseData.loadEnrollmentDashboard(enrollmentId, data => {
      setStudentId(data.user.id);
      setStudentName(data.user.name);
    });
  }

  const competencyComponents = course.competencies.map((c, i) => (
    <Competency
      course={course}
      competency={c}
      enrollmentId={enrollmentId}
      studentId={studentId}
      callingUser={callingUser}
      docId={docId}
      setDocId={setDocId}
      fileLoading={fileLoading}
      scrollTo={scrollToCompetencyProofId && c.competencyProof && c.competencyProof.id.toString() === scrollToCompetencyProofId.toString()}
      key={i}
    />
  ));

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.programInfo}>
        <Grid item>
          <Typography variant="h5">{!isStudent && studentName}</Typography>
        </Grid>
        <Grid item>
          <img alt="School Logo" crop="fill" height="51" src={process.env.PUBLIC_URL + "/antioch_school_logo.png"} width="217" />
        </Grid>
      </Grid>

      <Paper className={classes.course} key={1}>
        <Grid container justifyContent="space-between" className={classes.courseHeader} style={{ backgroundColor: color }}>
          <Grid item>
            <CompetencyProgressCircles competencies={competencies} />
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.courseNumber}>
              {courseNumber}
              <span className={classes.familyIcon}>
                <i className={"fas " + icon} />
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <div className={classes.courseBody}>
          <Grid container>
            <Grid item xs className={classes.courseDescription}>
              <Typography variant="h5" gutterBottom style={{ color: color }}>
                {ReactHtmlParser(name)}
              </Typography>

              <Typography variant="body2">{ReactHtmlParser(description)}</Typography>
            </Grid>

            <Grid item className={classes.competencySet}>
              <SwitchCompetencySetButton
                groupColor={color}
                competencySetId={competencySet.id}
                competencySets={competencySets}
                onSwitch={switchCompetencySet}
              />
            </Grid>
          </Grid>

          <Divider />

          <div className={classes.competenciesHeader}>
            <Grid container alignItems="center">
              <Grid item lg={9} md={8} sm={7} xs={12}>
                <Typography variant="h6" className={classes.competenciesHeaderTitle} style={{ color: color }}>
                  <Icon className={`fal fa-check ${classes.checkInCircle}`} style={{ color: color, borderColor: color }} />
                  Instructions
                </Typography>
              </Grid>
              <Hidden smDown>
                <Grid item lg={3} md={4} sm={5} xs={12}>
                  <CompetencyProofRoles {...course} />
                </Grid>
              </Hidden>
            </Grid>
          </div>

          {competencyComponents}
        </div>
      </Paper>
    </div>
  );
}

export default Course;
