import React from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import PrivacyPolicy from "./privacyPolicy.js";
import TermsOfUsePolicy from "./termsOfUsePolicy.js";

// POLICIES LOADER
function PoliciesLoader({}) {
  const { policy } = useParams();

  let content = <div>&nbsp;</div>;

  if (policy) {
    if (policy === "privacy_policy") {
      setPageTitle("Privacy Policy");
      content = <PrivacyPolicy />;
    } else if (policy === "terms_of_use") {
      setPageTitle("Terms of Use");
      content = <TermsOfUsePolicy />;
    }
  }

  return content;
}

export default PoliciesLoader;
