import React from "react";
import { Blink, colors, NormalButton } from "bild-ui";
import { openIntercom } from "bild-utils";

import LoadingScreen from "presentational/screens/loadingScreen.js";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import "./contract.css";

function Contract({ open, isLoading, closeDialog, contractStatus, message, classes, ...props }) {
  const supportRow = (
    <Grid item xs={12} className="support-link-row">
      <Grid container>
        <Grid item xs={6}>
          <Blink href={"https://intercom.help/bild-international/en/articles/3383101-enrollment-agreement"} target="_blank">
            <NormalButton variant="simple" className="default-link">What is this? Learn more</NormalButton>
          </Blink>
        </Grid>
        <Grid item xs={6}>
          <NormalButton variant="simple" className="default-link" onClick={openIntercom}>
            Contact us
          </NormalButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown>
      <DialogContent>
        <React.Fragment>
          <Grid container justifyContent="center" alignItems="center" className="contract">
            <Grid item xs={12}>
              {isLoading && <LoadingScreen />}

              {!isLoading && !message && (
                <Grid container justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h3" className="contract-title">
                      Enrollment Agreement
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      As a student, you are expected to enter into an enrollment agreement with the
                      <br /> Antioch School to maintain continuous enrollment in your program.
                    </Typography>
                    {contractStatus.skippedCount < contractStatus.maxSkipsAllowed && (
                      <React.Fragment>
                        <br />
                        <Typography variant="body2">
                          You may skip signing your enrollment agreement <b>{contractStatus.maxSkipsAllowed - contractStatus.skippedCount}</b> more
                          times.
                        </Typography>
                      </React.Fragment>
                    )}
                  </Grid>
                  {supportRow}
                  <Grid item xs={4}>
                    <NormalButton
                      variant="primary"
                      color={colors.bildBlue}
                      hoverColor={colors.darkBildBlue}
                      labelColor={colors.white}
                      className="contract-button"
                      component={Blink}
                      href={contractStatus.signingURL}
                    >
                      Start Signing
                    </NormalButton>
                  </Grid>
                  {contractStatus.skippedCount < contractStatus.maxSkipsAllowed && (
                    <Grid item xs={4}>
                      <NormalButton
                        variant="secondary"
                        color={colors.white}
                        borderColor={colors.gray}
                        hoverColor={colors.gray}
                        labelColor={colors.black}
                        hoverLabelColor={colors.black}
                        className="contract-button"
                        onClick={closeDialog}
                      >
                        Skip for now (<b className="skip-remaining-count">{contractStatus.maxSkipsAllowed - contractStatus.skippedCount}&nbsp;</b> Remaining)
                      </NormalButton>
                    </Grid>
                  )}
                </Grid>
              )}

              {!isLoading && message && message === "success" && (
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h2" className="confirm-success">
                      <i className={"fal fa-check-circle"} />
                    </Typography>
                    <Typography variant="h4">Enrollment Agreement successfully signed!</Typography>
                    <br />
                    <Typography variant="body2">
                      Our Antioch School staff will review your signed enrollment agreement and notify you of the school’s endorsement.
                    </Typography>
                    <br />
                  </Grid>
                  <Grid item xs={4}>
                    <Blink href={"/"}>
                      <Button className="default-submit-button contract-button">Return to Dashboard</Button>
                    </Blink>
                  </Grid>
                </Grid>
              )}

              {!isLoading && message && message === "failure" && (
                <Grid container justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h2" className="confirm-warning">
                      <i className={"fas fa-exclamation-triangle"} />
                    </Typography>
                    <Typography variant="h4">Enrollment agreement not signed</Typography>
                    <br />
                    <Typography variant="body2">It seems you have not signed an enrollment agreement.</Typography>
                    <br />
                    <Typography variant="body2">
                      Please contact us if you have questions or have trouble completing the signing process.
                      <br />
                      If you do not sign an enrollment agreement, this may result in you being placed on probation.
                    </Typography>
                  </Grid>
                  {supportRow}
                  <Grid item xs={4}>
                    <Blink href={"/"}>
                      <Button className="default-submit-button contract-button">Retry</Button>
                    </Blink>
                  </Grid>
                  <Grid item xs={4}>
                    <Blink href={"/"}>
                      <Button className="default-cancel-button contract-button">Return to Dashboard</Button>
                    </Blink>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      </DialogContent>
    </Dialog>
  );
}

export default Contract;
