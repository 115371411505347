import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { ENUMS } from "bild-data";
import { Typography, Button, Popover, Grid } from "@mui/material";

// RUBRIC ROW
function Row({ id, score, criterion, header, trainingCriteria, userCanAssess, hideScores, isThreeGrid, onChange, onClear }) {
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // This function ensures that badges are properly sorted timewise and marks them as either "current"
  // or "old" in order to apply faded colors correctly.
  // There is one problem with this implementation: scores are being provided one the criterion that
  // they belong to. This means the sorting is isolated to one criterion, and may create errors in
  // display on optional criteria where they are graded once but then dropped for another one. (Old
  // score would still be displayed as new.)
  // TODO: In the future the backend should take care or marking scores.
  useEffect(()=>{
    if (criterion && criterion.scores) {
      const scores = criterion.scores;
      scores.sort((s1, s2) => {
        if (s1.createdAt < s2.createdAt) {
          return -1;
        } else if (s1.createdAt > s2.createdAt) {
          return 1;
        } else {
          return 0;
        }
      });

      let stclflag = false;
      let stflag = false;
      let clflag = false;
      let afflag = false;
      for (let i = scores.length - 1; i >= 0; i--) {
        const s = scores[i];
        if (s.role) {
          switch (s.role.prefix.toUpperCase()) {
            case ENUMS.ROLES.STUDENT_LOCAL_MENTOR.PREFIX:
              if (stclflag === false) {
                s.current = true;
                stclflag = true;
              } else {
                s.current = false;
              }
              break;
            case ENUMS.ROLES.STUDENT.PREFIX:
              if (stflag === false) {
                s.current = true;
                stflag = true;
              } else {
                s.current = false;
              }
              break;
            case ENUMS.ROLES.LOCAL_MENTOR.PREFIX:
              if (clflag === false) {
                s.current = true;
                clflag = true;
              } else {
                s.current = false;
              }
              break;
            case ENUMS.ROLES.ASSOCIATE_FACULTY.PREFIX:
            case ENUMS.ROLES.SIMA_STAFF.PREFIX:
            case ENUMS.ROLES.SIMA_REVIEWER.PREFIX:
              if (afflag === false) {
                s.current = true;
                afflag = true;
              } else {
                s.current = false;
              }
              break;
            default:
              // skip
              break;
          }
        }
      }
    }
  }, []);

  // Build the tags with the assessors' prefixes if correct score.
  function getBadges(scores, val, isThreeGrid) {
    if (!scores) {
      return [];
    }

    // Normal Badges
    const stBadges = [];
    const clBadges = [];
    const afBadges = [];
    for (var i = 0; i < scores.length; i++) {
      const s = scores[i];
      if (s.scoreValue === val || (isThreeGrid && val === 3 && s.scoreValue === 2) || (isThreeGrid && val === 4 && s.scoreValue === 5)) {
        let badgeClass = s.role.prefix + " badge ";
        if (
          s.role.prefix.toUpperCase() === ENUMS.ROLES.STUDENT_LOCAL_MENTOR.PREFIX ||
          s.role.prefix.toUpperCase() === ENUMS.ROLES.SIMA_REVIEWER.PREFIX
        ) {
          badgeClass += " double-badge ";
        }
        if (s.current === true || s.current === null || s.current === undefined) {
          badgeClass += " current";
        } else {
          badgeClass += " old";
        }
        const badge = (
          <Typography className={badgeClass} key={i}>
            {s.role.prefix}
          </Typography>
        );

        switch (s.role.prefix.toUpperCase()) {
          case ENUMS.ROLES.STUDENT_LOCAL_MENTOR.PREFIX:
          case ENUMS.ROLES.STUDENT.PREFIX:
            stBadges.push(badge);
            break;
          case ENUMS.ROLES.LOCAL_MENTOR.PREFIX:
            clBadges.push(badge);
            break;
          case ENUMS.ROLES.ASSOCIATE_FACULTY.PREFIX:
          case ENUMS.ROLES.SIMA_STAFF.PREFIX:
          case ENUMS.ROLES.SIMA_REVIEWER.PREFIX:
            afBadges.push(badge);
            break;
          default:
            // skip
            break;
        }
      }
    }

    // AF Training
    const aftBadges = [];
    const afcBadges = [];
    const trainingScores = trainingCriteria.scores || null;
    if (trainingScores) {
      for (var ti = 0; ti < trainingScores.length; ti++) {
        const ts = trainingScores[ti];
        if (ts.scoreValue === val || (isThreeGrid && val === 3 && ts.scoreValue === 2) || (isThreeGrid && val === 4 && ts.scoreValue === 5)) {
          let badgeClass = ts.role.prefix + " badge double-badge current ";
          const badge = (
            <Typography className={badgeClass} key={ti}>
              {ts.role.prefix}
            </Typography>
          );

          switch (ts.role.prefix.toUpperCase()) {
            case ENUMS.ROLES.AF_TRAINEE.PREFIX:
              aftBadges.push(badge);
              break;
            case ENUMS.ROLES.AF_COACH.PREFIX:
              afcBadges.push(badge);
              break;
            default:
            // Do Nothing
          }
        }
      }
    }

    return (
      <div className="assessment-badges">
        <span className="st-badges badge-pile">{stBadges}</span>
        <span className="cl-badges badge-pile">{clBadges}</span>
        <span className="af-badges badge-pile">{afBadges}</span>
        <span className="aft-badges badge-pile">{aftBadges}</span>
        <span className="afc-badges badge-pile">{afcBadges}</span>
      </div>
    );
  }

  function getFields() {
    const xs = isThreeGrid ? 3 : 2;
    const fields = [];
    const values = isThreeGrid ? [1, 3, 4] : [1, 2, 3, 4, 5];
    for (let v = 0, i = values[v]; v < values.length; i = values[++v]) {
      const inputId = id + "-" + i;
      const checked = score === i;
      fields.push(
        <Grid item xs={xs} className={"rubric-cell value" + i + (checked && !hideScores ? " checked" : "")} key={i}>
          <input
            type="radio"
            value={i}
            name={id}
            data-criterion-id={criterion.id}
            id={inputId}
            onChange={onChange}
            checked={checked}
            disabled={!userCanAssess}
          />
          <label disabled={!userCanAssess} htmlFor={inputId}>
            {hideScores ? null : getBadges(criterion.scores, i, isThreeGrid)}
          </label>
          <i className="fas fa-times" onClick={onClear} data-criterion-id={criterion.id} />
        </Grid>
      );
    }

    return fields;
  }

  const xs = isThreeGrid ? 3 : 2;

  if (header === true) {
    const headerValues = isThreeGrid
      ? [
          <span>
            Does Not Meet
            <span className="sub-header">(Needs Significant Development)</span>
          </span>,
          <span>
            Partially Meets
            <span className="sub-header">(Needs Minor Development)</span>
          </span>,
          <span>
            Meets Criteria
            <span className="sub-header">(Well Developed)</span>
          </span>
        ]
      : [
          <span>1 - No Demonstration</span>,
          <span>2 - Insufficient</span>,
          <span>3 - Marginal</span>,
          <span>4 - Sufficient</span>,
          <span>5 - Exceptional</span>
        ];
    const components = [];
    for (let i = 0; i < headerValues.length; i++) {
      components.push(
        <Grid item xs={xs} className="rubric-header-cell" key={i}>
          <Typography variant="subtitle1" className="rubric-title" align="center">
            {headerValues[i]}
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item container className="rubric-row">
        <Grid item xs={xs} className="rubric-header-cell" />
        {components}
      </Grid>
    );

  } else {
    return (
      <Grid item container className="rubric-row">
        <Grid item xs={xs} className="rubric-criterion-name-cell">
          <Typography variant="subtitle1" className="rubric-title criterion-name">
            {criterion.name}
          </Typography>
          <Button
            ref={node => {
              setAnchorEl(node);
            }}
            onClick={() => {
              setDescriptionOpen(!descriptionOpen);
            }}
            className="rubric-subtitle link-button"
          >
            Learn More
            <span className="rubric-subtitle-info">
              <i className="fal fa-info-circle" />
            </span>
          </Button>
  
          <Popover
            className="criterion-description-popover-container"
            open={descriptionOpen === true}
            anchorEl={anchorEl}
            onClose={() => {
              setDescriptionOpen(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Typography className="criterion-description-popover" variant="body2">
              {ReactHtmlParser(criterion.description)}
            </Typography>
          </Popover>
        </Grid>
  
        {getFields()}
      </Grid>
    );
  }
  
}

export default Row;
