import React from "react";
import { colors, LineChartBuilder, List } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
});

export default function UserFileActivity({ data }) {
  const cls = useStyles();

  function makeModule(content) {
    return (
      <Grid container item xs={12} className={cls.module}>
        {content}
      </Grid>
    );
  }

  function getFileTotals() {
    let d = data.fileTotals;
    let rows = [
      ["Total Files:", d.totalFiles.toLocaleString()],
      ["Updated (Last 7 Days):", d.updatedFilesLastWeek.toLocaleString()],
      ["Updated (Last 30 Days):", d.updatedFilesLastMonth.toLocaleString()]
    ];

    let d2 = data.fileHistoryTotals;
    const tod = new Date();
    let month = tod.getMonth();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"];

    let chartData = [];
    for (let i = 5; i > -1; i--) {
      let mon = months[month - i < 1 ? month - i + 12 : month - i];
      let per = i === 0 ? "totalFileCount" : "previousPeriod" + i + "FileCount";
      chartData.push({ name: mon, uv: d2[per], color: colors.orange, uvName: "Files" });
    }

    return (
      <Grid container item xs={12}>
        <LineChartBuilder data={chartData} fillColor={colors.bildBlue} xAxis="name" />
        <Grid item xs={12} className={cls.scrollList}>
          <List items={rows} itemPadding={"0.25rem"} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        {data && makeModule(getFileTotals())}
      </Grid>
    </Grid>
  );
}
