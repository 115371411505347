import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import FileDropZone from "bild-utils/dragDrop/fileDropZone.js";
import FileActionButton from "./fileActionButton.js";
import FolioDisplayItem from "./folioDisplayItem.js";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  container: { height: "100%", display: "flex", flexDirection: "column" },
  dropZone: { flex: "1 1 auto", margin: "0 0.5rem" },
  dropZoneButton: { textAlign: "center" },
  dropZoneText: { paddingTop: "5px", paddingBottom: "20px" }
});

function DropZoneDialogContent({ contextRoot, onSelect, oldArtifacts, removeArtifact, onClose, autoLoad }) {
  const classes = useStyles();
  const [files, setFiles] = useState(new Map());

  function _addFiles(newFiles) {
    [...newFiles].forEach(f => files.set(f.name, f));
    setFiles(new Map(files));

    _submit();
  }

  function _deleteFunc(name) {
    return () => {
      files.delete(name);
      setFiles(new Map(files));
    };
  }

  // TODO decide if this should be merged with the diskAPI.js uploadFile function
  function _loadFile(file) {
    // Return a promise that the file will be loaded and returned
    return new Promise(resolve => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result.split(",")[1];
        let loadedFile = {
          name: file.name,
          folderId: contextRoot ? contextRoot.id : null,
          contentType: file.type,
          fileSizeBytes: file.bytes,
          base64EncodedFile: result
        };
        resolve(loadedFile);
      };
    });
  }

  function _submit() {
    if (onSelect && files.size > 0) {
      let artifacts = Array.from(files.values());
      var promises = [];

      // Load each native "File" and convert it into a format we can send to the server
      for (var i = 0; i < artifacts.length; i++) {
        let a = artifacts[i];
        // Each file may take a while, so promise to return it :)
        promises.push(_loadFile(a));
      }

      // Once all files have been created, send them on to the server
      Promise.all(promises)
        .then(result => {
          let allFiles = result;
          if (oldArtifacts) {
            allFiles = oldArtifacts.concat(result);
          }
          onSelect(allFiles);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  return (
    <div className={classes.container}>
      <DialogTitle>Upload Files:</DialogTitle>
      <FileDropZone onUploadIntent={_addFiles} contextRoot={contextRoot} className={classes.dropZone}>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.dropZoneButton}>
              <FileActionButton onUploadFiles={_addFiles} variant="upload-file" buttonVariant="outlined" autoLoad={autoLoad} />
              <div className={classes.dropZoneText}>
                <div>or</div>
                <div>Drop Files to Upload</div>
              </div>
            </Grid>
          </Grid>
          {[...files.keys()].map((name, id) => (
            <div key={name}>
              <FolioDisplayItem variant="chip" type="file" name={name} onDelete={_deleteFunc(name)} />
            </div>
          ))}
          {oldArtifacts &&
            [...oldArtifacts].map(item => (
              <div key={item.name}>
                <FolioDisplayItem
                  variant="chip"
                  type="file"
                  name={item.name}
                  onDelete={() => {
                    removeArtifact(item.name);
                  }}
                />
              </div>
            ))}
        </DialogContent>
      </FileDropZone>
    </div>
  );
}

export default DropZoneDialogContent;
