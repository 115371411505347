import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle, hideIntercom, updateURLWithoutLoad, getPath, refreshPage } from "bild-utils";
import { toaster } from "presentational/toasts/toasts.js";
import { LoadingScreen } from "bild-ui";
import { managerData, organizationsData, userData } from "bild-data";
import { loadAdminOrganization } from "bild-data/admin";
import OrganizationAdmin from "containers/admin/organizationAdmin.js";
import queryString from "query-string";

function OrganizationAdminLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organization, setOrganization] = useState({});
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [orgTree, setOrgTree] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [antiochOrg, setAntiochOrg] = useState({});
  const [subOrgs, setSubOrgs] = useState([]);
  const [subRoles, setSubRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [inheritedUserRoles, setInheritedUserRoles] = useState([]);
  const [defaultTab, setDefaultTab] = useState(1);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      setDefaultTab(parseInt(searchParams.t));
    }
    function _setData(d) {
      setPageTitle(" Admin | " + d.organizationName);
      hideIntercom();
      setOrganization(d);
      setOrgTree(d.organizationRelationship.children);
      setUserRoles(d.organizationRoles);
      setInheritedUserRoles(d.inheritedOrganizationRoles);
      setLoading(false);
    }

    function _setSubData(m) {
      setSubOrgs(m.availableOrganizations);
      setSubRoles(m.availableRoles);
    }

    function _setAllOrgs(d) {
      let orgs = d.organizations.map(x => {
        return { id: x.id, name: x.name, localEntityTypeId: x.localEntityType ? x.localEntityType.id : 7 };
      });
      setAllOrganizations(orgs);
    }

    function _setLoadError() {
      setError({"errorCode": 422, "displayMessage": "Organization not found (it may have been recently deleted)."});
    }

    loadAdminOrganization(organization_id, _setData, _setLoadError);
    managerData.getManagedOrganizationUserCertificates(organization_id, d => {
      setCertificates(d.certificates);
    });
    organizationsData.loadOrganization(organization_id, d => {
      setAntiochOrg(d);
    });
    managerData.getOrganizationManagedUsers(organization_id, _setSubData);
    organizationsData.loadCombinedOrganizations(_setAllOrgs);
  }, []);

  useEffect(()=>{
    if (defaultTab && defaultTab < 7) {
      updateURLWithoutLoad(getPath() + "?t=" + defaultTab);
    }
  },[defaultTab])

  function updateOrganization(data) {
    function _setData(d) {
      setOrganization(d);
      setOrgTree(d.organizationRelationship.children);
      toaster.success(`Organization Updated`);
    }

    managerData.updateManagedOrganization(organization_id, data, _setData);
  }

  function resendEmail(email, success_cb, error_cb) {
    userData.forgotPassword(email, success_cb, error_cb);
  }

  function archiveUser(userId, orgId, success_cb, error_cb) {
    function _success_cb(data) {
      managerData.getManagedUsers(
      (m) => {
        setOrganization({...organization, managedUsers: m.users.sort((a, b) => a.name.localeCompare(b.name))});
        toaster.success("User successfully archived.");
      }, e => {
        setError(e.response.data.error);
      });

      if (typeof success_cb === "function") success_cb(data);
    }

    let data = {
      id: userId,
      organizationIdsToArchiveRelationship: [ orgId ]
    };
    managerData.updateManagedUser(userId, data, _success_cb, error_cb);
  }
  
  function deleteOrganization(newParentOrgId) {
    let sendData = {"organizationsToRemove": [{"organizationId": organization_id, "parentOrganization": {"organizationId": newParentOrgId}}]};

    managerData.deleteManagedOrganizations(
      sendData,
      ()=>{
        toaster.success(`Organization (${organization.organizationName}) successfully deleted.`);
        refreshPage(true);
      }
    );
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <OrganizationAdmin
      organization={organization}
      updateOrganization={updateOrganization}
      orgTree={orgTree}
      subOrgs={subOrgs}
      subRoles={subRoles}
      userRoles={userRoles}
      inheritedUserRoles={inheritedUserRoles}
      certificates={certificates}
      antiochOrg={antiochOrg}
      setAntiochOrg={setAntiochOrg}
      resendEmail={resendEmail}
      archiveUser={archiveUser}
      defaultTab={defaultTab}
      setDefaultTab={setDefaultTab}
      allOrganizations={allOrganizations}
      deleteOrganization={deleteOrganization}
    />
  );
}

export default OrganizationAdminLoader;
