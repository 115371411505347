import React from "react";
import { colors, Glower } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  badgeWrapper: props => ({}),
  badge: props => ({
    height: "100%",
    width: "100%",
    maxHeight: "4.5rem",
    maxWidth: "max-content"
  }),
  completed: {},
  incomplete: { opacity: "0.75", filter: "brightness(85%)" }
});

function ProgramBadge({ color, badgeURL, completed }) {
  const cls = useStyles({ color });

  return (
    <Glower glowOn={completed} color={color}>
      <Grid container className={`${cls.badgeWrapper} ${completed ? cls.completed : cls.incomplete}`} justifyContent="center" alignItems="center">
        <img src={badgeURL} className={cls.badge} />
      </Grid>
    </Glower>
  );
}

export default ProgramBadge;
