import React, { useState, useEffect } from "react";
import { diskData, partnersData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { downloadItem, uploadCivilizationPartnerFiles } from "bild-data/disk";
import { useParams } from "react-router-dom";
import NetworkPartnerCivilization from "./networkPartnerCivilization";
import { toaster } from "presentational/toasts/toasts";

export default function NetworkPartnerCivlizationLoader({}) {
  const { civilization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [civilization, setCivilization] = useState([]);
  const [partners, setPartners] = useState([]);
  const [disk, setDisk] = useState(null);
  const [previewDocId, setPreviewDocId] = useState(null);
  const [previewDocLoading, setPreviewDocLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);

  const _uploadFiles = (files, destination) => uploadCivilizationPartnerFiles(files, destination, civilization_id, setDisk);

  useEffect(() => {
    setPageTitle("Civilization");
    partnersData.getNetworkPartnerCivilization(civilization_id, _setData);
    diskData.loadNetworkPartnerCivilizationDisk(civilization_id, setDisk);
  }, []);

  function _setData(d) {
    setCivilization(d);
    setPartners(d.networkPartners);
    setLoading(false);
  }

  function _setDocId(id) {
    setPreviewDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setPreviewDocLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setPreviewDocLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setPreviewDocLoading(false);
        }
      );
    }
  }

  function onPreviewIntent(item) {
    if (item) {
      if (item.raw.inAppLocalId) {
        _setDocId(item.raw.inAppLocalId);
      } else {
        setPreviewFile(item);
      }
    } else {
      setPreviewDocId(null);
      setPreviewFile(null);
    }
  }

  function _createFolder() {
    toaster.error("No folder creation for Civilizations.")
  }

  function updateCivilization(d) {
    let sendData = d;
    sendData.id = civilization_id;
    partnersData.createUpdateCivilizationPartner(sendData,
      ()=>{
        partnersData.getNetworkPartnerCivilization(civilization_id, _setData);
        toaster.success("Civilization Updated!")
      }
    );
  }

  if ((loading) || error) return <LoadingScreen error={error} />;
  return (
    <NetworkPartnerCivilization
      civilization={civilization}
      partners={partners}
      updateCivilization={updateCivilization}
      disk={disk}
      onCreateFolder={_createFolder}
      onUploadFiles={_uploadFiles}
      onDownloadItem={downloadItem}
      onPreviewIntent={onPreviewIntent}
      previewDocId={previewDocId}
      previewDocLoading={previewDocLoading}
      previewFile={previewFile}  
    />
  );
}
