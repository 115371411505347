class Toaster {
  constructor() {
    this.toastTypes = { SUCCESS: "success", ERROR: "error", WARNING: "warning", INFO: "info" };
    this.events = {};

    this.on = this.on.bind(this);
    this.dispatch = this.dispatch.bind(this);
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    this.warning = this.warning.bind(this);
    this.info = this.info.bind(this);
  }

  on(toastType, callback) {
    if (!this.events[toastType]) {
      this.events[toastType] = [];
    }

    this.events[toastType].push(callback);
  }

  dispatch(toastType, message) {
    const callbacks = this.events[toastType];
    if (callbacks) {
      for (let i = 0; i < callbacks.length; i++) {
        const c = callbacks[i];
        c(message);
      }
    }
  }

  success(message) {
    this.dispatch(this.toastTypes.SUCCESS, message);
  }

  error(message) {
    this.dispatch(this.toastTypes.ERROR, message);
  }

  warning(message) {
    this.dispatch(this.toastTypes.WARNING, message);
  }

  info(message) {
    this.dispatch(this.toastTypes.INFO, message);
  }
}

const toaster = new Toaster();

export default toaster;
