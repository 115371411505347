import React from "react";
import { colors, Blink, BTypography, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";
import UserLearningItemSegment from "../../user-learning-item/components/userLearningItemSegment.js";

function AssociatedResource({ userLearningItem, color }) {
  return (
    <UserLearningItemSegment icon="fal fa-book fa-2x" title="Associated Resources" color={color}>
      <Grid container>
        {userLearningItem.resourceId && (
          <Grid item xs={12}>
            <NormalButton
              variant="simple"
              labelColor={colors.lightBlue}
              hoverLabelColor={colors.lightBlue}
              component={Blink}
              dst="library"
              book_id={userLearningItem.resourceId}
              page_number={userLearningItem.resourcePage}
              target="_blank"
            >
              <BTypography variant="body1">{userLearningItem.resourceDescription}</BTypography>
            </NormalButton>
          </Grid>
        )}
        {!userLearningItem.resourceId && (
          <Grid item xs={12}>
            <BTypography variant="body1">{userLearningItem.resourceDescription}</BTypography>
          </Grid>
        )}
        {userLearningItem.additionalResources &&
          JSON.parse(userLearningItem.additionalResources).map(x => (
            <Grid item xs={12} key={x.name}>
              <NormalButton variant="simple" labelColor={colors.lightBlue} hoverLabelColor={colors.lightBlue} component={Blink} href={x.url} target="_blank">
                <BTypography variant="body1">{x.name}</BTypography>
              </NormalButton>
            </Grid>
          ))}
      </Grid>
    </UserLearningItemSegment>
  );
}

export default AssociatedResource;
