import React from "react";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  item: { overflowWrap: "normal", paddingRight: "0.25rem", overflow: "hidden", textOverflow: "ellipsis" }
});

function ListItem({ columns, spacing }) {
  const cls = useStyles();
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {columns.map((c, i) => (
        <Grid item xs={spacing ? spacing[i] : true} key={i} className={cls.item}>
          {c}
        </Grid>
      ))}
    </Grid>
  );
}

export default ListItem;
