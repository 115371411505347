import { putRequest } from "../core/comm.js";
import { localStorage as _ } from "..";
import { updateURL } from "bild-utils";

export function load(search_params, state, setState) {
  if (search_params && search_params.event) {
    if (search_params.event === "signing_complete") {
      // Signing Successful, update the backend and let the user know
      // We are only sending a finish callback, since we will allow the backend to succeed/fail silently
      setSignedContract(() => {
        _.setContractStatus(false);
        setState({ ...state, isLoading: false, message: "success" });
      });
    } else if (search_params.event === "ttl_expired") {
      // Contract link timed out, clear out contract_last_checked and reload the page
      _.setContractStatus(null);
      _.setContractLastChecked(null);
      updateURL("/");
    } else if (search_params.event === "decline") {
      // Signing Declined, alert the backend and let the user know they will either have to skip or try to sign it again
      // We are only sending a finish callback, since we will allow the backend to succeed/fail silently
      setDeclinedContract(() => {
        _.setContractStatus(false);
        _.setContractLastChecked(null);
        setState({ ...state, isLoading: false, message: "failure" });
      });
    } else {
      // Signing Failed, let the user know
      setState({ ...state, isLoading: false, message: "failure" });
    }
  } else {
    setState({ ...state, isLoading: false, contractStatus: _.getContractStatus() });
  }
}

export function skipContract(success_cb, error_cb) {
  _.setContractStatus(false);
  putRequest(`/users/contract/skip`, {}, success_cb, null, error_cb);
}

export function setDeclinedContract(finish_cb) {
  putRequest(`/users/contract/decline`, {}, null, finish_cb, null);
}

export function setSignedContract(finish_cb) {
  putRequest(`/users/contract/save`, {}, null, finish_cb, null);
}
