import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, Avatar, BTypography, PopTip, UserAvatar } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  chipWrapper: {
    padding: "0 0.25rem 0.25rem 0"
  },
  chip: {
    border: `2px solid ${colors.lightGray}`,
    borderRadius: "50px"
  },
  chipButton: {
    "&:hover": {
      background: colors.lightGray,
      cursor: "pointer"
    }
  },
  nameWrapper: { overflow: "hidden", whiteSpace: "nowrap" },
  name: {
    color: colors.black,
    padding: "0 0.5rem 0 0.25rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }
});

function Chip({ member, icon, size, hideName, clickable, onClick }) {
  const cls = useStyles({});
  const isButton = clickable ? clickable : typeof onClick === "function";

  return (
    <Grid container item xs key={member.id} alignItems="center" justifyContent="flex-start" className={cls.chipWrapper} onClick={onClick}>
      <PopTip text={member.name} delay={1000}>
        <Grid container item alignItems="center" justifyContent="flex-start" className={`${cls.chip} ${isButton ? cls.chipButton : ""}`}>
          <Grid item>
            <UserAvatar
              size={size ? size : 30}
              src={member.avatarURL}
              name={member.name}
              hideName
              className={cls.avatar}
              icon={icon}
              color={member.programColor ? member.programColor.webFrontendValue : member.color}
              srcSize="small"
              badgeSrc={member.badgeURL}
            />
          </Grid>
          {!hideName && (
            <Grid item xs className={cls.nameWrapper}>
              <BTypography variant="body1" className={cls.name}>{member.name}</BTypography>
            </Grid>
          )}
        </Grid>
      </PopTip>
    </Grid>
  );
}

export default Chip;
