import React, { useEffect, useState } from "react";
import { BTypography, NormalButton, SortableList, SupportDialog, UserAvatar, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, DialogTitle, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  title: { paddingBottom: "1rem" },
  body: { minHeight: "50vh" },
});

export default function ArchivedUsersDialog({ open, onClose, org, archivedUsers }) {
  const theme = useTheme();
  const cls = useStyles();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [users, setUsers] = useState([]);
  const [restoreUser, setRestoreUser] = useState(null);

  useEffect(()=>{
    if (org && archivedUsers) {
      setUsers(
        archivedUsers.sort((a,b) => a.name.localeCompare(b.name)).map(x => [
          [
            <UserAvatar size={35} src={x.avatarURL} name={x.name} badgeSrc={x.badgeURL} sortval={x.name} key={x.name} />
          ],
          x.archivedAt,
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <NormalButton
                onClick={() => {
                  setRestoreUser(x.name);
                }}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                Reactivate
              </NormalButton>
            </Grid>
          </Grid>
        ])
      );
    }
  },[org, archivedUsers]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true} fullScreen={smDown}>
        <DialogTitle className={cls.title}>
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
              <Grid item>
                <BTypography variant={"h5"}>{org.organizationName} - Archived Users</BTypography>
              </Grid>
            </Grid>
            <Grid item>
              <NormalButton
                onClick={onClose}
                variant={"micro"}
                color={colors.transparent}
                labelColor={colors.darkGray}
                hoverColor={colors.darkGray}
                hoverLabelColor={colors.black}
              >
                <i className="far fa-times fa-2x" />
              </NormalButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={cls.body}>
          <Grid container justifyContent="center" alignItems="center" className={cls.container}>
            <Grid container item>
              <SortableList
                perPage={10}
                headers={["Name", "Date Archived", ""]}
                items={users}
                itemPadding={"0.5rem 1rem"}
                emptyMessage={
                  <>
                    All people in this organization are active.
                  </>
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <SupportDialog
        open={Boolean(restoreUser)}
        closeDialog={() => {
          setRestoreUser(null);
        }}
        options={[`I would like to reactivate ${restoreUser} in ${org.organizationName}.`]}
      />
    </>
  );
}
