import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

import { Button } from "@mui/material";
import { Blink } from "bild-ui";

const useStyles = makeStyles({
  microRoot: {
    minWidth: "0",
    fontSize: "0.75rem",
    fontWeight: "normal",
    backgroundColor: colors.lightGray,
    margin: "0 0.25rem"
  },
  microLabel: {
    lineHeight: "0.75rem"
  },
  slimRoot: {
    fontWeight: "normal",
    backgroundColor: colors.lightGray,
    margin: "0.25rem",
    padding: "0.5rem 0.5rem"
  },
  slimLabel: {
    lineHeight: "1rem"
  },
  submitRoot: {
    fontWeight: "normal",
    backgroundColor: colors.green,
    margin: "0.25rem",
    padding: "0.5rem 0.5rem"
  },
  submitLabel: {
    lineHeight: "1rem",
    color: colors.white,
    "&:hover": {
      color: colors.black
    }
  },
  bigRoot: {
    fontWeight: "normal",
    width: "100%",
    lineHeight: "2rem",
    border: `1px solid ${colors.gray}`,
    "&:hover": {
      background: colors.darkerGray
    },
    "&:active": {
      background: colors.bildGray
    },
    "&:hover > span, &:active > span": {
      color: colors.white
    }
  },
  bigLabel: {
    lineHeight: "2rem",
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    color: colors.white
  },
  simpleRoot: {
    fontWeight: "bold",
    backgroundColor: "transparent",
    padding: "0.5rem 0.5rem"
  },
  simpleLabel: {
    lineHeight: "1rem",
    color: colors.lightBlue
  }
});

// USE BILD-UI's NORMALBUTTON OVER THIS

function Bbutton({ variant, ...props }) {
  const classes = useStyles();

  const isLink = typeof props.dst === "string";

  let cls = null;
  if (variant === "micro") cls = { root: classes.microRoot, text: classes.microLabel };
  else if (variant === "slim") cls = { root: classes.slimRoot, text: classes.slimLabel };
  else if (variant === "submit") cls = { root: classes.submitRoot, text: classes.submitLabel };
  else if (variant === "big") cls = { root: classes.bigRoot, text: classes.bigLabel };
  else if (variant === "simple") cls = { root: classes.simpleRoot, text: classes.simpleLabel };

  return <Button component={isLink ? Blink : undefined} classes={cls} {...props} />;
}

export default Bbutton;
