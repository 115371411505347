import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import queryString from "query-string";
import { setPageTitle, refreshPage } from "bild-utils";
import { load, skipContract } from "bild-data/contract";
import { toaster } from "presentational/toasts/toasts.js";

import Contract from "containers/contract/contract.js";

function ContractLoader({}) {
  const location = useLocation();
  setPageTitle("Enrollment Agreement");
  let [state, setState] = useState({ open: true, isLoading: true });
  const search_params = queryString.parse(location.search);
  useEffect(() => load(search_params, state, setState), []); // Empty array == componentDidMount()

  function closeDialog() {
    // Call Backend to let them know that the user has decided to postpone signing this (they get 3 postponements)
    let cs = state.contractStatus;
    if (cs && cs.skippedCount < cs.maxSkipsAllowed) {
      skipContract(
        () => {
          refreshPage();
          setState({ ...state, open: false });
        },
        e => {
          console.log(e);
          refreshPage();
        }
      );
    } else {
      toaster.error("You have already skipped this Enrollment Agreement the maximum number of times allowed. Please try again or contact support.");
    }
  }

  return (
    <Contract open={state.open} isLoading={state.isLoading} closeDialog={closeDialog} contractStatus={state.contractStatus} message={state.message} />
  );
}

export default ContractLoader;
