import React from "react";
import { colors } from "bild-ui";
import { makeStyles, createStyles, useTheme } from "@mui/styles";

import { Divider, Grid, TextField, IconButton, Menu, MenuItem } from "@mui/material";

import { MarkButton, BlockButton, FontFamilySelect } from ".";
import { FontSizeSelect } from ".";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    container: {
      boxShadow: `0 1px 1px ${colors.gray}`,
      background: colors.white,
      position: "sticky",
      top: "0",
      zIndex: "1000"
    },
    title: {
      [theme.breakpoints.down('md')]: {
        padding: "1.5rem 0"
      },
      [theme.breakpoints.up("sm")]: {
        padding: "1.5rem 1rem"
      },
      borderBottom: `1px solid ${colors.gray}`,
      height: "90px"
    },
    titleField: { width: "100%" },
    leftItem: { paddingLeft: "1rem" },
    rightItem: { paddingRight: "1rem", textAlign: "right" },
    menuItem: { opacity: "0.4", fontSize: ".75rem", paddingRight: ".75rem" },
    deleteItem: { color: colors.red }
  })
);

function ArtifactToolbar({ marks = [], blocks = [], name, placeholder, saved, onChangeName, onSave, dummyMode }) {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  function _changeName(name) {
    if (typeof onChangeName === "function") onChangeName(name);
  }

  function _handleMenuClick(event) {
    setMenuAnchorEl(event.currentTarget);
  }

  function _onSave() {
    _handleMenuClose();
    onSave();
  }

  function _handleMenuClose() {
    setMenuAnchorEl(null);
  }

  const markButtons = marks.map((m, i) => <MarkButton mark={m} key={i} />);
  const blockButtons = blocks.map((b, i) => <BlockButton block={b} key={i} />);

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={dummyMode ? 12 : 11} className={classes.title}>
          <TextField
            InputProps={{ disableUnderline: true }}
            inputProps={{ style: { fontSize: "1.5rem" } }}
            value={name}
            placeholder={placeholder ? placeholder : "Type a title..."}
            onChange={e => _changeName(e.target.value)}
            className={classes.titleField}
            variant="standard"
          />
        </Grid>
        {!dummyMode && (
          <>
            <Grid item xs={1} className={classes.title}>
              <Grid container>
                <Grid item>
                  <IconButton
                    style={{ height: "2rem", width: "2rem", border: `1px solid ${colors.gray}` }}
                    aria-controls="user-document-menu"
                    aria-haspopup="true"
                    onClick={_handleMenuClick}
                    size="large">
                    <i className="fal fa-ellipsis-h" style={{ fontSize: "1.5rem", textColor: `${colors.gray}` }} />
                  </IconButton>
                  <Menu id="user-document-menu" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={_handleMenuClose}>
                    <MenuItem onClick={_onSave}>
                      <i className={`${classes.menuItem} fas fa-save`}></i>Save
                    </MenuItem>
                    <MenuItem onClick={_handleMenuClose} disabled>
                      <i className={`${classes.menuItem} fas fa-edit`}></i>Edit
                    </MenuItem>
                    <MenuItem onClick={_handleMenuClose} disabled>
                      <i className={`${classes.menuItem} ${classes.deleteItem} fas fa-trash-alt`}></i>Put in Trash
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <Grid item container lg={9} md={10} sm={11} xs={12} alignItems="center" className={classes.leftItem}>
                {markButtons}
                {blockButtons}
                <FontSizeSelect />
                <FontFamilySelect />
              </Grid>
              <Grid container item lg={3} md={2} sm={1} xs={12} className={classes.rightItem} alignItems="center" justifyContent="center">
                {saved ? (
                  <Grid item xs={12}>
                    <i className="fal fa-check" />
                    {" All changes saved."}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <i className="fal fa-spinner-third fa-spin" />
                    {" Saving..."}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Divider />
    </div>
  );
}

export default ArtifactToolbar;
