import React, { useState, useEffect } from "react";
import { colors, ManagedMenu, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import GroupBlock from "./groupBlock.js";
import CreateUpdateGroupDialog from "./createUpdateGroupDialog.js";
import CreateUpdateCohortDialog from "./createUpdateCohortDialog.js";

const useStyles = makeStyles({
  containerWrapper: { height: "80vh", maxHeight: "80vh" },
  header: { padding: "0.5rem 1rem" },
  menuWrapper: { backgroundColor: colors.lightGray },
  groupWrapper: { backgroundColor: colors.white },
  group: {}
});

function ManageGroup({ users, paths, groups, startGroupId, createGroup, createUpdateGroup, createUpdateCohort }) {
  const cls = useStyles();
  const [currentGroup, setCurrentGroup] = useState(startGroupId ? startGroupId : groups[0] ? groups[0].id : null);
  const [newDialog, setNewDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [newCohortDialog, setNewCohortDialog] = useState(false);
  const [addCohortDialog, setAddCohortDialog] = useState(false);

  useEffect(() => {
    if (startGroupId) {
      setCurrentGroup(startGroupId);
    }
  }, [startGroupId]);

  return (
    <Grid container>
      <Grid item xs={12} className={cls.header}>
        <BTypography variant="h3">Sharing Groups</BTypography>
      </Grid>
      <Grid container item xs={12} className={cls.containerWrapper} direction="row" justifyContent="flex-start" alignItems="stretch">
        <Grid container item lg={2} md={3} xs={3} justifyContent="flex-start" alignItems="stretch" className={cls.menuWrapper}>
          <ManagedMenu
            items={groups}
            currentItem={currentGroup}
            setCurrentItem={setCurrentGroup}
            newItem={() => {
              setNewDialog(true);
            }}
            newItemText="New Group"
            newItem2={() => {
              setNewCohortDialog(true);
            }}
            newItem2Text="New Cohort"
          />
        </Grid>
        <Grid container item lg={10} md={9} xs={9} justifyContent="flex-start" alignItems="flex-start" className={cls.groupWrapper}>
          <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start" className={cls.group}>
            {groups && groups.length > 0 && currentGroup && (
              <GroupBlock
                group={groups.filter(x => x.id === currentGroup)[0]}
                addCohort={() => {
                  setAddCohortDialog(true);
                }}
                editGroup={() => {
                  setEditDialog(true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <CreateUpdateGroupDialog
        open={newDialog || editDialog}
        closeDialog={() => {
          setNewDialog(false);
          setEditDialog(false);
        }}
        group={editDialog ? groups.filter(x => x.id === currentGroup)[0] : null}
        allUsers={users}
        allPaths={paths}
        channel={editDialog ? groups.filter(x => x.id === currentGroup)[0] : null}
        createUpdateGroup={createUpdateGroup}
      />
      <CreateUpdateCohortDialog
        open={newCohortDialog || addCohortDialog}
        closeDialog={() => {
          setNewCohortDialog(false);
          setAddCohortDialog(false);
        }}
        group={addCohortDialog ? groups.filter(x => x.id === currentGroup)[0] : null}
        allUsers={users}
        allPaths={paths}
        createUpdateCohort={createUpdateCohort}
      />
    </Grid>
  );
}

export default ManageGroup;
