import React, { useState, useEffect } from "react";
import { ENUMS } from "bild-data";
import { colors, SortableList, Blink, NormalButton, BTypography, SupportDialog, UserAvatar, FilterTextField, FormAutocompleteField } from "bild-ui";
import { AddUserDialogLoader, ManageGroupsDialogLoader, PreviewPathDialogLoader } from "modules";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip, Popper, ClickAwayListener, Paper, List, ListItemButton } from "@mui/material";
import { toaster } from "presentational/toasts/toasts.js";
import { checkAdmin } from "bild-data/user";
import ArchivedUsersDialog from "./components/archivedUsersDialog";
import ArchiveUserDialog from "./components/archiveUserDialog";

const useStyles = makeStyles({
  wrapper: { padding: "1rem 1rem 5rem 1rem" },
  header: { padding: "1rem", marginBottom: "1rem", backgroundColor: colors.white },
  subHeader: { padding: "0.5rem 0 0 0" },
  orgs: { padding: "0.5rem 0" },
  org: { "&:hover": { cursor: "pointer !important" } },
  orgFilter: { minWidth: "15rem" },
  popperArrow: { color: colors.bildBlue },
  tooltip: { backgroundColor: colors.lightGray, color: colors.black, border: `1px solid ${colors.bildBlue}` },
  rolesIcon: { color: colors.bildBlue },
  roles: { margin: "0", padding: "0.1rem 0.1rem 0.1rem 0.5rem" },
  button: { paddingLeft: "0.5rem" },
  detailButton: { maxWidth: "15rem" }
});

function ManageUsers({ users, setUsers, archivedUsers, orgs, org, initialOrgFilter, roles, resendEmail, archiveUser }) {
  const cls = useStyles();
  const isAdmin = checkAdmin();

  const [userItems, setUserItems] = useState([]);
  const [groupDialog, setGroupDialog] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const [archiveRequestDialog, setArchiveRequestDialog] = useState(false);
  const [archiveUserDialog, setArchiveUserDialog] = useState(false);
  const [archivedDialog, setArchivedDialog] = useState(false);
  const [previewPathDialog, setPreviewPathDialog] = useState(false);
  const [updateUserDialog, setUpdateUserDialog] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");
  const [orgFilter, setOrgFilter] = useState(null);

  useEffect(() => {
    setOrgFilter(initialOrgFilter);
  }, [initialOrgFilter]);

  useEffect(() => {
    // map raw user data into list format
    setUserItems(
      users
        .filter(x => {
          if (filter.length > 0) {
            return (
              x.name.toLowerCase().includes(filter.toLowerCase()) ||
              x.emailAddress.toLowerCase().includes(filter.toLowerCase()) ||
              x.organizations.some(up => up.organizationName.toLowerCase().includes(filter.toLowerCase()))
            );
          } else {
            return 1;
          }
        })
        .filter(x => {
          if (orgFilter) {
            return x.organizations.some(z => z.organizationId.toString() === orgFilter.toString());
          } else {
            return 1;
          }
        })
        .map(x => [
          [<UserAvatar size={40} src={x.avatarURL} name={x.name} badgeSrc={x.badgeURL} srcSize="small" sortval={x.name} key={x.name} />],
          x.emailAddress,
          x.organizations
            .sort((a, b) => {
              return a.organizationName.localeCompare(b.organizationName);
            })
            .map(y => {
              let roles = y.roles.filter(x => x.id !== ENUMS.ROLES.STUDENT.ID);
              return (
                <Grid container item key={y.organizationName} sortval={y.organizationName} className={x.organizations.length > 1 ? cls.orgs : ""}>
                  {roles && roles.length > 0 && (
                    <Tooltip
                      title={_listRoles(roles)}
                      arrow
                      placement="right"
                      classes={{ arrow: cls.popperArrow, tooltip: cls.tooltip }}
                      className={cls.org}
                    >
                      <span>
                        {y.organizationName}
                        &nbsp;
                        <i className={`${cls.rolesIcon} fal fa-registered`} />
                      </span>
                    </Tooltip>
                  )}
                  {!roles || (roles.length < 1 && y.organizationName)}
                </Grid>
              );
            }),
          <Grid container justifyContent="flex-end">
            <Grid item>
              <NormalButton component={Blink} dst="manage-user" user_id={x.id} variant="simple" color={colors.darkGray} labelColor={colors.white}>
                Manage
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton
                variant="simple"
                onClick={e => {
                  onMenuClick(e, x.id, x.name, x.emailAddress, x);
                }}
              >
                <i className="far fa-ellipsis-h" />
              </NormalButton>
            </Grid>
          </Grid>
        ])
    );
  }, [users, orgs, roles, filter, orgFilter]);

  function onMenuClick(e, user_id, name, email, user) {
    setUser(user);
    setUserId(user_id);
    setUserName(name);
    setUserEmail(email);
    setMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function clear() {
    setUserId(null);
    setUserName(null);
    setUserEmail(null);
  }

  function _resendEmail() {
    let email = users.filter(x => x.id === userId)[0].emailAddress;
    closeMenu();
    resendEmail(
      email,
      () => {
        toaster.success("Password reset email successfully sent!");
      },
      e => {
        toaster.error("The password reset email was not able to be sent. Please try again or contact support.");
        console.log(e);
      }
    );
  }

  function _archiveUser() {
    if (isAdmin && user && org) {
      archiveUser(userId, org.organizationId);
      closeMenu();
    }
  }

  function _listRoles(roles) {
    let val = (
      <Grid container item className={cls.roles}>
        <BTypography variant="body1">No Roles Assigned</BTypography>
      </Grid>
    );

    if (roles && roles.length > 0) {
      val = (
        <Grid container className={cls.roles} justifyContent="center" alignItems="center">
          <ul className={cls.roles}>
            {roles.map(z => (
              <li key={z.id}>
                <BTypography variant="body1">{z.description}</BTypography>
              </li>
            ))}
          </ul>
        </Grid>
      );
    }

    return val;
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <BTypography variant="h4">Manage Users</BTypography>
        </Grid>
        <Grid item>
          <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
            <Grid item className={cls.orgFilter}>
              <FormAutocompleteField
                placeholder="Organization Filter"
                value={orgFilter}
                setValue={setOrgFilter}
                items={orgs.map(x => {
                  return { id: x.organizationId, name: x.organizationName };
                })}
                variant="small"
              />
            </Grid>
            <Grid item>
              <FilterTextField
                value={filter}
                placeholder="Search"
                onChange={e => setFilter(e.target.value)}
                onClear={() => {
                  setFilter("");
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.subHeader}>
            {archivedUsers && archivedUsers.length > 0 && (
              <Grid item className={cls.button}>
                <NormalButton
                  onClick={() => {
                    setArchivedDialog(true);
                  }}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  className={cls.detailButton}
                >
                  Archived Users
                </NormalButton>
              </Grid>
            )}
            <Grid item className={cls.button}>
              <NormalButton
                onClick={() => {
                  setPreviewPathDialog(true);
                }}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                Preview Programs
              </NormalButton>
            </Grid>
            <Grid item className={cls.button}>
              <NormalButton
                onClick={() => {
                  setGroupDialog(true);
                }}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                Manage Groups
              </NormalButton>
            </Grid>
            <Grid item className={cls.button}>
              <NormalButton
                onClick={() => {
                  clear();
                  setUserDialog(true);
                }}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                Add User
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          perPage={15}
          filter={filter}
          headers={["Name", "Email", "Organization", ""]}
          items={userItems}
          itemType="Users"
          emptyMessage={
            <BTypography variant="h6">{filter ? "No Users match the search criteria." : "There are not any users to manage, yet!"}</BTypography>
          }
        />
      </Grid>
      <Popper open={menuOpen} anchorEl={menuAnchorEl}>
        <ClickAwayListener onClickAway={closeMenu} mouseEvent="onMouseDown">
          <Paper>
            <List dense>
              <ListItemButton
                dense
                onClick={() => {
                  closeMenu();
                  setUserDialog(true);
                }}
              >
                Add Role
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  closeMenu();
                  setUpdateUserDialog(true);
                }}
              >
                Update User
              </ListItemButton>
              <ListItemButton dense onClick={_resendEmail}>
                Send Password Reset Email
              </ListItemButton>
              {isAdmin && org && user && user.organizations.some(x => x.organizationId == org.organizationId) && (
                <ListItemButton dense onClick={()=>{setArchiveUserDialog(true)}}>
                  <i className="fal fa-cabinet-filing" /> &nbsp; Archive User
                </ListItemButton>
              )}
              {!isAdmin && org && user && user.organizations.some(x => x.organizationId == org.organizationId) && (
                <ListItemButton dense onClick={()=>{setArchiveRequestDialog(true)}}>
                  <i className="fal fa-archive" /> &nbsp; Request to Archive User
                </ListItemButton>
              )}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <ManageGroupsDialogLoader
        open={groupDialog}
        onClose={() => {
          setGroupDialog(false);
        }}
      />
      <AddUserDialogLoader
        open={userDialog}
        onClose={() => {
          setUserDialog(false);
        }}
        defaultOrgs={orgs}
        defaultId={userId}
        defaultName={userName}
        defaultEmail={userEmail}
        success_cb={setUsers}
      />
      <PreviewPathDialogLoader
        open={previewPathDialog}
        onClose={() => {
          setPreviewPathDialog(false);
        }}
      />
      <ArchiveUserDialog
        open={archiveUserDialog}
        onClose={() => {
          setArchiveUserDialog(false);
        }}
        org={org}
        user={user}
        archiveUser={_archiveUser}
      />
      <ArchivedUsersDialog
        open={archivedDialog}
        onClose={() => {
          setArchivedDialog(!archivedDialog);
        }}
        org={org}
        archivedUsers={archivedUsers}
      />
      <SupportDialog
        open={updateUserDialog || archiveRequestDialog}
        closeDialog={() => {
          setUpdateUserDialog(false);
          setArchiveRequestDialog(false);
        }}
        options={[`I would like to ${updateUserDialog ? `update information for ${userName}` : (archiveRequestDialog ? `archive  ${userName} for ${org.organizationName}` : "")} .`]}
      />
    </Grid>
  );
}

export default ManageUsers;
