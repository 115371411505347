import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { libraryData, searchData, userData } from "bild-data";
import { setPageTitle, updateURLWithoutLoad } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import { StaffSearch } from "modules";
import { toaster } from "presentational/toasts/toasts";

export default function StaffSearchLoader({}) {
  const { search_term } = useParams();
  const [searchValue, setSearchValue] = useState(search_term);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [userPaths, setUserPaths] = useState([]);
  const [library, setLibrary] = useState(userData.getUserLibrary());
  const [books, setBooks] = useState([]);

  useEffect(() => {
    if (library && searchValue && searchValue.length > 2) {
      let sv = searchValue ? searchValue.toLowerCase() : "NO MATCH";
      let filteredBooks = library.libraryBooks.filter(x => x.title.toLowerCase().includes(sv));
      setBooks(filteredBooks);
    }
  },[library, searchValue]);

  useEffect(() => {
    setTotalCount(
      (users ? users.length : 0) +
      (orgs ? orgs.length : 0) +
      (groups ? groups.length : 0) +
      (userPaths ? userPaths.length : 0) +
      (books ? books.length : 0)
    );
  },[users, orgs, groups, userPaths, books]);

  useEffect(() => {
    function checkLibrary(newData) {
      let refresh = true;

      // Check if the localStorage library data is different from the server's
      if (library) {
        let sortedOldData = library.libraryBooks.map(x => { return {"id": x.id, "thumbnailUrl": x.thumbnailUrl} }).sort((a,b) => a.id - b.id);
        let sortedNewData = newData.libraryBooks.map(x => { return {"id": x.id, "thumbnailUrl": x.thumbnailUrl} }).sort((a,b) => a.id - b.id);
        let comparedData = JSON.stringify(sortedOldData) === JSON.stringify(sortedNewData);
        if (comparedData) {
          refresh = false;
        }
      }

      // Refresh the localStorage and page data
      if (refresh) {
        setLibrary(newData);
        userData.setUserLibrary(newData);
      }
    }

    // Load Library data
    libraryData.load(checkLibrary);
  },[]);

  useEffect(()=>{
    setPageTitle("Staff Search : " + searchValue);

    function _setData(d) {
      setUsers(d.users);
      setOrgs(d.organizations);
      setGroups(d.groups);
      setUserPaths(d.userPaths);
      setLoading(false);
      setRefreshing(false);
    }

    if (searchValue && searchValue.length > 2) {
      setRefreshing(true);
      searchData.staffSearch(searchValue, _setData);
      updateURLWithoutLoad("/staff/search/" + searchValue);
    } else {
      if (searchValue && searchValue.length < 3) {
        toaster.error("Search Value must be at least 3 characters long.");
      }
      setLoading(false);
    }
  },[searchValue]);

  function _onSearch(data, successCb) {
    setSearchValue(data);
    if (typeof successCb === "function") successCb();
  }

  if (loading) return <LoadingScreen />;
  return (
    <BaseWrapper>
      <StaffSearch
        searchValue={searchValue}
        totalCount={totalCount}
        users={users}
        orgs={orgs}
        groups={groups}
        userPaths={userPaths}
        books={books}
        onSearch={setSearchValue}
        refreshing={refreshing}
      />
    </BaseWrapper>
  );
}