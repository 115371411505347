import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from "query-string";
import { certificateData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import ValidatedUserCertificate from "./validatedUserCertificate.js";

function UserCertificateValidationLoader({}) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificate, setCertificate] = useState({});

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    function _setData(d) {
      setPageTitle("Certificate Validation");
      setCertificate(d);
      setLoading(false);
    }

    if (searchParams.last_name && searchParams.validation_code) {
      certificateData.validateUserCertificate(searchParams.last_name, searchParams.validation_code, _setData, e => {
        setError(e.response.data.error);
      });
    } else {
      setError({ displayMessage: "Required fields were not passed in." });
    }
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return <ValidatedUserCertificate certificate={certificate} />;
}

export default UserCertificateValidationLoader;
