import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import Bbutton from "presentational/input/bbutton.js";

const useStyles = makeStyles({
  dateWrapper: { textAlign: "center" },
  buttonWrapper: { textAlign: "right" },
  date: { color: colors.darkerGray }
});

function TraineeDetailItem({ courseName, studentName, updatedAt, status, trainingAssessmentId, competency }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs>
        <Typography variant="body1">{studentName}</Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="body1">{ReactHtmlParser(courseName)}</Typography>
      </Grid>
      <Grid item xs className={classes.dateWrapper}>
        <Typography variant="body1" className={classes.date}>
          {updatedAt}
        </Typography>
      </Grid>
      {status !== null && (
        <Grid item xs>
          <Typography variant="body1">
            {status ? "AFT Assessment Approved by Coach" : ReactHtmlParser("AFT Assessment <u>Not</u> Approved By Coach")}
          </Typography>
        </Grid>
      )}
      <Grid item xs className={classes.buttonWrapper}>
        {trainingAssessmentId && (
          <Bbutton variant="submit" dst={"training_assessment"} training_assessment_id={trainingAssessmentId}>
            View Assessment
          </Bbutton>
        )}
        {competency && (
          <Bbutton variant="submit" dst="course" course_id={competency.course.id} competency_proof_id={competency.competencyProof.id}>
            View Competency
          </Bbutton>
        )}
      </Grid>
    </Grid>
  );
}

export default TraineeDetailItem;
