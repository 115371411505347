const classes = {
  contentWrapper: {
    root: "content-wrapper-root",
    flat: "content-wrapper-flat",
    noPadding: "no-padding"
  },

  courseWrapper: {
    root: "course-wrapper-root",
    header: "cw-header",
    body: "cw-body",
    overview: "cw-overview",
    transcript: "cw-transcript",
    dashboard: "cw-dashboard",
    dashboardEmpty: "cw-dashboard-empty"
  }
};

export default classes;
