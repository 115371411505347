import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Avatar, PopTip } from "bild-ui";
import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

function MembersList({ members, hideAvatar, icon }) {
  return (
    <List dense>
      {members.map(m => {
        let id = m.user ? m.user.id : m.id;
        let name = m.user ? m.user.name : m.name;
        let avatarURL = m.user ? m.user.avatarURL : m.avatarURL;
        let color = m.programColor ? m.programColor.webFrontendValue : m.color;
        let subtitle = m.subtitle ? m.subtitle : null;

        return (
          <ListItem key={id}>
            {!hideAvatar && (
              <ListItemAvatar>
                <Avatar size={40} src={avatarURL} name={name} icon={icon} color={color} srcSize="small" />
              </ListItemAvatar>
            )}
            <ListItemText primary={name} secondary={ReactHtmlParser(subtitle)} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default MembersList;
