import React, { useEffect, useState } from "react";
import { loadScript } from "bild-utils";

import FileActionButton from "containers/folio/components/fileActionButton.js";

import "./vendor.css";
// BILD Dropbox File Picker
// inspired by https://github.com/sdoomz/react-dropbox-chooser
function DropboxFilePicker({ contextRoot, autoLoad, onUploadFiles }){
  const [apiLoaded, setApiLoaded] = useState(false);

  // Initial load of scripts
  useEffect(() => {
    loadScript("https://www.dropbox.com/static/api/2/dropins.js", "dropboxjs", [["data-app-key", process.env.REACT_APP_DROPBOX_KEY]], onScriptLoad);
  }, [contextRoot]);
  
  function onScriptLoad() {
    setApiLoaded(true);

    // Open the file picker automatically if autoLoad is true
    if (autoLoad) {
      onChoose();
    }
  }

  function onChoose() {
    let success = f => {
      onSuccess(f);
    };
    let cancel = e => {
      onCancel(e);
    };
    let linkType = "direct"; // "direct" or "preview"
    let multiselect = true; // Allow multiple files to be picked
    // TODO: replace this with list of extensions from API
    // Matches the list on https://intercom.help/bild-international/bild-cloud/students/acceptable-file-formats
    let extensions = [
      ".docx",
      ".doc",
      ".dot",
      ".odt",
      ".txt",
      ".rtf",
      ".pdf",
      ".pptx",
      ".ppt",
      ".odp",
      ".xlsx",
      ".xls",
      ".csv",
      ".ods",
      ".gif",
      ".jpeg",
      ".jpg",
      ".png",
      ".svg",
      ".tiff"
    ]; // Allowed file extensions that can be imported

    // Since Dropbox is loaded as a 3rd-party script outside of react, we need to use the window's Dropbox component
    window.Dropbox.choose({
      success,
      cancel,
      linkType,
      multiselect,
      extensions
    });
  }

  function onSuccess(docs) {
    let files = [];

    for (var i = 0; i < docs.length; i++) {
      let d = docs[i];
      let file = {
        name: d.name,
        fileSizeBytes: d.bytes,
        dropBoxLink: d.link,
        folderId: contextRoot ? contextRoot.id : null
      };
      files.push(file);
    }

    onUploadFiles(files, contextRoot);
  }

  function onCancel() {
    // TODO: Decide on what we want to do when they cancel, possibly nothing
  }

  let content = "";

  if (apiLoaded) {
    content = <FileActionButton onClick={onChoose} contextRoot={contextRoot} variant="add-from-dropbox" />;
  }

  return <span>{content}</span>;
}

export default DropboxFilePicker;
