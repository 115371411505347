import React, { useEffect, useState } from "react";
import { diskData } from "bild-data";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { BTypography, IndeterminateProgress, NormalButton, colors } from "bild-ui";
import { PdfViewer } from "modules";

const useStyles = makeStyles({
  header: { padding: "0.25rem 0.25rem" },
  previewNote: { fontStyle: "italic", textAlign: "center" },
  frame: props => ({
    width: "100%",
    height: props.isFullScreen ? "89vh" : "80vh",
    border: `3px solid ${colors.veryLightBlue}`,
    borderRadius: "4px",
    overflowY: "auto",
  }),
  fitWidth: { width: "100%", },
  fitHeight: { height: "100%", },
  previewProgressTitle: { textAlign: "center", color: colors.lightBlue },
});

function PreviewFile({ file, isFullScreen }) {
  const cls = useStyles({ isFullScreen });
  const [loading, setLoading] = useState(true);
  const [previewURL, setPreviewURL] = useState(null);
  const [fitWidth, setFitWidth] = useState(true);

  useEffect(()=>{
    diskData.previewItemURL(file.id, setPreviewURL);
  },[file]);

  let type;
  if (file) {
    const ct = file.contentType ? file.contentType : (file.raw && file.raw.contentType ? file.raw.contentType : "");
    let iOS = /^(iPhone|iPad|iPod)/.test(window.navigator.platform);
    let msie = /MSIE|Trident/.test(window.navigator.userAgent);
    let android = /Android/.test(window.navigator.userAgent);
    // Only give custom PDFJS viewer if on iOS or old device
    if ((iOS || msie || android) && (ct === "application/pdf"
        || ct === "application/msword"
        || ct === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        || ct === "application/vnd.ms-excel"
        || ct === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        || ct === "application/vnd.ms-powerpoint"
        || ct === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        || ct === "application/msword"
        || ct === "application/vnd.oasis.opendocument.text"
        || ct === "application/vnd.oasis.opendocument.spreadsheet"
        || ct === "application/vnd.oasis.opendocument.presentation"
        || ct === "text/xml"
        || ct === "application/rtf"
        || ct === "text/rtf"
        || (file.previewContentType === "application/pdf") || (file.raw && file.raw.previewContentType === "application/pdf")
    )) {
      type = "pdf";
    } else if (ct.substring(0, 6) === "image/") {
      type = "image";
    } else if (ct.substring(0, 6) === "video/") {
      type = "video"
    } else if (ct.substring(0, 6) === "audio/") {
      type = "audio"
    }
  }

  return (
    <Grid container item xs={12} alignItems="center" justifyContent="center">
      <Grid container item xs={12} alignItems="center" justifyContent="space-between" className={cls.header}>
        {(type === "image") && (
          <Grid item>
            <NormalButton
              variant={"secondary"}
              color={colors.offWhite}
              labelColor={colors.gray}
              hoverLabelColor={colors.darkerGray}
              onClick={()=>{setFitWidth(!fitWidth)}}
            >
              <span className="fa-stack">
                <i className={`far ${fitWidth ? "fa-rectangle-landscape" : "fa-rectangle-portrait"} fa-stack-2x`} />
                <i className={`far ${fitWidth ? "fa-arrows-h" : "fa-arrows-v"}`} />
              </span>
            </NormalButton>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={cls.frame} alignItems="center" justifyContent="center">
        <>
          {previewURL && (
            <>
              {loading && (
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                  <Grid item xs={12} className={cls.previewProgressTitle}>
                    <BTypography variant="h4">Getting File Preview...</BTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <IndeterminateProgress />
                  </Grid>
                </Grid>
              )}
              {type === "image" && (
                <img
                  src={previewURL}
                  style={{ display: loading ? "none" : "inherit" }}
                  title={file.name}
                  onLoad={()=>{setLoading(false)}}
                  className={fitWidth ? cls.fitWidth : cls.fitHeight}
                />
              )}
              {type === "video" && (
                <video
                  style={{ width: "100%", height: "100%", display: loading ? "none" : "inherit" }}
                  title={file.name}
                  src={previewURL}
                  type={file.contentType}
                  onCanPlay={()=>{setLoading(false)}}
                  controls
                />
              )}
              {type === "audio" && (
                <audio
                  style={{ width: "100%", display: loading ? "none" : "inherit" }}
                  title={file.name}
                  src={previewURL}
                  type={file.contentType}
                  onCanPlay={()=>{setLoading(false)}}
                  controls
                />
              )}
              {type === "pdf" && (
                <PdfViewer url={previewURL} onDocLoad={()=>{setLoading(false)}} />
              )}
              {!type && (
                <iframe
                  src={previewURL}
                  style={{ width: "100%", height: "100%", border: "none", display: loading ? "none" : "inherit" }}
                  title={file.name}
                  onLoad={()=>{setLoading(false)}}
                />
              )}
            </>
          )}
        </>
      </Grid>
      <Grid item>
        <BTypography variant="body2" className={cls.previewNote}>
          <b>NOTE:</b> This is a preview and may be incomplete or misformatted. Please download the file to fully view it.
        </BTypography>
      </Grid>
    </Grid>
  );
}

export default PreviewFile;
