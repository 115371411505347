import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, Expander, OrgGroupDetails, OrgGroupNode, OrgGroupFooter } from "bild-ui";
import { Grid, Popper, ClickAwayListener } from "@mui/material";
import { useWidth } from "bild-utils";

const useStyles = makeStyles({
  // Huge thanks to https://codepen.io/philippkuehn/pen/QbrOaN
  tree: {
    overflowY: "hidden",
    overflowX: "auto",
    padding: "0 1rem",
    transition: "all 1s ease",
    "& ul": {
      position: "relative",
      padding: "2vh 0",
      top: "1px",
      whiteSpace: "nowrap",
      textAlign: "center"
    },
    "& ul::after": { content: "''", display: "table", clear: "both" },
    "& ul ul::before": {
      content: "''",
      position: "absolute",
      top: "0",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`,
      width: "0",
      height: "2vh"
    }
    // HOVER CODE
    // "& li span:hover, & li span:hover + ul li span": {
    //   background: colors.bildBlue,
    //   color: colors.white,
    //   border: `1px solid ${colors.lightBlue}`
    // },
    // "& li span:hover + ul li::after, & li span:hover + ul li::before, & li span:hover + ul::before, & li span:hover + ul ul::before": {
    //   borderColor: colors.lightBlue
    // }
  },

  limb: {
    display: "table-cell",
    verticalAlign: "top",
    textAlign: "center",
    listStyleType: "none",
    position: "relative",
    padding: "2vh 0.5rem 0 0.5rem",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: "0",
      right: "50%",
      borderTop: `1px solid ${colors.gray}`,
      width: "50%",
      height: "2vh"
    },
    "&::after": {
      right: "auto",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`
    },
    "&:only-child::after, &:only-child::before": {
      display: "none"
    },
    "&:only-child": { paddingTop: "0" },
    "&:first-child::before, &:last-child::after": {
      border: "0 none"
    },
    "&:last-child::before": {
      borderRight: `1px solid ${colors.gray}`,
      borderRadius: "0 5px 0 0"
    },
    "&:first-child::after": { borderRadius: "5px 0 0 0" }
  },
  lastLimb: {
    "&::before": {
      borderRadius: "0 5px 0 0",
      borderLeft: `none !important`
    },
    "&::after": {
      borderTop: "none !important",
      borderRadius: "0 5px 0 0",
      left: "auto",
      right: "50%",
      borderLeft: "0",
      borderRight: "1px solid #bdbdbd"
    }
  },
  subLimb: {
    display: "table-cell",
    verticalAlign: "top",
    textAlign: "center",
    listStyleType: "none",
    position: "relative",
    padding: "1vh 0.5rem 0 0.5rem"
  },
  subLimbBranch: {},
  shrinkNodeInner: {
    border: `2px solid ${colors.bildBlue}`,
    padding: "0.5rem 0",
    background: colors.white,
    color: colors.bildBlue,
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  shrinkNodeWrapper: {
    padding: "1rem 0.5rem",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  shrinkNode: {
    maxWidth: "8rem"
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  // Scale Levels
  trunk: { transformOrigin: "left", transform: "scale(1)" },
  scale0: { transform: "scale(1)" },
  scale1: { transform: "scale(0.80)" },
  scale2: { transform: "scale(0.60)" },
  scale3: { transform: "scale(0.50)" },
  scale4: { transform: "scale(0.40)" },
  scale5: { transform: "scale(0.35)" },
  popper: {
    maxWidth: "27rem",
    background: colors.white,
    borderRadius: "0.5rem",
    padding: "0rem"
  }
});

function OrgGroupsDiagram({ data, legend, scalable, defaultScale, setOrgGroup, setOrgGroupUsers }) {
  const cls = useStyles();
  const [showNodes, setShowNodes] = useState([]);
  const [showShrunk, setShowShrunk] = useState([]);
  const [scaleLevel, setScaleLevel] = useState(defaultScale ? defaultScale : 0);
  const [popperData, setPopperData] = useState({});
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showLevels, setShowLevels] = useState([]);
  const [levelNames, setLevelNames] = useState({});

  const w = useWidth();
  const xs = w === "xs";

  useEffect(() => {
    // re-render on showNodes or showShrunk change
  }, [showNodes, showShrunk]);

  // Only show Levels that we have data for
  useEffect(()=>{
    let levels = [];
    function findLevels(node) {
      let children = node.children;
      if (children) {
        for (let i=0; i<children.length; i++) {
          findLevels(children[i]);
        }
      }

      levels.push(node.levelId);
    }
    findLevels(data);

    let foundLevels = Array.from(new Set(levels));
    setShowLevels(foundLevels);

    setLevelNames({
      level3Name: data.level3Name,
      level2Name: data.level2Name,
      level1Name: data.level1Name
    });
  },[data]);

  function _togglePopperData(event, data, color) {
    setAnchorEl(event.currentTarget);
    setPopperData({ ...data, color: color });
    setOpen(true);
  }

  function _toggleNode(id) {
    if (showNodes.some(x => x === id)) {
      setShowNodes(showNodes.filter(x => x !== id));
    } else {
      setShowNodes([...showNodes, id]);
    }
  }

  function _toggleShrunkNode(id) {
    if (showShrunk.some(x => x === id)) {
      setShowShrunk(showShrunk.filter(x => x !== id));
    } else {
      setShowShrunk([...showShrunk, id]);
    }
  }

  function getTree(parent, nodes, depth) {
    let limbs = [];
    let pid = parent.organizationId ? parent.organizationId : parent.id;
    for (let i = 0; i < nodes.length; i++) {
      let n = nodes[i];
      let oid = n.id;
      let showAllChildren = showShrunk.some(x => x === pid) ? true : false;
      let hideChildren = showNodes.some(x => x === oid) ? true : false;
      let hasChildren = n.children && n.children.length > 0;
      let isSubLimb = depth > 1 && i >= 3 && !hasChildren;
      // Decide if this is a main limb or a subLimb
      if (depth < 2 || hasChildren || showAllChildren || (depth > 1 && i < 6)) {
        if (isSubLimb && i % 3 === 0) {
          limbs.push(<div className={cls.subLimbBranch} key={oid + "subLimb"}></div>);
        }
        limbs.push(
          <li
            key={oid}
            className={`${depth > 1 && nodes.length >= 6 && i === 2 && !hasChildren ? cls.lastLimb : ""} ${isSubLimb ? cls.subLimb : cls.limb}`}
          >
            <OrgGroupNode data={n} hideChildren={hideChildren} toggleNode={_toggleNode} togglePopperData={_togglePopperData} />
            {hasChildren && (
              <Expander showFirst={true} expandIn={!hideChildren}>
                <ul>{getTree(n, n.children, depth + 1)}</ul>
              </Expander>
            )}
          </li>
        );
      }
      // If this has subLimbs, add a show/hide button
      if ((!showAllChildren && isSubLimb && i === 6) || (showAllChildren && i === nodes.length - 1)) {
        limbs.push(
          <div key={"extra"} className={cls.shrinkNodeWrapper}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={`${cls.shrinkNode}`}
              onClick={() => {
                _toggleShrunkNode(pid);
              }}
            >
              <Grid container item className={`${cls.shrinkNodeInner}`} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <i className={`fas fa-chevron-${showAllChildren ? "up" : "down"}`}></i>
                  &nbsp;
                  {showAllChildren ? `Hide` : `Show`} ({nodes.length - 3})
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
    return limbs;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item className={cls.tree}>
        <ul className={`${cls.trunk} ${cls[`scale${scaleLevel}`]}`}>{getTree(data, data.children, 0)}</ul>
      </Grid>
      <Grid container item xs={12}>
        <OrgGroupFooter
          legend={legend}
          scalable={scalable}
          scaleLevel={scaleLevel}
          setScaleLevel={setScaleLevel}
          level3Name={data.level3Name}
          level2Name={data.level2Name}
          level1Name={data.level1Name}
          showLevels={showLevels}
        />
      </Grid>
      <Popper open={open} anchorEl={anchorEl} placement={xs ? "bottom" : "right-start"} className={cls.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
          mouseEvent="onMouseDown"
        >
          <Grid container item xs={12}>
            <OrgGroupDetails data={popperData} levelNames={levelNames} setOrgGroup={setOrgGroup} setOrgGroupUsers={setOrgGroupUsers} />
          </Grid>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
}

export default OrgGroupsDiagram;
