import { getRequest, putRequest } from "../core/comm.js";

// NOTE: This should be rethought/renamed since it is no longer just for Admin calls (Staff can also use this at times)
//       Or split this function out to a "staff" data file
export function loadAdminUser(user_id, success_cb, error_cb) {
  getRequest(
    `/users/${user_id}/overview`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function loadUserRoles(user_id, success_cb, error_cb) {
  getRequest(
    `/users/${user_id}/roles`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function loadAdminUserCourse(user_id, success_cb, error_cb) {
  getRequest(
    `/courses/users/${user_id}/competency_proofs`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function loadAdminUserCompetency(user_id, success_cb, error_cb) {
  getRequest(
    `/courses/users/${user_id}/competency_proofs`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function loadAdminOrganization(organization_id, success_cb, error_cb) {
  getRequest(
    `/manage/organizations/${organization_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    error_cb
  );
}

export function updateUsername(user, newEmail, success_cb, error_cb) {
  let sendData = user;
  sendData.username = newEmail;
  sendData.emailAddress = newEmail;

  putRequest(`/users/${user.id}/username`, sendData, success_cb, null, error_cb);
}

export function createUserRole(user, org, role, success_cb, error_cb) {
  let sendData = {};
  sendData.user = user;
  sendData.organizationOverview = { organizationId: org.organizationId, organizationName: org.organizationName };
  sendData.visualRole = { id: role.ID, description: role.NAME, prefix: role.PREFIX };
  putRequest(`/users/${user.id}/role`, sendData, success_cb, null, error_cb);
}

export function updateUserRole(userId, data, success_cb, error_cb) {
  putRequest(`/users/${userId}/role`, data, success_cb, null, error_cb);
}

export function updateOrganization(userId, data, success_cb, error_cb) {
  putRequest(`/users/${userId}/organization`, data, success_cb, null, error_cb);
}

export function processBatchCertificates(success_cb, error_cb) {
  putRequest(`/certificates/generate_batch`, null, success_cb, null, error_cb);
}
