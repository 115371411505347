import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import { Typography, Grid, Divider, Checkbox } from "@mui/material";

import UserResult from "./components/userResult.js";
import OrganizationResult from "./components/organizationResult.js";
import SearchBar from "presentational/input/searchBar.js";

import { toaster } from "../../presentational/toasts/toasts.js";
import { getParam, updateURL } from "bild-utils";

const useStyles = makeStyles({
  container: { padding: "1rem 1rem 5rem 1rem", minHeight: "100%" },
  searchBarContainer: { textAlign: "center", marginBottom: "1rem" },
  searchBar: { width: "300px" },
  resultsContainer: { margin: "1rem 0 2rem 0" },
  blankSearch: { height: "400px" }
});

// SEARCH
function Search({ users, organizations, canMasquerade, value, isAdmin }) {
  const classes = useStyles();
  const [expandAll, setExpandAll] = useState(getParam("showFull") ? true : false);

  function submitSearch(searchTerm) {
    if (searchTerm.length === 0 || (isNaN(searchTerm) && searchTerm.length < 2)) {
      toaster.error("Searchs must be at least 1 number or 2 characters long.");
    } else {
      const params = expandAll ? "?showFull=true" : "";
      updateURL("/search/" + searchTerm + params);
    }
  }

  const blank_search = value === "";

  const userResults =
    users && users.map((u, i) => <UserResult user={u} expanded={expandAll} key={i} isAdmin={isAdmin} canMasquerade={canMasquerade} />);
  const organizationResults =
    organizations && organizations.map((o, i) => <OrganizationResult organization={o} expanded={expandAll} key={i} isAdmin={isAdmin} />);

  return (
    <div className={classes.container}>
      <div className={classes.searchBarContainer}>
        <SearchBar onSearch={submitSearch} className={classes.searchBar} defaultValue={value} />
      </div>

      <Grid container justifyContent="space-between">
        <Grid item>{!blank_search && `Showing results for: "${value}"`}</Grid>
        <Grid item>
          Expand all <Checkbox checked={expandAll} onChange={e => setExpandAll(e.target.checked)} />
        </Grid>
      </Grid>

      {blank_search && (
        <Grid container justifyContent="center" alignItems="center" className={classes.blankSearch}>
          <Typography>Here you may search.</Typography>
        </Grid>
      )}

      {!blank_search && (
        <React.Fragment>
          <Typography variant="h5">Users ({users ? users.length : 0})</Typography>
          <Divider />

          <Grid container alignItems="center" direction="column" className={classes.resultsContainer}>
            {userResults}
          </Grid>

          <Typography variant="h5">Organizations ({organizations ? organizations.length : 0})</Typography>
          <Divider />

          <Grid container alignItems="center" direction="column" className={classes.resultsContainer}>
            {organizationResults}
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
}

export default Search;
