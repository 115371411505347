import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Typography, TextField, Grid } from "@mui/material";

import { colors } from "bild-ui";

const useStyles = makeStyles({
  errorText: { color: colors.red },
  textField: { width: "3rem", minHeight: 0, margin: "0 0.3rem", borderRadius: 4 },
  textFieldInput: { padding: "0.5rem 0.4rem", fontSize: "0.9rem" },
  textFieldInvalid: { backgroundColor: colors.veryLightRed, color: colors.red },
  hint: { fontStyle: "italic", marginBottom: "0.5rem" }
});

function CreditManager({
  assignsCredits,
  showCredits,
  creditsValid,
  showCreditsHint,
  variableMinCredits,
  variableMaxCredits,
  creditsGiven,
  credits,
  trainingAssessment,
  groupColor,
  onChange
}) {
  const classes = useStyles();

  const badState = !creditsValid && showCreditsHint;

  if (trainingAssessment && trainingAssessment.assessments) {
    creditsGiven = trainingAssessment.assessments[0].creditsGiven;
  }
  return (
    <>
      {showCredits && (
        <Typography variant="h6" style={{ color: groupColor }} gutterBottom>
          Credit Hour Value <small style={{ fontWeight: "normal", color: "black" }}>{creditsGiven ? parseFloat(creditsGiven) : 0}</small>
        </Typography>
      )}

      {assignsCredits && (
        <>
          <Grid container alignItems="center">
            <Typography variant="h6" style={{ color: groupColor }} display="inline">
              Credit Hour Value
            </Typography>

            <TextField
              onChange={onChange}
              variant="outlined"
              type="number"
              value={credits ? parseFloat(credits) : ""}
              inputProps={{ min: variableMinCredits, max: variableMaxCredits, className: classes.textFieldInput }}
              className={clsx(classes.textField, { [classes.textFieldInvalid]: badState })}
            />

            {badState && (
              <Typography variant="subtitle1" display="inline" className={classes.errorText}>
                This credit amount is not within the approved range.
              </Typography>
            )}
          </Grid>

          <Typography variant="body1">
            Range: {variableMinCredits} &ndash; {variableMaxCredits} Credit Hours
          </Typography>

          <Typography variant="body1" className={classes.hint} gutterBottom>
            Credit hours will not be saved if the assessment is "Competency Not Yet Demonstrated."
          </Typography>
        </>
      )}
    </>
  );
}

export default CreditManager;
