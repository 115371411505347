import React from "react";
import { makeStyles } from "@mui/styles";

import FileIcon from "presentational/icons/fileIcon.js";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  container: { backgroundColor: "white" },
  icon: { marginRight: "0" },
  text: { marginBottom: "-4px" }
});

function FileDragImage({ variant, name }) {
  const classes = useStyles();

  return (
    <ListItem dense className={classes.container}>
      <ListItemIcon className={classes.icon}>
        <FileIcon variant={variant} />
      </ListItemIcon>
      <ListItemText className={classes.text}>
        <Typography variant="body1">{name}</Typography>
      </ListItemText>
    </ListItem>
  );
}

export default FileDragImage;
