import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import CourseWrapper from "presentational/wrappers/courseWrapper.js";
import { BTypography, Blink } from "bild-ui";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  course: { height: "100%", width: "100%" },
  courseContent: { height: "100%" },
  competencySet: { paddingTop: "0.5rem" },
  competencyProofDate: { paddingTop: "0.5rem" },
});

export default function AuditAntiochTranscriptCourse({ user, course }) {
  const cls = useStyles();
  const groupColor = course.courseGroupColor.webFrontendValue;
  const lastApprovedComp = course.competencies.map(x => x.competencyProof).filter(x => x.complete === true).sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];

  let header = (
    <Grid container justifyContent="space-between">
      <Grid item>
        <i className={`${course.complete ? "fas" : "far"} fa-circle`} />
      </Grid>
      <Grid item>
        <BTypography variant="h6">{course.courseNumber}</BTypography>
      </Grid>
      <Grid item>
        <i className={`fas ${course.familyGroupIcon.webFrontendValue}`} />
      </Grid>
      <Grid item xs={12} className={cls.competencySet}>
        <BTypography variant="body2">Competency Set: <b>{course.competencySet.handbookName}</b></BTypography>
      </Grid>
      <Grid item xs={12} className={cls.competencyProofDate}>
        <BTypography variant="body2">Last Approved Competency: <b>{lastApprovedComp ? lastApprovedComp.updatedAt : "n/a"}</b></BTypography>
      </Grid>
    </Grid>
  );

  let competencies = [];
  for (var j = 0; j < course.competencies.length; j++) {
    let cmp = course.competencies[j];
    competencies.push(<i className={`${cmp.competencyProof.complete ? "fas" : "far"} fa-circle`} style={{ color: groupColor }} key={"cmp1" + j}/>);
  }
  for (var j = competencies.length; j < course.competencySet.competencyCount && j < 20; j++) {
    competencies.push(<i className={`far fa-circle`} style={{ color: groupColor }} key={"cmp2" + j}/>);
  }
  if (course.competencySet.competencyCount >= 20) {
    competencies.push(<span style={{ color: groupColor }} key={"cmp3" + j}> +{course.competencySet.competencyCount - 20} more... </span>);
  }

  let components = [];
  components.push(
    <Grid item lg={2} md={4} sm={6} xs={12} key={course.competencySet.id}>
      <Grid
        container
        className={cls.course}
        component={Blink}
        dst={"user-audit-antioch-course"}
        course_id={course.id}
        user_id={user && user.id}
        competency_set_id={course.competencySet.id}
      >
        <CourseWrapper variant="dashboard" color={groupColor} header={header} className={cls.course}>
          <Grid container direction="column" justifyContent="space-between" className={cls.courseContent}>
            <Grid item>
              <BTypography variant="body1">{course.shortName == null ? ReactHtmlParser(course.name) : ReactHtmlParser(course.shortName)}</BTypography>
            </Grid>
            <Grid item>{competencies}</Grid>
          </Grid>
        </CourseWrapper>
      </Grid>
    </Grid>
  );

  return components;
}
