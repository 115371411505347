import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Dialog } from "@mui/material";
import { colors, Blink, NormalButton, BTypography, TreeDiagram, SortableList, FormTextField } from "bild-ui";

const useStyles = makeStyles({
  submitButton: { padding: "0.5rem" },
  confirmDialog: { padding: "1rem 2rem" },
  confirmButton: { margin: "0.5rem 0", padding: "1rem 2rem" },
  roleName: { padding: "2rem" },
  rolesTitle: { padding: "2rem 0 0.5rem 0" }
});

function UserAdminOrganizations({ orgTree, user, generalRoles, userRoles, setRoleOpen, removeRole }) {
  const cls = useStyles();
  const [confirmRole, setConfirmRole] = useState({});

  return (
    <Grid container item xs={12}>
      {orgTree && orgTree.length > 0 && (
        <Grid item xs={12} className={cls.diagram}>
          <TreeDiagram data={orgTree} scalable={true} legend={false} />
        </Grid>
      )}
      <Grid container item justifyContent="flex-end" alignItems="center">
        <Grid item xs className={cls.rolesTitle}>
          <BTypography variant="h5">Roles</BTypography>
        </Grid>
        <Grid item>
          <NormalButton
            variant="primary"
            color={colors.green}
            labelColor={colors.white}
            hoverColor={colors.green}
            className={cls.submitButton}
            onClick={() => {
              setRoleOpen(true);
            }}
          >
            <span className="fas fa-plus" /> &nbsp; Add
          </NormalButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          perPage={10}
          headers={["Organization", "Role", ""]}
          items={userRoles.map(x => [
            x.organizationOverview.organizationName,
            x.visualRole.description,
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <NormalButton
                  component={Grid}
                  item
                  variant="primary"
                  color={colors.darkRed}
                  labelColor={colors.white}
                  hoverColor={colors.darkerRed}
                  className={cls.submitButton}
                  onClick={() => {
                    setConfirmRole({
                      userRoleId: x.visualRole.userRoleId,
                      roleName: x.visualRole.description,
                      orgName: x.organizationOverview.organizationName
                    });
                  }}
                >
                  <span className="fas fa-times" /> &nbsp; Remove
                </NormalButton>
              </Grid>
            </Grid>
          ])}
          itemPadding={"0.5rem 1rem"}
          emptyMessage="No roles currently assigned."
        />
      </Grid>
      <Grid item xs={12} className={cls.rolesTitle}>
        <BTypography variant="h5">System Roles</BTypography>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          perPage={10}
          headers={["Role"]}
          items={generalRoles.sort((a, b) => a.description.localeCompare(b.description)).map(x => [x.description])}
          itemPadding={"0.5rem 1rem"}
          emptyMessage="No System roles currently assigned."
        />
      </Grid>
      <Dialog
        open={confirmRole.userRoleId ? true : false}
        onClose={() => {
          setConfirmRole({});
        }}
      >
        {confirmRole.userRoleId && (
          <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
            <Grid item className={cls.roleName}>
              <BTypography variant="h6">{user.name}</BTypography>
              <br />
              Remove <b>{confirmRole.roleName}</b> role from <b>{confirmRole.orgName}</b>?
            </Grid>
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="primary"
                color={colors.darkRed}
                labelColor={colors.white}
                hoverColor={colors.darkerRed}
                className={cls.confirmButton}
                onClick={() => {
                  removeRole(confirmRole.userRoleId);
                  setConfirmRole({});
                }}
              >
                Confirm Removal
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="secondary"
                color={colors.white}
                labelColor={colors.gray}
                borderColor={colors.gray}
                hoverColor={colors.darkGray}
                className={cls.confirmButton}
                onClick={() => {
                  setConfirmRole({});
                }}
              >
                Cancel
              </NormalButton>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </Grid>
  );
}

export default UserAdminOrganizations;
