import { getRequest } from "bild-data/core/comm.js";

//// REVIEW
export function load(success_cb, error_cb) {
  return getRequest(`/review/learners`, success_cb, null, error_cb);
}

//// REVIEW
export function loadShared(success_cb, error_cb) {
  return getRequest(`/review/shares`, success_cb, null, error_cb);
}

export function loadSharingGroup(group_id, success_cb, error_cb) {
  return getRequest(`/review/sharing_groups/${group_id}`, success_cb, null, error_cb);
}

export function loadSharingGroupPathItem(group_id, path_item_id, success_cb, error_cb) {
  return getRequest(`/review/sharing_groups/${group_id}/path_items/${path_item_id}`, success_cb, null, error_cb);
}


export function getShepherdsReview(success_cb, error_cb) {
  getRequest(
    `/review/shepherds`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getOrganizationShepherdsReview(organizationId, success_cb, error_cb) {
  getRequest(
    `/review/organizations/${organizationId}/shepherds`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}