import React, { useState } from "react";
import { putRequest } from "bild-data/core/comm.js";
import { toaster } from "presentational/toasts/toasts.js";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";

import LoadingScreen from "presentational/screens/loadingScreen.js";
import "./dialogs.css";

function ForgotPasswordDialog({ open, closeDialog, username, classes, ...props }) {
  const [form, setForm] = useState({ username: "" });
  const [error, setError] = useState({});

  function _handleSubmit() {
    if (_validateForm()) {
      setForm({ ...form, isLoading: true });
      putRequest(`/auth/forgot_password?email=${form.username}`, null, _handleSuccess, closeDialog, _handleFailure, false, true);
    }
  }

  function _handleSuccess() {
    // Request successfully recieved, let the user know and then close the dialog
    toaster.success(
      "Your request has been successfully sent. If that username exists in the system, you will recieve a email with further instructions."
    );
    setForm({ ...form, isLoading: false });
  }

  function _handleFailure(e) {
    // Things have failed, possible bad email address or illicit attempt, give "success" message anyway
    toaster.success(
      "Your request has been successfully sent. If that username exists in the system, you will recieve a email with further instructions."
    );
    setForm({ ...form, isLoading: false });
    console.log(e);
  }

  function _validateForm() {
    if (form.username.length < 6) {
      // TODO add in email pattern matching that can handle all our use cases (including periods ".")
      setError({ username: "Please enter a valid email address" });
      return false;
    } else {
      setError({});
      return true;
    }
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      _handleSubmit();
    }
  }

  let content = (
    <form onSubmit={_handleSubmit}>
      <Grid container direction="column" justifyContent="space-around" alignItems="stretch" className="forgot-password">
        <Grid item xs={12} className="form-row">
          <FormControl fullWidth error={error.username ? true : false}>
            <FormLabel>Email</FormLabel>
            <OutlinedInput
              id="username"
              fullWidth
              type="email"
              value={form.username}
              onChange={e => {
                _handleChange("username", e.target.value);
              }}
              onKeyDown={_handleKeyDown}
              autoFocus
            />
            {error.username && <FormHelperText>{error.username}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} className="form-row">
          <Button variant="contained" className="submit-button" onClick={_handleSubmit} onKeyDown={_handleKeyDown}>
            Request Password Reset
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  if (form.isLoading) {
    content = <LoadingScreen />;
  }

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent className="forgot-password-content">{content}</DialogContent>
    </Dialog>
  );
}

export default ForgotPasswordDialog;
