import React, { useState } from "react";
import { colors, BTypography, NormalButton, FilterTextField } from "bild-ui";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { UserPathThumbnails, UserPathList } from "equip";

const useStyles = makeStyles({
  headWrapper: {
    padding: "0.5rem 1rem"
  },
  head: {
    padding: "1rem",
    backgroundColor: colors.white
  },
  name: {
    fontWeight: "700"
  },
  pathsWrapper: {
    padding: "0.5rem 1rem"
  },
  paths: {
    backgroundColor: colors.white,
    padding: "0 0 4rem 0"
  },
  buttons: {
    padding: "1rem",
  },
  filter: {
    paddingRight: "1rem",
  },
});

function Learner({ user, paths }) {
  const cls = useStyles();
  const [thumbnail, setThumbnail] = useState(true);
  const [filter, setFilter] = useState("");

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.headWrapper}>
        <Grid item xs={12} className={cls.head}>
          <BTypography variant="h5" className={cls.name}>
            Hello, {user.name ? user.name.split(" ")[0] : user.name}!
          </BTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.pathsWrapper}>
        <Grid container item xs={12} className={cls.paths}>
          <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"} className={cls.buttons}>
            <Grid item xs>
              <BTypography variant="h5">Programs</BTypography>
            </Grid>
            <Grid item className={cls.filter}>
              {!thumbnail && (
                <FilterTextField
                  value={filter}
                  placeholder="Filter"
                  onChange={e => setFilter(e.target.value)}
                  onClear={() => {
                    setFilter("");
                  }}
                  inputPadding={"0.4rem 0.75rem"}
                />
              )}
            </Grid>
            <Grid item>
              <NormalButton
                variant={"primary"}
                color={colors.bildBlue}
                labelColor={colors.white}
                onClick={()=>{setThumbnail(!thumbnail)}}
              >
                <i className={thumbnail ? "fad fa-th-list" : "fad fa-th-large"} />
              </NormalButton>
            </Grid>
          </Grid>
          {thumbnail && <UserPathThumbnails paths={paths} allExpanded={true} />}
          {!thumbnail && <UserPathList paths={paths} filter={filter} />}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Learner;
