import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";

import { Hidden, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { blink, updateURL } from "bild-utils";
import { NormalButton, Blink } from "bild-ui";
import { searchData } from "bild-data";

const useStyles = makeStyles({
  Accordion: { maxWidth: "1100px", width: "100%" },
  AccordionSummary: { cursor: "auto !important" },
  avatarContainer: { marginRight: "0.5rem" },
  expansionButtonContainer: { display: "flex", alignItems: "stretch", height: "100%" },
  expansionButton: { display: "flex", justifyContent: "center" },
  infoContainer: { margin: "0 0.5rem", userSelect: "text" },
  orgAdminButton: { padding: "0.55rem" },
  buttonContainer: { marginLeft: "0.5rem", textAlign: "right" },
  buttonContainerXs: { margin: "0.5rem", textAlign: "right" },
  IDItems: { paddingRight: "1rem" },
  avatar: { fontSize: "24px", width: "48px" }
});

function OrganizationResult({ organization, expanded, isAdmin }) {
  const classes = useStyles();
  const expandAll = useRef(false);
  const internalExpand = useRef(false);
  const [internalExpanded, setInternalExpanded] = useState(false);

  if (!expandAll.current && expanded) setInternalExpanded(true);
  else if (expandAll.current && !expanded && !internalExpand.current) setInternalExpanded(false);
  expandAll.current = expanded;

  function _goToPage(e) {
    e.stopPropagation();

    searchData.updateOrganizationHistory(organization);

    updateURL(blink("manage-organization", { organization_id: organization.organizationId }));
  }

  function _goToAdminPage(e) {
    e.stopPropagation();

    searchData.updateOrganizationHistory(organization);

    updateURL(blink("organization-admin", { organization_id: organization.organizationId }));
  }

  return (
    <Accordion expanded={internalExpanded} className={classes.Accordion}>
      <AccordionSummary className={classes.AccordionSummary}>
        <Grid container direction="column">
          <Grid item container alignItems="center" wrap="nowrap">
            <Grid item className={classes.avatarContainer}>
              <span className={`${classes.avatar} fa-stack`}>
                <i className="fal fa-square fa-stack-2x" />
                <i className="fal fa-users fa-stack-1x" />
              </span>
            </Grid>
            <Grid item className={classes.expansionButtonContainer}>
              <NormalButton
                variant="simple"
                onClick={() => {
                  internalExpand.current = !internalExpanded;
                  setInternalExpanded(!internalExpanded);
                }}
                className={classes.expansionButton}
              >
                <i className={`fal fa-chevron-${internalExpanded ? "up" : "down"}`} />
              </NormalButton>
            </Grid>
            <Grid item xs className={classes.infoContainer}>
              <Typography variant="h5">{organization.organizationName}</Typography>
            </Grid>
            <Hidden smDown>
              <Grid item className={classes.buttonContainer}>
                <NormalButton variant="slim" onClick={_goToPage}>
                  View Organization
                </NormalButton>
              </Grid>
              {isAdmin && (
                <Grid item>
                  <NormalButton variant="simple" onClick={_goToAdminPage} className={classes.orgAdminButton}>
                    <i className="fal fa-cog" />
                  </NormalButton>
                </Grid>
              )}
              {!isAdmin && (
                <Grid item>
                  <NormalButton component={Blink} dst="manage-organization" organization_id={organization.organizationId} className={classes.orgAdminButton}>
                    <i className="fas fa-analytics" />
                  </NormalButton>
                </Grid>
              )}
            </Hidden>
          </Grid>

          <Hidden smUp>
            <Grid container item justifyContent="flex-end" className={classes.buttonContainerXs}>
              <NormalButton variant="slim" onClick={_goToPage}>
                View Organization
              </NormalButton>
              {isAdmin && (
                <Grid item>
                  <NormalButton variant="simple" onClick={_goToAdminPage} className={classes.orgAdminButton}>
                    <i className="fal fa-cog" />
                  </NormalButton>
                </Grid>
              )}
              {!isAdmin && (
                <Grid item>
                  <NormalButton component={Blink} dst="manage-organization" organization_id={organization.organizationId} className={classes.orgAdminButton}>
                    <i className="fas fa-analytics" />
                  </NormalButton>
                </Grid>
              )}
            </Grid>
          </Hidden>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column">
          <Grid item container>
            <Grid item className={classes.IDItems}>
              <Typography>Organization ID: {organization.organizationId}</Typography>
            </Grid>
            <Grid item>
              <Typography>Contact ID: {organization.organizationNumber ? organization.organizationNumber : "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default OrganizationResult;
