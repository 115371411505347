import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";

import Rubric from "./rubric.js";

const useStyles = makeStyles({
  closeButton: {
    color: "#3f51b5"
  }
});

function RubricDialog({ open, rubric, groupColor, assessmentLevel, onClose }) {
  const cls = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth scroll="paper">
      <DialogTitle>
        <i className="fal fa-th" /> Rubric Sample
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Rubric rubric={rubric} hideScores={true} groupColor={groupColor} assessmentLevel={assessmentLevel} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} className={cls.closeButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RubricDialog;
