import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { load } from "bild-data/implement";

// IMPLEMENTATION PATHWAY LOADER
function ImplementationPathway({}) {
  const location = useLocation();
  const [context, setContext] = useState("");

  useEffect(()=>{
    setPageTitle("Implementation Pathway");

    if (location.pathname.length > 11) {
      // this special line will pass whatever appears after "/implement" in the parent URL down into the iFrame
      setContext(location.pathname.substring(10) + location.search);
    }

    load();
  },[]);

  // Simply load an iframe pointing to implement.bild.org's page. Thanks @daviddear !
  return (
    <iframe
      src={"https://implement.bild.org" + context}
      style={{ width: "100%", height: "calc(100% - 7px)", border: "none" }}
      title="bild-implement"
    />
  );
}

export default ImplementationPathway;
