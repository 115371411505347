import React from "react";
import { makeStyles } from "@mui/styles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  hero: { backgroundColor: "#2a5082", paddingBottom: "5em", paddingTop: "5em", textAlign: "center", "& *": { color: "#FFF" } },
  body: { backgroundColor: "#FFF" },
  footer: { backgroundColor: "#20262a", color: "#fff", padding: "4em 0", textAlign: "center" }
});

// TERMS OF USE POLICY
function TermsOfUsePolicy() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" className={classes.body}>
      <Grid item xs={12} className={classes.hero}>
        <Typography variant="h3">Terms of Service</Typography>
      </Grid>
      <Grid item xs={8}>
        <p>
          These terms and conditions outline the rules and regulations for the use of BILD International's Website (https://cloud.bild.org). &nbsp;
        </p>
        <p>
          By accessing this website we assume you accept these terms and conditions in full. Do not continue to use BILD International's
          website&nbsp;if you do not accept all of the terms and conditions stated on this page.
        </p>
        <p>
          The following terminology applies to these Terms and Conditions, Privacy Statement and any or all Agreements: "Client", "You" and "Your"
          refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our"
          and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves.
          All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in
          the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the
          Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of. Any
          use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </p>
        <p>
          <strong>Cookies</strong>
          <br />
          We employ the use of cookies. By using BILD International's website you consent to the use of cookies in accordance with BILD
          International's privacy policy. Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each
          visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting.
        </p>
        <p>
          <strong>License</strong>
          <br />
          Unless otherwise stated, BILD International and/or it's licensors own the intellectual property rights for all material on BILD
          International. All intellectual property rights are reserved.
        </p>
        <p>
          <strong>You must not:</strong>
        </p>
        <ul>
          <li>Republish material from http://cloud.bild.org</li>
          <li>Sell, rent or sub-license material from http://cloud.bild.org</li>
          <li>Reproduce, duplicate or copy material from http://cloud.bild.org</li>
          <li>Redistribute content from BILD International (unless content is specifically made for redistribution).</li>
        </ul>
        <p>
          <strong>User Comments</strong>
          <br />
          This Agreement shall begin on the date hereof.
        </p>
        <p>
          Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material and data ('Comments') in
          areas of the website. BILD International does not screen, edit, publish or review Comments prior to their appearance on the website and
          Comments do not reflect the views or opinions of BILD International, its agents or affiliates. Comments reflect the view and opinion of the
          person who posts such view or opinion. To the extent permitted by applicable laws BILD Internationalshall&nbsp;not be responsible or liable
          for the Comments or for any loss cost, liability, damages or expenses caused and or suffered as a result of any use of and/or posting of
          and/or appearance of the Comments on this&nbsp;website.
        </p>
        <p>
          BILD International reserves the right to monitor all Comments and to remove any Comments which it considers&nbsp;in its absolute discretion
          to be inappropriate, offensive or otherwise in breach of these Terms and Conditions.
        </p>
        <p>You warrant and represent that:</p>
        <ul>
          <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
          <li>
            The Comments do not infringe any intellectual property right, including without limitation copyright,&nbsp;patent or trademark, or other
            proprietary right of any third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material&nbsp;or material which is an
            invasion of privacy.
          </li>
          <li>
            The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.&nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
          </li>
        </ul>
        <p>
          You hereby grant to BILD International a non-exclusive royalty-free license to use, reproduce, edit and authorize others to use, reproduce
          and edit any of your Comments in any and all forms, formats or media.
          <br />
          &nbsp;&nbsp; &nbsp;
          <br />
          No use of BILD International's logo or other artwork will be allowed for linking absent a trademark license agreement.
        </p>
        <p>
          <strong>Iframes</strong>
          <br />
          Without prior approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in
          any way the visual presentation or appearance of our Web site.
        </p>
        <p>
          <strong>Reservation of Rights</strong>
          <br />
          We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Web site. You
          agree to immediately remove all links to our Web site upon such request. We also reserve the right to amend these terms and conditions and
          its linking policy at any time. By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and
          conditions.
        </p>
        <p>
          <strong>Content Liability</strong>
          <br />
          We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all
          claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing
          content or materials that may be interpreted as&nbsp;libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the
          infringement or other violation of, any third party rights.
        </p>
        <p>
          <strong>Disclaimer</strong>
          <br />
          To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the
          use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose
          and/or the use of reasonable care and skill). Nothing in this disclaimer will:
        </p>
        <ul>
          <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
          <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
          <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
          <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>
        <p>
          The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including
          liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.
        </p>
        <p>
          To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any
          loss or damage of any nature.
        </p>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <img src={process.env.PUBLIC_URL + "/bild-logo.png"} alt="BILD International" width="80px" />
        <p>
          © {new Date().getFullYear()} BILD International
          <br />
          2400 Oakwood Road
          <br />
          Ames, IA 50014-8417
          <br />
          515-292-7012
        </p>
      </Grid>
    </Grid>
  );
}

export default TermsOfUsePolicy;
