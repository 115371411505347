import React, { useEffect, useState } from "react";
import Row from "./row.js";
import { Typography, Grid } from "@mui/material";
import "./rubric.css";

// RUBRIC
function Rubric({ rubric, rubricNumber, competencyId, onChange, submissionCount, assessmentLevel, groupColor, userCanAssess, userIsStudent, showTrainingHistory, userIsAssociateFaculty, assessmentFlowId, waitingOnTrainingRole, trainingCriteria, hideScores }) {
  const [score, setScore] = useState([]);
  const [localSubmissionCount, setLocalSubmissionCount] = useState(0);

  useEffect(()=>{
    // Clear the score if the user can no longer assess (most likely after submission)
    if (!userCanAssess) {
      setScore({});
    }

    // Clear the score if the assessment successfully submitted
    if (submissionCount > localSubmissionCount) {
      setScore({});
      setLocalSubmissionCount(submissionCount);
    }
  },[userCanAssess, submissionCount]);

  // Unashamedly stolen from Josh's code.
  function calculateIfPassed(score) {
    let isThreeGrid = rubric.threeGrid;

    let passed = true;
    let reviewPassed = true;
    let reviewValid = true;
    // Default to LM review
    let reviewTitle = "Competency Demonstrated";
    let reviewMessage = "You have assessed that the student's work demonstrates competency. Is that correct?";
    let reviewCaption = "The Associate Faculty will be notified that this competency is ready for their review.";
    // Switch to AF review if they are reviewing
    if (userIsAssociateFaculty) {
      reviewTitle = "Competency Valid for Credit!";
      reviewMessage = "You have assessed that the student's work demonstrates competency and is valid for credit. Is that correct?";
      reviewCaption = "The student will be notified that this competency is complete!";
    }
    // Set default "invalid" data messages
    let message, hintMessage;
    let title = "Competency Not Yet Demonstrated";
    let caption = "Please review or revise your artifacts and reasses.";
    if (!assessmentLevel || !rubric || !score) {
      return { passed: false };
    }

    let minScore = -1;
    let avgScore = -1;
    let reqCriteria = -1;
    let checkGradReq = false;
    if (assessmentLevel === 2) {
      //grad
      minScore = rubric.gradMinScore;
      avgScore = rubric.gradAvgScore;
      reqCriteria = rubric.gradCriteriaRequired;
      checkGradReq = true;
    } else {
      //undergrad default
      minScore = rubric.undergradMinScore;
      avgScore = rubric.undergradAvgScore;
      reqCriteria = rubric.undergradCriteriaRequired;
      checkGradReq = false;
    }

    //Iterate over the criteria in the rubric, search for a matching criteria in the scores.  Doing to so we can confirm all required are passed in.
    const criteria = rubric.criteria;
    let numberScored = 0;
    let cumulativeScore = 0;
    let totalRequired = 0;
    let requiredScored = 0;

    for (let i = 0; i < criteria.length; i++) {
      const c = rubric.criteria[i];
      const criterionScore = score[c.id];
      const required = (checkGradReq && c.gradRequired) || (!checkGradReq && c.undergradRequired);

      // fail: if required criteria but not "scored" or score is less than required minimum
      //       OR if additional criteria and the valid scores are all below the min score
      if ((required && (!criterionScore || criterionScore < minScore)) || (criterionScore !== 0 && criterionScore < minScore)) {
        if (userIsStudent) {
          passed = false;
          if (isThreeGrid) {
            message = 'You have selected either "Does Not Meet" or "Partially Meets" for one or more criteria.';
            hintMessage = 'To demonstrate competency, "Meets Criteria" must be selected for each criteria chosen.';
          } else {
            message = 'You have selected either "No Demonstration" or "Insufficient" for one or more criteria.';
            hintMessage =
              'To demonstrate competency, selections for all criteria must be at the level of "Marginal" or higher AND average a score of "Sufficient" or higher.';
          }
        } else {
          reviewTitle = "Competency Not Yet Demonstrated";
          reviewPassed = false;
          reviewMessage = "You have assessed that the student's work does not yet demonstrate competency. Is that correct?";
          reviewCaption = "The student will be notified that they need to reassess.";
        }
      }

      // Find out how many required criteria there are
      if (required) {
        totalRequired += 1;
      }

      // add Scores so we can check avg score and total number of scores passed in.
      if (criterionScore && criterionScore > 0) {
        numberScored += 1;
        cumulativeScore += criterionScore;
        // find out how many of the required criteria have a valid score
        if (required) {
          requiredScored += 1;
        }
      }
    }

    //Now check avg Score and total number of scores passed in to see if we meet the requirements
    //can not pass in more or less than the requried amount.
    if (cumulativeScore / numberScored < avgScore) {
      if (userIsStudent) {
        passed = false;
        message = 'Your assessment score did not average "Sufficient" or higher.';
        hintMessage = 'To demonstrate competency, combined scores must average "Sufficient" or higher.';
      } else {
        reviewTitle = "Competency Not Yet Demonstrated";
        reviewPassed = false;
        reviewMessage = "You have assessed that the student's work does not yet demonstrate competency. Is that correct?";
        reviewCaption = "The student will be notified that they need to reassess.";
      }
    }

    // Finally check if the user filled out the correct number of required and additional criteria
    if (requiredScored < totalRequired) {
      passed = false;
      reviewValid = false;
      title = "Not Enough Criteria Selected";
      message = "You have not made selections on the required number of criteria.";
      hintMessage = "To complete assessment, selections must be made for all Required Criteria plus the required number of Additional Critera.";
      caption = "Please make all needed selections and resubmit.";
    } else if (numberScored !== reqCriteria) {
      if (numberScored > reqCriteria) {
        title = "Too Many Additional Criteria Selected";
        message = "You have made selections on more than the required number of Additional Criteria.";
        hintMessage = "To complete assessment, only the required number of Additional Criteria may be selected.";
        caption = "Please revise the number of Additional Criteria selected and resubmit.";
      } else {
        title = "Not Enough Additional Criteria Selected";
        message = "You have not made selections on the required number of Additional Criteria.";
        hintMessage = "To complete assessment, selections must be made for the required number of Additional Criteria.";
        caption = "Please make selections on the required number of Additional Criteria and resubmit.";
      }

      passed = false;
      reviewValid = false;
    }

    return {
      passed: passed,
      title: title,
      message: message,
      hintMessage: hintMessage,
      caption: caption,
      reviewValid: reviewValid,
      reviewPassed: reviewPassed,
      reviewTitle: reviewTitle,
      reviewMessage: reviewMessage,
      reviewCaption: reviewCaption
    };
  }

 function handleChange(event) {
    // New object ensures proper update.
    const newScore = { ...score };
    const name = event.target.attributes["data-criterion-id"].value;
    newScore[name] = Number(event.target.value);

    // We assume undergrad
    const passData = calculateIfPassed(newScore);

    newScore["valid"] = passData.passed;
    newScore["title"] = passData.title;
    newScore["message"] = passData.message;
    newScore["hintMessage"] = passData.hintMessage;
    newScore["caption"] = passData.caption;
    if (!userIsStudent) {
      newScore["valid"] = passData.reviewValid;
      newScore["reviewPassed"] = passData.reviewPassed;
      newScore["reviewTitle"] = passData.reviewTitle;
      newScore["reviewMessage"] = passData.reviewMessage;
      newScore["reviewCaption"] = passData.reviewCaption;
    }
    setScore(newScore);

    if (userCanAssess && onChange && !hideScores) {
      onChange(newScore);
    }
  }

  function handleClear(event) {
    const newScore = { ...score };
    const name = event.target.attributes["data-criterion-id"].value;
    newScore[name] = 0;

    // We assume undergrad
    const passData = calculateIfPassed(newScore);

    newScore["valid"] = passData.passed;
    newScore["title"] = passData.title;
    newScore["message"] = passData.message;
    newScore["hintMessage"] = passData.hintMessage;
    newScore["caption"] = passData.caption;
    if (!userIsStudent) {
      newScore["valid"] = passData.reviewValid;
      newScore["reviewPassed"] = passData.reviewPassed;
      newScore["reviewTitle"] = passData.reviewTitle;
      newScore["reviewMessage"] = passData.reviewMessage;
      newScore["reviewCaption"] = passData.reviewCaption;
    }
    setScore(newScore);
    if (onChange) {
      onChange(newScore);
    }
  }

  function getRubricSection(criteria, type, header) {
    let _trainingCriteria = null;
    if (showTrainingHistory) {
      _trainingCriteria = trainingCriteria;
    }

    const components = [];
    for (let i = 0; i < criteria.length; i++) {
      const criterion = criteria[i];
      components.push(
        <Row
          criterion={criterion}
          score={score[criterion.id]}
          trainingCriteria={trainingCriteria ? trainingCriteria.find(x => x.id === criterion.id) : []}
          hideScores={hideScores}
          userCanAssess={userCanAssess}
          isThreeGrid={rubric.threeGrid === true}
          onChange={handleChange}
          onClear={handleClear}
          id={competencyId + "-" + criterion.id}
          key={i}
        />
      );
    }

    return (
      <div className={"rubric-section " + type} key={type}>
        <Typography variant="h6" style={{ color: groupColor }} className="rubric-title" gutterBottom>
          {header}
        </Typography>
        <div className="rubric-table-container">
          <Grid container direction="column" className="rubric-table">
            <Row header isThreeGrid={rubric.threeGrid} />
            {components}
          </Grid>
        </div>
      </div>
    );
  }

  // Return a section of rows for a rubric
  function getCriteria() {
    const level = assessmentLevel === 2 ? "grad" : "undergrad";

    const requiredCriteria = rubric.criteria.filter(c => c[level + "Required"]);
    const requiredSection = getRubricSection(requiredCriteria, "required-criteria", "Required Assessment Criteria");

    const additionalCriteria = rubric.criteria.filter(c => !c[level + "Required"]);
    if (additionalCriteria && additionalCriteria.length > 0) {
      const numRequired = rubric[level + "CriteriaRequired"] - requiredCriteria.length;
      const message = numRequired > 0 ? "Select " + numRequired : "None Required";
      const additionalSection = getRubricSection(additionalCriteria, "additional-criteria", "Additional Assessment Criteria - " + message);

      return [requiredSection, additionalSection];
    } else {
      return requiredSection;
    }
  }

  return (
    <form className={"rubric v2 " + (rubric.threeGrid === true ? "three-grid" : "")} id={rubricNumber}>
      {getCriteria()}
    </form>
  );
}

export default Rubric;
