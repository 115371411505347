import React, { useState, useEffect } from "react";
import { colors, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Select, MenuItem, TextField, Checkbox } from "@mui/material";

const useStyles = makeStyles({
  header: { paddingLeft: "1rem" },
  row: { padding: "0.25rem 1rem" },
  backButton: { borderRadius: "4px", border: `1px solid ${colors.darkGray}`, marginLeft: "1rem", padding: "1rem" },
  select: { width: "100%" },
  label: { textAlign: "right", paddingRight: "1rem" },
  textarea: { width: "100%", minHeight: "8rem", padding: "1rem" },
  required: { color: colors.red, fontSize: "1.5rem" },
  disabled: { opacity: "0.5" }
});

function FundraisingInteraction({ updateInteraction, setIsValid }) {
  const cls = useStyles();
  const [type, setType] = useState("");
  const [comm, setComm] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    // check validity of fields
    if (typeof setIsValid === "function") {
      if (type === "Talked Or Left Voice Mail" && comm.length > 0 && note.length > 0) {
        setIsValid(true);
      } else if (
        type !== "" &&
        type !== "Talked Or Left Voice Mail" &&
        ((comm.length > 0 && note.length > 0) || (comm.length === 0 && note.length === 0))
      ) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
    // Update parent fields on change
    _updateInteraction();
  }, [type, comm, note]);

  function _clear() {
    setType("");
    setComm("");
    setNote("");
  }

  function _updateInteraction() {
    let interaction = { type: type, comm: comm, note: note };
    updateInteraction(interaction);
  }

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.row} alignItems="center">
        <Grid item md={2} sm={3} xs={12} className={cls.label}>
          <BTypography variant="h6">
            Call Type <span className={cls.required}>*</span>
          </BTypography>
        </Grid>
        <Grid item md={10} sm={9} xs={12}>
          <Select
            variant="outlined"
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}
            className={cls.select}
          >
            <MenuItem value="No Answer - Just Rings">No Answer - Just Rings</MenuItem>
            <MenuItem value="No Answer - Automated/Unable to Complete">No Answer - Automated/Unable to Complete</MenuItem>
            <MenuItem value="No Answer - Mailbox Full/Not Set Up">No Answer - Mailbox Full/Not Set Up</MenuItem>
            <MenuItem value="Talked Or Left Voice Mail">Talked Or Left Voice Mail</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.row} alignItems="center">
        <Grid item md={2} sm={3} xs={12} className={cls.label}>
          <BTypography variant="h6">Call Communication {type === "Talked Or Left Voice Mail" && <span className={cls.required}>*</span>}</BTypography>
        </Grid>
        <Grid item md={10} sm={9} xs={12}>
          <Select
            variant="outlined"
            value={comm}
            onChange={e => {
              setComm(e.target.value);
            }}
            className={cls.select}
          >
            <MenuItem value="Left Voice Mail">Left Voice Mail</MenuItem>
            <MenuItem value="Complete Ask">Complete Ask</MenuItem>
            <MenuItem value="Incomplete Ask">Incomplete Ask</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.row} alignItems="center">
        <Grid item md={2} sm={3} xs={12} className={cls.label}>
          <BTypography variant="h6">Call Note {type === "Talked Or Left Voice Mail" && <span className={cls.required}>*</span>}</BTypography>
        </Grid>
        <Grid item md={10} sm={9} xs={12}>
          <textarea
            className={cls.textarea}
            value={note}
            onChange={e => {
              setNote(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FundraisingInteraction;
