import React from "react";

import { Grid } from "@mui/material";

function ListItem({ columns }) {
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      {columns.map((c, i) => (
        <Grid item xs key={i}>
          {c}
        </Grid>
      ))}
    </Grid>
  );
}

export default ListItem;
