import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors, Avatar, PopTip, Blink } from "bild-ui";
import BTypography from "../typography/bTypography";

const useStyles = makeStyles({
  titleWrapper: { paddingLeft: "0.4rem", overflowWrap: "normal" },
  link: { color: colors.lightBlue, textDecoration: "underline", "&:hover": { textDecoration: "none", color: colors.darkBlue } },
  title: { },
  subtitle: { color: colors.darkerGray },
  subtitleCircle: { paddingLeft: "0.25rem", }
});

export default function ProgramAvatar({ userPathId, size, icon, name, subtitle, src, srcSize, color, variant, nameVariant, subtitleVariant, showSubtitle, children }) {
  const cls = useStyles();
  nameVariant = nameVariant ? nameVariant : "body1"; // default to "body1" nameVariant
  subtitleVariant = subtitleVariant ? subtitleVariant : "body2"; // default to "body1" subtitleVariant

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Avatar size={size} icon={icon} name={name} src={src} srcSize={srcSize} color={color} variant={variant} children={children} />
      </Grid>
      <Grid container item xs className={cls.titleWrapper} justifyContent="flex-start" alignItems="center">
        <Grid component={userPathId ? Blink : null} dst="equip-user-path" user_path_id={userPathId} item className={`${userPathId ? cls.link : ""} ${cls.title}`}>
          <BTypography variant={nameVariant}>{ReactHtmlParser(name)}</BTypography>
        </Grid>
        {subtitle && (
          <Grid item xs={showSubtitle ? 12 : null} className={cls.subtitle}>
            {showSubtitle && (
              <BTypography variant={subtitleVariant}>
                {ReactHtmlParser(subtitle)}
              </BTypography>
            )}
            {!showSubtitle && (
              <PopTip text={ReactHtmlParser(subtitle)} delay={1000}>
                <BTypography variant={subtitleVariant} className={cls.subtitleCircle}>
                  <i className="fal fa-info-circle" />
                </BTypography>
              </PopTip>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
