import React, { useEffect, useState } from "react";
import { colors, BTypography, SortableList, UserAvatar, Blink, NormalButton, FilterTextField } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  header: {
    background: colors.white,
    padding: "1rem",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 100
  },
  body: { padding: "0rem" },
  title: { paddingLeft: "0.25rem" }
});

function AvailableCertificates({ certificates, certificateId, setCertificateId }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [certRowItems, setCertRowItems] = useState([]);
  const [filteredCerts, setFilteredCerts] = useState([]);

  // Set LEFT/RIGHT ARROW keybinds for navigation
  useEffect(() => {
    const keyPressHandler = e => {
      if (certificateId != null) {
        let currentIndex = filteredCerts.map(fc => fc.id).indexOf(certificateId);
        if (currentIndex > 0 && e.key === "ArrowLeft") {
          setCertificateId(filteredCerts[currentIndex - 1].id);
        } else if (currentIndex < filteredCerts.length - 1 && e.key === "ArrowRight") {
          setCertificateId(filteredCerts[currentIndex + 1].id);
        }
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [certificateId]);

  useEffect(() => {
    // Set filteredCertificates seperately from RowItems
    const fc = certificates.filter(x => {
      if (filter.length > 0) {
        return (x.name && x.name.toLowerCase().includes(filter.toLowerCase())) || (x.path.name && x.path.name.toLowerCase().includes(filter.toLowerCase()));
      } else {
        return 1;
      }
    });
    setFilteredCerts(fc);
    setCertRowItems(
      fc.map((x, i) => [
        [
          <UserAvatar
            size={30}
            icon={"fas fa-badge-check"}
            color={x.path.programColor.webFrontendValue}
            name={x.path.name}
            sortval={x.path.name}
            key={`a${i}`}
          />
        ],
        [
          <Grid container sortval={x.name} alignItems="center" key={`b${i}`}>
            <Grid item>
              <BTypography variant="body1" className={cls.title}>
                {x.name}
              </BTypography>
            </Grid>
          </Grid>
        ],
        <Grid container justifyContent="flex-end">
          <Grid item>
            <NormalButton
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              onClick={() => {
                setCertificateId(x.id);
              }}
            >
              View &nbsp; <i className="fas fa-chevron-right" />
            </NormalButton>
          </Grid>
        </Grid>
      ])
    );
  }, [certificates, filter]);

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} alignItems="center" justifyContent="space-between">
        <Grid container item xs>
          <BTypography variant="h4">Available Certificates</BTypography>
        </Grid>
        <Grid container item xs alignItems="center" justifyContent="flex-end">
          <Grid item>
            <NormalButton
              component={Blink}
              dst="all-user-certificates"
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              borderColor={colors.bildBlue}
            >
              All User Certificates
            </NormalButton>
          </Grid>
          <Grid item>
            <FilterTextField
              value={filter}
              placeholder="Filter"
              onChange={e => setFilter(e.target.value)}
              onClear={() => {
                setFilter("");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <SortableList
          perPage={10}
          filter={filter}
          headers={["Program", "Certificate", ""]}
          items={certRowItems}
          itemPadding={"0.5rem 1rem"}
          spacing={[6, 4, 2]}
        />
      </Grid>
    </Grid>
  );
}

export default AvailableCertificates;
