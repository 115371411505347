import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { BTypography, PaginationList, PopTip, SortButton } from "bild-ui";
import List from "./list.js";

const useStyles = makeStyles({
  header: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "1",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  sortHeader: { cursor: "pointer" }
});

function SortableList({
  perPage,
  filter,
  title,
  details,
  headers,
  sortableColumns,
  hiddenColumns,
  items,
  itemType,
  emptyMessage,
  spacing,
  backgroundColor,
  rowColor,
  evenRowColor,
  itemPadding,
  defaultSortCol,
  defaultSortDir,
  fixedHeight
}) {
  const cls = useStyles();
  const [sortCol, setSortCol] = useState(defaultSortCol ? defaultSortCol : 0); // first header
  const [sortDir, setSortDir] = useState(defaultSortDir ? defaultSortDir : 0); // 2: descending / 0: nothing / 1: ascending
  const [sortableCols, setSortableCols] = useState([]);

  useEffect(() => {
    sortList(defaultSortCol ? defaultSortCol : 0); // Sort by first column
    setSortableCols(sortableColumns ? sortableColumns : headers.map((x, i) => i));
  }, [defaultSortCol]);

  useEffect(() => {
    setSortDir(defaultSortDir ? defaultSortDir : 0); // 2: descending / 0: nothing / 1: ascending
  }, [defaultSortDir]);

  function sortArrayVal(rootArr) {
    // if this is an Array object, try to get the key values. If not, try child array and finally escape to sortable "zzz"
    // NOTE: Must check for not null (and possibly undefined), otherwise 0 breaks this check
    if (rootArr[0].props.sortval !== null) {
      return rootArr[0].props.sortval;
    } else if (rootArr[0].props.children[0] && rootArr[0].props.children[0].props.sortval !== null) {
      return rootArr[0].props.children[0].props.sortval;
    } else {
      return "zzz";
    }
  }

  function sortList(col) {
    // figure out what data type the field is and sort accordingly, this only works if a column has the same type of data
    items.sort((a, b) => {
      // set default sort values
      let aVal = a[col];
      let bVal = b[col];

      if (Array.isArray(aVal)) {
        aVal = sortArrayVal(aVal);
        bVal = sortArrayVal(bVal);
      }

      if (!isNaN(aVal)) {
        // 1. number sort
        return aVal - bVal;
      } else if (!isNaN(Date.parse(aVal))) {
        // 2. date sort
        return new Date(aVal) - new Date(bVal);
      } else {
        // 3. text sort
        return aVal.localeCompare(bVal);
      }
    });

    // Set sort dir and reverse items if needed
    if (col === sortCol) {
      if (sortDir === 1) {
        setSortDir(2);
        items.reverse();
      } else {
        setSortDir(1);
      }
    } else {
      setSortDir(1);
    }

    setSortCol(col);
  }

  if (items) {
    // Set Headers
    const listHeadings = [];
    for (let i = 0; i < headers.length; i++) {
      const isSortable = sortableCols.some(x => x === i);
      listHeadings.push(
        <PopTip
          text={headers[i] + (headers[i] !== "" && isSortable && (sortCol === i ? (sortDir === 1 ? " ↑" : " ↓") : ""))}
        >
          <BTypography
            variant="h6"
            className={`${cls.header} ${isSortable ? cls.sortHeader: ""}`}
            onClick={() => {
              if (isSortable) {
                sortList(i);
              }
            }}
          >
            {headers[i]}
            {headers[i] !== "" && isSortable && (
              <SortButton
                value={sortCol === i ? (sortDir === 1 ? "ascending" : "descending") : "off"}
                onChange={() => {
                  sortList(i);
                }}
              />
            )}
          </BTypography>
        </PopTip>
      );
    }

    // Set Items
    let listItems = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let listItem = [];
      for (let j = 0; j < item.length; j++) {
        listItem.push(item[j]);
      }
      listItems.push(listItem);
    }

    if (hiddenColumns) {
      // Remove header columns if hiddenColumns passed in
      for (let i = 0; i < listHeadings.length; i++) {
        if (hiddenColumns.some(x => x===i)) {
          delete listHeadings[i];
        }
      }

      // Remove item columns if hiddenColumns passed in
      for (let i = 0; i < listItems.length; i++) {
        for (let j = 0; j < listItems.length; j++) {
          let listItem = listItems[i];
          if (hiddenColumns.some(x => x===j)) {
            delete listItem[j];
          }
        }
      }
    }

    if (perPage) {
      return (
        <PaginationList
          perPage={perPage}
          filter={filter}
          title={title}
          details={details}
          headings={listHeadings}
          items={listItems}
          itemType={itemType}
          emptyMessage={emptyMessage}
          spacing={spacing}
          backgroundColor={backgroundColor}
          rowColor={rowColor}
          evenRowColor={evenRowColor}
          itemPadding={itemPadding}
          fixedHeight={fixedHeight}
        />
      );
    } else {
      return (
        <List
          filter={filter}
          title={title}
          details={details}
          headings={listHeadings}
          items={listItems}
          itemType={itemType}
          emptyMessage={emptyMessage}
          spacing={spacing}
          backgroundColor={backgroundColor}
          rowColor={rowColor}
          evenRowColor={evenRowColor}
          itemPadding={itemPadding}
          fixedHeight={fixedHeight}
        />
      );
    }
  } else {
    return null;
  }
}

export default SortableList;
