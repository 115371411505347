import React from "react";
import { makeStyles } from "@mui/styles";
import { BTypography, colors, NormalButton, Blink, Glower, Fader } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: { padding: "1rem" },
  messageWrapper: { border: `3px solid ${colors.bildBlue}`, padding: "1rem" },
  message: { fontWeight: "bold" },
  link: { color: colors.blue }
});

function CertificateDashboard({ newCertCount }) {
  const cls = useStyles();

  return (
    <Grid container item xs={12} className={cls.container}>
      <Glower glowOn={newCertCount > 0} color={colors.lightBlue}>
        <Fader fadeIn={newCertCount > 0}>
          <Grid container item xs={12} className={cls.messageWrapper} justifyContent="space-between" alignItems="center">
            <Grid item>
              <BTypography variant="h6" className={cls.message}>
                You have {newCertCount > 1 ? "new Certificates" : "a new Certificate"} available!
              </BTypography>
            </Grid>
            <Grid item>
              <NormalButton
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
                component={Blink}
                dst={"user-certificates"}
              >
                View Certificate{newCertCount > 1 ? "s" : ""} &nbsp; <i className="fas fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
        </Fader>
      </Glower>
    </Grid>
  );
}

export default CertificateDashboard;
