import React, { useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";

import { Editable, Slate } from "slate-react";
import { Transforms } from "slate";

import { Grid } from "@mui/material";

import { CommentToolbar, Leaf, Element, constants, useMessageEditor } from ".";

const useStyles = makeStyles({
  container: { minHeight: "5rem", width: "100%", borderRadius: "4px", border: "1px solid darkgrey", backgroundColor: "white" },
  editableContainer: { padding: "0.75rem", maxHeight: "8rem" },
  editable: { maxHeight: "7rem", overflowY: "auto" }
});

function MessageEditor({ onSend }) {
  const classes = useStyles();
  const [value, setValue] = useState(constants.initialValue);
  const editor = useMessageEditor();

  function _sendMessage() {
    if (value !== constants.initialValue) {
      if (typeof onSend === "function") onSend(value);
      if (editor) {
        // Loop through all children and delete them
        editor.children.map(item => {
          Transforms.delete(editor, { at: [0] })
        })
        // Reset children to initialValue
        editor.children = constants.initialValue;
        // Need to reset the selector to the very start, otherwise it used to blow up
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 }
        });
        // Reset the value so we can use it for verifying blank messages are not sent
        setValue(constants.initialValue);
      }
    }
  }

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} s />, []);
  return (
    <Slate editor={editor} value={value} onChange={newValue => setValue(newValue)} key={value}>
      <Grid container className={classes.container} direction="column" wrap="nowrap">
        <Grid item xs className={classes.editableContainer}>
          <Editable
            id="bild-message-editor"
            placeholder="Type a message"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            className={`${classes.editable} notranslate`}
            onKeyDown={e => {
              if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                _sendMessage();
              }
            }}
          />
        </Grid>
        <Grid item>
          <CommentToolbar marks={constants.marks} blocks={constants.blocks} onSendIntent={_sendMessage} />
        </Grid>
      </Grid>
    </Slate>
  );
}

export default MessageEditor;
