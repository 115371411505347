import React, { useState } from "react";
import { userLearningItemData, userData } from "bild-data";
import { LoadingScreen } from "bild-ui";
import Evaluation from "./evaluation.js";

function EvaluationLoader({ userPathItemId, userLearningItemId, evaluation, setEvaluation }) {
  const [loading] = useState(false); // [loading, setLoading]
  const [error, setError] = useState(null);
  const [user] = useState(userData.getOverview().user || []); // [user, setUser]

  function _setEvaluation(d) {
    setEvaluation(d.evaluation);
  }

  async function submitEvaluationScore(evaluationGroupId, evaluationItemId, score) {
    const tempEvaluation = JSON.parse(JSON.stringify(evaluation)); // need to a deep copy of the object? could we just use a shallow copy
    const evaluationGroupIndex = tempEvaluation.evaluationGroups.findIndex(x => x.id === evaluationGroupId);
    const evaluationIndex = tempEvaluation.evaluationGroups[evaluationGroupIndex].evaluationItems.findIndex(x => x.id === evaluationItemId);
    const scoreIndex = tempEvaluation.evaluationGroups[evaluationGroupIndex].evaluationItems[evaluationIndex].scores.findIndex(
      x => x.user.id === user.id
    );

    if (scoreIndex < 0) {
      tempEvaluation.evaluationGroups[evaluationGroupIndex].evaluationItems[evaluationIndex].scores.push({
        value: score,
        user: {
          id: user.id
        }
      });
    } else {
      tempEvaluation.evaluationGroups[evaluationGroupIndex].evaluationItems[evaluationIndex].scores[scoreIndex].value = score;
    }

    userLearningItemData.submitEvaluation(userLearningItemId, userPathItemId, tempEvaluation, _setEvaluation, e => {
      setError(e.response.data.error);
    });
  }

  async function addEvaluationComment(evaluationGroupId, evaluationItemId, comment) {
    const tempEvaluation = JSON.parse(JSON.stringify(evaluation)); // need to a deep copy of the object? could we just use a shallow copy
    const evaluationGroupIndex = tempEvaluation.evaluationGroups.findIndex(x => x.id === evaluationGroupId);
    const evaluationIndex = tempEvaluation.evaluationGroups[evaluationGroupIndex].evaluationItems.findIndex(x => x.id === evaluationItemId);

    if (comment && comment.value && comment.value !== "") {
      const { user, value } = comment;
      tempEvaluation.evaluationGroups[evaluationGroupIndex].evaluationItems[evaluationIndex].comments.push({ user, value });
      userLearningItemData.submitEvaluation(userLearningItemId, userPathItemId, tempEvaluation, _setEvaluation, e => {
        setError(e.response.data.error);
      });
    }
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return <Evaluation evaluation={evaluation} user={user} submitEvaluationScore={submitEvaluationScore} addEvaluationComment={addEvaluationComment} />;
}

export default EvaluationLoader;
