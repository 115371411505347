import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Blink, BTypography, NormalButton, colors } from "bild-ui";
import { createAndDownloadFile } from "bild-utils";
import Bbutton from "presentational/input/bbutton.js";
import List from "presentational/lists/list.js";

const useStyles = makeStyles({
  header: { padding: "1rem" },
  download: { marginBottom: "0.5rem" },
  reportDescription: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: "4px",
    background: colors.veryLightGray,
    padding: "0 1rem",
    margin: "1rem 0"
  },
  complete: { color: colors.green, fontWeight: "bold" }
});

function TotalStudentProgress({ dateRan, organizations, combined }) {
  const cls = useStyles();
  const headers = [
    "Organization",
    "Name",
    "Email",
    "Acts",
    "Essentials",
    "Leaders",
    "Pauline",
    "Life(n) Initial Plan",
    "Ministry Practicum",
    "SIMA Identifying",
    "Teaching FPS I",
    "Teaching FPS II",
    "Total"
  ];
  const headerIds = [18, 20, 21, 19, 99, 61, 1, 113, 114];
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const today = new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "");

  useEffect(() => {
    let orgs = [];
    let newData = [];
    let sortOrgs = organizations.sort((a, b) => a.organizationName.localeCompare(b.organizationName));
    for (let i = 0; i < sortOrgs.length; i++) {
      let org = sortOrgs[i];
      for (let j = 0; j < org.students.length; j++) {
        let row = [];
        let student = org.students[j];
        let studentProgress = 0;
        let totalProgress = 0;
        row.push(org.organizationName);
        row.push(student.userName);
        row.push(student.userEmail);
        for (let k = 0; k < headerIds.length; k++) {
          let hId = headerIds[k];
          let moduleValue = "N/A";
          for (let l = 0; l < student.modules.length; l++) {
            let module = student.modules[l];
            if (hId === module.moduleId) {
              // Ministry Practicums should count credits
              if (hId === 61) {
                studentProgress += module.completedCredits;
                moduleValue = `${module.completedCredits ? module.completedCredits : "0"}/${module.totalCompetencies}`;
              } else {
                studentProgress += module.completedCompetencies;
                moduleValue = `${module.completedCompetencies ? module.completedCompetencies : "0"}/${module.totalCompetencies}`;
              }
              totalProgress += module.totalCompetencies;
            }
          }
          row.push(moduleValue);
        }
        row.push(`${Math.round((studentProgress / totalProgress) * 100)}%`);
        newData.push(row);
      }
    }
    setData(newData);

    let newCsvData = headers;
    for (let i = 0; i < newData.length; i++) {
      let d = newData[i];
      newCsvData += "\n" + d.join(",").replaceAll("/", "\\");
    }
    setCsvData(newCsvData);
  }, [organizations]);

  function getRows() {
    let orgHeaders = headers.map(h => (
      <Grid item xs key={h}>
        {h}
      </Grid>
    ));

    let students = data.map(x => {
      return [
        <Grid container item>
          {x.map(y => (
            <Grid item xs className={y.split("/")[0] === y.split("/")[1] ? cls.complete : ""}>
              {y}
            </Grid>
          ))}
        </Grid>
      ];
    });

    return <List title={`Total Student Progress`} details={dateRan} headings={orgHeaders} items={students} />;
  }

  return (
    <Grid container>
      <Grid item xs={12} className={cls.reportDescription}>
        <BTypography variant="h6" className={cls.header}>
          Report of student competencies validated in a course compared to number of competencies in the course
        </BTypography>
      </Grid>
      <Grid item xs={12}>
        <NormalButton
          onClick={() => {
            createAndDownloadFile(`BILD_Cloud_All_Student_Progress_${today}.csv`, csvData);
          }}
          variant="simple"
          color={colors.lightBlue}
          labelColor={colors.white}
          className={cls.download}
        >
          Download Data
        </NormalButton>
      </Grid>
      <Grid item xs={12}>
        {getRows()}
      </Grid>
    </Grid>
  );
}

export default TotalStudentProgress;
