import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, FormField, Avatar, LoadingScreen } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Box, TextField, InputAdornment, Autocomplete, ListItemText } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  field: {},
  small: { "&&& input": { padding: "0.75rem 4rem 0.75rem 0.75rem !important" } },
  avatar: { padding: "1.25rem 0" },
  selectedAvatar: { padding: "1.25rem 0 1.25rem 0.5rem !important", marginRight: "-5px" },
  errorWrapper: { color: colors.red, "& fieldset": { borderColor: colors.red } }
});

const filterOptions = createFilterOptions({
  limit: 100
});

function FormAutocompleteField({
  label,
  placeholder,
  variant,
  value,
  setValue,
  items,
  disabled,
  loading,
  error,
  required,
  classes,
  showAvatar,
  showIcon,
  iconColor,
  color
}) {
  const cls = useStyles();
  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    // reset localValue if value is reset/null
    if (!value && localValue) {
      setLocalValue(null);
    }
    // set localValue if value is set and localValue is not or if both are set but localValue does not match value
    if ((!localValue && value) || (value && localValue && value !== localValue)) {
      let values = items.filter(x => x.id.toString() === value.toString());
      setLocalValue(values ? values[0] : null);
    }
  }, [value]);

  function _setValue(newValue) {
    setLocalValue(newValue);
    setValue(newValue !== null ? newValue.id : newValue);
  }

  if (loading) return <LoadingScreen size={25} />;
  // renderInput={params => <TextField {...params} variant="outlined" placeholder={placeholder} />}
  return (
    <FormField
      label={label}
      color={color}
      error={error}
      required={required}
      child={
        <Autocomplete
          filterOptions={filterOptions}
          options={items}
          getOptionDisabled={option => option && option.disabled}
          isOptionEqualToValue={option => option && localValue && option.id.toString() === localValue.id.toString()}
          getOptionLabel={items => items.subtitle ? items.name.concat(' — ', items.subtitle) : items.name || ""}
          value={localValue}
          onChange={(_event, newValue) => {
            _setValue(newValue);
          }}
          className={`${cls.field} ${variant ? cls[variant] : ""}`}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {showAvatar && (
                <InputAdornment position="start" className={cls.avatar}>
                  <Avatar size={40} name={option.name} src={option.avatarURL} srcSize="small" icon={showIcon} color={iconColor} />
                </InputAdornment>
              )}
              <ListItemText primary={option.name} secondary={option.subtitle ? ReactHtmlParser(option.subtitle) : null} className={cls.name}/>
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={items.length > 100 ? "Type to see more..." : placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Box>
                    {showAvatar && localValue && (
                      <InputAdornment position="start" className={cls.selectedAvatar}>
                        <Avatar size={40} name={localValue.name} src={localValue.avatarURL} srcSize="small" icon={showIcon} color={iconColor} />
                      </InputAdornment>
                    )}
                    {params.InputProps.startAdornment}
                  </Box>
                )
              }}
            />
          )}
        />
      }
    />
  );
}

export default FormAutocompleteField;
