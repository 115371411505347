import React, { useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";

import BreadCrumbs from "./breadCrumbs.js";
import SearchBar from "presentational/input/searchBar.js";
import FolioList from "./folioList.js";
import FolioDisplayItem from "./folioDisplayItem.js";
import FileActionButton from "./fileActionButton.js";
import FolioDialog from "./folioDialog.js";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Disk from "../utils/disk.js";
import { useSearchRoot, useAddFolderDialog } from "../utils/folioHooks";

import { useWidth } from "bild-utils";

const useStyles = makeStyles({
  dialog: { margin: "2rem" },
  dialogSmall: { margin: "0.5rem 0" },
  dialogPaper: { borderRadius: "4px" },
  dialogTitle: { paddingBottom: "0.5rem" },
  dialogTitleSmall: { padding: "1rem 0.5rem 0.5rem 0.5rem" },
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  crumbs: {
    marginBottom: "0.25rem"
  },
  dialogContent: { paddingBottom: "0.25rem" },
  dialogContentSmall: { padding: "0.25rem 0.5rem", overflow: "unset" },
  fileListContainer: {
    flex: "1 1 auto",
    overflow: "hidden"
  },
  fileAction: {
    marginBottom: "0.5rem"
  },
  searchBar: {
    margin: "0"
  }
});

function FolioSelectorDialog({
  diskArrays,
  title,
  open,
  fileSelector,
  folderSelector,
  onClose,
  onSelect,
  onSubmit,
  onCreateFolder,
  setDisk,
  bodyOnly
}) {
  const classes = useStyles();

  // Build data structures from raw props.
  const { folders, files } = diskArrays || { folders: [], files: [] };
  const disk = useMemo(() => new Disk(folders, files), [folders, files]);

  // Custom hook to manage current root, and crumbs.
  const [contextRoot, setRoot, searchTerm, setSearchTerm, crumbs] = useSearchRoot(disk);
  const { folders: searchedFolders, files: searchedFiles } = disk.search(searchTerm, contextRoot);

  fileSelector = fileSelector === true || folderSelector !== true;
  folderSelector = !fileSelector;

  const [selection, setSelection] = useState(fileSelector ? [] : [contextRoot]);
  function _select(item) {
    if (item.selected) {
      _deselect(item);
      return;
    }

    item.selected = true;
    if (fileSelector) {
      selection.push(item);
      const newSelection = [...selection]; // new array instance else no rerender.
      setSelection(newSelection);

      if (onSelect) onSelect(newSelection);
    } else {
      if (selection[0]) selection[0].selected = false;
      setSelection([item]);

      if (onSelect) onSelect(item);
    }
  }
  function _deselect(item) {
    item.selected = false;
    if (fileSelector) {
      const newSelection = [...selection.filter(i => i.id !== item.id)];
      setSelection(newSelection);

      if (onSelect) onSelect(newSelection);
    } else {
      setSelection([contextRoot]);
      if (onSelect) onSelect(contextRoot);
    }
  }
  function _submit() {
    if (onSubmit && selection.length > 0) {
      if (fileSelector) onSubmit(selection);
      else onSubmit(selection[0]);
    }
  }

  function _cancel() {
    selection.forEach(i => (i.selected = false));
    setSelection([]);
    if (onClose) onClose();
  }

  function _isDisabled() {
    return selection.length < 1;
  }

  const showCreateFolder = setDisk && onCreateFolder;
  const [dialogProps, onCreateFolderIntent] = useAddFolderDialog(contextRoot, onCreateFolder, setDisk);

  const w = useWidth();
  const xs = w === "xs";

  const dialogContent = (
    <React.Fragment>
      <DialogTitle className={xs ? classes.dialogTitleSmall : classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <SearchBar className={classes.searchBar} onChange={setSearchTerm} value={searchTerm || ""} />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={xs ? classes.dialogContentSmall : classes.dialogContent}>
        <div className={classes.container}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <BreadCrumbs crumbs={crumbs} onGoToFolder={setRoot} className={classes.crumbs} />
            </Grid>

            {showCreateFolder && (
              <Grid item>
                <FileActionButton onClick={onCreateFolderIntent} variant="add-folder" />
              </Grid>
            )}
          </Grid>

          <FolioList
            folders={searchedFolders}
            files={searchedFiles}
            contextRoot={contextRoot}
            onChangeDirectory={setRoot}
            onSelect={_select}
            fileSelector={fileSelector}
            folderSelector={folderSelector}
            scrollable={!xs}
            className={classes.fileListContainer}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Grid container alignItems="flex-end">
          <Grid item xs>
            {selection.map(i => (
              <FolioDisplayItem
                variant={fileSelector ? "chip" : "display"}
                type={i.type}
                name={i.name}
                onDelete={i.isFile ? () => _deselect(i) : undefined}
                key={i.id}
              />
            ))}
          </Grid>
          {!bodyOnly && (
            <Grid item>
              <Grid container>
                <Button className="default-cancel-button" onClick={_cancel}>
                  Cancel
                </Button>
                <Button className="default-submit-button" onClick={_submit} disabled={_isDisabled()}>
                  Select
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogActions>
      {showCreateFolder && <FolioDialog contextRoot={contextRoot} {...dialogProps} />}
    </React.Fragment>
  );

  if (bodyOnly)
    return (
      <Grid container direction="column" style={{ height: "100%" }}>
        {dialogContent}
      </Grid>
    );

  return (
    <Dialog
      open={open === true}
      fullScreen
      onClose={_cancel}
      className={xs ? classes.dialogSmall : classes.dialog}
      PaperProps={{ className: classes.dialogPaper }}
    >
      {dialogContent}
    </Dialog>
  );
}

FolioSelectorDialog.defaultProps = {
  folderSelector: false
};

export default FolioSelectorDialog;
