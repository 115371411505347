import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import ListItem from "./listItem.js";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  container: props => ({
    background: props.backgroundColor ? props.backgroundColor : colors.white
  }),
  title: props => ({ padding: props.itemPadding ? props.itemPadding : "1rem" }),
  divider: { margin: "0.5rem 0 1rem 0" },
  listItem: props => ({
    background: props.rowColor ? props.rowColor : colors.white,
    padding: props.itemPadding ? props.itemPadding : "1rem",
    borderBottom: "1px solid "
  }),
  evenListItem: props => ({
    background: props.evenRowColor ? props.evenRowColor : colors.offWhite
  }),
  details: { textAlign: "right" },
  headerRow: { background: `${colors.lightGray} !important` },
  fixedBodyHeight: props => ({ height: props.fixedHeight, minHeight: props.fixedHeight, maxHeight: props.fixedHeight, overflow: "scroll" }),
  emptyMessage: { textAlign: "center", padding: "2rem" }
});

function List({ filter, title, details, headings, items, variant, emptyMessage, spacing, backgroundColor, rowColor, evenRowColor, itemPadding, fixedHeight }) {
  const cls = useStyles({ rowColor, evenRowColor, backgroundColor, itemPadding, fixedHeight });

  return (
    <Grid container className={cls.container} direction="column" justifyContent="space-between" alignItems="flex-start">
      {title && (
        <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={10}>
            <Typography variant="h5" className={cls.title}>
              {title}
            </Typography>
          </Grid>
          {details && (
            <Grid item xs className={cls.details}>
              {details}
            </Grid>
          )}
        </Grid>
      )}
      {headings && headings.length > 0 && (
        <Grid container item xs={12} direction="row"  className={`${cls.listItem} ${cls.headerRow}`}>
          <ListItem columns={headings} spacing={spacing} variant="header" />
        </Grid>
      )}
      <Grid container item xs={12} direction="column" className={fixedHeight ? cls.fixedBodyHeight : ""} justifyContent="flex-start" alignItems="flex-start">
        <Grid container item>
          {items.map((x, i) => (
            <Grid container item xs={12} direction="row" key={i} className={`${cls.listItem} ${i % 2 === 1 ? cls.evenListItem : ""}`}>
              <ListItem columns={x} spacing={spacing} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {items.length === 0 && emptyMessage && (
        <Grid item xs={12} key={emptyMessage} className={cls.emptyMessage}>
          {emptyMessage}
        </Grid>
      )}
    </Grid>
  );
}

export default List;
