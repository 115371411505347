import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Icon, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Popper, Fade } from "@mui/material";
import { NormalButton, colors, Blink, Avatar, UserAvatar } from "bild-ui";
import { blink, updateURL } from "bild-utils";
import { searchData } from "bild-data";

const useStyles = makeStyles({
  popperWrapper: {
    zIndex: "1000"
  },
  popper: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    borderRadius: "4px",
    backgroundColor: "white",
    width: "375px",
    maxWidth: "100%"
  },
  shortcutButton: {
    marginRight: "0.25rem",
    padding: "auto 0.5rem",
    backgroundColor: "green",
    color: "white",
    borderRadius: "4px",
    fontSize: "0.75rem",
    cursor: "pointer",
    height: "20px"
  },
  bottomLinks: {
    padding: "0.5rem 1rem"
  },
  avatar: {
    minWidth: "0",
    paddingRight: "0.5rem"
  },
  deleteButton: {
    marginRight: "-10px",
    padding: "8px"
  },
  deleteIcon: {
    fontSize: "18px"
  },
  masqueradeButton: {
    borderRadius: "4px",
    backgroundColor: "lightgrey",
    padding: "0.1rem 0.5rem 0 0.5rem",
    marginRight: "0.25rem"
  }
});

function SearchHistoryPopper({ searchTerm, anchorEl, canMasquerade, isAdmin, ...props }) {
  const classes = useStyles();

  const [history, setHistory] = useState(searchData.getHistory());
  const userHistory = (history && history.users) || [];
  const orgHistory = (history && history.organizations) || [];

  function _removeUser(id) {
    return () => {
      searchData.removeUserFromHistory(id);
      setHistory(searchData.getHistory());
    };
  }

  function _removeOrg(id) {
    return () => {
      searchData.removeOrganizationFromHistory(id);
      setHistory(searchData.getHistory());
    };
  }

  function _masquerade(user) {
    return () => {
      searchData.updateUserHistory(user);
      updateURL(blink("masquerade", { user_id: user.id }));
    };
  }

  function _goToUserAdmin(user) {
    return () => {
      searchData.updateUserHistory(user);
      updateURL(blink("user-admin", { user_id: user.id }));
    };
  }

  function _goToOrg(org) {
    return () => {
      searchData.updateOrganizationHistory(org);
      updateURL(blink("manage-organization", { organization_id: org.organizationId }));
    };
  }

  function _goToOrgAdmin(org) {
    return () => {
      searchData.updateOrganizationHistory(org);
      updateURL(blink("organization-admin", { organization_id: org.organizationId }));
    };
  }

  const st = searchTerm && searchTerm.toLowerCase();
  const userListItems = userHistory
    .filter(hi => hi && hi.name && hi.name.toLowerCase().includes(st))
    .map((hi, i) => (
      <ListItem key={i}>
        <ListItemAvatar className={classes.avatar}>
          <UserAvatar size={30} src={hi.avatarURL} name={hi.name} srcSize="small" badgeSrc={hi.badgeURL} />
        </ListItemAvatar>
        <ListItemSecondaryAction>
          {canMasquerade && (
            <NormalButton variant="micro" onClick={_masquerade(hi)} labelColor={colors.black}>
              <i className="fas fa-mask" />
            </NormalButton>
          )}

          {isAdmin && (
            <NormalButton variant="micro" onClick={_goToUserAdmin(hi)} labelColor={colors.black}>
              <i className="fal fa-cog" />
            </NormalButton>
          )}
          {!isAdmin && (
            <NormalButton variant="micro" component={Blink} dst="manage-user" user_id={hi.id}>
              <i className="fas fa-user-chart" />
            </NormalButton>
          )}

          <IconButton
            className={classes.deleteButton}
            onClick={_removeUser(hi.id)}
            size="large">
            <Icon className={`${classes.deleteIcon} fal fa-times fa-md`} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  const orgListItems = orgHistory
    .filter(hi => hi && hi.organizationName && hi.organizationName.toLowerCase().includes(st))
    .map((hi, i) => (
      <ListItem key={i}>
        <ListItemAvatar className={classes.avatar}>
          <Avatar size={30} src={hi.avatarURL} name={hi.organizationName} icon={"fal fa-users"} variant={"square"} srcSize="small" />
        </ListItemAvatar>
        <ListItemText>{hi.organizationName}</ListItemText>
        <ListItemSecondaryAction>
          <NormalButton variant="micro" onClick={_goToOrg(hi)} labelColor={colors.black}>
            <i className="fal fa-sign-out" />
          </NormalButton>
          {isAdmin && (
            <NormalButton variant="micro" onClick={_goToOrgAdmin(hi)} labelColor={colors.black}>
              <i className="fal fa-cog" />
            </NormalButton>
          )}

          <IconButton
            className={classes.deleteButton}
            onClick={_removeOrg(hi.organizationId)}
            size="large">
            <Icon className={`${classes.deleteIcon} fal fa-times fa-md`} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  const open = Boolean(anchorEl) && Boolean(searchTerm) && searchTerm.length > 1 && (userListItems.length > 0 || orgListItems.length > 0);

  return (
    <Popper anchorEl={anchorEl} open={open} className={classes.popperWrapper}>
      <Fade in={open}>
        <div className={classes.popper}>
          <List dense>
            {userListItems}
            {orgListItems}
          </List>
          <Grid container justifyContent="space-between" className={classes.bottomLinks}>
            <Grid item xs={12} component={Blink} dst="search" search_term={searchTerm}>
              Show all results...
            </Grid>
            <Grid item>{/*Show full history...*/}</Grid>
          </Grid>
        </div>
      </Fade>
    </Popper>
  );
}

export default SearchHistoryPopper;
