import React from "react";
import { convertNetworkTreeJSONToCSV, createAndDownloadFile } from "bild-utils";
import { Blink } from "bild-ui";
import { Popper, ClickAwayListener, Paper, List, ListItemButton } from "@mui/material";

function OrganizationsReportPopper({ open, setOpen, anchorEl, orgTree }) {
  return (
    <Popper open={open} anchorEl={anchorEl}>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
        mouseEvent="onMouseDown"
      >
        <Paper>
          <List dense>
            {orgTree && orgTree.length > 0 && (
              <ListItemButton
                dense
                onClick={() => {
                  const today = new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "");
                  let csvData = convertNetworkTreeJSONToCSV(orgTree);
                  createAndDownloadFile(`Equip_Organizations_${today}.csv`, csvData);
                }}
              >
                <i className="fal fa-file-excel" /> &nbsp; Download Equip Diagram Data
              </ListItemButton>
            )}
            <ListItemButton dense component={Blink} dst={"reports-organization-student-progress"} organization_id={"all"}>
              <i className="fal fa-clipboard-user" /> &nbsp; Combined A.S. Student Progress
            </ListItemButton>
            <ListItemButton dense component={Blink} dst={"reports-organization-module-progress"} organization_id={"all"}>
              <i className="fal fa-clipboard-list-check" /> &nbsp; Combined A.S. Module Progress
            </ListItemButton>
            <ListItemButton dense component={Blink} dst={"reports-total-student-progress"} organization_id={"all"}>
              <i className="fal fa-clipboard-list" /> &nbsp; A.S. Total Progress
            </ListItemButton>
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

export default OrganizationsReportPopper;
