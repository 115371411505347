import React from "react";
import { Grid, Button, CircularProgress, Typography } from "@mui/material";
import "./enrollmentStatement.css";

// ENROLLMENT STATEMENT REPORT
function EnrollmentStatement({ statement, user, enrollmentNumber }) {
  function getHeader(p, u) {
    return (
      <Grid container className="statement-header" alignItems="flex-start">
        <Grid item sm={6} xs={12} className="print-50">
          <img src={process.env.PUBLIC_URL + "/antioch_school_logo@2x.png"} width="300px" alt="Antioch School" />
        </Grid>
        <Grid item sm={6} xs={12} className="print-50 student-summary">
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid item md={8} sm={10} xs={12} className="student-summary-title">
                  Student Statement of Account
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h6">Enrollment #:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">{enrollmentNumber}</Typography>
            </Grid>

            <Grid item xs={8}>
              Student #:
            </Grid>
            <Grid item xs={4}>
              {u.userNumber}
            </Grid>

            <Grid item xs={8}>
              Date:
            </Grid>
            <Grid item xs={4}>
              {p.currentDate}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getSubHeader(p) {
    return (
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item sm={5} xs={12} className="print-50">
          <Grid container className="address-summary" alignItems="flex-start">
            <Grid item xs={3}>
              <Typography>Student: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={12}>
                  {p.addressLine1}
                </Grid>
                <Grid item xs={12}>
                  {p.addressLine2}
                </Grid>
                <Grid item xs={12}>
                  {p.addressLine3}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12} className="print-50">
          <Grid container justifyContent="flex-end">
            <Grid item md={8} sm={10} xs={12} className="student-summary-title">
              <Grid container>
                <Grid item sm={8} xs={6} className="last-payment">
                  Last Payment Date
                </Grid>
                <Grid item sm={4} xs={6} className="last-payment">
                  {p.lastPaymentDate}
                </Grid>

                <Grid item sm={8} xs={6} className="last-payment">
                  Last Payment Amount
                </Grid>
                <Grid item sm={4} xs={6} className="last-payment">
                  {p.lastPaymentAmount}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getStatement(p) {
    return (
      <Grid container className="statement-summary">
        <Grid item xs={12} className="section-title">
          <Typography variant="h6">Statement Summary</Typography>
        </Grid>

        <Grid container className="section-header">
          <Grid item xs={2}>
            &nbsp;
          </Grid>
          <Grid item xs={2}>
            Total
          </Grid>
          <Grid item xs={2}>
            To Date
          </Grid>
          <Grid item xs={2}>
            Paid
          </Grid>
          <Grid item xs={2}>
            Late
          </Grid>
          <Grid item xs={2}>
            Payoff
          </Grid>
        </Grid>
        <Grid container className="section-row">
          <Grid item xs={2}>
            Tuition
          </Grid>
          <Grid item xs={2}>
            {p.tuitionTotal}
          </Grid>
          <Grid item xs={2}>
            {p.tuitionToDate}
          </Grid>
          <Grid item xs={2}>
            {p.tuitionPaid}
          </Grid>
          <Grid item xs={2}>
            {p.tuitionLate}
          </Grid>
          <Grid item xs={2}>
            {p.tuitionPayoff}
          </Grid>
        </Grid>
        <Grid container className="section-row">
          <Grid item xs={2}>
            Portfolio Fee
          </Grid>
          <Grid item xs={2}>
            {p.simaTotal}
          </Grid>
          <Grid item xs={2}>
            {p.simaToDate}
          </Grid>
          <Grid item xs={2}>
            {p.simaPaid}
          </Grid>
          <Grid item xs={2}>
            {p.simaLate}
          </Grid>
          <Grid item xs={2}>
            {p.simaPayoff}
          </Grid>
        </Grid>
        <Grid container className="section-row">
          <Grid item xs={2}>
            Fees
          </Grid>
          <Grid item xs={2}>
            {p.feeTotal}
          </Grid>
          <Grid item xs={2}>
            {p.feeToDate}
          </Grid>
          <Grid item xs={2}>
            {p.feePaid}
          </Grid>
          <Grid item xs={2}>
            {p.feeLate}
          </Grid>
          <Grid item xs={2}>
            {p.feePayoff}
          </Grid>
        </Grid>
        <Grid container className="section-row">
          <Grid item xs={2}>
            Total
          </Grid>
          <Grid item xs={2}>
            {p.totalTotal}
          </Grid>
          <Grid item xs={2}>
            {p.totalToDate}
          </Grid>
          <Grid item xs={2}>
            {p.totalPaid}
          </Grid>
          <Grid item xs={2}>
            {p.totalLate}
          </Grid>
          <Grid item xs={2}>
            {p.totalPayoff}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getPayment(p) {
    let payments = [];

    if (p.enrollmentStatementSummary) {
      for (var i = 0; i < p.enrollmentStatementSummary.length; i++) {
        let q = p.enrollmentStatementSummary[i];
        payments.push(
          <Grid container className="section-row" key={i}>
            <Grid item xs={3}>
              {q.remitter}
            </Grid>
            <Grid item xs={2}>
              {q.year}
            </Grid>
            <Grid item xs={2}>
              {q.tuition}
            </Grid>
            <Grid item xs={3}>
              {q.sima}
            </Grid>
            <Grid item xs={2}>
              {q.fee}
            </Grid>
          </Grid>
        );
      }
    } else {
      payments.push(<Typography key={1}>No payment history.</Typography>);
    }

    return (
      <Grid container className="payment-summary">
        <Grid item xs={12} className="section-title">
          <Typography variant="h6">Payment Summary</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container className="section-header">
            <Grid item xs={3}>
              Remitter
            </Grid>
            <Grid item xs={2}>
              Year
            </Grid>
            <Grid item xs={2}>
              Tuition
            </Grid>
            <Grid item xs={3}>
              Portfolio Fee
            </Grid>
            <Grid item xs={2}>
              Fee
            </Grid>
          </Grid>
        </Grid>

        {payments}
      </Grid>
    );
  }

  function getSchedule(p) {
    let schedule = [];

    if (p.enrollmentStatementSchedule) {
      for (var i = 0; i < p.enrollmentStatementSchedule.length; i++) {
        let q = p.enrollmentStatementSchedule[i];
        schedule.push(
          <Grid container className="section-row" key={i}>
            <Grid item xs={3}>
              {q.date}
            </Grid>
            <Grid item xs={3}>
              {q.tuition}
            </Grid>
            <Grid item xs={3}>
              {q.sima}
            </Grid>
            <Grid item xs={3}>
              {q.total}
            </Grid>
          </Grid>
        );
      }
    } else {
      schedule.push(<Typography key={1}>No remaining schedule.</Typography>);
    }

    return (
      <Grid container className="remaining-schedule">
        <Grid item xs={12} className="section-title">
          <Typography variant="h6">Remaining Schedule</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container className="section-header">
            <Grid item xs={3}>
              Date
            </Grid>
            <Grid item xs={3}>
              Tuition
            </Grid>
            <Grid item xs={3}>
              Portfolio Fee{" "}
            </Grid>
            <Grid item xs={3}>
              Total
            </Grid>
          </Grid>
        </Grid>

        {schedule}
      </Grid>
    );
  }

  function getHistory(p) {
    let history = [];

    if (p.enrollmentStatementHistory) {
      for (var i = 0; i < p.enrollmentStatementHistory.length; i++) {
        let q = p.enrollmentStatementHistory[i];
        history.push(
          <Grid container className="section-row" key={i}>
            <Grid item xs={2}>
              <Typography variant="body1">{q.processDate}</Typography>
            </Grid>
            <Grid item xs={1}>
              {q.type}
            </Grid>
            <Grid item xs={1}>
              {q.receiptNumber}
            </Grid>
            <Grid item xs={2}>
              {q.remitter}
            </Grid>
            <Grid item xs={2}>
              {q.transactionNumber}
            </Grid>
            <Grid item xs={2}>
              {q.recievedDate}
            </Grid>
            <Grid item xs={1}>
              {q.amount}
            </Grid>
            <Grid item xs={1}>
              {q.balance}
            </Grid>
          </Grid>
        );
      }
    } else {
      history.push(<Typography key={1}>No transaction history.</Typography>);
    }

    return (
      <Grid container className="remaining-schedule">
        <Grid item xs={12} className="section-title">
          <Typography variant="h6">Transaction History</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container className="section-header">
            <Grid item xs={2}>
              Processed
            </Grid>
            <Grid item xs={1}>
              Type
            </Grid>
            <Grid item xs={1}>
              Receipt #
            </Grid>
            <Grid item xs={2}>
              Remitter
            </Grid>
            <Grid item xs={2}>
              Trans #
            </Grid>
            <Grid item xs={2}>
              Received
            </Grid>
            <Grid item xs={1}>
              Amount
            </Grid>
            <Grid item xs={1}>
              Balance
            </Grid>
          </Grid>
        </Grid>

        {history}
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="space-between" className="enrollment-statement-report">
      <Grid item xs={12} className="print-report-wrapper">
        <Button
          onClick={() => {
            window.print();
          }}
          className="print-report-button"
        >
          <Typography>
            <i className="fa fa-print" /> Print Report
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        {getHeader(statement, user)}
      </Grid>
      <Grid item xs={12}>
        {getSubHeader(statement)}
      </Grid>
      <Grid item xs={12}>
        {getStatement(statement)}
      </Grid>
      <Grid item xs={12}>
        {getPayment(statement)}
      </Grid>
      <Grid item xs={12}>
        {getSchedule(statement)}
      </Grid>
      <Grid item xs={12}>
        {getHistory(statement)}
      </Grid>
    </Grid>
  );
}

export default EnrollmentStatement;
