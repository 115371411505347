import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, Tooltip, Legend, ResponsiveContainer, PolarRadiusAxis } from 'recharts';

const useStyles = makeStyles({
  wrapper: { minWidth: "300px", minHeight: "300px", overflow: "hidden" },
  tooltipLabel: {
    background: "#ffffffc2",
    padding: "1rem 2rem",
    border: `1px solid ${colors.bildBlue}`,
    maxWidth: "300px"
  }
});

export default function RadarChartBuilder({ data, valAColor, valBColor, valCColor, valDColor, percentOffset }) {
  const cls = useStyles();

  const CustomTooltip = (props) => {
    const { x, y, stroke, payload } = props;
    let p = payload;
    if (p && p.length > 0 && p[0].payload.subject !== null && !p[0].payload.subject.startsWith("___")) {
      
      return (
        <div className={cls.tooltipLabel}>
          <Grid container>
            <Grid item xs={12}>
              <b>
                {p[0].payload.subject}
              </b>
            </Grid>
            <Grid item xs={12}>
              {p[0].value + percentOffset >= 0 ? Math.floor(p[0].value + percentOffset) : 0}% {p[0].name}
            </Grid>
            <Grid item xs={12}>
              {p[1].value + percentOffset >= 0 ? Math.floor(p[1].value + percentOffset) : 0}% {p[1].name}
            </Grid>
            <Grid item xs={12}>
              {p[2].value + percentOffset >= 0 ? Math.floor(p[2].value + percentOffset) : 0}% {p[2].name}
            </Grid>
            <Grid item xs={12}>
              {p[3].value + percentOffset >= 0 ? Math.floor(p[3].value + percentOffset) : 0}% {p[3].name}
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  function tickFormatter(value, index) {
    const limit = 15;
    if (value.startsWith("___")) return "";
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
 };

  if (!data) return <></>;
  return (
    <Grid container item xs={12} className={cls.wrapper}>
      <ResponsiveContainer width="99%" height="99%">
        <RadarChart cx="50%" cy="50%" width={600} height={500} outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" tickFormatter={tickFormatter} />
          <PolarRadiusAxis domain={[0, 100 + percentOffset]} axisLine={false} tick={false}/>
          <Radar name="Total In Progress" dataKey="C" stroke={valCColor} fill={valCColor} fillOpacity={0.2} />
          <Radar name="Total Completed" dataKey="D" stroke={valDColor} fill={valDColor} fillOpacity={0.3} />
          <Radar name="Core In Progress" dataKey="A" stroke={valAColor} fill={valAColor} fillOpacity={0.4} />
          <Radar name="Core Completed" dataKey="B" stroke={valBColor} fill={valBColor} fillOpacity={0.5} />
          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip />} />
          <Legend wrapperStyle={{ paddingTop: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }}/>
        </RadarChart>
      </ResponsiveContainer>
    </Grid>
  );
}
