import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import Collapse from "@mui/material/Collapse";

const useStyles = makeStyles({
  container: { position: "relative" },
  draggedContainer: { position: "relative" },
  dragImage: {
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "-1000"
  },
  draggingBody: {
    pointerEvents: "none"
  }
});

function Draggable({ children, payload, dragImage, disabled }) {
  const classes = useStyles();
  const [dragged, setDragged] = useState(false);
  const [collapseIn, setCollapseIn] = useState(true);
  const timer = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    return () => {
      // Prevents a leaked hook from being run after dismount.
      clearTimeout(timer.current);
    };
  }, []);

  if (disabled) {
    return children;
  }

  function _onDragStart(e) {
    e.dataTransfer.setData("bcfe internal-drag", "internal-drag");
    e.dataTransfer.setData("bcfe payload", payload);

    if (dragImage) {
      e.dataTransfer.setDragImage(imageRef.current, 0, 0);
    }

    setDragged(true);
    timer.current = setTimeout(() => setCollapseIn(false), 200);
  }

  function _onDragEnd() {
    setDragged(false);
    // The intention of this line is to alleviate the temporary reoccurence of a dropped element in its
    // old position. A better solution would be to hide the element until we have feedback on whether
    // the move was successful or not. But this is a deal more complicated.
    clearTimeout(timer.current);
    timer.current = setTimeout(() => setCollapseIn(true), 1000);
  }

  return (
    <Collapse in={collapseIn}>
      <div onDragStart={_onDragStart} onDragEnd={_onDragEnd} className={dragged ? classes.draggedContainer : classes.container} draggable>
        {children}

        <div ref={imageRef} className={classes.dragImage}>
          {dragImage}
        </div>
      </div>
    </Collapse>
  );
}

export default Draggable;
