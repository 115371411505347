import React, { useState, useEffect } from "react";
import { localStorage, messageData } from "bild-data";
import { hideIntercom, setPageTitle, useInterval } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import Messages from "./messages.js";

function MessageLoader({ startChannelId, openMessageUsers, initialAnchors }) {
  const [loading, setLoading] = useState(true);
  const [channels, setChannels] = useState([]);
  const [channelId, setChannelId] = useState(startChannelId ? startChannelId : null);
  const [recipients, setRecipients] = useState([]);
  const [user, setUser] = useState(null);
  const [anchors, setAnchors] = useState([]);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [loadMessages, setLoadMessages] = useState(false);

  useEffect(() => {
    hideIntercom();
    function _setData(m) {
      setPageTitle("Messages");
      _setChannels(m);
      setUser(m.user);
      setLoading(false);
    }
    function _setRecipients(r) {
      setRecipients(r.recipients);
    }

    messageData.getChannels(_setData);
    messageData.getAvailableMessageRecipients(_setRecipients);
  }, []);

  // Every 10 seconds, check if there are new messages
  useInterval(() => {
    function getNewMessages(x) {
      if (x.hasUnread) {
        if (localStorage.getMessagesLastChecked() !== x.lastMessageAt) {
          messageData.getChannels(_setChannels);
          localStorage.setMessagesLastChecked(x.lastMessageAt);
        }
      }
    }

    messageData.getCheckNewMessages(getNewMessages);
  }, 10000);

  useEffect(() => {
    if (channelId && loadMessages) {
      messageData.getChannelMessages(channelId, _setChannelMessages);
    }
  }, [channelId, loadMessages]);

  useEffect(() => {
    if (user && openMessageUsers) {
      createChannel("", openMessageUsers, 1);
    }
  }, [user, openMessageUsers]);

  function _setChannelMessages(m) {
    // Stop message loading icon, from createMessage call
    setIsMessageSending(false);    
    setLoadMessages(false);

    let tempChannels = [...channels];
    for (let i=0; i<tempChannels.length; i++) {
      if (tempChannels[i].id === channelId) {
        tempChannels[i].messages = m.messages;
      }
    }
    setChannels(tempChannels);
  }

  function _setChannels(m, users, type_id) {
    // Save old, other channel messages for quicker loading, but override all other data from new channel info
    let newChannels = m.channels;
    if (channels.length > 0) {
      for (let i=0; newChannels.length > i; i++) {
        let c = newChannels[i];
        if (!c.messages || c.messages.length < 1) {
          let oldChannel = channels.filter(x => x.id === c.id)[0];
          if (oldChannel && oldChannel.messages.length > 0) {
            c.messages = oldChannel.messages;
          }
        }
      }
    }
    setChannels(newChannels);
    // If users and type_id where passed in, try to figure out what new channel was created/updated and switch to it
    if (users && type_id) {
      let fc = m.channels.filter(
        x => String(x.type.id) === String(type_id) && JSON.stringify(x.members.map(y => y.id).sort()) === JSON.stringify(users.sort())
      );
      if (fc.length > 0) {
        setChannelId(fc.sort((a, b) => b.id - a.id)[0].id);
      }
    }
    // Reload any messages for this channel
    setLoadMessages(true);
  }

  function createMessage(channel_id, content) {
    // Start message loading icon, finished in createMessages callback
    setIsMessageSending(true);
    // We only want to set the first message as an anchored message
    let messageAnchors = initialAnchors === anchors ? [] : initialAnchors;
    setAnchors(initialAnchors);
    let newData = {
      messages: [{ user: { id: user.id }, channelId: channel_id, anchors: messageAnchors, content: JSON.stringify(content) }]
    };
    messageData.createMessages(channel_id, newData, _setChannelMessages);
    readChannel(channel_id);
  }

  function createChannel(name, users, type_id) {
    users.push(user.id);
    let members = users.map(x => {
      return { id: x };
    });
    let newData = {
      channels: [{ name: name, type: { id: type_id }, members: members }]
    };
    messageData.createUpdateChannels(newData, x => {
      _setChannels(x, users, type_id);
    });
  }

  function updateChannel(id, name, users) {
    let members = users.map(x => {
      return { id: x };
    });
    let newData = {
      channels: [{ id: id, name: name, members: members }]
    };
    messageData.createUpdateChannels(newData, _setChannels);
  }

  function readChannel(id) {
    let newData = {
      channels: [{ id: id, read: true }]
    };
    messageData.createUpdateChannels(newData, _setChannels);
  }

  function _setChannelId(id) {
    setChannelId(id);
    setLoadMessages(true);
  }

  if (loading) return <LoadingScreen />;
  return (
    <Messages
      user={user}
      channels={channels}
      startChannelId={channelId}
      setChannelId={_setChannelId}
      recipients={recipients}
      createMessage={createMessage}
      createChannel={createChannel}
      updateChannel={updateChannel}
      readChannel={readChannel}
      isLoadingMessages={loadMessages}
      isMessageSending={isMessageSending}
    />
  );
}

export default MessageLoader;
