import React, { useState, useEffect } from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { colors, UserAvatar, BTypography, NormalButton, SortableList, FilterTextField, SupportDialog, PopTip } from "bild-ui";

const useStyles = makeStyles({
  container: { padding: "1rem" },
  body: { padding: "1rem", background: colors.white },
  heading: { paddingLeft: "0.5rem" },
  section: { padding: "1rem 0" },
  certificates: { padding: "2rem 1rem 0 1rem" },
  certificate: { padding: "1rem 0" },
  icon: { padding: "0 0.25rem 0 0", color: colors.red },
  avatarName: { paddingLeft: "0.25rem", overflowWrap: "normal" }
});

function OrgUserCertificates({ organization, certificates }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [certRowItems, setCertRowItems] = useState([]);
  const [supportCertName, setSupportCertName] = useState(null);

  useEffect(() => {
    setCertRowItems(
      certificates
        .filter(x => {
          if (filter.length > 0) {
            return x.name.toLowerCase().includes(filter.toLowerCase()) || x.user.name.toLowerCase().includes(filter.toLowerCase());
          } else {
            return 1;
          }
        })
        .map(x => [
          [
            <Grid container sortval={x.name} alignItems="center" key={x.name}>
              <Grid item xs>
                <BTypography variant="h6">{x.name}</BTypography>
              </Grid>
            </Grid>
          ],
          [<UserAvatar size={40} src={x.user.avatarURL} name={x.user.name} badgeSrc={x.user.badgeURL}  srcSize="small" sortval={x.user.name} key={x.user.name} />],
          [
            <BTypography variant="body1" sortval={x.completedOn} key={x.completedOn}>
              Completed on <b>{x.completedOn}</b>
            </BTypography>
          ],
          <Grid container justifyContent="flex-end">
            <Grid item>
              {x.processed && !x.processingError && (
                <NormalButton
                  onClick={() => {
                    downloadRequest(x.downloadURL, x.name);
                  }}
                  variant="primary"
                  color={colors.bildBlue}
                  labelColor={colors.white}
                >
                  Download
                </NormalButton>
              )}
              {x.processed && x.processingError && (
                <PopTip text="There was an error processing this certificate. Please contact support.">
                  <NormalButton
                    variant="primary"
                    color={colors.darkRed}
                    hoverColor={colors.darkerRed}
                    labelColor={colors.white}
                    onClick={()=>{setSupportCertName(x.name)}}
                  >
                    <i className="far fa-exclamation-triangle" />
                  </NormalButton>
                </PopTip>
              )}
              {!x.processed && (
                <BTypography variant="body1">
                  Download link will <br /> be available soon.
                </BTypography>
              )}
            </Grid>
          </Grid>
        ])
    );
  }, [certificates, filter]);

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.container}>
      <Grid container item xs={12} className={cls.body}>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item xs>
            <BTypography variant="h3" className={cls.heading}>
              {organization.organizationName} Certificates
            </BTypography>
          </Grid>
          <Grid item>
            <FilterTextField
              value={filter}
              placeholder="Filter"
              onChange={e => setFilter(e.target.value)}
              onClear={() => {
                setFilter("");
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={cls.certificates}>
          <SortableList
            perPage={10}
            filter={filter}
            headers={["Certificate", "Earned By", "Date", ""]}
            items={certRowItems}
            emptyMessage={"No Completed Certificates"}
          />
        </Grid>
      </Grid>
      <SupportDialog
        open={supportCertName}
        closeDialog={() => {
          setSupportCertName(null);
        }}
        options={[`I am experiencing a problem with a certificate: ${supportCertName}.`]}
      />
    </Grid>
  );
}

export default OrgUserCertificates;
