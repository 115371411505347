import React from "react";
import { hideIntercom, showIntercom } from "bild-utils";
import { ManageGroupLoader } from "modules";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, DialogContent, Grid, Button } from "@mui/material";
import { colors, NormalButton } from "bild-ui";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    content: {
      padding: "0 !important"
    }
  })
);

function ManageGroupsDialogLoader({ open, onClose, openMessageUsers, anchors }) {
  const theme = useTheme();
  const cls = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (open) {
    hideIntercom();
  } else {
    showIntercom();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true} fullScreen={fullScreen}>
      <DialogContent className={cls.content}>
        <Grid container justifyContent="flex-end" alignItems="center">
          {fullScreen && (
            <Grid item>
              <NormalButton onClick={onClose} variant="secondary" color={colors.white} hoverColor={colors.lightGray} labelColor={colors.black} hoverLabelColor={colors.black}>
                <i className="fas fa-times" />
              </NormalButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <ManageGroupLoader openMessageUsers={openMessageUsers} initialAnchors={anchors} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ManageGroupsDialogLoader;
