import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { colors } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: colors.veryLightGray,
      minHeight: "100%",
      padding: "1rem",
      [theme.breakpoints.down('md')]: {
        padding: "0.5rem"
      },
      [theme.breakpoints.down('sm')]: {
        padding: "0.25rem"
      }
    }
  })
);

function EquipWrapper({ children }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default EquipWrapper;
