import React, { useState } from "react";
import { colors, BTypography, MembersChecklist, ChipList } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";

const useStyles = makeStyles({
  rowItem: { paddingTop: "1rem" }
});

function UpdateMembersChecklists({ title, allMembers, members, selectedMembers, updateMember, icon, showChipList }) {
  const cls = useStyles();

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={cls.rowItem}>
        <BTypography variant="subtitle2">{title ? title : "Members"}:</BTypography>
      </Grid>
      {!showChipList && (
        <Grid item xs={12}>
          <MembersChecklist filterable={true} members={members} selectedMembers={selectedMembers} updateMember={updateMember} icon={icon} />
        </Grid>
      )}
      {showChipList && (
        <Grid item xs={12}>
          <ChipList members={allMembers.filter(r => selectedMembers.includes(r.id))} icon={icon} />
        </Grid>
      )}
      <Grid item xs={12} className={cls.rowItem}>
        <BTypography variant="subtitle2">Add {title ? title : "Members"}:</BTypography>
      </Grid>
      <Grid container item xs={12}>
        <MembersChecklist
          filterable={true}
          members={allMembers.filter(r => !members.map(x => x.id).includes(r.id))}
          selectedMembers={selectedMembers}
          updateMember={updateMember}
          icon={icon}
        />
      </Grid>
    </Grid>
  );
}

export default UpdateMembersChecklists;
