import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import dropBase from "./dragDrop.js";

const useStyles = makeStyles({
  invisible: { display: "none" },

  container: { position: "relative" },
  inlineContainer: { position: "relative", display: "inline-block" },

  overlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0"
  }
});

function Droppable({ children, onDrop, inline, className, disabled }) {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  inline = inline === true;

  useEffect(() => {
    dropBase.setup();
    return dropBase.clear;
  }, []);

  if (disabled) return <div className={(inline ? classes.inlineContainer : classes.container) + " " + className}>{children}</div>;

  function _onDragEnter(e) {
    e.preventDefault();
    if ([...e.dataTransfer.types].includes("bcfe internal-drag")) setActive(true);
  }
  function _onDragLeave(e) {
    e.preventDefault();
    setActive(false);
  }
  function _onDrop(e) {
    setActive(false);
    if (onDrop && [...e.dataTransfer.types].includes("bcfe internal-drag")) onDrop(e.dataTransfer.getData("bcfe payload"));
  }

  const [containerClasses, overlayClasses] = [[className], []];
  containerClasses.push(inline ? classes.inlineContainer : classes.container);
  overlayClasses.push(active ? classes.overlay : classes.invisible);

  // We add draggedOver="true" to children elements. Use string and not boolean in case child is native element.
  children = React.Children.map(children, c => React.cloneElement(c, { draggedOver: active ? "true" : "false" }));

  return (
    <div onDragEnter={_onDragEnter} className={containerClasses.join(" ")}>
      {children}

      {active && <div className={overlayClasses.join(" ")} onDragLeave={_onDragLeave} onDrop={_onDrop} />}
    </div>
  );
}

export default Droppable;
