import React from "react";
import { ENUMS } from "bild-data";
import ReactHtmlParser from "react-html-parser";
import { Grid, Hidden, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Blink } from "bild-ui";
import CourseWrapper from "presentational/wrappers/courseWrapper.js";
import AssessmentStatus from "presentational/assessmentStatus/assessmentStatus";


const useStyles = makeStyles({
  competencyListItem: {
    padding: "0.75rem",
    "&:last-child": {
      borderBottom: "none",
      borderRadius: "0 0 3px 3px",
    }, "&:nth-child(even)": {
      background: "#f5f5f5",
    }
  }
});

export default function AuditAntiochTranscriptCourseCompetencyProofs({ showCourseNumber, showHandbook, user, course, contentType, enrollmentId  }) {
  const cls = useStyles();
  const groupColor = course.courseGroupColor.webFrontendValue;
  const competencies = course.competencies;
  let statusItemHeaders = [ENUMS.ROLES.STUDENT.NAME, ENUMS.ROLES.LOCAL_MENTOR.NAME, ENUMS.ROLES.ASSOCIATE_FACULTY.NAME];

  const header = (
    <Grid container>
      {showCourseNumber && (
        <Grid item xs={12}>
          <Typography variant="h5">{course.courseNumber}</Typography>
        </Grid>
      )}
      {showHandbook && (
        <Grid item xs={12}>
          <Typography variant="body1">Handbook: {course.competencySet.handbookName}</Typography>
        </Grid>
      )}
      <Grid item sm={7} xs={12}>
        <Typography variant="h6">{ReactHtmlParser(course.name)}</Typography>
      </Grid>
      <Hidden smDown>
        <Grid item xs={5} container>
          {statusItemHeaders.map((x, i) => 
            <Grid item className="status-item" xs={4} key={i}>
              <Typography align="center" variant="subtitle1">
                {x}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );

  const competencyRows = competencies.map((x, i) => 
    <div className={cls.competencyListItem} key={i}>
      <Grid
        container
        component={Blink}
        dst={"user-audit-antioch-course"}
        course_id={course.id}
        user_id={user && user.id}
        competency_set_id={course.competencySet.id}
        competency_proof_id={x.competencyProof ? x.competencyProof.id : -1}
        id={x.id}
      >
        <Grid item container xs={12} sm={7} alignItems="center">
          <Typography variant="body1">
            {x.description ? ReactHtmlParser(x.description) : ReactHtmlParser(x.name)}
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={5} alignItems="center">
          <AssessmentStatus icons={x.assessmentFlowIcons} />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Grid item xs={12}>
      <CourseWrapper variant="transcript" header={header} color={groupColor}>
        {competencyRows}
      </CourseWrapper>
    </Grid>
  );
}
