import React from "react";
import { colors, FormField } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  field: { width: "100%" },
  errorWrapper: { color: colors.red, "& fieldset": { borderColor: colors.red } }
});

function FormTextField({ label, placeholder, value, setValue, disabled, error, required, classes, color, multiline, rows }) {
  const cls = useStyles();

  return (
    <FormField
      label={label}
      color={color}
      error={error}
      required={required}
      child={
        <TextField
          variant="outlined"
          placeholder={placeholder ? placeholder : `${label}${required ? "*" : ""}`}
          value={value}
          onChange={e => {
            if (!disabled) {
              setValue(e.target.value);
            }
          }}
          className={`${cls.field} ${classes}`}
          multiline={multiline}
          rows={rows}
          disabled={disabled}
        />
      }
    />
  );
}

export default FormTextField;
