import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";

import { Grid, Typography, TextField } from "@mui/material";

const useStyles = makeStyles({
  textField: {
    marginBottom: "0.5rem",
    "& > div": {
      padding: "0.5rem",
      border: `1px solid ${colors.darkGray}`,
      borderRadius: "5px",
      overflowX: "hidden",
      overflowY: "auto",
      "-webkit-overflow-scrolling": "touch"
    },
    "& textarea": {}
  },
  required: { paddingLeft: "0.2rem", color: colors.red },
  readOnly: { background: colors.veryLightGray, "& textarea": { color: `${colors.black} !important`, "-webkit-text-fill-color": `${colors.black} !important` } }
});

function BTextField({ title, value, required, defaultValue, onChange, readOnly, ...props }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {title}
          {required && <span className={classes.required}>*</span>}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputProps={{ disableUnderline: true }}
          fullWidth
          multiline
          onChange={e => onChange(e.target.value)}
          className={`${classes.textField} ${readOnly ? classes.readOnly : ""}`}
          disabled={readOnly ? true : false}
          value={value}
          defaultValue={defaultValue}
          {...props}
        />
      </Grid>
    </Grid>
  );
}

export default BTextField;
