import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, Legend, ZoomButtons } from "bild-ui";
import { Grid } from "@mui/material";
import { ENUMS } from "bild-data";

const useStyles = makeStyles({
  subItem: {
    padding: "0.5rem 0.25rem"
  }
});

function TreeFooter({ legend, scalable, scaleLevel, setScaleLevel, hiddenTypeKeys }) {
  const cls = useStyles();

  let typeKeys = [
    { name: ENUMS.LOCAL_ENTITY_TYPES.GRT.NAME, color: colors.grt },
    { name: ENUMS.LOCAL_ENTITY_TYPES.PRT.NAME, color: colors.prt },
    { name: ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_3.NAME, color: colors.networkofnetworks },
    { name: ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_2.NAME, color: colors.network },
    { name: ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_1.NAME, color: colors.localnetwork },
    { name: ENUMS.LOCAL_ENTITY_TYPES.LOCAL_ORGANIZATION.NAME, color: colors.localorganization }
  ].filter(x => hiddenTypeKeys && hiddenTypeKeys.includes(x.name) );

  return (
    <Grid container item alignItems="stretch" justifyContent="flex-start">
      {legend && (
        <>
          <Grid item className={cls.subItem}>
            <Legend
              title="Organization Types"
              keys={typeKeys}
            />
          </Grid>
          <Grid item className={cls.subItem}>
            <Legend
              title="People With Programs"
              keys={[
                { name: "1-25", color: colors.black, icon: "bfont bf-fire-1" },
                { name: "25-100", color: colors.black, icon: "bfont bf-fire-2" },
                { name: "100-500", color: colors.black, icon: "bfont bf-fire-3" },
                { name: "500+", color: colors.black, icon: "bfont bf-fire-4" }
              ]}
            />
          </Grid>
          <Grid item className={cls.subItem}>
            <Legend
              title="People With Progress"
              keys={[
                { name: "0-25", color: "#48a7ff", icon: "fal fa-circle" },
                { name: "26-100", color: "#fbc512", icon: "fal fa-circle" },
                { name: "100-500", color: "#f18000", icon: "fal fa-circle" },
                { name: "500+", color: "#f32535", icon: "fal fa-circle" }
              ]}
            />
          </Grid>
        </>
      )}
      {scalable && (
        <Grid container item xs justifyContent="flex-end">
          <Grid item className={cls.subItem}>
            <ZoomButtons scale={scaleLevel} setScale={setScaleLevel} max={5} min={0} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default TreeFooter;
