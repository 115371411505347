import React, { useState, useEffect } from "react";
import { managerData, organizationsData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { EquipWrapper, LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import ManageOrganizations from "./manageOrganizations.js";

function ManageOrganizationsLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [equipOrgs, setEquipOrgs] = useState([]);
  const [orgTree, setOrgTree] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageTitle("Organization Management");

    function _setUsers(m) {
      setUsers(m.users);
    }

    function _setOrgs(m) {
      setOrgs(m.organizations);
      setOrgTree(m.organizationRelationships);
      setLoading(false);
    }

    managerData.getManagedUsers(_setUsers, e => {
      setError(e.response.data.error);
    });
    managerData.getManagedOrganizations((m)=>{setEquipOrgs(m.organizations)}, e => {
      setError(e.response.data.error);
    });
    organizationsData.loadCombinedOrganizations(_setOrgs, e => {
      setError(e.response.data.error);
    });
  }, []);

  function createOrganization(newOrg) {
    let data = {
      organizations: [
        {
          organizationName: newOrg.name,
          addressLine1: newOrg.addressLine1,
          addressLine2: newOrg.addressLine2,
          city: newOrg.city,
          stateProvince: newOrg.state,
          zip: newOrg.zip,
          country: newOrg.country,
          phone: newOrg.phone,
          parentOrganization: { organizationId: newOrg.parentOrg },
          primaryUser: { id: newOrg.primaryUser }
        }
      ]
    };
    managerData.createManagedOrganization(data, d => {
      toaster.success(`Organization Created:  ${newOrg.name}!`);
      organizationsData.loadCombinedOrganizations(
        x => {
          setOrgs(x.organizations);
        },
        e => {
          setError(e.response.data.error);
        }
      ); // reload all orgs, note this loses the sort
      setOrgTree(d.organizationRelationships);
    });
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <EquipWrapper>
      <ManageOrganizations
        orgs={orgs}
        equipOrgs={equipOrgs}
        orgTree={orgTree}
        users={users}
        setUsers={setUsers}
        createOrganization={createOrganization}
      />
    </EquipWrapper>
  );
}

export default ManageOrganizationsLoader;
