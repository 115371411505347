import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Blink, BTypography, colors, NormalButton } from "bild-ui";
import List from "presentational/lists/list.js";

const useStyles = makeStyles({
  reportDescription: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: "4px",
    background: colors.veryLightGray,
    padding: "0 1rem",
    margin: "1rem 0"
  }
});

function OrganizationCourseProgress({ dateRan, organizations, combined }) {
  const cls = useStyles();
  const headers = ["Module", "Complete", "Started", "Not Started", "Total"];
  const headerIds = [18, 20, 21, 19, 99, 61, 1, 113, 114];

  function getOrgs() {
    let orgHeaders = headers.map(h => (
      <Grid item xs key={h}>
        {h}
      </Grid>
    ));

    if (combined) {
      let combinedOrgs = { students: [] };
      combinedOrgs.students = organizations.map(x => combinedOrgs.students.concat(x.students)).flat();
      return <List title={`All Module Progress`} details={dateRan} headings={orgHeaders} items={getTotals(combinedOrgs)} />;
    } else {
      return organizations.map(o => [
        <List title={`${o.organizationName} Module Progress`} details={dateRan} headings={orgHeaders} items={getTotals(o)} key={o.organizationId} />
      ]);
    }
  }

  function getTotals(org) {
    return headerIds.map(id => [getTotalsForModule(org, id)]);
  }

  function getTotalsForModule(org, id) {
    let name = "";
    let complete = 0;
    let started = 0;
    let empty = 0;
    let count = 0;
    for (let j = 0; j < org.students.length; j++) {
      let modules = org.students[j].modules;
      for (let k = 0; k < modules.length; k++) {
        let module = modules[k];
        if (module.moduleId === id) {
          name = module.moduleName;
          complete += module.moduleCompleted ? 1 : 0;
          started += module.completedCompetencies && !module.moduleCompleted && module.completedCompetencies > 0 ? 1 : 0;
          empty += !module.completedCompetencies || module.completedCompetencies === 0 ? 1 : 0;
          count += 1;
        }
      }
    }

    return (
      <Grid container item xs={12} key={id}>
        <Grid item xs>
          {ReactHtmlParser(name)}
        </Grid>
        <Grid item xs>
          {complete}
        </Grid>
        <Grid item xs>
          {started}
        </Grid>
        <Grid item xs>
          {empty}
        </Grid>
        <Grid item xs>
          {count}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Blink dst={"reports-organization-module-progress"} organization_id={`${combined ? "" : "all"}`}>
          <NormalButton variant={"simple"}>
            <i className="fal fa-clipboard-list-check" /> &nbsp; View {combined ? "Details" : "Combined"}
          </NormalButton>
        </Blink>
      </Grid>
      <Grid item xs={12} className={cls.reportDescription}>
        <p>
          <BTypography variant="h6">Report of student progress in completing core courses</BTypography>
        </p>
        <p>
          <b>Complete:</b> Count of students with all competencies validated in a course
        </p>
        <p>
          <b>Started:</b> Count of students with one or more, but not all, competencies validated in a course
        </p>
        <p>
          <b>Not Started:</b> Count of students with no validated competencies in a course
        </p>
      </Grid>
      <Grid item xs={12}>
        {getOrgs()}
      </Grid>
    </Grid>
  );
}

export default OrganizationCourseProgress;
