import React, { useEffect, useState } from "react";
import { colors, Blink, SortableList, BTypography, NormalButton, FilterTextField } from "bild-ui";
import { Grid, Divider } from "@mui/material";
import "./organizations.css";

// ORGANIZATIONS
function Organizations({ organizations, showReports }) {
  const [filter, setFilter] = useState("");
  const [orgItems, setOrgItems] = useState([]);

  useEffect(() => {
    // map raw user data into list format
    setOrgItems(
      organizations
        .filter(x => {
          if (filter.length > 0) {
            return (
              x.organizationName.toLowerCase().includes(filter.toLowerCase())
            );
          } else {
            return 1;
          }
        })
        .map(x => [
          x.organizationName,
          <Grid container justifyContent="flex-end">
            <Grid item>
              <NormalButton component={Blink} dst="organization-users" organization_id={x.organizationId} variant="simple" color={colors.darkGray} labelColor={colors.white}>
                View
              </NormalButton>
            </Grid>
          </Grid>
        ])
    );
  }, [organizations, filter]);

  return (
    <Grid container className="organizations-wrapper">
      <Grid item xs className="organizations">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={9} xs={12}>
            <BTypography variant="h5" className="page-headline">
              Organizations
            </BTypography>
            {showReports && (
              <>
                <NormalButton component={Blink} dst={"reports-organization-student-progress"} organization_id={"all"}>
                  <i className="fal fa-clipboard-user" /> &nbsp; Combined Student Progress
                </NormalButton>
                <NormalButton component={Blink} dst={"reports-organization-module-progress"} organization_id={"all"}>
                  <i className="fal fa-clipboard-list-check" /> &nbsp; Combined Module Progress
                </NormalButton>
                <NormalButton component={Blink} dst={"reports-total-student-progress"}>
                  <i className="fal fa-clipboard-list" /> &nbsp; Total Progress
                </NormalButton>
              </>
            )}
          </Grid>
          <Grid container item md={3} xs={12} justifyContent={"flex-end"}>
            <Grid item>
              <FilterTextField
                  value={filter}
                  placeholder="Search"
                  onChange={e => setFilter(e.target.value)}
                  onClear={() => {
                    setFilter("");
                  }}
                  className="search-bar"
                />
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className="search-divider" />
          </Grid>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SortableList
            perPage={15}
            filter={filter}
            headers={["Name", ""]}
            items={orgItems}
            itemPadding={"0.25rem 1rem"}
            emptyMessage={
              <BTypography variant="h6">{filter ? "No Organizations match the search criteria." : "There are not any Organizations to manage, yet!"}</BTypography>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Organizations;
