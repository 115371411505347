import React, { useEffect, useState } from "react";
import { setPageTitle, refreshPage } from "bild-utils";
import { userData } from "bild-data";
import { UploadAvatarDialog, UploadSignatureDialog, LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import UserProfile from "./userProfile.js";
import ChangePasswordDialog from "presentational/dialogs/changePasswordDialog.js";

// User Profile LOADER
function UserProfileLoader({}) {
  const [loading, setLoading] = useState(true);
  const [loadingTitle, setLoadingTitle] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [avatarDialog, setAvatarDialog] = useState(false);
  const [signatureDialog, setSignatureDialog] = useState(false);
  const userOverview = userData.getOverview();

  useEffect(() => {
    setPageTitle("Profile");

    function _setData(m) {
      setUser(m);
      setLoading(false);
    }

    const userId = userData.getOverview().user.id;
    userData.loadUserProfile(userId, _setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  function handleSubmit(form) {
    userData.modifyUserProfile(
      user.id,
      form,
      () => {
        toaster.success("Your profile has been updated.");
      },
      () => {
        toaster.error("There was an error updating your profile.");
      }
    );
  }

  function uploadNewAvatar(avatarRawData, avatarOriginalRawData, avatarSmallRawData, success_cb, error_cb) {
    setLoading(true);
    setLoadingTitle("Uploading Image");
    userData.uploadNewAvatar(
      user.id,
      avatarRawData,
      avatarOriginalRawData,
      avatarSmallRawData,
      x => {
        if (typeof success_cb === "function") success_cb(x);
        setUser(x);
        setLoading(true);
        setLoadingTitle(null);
        refreshPage(); // refresh the page to force new images to appear
      },
      error_cb
    );
  }

  function uploadNewSignature(signatureRawData, signatureOriginalRawData, success_cb, error_cb) {
    setLoading(true);
    setLoadingTitle("Uploading Signature");
    userData.uploadNewSignature(
      user.id,
      signatureRawData,
      signatureOriginalRawData,
      x => {
        if (typeof success_cb === "function") success_cb(x);
        setUser(x);
        setLoading(true);
        setLoadingTitle(null);
        refreshPage(); // refresh the page to force new images to appear
      },
      error_cb
    );
  }

  if (loading || error) return <LoadingScreen error={error} title={loadingTitle} />;
  return (
    <>
      <UserProfile
        handleSubmit={handleSubmit}
        handleChangePassword={() => {
          setPasswordDialog(true);
        }}
        handleUploadAvatar={() => {
          setAvatarDialog(true);
        }}
        handleUploadSignature={() => {
          setSignatureDialog(true);
        }}
        userProfile={user}
        userId={user.id}
        userOverview={userOverview}
      />
      <ChangePasswordDialog
        open={passwordDialog}
        closeDialog={() => {
          setPasswordDialog(false);
        }}
      />
      <UploadAvatarDialog
        open={avatarDialog}
        closeDialog={() => {
          setAvatarDialog(false);
        }}
        uploadNewAvatar={uploadNewAvatar}
        userProfile={user}
      />
      <UploadSignatureDialog
        open={signatureDialog}
        closeDialog={() => {
          setSignatureDialog(false);
        }}
        uploadNewSignature={uploadNewSignature}
      />
    </>
  );
}

export default UserProfileLoader;
