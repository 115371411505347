import React from "react";
import { makeStyles } from "@mui/styles";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  container: { paddingBottom: "0" }
});

// FilesDialogLoader
// This container can be thought of as a collection of steps (in dialog form) that lead the
// user through the process of selecting files to attach to a competency.
//
// There are multiple stages that this dialog can find itself in:
// + 0. Initial stage, user chooses where the files are coming from. (Folio, computer, Google, Dropbox)
//
// + 1. Show Folio for file or destination folder selection.
//
// + 2. If source not Folio, show appropriate file picker
//
// + 3. Confirm selected files (+ destination) and target competency.
//
// + -1. Error state.

function AttachArtifactStepper({ activeStep, activeSource, setStage, onCancel, onFinal, hasArtifact, hasDestination }) {
  const classes = useStyles();

  function setStage0() {
    if (setStage && activeStep > 0) setStage(0);
  }
  function setStage1() {
    if (setStage && activeStep > 1) setStage(1);
  }
  function setStage2() {
    if (setStage && ((activeStep === 1 && hasArtifact) || activeStep > 2)) setStage(2);
  }

  function stage1Status() {
    return !hasArtifact;
  }
  function stage2Status() {
    return !hasDestination;
  }

  return (
    <Stepper activeStep={activeStep} orientation="vertical" className={classes.container}>
      <Step>
        <StepLabel onClick={setStage0} style={activeStep > 0 && { cursor: activeStep > 0 ? "pointer" : "" }}>
          {activeSource === "computer" && (
            <span>
              <i className={`far fa-desktop`} /> Device
            </span>
          )}
          {activeSource === "google" && (
            <span>
              <i className={`fab fa-google-drive`} /> Google Drive
            </span>
          )}
          {activeSource === "dropbox" && (
            <span>
              <i className={`fab fa-dropbox`} /> Dropbox
            </span>
          )}
        </StepLabel>
        <StepContent>Choose where you will attach your artifacts from.</StepContent>
      </Step>
      <Step>
        <StepLabel onClick={setStage1} style={activeStep > 0 && { cursor: activeStep > 1 ? "pointer" : "" }}>
          Select Artifacts
        </StepLabel>
        <StepContent>
          <Grid container>
            <Grid item xs={12}>
              Select the artifacts you want to attach to this competency.
            </Grid>
            <Grid>
              <Button onClick={setStage2} className="default-submit-button" disabled={stage1Status()}>
                Next
              </Button>
              <Button className="default-cancel-button" style={{ marginLeft: "10px" }} onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </StepContent>
      </Step>
      <Step>
        <StepLabel onClick={setStage2} style={{ cursor: activeStep > 2 ? "pointer" : "" }}>
          Choose Where to Store
        </StepLabel>
        <StepContent>
          <Grid container>
            <Grid item xs={12}>
              Select the location in your Folio where you want to save these files. We'll automatically save them there for you.
            </Grid>
            <Grid>
              <Button onClick={onFinal} className="default-submit-button" disabled={stage2Status()}>
                Finish
              </Button>
              <Button className="default-cancel-button" style={{ marginLeft: "10px" }} onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </StepContent>
      </Step>
    </Stepper>
  );
}

export default AttachArtifactStepper;
