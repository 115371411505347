import React from "react";
import "./wrappers.css";
import classes from "./classes.js";

const c = classes.courseWrapper;

function CourseWrapper({ variant, className, header, classes, color, children }) {
  let [rootClass, headerClass, bodyClass] = [c.root, c.header, c.body];
  switch (variant) {
    case "overview":
      rootClass += " " + c.overview;
      break;
    case "transcript":
      rootClass += " " + c.transcript;
      break;
    case "dashboard":
      rootClass += " " + c.dashboard;
      break;
    case "dashboard-empty":
      rootClass += " " + c.dashboardEmpty;
      break;
    default:
      break;
  }

  if (className) {
    rootClass += " " + className;
  }
  if (classes) {
    if (classes.root) {
      rootClass += " " + classes.root;
    }
    if (classes.body) {
      bodyClass += " " + classes.body;
    }
    if (classes.header) {
      headerClass += " " + classes.header;
    }
  }

  return (
    <div className={rootClass} style={{ borderColor: color }}>
      {header && (
        <div className={headerClass} style={{ backgroundColor: color }}>
          {header}
        </div>
      )}
      <div className={bodyClass}>{children}</div>
    </div>
  );
}

export default CourseWrapper;
