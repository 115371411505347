import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle, hideIntercom, updateURLWithoutLoad, getPath } from "bild-utils";
import { toaster } from "presentational/toasts/toasts.js";
import { ENUMS, organizationsData, userData, userPathData, managerData, diskData } from "bild-data";
import { LoadingScreen } from "bild-ui";
import { loadAdminUser, loadUserRoles, updateUsername, createUserRole, updateOrganization, updateUserRole } from "bild-data/admin";
import UserAdmin from "containers/admin/userAdmin.js";
import queryString from "query-string";

function UserAdminLoader({}) {
  const { user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState(null);
  const [user, setUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [orgTree, setOrgTree] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [generalRoles, setGeneralRoles] = useState([]);
  const [userPaths, setUserPaths] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [disk, setDisk] = useState(null);
  const [defaultTab, setDefaultTab] = useState(1);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      setDefaultTab(parseInt(searchParams.t));
    }
    function _setOrgs(d) {
      let orgs = d.organizations.map(x => {
        return { organizationId: x.id, organizationName: x.name };
      });
      setOrganizations(orgs);
      setOrgTree(d.organizationRelationships);
    }
    function _setAllOrgs(d) {
      let orgs = d.organizations.map(x => {
        return { organizationId: x.id, organizationName: x.name };
      });
      setAllOrganizations(orgs);
    }
    function _setData(d) {
      setPageTitle(" Admin | " + d.user.name);
      hideIntercom();
      setOverview(d);
      setUser(d.user);
      setGeneralRoles(d.roles);
      setLoading(false);
    }

    organizationsData.loadCombinedOrganizationsForUser(user_id, _setOrgs);
    organizationsData.loadCombinedOrganizations(_setAllOrgs);
    loadAdminUser(user_id, _setData);
    loadUserRoles(user_id, setUserRoles);
    userPathData.loadLearnerByUserId(user_id, x => {
      setUserPaths(x.userPaths);
    });
    userData.loadUserProfile(user_id, setUserProfile);
    managerData.getManagedUserCertificates(user_id, x => {
      setCertificates(x.certificates);
    });
    diskData.load(user_id, setDisk);
    managerData.getManagedUsers(
      x => {setAllUsers(x.users)},
      e => {setError(e.response.data.error)}
    );
  }, []);

  useEffect(()=>{
    if (defaultTab && defaultTab < 7) {
      updateURLWithoutLoad(getPath() + "?t=" + defaultTab);
    }
  },[defaultTab])

  function onOrgSuccess(data) {
    let newOverview = JSON.parse(JSON.stringify(overview));
    newOverview.primaryOrganization = data.primaryOrganization;
    setOverview(newOverview);
    setUserProfile(data);
  }

  function updateUserProfile(formData) {
    userData.modifyUserProfile(
      user_id,
      formData,
      () => {
        toaster.success("User profile has been updated.");
      },
      () => {
        toaster.error("There was an error updating this profile.");
      }
    );
  }

  function updateUserPathShepherd(userPathId, shepherdUserId) {
    let data = {
      id: user.id,
      userPathsToUpdate: [{ id: userPathId, shepherdUser: { id: shepherdUserId } }]
    };
    managerData.updateManagedUser(user.id, data, x => {
      toaster.success(`Program Shepherd Updated!`);
      setUser(x);
      setUserPaths(x.userPaths);
    });
  }

  function refreshUserPath(id) {
    let data = {
      id: user.id,
      userPathsToUpdate: [{ id: id, refreshProgram: true }]
    };
    managerData.updateManagedUser(user.id, data, x => {
      toaster.success(`Program Refreshed!`);
      setUser(x);
      setUserPaths(x.userPaths);
    });
  }

  function removeUserPath(id) {
    let data = {
      id: user.id,
      userPathsToRemove: [{ id: id }]
    };
    managerData.updateManagedUser(user.id, data, x => {
      toaster.success(`Program Removed!`);
      setUser(x);
      setUserPaths(x.userPaths);
    });
  }

  function removeCert(id) {
    function _setCertData(d) {
      // Just set current cert data to every cert but the ones removed
      setCertificates(certificates.filter(x => x.id !== id));
    }
    let data = { certificatesToRemove: [{ id: id }] };
    managerData.updateUserCertificates(data, _setCertData);
  }

  function removeRole(userRoleId) {
    function _setUserRolesData(d) {
      // Just set current cert data to every cert but the ones removed
      setUserRoles(userRoles.filter(x => x.visualRole.userRoleId !== userRoleId));
      toaster.success(`Role Removed!`);
    }
    let data = { userRoleToRemove: { userRoleId: userRoleId } };
    updateUserRole(user.id, data, _setUserRolesData);
  }

  function updateUserStorage(data, success_cb) {
    function _setDiskData(d) {
      // Just reload the current disk
      diskData.load(user_id, (r) => {
        setDisk(r);
        if (typeof success_cb === "function") success_cb();
      });
    }
    managerData.updateManagedUser(user_id, data, _setDiskData);
  }

  if (loading) return <LoadingScreen />;
  return (
    <UserAdmin
      overview={overview}
      user={user}
      allUsers={allUsers}
      userProfile={userProfile}
      disk={disk}
      organizations={organizations}
      allOrganizations={allOrganizations}
      orgTree={orgTree}
      availableRoles={ENUMS.ROLES}
      generalRoles={generalRoles}
      userRoles={userRoles}
      userPaths={userPaths}
      certificates={certificates}
      updateUserProfile={updateUserProfile}
      updateUsername={updateUsername}
      createUserRole={createUserRole}
      onRoleSuccess={setUserRoles}
      updateOrg={updateOrganization}
      onOrgSuccess={onOrgSuccess}
      updateUserPathShepherd={updateUserPathShepherd}
      refreshUserPath={refreshUserPath}
      removeUserPath={removeUserPath}
      removeCert={removeCert}
      removeRole={removeRole}
      defaultTab={defaultTab}
      setDefaultTab={setDefaultTab}
      updateUserStorage={updateUserStorage}
    />
  );
}

export default UserAdminLoader;
