import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { useSlate } from "slate-react";

import { Icon, IconButton } from "@mui/material";

import { toggleMark, toggleLink, isMarkActive, isLinkActive } from "..";

const useStyles = makeStyles({
  icon: {
    fontSize: "1rem",
    color: colors.black
  },
  active: {
    backgroundColor: colors.lightGray
  },
  button: { height: "30px", width: "35px", padding: 0, borderRadius: 0 }
});

function MarkButton({ mark, className, ...props }) {
  const classes = useStyles();
  const editor = useSlate();

  let active = isMarkActive(editor, mark);
  let fontAwesome;
  switch (mark) {
    case "bold":
      fontAwesome = "fas fa-bold";
      break;
    case "italic":
      fontAwesome = "fas fa-italic";
      break;
    case "underline":
      fontAwesome = "fas fa-underline";
      break;
    case "strikethrough":
      fontAwesome = "fas fa-strikethrough";
      break;
    case "link":
      fontAwesome = "fas fa-link";
      if (isLinkActive(editor)) {
        fontAwesome = "fas fa-unlink";
        active = true;
      }
      break;
    default:
      return null;
  }

  const icClass = `${classes.button} ${className} ${active ? classes.active : ""}`;
  const iClass = `${fontAwesome} ${classes.icon}`;

  return (
    <IconButton
      {...props}
      className={icClass}
      onClick={() => {
        if (mark === "link") {
          toggleLink(editor);
        } else {
          toggleMark(editor, mark);
        }
      }}
      size="large">
      <Icon className={iClass} />
    </IconButton>
  );
}

export default MarkButton;
