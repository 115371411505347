import { useEffect } from "react";
import { refreshToken } from "bild-data/core/comm";
import { useParams } from 'react-router-dom';

// MASQUERADE LOADER
function MasqueradeLoader() {
  const { user_id } = useParams();

  useEffect(()=>{
    refreshToken("/", user_id);
  },[]);

  return null;
}

export default MasqueradeLoader;
