import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import Search from "./search.js";

import { LoadingScreen } from "bild-ui";
import { searchData } from "bild-data";
import { checkAdmin } from "bild-data/user";

// SEARCH LOADER
function SearchLoader() {
  const { search_value } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(checkAdmin());
  const [value, setValue] = useState("");
  const [canMasquerade, setCanMasquerade] = useState(null);
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);

  useEffect(()=>{
    setPageTitle("Search");

    function _setData(d) {
      setCanMasquerade(d.callingUserCanMasquerade);
      setUsers(d.users);
      setOrgs(d.organizations);
      setLoading(false);
    }

    if (search_value) {
      setValue(search_value);
      searchData.search(search_value, _setData);
    } else {
      setLoading(false);
    }
  },[])

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <Search
        canMasquerade={canMasquerade}
        users={users}
        organizations={orgs}
        value={value}
        isAdmin={isAdmin}
      />
    );
  }
}

export default SearchLoader;
