import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import ListItem from "./components/listItem.js";
import { Grid, Typography, Divider } from "@mui/material";

const useStyles = makeStyles({
  container: { paddingBottom: "1rem" },
  divider: { margin: "0.5rem 0 1rem 0" },
  listItem: { padding: "1rem 0.5rem", borderBottom: "1px solid " },
  evenListItem: { background: colors.offWhite },
  details: { textAlign: "right" },
  headerRow: { background: colors.lightGray }
});

function List({ title, details, headings, items, variant }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
      {title && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          {details && (
            <Grid item xs className={classes.details}>
              {details}
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      )}
      {headings && (
        <Grid item xs={12} className={`${classes.listItem} ${classes.headerRow}`}>
          <ListItem columns={headings} variant="header" />
        </Grid>
      )}
      {items.map((x, i) => (
        <Grid item xs={12} key={i} className={`${classes.listItem} ${i % 2 === 1 ? classes.evenListItem : ""}`}>
          <ListItem columns={x} />
        </Grid>
      ))}
    </Grid>
  );
}

export default List;
