import React from "react";
import { colors, DualProgress, ThinDualProgress } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({});

function PathProgress({ totalCompletedCoreSteps, totalInProgressCoreSteps, totalCoreSteps, totalCompletedSteps, totalInProgressSteps, totalSteps, thin }) {
  const cls = useStyles();
  const val1 = (totalCompletedCoreSteps / totalCoreSteps) * 100;
  const subVal1 = ((totalCompletedCoreSteps + totalInProgressCoreSteps) / totalCoreSteps) * 100;
  const val1Color = colors.green;
  const val1Tip = totalCompletedCoreSteps + "/" + totalCoreSteps + " Core Steps Completed";
  const subVal1Tip = totalInProgressCoreSteps + "/" + totalCoreSteps + " Core Steps In Progress";
  const val2 = (totalCompletedSteps / totalSteps) * 100;
  const subVal2 = ((totalCompletedSteps + totalInProgressSteps) / totalSteps) * 100;
  const val2Color = colors.progressBlue;
  const val2Tip = totalCompletedSteps + "/" + totalSteps + " Steps Completed";
  const subVal2Tip = totalInProgressSteps + "/" + totalSteps + " Steps In Progress";

  if (thin) {
    return (
      <Grid container item xs={12} className={cls.backBarWrapper}>
        <ThinDualProgress
          val1={val1}
          subVal1={subVal1}
          val1Color={val1Color}
          val2={val2}
          subVal2={subVal2}
          val2Color={val2Color}
        />
      </Grid>
    );
  } else {
    return (
      <Grid container item xs={12} className={cls.backBarWrapper}>
        <DualProgress
          val1={val1}
          subVal1={subVal1}
          val1Color={val1Color}
          val1Tip={val1Tip}
          subVal1Tip={subVal1Tip}
          val2={val2}
          subVal2={subVal2}
          val2Color={val2Color}
          val2Tip={val2Tip}
          subVal2Tip={subVal2Tip}
        />
      </Grid>
    );
  }
}

export default PathProgress;
