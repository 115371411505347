import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Checkbox, Typography } from "@mui/material";
import AfTrainingQuestions from "./afTrainingQuestions.js";
import BTextField from "presentational/input/bTextField.js";

const useStyles = makeStyles({
  section: { margin: "1rem 0" }
});

function AfTrainingHistoryQuestions({ trainingAssessment, showFullTrainingHistory }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.section}>
        <BTextField
          title="Trainee Comment For Student:"
          readOnly={true}
          value={trainingAssessment ? trainingAssessment.assessments[0].comment : ""}
        />
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <AfTrainingQuestions answers={JSON.parse(trainingAssessment.traineeQuestionsData)} readOnly={true} />
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <BTextField title="Coach Comment For Trainee:" readOnly={true} value={trainingAssessment ? trainingAssessment.coachComment : ""} />
        {showFullTrainingHistory && (
          <Grid container item xs={12} className={classes.section}>
            <label>
              <Checkbox readOnly={true} checked={trainingAssessment ? trainingAssessment.goodExample : false} disabled={true} />
              <Typography variant="h6" display="inline">
                Flag as exemplary review of competency
              </Typography>
            </label>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default AfTrainingHistoryQuestions;
