import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from "query-string";
import { managerData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import ManageUsers from "./manageUsers.js";

function ManageUsersLoader({}) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [roles, setRoles] = useState([]);
  const [orgFilter, setOrgFilter] = useState(null);

  useEffect(() => {
    setPageTitle("User Management");

    const searchParams = queryString.parse(location.search);

    function _setData(m) {
      if (searchParams && searchParams.orgId) {
        setOrgFilter(searchParams.orgId);
      }
      setUsers(m.users.sort((a, b) => a.name.localeCompare(b.name))); // sort initial users by name
      setOrgs(m.availableOrganizations);
      setRoles(m.availableRoles);
      setLoading(false);
    }

    managerData.getManagedUsers(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  function resendEmail(email, success_cb, error_cb) {
    userData.forgotPassword(email, success_cb, error_cb);
  }

  function archiveUser(userId, orgId, success_cb, error_cb) {
    function _success_cb(data) {
      managerData.getManagedUsers((m) => {setUsers(m.users.sort((a, b) => a.name.localeCompare(b.name)))}, e => {
        setError(e.response.data.error);
      });
      if (typeof success_cb === "function") success_cb(data);
    }

    let data = {
      id: userId,
      organizationIdsToArchiveRelationship: [ orgId ]
    };
    managerData.updateManagedUser(userId, data, _success_cb, error_cb);
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <ManageUsers
      users={users}
      setUsers={setUsers}
      orgs={orgs}
      initialOrgFilter={orgFilter}
      roles={roles}
      resendEmail={resendEmail}
      archiveUser={archiveUser}
    />
  );
}

export default ManageUsersLoader;
