import React, { useState, useEffect } from "react";
import { equipReviewData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { EquipWrapper, LoadingScreen } from "bild-ui";
import queryString from "query-string";
import ReviewSharedPaths from "./reviewSharedPaths.js";

function SharedPathsLoader({}) {
  const searchParams = queryString.parse(location.search);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Shared Programs");
      setGroups(d.sharingGroups);
      setLoading(false);
      if (searchParams && searchParams.group_id) {
        setGroupId(Number(searchParams.group_id));
      }
    }

    equipReviewData.loadShared(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    if (groupId) {
      setCurrentGroup(groups.filter(g => g.id === groupId)[0]);
    } else {
      setCurrentGroup(null);
    }
  }, [groupId]);


  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <EquipWrapper>
      <ReviewSharedPaths groups={groups} groupId={groupId} setGroupId={setGroupId} currentGroup={currentGroup} />
    </EquipWrapper>    
  );
}

export default SharedPathsLoader;
