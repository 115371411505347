import { getRequest } from "../core/comm.js";
import { localStorage as ls } from "..";

const history_key = "search_history";

//// SEARCH
export function search(searchTerm, success_cb, error_cb) {
  const sanitizedSearchTerm = window.encodeURI(searchTerm).replace(/'/g, "%27");
  getRequest(`/search/${sanitizedSearchTerm}`, success_cb, null, error_cb);
}

export function staffSearch(searchTerm, success_cb, error_cb) {
  const sanitizedSearchTerm = window.encodeURI(searchTerm).replace(/'/g, "%27");
  getRequest(`/search/staff/${sanitizedSearchTerm}`, success_cb, null, error_cb);
}

function _userObject(user) {
  const uo = {};
  uo.name = user.name;
  uo.id = user.id;
  uo.avatarURL = user.avatarURL;
  uo.enrollments = user.enrollments
    ? user.enrollments.map(e => ({
        id: e.id,
        programName: e.programName,
        programClassAbbreviation: e.programClassAbbreviation,
        clUser: { id: e.clUser.id, name: e.clUser.name },
        afUser: { id: e.afUser.id, name: e.afUser.name }
      }))
    : [];
  uo.hits = 1;
  uo.lastHit = Date.now();

  return uo;
}

function _orgObject(org) {
  const oo = {};
  oo.organizationName = org.organizationName;
  oo.organizationId = org.organizationId;
  oo.organizationNumber = org.organizationNumber;
  oo.hits = 1;
  oo.lastHit = Date.now();

  return oo;
}

export function updateUserHistory(user) {
  let h = ls.getItem(history_key);
  if (!Boolean(h)) h = { users: [], organizations: [] };
  const userHistory = h.users || [];

  const userRecord = userHistory.find(u => u.id === user.id);
  if (userRecord) {
    if (userRecord.hits) userRecord.hits += 1;
    else userRecord.hits = 1;
    userRecord.lastHit = Date.now();
    userRecord.name = user.name;
    userRecord.avatarURL = user.avatarURL;
  } else if (userHistory.length >= 25) {
    userHistory.sort((a, b) => {
      if (a.lastHit > b.lastHit) return -1;
      else if (a.lastHit < b.lastHit) return 1;
      else if (a.hits > b.hits) return -1;
      else if (a.hits < b.hits) return 1;
      else return 0;
    });

    userHistory.pop();
    userHistory.push(_userObject(user));
  } else {
    userHistory.push(_userObject(user));
  }

  h.users = userHistory;
  ls.setItem(history_key, h);
}

export function updateOrganizationHistory(org) {
  let h = ls.getItem(history_key);
  if (!Boolean(h)) h = { users: [], organizations: [] };
  const orgHistory = h.organizations || [];

  const orgRecord = orgHistory.find(o => o.organizationId === org.organizationId);
  if (Boolean(orgRecord)) {
    if (orgRecord.hits) orgRecord.hits += 1;
    else orgRecord.hits = 1;
    orgRecord.lastHit = Date.now();
  } else if (orgHistory.length >= 25) {
    orgHistory.sort((a, b) => {
      if (a.lastHit > b.lastHit) return -1;
      else if (a.lastHit < b.lastHit) return 1;
      else if (a.hits > b.hits) return -1;
      else if (a.hits < b.hits) return 1;
      else return 0;
    });

    orgHistory.pop();
    orgHistory.push(_orgObject(org));
  } else {
    orgHistory.push(_orgObject(org));
  }

  h.organizations = orgHistory;
  ls.setItem(history_key, h);
}

export function removeUserFromHistory(id) {
  let h = ls.getItem(history_key);
  if (!Boolean(h)) {
    h = { users: [], organizations: [] };
    ls.setItem(history_key, h);
    return;
  }

  let userHistory = h.users || [];

  userHistory = userHistory.filter(u => u.id !== id);

  h.users = userHistory;
  ls.setItem(history_key, h);
}

export function removeOrganizationFromHistory(id) {
  let h = ls.getItem(history_key);
  if (!Boolean(h)) {
    h = { users: [], organizations: [] };
    ls.setItem(history_key, h);
    return;
  }

  let orgHistory = h.organizations || [];

  orgHistory = orgHistory.filter(o => o.organizationId !== id);

  h.organizations = orgHistory;
  ls.setItem(history_key, h);
}

export function getHistory() {
  let h = ls.getItem(history_key);
  if (!Boolean(h)) {
    h = { users: [], organizations: [] };
    ls.setItem(history_key, h);
  }

  return h;
}
