import React from "react";
import { makeStyles } from "@mui/styles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  hero: { backgroundColor: "#2a5082", paddingBottom: "5em", paddingTop: "5em", textAlign: "center", "& *": { color: "#FFF" } },
  body: { backgroundColor: "#FFF" },
  footer: { backgroundColor: "#20262a", color: "#fff", padding: "4em 0", textAlign: "center" }
});

// PRIVACY POLICY
function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.body}>
      <Grid item xs={12} className={classes.hero}>
        <Typography variant="h3">Privacy Policy</Typography>
      </Grid>
      <Grid item xs={8}>
        <p>
          <em>Last Updated: October 3, 2024</em>
        </p>
        <p>BILD International ("us", "we", or "our") operates the cloud.bild.org website (the "Service").</p>
        <p>
          This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the
          choices you have associated with that data. Our Privacy Policy for BILD International is created with the help of the Free Privacy Policy
          Generator.
        </p>
        <p>
          We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance
          with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms
          and Conditions, accessible from cloud.bild.org
        </p>
        <h3>Information Collection And Use</h3>
        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        <h3>Types of Data Collected</h3>
        <p>
          <strong>Personal Data</strong>
        </p>
        <p>
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
        </p>
        <p>
          <strong>Email address</strong>
          <br />
          First name and last name
          <br />
          Phone number
          <br />
          Address, State, Province, ZIP/Postal code, City
          <br />
          Cookies and Usage Data
        </p>
        <p>
          <strong>Usage Data</strong>
        </p>
        <p>
          We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your
          computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
        </p>
        <p>
          <strong>Tracking &amp; Cookies Data</strong>
          <br />
          We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
        </p>
        <p>
          Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to
          improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you
          may not be able to use some portions of our Service.
        </p>
        <p>
          <strong>Third Party Applications</strong>
          <br />
          We allow third party applications to connect temporarily with BILD Cloud for the purpose of importing documents into our Service. We do not
          allow our Service to export documents into any third party application.
        </p>
        <p>
          <strong>Examples of Cookies we use:</strong>
        </p>
        <p>
          Session Cookies. We use Session Cookies to operate our Service.
          <br />
          Preference Cookies. We use Preference Cookies to remember your preferences and various settings.
          <br />
          Security Cookies. We use Security Cookies for security purposes.
          <br />
          Use of Data
          <br />
          BILD International uses the collected data for various purposes:
        </p>
        <p>
          To provide and maintain the Service
          <br />
          To notify you about changes to our Service
          <br />
          To allow you to participate in interactive features of our Service when you choose to do so
          <br />
          To provide customer care and support
          <br />
          To provide analysis or valuable information so that we can improve the Service
          <br />
          To monitor the usage of the Service
          <br />
          To detect, prevent and address technical issues
        </p>
        <h3>Transfer Of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
        </p>
        <p>
          If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal
          Data, to United States and process it there.
        </p>
        <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
        <p>
          BILD International will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h3>Data Retention</h3>
        <p>
          We store your personal information for a period of time that is consistent with education standards and in line with all legal requirements.
        </p>
        <p>
          You may request for your data to be deleted by contacting us using any of the methods in the Contact Us section of this Privacy Policy.
        </p>
        <h3>Disclosure Of Data</h3>
        <p>
          <strong>Legal Requirements</strong>
          <br />
          BILD International may disclose your Personal Data in the good faith belief that such action is necessary to:
        </p>
        <p>
          <strong>To comply with a legal obligation</strong>
          <br />
          To protect and defend the rights or property of BILD International
          <br />
          To prevent or investigate possible wrongdoing in connection with the Service
          <br />
          To protect the personal safety of users of the Service or the public
          <br />
          To protect against legal liability
          <br />
          Security Of Data
          <br />
          The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage
          is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <h3>Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related services or to assist us in analyzing how our Service is used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
        <h3>Links To Other Sites</h3>
        <p>
          Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
        </p>
        <h3>Children's Privacy</h3>
        <p>Our Service does not address anyone under the age of 18 ("Children").</p>
        <p>
          We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are
          aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from
          children without verification of parental consent, we take steps to remove that information from our servers.
        </p>
        <h3>Changes To This Privacy Policy</h3>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>
          We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective
          date" at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <p>
          By email: cloudsupport@bild.org
          <br />
          By visiting this page on our website: cloud.bild.org
          <br />
          By phone number: 515-292-7012
          <br />
          By mail: 2400 Oakwood Road, Ames, IA 50014, United States
        </p>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <img src={process.env.PUBLIC_URL + "/bild-logo.png"} alt="BILD International" width="80px" />
        <p>
          © {new Date().getFullYear()} BILD International
          <br />
          2400 Oakwood Road
          <br />
          Ames, IA 50014-8417
          <br />
          515-292-7012
        </p>
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;
