import React from "react";
import { Blink } from "bild-ui";
import { Grid, Typography, Divider, Badge, Button } from "@mui/material";
import User from "./components/user.js";

import "./review.css";

// REVIEW ORGANIZATION
function ReviewOrganization({ totalReviewCount, organizationId, organizationName, enrollments, reviewType }) {

  return (
    <Grid container className="review-wrapper">
      <Grid item xs className="review">
        <Grid item xs={12} component={Blink} dst={"review"} review_type={reviewType}>
          <Button style={{ textTransform: "capitalize" }} className="back-link">
            <i className="fal fa-chevron-left" />
            &nbsp; Back to {reviewType} Review
          </Button>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5" className="page-headline" gutterBottom>
            {organizationName}
            <Badge color={totalReviewCount > 0 ? "secondary" : "primary"} className="header-badge" badgeContent={totalReviewCount} overlap="rectangular">
              &nbsp;
            </Badge>
          </Typography>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <User enrollments={enrollments} reviewType={reviewType} orgId={organizationId} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReviewOrganization;
