import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "./user.css";

function LegacyRegister({ username }) {
  return (
    <Grid container justifyContent="center" alignItems="center" className="register">
      <Grid item xs={12} className="register-box">
        <Grid container direction="column" justifyContent="space-between">
          <Grid item xs={12} className="form-item-container logo-container">
            <img src="/bild_cloud_login_logo.png" alt="bc-login-logo" className="logo" />
          </Grid>
          <Grid item xs={12} className="form-item-container">
            <Typography variant="body1" className="legacy-subtitle">
              This registration link has expired.
              <br />
              A new link has been sent to your email.
              <br />
            </Typography>
            <Typography variant="subtitle1" className="legacy-email">
              {username}
            </Typography>
            <Typography variant="body1" className="legacy-subtitle">
              Please allow 5 minutes for the email to appear in your inbox.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LegacyRegister;
