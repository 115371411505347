import React from "react";
import { makeStyles } from "@mui/styles";
import { Blink, UserAvatar, BTypography, PathProgress, colors, NormalButton, SortableList, Expander } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  header: {
    position: "sticky",
    top: "0",
    zIndex: "101",
    background: colors.white
  },
  body: { padding: "1rem 0" },
  coachWrapper: { padding: "0.5rem 1rem" },
  coach: { minWidth: "20rem" }
});

export default function ShepherdingCoaches({ coaches }) {
  const cls = useStyles();

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.header}>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item>
            <BTypography variant="h3">MCCEE Shepherding Coaches</BTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center" className={cls.body}>
        {coaches.sort((a,b) => a.name.localeCompare(b.name)).map(x =>
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} key={x.id}>
            <Grid item className={cls.coachWrapper}>
              <NormalButton
                dst="equip-review-shepherding-overview"
                user_id={x.id}
                variant={"secondary"}
                color={colors.white}
                hoverColor={colors.veryLightGray}
                labelColor={colors.bildBlue}
                hoverLabelColor={colors.bildBlue}
                borderColor={colors.bildBlue}
                className={cls.coach}
              >
                <UserAvatar size={50} src={x.avatarURL} name={x.name} badgeSrc={x.badgeURL} nameVariant="h5" />
                <i className="fas fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
