import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, FormAutocompleteField, FormTextField, NormalButton, UpdateMembersChecklists, UserAvatar } from "bild-ui";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

const useStyles = makeStyles({
  title: props => ({
    color: colors.white,
    backgroundColor: colors.darkGray,
  }),
  icon: {
    color: colors.white,
    fontWeight: "bold",
    padding: "0 0.5rem 0 0"
  },
  body: { padding: "1rem 0 0 0" },
  colHeader: { textAlign: "center", fontWeight: "bold" },
  submitWrapper: { padding: "1rem 0" },
  submit: { padding: "1rem 2rem" }
});

export default function OrgGroupMassUsersDialog({ open, onClose, onSubmit, orgGroups, unassignedUsers }) {
  const cls = useStyles();
  const [parents, setParents] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  useEffect(()=>{
    function getChildren(node) {
      let tempParents = [{"id": node.id, "name": node.name, "levelId": node.levelId, "parentOrganizationGroupId": node.parentOrganizationGroupId }];
      if (node.children) {
        for (let i=0; i < node.children.length; i++) {
          tempParents.push(getChildren(node.children[i]));
        }
      }
      return tempParents.flat();
    }

    if (orgGroups) {
      if (orgGroups.children) {
        let tempParents = [];
        for (let i=0; i < orgGroups.children.length; i++) {
          tempParents.push(getChildren(orgGroups.children[i]));
        }
        setParents(tempParents.flat());
      }
    }
  },[orgGroups]);

  useEffect(()=>{
    setRowCount(rows.filter(x => x.orgGroupId).length);
  },[rows]);

  useEffect(()=>{
    let newRows = [];
    for (let i=0; i<unassignedUsers.length; i++) {
      newRows.push({ id: unassignedUsers[i].id, name: unassignedUsers[i].name });
    }
    setRows(newRows);
  },[unassignedUsers]);

  function _onSubmit() {
    let sendData = [];
    let newUsers = rows.filter(x => x.orgGroupId);

    for (let i=0; i < parents.length; i++) {
      let usersToAdd = [];

      for (let j=0; j < newUsers.length; j++) {
        if (parents[i].id === newUsers[j].orgGroupId) {
          usersToAdd.push({id: newUsers[j].id});
        }
      }

      if (usersToAdd.length > 0) {
        sendData.push({
          id: parents[i].id,
          name: parents[i].name,
          levelId: parents[i].levelId,
          parentOrganizationGroupId: parents[i].parentOrganizationGroupId,
          usersToAdd: usersToAdd
        });
      }
    }

    onSubmit("massUpdate", sendData);
    _onClose();
  }

  function updateRow(index, field, value) {
    let newRows = JSON.parse(JSON.stringify(rows));
    newRows[index][field] = value;
    setRows(newRows);
  }

  function _onClose() {
    setRows([]);
    onClose();
  }

  function makeRow(user, index) {
    return (
      <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} key={index}>
        <Grid item xs={6} className={cls.rowItem}>
          <UserAvatar size={35} src={user.avatarURL} name={user.name} nameVariant="subtitle1" badgeSrc={user.badgeURL} />
        </Grid>
        <Grid item xs={6} className={cls.rowItem}>
          <FormAutocompleteField
            items={parents}
            value={rows && rows[index] && rows[index]["orgGroupId"]}
            setValue={v => {
              updateRow(index, "orgGroupId", v);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog open={open} onClose={_onClose} fullWidth maxWidth="sm">
      <DialogTitle className={cls.title}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <BTypography variant="h5">{unassignedUsers.length} Unassigned People</BTypography>
          </Grid>
          <Grid item>
            <NormalButton
              onClick={_onClose}
              variant={"micro"}
              color={colors.transparent}
              labelColor={colors.white}
              hoverColor={colors.darkGray}
              hoverLabelColor={colors.white}
            >
              <i className="far fa-times fa-2x" />
            </NormalButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} className={cls.body}>
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={6} className={cls.rowItem}>
              <BTypography variant="h6" className={cls.colHeader}>Person</BTypography>
            </Grid>
            <Grid item xs={6} className={cls.rowItem}>
              <BTypography variant="h6" className={cls.colHeader}>Group</BTypography>
            </Grid>
          </Grid>
          {unassignedUsers && unassignedUsers.map((x, index) => {
            return makeRow(x, index);
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} className={cls.submitWrapper} justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              onClick={_onSubmit}
              variant={"primary"}
              color={colors.bildBlue}
              labelColor={colors.white}
              className={cls.submit}
              disabled={rowCount < 1}
            >
              Assign ({rowCount})
            </NormalButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
