import { getRequest, putRequest } from "bild-data/core/comm.js";

export function getFundraisingItems(success_cb, error_cb) {
  getRequest(
    `/fundraising`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getFundraisingConstituent(fundraising_item_id, success_cb, error_cb) {
  getRequest(
    `/fundraising/items/${fundraising_item_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function getFundraisingTask(fundraising_task_id, success_cb, error_cb) {
  getRequest(
    `/fundraising/tasks/${fundraising_task_id}`,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function updateFundraisingConstituent(fundraising_item_id, data, success_cb, error_cb) {
  putRequest(
    `/fundraising/items/${fundraising_item_id}`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}

export function updateFundraisingTask(fundraising_task_id, data, success_cb, error_cb) {
  putRequest(
    `/fundraising/tasks/${fundraising_task_id}`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}
