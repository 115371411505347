import React, { useState } from "react";
import { putRequest } from "bild-data/core/comm";
import { toaster } from "presentational/toasts/toasts.js";
import LoadingDialog from "presentational/dialogs/loadingDialog.js";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// LEGACY CODE
function ChangeHandbookDialog({ open, closeDialog, username, classes, ...props }) {
  const [state, setState] = useState({ isLoading: false });
  const [error, setError] = useState({});

  function _handleChange(field, value) {
    setState({ ...state, [field]: value });
  }

  function _handleSubmit() {
    if (!state.subject) {
      setError({ subject: "Please select one of the options." });
    } else {
      let data = {
        supportCategory: state.subject,
        email: username,
        message: state.message ? state.message : "~No message content~",
        userUrlLocation: window.location.href,
        deviceInfo: navigator.userAgent
      };

      // TODO add intercom event for this
      if (!state.isLoading) {
        setState({ ...state, isLoading: true });
        putRequest(`/help/support_ticket`, data, _handleSuccess, closeDialog, _handleFailure, false, true);
      }
    }
  }

  function _handleSuccess() {
    // Request successfully recieved, let the user know and then close the dialog
    toaster.success("Your request has been successfully sent.");
    setState({ ...state, isLoading: false });
  }

  function _handleFailure(e) {
    // Things have failed, let the user know to try again or email us directly
    setState({ ...state, isLoading: false });
    console.log(e);
    toaster.error("There was an error while trying to contact the server. Please try again or email cloudsupport@bild.org directly.");
  }

  return (
    <React.Fragment>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle>Submit a Support Request</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={error.subject ? true : false}>
                <RadioGroup
                  value={state.subject}
                  defaultValue="Something seems to be broken."
                  onChange={e => {
                    _handleChange("subject", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="I would like to switch to the December 2023 Handbook."
                    control={<Radio />}
                    label="I would like to switch to the December 2023 Handbook."
                  />
                  <FormControlLabel
                    value="I would like more information about switching Handbooks."
                    control={<Radio />}
                    label="I would like more information about switching Handbooks."
                  />
                  {error.subject && <FormHelperText>{error.subject}</FormHelperText>}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ margin: "10px 0px" }}>
              <FormControl fullWidth>
                <FormLabel>Comments/Questions</FormLabel>
                <OutlinedInput
                  id="support-message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.message ? state.message : ""}
                  onChange={e => {
                    _handleChange("message", e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} className="default-cancel-button">
            Cancel
          </Button>
          <Button onClick={_handleSubmit} disabled={state.isLoading} className="default-submit-button">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={state.isLoading} />
    </React.Fragment>
  );
}

export default ChangeHandbookDialog;
