import React from "react";
import { Grid, Typography } from "@mui/material";
import OverviewCourse from "./overviewCourse.js";
import TranscriptCourse from "./transcriptCourse.js";
import SelectOverviewCourse from "./selectOverviewCourse.js";
import ContentWrapper from "presentational/wrappers/contentWrapper.js";
import classes from "../classes.js";

// TRANSCRIPT REVIEW STATUS
function EnrollmentContent({ contentType, statusItemHeaders, activeTab, enrollmentId, genSwitchCourse, courseGroups }) {

  function renderCourses(group) {
    const courses = group.courses;

    const components = [];
    for (var i = 0; i < courses.length; i++) {
      const c = courses[i];
      if (contentType === "Overview") {
        components.push(
          <OverviewCourse
            course={c}
            statusItemHeaders={statusItemHeaders}
            contentType={activeTab}
            key={i}
            enrollmentId={enrollmentId}
            genSwitchCourse={genSwitchCourse}
          />
        );
      } else {
        components.push(
          <TranscriptCourse
            course={c}
            statusItemHeaders={statusItemHeaders}
            contentType={contentType}
            key={i}
            enrollmentId={enrollmentId}
          />
        );
      }
    }

    return components;
  }

  function renderSelectableCourses(group) {
    const course = group.userAddNextCourse;
    let components = [];

    if (contentType === "Overview") {
      components = [<SelectOverviewCourse key="999" course={course} genSwitchCourse={genSwitchCourse} />];
    }

    // return a merged array of active courses and an "add course" button
    return [...renderCourses(group), ...components];
  }

  const components = [];
  for (let i = 0; i < courseGroups.length; i++) {
    const group = courseGroups[i];
    let courses = [];
    // TODO: Antioch FE ?? -- courseGroup API should have a groupColor attribute
    let groupColor = group.courses[0]
      ? group.courses[0].courseGroupColor.webFrontendValue
      : group.userAddNextCourse.courseGroupColor.webFrontendValue;

    // TODO replace this with backend logic for which course groups to display "add a course" button
    if (group.userAddsCourses) {
      courses = renderSelectableCourses(group);
    } else {
      courses = renderCourses(group);
    }
    components.push(
      <ContentWrapper variant="flat" key={i} className={classes.courseGroup}>
        <Typography variant="h5" gutterBottom style={{ color: groupColor }}>
          {group.name}
        </Typography>
        <Grid container spacing={2}>
          {courses}
        </Grid>
      </ContentWrapper>
    );
  }

  return <div>{components}</div>;
}

export default EnrollmentContent;
