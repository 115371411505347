import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, NetworkNodeDetails, HeatBadge, Chip, Blink } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  node: {
    maxWidth: "20rem",
    minWidth: "7rem",
    display: "inline-block",
    position: "relative",
    top: "1px"
  },
  nodeInner: {
    border: `2px solid ${colors.bildGray}`,
    borderRadius: "0.5rem",
    background: colors.white,
    overflow: "hidden",
    whiteSpace: "normal",
    color: colors.bildGray
  },
  nodeName: {
    background: colors.darkerGray,
    color: colors.white,
    fontWeight: "bold",
    /* Key fields allow for multiple lines before ellipsis */
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    padding: "0.25rem 0.5rem",
    minHeight: "2rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  badge: {
    position: "absolute",
    top: "-1rem",
    right: "-1rem",
    zIndex: "1000"
  },
  users: {
    padding: "0.5rem 0.25rem",
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  level1Groupnode: { width: "15rem !important" },
  level2Groupnode: { width: "17rem !important" },
  level3Groupnode: { width: "20rem !important" },
  level4Groupnode: { width: "25rem !important" },
  level1Groupname: { background: `${colors.level1Group} !important` },
  level2Groupname: { background: `${colors.level2Group} !important` },
  level3Groupname: { background: `${colors.level3Group} !important` },
  level4Groupname: { minHeight: "3.5rem", background: `${colors.level4Group} !important` },
  level1Group: { borderColor: `${colors.level1Group} !important` },
  level2Group: { borderColor: `${colors.level2Group} !important` },
  level3Group: { borderColor: `${colors.level3Group} !important` },
  level4Group: { borderColor: `${colors.level4Group} !important` }
});

function OrgGroupNode({ data, hideChildren, toggleNode, togglePopperData }) {
  const cls = useStyles();
  let oid = data.organizationId ? data.organizationId : data.id;
  let users = data.subEqpUsersWithAnyProgram;
  let prog = data.subEqpUsersWithAnyProgress;
  let nodeClass = "";
  let badgeLevel = prog > 500 ? 4 : prog > 100 ? 3 : prog > 25 ? 2 : 1;
  let badgeIcon = users > 500 ? "fire-4" : users > 100 ? "fire-3" : users > 25 ? "fire-2" : users > 0 ? "fire-1" : "";
  let hasChildren = data.children && data.children.length > 0;

  if (data.levelId === 1) {
    nodeClass = "level1Group";
  } else if (data.levelId === 2) {
    nodeClass = "level2Group";
  } else if (data.levelId === 3) {
    nodeClass = "level3Group";
  } else if (data.levelId === 4) {
    nodeClass = "level4Group";
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center" className={`${cls.node} ${cls[nodeClass + "node"]}`}>
      <Grid item className={cls.badge}>
        {badgeIcon && <HeatBadge level={badgeLevel} icon={badgeIcon} />}
      </Grid>
      <Grid container item className={`${cls.nodeInner} ${cls[nodeClass]}`} justifyContent="center" alignItems="center">
        <Grid
          container
          item
          xs={12}
          onClick={e => {
            togglePopperData(e, data, colors[nodeClass]);
          }}
          className={`${cls.nodeName} ${cls[nodeClass + "name"]}`}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {data.name}
          </Grid>
        </Grid>
        {data.users && data.users.length > 0 && (
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.users}>
            {data.users.map((y,j) => { return (
              <Grid item key={j}>
                <Blink dst="manage-user" user_id={y.id}>
                  <Chip member={y} hideName size={25} clickable/>
                </Blink>
              </Grid>
            ); })}
          </Grid>
        )}
        {hasChildren && (
          <Grid
            item
            xs={12}
            onClick={() => {
              toggleNode(oid);
            }}
            className={cls.hasChildren}
          >
            <i className={`fas fa-chevron-${hideChildren ? "up" : "down"}`} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default OrgGroupNode;
