import React from "react";
import { makeStyles } from "@mui/styles";
import { constants } from "..";

const useStyles = makeStyles({
  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  underline: { textDecoration: "underline" },
  strikethrough: { textDecoration: "line-through" },
  strikethroughUnderline: { textDecoration: "underline line-through" },
  code: { fontFamily: "courrier, monospace" }
});

function Leaf({ attributes, children, leaf }) {
  const classes = useStyles();
  const fontFamily = leaf.fontFamily || constants.fonts[0].fontFamily;
  const fontSize = leaf.fontSize || constants.fontSizes[4];

  let c = [];

  if (leaf.bold) c.push(classes.bold);

  if (leaf.italic) c.push(classes.italic);

  if (leaf.underline && leaf.strikethrough) c.push(classes.strikethroughUnderline);
  if (leaf.underline && !leaf.strikethrough) c.push(classes.underline);
  if (leaf.strikethrough && !leaf.underline) c.push(classes.strikethrough);

  if (leaf.code) c.push(classes.code);

  return (
    <span {...attributes} className={c.join(" ")} style={{ fontFamily, fontSize: `${fontSize}pt` }}>
      {children}
    </span>
  );
}

export default Leaf;
