import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Blink } from "bild-ui";

import CourseWrapper from "presentational/wrappers/courseWrapper.js";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import classes from "../classes.js";

// TRANSCRIPT REVIEW COURSE GROUPS
function OverviewCourse({ course, enrollmentId, genSwitchCourse }) {
  const groupColor = course.courseGroupColor.webFrontendValue;
  const groupIcon = course.familyGroupIcon.webFrontendValue;
  const name = course.name;
  const description = course.description;
  const switchable = course.substitutable;

  function renderCompetencyCircles(competencies) {
    let components = [];
    let max_length = 5; // Max number of competency circles to show
    let competency_length = competencies.length > max_length ? max_length : competencies.length;
    for (var i = 0; i < competency_length; i++) {
      const competencyProof = competencies[i].competencyProof;
      if (competencyProof && competencyProof.complete === true) {
        components.push(<i className="fas fa-circle competency-circle" key={i} />);
      } else {
        components.push(<i className="far fa-circle" key={i} />);
      }
    }

    // Show the number of hidden competencies (if any)
    if (competencies.length > max_length) {
      components.push(<span key="end-node">+ {competencies.length - max_length}</span>);
    }

    return <div className={classes.overview.circlesContainer}>{components}</div>;
  }

  const header = (
    <Grid container justifyContent="space-between" alignContent="center" style={{ backgroundColor: groupColor }}>
      <Grid item>{renderCompetencyCircles(course.competencies)}</Grid>
      <Grid item className={classes.overview.groupIconContainer}>
        <i className={`fas ${groupIcon}`} />
      </Grid>
    </Grid>
  );

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <CourseWrapper variant="overview" header={header} color={groupColor}>
        <Grid container direction="column" justifyContent="space-between" style={{ height: "100%" }}>
          <Grid item xs={12} className="course-overview-row">
            <Typography variant="h6" gutterBottom style={{ color: groupColor }}>
              {ReactHtmlParser(name)}
            </Typography>

            <Typography variant="body1" gutterBottom>
              &nbsp;
            </Typography>
          </Grid>

          <Grid item xs={12} className="course-overview-detail-row">
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" style={{ height: "100%" }}>
              <Grid
                item
                xs={4}
                className="course-overview-detail-row"
                component={Blink}
                dst={"course"}
                course_id={course.id}
                enrollment_id={enrollmentId}
              >
                <Button className="course-overview-button" style={{ backgroundColor: groupColor }}>
                  Open
                </Button>
              </Grid>
              {switchable && (
                <Grid item xs={8} className="course-overview-row course-switch">
                  <Button
                    onClick={() => {
                      genSwitchCourse(course);
                    }}
                    className="course-switch-button"
                    style={{ border: "1px solid" + groupColor, color: groupColor }}
                  >
                    <i className="fas fa-exchange" /> Switch Course
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CourseWrapper>
    </Grid>
  );
}

export default OverviewCourse;
