import React from "react";
import { colors, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip } from "@mui/material";
import Bbutton from "presentational/input/bbutton.js";

const useStyles = makeStyles({
  button: { color: colors.white, border: `1px solid ${colors.green}` },
  enabled: {
    background: colors.green,
    "&:hover": { background: colors.darkerGreen, border: `1px solid ${colors.green}` },
    "&:hover span": { color: colors.white }
  },
  disabled: {},
  tooltip: { fontSize: "3rem" }
});

function ToggleButton({ enabled, text, tipText, onClick }) {
  const classes = useStyles();

  tipText = enabled ? "" : tipText;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Tooltip
          title={<BTypography variant="subtitle1">Before you can assess, you need to attach a file</BTypography>}
          arrow
          placement="top"
          disableFocusListener={enabled}
          disableHoverListener={enabled}
          disableTouchListener={enabled}
        >
          <span>
            <Bbutton disabled={!enabled} className={`${classes.button} ${enabled ? classes.enabled : classes.disabled}`} onClick={onClick}>
              <BTypography>{text}</BTypography>
            </Bbutton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ToggleButton;
