import "./polyfills.js"; /* ABSOLUTELY MUST BE FIRST */

import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

/* MODULES */
import { DriveLoader, UserCertificateValidationLoader } from "modules";
import ModulesRoutes from "modules/modulesRoutes.js";
/* EQUIP */
import EquipRoutes from "equip/equipRoutes.js";
/* NEW ANTIOCH */
import AntiochRoutes from "containers/antiochRoutes.js";
/* ORIGINAL */
import UserProfileLoader from "./containers/user/userProfileLoader.js";
import UserRecordsLoader from "./containers/records/userRecordsLoader.js";
import DashboardLoader from "./containers/dashboard/dashboardLoader.js";
import EnrollmentLoader from "./containers/enrollment/enrollmentLoader.js";
import CourseLoader from "./containers/course/courseLoader.js";
import CompetencyProofLoader from "./containers/competency/competencyProofLoader.js";
import ReviewLoader from "./containers/review/reviewLoader.js";
import ReviewOrganizationLoader from "./containers/review/reviewOrganizationLoader.js";
import ReviewUserLoader from "./containers/review/reviewUserLoader.js";
import ReviewDelinquentLoader from "./containers/review/reviewDelinquentLoader";
import TraineeReviewLoader from "./containers/review/training/traineeReviewLoader.js";
import TraineeDetailsLoader from "./containers/review/training/traineeDetailsLoader.js";
import TrainingAssessmentLoader from "./containers/review/training/trainingAssessmentLoader.js";
import TrainingModuleReviewLoader from "./containers/review/training/trainingModuleReviewLoader.js";
import TrainingModulesReviewLoader from "./containers/review/training/trainingModulesReviewLoader.js";
import OrganizationsLoader from "./containers/organizations/organizationsLoader.js";
import OrganizationLoader from "./containers/organizations/organizationLoader.js";
import SearchLoader from "./containers/search/searchLoader.js";
import FolioLoader from "./containers/folio/folioLoader.js";
import LibraryLoader from "./containers/library/libraryLoader.js";
import ReaderLoader from "./containers/library/readerLoader.js";
import SimaLoader from "./containers/sima/simaLoader.js";
import SimaUserLoader from "./containers/sima/simaUserLoader.js";
import SimaReviewLoader from "./containers/sima/simaReviewLoader";
import Translations from "./containers/translations/translations.js";
import ImplementationPathway from "./containers/implement/implementationPathway.js";
import HomeLoader from "./containers/home/homeLoader.js";
import PoliciesLoader from "./containers/policies/policiesLoader.js";
import UserLoader from "./containers/user/userLoader.js";
import PasswordResetLoader from "./containers/user/passwordResetLoader.js";
import RegisterLoader from "./containers/user/registerLoader.js";
import MasqueradeLoader from "./containers/user/masqueradeLoader.js";
import UserAdminLoader from "./containers/admin/userAdminLoader.js";
import UserAdminCompetencyLoader from "./containers/admin/userAdminCompetencyLoader.js";
import UserAdminCourseLoader from "./containers/admin/userAdminCourseLoader.js";
import OrganizationAdminLoader from "./containers/admin/organizationAdminLoader.js";
import Menus from "./containers/menu/menus.js";
import { IntercomWidget } from "bild-vendors";
import ContractLoader from "./containers/contract/contractLoader.js";
import PageMissingScreen from "./presentational/screens/pageMissingScreen.js";
import { ToastContainer } from "./presentational/toasts/toasts.js";
import { FileProgressContainer } from "bild-utils";
import FlashMessage from "./presentational/flash/flash.js";

import "./index.css";
import theme from "./theme.js";
import { getDigestToken, getContractStatus } from "bild-data/core/localStorage.js";
import { checkContractStatus } from "bild-data/core/comm.js";
import { checkAdmin, checkSimaAccess, checkShowSearch, checkMasquerade } from "bild-data/user";
import { processUploadQueue } from "bild-data/drive";

// Check for user's auth token and enrollment_agreement (contract) status
const token = getDigestToken();
const contractNeeded = getContractStatus();
if (token) {
  checkContractStatus();
}
// Check if user has anything new to upload from Local Storage (only called on page load here)
processUploadQueue();
setInterval(function() {
  processUploadQueue();
}, 30000);

// TODO: when using in router possibly move to a "protectedRoute" class per https://stackoverflow.com/a/48497783/4966156
const isAdmin = checkAdmin();
const hasSimaAccess = checkSimaAccess();
const showSearch = checkShowSearch();
const canMasquerade = checkMasquerade();

// This is the container of our everything, menus require a reference to it to function properly
// const root = document.getElementById("root");
const container = document.getElementById("root");
const root = createRoot(container);

// This component will be rendered in the DOM and contain all our routes
const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <FileProgressContainer />
      <FlashMessage />

      {/* User is not logged in yet */}
      {!token && (
        <BrowserRouter>
          <Routes>
            <Route path="/home" element={<HomeLoader/>} />
            <Route path="/policies/:policy" element={<PoliciesLoader/>} />
            <Route path="/logout" element={<UserLoader/>} />
            <Route path="/login" element={<UserLoader/>} />
            <Route path="/password_reset/:reset_digest" element={<PasswordResetLoader/>} /> // Needs to be included in all routes
            <Route path="/register/:reset_digest" element={<RegisterLoader/>} /> // Needs to be included in all routes
            <Route path="/certificate_validation" element={<UserCertificateValidationLoader/>} /> // Needs to be included in all routes
            <Route path="/" element={<HomeLoader/>} />
            <Route path="*" element={<HomeLoader/>} />
          </Routes>
        </BrowserRouter>
      )}

      {/* User is logged in, but needs to sign a contract */}
      {token && contractNeeded && (
        <React.Fragment>
          <IntercomWidget />
          <Menus root={root} />
          <BrowserRouter>
            <Routes>
              <Route path="/password_reset/:reset_digest" element={<PasswordResetLoader/>} /> // Needs to be included in all routes
              <Route path="/register/:reset_digest" element={<RegisterLoader/>} /> // Needs to be included in all routes
              <Route path="/certificate_validation" element={<UserCertificateValidationLoader/>} /> // Needs to be included in all routes
              <Route path="*" element={<ContractLoader/>} />
            </Routes>
          </BrowserRouter>
        </React.Fragment>
      )}

      {/* User is logged in */}
      {token && !contractNeeded && (
        <React.Fragment>
          <IntercomWidget />
          <Menus root={root} />
          <BrowserRouter>
            <Routes>
              {ModulesRoutes}
              {EquipRoutes}
              {AntiochRoutes}
              <Route path="/password_reset/:reset_digest" element={<PasswordResetLoader/>} /> // Needs to be included in all routes
              <Route path="/register/:reset_digest" element={<RegisterLoader/>} /> // Needs to be included in all routes
              <Route path="/drive/:docId" element={<DriveLoader/>} />
              <Route path="/dashboard" element={<DashboardLoader/>} />
              <Route path="/profile/records" element={<UserRecordsLoader/>} />
              <Route path="/profile" element={<UserProfileLoader/>} />
              <Route path="/enrollments/:enrollment_id" element={<EnrollmentLoader/>} />
              <Route path="/competency_proofs/:competency_proof_id/courses/:course_id" element={<CompetencyProofLoader/>} />
              <Route path="/courses/:course_id/enrollments/:enrollment_id" element={<CourseLoader/>} />
              <Route path="/courses/:course_id/competency_proofs/:competency_proof_id" element={<CourseLoader/>} />
              <Route path="/review/training_assessments/:training_assessment_id" element={<TrainingAssessmentLoader/>} />
              <Route path="/review/trainee/:user_id" element={<TraineeDetailsLoader/>} />
              <Route path="/review/trainee" element={<TraineeDetailsLoader/>} />
              <Route path="/review/coach/:scope" element={<TraineeReviewLoader/>} />
              <Route path="/review/coach" element={<TraineeReviewLoader/>} />
              <Route path="/review/trainees/:user_id/training_modules/:training_module_id" element={<TrainingModuleReviewLoader/>} />
              <Route path="/review/trainees/:user_id/training_modules" element={<TrainingModulesReviewLoader/>} />
              <Route path="/review/delinquent/:user_id" element={<ReviewDelinquentLoader/>} />
              <Route path="/review/delinquent" element={<ReviewDelinquentLoader/>} />
              <Route path="/review/:review_type/users/:user_id" element={<ReviewUserLoader/>} />
              <Route path="/review/:review_type/organizations/:organization_id" element={<ReviewOrganizationLoader/>} />
              <Route path="/review/:review_type" element={<ReviewLoader/>} />
              {isAdmin && <Route path="/organizations/:organization_id/admin" element={<OrganizationAdminLoader/>} />}
              <Route path="/organizations/:organization_id/users" element={<OrganizationLoader/>} />
              <Route path="/organizations/:organization_id/admissions" element={<OrganizationLoader/>} />
              <Route path="/organizations" element={<OrganizationsLoader/>} />
              <Route path="/folio" element={<FolioLoader/>} />
              <Route path="/library/:book_id" element={<ReaderLoader/>} />
              <Route path="/library" element={<LibraryLoader/>} />
              {hasSimaAccess && <Route path="/sima/review" element={<SimaReviewLoader/>} />}
              {hasSimaAccess && <Route path="/sima/users/:user_id/enrollments/:enrollment_id" element={<SimaUserLoader/>} />}
              {hasSimaAccess && <Route path="/sima/search/:search_value" element={<SimaLoader/>} />}
              {hasSimaAccess && <Route path="/sima/search" element={<SimaLoader/>} />}
              <Route path="/translations" element={<Translations/>} />
              <Route path="/implement/*" element={<ImplementationPathway/>} />
              <Route path="/implement" element={<ImplementationPathway/>} />
              <Route path="/home" element={<HomeLoader/>} />
              <Route path="/policies/:policy" element={<PoliciesLoader/>} />
              {showSearch && <Route path="/search/:search_value" element={<SearchLoader/>} />}
              {showSearch && <Route path="/search" element={<SearchLoader/>} />}
              {canMasquerade && <Route path="/masquerade/:user_id" element={<MasqueradeLoader/>} />}
              {/* Need to keep this open so masqueraders can stop masquerade */}
              <Route path="/masquerade" element={<MasqueradeLoader/>} />
              {isAdmin && <Route path="/users/:user_id/admin" element={<UserAdminLoader/>} />}
              {isAdmin && <Route path="/users/:user_id/competency_proofs" element={<UserAdminCompetencyLoader/>} />}
              {isAdmin && <Route path="/users/:user_id/courses" element={<UserAdminCourseLoader/>} />}
              <Route path="/all_competency_proofs_overview" element={<UserAdminCompetencyLoader/>} />
              <Route path="/all_courses_overview" element={<UserAdminCourseLoader/>} />
              <Route path="/logout" element={<UserLoader/>} />
              <Route path="/" element={<DashboardLoader/>} />
              <Route path="/*" element={<PageMissingScreen/>} />
            </Routes>
          </BrowserRouter>
        </React.Fragment>
      )}
    </ThemeProvider>
  </StyledEngineProvider>
);

// Render the DOM
// ReactDOM.render(<App />, root);
root.render(<App />);
