import { getRequest } from "../core/comm.js";

//// LIBRARY
export function load(success_cb, error_cb) {
  getRequest(`/library`, success_cb, null, error_cb);
}

export function loadBook(book_id, success_cb, error_cb) {
  getRequest(`/library/${book_id}`, success_cb, null, error_cb);
}

export function getYouTubeVideos(success_cb, error_cb) {
  // Very special temp call, to be replaced in the backend once out of BETA
  return fetch('https://www.googleapis.com/youtube/v3/search?key=AIzaSyAoRQrc7idIl62jCYh-IbgGEl-jyJlkfiU&channelId=UCEyE5kyPIYO3X8Rhn9beuyA&part=snippet,id&order=date&maxResults=20')
    .then((response) => response.json())
    .then((responseJson) => {
      if (typeof success_cb === "function") success_cb(responseJson);
    })
    .catch((error) => {
      if (typeof error_cb === "function") success_cb(error);
    });
}
