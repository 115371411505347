import React, { useCallback, useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { colors, BTypography, NormalButton } from "bild-ui";
import { Grid, Dialog, DialogContent, Hidden, useMediaQuery } from "@mui/material";

const useStyles = makeStyles({
  dialog: { overflow: "hidden !important" },
  container: {
    position: "relative",
    overflow: "hidden !important",
    padding: "8px 12px"
  },
  title: {
    padding: "0 1rem 1rem 0"
  },
  canvas: {
    cursor: "pointer",
    background: "white",
    border: "3px dashed #d8d8d8",
    overflow: "none",
    "&:hover": {
      borderColor: colors.lightBlue
    },
    transition: "all 1s ease, opacity 0.5s ease"
  },
  buttonWrapper: { padding: "0.5rem 1rem" },
  button: { padding: "0.5rem 1rem" }
});

function UploadSignatureDialog({ open, closeDialog, uploadNewSignature, ...props }) {
  const cls = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ready, setReady] = useState(false);
  const [signatureImageSource, setSignatureImageSource] = useState(null);
  const [signatureOriginalImageSource, setSignatureOriginalImageSource] = useState(null);
  const canvasRef = useRef(null);
  const xRef = useRef(0);
  const yRef = useRef(0);
  const inputFile = useRef(null);
  const small = useMediaQuery(theme.breakpoints.down('md'));
  const xsmall = useMediaQuery(theme.breakpoints.down('sm'));
  const cW = small ? (xsmall ? 300 : 450) : 600;
  const cH = small ? (xsmall ? 150 : 225) : 300;

  useEffect(() => {
    window.addEventListener("mousedown", start, false);
    window.addEventListener("mouseup", stop, false);
    window.addEventListener("touchstart", start, false);
    window.addEventListener("touchend", stop, false);
  }, [canvasRef]);

  function reposition(event) {
    xRef.current = (event.clientX || event.touches[0].clientX) - event.target.getBoundingClientRect().left;
    yRef.current = (event.clientY || event.touches[0].clientY) - event.target.getBoundingClientRect().top;
  }

  function start(event) {
    if (canvasRef.current) {
      canvasRef.current.addEventListener("mousemove", draw, false);
      canvasRef.current.addEventListener("touchmove", draw, false);
      reposition(event);
    }
  }
  function stop() {
    if (canvasRef.current) {
      canvasRef.current.removeEventListener("mousemove", draw);
      canvasRef.current.removeEventListener("touchmove", draw);
      save();
    }
  }
  function save() {
    var dataURL = canvasRef.current.toDataURL("image/png");
    setSignatureImageSource(dataURL);
  }

  function draw(event) {
    setReady(true);
    let ctx = canvasRef.current.getContext("2d");
    ctx.beginPath();
    ctx.lineWidth = 5;
    ctx.lineCap = "round";
    ctx.strokeStyle = "#000000";
    ctx.moveTo(xRef.current, yRef.current);
    reposition(event);
    let sx = (event.clientX || event.touches[0].clientX) - event.target.getBoundingClientRect().left;
    let sy = (event.clientY || event.touches[0].clientY) - event.target.getBoundingClientRect().top;
    ctx.lineTo(sx, sy);
    ctx.stroke();
  }

  function clear() {
    setReady(false);
    let ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setSignatureImageSource(null);
  }

  function _handleUpload() {
    // Upload normal, cropped signature and unmodified original signature
    uploadNewSignature(signatureImageSource, signatureOriginalImageSource);
  }

  function _handleImport() {
    // get image that was selected to be uploaded
    var file = inputFile.current.files[0];
    // is this an image file or not?
    if (file && file.type.match("image.*")) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      // set the src of the preview image to be the newly selected file
      reader.onloadend = function(e) {
        this.url = e.target.result;
        setSignatureOriginalImageSource(e.target.result);
        const context = canvasRef.current.getContext("2d");
        const image = new Image();
        image.src = this.url;
        image.onload = () => {
          // set cropping dimension
          const dW = 600;
          const dH = 300;
          // set output default dimensions to cropping size
          let outW = dW;
          let outH = dH;
          let imageW = image.width;
          let imageH = image.height;
          let mx = 1;
          // If the image is not square, find the shorter side to set as our resizing anchor
          if (imageW >= imageH) {
            mx = imageH / dH;
          }
          if (imageW < imageH) {
            mx = imageW / dW;
          }
          // resize the image, keeping the original scale
          outW = outW * mx;
          outH = outH * mx;
          // center the source x,y
          const sx = Math.max(0, (imageW - outW) / 2);
          const sy = Math.max(0, (imageH - outH) / 2);
          // clear the canvas
          clear();
          setReady(true);
          // draw the resized image in the center of the cropping zone
          context.drawImage(image, sx, sy, outW, outH, 0, 0, dW, dH);
          // save to image ref
          save();
        };
      };
    }
  }

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth className={cls.dialog}>
      <DialogContent className={cls.container}>
        <Grid container justifyContent="center" alignItems="center">
          <Hidden only="sm">
            <Grid item xs={12} className={cls.title}>
              <BTypography variant="h4">Update Signature</BTypography>
            </Grid>
          </Hidden>
          <Grid item>
            <canvas ref={canvasRef} className={cls.canvas} width={cW} height={cH} />
          </Grid>
          <Grid container item xs direction="column" justifyContent="center" alignItems="flex-start">
            <Grid item className={cls.buttonWrapper}>
              <NormalButton
                onClick={clear}
                disabled={!ready}
                variant="secondary"
                color={colors.white}
                borderColor={colors.darkerRed}
                hoverColor={colors.darkerRed}
                labelColor={colors.darkerRed}
                className={cls.button}
              >
                Clear
              </NormalButton>
            </Grid>
            <Grid item className={cls.buttonWrapper}>
              <NormalButton
                onClick={() => {
                  inputFile.current.click();
                }}
                variant="secondary"
                color={colors.white}
                borderColor={colors.bildBlue}
                hoverColor={colors.bildBlue}
                labelColor={colors.bildBlue}
                className={cls.button}
              >
                Import
              </NormalButton>
              <input type="file" accept=".png, .jpg, .jpeg, .gif" onChange={_handleImport} ref={inputFile} style={{ display: "none" }} />
            </Grid>
            <Grid item className={cls.buttonWrapper}>
              <NormalButton
                onClick={_handleUpload}
                disabled={!ready}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.button}
              >
                Upload Signature
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export default UploadSignatureDialog;
