import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";

import { Button } from "@mui/material";

import AssessmentHistoryDialog from "./assessmentHistoryDialog.js";
import { PreviewFileDialog } from "modules/index.js";

const useStyles = makeStyles({
  button: { marginBottom: "1rem", color: "#3f51b5" }
});

function AssessmentHistoryFlowButton({ assessments, competencyName, docId, setDocId, fileLoading, previewFile, setPreviewFile, viewDocument, viewPreview }) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setDialogOpen(true)} className={classes.button}>
        View Assessment History
      </Button>

      <AssessmentHistoryDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        assessments={assessments}
        competencyName={ReactHtmlParser(competencyName)}
        viewDocument={viewDocument}
        viewPreview={viewPreview}
      />
    </>
  );
}

export default AssessmentHistoryFlowButton;
