import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  fileIcon: {
    fontSize: "1.5rem",
    width: "1.6rem",
    height: "1.6rem",
    lineHeight: "1.6rem",
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0 0.5rem",
    color: "#76C4FF"
  },
  folderIcon: {
    fontSize: "1.5rem",
    width: "1.6rem",
    height: "1.6rem",
    lineHeight: "1.6rem",
    verticalAlign: "middle",
    textAlign: "center",
    margin: "0 0.5rem",
    color: "#76C4FF"
  },
  addFolderIcon: {
    fontSize: "1.33rem",
    width: "1.4rem",
    height: "1.4rem",
    lineHeight: "1.4rem",
    verticalAlign: "top",
    textAlign: "center",
    marginRight: "0.5rem"
  },
  uploadFileIcon: {
    fontSize: "1.33rem",
    width: "1.4rem",
    height: "1.4rem",
    lineHeight: "1.4rem",
    verticalAlign: "top",
    textAlign: "center",
    marginRight: "0.5rem"
  },
  invisible: {
    fontSize: "1.5rem",
    width: "1.6rem",
    height: "1.6rem",
    lineHeight: "1.6rem",
    verticalAlign: "middle",
    textAlign: "middle",
    margin: "0 0.5rem",
    visibility: "hidden"
  }
});

function FileIcon({ variant, className, invisible }) {
  const classes = useStyles();
  let classNameProp;
  switch (variant) {
    case "add-from-dropbox":
      classNameProp = `fab fa-dropbox ${classes.uploadFileIcon}`;
      break;
    case "add-from-google":
      classNameProp = `fab fa-google-drive ${classes.uploadFileIcon}`;
      break;
    case "folder":
      classNameProp = `fas fa-folder ${classes.folderIcon}`;
      break;
    case "folder-open":
      classNameProp = `fas fa-folder-open ${classes.folderIcon}`;
      break;
    case "add-folder":
      classNameProp = `far fa-folder-plus ${classes.addFolderIcon}`;
      break;
    case "upload-file":
      classNameProp = `far fa-file-upload ${classes.uploadFileIcon}`;
      break;
    case "invisible":
      classNameProp = `fas fa-folder ${classes.invisible}`;
      break;
    default:
      classNameProp = `fas fa-${variant} ${classes.fileIcon}`;
      break;
  }

  if (className) {
    classNameProp += " " + className;
  }

  return <i className={classNameProp} />;
}

export default FileIcon;
