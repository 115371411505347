import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  backBar: {
    position: "relative",
    height: "0.75rem",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "100",
    backgroundColor: colors.veryLightGray
  },
  bar: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    bottom: "0",
    transition: "transform 0.2s linear",
    transformOrigin: "left"
  },
  subBar: {
    zIndex: "101",
    backgroundColor: colors.veryLightGreen,
    opacity: "0.75"
  },
  bar1: {
    zIndex: "102",
    backgroundColor: colors.green
  }
});

function Progress({ value, subValue }) {
  const cls = useStyles();

  return (
    <Grid container className={cls.backBar}>
      {subValue !== null && (
        <Grid item xs={12} className={`${cls.bar} ${cls.subBar}`} style={{ transform: `translateX(-${100 - parseInt(subValue)}%)` }}>
          &nbsp;
        </Grid>
      )}
      <Grid item xs={12} className={`${cls.bar} ${cls.bar1}`} style={{ transform: `translateX(-${100 - parseInt(value)}%)` }}>
        &nbsp;
      </Grid>
    </Grid>
  );
}

export default Progress;
