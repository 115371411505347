import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { libraryData } from "bild-data";
import { setPageTitle, hideIntercom } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import queryString from "query-string";

import Reader from "containers/library/reader.js";

function ReaderLoader() {
  const location = useLocation();
  const { book_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [docId, setDocId] = useState({});
  const [docAuthToken, setDocAuthToken] = useState({});
  const [language, setLanguage] = useState(null);
  const [downloadable, setDownloadable] = useState(false);
  const [page, setPage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(()=>{
    setPageTitle("Reader");
    hideIntercom();

    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      setLanguage(searchParams.language);
      setPage(searchParams.page);
    }

    function _setBook(data) {
      setDocId(data.boxDocumentId);
      setDocAuthToken(data.authToken);
      setDownloadable(data.language !== "2" && data.language !== "4" ? true : false); // All non-english, non-spanish materials are downloadable
      setLoading(false);
    }

    // Load book data
    libraryData.loadBook(book_id, _setBook, setError);
  }, []);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <Reader
        documentId={docId}
        documentAuthToken={docAuthToken}
        language={language}
        page={page}
        downloadable={downloadable}
      />
    );
  }
}

export default ReaderLoader;
