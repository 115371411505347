import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  badgeWrapper: props => ({
    width: "2rem",
    height: "2rem",
    border: "double 1px transparent",
    borderRadius: "100%",
    backgroundImage: `linear-gradient(white, white), radial-gradient(circle at top left, ${props.color1}, ${props.color2})`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box"
  }),
  badge: props => ({
    margin: props.side ? "0 0 0.2rem 0.2rem" : "",
    width: "40px",
    background: `linear-gradient(${props.color1}, ${props.color2})`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontSize: "1.5rem",
    marginTop: "-0.25rem"
  })
});

function HeatBadge({ level, icon, side }) {
  let color1;
  let color2;

  switch (level) {
    case 1:
      color1 = "#48a7ff";
      color2 = "#48a7ff";
      break;
    case 2:
      color1 = "#fbc512";
      color2 = "#fbc512";
      break;
    case 3:
      color1 = "#f18000";
      color2 = "#f18000";
      break;
    case 4:
      color1 = "#f32535";
      color2 = "#f32535";
      break;
    default:
  }

  const cls = useStyles({ color1, color2, side });

  return (
    <Grid container className={cls.badgeWrapper} justifyContent="center" alignItems="center">
      <i className={`${cls.badge} bfont bf-${icon}`}></i>
    </Grid>
  );
}

export default HeatBadge;
