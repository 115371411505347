import React from "react";
import { colors, BTypography, PopTip } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip } from "@mui/material";
import Bbutton from "presentational/input/bbutton.js";

const useStyles = makeStyles({
  button: {
    color: colors.white,
    paddingTop: ".75rem",
    paddingBottom: ".75rem",
    width: "100%",
    border: `1px solid ${colors.green}`
  },
  text: { fontWeight: "bold" },
  rounded: {
    borderRadius: "20px",
    boxShadow: "0 5px 3px grey"
  },
  squared: {
    borderRadius: "0"
  },
  enabled: {
    background: colors.green,
    "&:hover": { background: colors.green, filter: "brightness(0.9)" },
    "&:active": { background: colors.green, filter: "brightness(0.8)" },
    "&:hover span": { color: colors.white }
  },
  disabled: {},
  tooltip: { fontSize: "2.0rem" }
});

function AssessButton({ variant, enabled, text, content, tipText, onClick }) {
  const cls = useStyles();

  let btn = cls.button;
  switch (variant) {
    case "rounded":
      btn = `${btn} ${cls.rounded}`;
      break;
    case "squared":
      btn = `${btn} ${cls.squared}`;
      break;
    default:
      break;
  }

  tipText = enabled ? "" : tipText;
  return (
    <Grid container>
      <Grid item xs={12}>
        <PopTip text={tipText ? tipText : ""} arrowPlacement="top" delay={1000} disabled={enabled}>
          <span>
            <Bbutton disabled={!enabled} className={`${btn} ${enabled ? cls.enabled : cls.disabled}`} onClick={onClick}>
              {!content && <BTypography className={cls.text}>{text}</BTypography>}
              {content && content}
            </Bbutton>
          </span>
        </PopTip>
      </Grid>
    </Grid>
  );
}

export default AssessButton;
