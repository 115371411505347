import React, { useCallback } from "react";

import { Editable, Slate } from "slate-react";
import { Grid } from "@mui/material";
import { Leaf, Element, useMessageEditor } from ".";

function Message({ value }) {
  const editor = useMessageEditor();

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  return (
    <Slate editor={editor} value={value} selection={null}>
      <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
        <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly />
      </Grid>
    </Slate>
  );
}

export default Message;
