import React, { useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";

import Draggable from "bild-utils/dragDrop/draggable.js";
import Droppable from "bild-utils/dragDrop/droppable.js";
import FileDropZone from "bild-utils/dragDrop/fileDropZone.js";
import FileDragImage from "./fileDragImage.js";
import FolioListItem from "./folioListItem.js";
import ContentWrapper from "presentational/wrappers/contentWrapper.js";

import Divider from "@mui/material/Divider";

import { sort } from "../utils/disk.js";
import { renderFolioPayload, parseFolioPayload } from "bild-utils/dragDrop/dragDrop.js";

const useStyles = makeStyles({
  container: { display: "flex", flexDirection: "column" },
  scrollContainer: {
    maxHeight: "100%",
    overflow: "auto",
    "&::-webkit-overflow-scrolling": "touch"
  },
  dropZoneScroll: {
    flex: "1 1 auto",
    overflow: "auto"
  },
  dropZone: {
    flex: "1 1 auto"
  }
});

function FolioList({
  folders,
  files,
  contextRoot,
  onChangeDirectory,
  onUploadIntent,
  onMoveItem,
  onOpenContextMenu,
  onSelect,
  fileSelector,
  folderSelector,
  className,
  scrollable
}) {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = useState("descending");
  const { folders: sortedFolders, files: sortedFiles } = useMemo(() => sort({ folders, files }, sortOrder), [folders, files, sortOrder]);

  function _onSelectFolderFunc(item) {
    return function(e) {
      e.stopPropagation();
      if (folderSelector && onSelect) onSelect(item);
    };
  }

  function _onClickFolderFunc(item) {
    return function(e) {
      e.stopPropagation();
      if (folderSelector && onSelect) onSelect(item);
      if (onChangeDirectory) onChangeDirectory(item);
    };
  }

  function _onSelectFileFunc(item) {
    return function(e) {
      e.stopPropagation();
      if (fileSelector && onSelect) onSelect(item);
    };
  }

  function _onClickFileFunc(item) {
    return function(e) {
      e.stopPropagation();
      if (fileSelector) onSelect(item);
    };
  }

  function _onOpenMenuFunc(item) {
    return function(anchorEl, x, y) {
      if (onOpenContextMenu) onOpenContextMenu(item, anchorEl, x, y);
    };
  }

  const isMini = fileSelector || folderSelector ? true : false;
  const fliVariant = isMini ? "dialog-list-item" : "list-item";

  const components = [];
  for (let i = 0; i < sortedFolders.length; i++) {
    const f = sortedFolders[i];
    components.push(
      <React.Fragment key={`fo${f.id}${i}`}>
        <Draggable
          payload={renderFolioPayload(f.type, f.id)}
          dragImage={<FileDragImage variant="folder" name={f.name} />}
          disabled={isMini}
          key={`fo${f.id}${i}`}
        >
          <Droppable
            onDrop={payload => {
              const [type, id] = parseFolioPayload(payload);
              onMoveItem(type, id, f);
            }}
            disabled={isMini}
          >
            <FolioListItem
              variant={fliVariant}
              item={f}
              onClick={_onClickFolderFunc(f)}
              onSelect={_onSelectFolderFunc(f)}
              onOpenMenu={_onOpenMenuFunc(f)}
              selectable={folderSelector}
            />
          </Droppable>
        </Draggable>
        <Divider />
      </React.Fragment>
    );
  }
  for (let i = 0; i < sortedFiles.length; i++) {
    const f = sortedFiles[i];
    components.push(
      <Draggable
        payload={renderFolioPayload(f.type, f.id)}
        dragImage={<FileDragImage variant="file" name={f.displayName} />}
        disabled={isMini}
        key={`fi${f.id}${i}`}
      >
        <FolioListItem
          variant={fliVariant}
          item={f}
          onClick={_onClickFileFunc(f)}
          onSelect={_onSelectFileFunc(f)}
          onOpenMenu={_onOpenMenuFunc(f)}
          selectable={fileSelector}
        />
        <Divider />
      </Draggable>
    );
  }

  return (
    <div className={classes.container + " " + className}>
      {!isMini && <FolioListItem variant="list-header" sortOrder={sortOrder} onChangeSortOrder={setSortOrder} />}
      <FileDropZone
        hidden
        contextRoot={contextRoot}
        onUploadIntent={onUploadIntent}
        className={scrollable ? classes.dropZoneScroll : classes.dropZone}
        disabled={isMini}
      >
        <ContentWrapper variant="flat" className={scrollable ? classes.scrollContainer : ""} noPadding>
          {components}
        </ContentWrapper>
      </FileDropZone>
    </div>
  );
}

FolioList.defaultProps = {
  scrollable: true
};

export default FolioList;
