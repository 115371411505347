import React from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, NormalButton, Blink, BTypography, Glower } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import LoadingScreen from "presentational/screens/loadingScreen.js";

const useStyles = makeStyles({
  inner: props => ({ padding: "1rem", borderTop: `1px solid ${props.color}` }),
  name: props => ({ color: props.color }),
  buttonWrapper: { width: "100%", textAlign: "right" },
  button: props => ({ minWidth: "6rem" }),
  completeMark: { color: colors.green }
});

function LearningGroupItem({ item, color, defaultGroupItem, bcTab, bcGroup, bcItem }) {
  const cls = useStyles({ color });

  if (!item) {
    return <LoadingScreen />;
  } else {
    let buttonText = "Start";
    let buttonColor = color;

    if (item.completed) {
      buttonText = "Complete";
      buttonColor = colors.green;
    } else if (item.userLearningItem.inProgress) {
      buttonText = "Resume";
    }

    return (
      <Grid container id={defaultGroupItem ? "default-open-group-item" : ""}>
        <Glower glowOn={defaultGroupItem ? true : false} color={color}>
          <Grid container item justifyContent="space-between" alignItems="center" className={cls.inner}>
            <Grid item sm xs>
              <BTypography variant="body1" className={cls.name}>
                {ReactHtmlParser(item.userLearningItem.name)}
              </BTypography>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" item xs={4} className={cls.buttonWrapper}>
              <Grid item xs={12}>
                <NormalButton
                  variant="simple"
                  color={buttonColor}
                  labelColor={colors.white}
                  className={cls.button}
                  component={Blink}
                  dst="equip-user-path-item"
                  user_path_item_id={item.id}
                  breadcrumb={bcTab + "-" + bcGroup + "-" + bcItem}
                >
                  {buttonText}
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </Glower>
      </Grid>
    );
  }
}

export default LearningGroupItem;
