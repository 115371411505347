import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const useStyles = makeStyles({
  closeButton: {
    color: "#f50057"
  },
  submitButton: {
    color: "#3f51b5"
  }
});

function AdmissionsConfirmDialog({ userId, appId, defaultName, defaultEmail, defaultDegreeId, availablePrograms, open, onSubmit, onCancel }) {
  const cls = useStyles();
  const [degreeId, setDegreeId] = useState(-1);

  useEffect(()=>{
    if (defaultDegreeId) {
      setDegreeId(defaultDegreeId);
    }
  },[defaultDegreeId]);

  function checkDisabled() {
    if (degreeId && degreeId >= 0) {
      return false;
    } else {
      return true;
    }
  }

  function submit() {
    if (onSubmit && !checkDisabled()) {
      onSubmit(userId, appId, degreeId);
    }
  }

  const programOptions = [
    <option value={-1} key={-1}>
      Select a degree...
    </option>
  ];
  const degreeList = availablePrograms;
  for (let i = 0; i < degreeList.length; i++) {
    const d = degreeList[i];
    programOptions.push(
      <option value={d.programId} key={d.programId}>
        {d.programClassName}
      </option>
    );
  }

  return (
    <Dialog open={open === true} onClose={onCancel} fullWidth maxWidth="xl">
      <DialogTitle>Commend a Student</DialogTitle>
      <DialogContent>
        <Grid container wrap="wrap" justifyContent="space-around" alignItems="center">
          <Grid item md={4} xs={12} className="dialog-field">
            <TextField label="Name" fullWidth margin="dense" variant="outlined" value={defaultName} disabled={true} />
          </Grid>
          <Grid item md={4} xs={12} className="dialog-field">
            <TextField label="Email" fullWidth margin="dense" variant="outlined" value={defaultEmail} disabled={true} />
          </Grid>
          <Grid item md={4} xs={12} className="dialog-field">
            <TextField
              select
              label="Degree Program"
              fullWidth
              value={degreeId}
              SelectProps={{
                native: true
              }}
              margin="dense"
              variant="outlined"
              autoFocus
              onChange={e => {setDegreeId(e.target.value)}}
            >
              {programOptions}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={cls.closeButton} onClick={onCancel}>
          Cancel
        </Button>
        <Button className={cls.submitButton} disabled={checkDisabled()} onClick={submit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdmissionsConfirmDialog;
