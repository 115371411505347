import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    overflow: "auto"
  },
  circle: {
    fill: colors.darkGray
  },
  fillCircle: {
    fill: colors.lightOrange
  },
  line: {
    stroke: colors.darkGray
  },
  fillLine: {
    stroke: colors.lightOrange
  }
});

function Progress({ current, total }) {
  const classes = useStyles();
  const nodes = [];

  // Draw All Lines
  for (var i = 0; i < total; i++) {
    if (i > 0) {
      const filled = i <= current ? classes.fillLine : "";
      const line = (
        <line
          x1={30 * i - 15}
          y1="15"
          x2={30 * (i + 1) - 15}
          y2="15"
          className={`${classes.line} ${filled}`}
          style={{ strokeWidth: "3px" }}
          key={`${i}l`}
        />
      );
      nodes.push(line);
    }
  }
  // Draw All Circles
  for (var j = 0; j < total; j++) {
    const filled = j <= current ? classes.fillCircle : "";
    const circle = (
      <circle cx={30 * (j + 1) - 15} cy="15" width="15px" height="15px" r="10" className={`${classes.circle} ${filled}`} key={`${j}c`} />
    );
    nodes.push(circle);
  }

  return (
    <div className={classes.container}>
      <svg height="30px" width="100%" xmlns="http://www.w3.org/2000/svg" overflow="auto">
        {nodes}
      </svg>
    </div>
  );
}

export default Progress;
