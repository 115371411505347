import React, { useState, useEffect } from "react";
import { partnersData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts";
import NetworkPartnerCivilizations from "./networkPartnersCivilizations";

export default function NetworkPartnerCivlizationsLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [civilizations, setCivilizations] = useState([]);

  useEffect(() => {
    setPageTitle("Civilizations");

    partnersData.getAllNetworkPartnerCivilizations(_setData);
  }, []);

  function _setData(d) {
    setCivilizations(d.civilizations);
    setLoading(false);
  }

  function updatePartner(data) {
    partnersData.createUpdateNetworkPartner(data, ()=>{
      partnersData.getAllNetworkPartners(_setData);
      toaster.success("Partner Created!");
    })
  }

  if ((loading) || error) return <LoadingScreen error={error} />;
  return (
    <NetworkPartnerCivilizations civilizations={civilizations} />
  );
}
