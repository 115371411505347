import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reviewData, courseData, userData } from "bild-data";
import { hideIntercom, setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import TrainingModuleReview from "./components/trainingModuleReview.js";

function TrainingModuleReviewLoader({}) {
  const { user_id, training_module_id } = useParams();
  const [error, setError] = useState(null);
  const [competencies, setCompetencies] = useState(null);
  const user = userData.getOverview();
  const [trainee, setTrainee] = useState(null);
  const [trainingModule, setTrainingModule] = useState(null);
  const [trainingAssessments, setTrainingAssessments] = useState(null);

  useEffect(() => {
    setPageTitle("Associate Faculty Training Module Overview");
    hideIntercom();

    userData.loadUserProfile(user_id, data => setTrainee(data.name));

    reviewData.loadAfTrainingModuleReview(
      user_id,
      training_module_id,
      data => {
        setTrainingModule(data.trainingModule);
        setCompetencies(data.reviewCompetencies);
        setTrainingAssessments(data.trainingAssessments.sort((a, b) => a.competency.name.localeCompare(b.competency.name)));
      },
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  function loadCompetencyProof(courseId, competencyProofId, successCB) {
    courseData.loadCompetencyProof(courseId, competencyProofId, successCB);
  }

  if (!competencies || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <TrainingModuleReview
        competencies={competencies}
        loadCompetencyProof={loadCompetencyProof}
        user={user}
        trainee={trainee}
        trainingModule={trainingModule}
        trainingAssessments={trainingAssessments}
      />
    </BaseWrapper>
  );
}

export default TrainingModuleReviewLoader;
