import React from "react";
import { BTypography } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Divider } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    wrapper: {
      width: "100%",
      padding: "1rem 2rem",
      [theme.breakpoints.down('sm')]: {
        padding: "0.25rem !important"
      }
    },
    head: props => ({
      color: props.color,
      width: "100%",
      padding: "1rem 0",
      minHeight: "1rem",
      lineHeight: "2",
      [theme.breakpoints.down('sm')]: {
        padding: "0.25rem !important"
      }
    }),
    title: props => ({
      color: props.color,
      fontWeight: "bold"
    }),
    icon: {
      padding: "0.25rem 1rem 0 0",
      [theme.breakpoints.down('sm')]: {
        padding: "0.25rem !important"
      }
    },
    body: {
      padding: "1rem 0",
      [theme.breakpoints.down('sm')]: {
        padding: "0.25rem !important"
      }
    },
    divider: {
      paddingTop: "0.25rem"
    }
  })
);

function UserLearningItemSegment({ children, icon, title, rightButton, color }) {
  const cls = useStyles({ color });

  return (
    <Grid container className={cls.wrapper}>
      {title && (
        <Grid container item xs={12} justifyContent="flex-start" alignItems="center" className={cls.head}>
          <Grid item className={cls.icon}>
            <i className={icon} />
          </Grid>
          <Grid item xs>
            <BTypography vairant="h6" className={cls.title}>
              {title}
            </BTypography>
          </Grid>
          {rightButton && (
            <Grid container item xs justifyContent="flex-end">
              <Grid item>{rightButton}</Grid>
            </Grid>
          )}
          <Grid item xs={12} className={cls.divider}>
            <Divider />
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} className={cls.body}>
        {children}
      </Grid>
    </Grid>
  );
}

export default UserLearningItemSegment;
