import React from "react";
import { makeStyles } from "@mui/styles";

import { Grid, Typography, IconButton, Icon } from "@mui/material";

import { colors } from "bild-ui";

const useStyles = makeStyles({
  tile: {
    margin: "1rem",
    padding: "1.5rem 0.75rem 0.75rem 0.75rem",
    border: "1px solid grey",
    borderRadius: "4px",
    width: "150px",
    height: "200px",
    cursor: "pointer",
    position: "relative",
    "& *": { color: "grey" },

    "&:hover": { borderColor: "black", transition: "border-color 0.2s" }
  },
  createDocument: {
    borderColor: colors.bildBlue,
    "& *": { color: colors.bildBlue },

    "&:hover": { backgroundColor: colors.bildBlue, borderColor: colors.bildBlue, transition: "background-color 0.2s" },
    "&:hover *": { color: "white", transition: "color 0.2s" }
  },
  typo: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    height: "2.2rem"
  },
  deleteButton: {
    position: "absolute",
    top: "5px",
    right: "5px",

    height: "30px",
    width: "30px",
    marginLeft: "0.5rem",
    "& *": { fontSize: "16px" }
  }
});

function Tile({ name, onClick, createDocument, onDeleteIntent }) {
  const classes = useStyles();

  function _deleteIntent(e) {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onDeleteIntent === "function") onDeleteIntent();
  }

  if (createDocument) {
    return (
      <Grid item container justifyContent="center" alignItems="center" className={`${classes.tile} ${classes.createDocument}`} onClick={onClick}>
        <i className="fal fa-plus fa-3x" />
      </Grid>
    );
  }

  return (
    <Grid item container direction="column" justifyContent="space-between" alignItems="center" className={classes.tile} onClick={onClick}>
      <i className="fal fa-file-alt fa-6x" />
      <Typography variant="body2" align="center" className={classes.typo}>
        {name}
      </Typography>

      <IconButton className={classes.deleteButton} onClick={_deleteIntent} size="large">
        <Icon className="fal fa-times fa-xs" />
      </IconButton>
    </Grid>
  );
}

export default Tile;
