import React, { useState, useEffect } from "react";
import { colors, BTypography, ProgramBadge, NormalButton, Expander, BBadge } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  header: props => ({
    backgroundColor: props.color,
    backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    transition: "all 1s ease",
    position: "sticky",
    top: "0",
    bottom: "0"
  }),
  subHeader: props => ({
    backgroundImage: props.backgroundURL ? `url("${props.backgroundURL}")` : "",
    backgroundPositionY: "calc(50% + -6.15rem)",
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    backgroundColor: `${props.color}${props.backgroundURL ? "21" : ""}`,
    transition: "all 1s ease",
  }),
  closeButton: {
    position: "absolute",
    left: "0",
    top: "0",
    padding: "1rem"
  },
  programTitle: {
    padding: "1rem 0 0 0",
    color: colors.white,
    fontWeight: "bold",
    textAlign: "center"
  },
  programSubtitle: {
    padding: "0 0 0.5rem 0",
    color: colors.white,
    textAlign: "center"
  },
  pathDataWrapper: { padding: "0.5rem" },
  pathData: { color: colors.white, padding: "0 1rem" },
  dataLabel: { minWidth: "10rem" },
  dataName: { fontWeight: "bold" },
  bodyWrapper: { overflow: "auto" },
  body: { padding: "1rem", height: "100%" },
  groupRow: { padding: "1rem 0.5rem" },
  groupName: props => ({ color: props.color, fontWeight: "bold" }),
  groupSubtitle: props => ({ color: props.color }),
  groupDescription: {
    padding: "0 0.5rem",
    color: colors.darkerGray
  },
  itemRow: { padding: "0.75rem 0 0.5rem 0.5rem", borderBottom: `1px solid ${colors.veryLightGray}` },
  itemName: props => ({ color: props.color, fontWeight: "bold" }),
  itemMethod: {
    border: `1px solid ${colors.darkerGray}`,
    borderRadius: "4px",
    padding: "0.1rem 0.3rem",
    color: colors.darkerGray
  },
  itemLevel: {
    marginLeft: "0.25rem",
    border: `1px solid ${colors.darkerGray}`,
    borderRadius: "4px",
    padding: "0.1rem 0.3rem",
    color: colors.darkerGray
  },
  itemDescription: {
    padding: "0 1rem",
    color: colors.black
  }
});

function PreviewPath({ path, onClose }) {
  const backgroundURL = path.backgroundImageURL;
  const color = path.programColor.webFrontendValue;
  const cls = useStyles({ backgroundURL, color });

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.wrapper}>
      <Grid item xs={12} className={cls.header}>
        <NormalButton variant="primary" hoverColor={colors.darkGray} labelColor={colors.white} hoverLabelColor={colors.white} onClick={onClose} className={cls.closeButton}>
          <i className="fas fa-arrow-left fa-2x" />
        </NormalButton>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <BTypography variant="h3" className={cls.programTitle}>
              {ReactHtmlParser(path.name)}
            </BTypography>
          </Grid>
          {path.subtitle && (
            <Grid item xs={12}>
              <BTypography variant="subtitle1" className={cls.programSubtitle}>
                {ReactHtmlParser(path.subtitle)}
              </BTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.subHeader} justifyContent="center" alignItems="center">
        <Grid container item sm={6} xs={12} className={cls.pathDataWrapper}>
          <Grid container item xs={12} className={cls.pathData} justifyContent="center" alignItems="center">
            <Grid item className={cls.dataLabel}>
              Estimated Time:
            </Grid>
            <Grid item xs>
              <BTypography variant="subtitle1" className={cls.dataName}>
                {" "}
                {path.estimatedTime}
              </BTypography>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={cls.pathData} justifyContent="center" alignItems="center">
            <Grid item className={cls.dataLabel}>
              Level:
            </Grid>
            <Grid item xs>
              <BTypography variant="subtitle1" className={cls.dataName}>
                {" "}
                {path.completionLevel.name}
              </BTypography>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={cls.pathData} justifyContent="center" alignItems="center">
            <Grid item className={cls.dataLabel}>
              Total Steps:
            </Grid>
            <Grid item xs>
              <BTypography variant="subtitle1" className={cls.dataName}>
                {" "}
                {path.totalSteps}
              </BTypography>
            </Grid>
          </Grid>
        </Grid>
        {path.badges && (
          <Grid container item sm={6} xs={12} className={cls.badgeWrapper}>
            {JSON.parse(path.badges).map((b, i) => (
              <Grid item xs={12} className={cls.badge} key={i}>
                <ProgramBadge color={color} badgeURL={b.imageURL} completed={true} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={cls.bodyWrapper}>
        <Grid container item xs={12} className={cls.body}>
          {path.learningGroups.map(x => {
            return (
              <Grid container item xs={12} className={cls.groupRow} key={x.id}>
                <Grid item xs={12}>
                  <BTypography variant="h6" className={cls.groupName}>
                    {ReactHtmlParser(x.name)}
                  </BTypography>
                </Grid>
                <Grid item xs={12}>
                  <BTypography variant="subtitle1" className={cls.groupSubtitle}>
                    {ReactHtmlParser(x.subtitle)}
                  </BTypography>
                </Grid>
                <Grid item xs={12}>
                  <BTypography variant="body1" className={cls.groupDescription}>
                    {ReactHtmlParser(x.description)}
                  </BTypography>
                </Grid>
                {x.pathItems.map(y => {
                  return (
                    <Grid container item xs={12} className={cls.itemRow} key={y.id} alignItems="center">
                      <Grid container item xs={12} alignItems="center">
                        <Grid item xs>
                          <BTypography className={cls.itemName}>{y.learningItem.name}</BTypography>
                        </Grid>
                        <Grid item>
                          <span className={cls.itemMethod}>{y.learningItem.completionMethod.name}</span>
                          <span className={cls.itemLevel}>{y.learningItem.minimumCompletionLevel.name.split(" ").map(x => x[0])}</span>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} alignItems="center">
                        <Grid item className={cls.itemDescription}>
                          {ReactHtmlParser(y.learningItem.description)}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PreviewPath;
