import React, { useState, useEffect } from "react";
import WorldMap from "./worldMap";
import { organizationsData, userData } from "bild-data";
import { LoadingScreen, colors } from "bild-ui";
import { setPageTitle } from "bild-utils";


export default function WorldMapLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [heatCircleData, setHeatCircleData] = useState([]);
  const [markerData, setMarkerData] = useState([]);
  const [defaultLayers, setDefaultLayers] = useState(null);

  useEffect(() => {
    setPageTitle("BILD Network World Map");

    function _setData(m) {
      // Filter out orgs that have no GPS data, and sort by radius to keep smaller items on a higher z-index for popups
      let orgs = m.organizations.filter(x => x.gpsLocation).sort((a,b) => b.gpsRadius - a.gpsRadius);
      // Heatmap Circles
      let heatCircles = [];
      // General Location Marker
      let markers = [];
      for (let i=0; i < orgs.length; i++) {
        let org = orgs[i];
        // Only add orgs with at least one Equip or Antioch User
        if (org.equipUserCount || org.antiochUserCount) {
          let gps = org.gpsLocation.split(", ");
          let color = org.equipUserCount > 0 ? colors.bildBlue : org.antiochUserCount > 0 ? colors.antiochPurple : colors.darkGray;
          let item = {
            pos: [parseFloat(gps[0]),
            parseFloat(gps[1])],
            id: org.id,
            name: org.name,
            radius: org.gpsRadius,
            color: color,
            equipUserCount: org.equipUserCount,
            antiochUserCount: org.antiochUserCount,
          };
          heatCircles.push(item);
          markers.push(item);
        }
      }
      setHeatCircleData(heatCircles);
      setMarkerData(markers);
      setLoading(false);
    }

    // Initialize Layers
    const userPreferences = userData.getUserPreferences();
    _updateLayers(userPreferences.worldMapLayers);

    organizationsData.loadAllCombinedOrganizations(_setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  function _updateLayers(newLayers) {
    const userPreferences = userData.getUserPreferences();
    userPreferences.worldMapLayers = newLayers;
    userData.setUserPreferences(userPreferences);
    setDefaultLayers(newLayers);
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <WorldMap
      heatCircleData={heatCircleData}
      markerData={markerData}
      defaultLayers={defaultLayers}
      setLayers={_updateLayers}
    />
  );
}