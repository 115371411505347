import React from "react";
import { BTypography, NormalButton, UserAvatar, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, DialogTitle, Grid, useMediaQuery } from "@mui/material";
import AllUserCertificates from "modules/certificates/userCertificates/allUserCertificates";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles({
  labelWrapper: { color: colors.bildBlue },
  title: { paddingBottom: "1rem" },
  body: { minHeight: "50vh" },
});

export default function UserCertificatesDialog({ open, onClose, user, certificates }) {
  const theme = useTheme();
  const cls = useStyles();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true} fullScreen={smDown}>
      <DialogTitle className={cls.title}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
            <Grid item>
              <UserAvatar size={35} src={user.avatarURL} name={user.name} badgeSrc={user.badgeURL} nameVariant="subtitle1" />
            </Grid>
            <Grid item>
              <BTypography variant={"h5"}>&nbsp; Certificates</BTypography>
            </Grid>
          </Grid>
          <Grid item>
            <NormalButton
              onClick={onClose}
              variant={"micro"}
              color={colors.transparent}
              labelColor={colors.darkGray}
              hoverColor={colors.darkGray}
              hoverLabelColor={colors.black}
            >
              <i className="far fa-times fa-2x" />
            </NormalButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={cls.body}>
        <Grid container justifyContent="center" alignItems="center" className={cls.container}>
          <Grid container item>
            <AllUserCertificates certificates={certificates} hideHeader hideUser perPage={smDown ? 4 : 10} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
