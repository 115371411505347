import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { courseData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import CompetencyProof from "./competencyProof.js";

function CompetencyProofLoader({}) {
  const { course_id, competency_proof_id } = useParams();
  const [error, setError] = useState(null);
  const [course, setCourse] = useState(null);
  const [competency, setCompetency] = useState(null);
  const [callingUser, setCallingUser] = useState(userData.getOverview() || {});
  const [docId, setDocId] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    setPageTitle("Competency Proof");

    function _setData(data) {
      setCourse(data);
      for (let i = 0; i < data.competencies.length; i++) {
        let c = data.competencies[i];
        if (c.competencyProof && c.competencyProof.id.toString() === competency_proof_id.toString()) {
          setCompetency(c);
        }
      }
    }

    // Load Data
    courseData.loadCompetencyProof(course_id, competency_proof_id, _setData, e => {
      setError(e.response.data.error);
    });
    userData.loadOverview(
      o => setCallingUser(o.user),
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  function _setDocId(id) {
    setDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setFileLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setFileLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setFileLoading(false);
        }
      );
    }
  }

  if (!competency || error) return <LoadingScreen error={error} />;
  return (
    <CompetencyProof
      course={course}
      competency={competency}
      callingUser={callingUser}
      docId={docId}
      setDocId={_setDocId}
      fileLoading={fileLoading}
    />
  );
}

export default CompetencyProofLoader;
