import React, { useState, useEffect } from "react";
import { colors, SortableList, List, Blink, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Select, MenuItem, TextField, Checkbox } from "@mui/material";

const useStyles = makeStyles({
  row: { padding: "0.25rem 1rem" },
  callButton: { marginRight: "1rem", padding: "1rem 2rem" },
  phoneRemove: {
    marginTop: "2px",
    padding: "1rem",
    borderRadius: "4px",
    "&:hover": { cursor: "pointer" }
  },
  phoneNote: { width: "95%" },
  label: { textAlign: "right", paddingRight: "1rem" },
  required: { color: colors.red, fontSize: "1.5rem" }
});

function FundraisingPhones({ phones, phonesToRemove, setPhonesToRemove, setIsValid }) {
  const cls = useStyles();

  useEffect(() => {
    // check validity of fields
    if (typeof setIsValid === "function") {
      if (phonesToRemove.length === 0 || phonesToRemove.every(p => p.note.length > 0)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [phonesToRemove]);

  function _updatePhones(id) {
    if (phonesToRemove.some(y => y.id === id)) {
      // phone is already in the list, remove it
      setPhonesToRemove(phonesToRemove.filter(z => z.id !== id));
    } else {
      // phone is not in the list, add it
      let phone = phones.filter(z => z.bloomerangPhoneId === id)[0];
      phone.id = phone.bloomerangPhoneId;
      phone.note = "";
      setPhonesToRemove([...phonesToRemove, phone]);
    }
  }

  function _updatePhoneNote(id, newValue) {
    let phone = phonesToRemove.filter(z => z.id === id)[0];
    phone.note = newValue;
    setPhonesToRemove([...phonesToRemove.filter(z => z.id !== id), phone]);
  }

  function _clear() {
    setPhonesToRemove([]);
  }

  let listItems = phones.map(x => {
    let phone = phonesToRemove.filter(y => y.id === x.bloomerangPhoneId)[0];
    return [
      <Grid container>
        <Grid item>
          <NormalButton
            component={Blink}
            href={`tel:${x.number}`}
            variant="simple"
            color={colors.lightBlue}
            labelColor={colors.white}
            className={cls.callButton}
          >
            <i className="fad fa-phone-alt" /> &nbsp; Call
          </NormalButton>
        </Grid>
        <Grid item>
          {x.number}
          <br />
          {x.type}
        </Grid>
      </Grid>,
      <label className={cls.phoneRemove}>
        <Checkbox checked={phone ? true : false} onChange={() => _updatePhones(x.bloomerangPhoneId)} />
        Remove Phone Number
      </label>,
      <>
        <TextField
          placeholder={`reason for removal`}
          variant="outlined"
          disabled={phone ? false : true}
          value={phone ? phone.note : ""}
          onChange={e => {
            _updatePhoneNote(x.bloomerangPhoneId, e.target.value);
          }}
          className={cls.phoneNote}
        />
        {phone && <span className={cls.required}>*</span>}
      </>
    ];
  });

  return <List items={listItems} emptyMessage={"This constituent has no phone numbers."} />;
}

export default FundraisingPhones;
