import React, { useEffect, useState } from "react";
import { Blink, colors, LoadingScreen, NormalButton, SortableList, UserAvatar } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  tab: {
    padding: "0.5rem 1rem",
    borderRadius: "0",
    textAlign: "center",
  },
  activeTab: {
    background: `${colors.bildBlue} !important`,
    "&:hover": {
      background: `${colors.darkBildBlue} !important`
    }
  }
});

export default function UserShepherding({ data }) {
  const cls = useStyles();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const [learners, setLearners] = useState([]);
  const [shepherds, setShepherds] = useState([]);

  useEffect(()=>{
    if (data) {
      if (data.shepherdUserPaths) {
        let l = data.shepherdUserPaths.sort((a, b) => a.user.name.localeCompare(b.user.name));
        setLearners(l.map(x => [
          [<UserAvatar size={35} src={x.user.avatarURL} name={x.user.name} sortval={x.user.name} key={x.user.name}/>],
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <NormalButton
                component={Blink}
                dst="manage-user"
                user_id={x.user.id}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                Manage
              </NormalButton>
            </Grid>
          </Grid>
        ]));
      }

      if (data.userWithUserPaths && data.userWithUserPaths.userPaths) {
        let s = data.userWithUserPaths.userPaths.sort((a, b) => a.shepherdUser.name.localeCompare(b.shepherdUser.name)).map(x => x.shepherdUser);
        s = s.filter((elem, index) => s.findIndex(obj => obj.id === elem.id) === index);
        setShepherds(s.map(x => [
          [<UserAvatar size={35} src={x.avatarURL} name={x.name} badgeSrc={x.badgeURL} sortval={x.name} key={x.name}/>],
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              {/* NOTE Special: Exclude BILD Coaches */}
              {![11, 16, 19, 24, 27, 360, 563].includes(x.id) && (
                <NormalButton
                  component={Blink}
                  dst="manage-user"
                  user_id={x.id}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  className={cls.detailButton}
                >
                  Manage
                </NormalButton>
              )}
            </Grid>
          </Grid>
        ]));
      }

      setLoading(false);
    }

  },[data]);

  if (loading) return <LoadingScreen />;
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Grid item>
          <NormalButton
            onClick={()=>{setTab(1)}}
            className={`${cls.tab} ${tab === 1 ? cls.activeTab : ""}`}
            variant="primary"
            color={colors.gray}
            labelColor={colors.white}
            hoverColor={colors.darkGray}
          >
            {learners.length} Learner{learners.length > 1 ? "s" : ""}
          </NormalButton>
        </Grid>
        <Grid item>
          <NormalButton
            onClick={()=>{setTab(2)}}
            className={`${cls.tab} ${tab === 2 ? cls.activeTab : ""}`}
            variant="primary"
            color={colors.gray}
            labelColor={colors.white}
            hoverColor={colors.darkGray}
          >
            {shepherds.length} Shepherd{shepherds.length > 1 ? "s" : ""}
          </NormalButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SortableList
          headers={[]}
          items={tab === 1 ? learners : shepherds}
          itemPadding={"0.5rem 1rem"}
          emptyMessage={tab === 1 ? "No one currently being shepherded" : "No one currently shepherding"}
        />
      </Grid>
    </Grid>
  );
}
