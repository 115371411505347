import React from "react";
import { makeStyles } from "@mui/styles";

const MAX_LEN = 5; // Max number of competency circles to show

const useStyles = makeStyles({
  circle: { fontSize: "12px", margin: "0 3px" }
});

function Circle({ complete }) {
  const classes = useStyles();
  return <i className={`${!!complete ? "fas" : "far"} fa-circle ${classes.circle}`} />;
}

function CompetencyProgressCircles({ competencies }) {
  const length = competencies.length;
  const lim = length > MAX_LEN ? MAX_LEN : length;

  const circles = [];
  for (let i = 0; i < lim; i++) circles.push(<Circle {...competencies[i].competencyProof} key={i} />);

  // Show the number of hidden competencies (if any)
  if (length > MAX_LEN) circles.push(<span key="orphan">+ {length - MAX_LEN}</span>);

  return <div>{circles}</div>;
}

export default CompetencyProgressCircles;
