import React, { useEffect, useState } from "react";
import { downloadRequest } from "bild-data/core/comm";
import { Grid, Typography, Button } from "@mui/material";
import { NormalButton } from "bild-ui";
import FolioSelectorDialog from "containers/folio/components/folioSelectorDialog.js";
import FolioDisplayItem from "containers/folio/components/folioDisplayItem.js";
import FileActionButton from "containers/folio/components/fileActionButton.js";
import { load as loadDisk } from "bild-data/disk";
import { hideIntercom, showIntercom } from "bild-utils";

function SimaFiles({ userId, course, competency, competencyProof, files, onAttach, onFileUpload, onFileRemove, mapCompetency }) {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [diskArrays, setDiskArrays] = useState(null);
  useEffect(() => loadDisk(userId, setDiskArrays), [count]);

  function _directAttach(artifacts) {
    const attachments = artifacts.map(a => {
      return { id: a.id };
    });
    if (onAttach) onAttach(attachments);
    setOpen(false);
  }

  if (open) {
    hideIntercom();
  } else {
    showIntercom();
  }

  let fileRows = [];
  if (competencyProof) {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let removeArtifact = null;
      if (competencyProof.asStaffCanModifyFiles) {
        removeArtifact = () => {
          onFileRemove(file.id);
        };
      }
      fileRows.push(
        <Grid item key={i}>
          <Button
            className="default-download-button download-chip"
            onClick={() => {
              downloadRequest(file.fileURL, file.name);
            }}
          >
            <FolioDisplayItem variant="download-artifact" name={file.name} type="file" onDelete={removeArtifact} className="artifact-chip" />
          </Button>
        </Grid>
      );
    }
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={12}>
        <Typography variant="h6">Attachments:</Typography>
      </Grid>
      {(!competencyProof || competencyProof.asStaffCanModifyFiles) && (
        <>
          <Grid item>
            <NormalButton
              variant="simple"
              className="default-action-button"
              onClick={() => {
                setOpen(true);
                setCount(count + 1);
              }}
            >
              Attach File from Folio
            </NormalButton>
          </Grid>
          <Grid item>
            <FileActionButton onUploadFiles={onFileUpload} contextRoot={null} variant="upload-file" additionalFileTypes={".mp3"} />
          </Grid>
        </>
      )}
      <Grid item xs={12} className="sima-files">
        <Grid container>{fileRows}</Grid>
      </Grid>
      {diskArrays && (
        <FolioSelectorDialog
          title="Select files from your Folio:"
          diskArrays={diskArrays}
          onSubmit={selection => {
            _directAttach(selection);
          }}
          onClose={() => {
            setOpen(false);
          }}
          open={open}
        />
      )}
    </Grid>
  );
}

export default SimaFiles;
