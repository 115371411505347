import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { courseData, userData } from "bild-data";
import { LoadingScreen } from "bild-ui";
import AuditAntiochCourses from "./auditAntiochCourses";
import { loadAdminUser } from "bild-data/admin";


export default function AuditAntiochCoursesLoader({}) {
  const { user_id } = useParams();
  const userOverview = userData.getOverview();
  const userId = user_id || userOverview.user.id;
  const [loading, setLoading] = useState(true);
  const [courseGroups, setCourseGroups] = useState(null);
  const [user, setUser] = useState(null);
  const [totals, setTotals] = useState({ "totalCourses": 0, "totalCoursesCompleted": 0, "totalCompetencies": 0, "totalCompetenciesCompleted": 0 });

  useEffect(() => {
    setPageTitle("Audit Course Work");
    function _setData(d) {
      setCourseGroups(d);
      setLoading(false);
    }

    courseData.loadAllCompetencyProofs(userId, _setData);
  }, [userId]);

  useEffect(() => {
    if (!user) {
      loadAdminUser(userId, d => {
        setUser(d.user);
      });
    }
  }, [userId, user]);

  useEffect(() => {
    if (courseGroups) {
      const allCourses = courseGroups.map(x => x.courses ).flat();
      const allCompetencies = allCourses.map(x => x.competencies ).flat();
      setTotals({
        ...totals,
        "totalCourses": allCourses.length,
        "totalCoursesCompleted": allCourses.filter(x => x.complete).length,
        "totalCompetencies": allCompetencies.length,
        "totalCompetenciesCompleted": allCompetencies.filter(x => x.competencyProof && x.competencyProof.complete).length,
      });
    }
  }, [courseGroups]);

  if (loading) {
    return <LoadingScreen />;
  } else {
    return <AuditAntiochCourses user={user} courseGroups={courseGroups} totals={totals} />;
  }
}
