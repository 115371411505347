import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { colors, BTypography, SupportDialog, NormalButton } from "bild-ui";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import Folio from "containers/folio/folio";
import NetworkPartners from "./networkPartners";
import NetworkPartnerCivilizationDialog from "./components/networkPartnerCivilizationDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    empty: { padding: "1rem", color: colors.darkGray },
    header: { padding: "1rem 1rem", background: colors.white, position: "sticky", top: 0, zIndex: 1000, maxHeight: "20vh", overflow: "auto" },
    lastSeen: { fontStyle: "italic", padding: "0 0.5rem" },
    emailWrapper: { padding: "0.5rem 0" },
    email: { padding: "0.25rem 0.5rem", borderRadius: "0.2rem", border: `0.1rem solid ${colors.lightGray}`},
    headerIcon: { color: colors.darkerGray },
    headerRight: { textAlign: "right", },
    headerStatus: {
      border: `0.2rem solid ${colors.lightBlue}`,
      borderRadius: "0.4rem",
      padding: "1rem"
    },
    headerSubject: {
      padding: "0.25rem",
      textAlign: "left",
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "scroll",
      textOverflow: "ellipsis",
    },
    headerName: { paddingRight: "1rem" },
    body: { paddingBottom: "2rem" },
    sectionWrapper: { 
      padding: "0.5rem 0.5rem 0 0",
      [theme.breakpoints.down('md')]: {
        padding: "0.25rem !important"
      },
    },
    sectionWrapperLeft: { paddingLeft: "0.5rem" },
    section: { padding: "0.5rem", background: colors.white },
    sectionHeader: { padding: "0.5rem" },
    sectionInner: { minHeight: "20rem", maxHeight: "25rem", overflowY: "auto", },
    sectionDiagram: { maxHeight: "20rem !important", overflow: "hidden !important" },
    item: {
      backgroundColor: colors.white,
      border: "1px solid",
      borderRadius: `4px`,
      padding: "1rem",
      height: "100%"
    },
    status: { fontWeight: "bold", textAlign: "center" },
    greenStatus: { color: colors.darkGreen },
    yellowStatus: { color: colors.darkerYellow },
    redStatus: { color: colors.darkRed },
    label: { textAlign: "right", paddingRight: "1rem" },
    input: {
      width: "100%",
      minHeight: "3.5rem",
      padding: "0 1rem",
      background: colors.white,
      border: `1px solid ${colors.black}`,
      borderRadius: "4px"
    },
  }));

export default function NetworkPartnerCivilization({ civilization, partners, disk, onCreateFolder, onUploadFiles, onDownloadItem, onPreviewIntent, previewDocId, previewDocLoading, previewFile, updateCivilization }) {
  const theme = useTheme();
  const cls = useStyles(theme);
  const [civDialog, setCivDialog] = useState(false);
  const [deleteFileRequest, setDeleteFileRequest] = useState(null);

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="center">
        <Grid container item sm={8} xs={12} justifyContent="flex-start" alignItems="center">
          <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
            <Grid item className={cls.headerName}>
              <BTypography variant="h3"><i className={`fas fa-triangle fa-fw ${cls.headerIcon}`} /> {civilization.name}</BTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={4} xs={12} justifyContent="flex-end" alignItems="flex-end" className={cls.headerRight}>
          <Grid container item xs={12} className={cls.headerStatus} justifyContent="flex-end" alignItems="flex-end">
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item xs className={cls.headerSubject}>
                <BTypography variant="subtitle1">
                  {civilization.currentStatus}
                </BTypography>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
              <Grid item>
                <BTypography variant="body1">
                  {civilization.currentStatusUpdatedAt}
                </BTypography>
              </Grid>
              <Grid item>
                <NormalButton
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  onClick={()=>{
                    setCivDialog(true);
                  }}
                >
                  Update
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <Grid container item lg={12} md={12} sm={12} xs={12} className={`${cls.rowWrapper} ${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          <Grid item xs={12} className={cls.section}>
            <NetworkPartners title={`${civilization.name}'s Partners (${civilization.networkPartnersCount})`} partners={partners} fixedHeight={"40vh"} />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} className={`${cls.rowWrapper} ${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          {disk && (
            <Folio
              diskArrays={disk}
              onCreateFolder={onCreateFolder}
              onUploadFiles={onUploadFiles}
              onPreviewIntent={onPreviewIntent}
              onDownloadItem={onDownloadItem}
              onDeleteItem={(x)=>{setDeleteFileRequest(x)}}
              previewDocId={previewDocId}
              previewDocLoading={previewDocLoading}
              previewFile={previewFile}
              rootBreadcrumbTitle="Civilization Files"
              fullWidth
            />
          )}
        </Grid>
      </Grid>
      <NetworkPartnerCivilizationDialog
        open={civDialog}
        onClose={()=>{setCivDialog(false)}}
        civilization={civilization}
        updateCivilization={updateCivilization}
      />
      <SupportDialog
        open={deleteFileRequest ? true : false}
        closeDialog={() => {
          setDeleteFileRequest(null);
        }}
        options={[`I would like to remove this file id: '${deleteFileRequest ? deleteFileRequest.id : ""}' with name: '${deleteFileRequest ? deleteFileRequest.name : ""}' for civilization id '${civilization.id}'.`]}
      />
    </Grid>
  );
}
