import { useRef } from "react";

class Padlock {
  constructor(padlockRef) {
    this.padlockRef = padlockRef;
    this.lock = this.lock.bind(this);
    this.unlock = this.unlock.bind(this);
    this.isLocked = this.isLocked.bind(this);
  }

  lock() {
    if (this.padlockRef.current) {
      // Cannot lock.
      return false;
    } else {
      // Lock.
      this.padlockRef.current = true;
      return true;
    }
  }

  unlock() {
    if (!this.padlockRef.current) {
      // Cannot unlock.
      return false;
    } else {
      // unlock.
      this.padlockRef.current = false;
      return false;
    }
  }

  isLocked() {
    return !!this.padlockRef.current;
  }
}

export default function usePadlock(defaultVal = false) {
  return new Padlock(useRef(defaultVal));
}
