import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { simaData } from "bild-data";
import SimaSearch from "./simaSearch.js";

// SIMA LOADER
function SimaLoader({}) {
  const { search_value } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState(search_value);

  useEffect(()=>{
    setPageTitle("SIMA");

    function _setUsers(d) {
      setValue(search_value);
      setUsers(d.users);
      setLoading(false);
    }

    if (!search_value) {
      setValue("");
      setLoading(false);
    }

    simaData.loadSimaSearch(search_value, _setUsers, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return <SimaSearch users={users} value={value} />;
  }
}

export default SimaLoader;
