import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, NormalButton } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  wrapper: { background: colors.white, padding: "0.25rem" },
  button: { padding: "0.25rem" }
});

function ZoomButtons({ scale, setScale, changeScale, max, min }) {
  const cls = useStyles();

  function _changeScale(newScale) {
    if (newScale > max) {
      // do nothing, this is over the limit
    } else if (newScale < min) {
      // do nothing, this is under the minimum
    } else {
      setScale(newScale);
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.wrapper}>
      <Grid item sm={6} xs={12}>
        <NormalButton
          onClick={() => {
            _changeScale(scale + 1);
          }}
          className={cls.button}
          variant={"simple"}
        >
          <i className="fal fa-search-minus" />
        </NormalButton>
      </Grid>
      <Grid item sm={6} xs={12}>
        <NormalButton
          onClick={() => {
            _changeScale(scale - 1);
          }}
          className={cls.button}
          variant={"simple"}
        >
          <i className="fal fa-search-plus" />
        </NormalButton>
      </Grid>
    </Grid>
  );
}

export default ZoomButtons;
