import React from "react";
import { colors, Blink, BTypography, UserAvatar, PathProgress, ProgramBadge, NormalButton, PopTip } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import {} from "bild-ui";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { downloadRequest } from "bild-data/core/comm";

const useStyles = makeStyles({
  pathWrapper: {
    minHeight: "5rem",
    padding: "1rem"
  },
  path: {
    borderRadius: "4px",
    backgroundColor: colors.white
  },
  inactive: {
    opacity: "0.8"
  },
  pathTitleWrapper: props => ({
    padding: "1rem 1rem 0.5rem 1rem",
    "&:hover": {
      backgroundColor: `${props.color}20 !important`
    }
  }),
  pathIcon: {
    lineHeight: "2rem",
    paddingRight: "0.5rem",
  },
  pathTitle: {
    lineHeight: "2rem",
    fontWeight: "bold",
    marginBottom: "0rem",
    "& sup": {
      lineHeight: "1rem"
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "3",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  pathSubtitle: { },
  pathArrow: { paddingLeft: "0.5rem" },
  shepherdAvatar: { padding: "0 0.25rem 0 0.5rem" },
  progress: { padding: "0.5rem 1rem 0 0" },
  certificate: { textAlign: "center", padding: "1rem" },
  bottomWrapper: { padding: "0.5rem 1rem 1rem 1rem" }
});

export default function UserPathThumbnail({ path, color, showFull }) {
  const cls = useStyles({color});

  const sm = showFull ? 12 : path.active ? 6 : (path.completed ? 6 : 4);

  return (
    <Grid container item sm={sm} xs={12} className={cls.pathWrapper}>
      <Grid
        container
        item
        xs={12}
        className={`${cls.path} ${!path.active && !path.completed ? cls.inactive : ""}`}
        style={{ border: `0.1rem solid ${color}` }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          component={Blink}
          dst="equip-user-path"
          user_path_id={path.id}
          className={cls.pathTitleWrapper }
        >
          <Grid container item xs={12} alignItems={"center"} justifyContent={"center"}>
            <Grid container item xs>
              <Grid item style={{ color: color }}>
                <i className={`fal fa-badge-check ${cls.pathIcon}`} />
              </Grid>
              <Grid container item xs>
                <Grid item xs={12}>
                  <PopTip text={path.title} delay={1000}>
                    <BTypography variant="h6" className={cls.pathTitle} style={{ color: color }}>
                      {ReactHtmlParser(path.title)}
                    </BTypography>
                  </PopTip>
                </Grid>
                {path.subtitle && (
                  <Grid item xs={12}>
                    <BTypography variant="subitle2" className={cls.pathSubtitle} style={{ color: color }}>
                      {ReactHtmlParser(path.subtitle)}
                    </BTypography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <BTypography variant="h6" className={cls.pathArrow} style={{ color: color }}>
                <i className="far fa-chevron-right" />
              </BTypography>
            </Grid>
          </Grid>
          
        </Grid>
        {(!path.completed || showFull) && (
          <Grid container item xs={12} className={cls.bottomWrapper}>
            {(path.active || !path.active || showFull) && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Grid item>Shepherd:</Grid>
                <Grid item className={cls.shepherdAvatar}>
                  <UserAvatar size={30} src={path.shepherdUser.avatarURL} name={path.shepherdUser.name} badgeSrc={path.shepherdUser.badgeURL}  />
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} className={cls.progress} alignItems="flex-end" justifyContent="flex-end">
              <Grid item xs={12}>
                <PathProgress
                  totalCompletedCoreSteps={path.totalCompletedCoreSteps}
                  totalInProgressCoreSteps={path.totalInProgressCoreSteps}
                  totalCoreSteps={path.totalCoreSteps}
                  totalCompletedSteps={path.totalCompletedSteps}
                  totalInProgressSteps={path.totalInProgressSteps}
                  totalSteps={path.totalSteps}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {path.completed && (
          <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.bottomWrapper}>
            <Grid container item sm={9} xs={12} className={cls.badgeWrapper}>
              {path.badges && JSON.parse(path.badges).map((b, i) => (
                <Grid item xs={12} className={cls.badge} key={i}>
                  <ProgramBadge color={color} badgeURL={b.imageURL} completed={path.completed} />
                </Grid>
              ))}
            </Grid>
            <Grid container item sm={3} xs={12} className={cls.certificateWrapper}>
              {path.certificates && path.certificates.map((c, i) => (
                <Grid item xs={12} className={cls.certificate} key={i}>
                  <NormalButton
                    variant={"secondary"}
                    color={colors.white}
                    labelColor={color}
                    borderColor={color}
                    hoverLabelColor={colors.white}
                    hoverColor={color}
                    onClick={() => {
                      downloadRequest(c.downloadURL, c.name);
                    }}
                  >
                    <i className="fas fa-download" />
                  </NormalButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}