import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { Drive } from ".";
import { FullPageEditor } from "modules";
import { driveData, diskData } from "bild-data";
import { LoadingScreen } from "bild-ui";

function DriveLoader() {
  const { docId } = useParams();
  const [handles, setHandles] = useState(driveData.getHandles());
  const [loading, setLoading] = useState(true);
  const [docObject, setDocObject] = useState(null);
  const [docHandle, setDocHandle] = useState(null);

  useEffect(() => {
    if (docId) {
      setDocObject(driveData.getDocument(docId));
      setDocHandle(driveData.getHandle(docId));

      // If the file is not yet available locally, fetch it, save it locally, and return it
      if (!docObject) {
        diskData.loadArtifact(
          docId,
          () => {
            setDocObject(driveData.getDocument(docId));
            setDocHandle(driveData.getHandle(docId));
            setLoading(false);
          },
          e => {
            console.log("Unable to download file from server.");
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
    }
  }, []);

  if (docId) {
    if (loading) return <LoadingScreen />;
    return (
      <FullPageEditor document={docObject} handle={docHandle} onHandleChange={driveData.updateHandle} onDocumentChange={driveData.updateDocument} />
    );
  }

  function createNewDocument() {
    const id = driveData.createDocument();
    window.location.href = `/drive/${id}`;
  }

  function deleteDocument(id) {
    driveData.deleteDocument(id);
    setHandles(driveData.getHandles());
  }

  function goToDocument(handle) {
    window.location.href = `/drive/${handle.id}`;
  }

  return <Drive handles={handles} onCreateDocument={createNewDocument} onGoToDocument={goToDocument} onDeleteDocument={deleteDocument} />;
}

export default DriveLoader;
