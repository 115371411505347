import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

const baseURL = process.env.REACT_APP_BC2_API_URL;

const useStyles = makeStyles({
  container: { overflow: "hidden !important" },
  avatar: props => ({
    width: props.width,
    maxWidth: "100%",
    overflow: "hidden !important"
  }),
  circle: {
    borderRadius: "50%"
  },
  square: {
    borderRadius: "20%"
  },
  image: props => ({
    width: "100%",
    height: "100%",
    overflow: "hidden"
  })
});

function Signature({ src, width, name }) {
  if (src && typeof src === "string" && src.charAt(0) === "/") {
    src = baseURL + src; // add API baseURL if needed
  }
  const cls = useStyles({ width });

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.container}>
      <Grid container item xs className={`${cls.avatar}`}>
        {src && <img className={`${cls.image}`} src={src} alt={name} title={name} loading="lazy" />}
      </Grid>
    </Grid>
  );
}

export default Signature;
