import React from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { NormalButton, colors } from "bild-ui";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles({
  documentWrapper: { paddingTop: ".5rem" },
  documentItem: { padding: "1rem 0" },
  icon: { padding: "0 1rem 0 0", color: colors.red },
  button: { padding: "0.5rem 1rem" }
});

function UserRecordListItem({ document }) {
  const cls = useStyles();

  return (
    <Grid item xs={12} className={cls.documentWrapper}>
      <Grid container justifyContent="center" alignItems="center" className={cls.documentItem}>
        <Grid item className={cls.icon}>
          <i className="fal fa-file-pdf fa-3x" />
        </Grid>
        <Grid container item xs justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6">{document.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Document Date: {document.dateCreated}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <NormalButton
            variant={"secondary"}
            className={cls.button}
            onClick={() => {
              downloadRequest(document.downloadURL, document.name);
            }}
            borderColor={colors.lightGray}
          >
            Download
          </NormalButton>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
}

export default UserRecordListItem;
