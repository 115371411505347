import React, { useState, useEffect } from "react";
import { managerData } from "bild-data";
import { setPageTitle, hideIntercom } from "bild-utils";
import { LoadingScreen, BaseWrapper } from "bild-ui";
import OrganizationRecentActivity from "./organizationRecentActivity.js";
import { useParams } from "react-router-dom";
import { loadAdminOrganization } from "bild-data/admin/admin.js";

export default function OrganizationRecentActivityLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [orgName, setOrgName] = useState("");

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Organization Activity Dashboard");
      hideIntercom();
      setData(d);
      setLoading(false);
    }

    managerData.getManagedOrganizationRecentActivity(organization_id, _setData, e => {
      setError(e.response.data.error);
    });
    loadAdminOrganization(organization_id, (x)=>{setOrgName(x.organizationName)})
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <OrganizationRecentActivity orgName={orgName} data={data} />
    </BaseWrapper>
  );
}
