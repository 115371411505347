import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { loadUserRegistration, userRegister } from "bild-data/user";
import Register from "./register.js";
import LegacyRegister from "./legacyRegister.js";
import LoadingScreen from "presentational/screens/loadingScreen.js";

// REGISTER LOADER
function RegisterLoader({}) {
  const { reset_digest } = useParams();
  setPageTitle("Register");
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => loadUserRegistration(reset_digest, setState), []); // Empty array == componentDidMount()

  function handleRegister(formData) {
    // format the form data into the data needed for the API
    let data = {
      emailAddress: state.username,
      resetToken: reset_digest,
      newPassword: formData.password,
      newPasswordConfirm: formData.confirm_password
    };
    userRegister(reset_digest, data, setState);
  }

  if (state.isLoading || state.error) {
    return <LoadingScreen error={state.error} />;
  } else if (reset_digest.length === 25 /*CANVAS Legacy Catch*/) {
    return <LegacyRegister username={state.username} />;
  } else {
    return <Register handleSubmit={handleRegister} username={state.username} />;
  }
}

export default RegisterLoader;
