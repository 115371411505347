import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  labelWrapper: props => ({ color: props.color ? props.color : colors.bildBlue }),
  fieldWrapper: { padding: "0.25rem 0" },
  errorWrapper: { color: colors.red, "& fieldset": { borderColor: colors.red } },
  required: { color: colors.red, fontSize: "0.3rem" }
});

function FormField({ label, error, required, child, color }) {
  const cls = useStyles({ color });

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={`${cls.labelWrapper} ${error ? cls.errorWrapper : ""}`}>
        {label}
        {required && (
          <sup className={cls.required}>
            <i className="fas fa-asterisk" />
          </sup>
        )}
      </Grid>
      <Grid item xs={12} className={`${cls.fieldWrapper} ${error ? cls.errorWrapper : ""}`}>
        {child}
      </Grid>
    </Grid>
  );
}

export default FormField;
