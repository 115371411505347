import { getRequest } from "bild-data/core/comm.js";

//// LEARNER
export function loadLearner(success_cb, error_cb) {
  return getRequest(`/paths/users`, success_cb, null, error_cb);
}

export function loadLearnerByUserId(user_id, success_cb, error_cb) {
  return getRequest(`/paths/users/${user_id}`, success_cb, null, error_cb);
}

//// USER PATH
export function load(user_path_id, success_cb, error_cb) {
  return getRequest(`/paths/user_paths/${user_path_id}`, success_cb, null, error_cb);
}
