import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { enrollmentData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen, EquipWrapper } from "bild-ui";
import EnrollmentStatement from "./enrollmentStatement.js";

// ENROLLMENT STATEMENT REPORT LOADER
function EnrollmentStatementLoader({}) {
  const { enrollment_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrollmentNumber, setEnrollmentNumber] = useState(null);
  const [statement, setStatement] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(()=>{
    setPageTitle("Enrollment Statement");

    function _setEnrollmentStatement(d) {
      setEnrollmentNumber(d.enrollmentNumber);
      setStatement(d.enrollmentStatement);
      setUser(d.user);
      setLoading(false);
    }

    enrollmentData.loadStatement(enrollment_id, _setEnrollmentStatement, setError);
  },[]);

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return <EquipWrapper><EnrollmentStatement enrollmentNumber={enrollmentNumber} statement={statement} user={user} /></EquipWrapper>;
  }
}

export default EnrollmentStatementLoader;
