import React, { useState, useEffect } from "react";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from "@mui/material";
import { BTypography, CountrySelect, FormAutocompleteField, FormField, FormTextField, LoadingScreen, NormalButton, colors } from "bild-ui";

export default function NetworkPartnerDialog ({ open, onClose, partner, allUsers, allCivilizations, updatePartner }) {
  const [form, setForm] = useState({ "isActive": true });

  useEffect(()=>{
    if (partner) {
      setForm({
        "name": partner.name,
        "civilizationId": partner.parentCivilizationPartner ? partner.parentCivilizationPartner.id : null,
        "country": partner.country,
        "leaderUserId": partner.keyLeaderUser.id,
        "coachUserId": partner.primaryCoachUser.id,
        "leaderNote": partner.leaderNote,
        "generalNote": partner.generalNote,
        "isActive": partner.isActive,
      });
    }
  },[partner]);

  function _isReady() {
    if (partner) {
      if (form && (
        partner.name === form.name
        && partner.parentCivilizationPartner && partner.parentCivilizationPartner.id === form.civilizationId
        && partner.country === form.country
        && partner.keyLeaderUser.id === form.leaderUserId
        && partner.primaryCoachUser.id === form.coachUserId
        && partner.leaderNote === form.leaderNote
        && partner.generalNote === form.generalNote
        && partner.isActive === form.isActive
      )) {
        return false;
      }
    }
    
    if (form && (form.name && form.name.length > 0) && (form.leaderUserId && form.leaderUserId > 0) && (form.coachUserId && form.coachUserId > 0)) {
      return true;
    }
    
    return false;
  }

  function _updatePartner() {
    if (form && form.name && form.leaderUserId && form.coachUserId) {
      updatePartner({
        "name": form.name,
        "parentCivilizationPartner": { "id": form.civilizationId },
        "country": form.country,
        "keyLeaderUser": { "id": form.leaderUserId },
        "primaryCoachUser": { "id": form.coachUserId },
        "leaderNote": form.leaderNote,
        "generalNote": form.generalNote,
        "isActive": form.isActive === true ? true : false
      });
      setForm({});
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <BTypography variant="h5">{partner ? "Update " : "Create " } Network Partner</BTypography>
      </DialogTitle>
      <DialogContent>
        {!allUsers && (
          <LoadingScreen />
        )}
        {allUsers && (
          <Grid container>
            <Grid item xs={12}>
              <FormTextField
                label="Partner Name"
                value={form.name || ""}
                setValue={(x)=>{setForm({...form, "name": x})}}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocompleteField
                label="Civilization"
                value={form.civilizationId}
                setValue={x => {
                  setForm({...form, "civilizationId": x});
                }}
                items={allCivilizations}
              />
            </Grid>
            <Grid item xs={12}>
            <FormField
              label="Country"
              child={
                <CountrySelect
                  value={form.country}
                  setValue={x => {
                    setForm({...form, "country": x});
                  }}
                />
              }
            />
            </Grid>
            <Grid item xs={12}>
              <FormAutocompleteField
                label="Key Leader"
                value={form.leaderUserId}
                setValue={x => {
                  setForm({...form, "leaderUserId": x});
                }}
                items={allUsers}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocompleteField
                label="Primary Coach"
                value={form.coachUserId}
                setValue={x => {
                  setForm({...form, "coachUserId": x});
                }}
                items={allUsers}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Leader Note"
                value={form.leaderNote || ""}
                setValue={(x)=>{setForm({...form, "leaderNote": x})}}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="General Note"
                value={form.generalNote || ""}
                setValue={(x)=>{setForm({...form, "generalNote": x})}}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.isActive}
                    onClick={()=>{setForm({...form, "isActive": !form.isActive})}}
                  />
                }
                label={`Active`}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <NormalButton
          variant="primary"
          color={colors.bildBlue}
          hoverColor={colors.darkBildBlue}
          labelColor={colors.white}
          disabled={!_isReady()}
          onClick={_updatePartner}
        >
          {partner ? "Update" : "Create" }
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
}