const constants = {
  hotkeys: {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underlined",
    "mod+`": "code"
  },
  marks: ["bold", "italic", "underline", "strikethrough", "fonts", "link"],
  blocks: ["numbered-list", "bulleted-list", "left", "center", "right", "justify"],
  fonts: [
    { name: "Proxima Nova", fontFamily: "proxima-nova, sans-serif" },
    { name: "Arial", fontFamily: "Arial, sans-serif" },
    { name: "Heebo", fontFamily: "Heebo, sans-serif" },
    { name: "Times New Roman", fontFamily: "Times New Roman, serif" },
    { name: "Times", fontFamily: "Times, serif" },
    { name: "Courier New", fontFamily: "Courier New, serif" },
    { name: "Courier", fontFamily: "Courier, serif" },
    { name: "Palatino", fontFamily: "Palatino, serif" }
  ],
  fontSizes: [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
  initialValue: [
    {
      type: "paragraph",
      children: [{ text: "" }]
    }
  ],
  LIST_TYPES: ["numbered-list", "bulleted-list"],
  TEXT_ALIGN_TYPES: ["left", "center", "right", "justify"],
  ELEMENT_TAGS: {
    A: el => ({ type: "link", url: el.getAttribute("href") }),
    BLOCKQUOTE: () => ({ type: "quote" }),
    H1: () => ({ type: "heading-one" }),
    H2: () => ({ type: "heading-two" }),
    H3: () => ({ type: "heading-three" }),
    H4: () => ({ type: "heading-four" }),
    H5: () => ({ type: "heading-five" }),
    H6: () => ({ type: "heading-six" }),
    IMG: el => ({ type: "image", url: el.getAttribute("src") }),
    LI: () => ({ type: "list-item" }),
    OL: () => ({ type: "numbered-list" }),
    P: () => ({ type: "paragraph" }),
    PRE: () => ({ type: "code" }),
    UL: () => ({ type: "bulleted-list" })
  },
  // COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
  TEXT_TAGS: {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true })
  }
};

export default constants;
