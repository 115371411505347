import React, { useState, useEffect, useMemo } from "react";
import {
  colors,
  BTypography,
  NormalButton,
  Expander,
  FormTextField,
  UpdateMembersChecklists,
  FormAutocompleteField,
  PopTip,
  FormErrors,
  TabButtons
} from "bild-ui";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, DialogContent, Grid, TextField, Checkbox } from "@mui/material";

const useStyles = makeStyles({
  containerWrapper: { paddingBottom: "2rem", overflowX: "hidden" },
  form: { padding: "0.5rem" },
  name: { width: "100%" },
  rowItem: { paddingTop: "1rem" },
  tabs: { paddingTop: "1rem" },
  button: { border: `1px solid ${colors.black}` },
  active: { backgroundColor: colors.gray },
  tip: { paddingLeft: "0.5rem" }
});

function CreateUpdateGroupDialog({ open, closeDialog, group, allUsers, allPaths, createUpdateGroup }) {
  const theme = useTheme();
  const cls = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isUpdateGroup = group && group.id;
  const [tab, setTab] = useState(0);
  const [name, setName] = useState(group ? group.name : "");
  const [paths, setPaths] = useState(group ? group.paths : []);
  const [selectedPaths, setSelectedPaths] = useState(group ? group.paths.map(x => x.id) : []);
  const [members, setMembers] = useState(group ? group.users : []);
  const [selectedUsers, setSelectedUsers] = useState(group ? group.users.map(x => x.id) : []);
  const [assignPrograms, setAssignPrograms] = useState(false);
  const [createGroup, setCreateGroup] = useState(isUpdateGroup ? false : true);
  const [shepherd, setShepherd] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setTab(0);
    setName(group ? group.name : "");
    setPaths(group ? group.paths : []);
    setSelectedPaths(group ? group.paths.map(x => x.id) : []);
    setMembers(group ? group.users : []);
    setSelectedUsers(group ? group.users.map(x => x.id) : []);
    setAssignPrograms(false);
    setCreateGroup(isUpdateGroup ? false : true);
    setShepherd(null);
    setErrors([]);
  }, [open]);

  // Memoize tabs to greatly increase performance
  function memoTab0() {
    return (
      <UpdateMembersChecklists
        title="People"
        allMembers={allUsers}
        members={members}
        selectedMembers={selectedUsers}
        updateMember={changeUser}
        showChipList={!isUpdateGroup}
      />
    );
  }
  function memoTab1() {
    return (
      <UpdateMembersChecklists
        title="Programs"
        allMembers={allPaths}
        members={paths}
        selectedMembers={selectedPaths}
        updateMember={changePath}
        icon={"fas fa-badge-check"}
        showChipList={!isUpdateGroup}
      />
    );
  }
  const tab0 = useMemo(() => memoTab0(), [allUsers, members, selectedUsers]);
  const tab1 = useMemo(() => memoTab1(), [allPaths, paths, selectedPaths]);

  function _validate() {
    let valid = true;
    let newErrors = [];

    // New groups must have at least 1 user and program
    if (createGroup && !name && name.length < 1) {
      newErrors["name"] = "Group Name not set.";
    }
    // Assigned Programs need a shepherd
    if (assignPrograms && !shepherd) {
      newErrors["shepherd"] = "Group Shepherd not set.";
    }
    // Shepherd cannot also be assigned a program under themselves
    if (assignPrograms && shepherd && (members.filter(x => x.id === shepherd).length > 0 || selectedUsers.filter(x => x === shepherd).length > 0)) {
      newErrors["shepherd"] = "Users cannot be their own Shepherd.";
    }

    if (Object.keys(newErrors).length > 0) {
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  }

  function _createUpdateGroup() {
    if (_validate()) {
      let groupId = group ? group.id : null; // Include id for update and null for create
      let updateGroup = group ? true : createGroup;
      let new_paths = selectedPaths.filter(sp => !paths.map(p => p.id).includes(sp));
      let remove_paths = paths.filter(p => !selectedPaths.map(sp => sp).includes(p.id)).map(x => x.id);
      let new_users = selectedUsers.filter(su => !members.map(m => m.id).includes(su));
      let remove_users = members.filter(m => !selectedUsers.map(su => su).includes(m.id)).map(x => x.id);
      createUpdateGroup(groupId, name, new_paths, remove_paths, new_users, remove_users, assignPrograms, updateGroup, shepherd);
      closeDialog();
    }
  }

  function changeUser(userId) {
    let index = selectedUsers.indexOf(userId);
    if (index === -1) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter(u => u !== userId));
    }
  }

  function changePath(pathId) {
    let index = selectedPaths.indexOf(pathId);
    if (index === -1) {
      setSelectedPaths([...selectedPaths, pathId]);
    } else {
      setSelectedPaths(selectedPaths.filter(p => p !== pathId));
    }
  }

  if (open) {
    return (
      <Dialog open={open} onClose={closeDialog} maxWidth={"md"} fullScreen={fullScreen}>
        <DialogContent className={cls.containerWrapper}>
          <Grid container justifyContent="center" alignItems="center" className={cls.form}>
            {fullScreen && (
              <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <NormalButton onClick={closeDialog} variant="simple">
                    <i className="fas fa-times" />
                  </NormalButton>
                </Grid>
              </Grid>
            )}
            {!isUpdateGroup && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Grid container item xs={6} justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={assignPrograms}
                      onClick={() => {
                        setAssignPrograms(!assignPrograms);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      setAssignPrograms(!assignPrograms);
                    }}
                  >
                    <BTypography variant="subtitle2">Assign Programs</BTypography>
                  </Grid>
                  <Grid item className={cls.tip}>
                    <PopTip text={"All selected People will be assigned to selected Programs."}>
                      <i className="fal fa-question-circle" />
                    </PopTip>
                  </Grid>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={createGroup}
                      onClick={() => {
                        setCreateGroup(!createGroup);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      setCreateGroup(!createGroup);
                    }}
                  >
                    <BTypography variant="subtitle2">Create Sharing Group</BTypography>
                  </Grid>
                  <Grid item className={cls.tip}>
                    <PopTip
                      text={
                        "A sharing group is a collection of People and Programs that will be able to interact with each other. Please note this does not assign any programs unless you also select the 'Assign Programs' checkbox"
                      }
                    >
                      <i className="fal fa-question-circle" />
                    </PopTip>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <FormErrors errors={errors} />
            <Expander showFirst={true} expandIn={isUpdateGroup || createGroup}>
              <FormTextField error={errors["name"]} label="Group Name" value={name} setValue={setName} required={true} />
            </Expander>
            <Expander showFirst={true} expandIn={assignPrograms}>
              <FormAutocompleteField
                error={errors["shepherd"]}
                label="Shepherd"
                value={shepherd}
                setValue={setShepherd}
                items={allUsers}
                required={true}
                showAvatar={true}
              />
            </Expander>
            <Grid container item xs={12} className={cls.tabs}>
              <TabButtons tab1Text="People" tab2text="Programs" value={tab} setValue={setTab} color={colors.bildBlue} />
            </Grid>
            <Grid container item xs={12}>
              {tab === 0 && tab0}
              {tab === 1 && tab1}
            </Grid>
            <Grid container item xs={12} className={cls.rowItem}>
              <Grid item xs={12}>
                <NormalButton
                  fullWidth
                  onClick={_createUpdateGroup}
                  variant="simple"
                  color={colors.bildBlue}
                  labelColor={colors.white}
                >
                  {isUpdateGroup ? "Update " : "Create "} Group
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default CreateUpdateGroupDialog;
