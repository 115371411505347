import React from "react";
import { makeStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import { BTypography, Blink, NormalButton, colors } from "bild-ui";

import ContentWrapper from "presentational/wrappers/contentWrapper.js";
import CourseWrapper from "presentational/wrappers/courseWrapper.js";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "../enrollment/enrollment.css";

const useStyles = makeStyles({
  header: { padding: "0.5rem 0", position: "sticky", top: "0", background: colors.white, zIndex: "10" },
  title: { paddingBottom: "0.5rem" },
  enrollmentWrapper: { padding: "1rem", background: "#fff", minHeight: "100%", width: "100%" },
  courseGroup: { width: "100%" },
  totals: { padding: "0.5rem 0 1rem 0" },
  course: { height: "100%", width: "100%" },
  courseContent: { height: "100%" }
});

function UserAdminCourse({ user, courseGroups, totals, filterCourses, filter }) {
  const cls = useStyles();

  function renderCourses(courses) {
    const components = [];
    for (var i = 0; i < courses.length; i++) {
      let c = courses[i];
      let groupColor = c.courseGroupColor.webFrontendValue;
      let header = (
        <Grid container justifyContent="space-between">
          <Grid item>
            <i className={`${c.complete ? "fas" : "far"} fa-circle`} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{c.courseNumber}</Typography>
          </Grid>
          <Grid item>
            <i className={`fas ${c.familyGroupIcon.webFrontendValue}`} />
          </Grid>
        </Grid>
      );
      let competencies = [];
      for (var j = 0; j < c.competencies.length; j++) {
        let cmp = c.competencies[j];
        competencies.push(<i className={`${cmp.competencyProof.complete ? "fas" : "far"} fa-circle`} style={{ color: groupColor }} />);
      }

      components.push(
        <Grid item lg={2} md={4} sm={6} xs={12} key={i}>
          <Grid
            container
            className={cls.course}
            component={Blink}
            dst={"course"}
            course_id={c.id}
            competency_proof_id={c.competencies[0].competencyProof.id}
            key={i}
          >
            <CourseWrapper variant="dashboard" color={groupColor} header={header} className={cls.course}>
              <Grid container direction="column" justifyContent="space-between" className={cls.courseContent}>
                <Grid item>
                  <Typography variant="body1">{c.shortName == null ? ReactHtmlParser(c.name) : ReactHtmlParser(c.shortName)}</Typography>
                </Grid>
                <Grid item>{competencies}</Grid>
              </Grid>
            </CourseWrapper>
          </Grid>
        </Grid>
      );
    }
    return components;
  }

  const totalComponents = [];
  Object.keys(totals).forEach(function(item) {
    totalComponents.push(
      <Grid container item xs key={item} justifyContent="center" alignItems="center">
        <Grid item>
          <NormalButton
            onClick={() => {
              filterCourses(totals[item].filter);
            }}
            variant="primary"
            color={filter === totals[item].filter ? colors.bildBlue : colors.white}
            labelColor={filter === totals[item].filter ? colors.white : colors.bildBlue}
            hoverColor={colors.darkBildBlue}
            borderColor={colors.bildBlue}
          >
            {totals[item].value} {item}
          </NormalButton>
        </Grid>
      </Grid>
    );
  });

  const components = [];
  for (let i = 0; i < courseGroups.length; i++) {
    const group = courseGroups[i];
    let courses = [];
    let groupColor = group.courses[0].courseGroupColor.webFrontendValue;

    courses = renderCourses(group.courses);

    components.push(
      <ContentWrapper variant="flat" key={i} className={cls.courseGroup}>
        <Typography variant="h5" gutterBottom style={{ color: groupColor }}>
          {group.name}
        </Typography>
        <Grid container spacing={2}>
          {courses}
        </Grid>
      </ContentWrapper>
    );
  }

  return (
    <div className={cls.enrollmentWrapper}>
      <Grid container item xs={12} className="enrollment">
        <Grid container item xs={12} className={cls.header}>
          <Grid item xs={12} className={cls.title}>
            <BTypography variant="h3">{user && user.name}'s Courses</BTypography>
          </Grid>
          <Grid container item xs={12} className={cls.totals}>
            {totalComponents}
          </Grid>
        </Grid>
        {components}
      </Grid>
    </div>
  );
}

export default UserAdminCourse;
