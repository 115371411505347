import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Blink, BTypography, colors, NormalButton, SortableList } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({});

export default function StaffSearchOrganizations({ orgs, count }) {
  const cls = useStyles();
  const [items, setItems] = useState([]);

  useEffect(()=>{
    if (orgs) {
      setItems(orgs.map(x => [
        [<BTypography variant="body1" sortval={x.organizationName} key={x.organizationName}>
          {x.organizationName}
        </BTypography>],
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              component={Blink}
              dst="manage-organization"
              organization_id={x.organizationId}
              variant="micro"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              className={cls.detailButton}
            >
              <i className="fas fa-chevron-right" />
            </NormalButton>
          </Grid>
        </Grid>
      ]));
    }
  },[orgs]);

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
      <SortableList
        headers={["Name", ""]}
        items={items}
        itemPadding={"0.5rem 1rem"}
        itemType={"Organizations"}
        title={`Organizations (${count})`}
        fixedHeight={"55vh"}
      />
    </Grid>
  );
}
