import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  in: { transition: "opacity 0.5s ease", opacity: "1" },
  out: { transition: "opacity 0.5s ease", opacity: "0" }
});

function Fader({ fadeIn, children }) {
  const cls = useStyles();
  const [fade, setFade] = useState(null);

  useEffect(() => {
    fadeIn ? setFade(cls.in) : setFade(cls.out);
  }, [fadeIn]);

  return (
    <Grid container item xs={12} className={fade}>
      {children}
    </Grid>
  );
}

export default Fader;
