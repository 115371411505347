import React from "react";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";

const useStyles = makeStyles({
  closeButton: {
    color: "#3f51b5"
  }
});

export default function FullInstructionsDialog({ open, instructions, onClose }) {
  const cls = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth scroll="paper">
      <DialogTitle>
        <i className="fal fa-ruler" /> Instructions
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {ReactHtmlParser(instructions)}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} className={cls.closeButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
