import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, FormAutocompleteField, FormTextField, NormalButton } from "bild-ui";
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid } from "@mui/material";

const useStyles = makeStyles({
  title: props => ({
    color: colors.white,
    backgroundColor: props.lic === 1 ? colors.level1Group : props.lic === 2 ? colors.level2Group : props.lic === 3 ? colors.level3Group : colors.darkGray,
  }),
  icon: {
    color: colors.white,
    fontWeight: "bold",
    padding: "0 0.5rem 0 0"
  },
  body: { padding: "1rem 0 0 0" },
  errors: { backgroundColor: colors.lightRed, marginBottom: "0.5rem", padding: "0.5rem" },
  error: { color: colors.red },
  errorBullet: { fontSize: ".3rem", padding: "0 0.5rem 0.5rem 0" },
  submitWrapper: { padding: "1rem 0" },
  submit: { padding: "1rem 2rem" },
  destroyTitle: props => ({
    color: colors.white,
    backgroundColor: colors.darkRed,
  }),
  destroyRow: { textAlign: "center", padding: "0.25rem 1rem"},
  checkboxLabel: { color: colors.darkRed },
  parentTip: { color: colors.darkGray, fontStyle: "italic" }
});

export default function OrgGroupEditDialog({ open, onClose, onSubmit, orgGroups, defaultOrgGroup }) {
  const lic = defaultOrgGroup ? defaultOrgGroup.levelId : 0;
  const cls = useStyles({ lic });
  const [form, setForm] = useState({
    errors: [],
    id: null,
    name: "",
    levelId: null,
    parentId: null
  });
  const [types, setTypes] = useState([]);
  const [parents, setParents] = useState([]);
  const [destroyDialog, setDestroyDialog] = useState(false);
  const [understandDelete, setUnderstandDelete] = useState(false);

  useEffect(()=>{
    if (defaultOrgGroup) {
      setForm({
        ...form,
        "id": defaultOrgGroup.id ? defaultOrgGroup.id : null,
        "name": defaultOrgGroup.name ? defaultOrgGroup.name : "",
        "levelId": defaultOrgGroup.levelId ? defaultOrgGroup.levelId : null,
        "parentId": defaultOrgGroup.parentOrganizationGroupId ? defaultOrgGroup.parentOrganizationGroupId : null
      });
    }
  },[defaultOrgGroup]);

  useEffect(()=>{
    function getChildren(node) {
      let tempParents = [{"id": node.id, "name": node.name, "levelId": node.levelId, parentId: node.parentOrganizationGroupId}];
      if (node.children) {
        for (let i=0; i < node.children.length; i++) {
          tempParents.push(getChildren(node.children[i]));
        }
      }
      return tempParents.flat();
    }

    if (orgGroups) {
      // If not updating an existing group, show all levels
      let tempTypes = [{"id": 3, "name": orgGroups.level3Name}, {"id": 2, "name": orgGroups.level2Name}, {"id": 1, "name": orgGroups.level1Name}];
      if (orgGroups.children) {
        let tempParents = [];
        for (let i=0; i < orgGroups.children.length; i++) {
          tempParents.push(getChildren(orgGroups.children[i]));
        }
        // Only include those that are higher than level 1 (Church) and are not the current id
        tempParents = tempParents.flat().filter(x => x.levelId > 1);
        if (defaultOrgGroup && defaultOrgGroup.id) {
          // if defaultOrgGroup is present, also limit by groups higher than the current LevelId and not the parentOrgGroup
          tempParents = tempParents.filter(x => x.levelId > defaultOrgGroup.levelId || x.id == defaultOrgGroup.parentOrganizationGroupId);

          // If this group has any parents or children, disable types that should not be available at this level
          if (defaultOrgGroup.parentOrganizationGroupId || defaultOrgGroup.children) {
            // Get levelIds for current orgGroup and immediate children
            let tempLevelIds = defaultOrgGroup.children.map(x => x.levelId);
            tempLevelIds.push(defaultOrgGroup.levelId);
            // disable the lowest level option if any children exist
            if (defaultOrgGroup.children.length > 0) {
              tempLevelIds.push(1);
            }
            // disable this orgGroup's parent's level
            if (defaultOrgGroup.parentOrganizationGroupId) {
              // find parent level
              let parent = tempParents.filter(x => x.id === defaultOrgGroup.parentOrganizationGroupId)[0];
              if (parent) {
                tempLevelIds.push(parent.levelId);
                // Do the same for grandparent's level
                if (parent.parentId) {
                  let grandparent = tempParents.filter(x => x.id === parent.parentId)[0];
                  if (grandparent) {
                    tempLevelIds.push(grandparent.levelId);
                  }
                }
              }
            }
            // Flatten to only unique values
            tempLevelIds = [...new Set(tempLevelIds.map(x => Number(x)))];
            // For each level, disable it in the type list
            for (let i=0; i < tempLevelIds.length; i++) {
              let index = tempTypes.map(x => Number(x.id)).indexOf(tempLevelIds[i]);
              if (index >= 0) {
                tempTypes[index] = {...tempTypes[index], disabled: true};
              }
            }
          }
        }
        setParents(tempParents);
      }
      setTypes(tempTypes);
    }
  },[orgGroups, defaultOrgGroup]);

  function _onSubmit() {
    // First check everything is in order, and then createUser
    let newError = [];

    // Check form for mistakes
    if (form.name === "" || form.name === null) {
      newError.push({ pos: 0, val: "Name needs to be filled in." });
    }
    if (form.levelId === "" || form.levelId === null) {
      newError.push({ pos: 1, val: "A Type needs to be selected." });
    }
    if (form.parentId && (form.levelId >= parents.filter(x => x.id === form.parentId)[0].levelId)) {
      newError.push({ pos: 2, val: "The parent must be at a higher type." });
    }

    // Check if the basics are valid
    if (newError.length <= 0) {
      // Create/Update the user
      onSubmit("update", form);
      _onClose();
    } else {
      setForm({ ...form, errors: newError });
    }
  }

  function _onDestroy() {
    onSubmit("delete", form);
    _onClose();
  }

  function _closeDestroy() {
    setDestroyDialog(false);
    setUnderstandDelete(false);
  }

  function _onClose() {
    setForm({
      errors: [],
      id: null,
      name: "",
      levelId: null,
      parentId: null
    });
    setDestroyDialog(false);
    setUnderstandDelete(false);
    onClose();
  }

  return (
    <>
      <Dialog open={open} onClose={_onClose} fullWidth maxWidth="sm">
        <DialogTitle className={cls.title}>
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
              <Grid item className={cls.icon}>
                {form.id ? <i className={lic === 3 ? "fal fa-chart-network" : lic === 2 ? "far fa-project-diagram" : lic === 1 ? "far fa-home-alt" : ""} /> : ""}
              </Grid>
              <Grid item>
                  <BTypography variant="h5">
                    {form.id ? "Edit " : "Add a new Node"}
                    {form.id ? <i>{form.name}</i> : ""}
                  </BTypography>
              </Grid>
            </Grid>
            <Grid item>
              <NormalButton
                onClick={_onClose}
                variant={"micro"}
                color={colors.transparent}
                labelColor={colors.white}
                hoverColor={colors.darkGray}
                hoverLabelColor={colors.white}
              >
                <i className="far fa-times fa-2x" />
              </NormalButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12} className={cls.body}>
            {form.errors.length > 0 && (
              <Grid item xs={12} className={cls.errors}>
                {form.errors.map((x, i) => (
                  <Grid container item xs={12} key={i} className={cls.error} justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <i className={`${cls.errorBullet} fas fa-circle`} />
                    </Grid>
                    <Grid item>{x.val}</Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormTextField
                label="Name"
                value={form.name}
                setValue={x => {
                  setForm({ ...form, name: x });
                }}
                error={form.errors.some(x => x.pos === 0)}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocompleteField
                label="Type"
                items={types ? types : []}
                value={form.levelId}
                setValue={x => {
                  setForm({ ...form, levelId: x });
                }}
                error={form.errors.some(x => x.pos === 1)}
                required={true}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <FormAutocompleteField
                  label="Parent"
                  items={parents ? parents : []}
                  value={form.parentId}
                  setValue={x => {
                    setForm({ ...form, parentId: x });
                  }}
                  error={form.errors.some(x => x.pos === 2)}
                />
              </Grid>
              <Grid item xs={12}>
                <BTypography variant="body1" className={cls.parentTip}>Parent must be a higher type or empty</BTypography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.submitWrapper} justifyContent={"space-between"} alignItems={"center"}>
              <Grid item>
                {form.id && (
                  <NormalButton
                    onClick={()=>{setDestroyDialog(true)}}
                    variant={"secondary"}
                    color={colors.transparent}
                    borderColor={colors.red}
                    labelColor={colors.red}
                    hoverLabelColor={colors.darkRed}
                    className={cls.submit}
                  >
                    Delete
                  </NormalButton>
                )}
              </Grid>
              <Grid item>
                <NormalButton
                  onClick={_onSubmit}
                  variant={"primary"}
                  color={colors.bildBlue}
                  labelColor={colors.white}
                  className={cls.submit}
                >
                  {form.id ? "Update" : "Create"}
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={destroyDialog} onClose={_closeDestroy}>
        <DialogTitle className={cls.destroyTitle}>
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
              <BTypography variant="h5">
                Delete <i>{defaultOrgGroup ? defaultOrgGroup.name : ""}</i>
              </BTypography>
            </Grid>
            <Grid item>
              <NormalButton
                onClick={_closeDestroy}
                variant={"micro"}
                color={colors.transparent}
                labelColor={colors.white}
                hoverColor={colors.darkGray}
                hoverLabelColor={colors.white}
              >
                <i className="far fa-times fa-2x" />
              </NormalButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12} className={cls.submitWrapper} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} className={cls.destroyRow}>
              <BTypography variant="body1">
                People and groups directly associated with this group will remain but need to be reassigned.
              </BTypography>
            </Grid>
            <Grid item xs={12} className={cls.destroyRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={understandDelete}
                    onClick={()=>{setUnderstandDelete(!understandDelete)}}
                    disableRipple={true}
                    color="error"
                  />
                }
                label="I understand and want to continue."
                className={cls.checkboxLabel}
              />
            </Grid>
            <Grid item xs={12} className={cls.destroyRow}>
              <NormalButton
                onClick={_onDestroy}
                variant={"primary"}
                color={colors.darkRed}
                labelColor={colors.white}
                hoverColor={colors.darkerRed}
                className={cls.submit}
                disabled={!understandDelete}
              >
                Permanently Delete
              </NormalButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
