import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import Competency from "./competency.js";

import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: { padding: "1rem" },
  course: { background: `${colors.white}` }
});

function CompetencyProof({ course, competency, callingUser, docId, setDocId, fileLoading }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.course}>
        <Competency
          course={course}
          competency={competency}
          callingUser={callingUser}
          docId={docId}
          setDocId={setDocId}
          fileLoading={fileLoading}
        />
      </Grid>
    </Grid>
  );
}

export default CompetencyProof;
