import React, { useState } from "react";
import { colors, BTypography, Expander, NormalButton, Blink } from "bild-ui";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { UserPathThumbnail } from "equip";

const useStyles = makeStyles({
  sectionPaths: { maxHeight: "30rem", overflowY: "auto" },
  sectionTitle: {
    padding: "1rem",
    color: colors.bildGray,
  },
  pathCount: { fontSize: "1rem" },
  spacer: { padding: "1rem" },
  noPaths: { padding: "1rem", fontStyle: "italic" },
  learnerButton: { padding: "1rem" }
});

export default function UserPathThumbnails({ paths, allExpanded, dashboardView }) {
  const cls = useStyles();
  const [activeExpand, setActiveExpand] = useState(true);
  const [inactiveExpand, setInactiveExpand] = useState(allExpanded);
  const [completeExpand, setCompleteExpand] = useState(allExpanded);

  if (paths && paths.length > 0) {
    let active = [];
    let complete = [];
    let inactive = [];
    for (let i=0; i<paths.length; i++) {
      let p = paths[i];
      let path = <UserPathThumbnail path={p} color={p.programColor.webFrontendValue} key={p.id}/>;
      if (p.active && !p.completed) {
        active.push(path);
      } else if (p.completed) {
        complete.push(path);
      } else {
        inactive.push(path);
      }
    }

    return (
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          {!dashboardView && (
            <Grid container item xs={12} alignItems="center" justifyContent="flex-start" className={cls.sectionTitle}>
              <Grid item>
                <BTypography variant="h5">Active &nbsp;</BTypography>
              </Grid>
              <Grid item className={cls.pathCount}>
                <BTypography variant="b1">({active.length})</BTypography>
              </Grid>
              <Grid item>
                <NormalButton
                  variant="simple"
                  labelColor={colors.black}
                  onClick={()=>{setActiveExpand(!activeExpand)}}
                >
                  <i className={`fas fa-chevron-${activeExpand ? "up" : "down" }`}/>
                </NormalButton>
              </Grid>
            </Grid>
          )}
          <Expander showFirst={true} expandIn={activeExpand}>
            <Grid container item xs={12} className={cls.sectionPaths}>
              {active}
            </Grid>
          </Expander>
        </Grid>
        {dashboardView && (
          <Grid container item xs={12} justifyContent={"flex-end"} alignItems={"center"} className={cls.learnerButton}>
            <Grid item>
              <NormalButton
                component={Blink}
                dst={"equip-learner"}
                variant="secondary"
                color={colors.white}
                labelColor={colors.bildGray}
                borderColor={colors.bildGray}
                hoverLabelColor={colors.bildGray}
              >
                View All Programs
              </NormalButton>
            </Grid>
          </Grid>
        )}
        {!dashboardView && (
          <>
            <Grid item xs={12} className={cls.spacer}>
              <hr/>
            </Grid>
            {complete.length > 0 && (
              <Grid container item xs={12}>
                <Grid container item xs={12} alignItems="center" justifyContent="flex-start" className={cls.sectionTitle}>
                  <Grid item>
                    <BTypography variant="h5">Completed &nbsp;</BTypography>
                  </Grid>
                  <Grid item className={cls.pathCount}>
                    <BTypography variant="b1">({complete.length}) &nbsp;</BTypography>
                  </Grid>
                  <Grid item>
                    <NormalButton
                      variant="simple"
                      labelColor={colors.black}
                      onClick={()=>{setCompleteExpand(!completeExpand)}}
                    >
                      <i className={`fas fa-chevron-${completeExpand ? "up" : "down" }`}/>
                    </NormalButton>
                  </Grid>
                </Grid>
                <Expander showFirst={allExpanded} expandIn={completeExpand}>
                  <Grid container item xs={12} className={cls.sectionPaths}>
                    {complete}
                  </Grid>
                </Expander>
              </Grid>
            )}
            {inactive.length > 0 && (
              <Grid container item xs={12}>
                <Grid container item xs={12} alignItems="center" justifyContent="flex-start" className={cls.sectionTitle}>
                  <Grid item>
                    <BTypography variant="h5">Inactive &nbsp;</BTypography>
                  </Grid>
                  <Grid item className={cls.pathCount}>
                    <BTypography variant="b1">({inactive.length})</BTypography>
                  </Grid>
                  <Grid item>
                    <NormalButton
                      variant="simple"
                      labelColor={colors.black}
                      onClick={()=>{setInactiveExpand(!inactiveExpand)}}
                    >
                      <i className={`fas fa-chevron-${inactiveExpand ? "up" : "down" }`}/>
                    </NormalButton>
                  </Grid>
                </Grid>
                <Expander showFirst={allExpanded} expandIn={inactiveExpand}>
                  <Grid container item xs={12} className={cls.sectionPaths}>
                    {inactive}
                  </Grid>
                </Expander>
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid container item xs={12} className={cls.noPaths}>
        <BTypography variant="h6">No paths currently assigned</BTypography>
      </Grid>
    );
  }
}