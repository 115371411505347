import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography, NormalButton, Blink, Chip, Expander, PopTip } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: {
    border: `2px solid ${colors.white}`,
    borderRadius: "0.5rem"
  },
  dataSmall: {
    textAlign: "center"
  },
  nameWrapper: props => ({
    background: props.color,
    color: colors.white,
    fontWeight: "bold",
    padding: "0.75rem 0 0.5rem 1rem",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem"
  }),
  name: {
    color: colors.white,
    fontWeight: "bold",
  },
  subName: { padding: "1rem 0 0.25rem 0" },
  icon: {
    color: colors.white,
    fontWeight: "bold",
    padding: "0 0.5rem 0 0"
  },
  buttons: {
    padding: "0 0.5rem"
  },
  button: {
    padding: "0 0 0 0.5rem"
  },
  body: props => ({
    border: `2px solid ${props.color}`,
    padding: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem"
  }),
  usersList: { maxHeight: "5rem", overflow: "scroll" },
  spacer: { padding: "0.5rem 0.5rem" },
  bodyReport: { padding: "0rem 0.5rem 0.5rem 0.5rem" },
  bodyUsers: { maxHeight: "20rem", overflow: "auto" },
  detailButton: { fontSize: "1rem" }
});

function OrgGroupDetails({ data, levelNames, setOrgGroup, setOrgGroupUsers }) {
  const color = data.color ? data.color : colors.gray;
  const cls = useStyles({ color });
  const isParent = data.children && data.children.length > 0;
  const [stats, setStats] = useState(false);

  function getChildren(node) {
    const hasChildren = node.children && node.children.length > 0;
    const nameVariant = node.levelId === 3 ? "h4" : (node.levelId === 2 ? "h5" : "subtitle1");

    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <BTypography variant={nameVariant} className={cls.subName}>
            {node.name}
          </BTypography>
        </Grid>
        <Grid container item className={cls.usersList}>
          {node.users.map((y,j) => {
            return (
              <Grid item key={j}>
                <Blink dst="manage-user" user_id={y.id}>
                  <Chip member={y} clickable/>
                </Blink>
              </Grid>
            );
          })}
        </Grid>
        {isParent && node.children && (
          <Grid container item xs={12}>
            {node.children.map((x, j) => 
              <Grid container key={j}>
                {getChildren(x)}
              </Grid>
            )}
          </Grid>
        )}
        {(!hasChildren && (!node.users || node.users.length < 1)) && (
          <BTypography variant="h6">No users currently.</BTypography>
        )}
      </Grid>
    );
  }

  return (
    <Grid container className={cls.container}>
      <Grid container item xs={12} className={cls.nameWrapper} justifyContent="space-between" alignItems="center">
        <Grid container item xs className={cls.name}>
          <Grid item className={cls.icon}>
            <PopTip text={data.levelId === 3 ? levelNames.level3Name : data.levelId === 2 ? levelNames.level2Name : data.levelId === 1 ? levelNames.level1Name : ""}>
              <i className={data.levelId === 3 ? "fal fa-chart-network" : data.levelId === 2 ? "far fa-project-diagram" : data.levelId === 1 ? "far fa-home-alt" : ""} />
            </PopTip>
          </Grid>
          <Grid item>
            <BTypography variant="h6">{data.name}</BTypography>
          </Grid>
        </Grid>
        <Grid container xs item className={cls.buttons} justifyContent={"flex-end"}>
          <Grid item className={cls.button}>
            <NormalButton
              onClick={()=>{setOrgGroupUsers(data)}}
              variant={"secondary"}
              color={colors.transparent}
              borderColor={colors.white}
              labelColor={colors.white}
              hoverColor={colors.white}
              hoverLabelColor={colors.darkGray}
            >
              <i className={`${cls.detailButton} fas fa-users`} />
            </NormalButton>
          </Grid>
          <Grid item className={cls.button}>
            <NormalButton
              onClick={()=>{setOrgGroup(data)}}
              variant={"secondary"}
              color={colors.transparent}
              borderColor={colors.white}
              labelColor={colors.white}
              hoverColor={colors.white}
              hoverLabelColor={colors.darkGray}
            >
              <i className={`${cls.detailButton} fas fa-cog`} />
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <Expander showFirst={false} expandIn={stats}>
          <Grid container item xs={12} className={cls.bodyReport}>
            <Grid item xs={isParent ? 8 : 10}></Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                Local
              </Grid>
            )}
            <Grid item xs={2} className={cls.dataSmall}>
              Total
            </Grid>
            <Grid item xs={isParent ? 8 : 10}>
              Users
            </Grid>
            <Grid item xs={2} className={cls.dataSmall}>
              {data.eqpUsersWithAnyProgram}
            </Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                {data.subEqpUsersWithAnyProgram}
              </Grid>
            )}
            <Grid item xs={isParent ? 8 : 10}>
              Users w/ Progress
            </Grid>
            <Grid item xs={2} className={cls.dataSmall}>
              {data.eqpUsersWithAnyProgress}
            </Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                {data.subEqpUsersWithAnyProgress}
              </Grid>
            )}
            <Grid item xs={isParent ? 8 : 10}>
              Users w/ 50% Progress
            </Grid>
            <Grid item xs={2} className={cls.dataSmall}>
              {data.eqpUsersWith50PercentProgress}
            </Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                {data.subEqpUsersWith50PercentProgress}
              </Grid>
            )}
            <Grid item xs={isParent ? 8 : 10}>
              Users w/ (Lead/Entrust)
            </Grid>
            <Grid item xs={2} className={cls.dataSmall}>
              {data.eqpUsersWithLeadOrEntrustProgram}
            </Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                {data.subEqpUsersWithLeadOrEntrustProgram}
              </Grid>
            )}
            <Grid item xs={isParent ? 8 : 10}>
              Users w/ Certificate
            </Grid>
            <Grid item xs={2} className={cls.dataSmall}>
              {data.eqpUsersWithAnyCertificate}
            </Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                {data.subEqpUsersWithAnyCertificate}
              </Grid>
            )}
            <Grid item xs={isParent ? 8 : 10}>
              Shepherd Assessments Waiting
            </Grid>
            <Grid item xs={2} className={cls.dataSmall}>
              {data.eqpShepherdAssessmentsWaiting}
            </Grid>
            {isParent && (
              <Grid item xs={2} className={cls.dataSmall}>
                {data.subEqpShepherdAssessmentsWaiting}
              </Grid>
            )}
          </Grid>
        </Expander>
        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              variant={"secondary"}
              color={colors.transparent}
              labelColor={colors.darkerGray}
              hoverLabelColor={colors.black}
              onClick={()=>{setStats(!stats)}}
            >
              Stats &nbsp; <i className={`fas fa-chevron-${stats ? "up" : "down"}`} />
            </NormalButton>
          </Grid>
        </Grid>
        <Grid item xs={12} className={cls.spacer}>
          <hr/>
        </Grid>
        <Grid container item xs={12} className={cls.bodyUsers}>
          {data.users.map((y,j) => { return (
            <Grid item key={j}>
              <Blink dst="manage-user" user_id={y.id}>
                <Chip member={y} clickable/>
              </Blink>
            </Grid>
          )})}
          <Grid container item xs={12}>
            {isParent && data.children && data.children.map((x, j) => 
              <Grid container key={j}>
                {getChildren(x)}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrgGroupDetails;
