import React, { useState, useEffect } from "react";
import { Dialog, Grid, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { BTypography, NormalButton, colors } from "bild-ui";

export default function NetworkPartnerOrganizationRemoveDialog({ open, onClose, org, updatePartner }) {

  function _removeOrg() {
    updatePartner({
      "organizationsToRemove": [{ "organizationId": org.id }]
    });
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <BTypography variant="h5">Confirm Removal</BTypography>
      </DialogTitle>
      <DialogContent>
        <BTypography variant="subtitle1" style={{ textAlign: "center" }}>
          <br/>Remove <b>{org.name}</b> from <b>{org.partnerName}</b>
        </BTypography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <Grid item>
            <NormalButton
              variant="secondary"
              color={colors.white}
              labelColor={colors.gray}
              borderColor={colors.gray}
              hoverColor={colors.darkGray}
              onClick={onClose}
            >
              Cancel
            </NormalButton>
          </Grid>
          <Grid item style={{ padding: "1rem" }}>
            <NormalButton
              variant="primary"
              color={colors.darkRed}
              labelColor={colors.white}
              hoverColor={colors.darkerRed}
              onClick={_removeOrg}
            >
              Confirm Removal
            </NormalButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

}