import React from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";

import { Grid, Typography, Tooltip } from "@mui/material";

import { colors, NormalButton } from "bild-ui";

const useStyles = makeStyles({
  header: {
    "& *": { fontWeight: "bold" },
    borderBottom: "1px solid darkgrey"
  },
  row: {
    "&:nth-child(odd)": {
      background: "#e8ebec"
    }
  },
  cell: {
    "& > *:last": {
      paddingBottom: 0
    },
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifContent: "center"
  },
  files: {
    textAlign: "left"
  },
  link: {
    color: colors.black,
    "&:hover": { textDecoration: "underline" }
  },
  fileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "35vw"
  }
});

function AssessmentHistoryRow({ createdAt = "Date", summaryMessage = "Action", files, user, isHeader, viewDocument, viewPreview }) {
  const cls = useStyles();

  const name = (user || {}).name || "User";

  let fileItems = isHeader
    ? "Documents Attached"
    : (files || []).map((f, i) => (
          <Grid item xs key={i}>
            <NormalButton
              onClick={() => {
                if (f.inAppLocalId) {
                  viewDocument(f.inAppLocalId);
                } else {
                  f.isFile = true;
                  viewPreview(f);
                }
              }}
              className={cls.link}
            >
              <Tooltip title={f.name} placement="left" >
                <span className={cls.fileName}>
                  {f.name}
                </span>
              </Tooltip>
            </NormalButton>
          </Grid>
      ));

  return (
    <Grid container className={isHeader ? cls.header : cls.row}>
      <Grid item xs={2} className={cls.cell} component={Typography}>
        {createdAt}
      </Grid>
      <Grid item xs={3} className={cls.cell} component={Typography}>
        {summaryMessage}
      </Grid>
      <Grid item xs={2} className={cls.cell} component={Typography}>
        {name}
      </Grid>
      <Grid container item xs={5} className={`${cls.cell} ${cls.files}`} component={Typography}>
        {fileItems}
      </Grid>
    </Grid>
  );
}

export default AssessmentHistoryRow;
