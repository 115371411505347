import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  pageError: {
    marginTop: "50px",
    textAlign: "center",
    bildIconCloud: {
      width: "200px"
    }
  }
});

function PageMissingScreen({ error }) {
  const cls = useStyles();
  return (
    <Grid container className={cls.pageError} justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <svg
          className="bild-question-cloud"
          style={{ maxWidth: "300px" }}
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          preserveAspectRatio="xMidYMid meet"
        >
          <g transform="translate(0.8,9) scale(0.01,-0.01)" fill="#fff" stroke="none">
            <path
              className="bild-cloud-logo"
              stroke="#fff"
              d="M290 469 c-59 -24 -100 -85 -100 -149 0 -26 -4 -40 -13 -40 -7 0 -25 -13 -40 -28 -73 -77 -42 -193 59 -221 51 -14 407 -14 458 0 75 21 120 100 96 165 -13 35 -58 84 -77 84 -8 0 -13 12 -13 31 0 41 -15 66 -50 84 -33 17 -73 19 -99 5 -14 -7 -22 -3 -39 19 -38 52 -120 75 -182 50z m120 -24 c15 -8 39 -29 53 -46 21 -27 27 -30 45 -20 58 31 132 -5 132 -65 0 -29 6 -38 35 -56 39 -22 65 -63 65 -100 0 -36 -37 -88 -75 -104 -49 -20 -431 -20 -480 0 -89 38 -92 170 -5 206 27 11 30 17 30 57 0 24 7 57 15 74 32 61 121 87 185 54z"
            />

            <circle className="face" cx="425" cy="210" r="150" />
          </g>
          <g transform="translate(-4.85,2.5) scale(0.0365,0.0365)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#014f98"
              d="M271.346,79.721c-22.935-0.015-41.855,18.864-41.865,41.774 c-0.009,23.403,18.722,42.228,42.013,42.225c23.185-0.003,41.988-18.82,41.985-42.013C313.477,98.474,294.673,79.737,271.346,79.721 z M272.383,149.558c-3.299,0.027-5.461-2.08-5.474-5.332c-0.014-3.298,2.089-5.447,5.347-5.464c3.22-0.017,5.461,2.198,5.462,5.396 C277.719,147.317,275.53,149.533,272.383,149.558z M285.293,116.914c-1.209,2.626-3.042,4.78-4.971,6.863 c-1.687,1.822-2.979,3.816-3.573,6.273c-0.584,2.42-3.066,3.882-5.458,3.37c-2.205-0.472-3.502-2.64-3.185-5.167 c0.463-3.685,2.492-6.495,4.892-9.143c2.326-2.567,3.984-5.44,3.5-9.089c-0.124-0.936-0.336-1.906-0.739-2.749 c-1.062-2.216-3.772-2.551-5.337-0.646c-0.645,0.785-1.099,1.762-1.484,2.714c-0.667,1.65-1.924,2.258-3.578,2.284 c-1.199,0.019-2.399,0.026-3.598-0.001c-2.296-0.052-3.059-1.019-2.647-3.311c1.273-7.108,6.19-11.073,15.502-11.072 c1.893,0.015,5.314,0.775,8.059,3.398C286.663,104.45,287.757,111.562,285.293,116.914z"
            />
          </g>
        </svg>
        <Typography variant="h5">Page not found.</Typography>
        <br />
        <Typography variant="body1">The page you are looking for does not exist.</Typography>
      </Grid>
    </Grid>
  );
}

export default PageMissingScreen;
