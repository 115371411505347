import React from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip } from "@mui/material";
import { colors, Avatar, BTypography, NormalButton, List, Blink, Glower, LineChartBuilder, BarChartBuilder, AreaChartBuilder } from "bild-ui";

const useStyles = makeStyles({
  container: {},
  header: {
    position: "sticky",
    top: "0",
    zIndex: "100",
    background: colors.white,
    textAlign: "center",
    paddingBottom: "1rem"
  },
  dataRan: { color: colors.gray },
  autoRefreshDisable: { color: colors.red },
  avatarName: { paddingLeft: "0.25rem", fontSize: "1rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
  mainVal: { fontWeight: "bold", fontSize: "1rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
  subVal: { fontStyle: "italic", fontSize: "0.75rem" },
  module: {
    minHeight: "10rem",
    marginBottom: "1rem",
    padding: "0.5rem",
    borderRadius: "0.25rem"
  },
  moduleTitle: { textAlign: "center", paddingTop: "0.5rem" },
  title: { fontWeight: "bold" },
  noData: { fontStyle: "italic" },
  totalPrograms: { textAlign: "center" },
  chartCol: {
    background: colors.lightGray,
    padding: "0.25rem"
  },
  chartText: {
    writingMode: "vertical-rl",
    textOrientation: "mixed"
  },
  scrollList: {
    maxHeight: "10rem",
    overflow: "hidden",
    overflowY: "scroll"
  },
  scrollMainVal: { fontSize: "0.8rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
  scrollSub: { textAlign: "center" },
  scrollSubTopVal: { borderBottom: `1px solid ${colors.lightGray}`, lineHeight: "0.8rem" },
  scrollSubBottomVal: { color: colors.gray, lineHeight: "0.8rem" }
});

export default function OrganizationRecentActivity({ orgName, data }) {
  const cls = useStyles();

  function userWithAvatar(user) {
    return (
      <Grid
        component={Blink}
        dst={"manage-user"}
        user_id={user.id}
        container
        item
        xs={12}
        justifyContent="flex-start"
        alignItems="center"
        className={cls.userVal}
      >
        <Grid item>
          <Avatar size={25} src={user.avatarURL} name={user.fullName} srcSize="small" />
        </Grid>
        <Grid item xs className={cls.avatarName}>
          {user.fullName}
        </Grid>
      </Grid>
    );
  }

  function stackedCol(user, val1, val2) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          {userWithAvatar(user)}
        </Grid>
        <Grid item xs={6} className={cls.subVal}>
          {val2}
        </Grid>
        <Grid item xs={12} className={cls.mainVal}>
          {val1}
        </Grid>
      </Grid>
    );
  }

  function moduleTitle(title) {
    return (
      <Grid item xs={12} className={cls.moduleTitle}>
        <BTypography variant="h6" className={cls.title}>
          {title}
        </BTypography>
      </Grid>
    );
  }

  function getRecentUsers() {
    if (data.onlineUsers) {
      let rows = data.onlineUsers.map(x => [stackedCol(x, null, x.lastLoginDate)]);
      rows = rows.concat([[moduleTitle("Last 3 Created Users")]]);
      rows = rows.concat(data.recentlyCreatedUsers.map(x => [stackedCol(x, null, x.createdAt)]));
  
      return <List items={rows} itemPadding={"0.5rem"} />;
    }
  }

  function getUserTotals() {
    let d = data.userTotals;
    if (d) {
      let rows = [
        ["Users:", d.totalUserCount.toLocaleString()],
        ["Active (1 day):", d.onlineUsersLastDay.toLocaleString()],
        ["Active (1 week):", d.onlineUsersLastWeek.toLocaleString()]
      ];
  
      let d2 = data.userHistoryTotals;
      const tod = new Date();
      let month = tod.getMonth();
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"];
  
      let chartData = [];
      for (let i = 5; i > -1; i--) {
        let mon = months[month - i < 1 ? month - i + 12 : month - i];
        let per = i === 0 ? "totalUserCount" : "previousPeriod" + i + "UserCount";
        chartData.push({ name: mon, uv: d2[per], color: colors.bildBlue, uvName: "Users" });
      }
  
      return (
        <>
          <AreaChartBuilder data={chartData} fillColor={colors.lightBlue} xAxis="name" />
          <div className={cls.scrollList}>
            <List items={rows} itemPadding={"0.5rem"} />
          </div>
        </>
      );
    }
  }

  function getProgramTotals() {
    let totalAssigned = 0;
    let totalCompleted = 0;
    for (let i = 0; i < data.pathTotals.length; i++) {
      let pt = data.pathTotals[i];
      totalAssigned += pt.totalAssigned;
      totalCompleted += pt.totalCompleted;
    }
    let rows = data.pathTotals
      .sort((a, b) => b.totalAssigned - a.totalAssigned)
      .sort((a, b) => b.totalCompleted - a.totalCompleted)
      .map(x => [
        <Tooltip title={x.pathName}>
          <div className={cls.scrollMainVal}>{x.pathName}</div>
        </Tooltip>,
        <Tooltip title={`${x.totalCompleted.toLocaleString()} Completed / ${x.totalAssigned.toLocaleString()} Assigned`}>
          <Grid container className={cls.scrollSub} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={cls.scrollSubTopVal}>
              {x.totalCompleted.toLocaleString()}
            </Grid>
            <Grid item xs={12} className={cls.scrollSubBottomVal}>
              {x.totalAssigned.toLocaleString()}
            </Grid>
          </Grid>
        </Tooltip>
      ]);

    const chartData = data.pathTotals
      .sort((a, b) => b.totalAssigned - a.totalAssigned)
      .sort((a, b) => b.totalCompleted - a.totalCompleted)
      .slice(0, 10)
      .map(d => {
        return {
          name: d.pathName,
          uv: d.totalCompleted,
          pv: d.totalAssigned - d.totalCompleted,
          uvName: "Completed",
          pvName: "Assigned",
          color: d.programColor ? d.programColor.webFrontendValue : colors.avatarColors[d.pathName.length % 10 || 0].backgroundColor
        };
      });

    return (
      <>
        <BarChartBuilder data={chartData} fillColor={colors.bildBlue} />
        <div className={cls.scrollList}>
          <Tooltip title={`${totalCompleted.toLocaleString()} Completed / ${totalAssigned.toLocaleString()} Assigned`}>
            <Grid item className={cls.totalPrograms}>
              <b>
                {totalCompleted.toLocaleString()} / {totalAssigned.toLocaleString()}
              </b>
            </Grid>
          </Tooltip>
          <List items={rows} itemPadding={"0.5rem"} spacing={[10, 2]} />
        </div>
      </>
    );
  }

  function getPrograms() {
    let rows = data.completedUserPaths.map(x => [
      stackedCol(
        x.user,
        <span style={{ color: `${x.programColor ? x.programColor.webFrontendValue : colors.black}` }}>{x.title}</span>,
        x.completedAt
      ),
      <Grid container>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item>
            <NormalButton
              onClick={() => {
                downloadRequest(x.certificates[0].downloadURL, x.certificates[0].name);
              }}
              variant="micro"
            >
              <i className="fas fa-download" />
            </NormalButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item>
            <NormalButton component={Blink} dst={"equip-user-path"} user_path_id={x.id} variant="micro">
              <i className="fas fa-arrow-right" />
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    ]);

    return <List items={rows} spacing={[10, 2]} itemPadding={"0.5rem"} />;
  }

  function getProgramItems() {
    let rows = data.completedUserPathItems.map(x => [
      stackedCol(x.userLearningItem.user, x.userLearningItem.name, x.completedAt),
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <NormalButton component={Blink} dst={"equip-user-path-item"} user_path_item_id={x.id} variant="micro">
            <i className="fas fa-arrow-right" />
          </NormalButton>
        </Grid>
      </Grid>
    ]);

    return <List items={rows} spacing={[10, 2]} itemPadding={"0.5rem"} />;
  }

  function getRecentPrograms() {
    let rows = data.recentlyAssignedUserPaths.map(x => [
      stackedCol(x.user, <span style={{ color: `${x.programColor ? x.programColor.webFrontendValue : colors.black}` }}>{x.title}</span>, x.createdAt),
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <NormalButton component={Blink} dst={"equip-user-path"} user_path_id={x.id} variant="micro">
            <i className="fas fa-arrow-right" />
          </NormalButton>
        </Grid>
      </Grid>
    ]);

    return <List items={rows} spacing={[10, 2]} itemPadding={"0.5rem"} />;
  }

  function makeModule(dataField, title, content) {
    let hasData = false;
    if (data[dataField] && (data[dataField].length > 0 || Object.keys(data[dataField]).length > 0)) {
      hasData = true;
    }

    return (
      <Grid container item lg={4} md={4} sm={6} xs={12} className={cls.module}>
        {moduleTitle(title)}
        {hasData ? content() : 
        (
          <Grid container item xs={12} justifyContent="center" alignItems="flex-start">
            <Grid item>
              <BTypography variant="body1" className={cls.noData}>No data currently available.</BTypography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container className={cls.container}>
      <Grid container item xs={12} justifyContent="flex-start" alignItems="stretch" direction="row" className={cls.body}>
        <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.header}>
          <Grid item xs={3} className={cls.dataRan}>
            {data.dateRan}
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <BTypography variant="h5">{orgName}</BTypography>
            </Grid>
            <Grid item xs={12}>
              <BTypography variant="h6">Recent Activity Dashboard</BTypography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
        </Grid>
        {makeModule("onlineUsers", "Last 3 Online Users", getRecentUsers)}
        {makeModule("userTotals", "Users", getUserTotals)}
        {makeModule("pathTotals", "Program Completion", getProgramTotals)}
        {makeModule("recentlyAssignedUserPaths", "Last 3 Assigned Programs", getRecentPrograms)}
        {makeModule("completedUserPaths", "Last 3 Completed Programs", getPrograms)}
        {makeModule("completedUserPathItems", "Last 3 Completed Program Items", getProgramItems)}
      </Grid>
    </Grid>
  );
}
