import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Bbutton from "presentational/input/bbutton.js";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  container: { textAlign: "center" }
});

function TrainingHistoryStepper({ currentAssessment, otherAssessments }) {
  const classes = useStyles();
  let assessments = [];
  const [lastId, setLastId] = useState(null);
  const [nextId, setNextId] = useState(null);

  useEffect(() => {
    let assessments = [currentAssessment].concat(otherAssessments).sort((a, b) => {
      return a.id - b.id;
    });

    for (let i = 0; i < assessments.length; i++) {
      let a = assessments[i];
      if (a.id === currentAssessment.id) {
        if (assessments[i - 1]) {
          setLastId(assessments[i - 1].id);
        }

        if (assessments[i + 1]) {
          setNextId(assessments[i + 1].id);
        }
      }
    }
  }, [currentAssessment, otherAssessments]);

  if (assessments) {
    return (
      <Grid container className={classes.container} justifyContent="center" alignItems="center">
        <Grid item xs={5}>
          <Bbutton variant={lastId ? "slim" : ""} dst={"training_assessment"} training_assessment_id={lastId} disabled={!lastId}>
            <Typography variant="h5">
              <i className="fa far fa-chevron-left" />
            </Typography>
          </Bbutton>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">ID: {currentAssessment.id}</Typography>
          <Typography variant="h5">{currentAssessment.createdAt}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Bbutton variant={nextId ? "slim" : ""} dst={"training_assessment"} training_assessment_id={nextId} disabled={!nextId}>
            <Typography variant="h5">
              <i className="fa far fa-chevron-right" />
            </Typography>
          </Bbutton>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

export default TrainingHistoryStepper;
