import React from "react";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { colors, Blink } from "bild-ui";
import { Grid, Typography } from "@mui/material";
import AssessmentStatus from "presentational/assessmentStatus/assessmentStatus.js";

const useStyles = makeStyles({
  competencyLink: {
    color: colors.black,
    "&:hover": {
      textDecoration: "none"
    }
  }
});

// TRANSCRIPT REVIEW COMPETENCY
function TranscriptCompetency({ enrollmentId, courseId, competency }) {
  const cls = useStyles();

  return (
    <Grid
      container
      component={Blink}
      dst={"course"}
      course_id={courseId}
      enrollment_id={enrollmentId}
      competency_proof_id={competency.competencyProof ? competency.competencyProof.id : -1}
      className={cls.competencyLink}
      id={competency.id}
    >
      <Grid item container xs={12} sm={7} alignItems="center">
        <Typography variant="body1">
          {competency.description ? ReactHtmlParser(competency.description) : ReactHtmlParser(competency.name)}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={5} alignItems="center">
        <AssessmentStatus icons={competency.assessmentFlowIcons} />
      </Grid>
    </Grid>
  );
}

export default TranscriptCompetency;
