import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { BTypography, Chip, NormalButton, SortableList, colors } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({});

export default function StaffSearchGroups({ groups, count }) {
  const cls = useStyles();
  const [items, setItems] = useState([]);

  useEffect(()=>{
    if (groups) {
      setItems(groups.map(x => [
        [
          <Grid container sortval={x.name} key={0} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={4}>
              <BTypography variant="subtitle2">{x.name}</BTypography>
            </Grid>
            <Grid container item sm={7} xs={5} justifyContent={"flex-start"} alignItems={"center"} className={cls.userChips}>
              {x.users.map((y,j) => { return (
                <Grid item key={j}>
                  <Chip member={y} hideName size={25}/>
                </Grid>
              ); })}
            </Grid>
            <Grid container item sm={1} xs={3} justifyContent={"flex-end"} alignItems={"center"}>
              <Grid item>
                <NormalButton
                  variant={"micro"}
                  dst={"equip-review-shared-paths"}
                  group_id={x.id}
                  color={colors.bildBlue}
                  labelColor={colors.white}
                >
                  <i className="fas fa-chevron-right" />
                </NormalButton>
              </Grid>
            </Grid>
          </Grid>
        ]
      ]));
    }
  },[groups]);

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
      <SortableList
        headers={["Name"]}
        items={items}
        itemPadding={"0.5rem 1rem"}
        itemType={"Groups"}
        title={`Your Groups (${count})`}
        fixedHeight={"55vh"}
      />
    </Grid>
  );
}
