import React, { useState, useCallback } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { colors, Avatar } from "bild-ui";

import { Editable, Slate } from "slate-react";

import { Grid, Typography, Divider, Popper, IconButton, Icon } from "@mui/material";

import { Leaf, Element, useCommentEditor } from ".";
import { timeElapsed } from "bild-utils";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    container: { width: "100%", backgroundColor: colors.white, borderRadius: "4px" },
    editableContainer: { padding: "0.75rem" },
    editable: { minHeight: "100%" },
    avatarItem: { padding: "0 0.5rem 0.5rem 0" },
    timeStampItem: { textAlign: "right" },
    div: { margin: "0.25rem -0.75rem", backgroundColor: "transparent" },
    popperContent: {
      display: "inline-block",
      color: "white",
      backgroundColor: colors.black,
      padding: "0.25rem 0.5rem",
      border: "1px solid lightgrey",
      borderRadius: "4px"
    },
    infoContainer: { padding: "0.5rem 0.75rem 0.5rem 0" },
    deleteButton: {
      height: "30px",
      width: "30px",
      marginLeft: "0.5rem",
      "& *": { fontSize: "16px" }
    },
    commentWrapper: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "3rem"
      }
    },
    messageWrapper: { backgroundColor: colors.veryLightGray, borderRadius: "4px" }
  })
);

function Comment({ value, name, createdAt, id, onDeleteIntent }) {
  const classes = useStyles();

  const editor = useCommentEditor();

  const elapsed = timeElapsed(createdAt, new Date().getTime());
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [popperOpen, setPopperOpen] = useState(false);
  const localeTimeString = new Date(createdAt).toLocaleString();
  function _mouseIn(e) {
    setAnchorEl(e.currentTarget);
    setPopperOpen(true);
  }
  function _mouseOut(e) {
    setAnchorEl(undefined);
    setPopperOpen(false);
  }

  function _deleteIntent() {
    if (typeof onDeleteIntent === "function") onDeleteIntent(id);
  }

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  return (
    <Slate editor={editor} value={value} selection={null}>
      <Grid container className={classes.container} direction="column" wrap="nowrap">
        <Grid item container alignItems="center" className={classes.infoContainer}>
          <Grid item className={classes.avatarItem}>
            <Avatar name={name} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{name}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start" alignItems="center" className={classes.commentWrapper}>
          <Grid item md={10} sm={11} xs={12} className={`${classes.editableContainer} ${classes.messageWrapper}`}>
            <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly className={classes.editable} />
          </Grid>
          <Grid item xs={1}>
            <IconButton className={classes.deleteButton} onClick={_deleteIntent} size="large">
              <Icon className="fal fa-times fa-xs" />
            </IconButton>
          </Grid>
          <Grid item md={10} sm={11} xs={11} className={classes.timeStampItem}>
            <Typography variant="body2" aria-describedby={id} align="right" onMouseEnter={_mouseIn} onMouseOut={_mouseOut} display="inline">
              {elapsed}
            </Typography>
            <Popper id={id} open={popperOpen} anchorEl={anchorEl} placement="top">
              <span style={{ padding: "0.5rem", display: "inline-block" }}>
                <span className={classes.popperContent}>{localeTimeString}</span>
              </span>
            </Popper>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.div} />
    </Slate>
  );
}

export default Comment;
