import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "@mui/material";

import { colors } from "bild-ui";

const useStyles = makeStyles({
  typo: { padding: "0.25rem 0rem 0.25rem 3rem", textTransform: "capitalize", textAlign: "left" },
  iconClass: { width: "25px", textAlign: "center" },
  link: { cursor: "pointer", "&:hover *": { opacity: 0.87 }, "&:hover span": { textDecoration: "underline" } }
});

function AssessmentInfoLink({ icon, name, url, onClick, statusIndicator, groupColor }) {
  const classes = useStyles();

  const v = statusIndicator ? "subtitle1" : undefined;
  const c = `${classes.typo} ${url || onClick ? classes.link : ""}`;
  const a = url ? "a" : undefined;

  return (
    <Typography
      variant={v}
      className={c}
      display="block"
      onClick={onClick}
      component={a}
      href={url}
      target={"_blank"}
      style={{ color: statusIndicator ? groupColor : colors.bildGray }}
    >
      <i className={`fal ${icon} fa-fw ${classes.iconClass}`} />
      <span>{name}</span>
    </Typography>
  );
}

export default AssessmentInfoLink;
