import React from "react";
import { Blink } from "bild-ui";
import { Grid, Typography, Divider, Badge, Button } from "@mui/material";
import CompetencyProof from "./components/competencyProof.js";

import "./review.css";

// REVIEW USER
function ReviewUser({ totalReviewCount, competencies, reviewType, orgId, organization, user }) {
  const organizationId = orgId ? orgId : organization.organizationId;

  return (
    <Grid container className="review-wrapper">
      <Grid item xs className="review">
        <Grid item md={12} xs={12} component={Blink} dst={"review"} review_type={reviewType} organization_id={organizationId}>
          <Button className="back-link">
            <i className="fal fa-chevron-left" />
            &nbsp; Back to Organization
          </Button>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant="h5" className="page-headline" gutterBottom>
            {user.name}
            <Badge color={totalReviewCount > 0 ? "secondary" : "primary"} className="header-badge" badgeContent={totalReviewCount} overlap="rectangular">
              &nbsp;
            </Badge>
          </Typography>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>
          <CompetencyProof userId={user.id} competencies={competencies} reviewType={reviewType} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReviewUser;
