import { getRequest, putRequest } from "../core/comm.js";

//// ORGANIZATIONS
export function load(success_cb, error_cb) {
  getRequest(`/organizations`, success_cb, null, error_cb);
}

export function loadOrganization(organization_id, success_cb, error_cb) {
  getRequest(`/organizations/${organization_id}`, success_cb, null, error_cb);
}

export function loadOrganizationGroups(organization_id, success_cb, error_cb) {
  getRequest(`/organizations/${organization_id}/groups`, success_cb, null, error_cb);
}

export function modifyAdmissionsUser(organization_id, data, success_cb, error_cb) {
  putRequest(`/organizations/${organization_id}/admission_user`, data, success_cb, null, error_cb);
}

export function loadCombinedOrganizations(success_cb, error_cb) {
  getRequest(`/organizations/combined`, success_cb, null, error_cb);
}

export function loadAllCombinedOrganizations(success_cb, error_cb) {
  getRequest(`/organizations/all_combined`, success_cb, null, error_cb);
}

export function loadCombinedOrganizationsForUser(user_id, success_cb, error_cb) {
  getRequest(`/organizations/users/${user_id}/combined`, success_cb, null, error_cb);
}
