import React, { useState } from "react";
import { toaster } from "presentational/toasts/toasts.js";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

function ChangeUsernameDialog({ open, user, onClose, onChange, onSubmit, classes }) {
  const [newEmail, setNewEmail] = useState("");

  function _validateEmail() {
    if (
      !newEmail ||
      !newEmail.length > 0 ||
      !newEmail.match(
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g
      )
    ) {
      toaster.error("Invalid Email Address.");
    } else {
      onSubmit(
        user,
        newEmail,
        data => {
          _onClose();
          toaster.success("Email Address Updated!");
        },
        data => {
          _onClose();
          toaster.error("Email Update Failed.");
        }
      );
    }
  }

  function _onClose() {
    setNewEmail("");
    onClose(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent className={classes.dialog}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="h6">Change Username/Email for {user.name}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.fieldWrapper}>
            <FormControl fullWidth>
              <FormLabel>Current Username</FormLabel>
              <OutlinedInput disabled fullWidth value={user.username} />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.fieldWrapper}>
            <FormControl fullWidth>
              <FormLabel>New Username</FormLabel>
              <OutlinedInput
                fullWidth
                autoFocus
                variant="email"
                value={newEmail}
                onChange={e => {
                  setNewEmail(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Button
              className="default-submit-button"
              onClick={() => {
                _validateEmail();
              }}
            >
              Change
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ChangeUsernameDialog;
