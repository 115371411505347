import React, { useState } from "react";
import { checkAdmin, checkBetaTester } from "bild-data/user";
import { BTypography, EquipWrapper, NormalButton, TabButtons, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { EbookstoreLibrary } from "bild-vendors";
import VideoLibrary from "./videoLibrary";

const useStyles = makeStyles({
  headerWrapper: { padding: "0 0 1rem 0" },
  header: { background: colors.white, padding: "0.5rem 1rem" },
  tabs: { padding: "0" }
});

export default function Library({ books, language }) {
  const cls = useStyles();
  const [bookView, setBookView] = useState(true);
  const showTabs = checkAdmin() || checkBetaTester();

  return (
    <EquipWrapper>
      <Grid container>
        <Grid container item xs={12} className={cls.headerWrapper}>
          <Grid container item xs={12} justifyContent="space-between" alignItems="center" className={cls.header}>
            <Grid container item xs>
              <Grid item>
                <BTypography variant="h2">Library</BTypography>
              </Grid>
              <Grid item>
                <span className="beta-badge">BETA</span>
              </Grid>
            </Grid>
            {showTabs && (
              <Grid container item xs className={cls.tabs} justifyContent={"flex-end"} alignItems={"center"}>
                <TabButtons
                  tab1Text={
                    <BTypography>
                      <i className="fad fa-books" /> Books
                    </BTypography>
                  }
                  tab2text={
                    <BTypography>
                      <i className="fab fa-youtube" /> Videos
                    </BTypography>
                  }
                  value={bookView ? 0 : 1}
                  setValue={()=>{setBookView(!bookView)}}
                  color={colors.bildBlue}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {bookView && (
            <EbookstoreLibrary books={books} language={language} />
          )}
          {!bookView && (
            <VideoLibrary />
          )}
        </Grid>
      </Grid>
    </EquipWrapper>
  );
}