import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";

import "./searchBar.css";
const classes = {
  searchBar: "search-bar",
  searchBarInput: {
    root: "search-bar-input",
    focused: "search-bar-input-focused"
  },
  searchBarNativeInput: "search-bar-native-input",
  searchBarIcon: "search-bar-icon",
  searchBarIconClickable: "search-bar-icon-clickable"
};

function SearchBar({ defaultValue, value, placeholder, className, fullWidth, autoFocus, onChange, onSearch }) {
  const [searchValue, setSearchValue] = useState(defaultValue ? defaultValue : "")

  function _onSubmit(e) {
    // 13 => Enter key
    if (e.keyCode === 13) {
      if (onSearch) {
        onSearch(searchValue);
      }
    }
  }

  function _onSearch() {
    if (onSearch) {
      onSearch(searchValue);
    }
  }

  function _onChange(e) {
    let newValue = e.target.value;
    setSearchValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }

  const iconClass = onSearch ? classes.searchBarIconClickable : classes.searchBarIcon;

  return (
    <TextField
      margin="dense"
      variant="outlined"
      onChange={_onChange}
      InputProps={{
        classes: classes.searchBarInput,
        inputProps: { placeholder: placeholder ? placeholder : "Search...", className: "search-bar-native-input" },
        endAdornment: (
          <InputAdornment position="end" onClick={_onSearch}>
            <i className={`fal fa-search ${iconClass}`} />
          </InputAdornment>
        )
      }}
      className={`${className} ${classes.searchBar}`}
      fullWidth={fullWidth}
      onKeyDown={_onSubmit}
      value={value}
      defaultValue={defaultValue}
      autoFocus={autoFocus ? autoFocus : false}
    />
  );
}

export default SearchBar;
