import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography, TextField } from "@mui/material";

const useStyles = makeStyles({
  commentField: {
    "& > div": {
      padding: "0.5rem",
      marginBottom: "0.5rem",
      border: "1px solid darkgrey",
      borderRadius: "5px",
      overflowX: "hidden",
      overflowY: "auto",
      "-webkit-overflow-scrolling": "touch"
    }
  }
});

function CommentField({ groupColor, commentRef }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" style={{ color: groupColor }} gutterBottom>
        Add a Comment
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={3}
        onChange={e => (commentRef.current = e.target.value)}
        className={classes.commentField}
      />
    </>
  );
}

export default CommentField;
