import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { userData, organizationsData } from "bild-data";
import { updateURLWithoutLoad, setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { Grid } from "@mui/material";
import OrganizationTabs from "./organizationTabs.js";
import OrganizationUsers from "./organizationUsers.js";
import OrganizationAdmissions from "./admissions/organizationAdmissions.js";

// ORGANIZATION LOADER
function OrganizationLoader({}) {
  const location = useLocation();
  const { organization_id } = useParams();
  const [userOverview, setUserOverview] = useState(userData.getOverview());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizationId, setOrganizationId] = useState(organization_id);
  const [organizationName, setOrganizationName] = useState("");
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState("");
  const [canViewAdmissions, setCanViewAdmissions] = useState(false);
  const [canCommend, setCanCommend] = useState(false);
  const [users, setUsers] = useState([]);
  const [admissionUsers, setAdmissionUsers] = useState([]);
  const [availablePrograms, setAvailablePrograms] = useState([]);

  useEffect(()=>{
    setPageTitle("Organization");

    function _setOrganization(data) {
      setCanViewAdmissions(data.callingUserCanSeeAdmissions);
      setCanCommend(data.callingUserCanCommendUsers);
      setUsers(data.users);
      setAdmissionUsers(data.admissionUsers);
      setAvailablePrograms(data.availablePrograms);
      setOrganizationName(data.organizationName);
      setLoading(false);
    }

    let t = 0;
    if (location.pathname.includes("admissions")) {
      t = 1;
    }
    setTab(t);
    setOrganizationId(organization_id);

    organizationsData.loadOrganization(organization_id, _setOrganization, setError);
  },[]);

  function updateTab(newTab) {
    if (newTab !== tab) {
      let tabName = newTab === 1 ? "admissions" : "users";
      // TODO don't have this update the url, just the tab anchor or something
      updateURLWithoutLoad("/organizations/" + organizationId + "/" + tabName);

      setTab(newTab);
    }
  }

  function updateUsers(data) {
    setUsers(data.users);
    setAdmissionUsers(data.admissionUsers);
  }

  // depending on the data that is present, display different views
  let view = null;
  if (canViewAdmissions && tab === 1) {
    view = (
      <OrganizationAdmissions
        canCommend={canCommend}
        admissionUsers={admissionUsers}
        availablePrograms={availablePrograms}
        organizationName={organizationName}
        organizationId={organizationId}
        filterValue={filter}
        updateUsers={updateUsers}
      />
    );
  } else {
    view = (
      <OrganizationUsers
        initialUsers={users}
        organizationName={organizationName}
        filterValue={filter}
        updateUsers={updateUsers}
      />
    );
  }

  if (loading || error) {
    return <LoadingScreen error={error} />;
  } else {
    return (
      <Grid container className="organizations-wrapper">
        <Grid item xs className="organizations">
          <Grid container>
            <Grid item xs={12}>
              <OrganizationTabs
                tab={tab}
                canViewAdmissions={canViewAdmissions}
                organizationId={organizationId}
                organizationName={organizationName}
                updateTab={updateTab}
                filterUsers={(e) => { setFilter(e.target.value) }}
                filterValue={filter}
                showReports={userOverview.showOrganizationReports}
              />
            </Grid>
            <Grid item xs={12}>
              {view}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default OrganizationLoader;
