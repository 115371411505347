import React, { useRef, useEffect, useState } from "react";
import { colors, Blink, BTypography, MembersList, NormalButton } from "bild-ui";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Grid, Chip } from "@mui/material";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    containerWrapper: { padding: "0.5rem 1rem 0.5rem 0.5rem" },
    title: { padding: "0.5rem" },
    subtitle: { padding: "0.5rem" },
    membersWrapper: { padding: "0.5rem" },
    members: {
      overflowY: "auto",
      minHeight: "15vh",
      maxHeight: "30vh",
      padding: "0.5rem",
      border: `1px solid ${colors.veryLightBlue}`,
      borderRadius: "4px"
    },
    buttonWrapper: { paddingRight: "0.5rem" }
  })
);

function GroupBlock({ user, group, addCohort, editGroup }) {
  const cls = useStyles();

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" className={cls.containerWrapper}>
      <Grid container item direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid container item xs={12}>
          <Grid container item justifyContent="space-between" alignItems="flex-start">
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item xs className={cls.title}>
                <BTypography variant="h4">{group.name}</BTypography>
              </Grid>
              <Grid item className={cls.buttonWrapper}>
                <NormalButton component={Blink} dst="equip-review-shared-paths" group_id={group.id} variant="simple" color={colors.bildBlue} labelColor={colors.white}>
                  View Shared Programs
                </NormalButton>
              </Grid>
              <Grid item className={cls.buttonWrapper}>
                <NormalButton onClick={addCohort} variant="simple" color={colors.bildBlue} labelColor={colors.white}>
                  Add Cohort to Group
                </NormalButton>
              </Grid>
              <Grid item className={cls.buttonWrapper}>
                <NormalButton onClick={editGroup} variant="simple" color={colors.bildBlue} labelColor={colors.white}>
                  Update Group
                </NormalButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.subtitle}>
              <BTypography variant="h6">Programs:</BTypography>
            </Grid>
            <Grid container item xs={12} className={cls.membersWrapper}>
              <Grid item xs={12} className={cls.members}>
                <MembersList members={group.paths.sort((a, b) => a.name.localeCompare(b.name))} icon={"fas fa-badge-check"} />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.subtitle}>
              <BTypography variant="h6">People:</BTypography>
            </Grid>
            <Grid container item xs={12} className={cls.membersWrapper}>
              <Grid item xs={12} className={cls.members}>
                <MembersList members={group.users.sort((a, b) => a.name.localeCompare(b.name))} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GroupBlock;
