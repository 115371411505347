import { getRequest, putRequest, deleteRequest, downloadRequest, previewRequest, previewNetworkPartnerRequest } from "../core/comm.js";
import { localStorage as ls, driveData } from "..";
import { uploadManager, b64EncodeUnicode, b64DecodeUnicode } from "bild-utils";

export function load(userId, success_cb, error_cb) {
  if (userId) {
    getRequest(`/disks/users/${userId}`, success_cb, null, error_cb);
  } else {
    getRequest(`/disks`, success_cb, null, error_cb);
  }
}

function _modifyItem(isFile, newItem, userId, success_cb, error_cb) {
  let data = { files: [], folders: [] };

  if (isFile) {
    data.files.push({
      id: newItem.id,
      name: newItem.name,
      folderId: newItem.folderId
    });
  } else {
    data.folders.push({
      id: newItem.id,
      name: newItem.name,
      parentFolderId: newItem.parentFolderId
    });
  }

  if (userId) {
    putRequest(`/disks/users/${userId}`, data, success_cb, null, error_cb);
  } else {
    putRequest(`/disks`, data, success_cb, null, error_cb);
  }
}

function _createItem(data, name, networkPartnerId, civilizationId, success_cb, error_cb) {
  uploadManager.add(name);

  // Send the creation request and return a promise
  let createItemPromise = new Promise(function(resolve, reject) {
    if (civilizationId) {
      putRequest(`/network_partners/civilizations/${civilizationId}/disk`, data, resolve, null, reject);
    } else if (networkPartnerId) {
      putRequest(`/network_partners/${networkPartnerId}/disk`, data, resolve, null, reject);
    } else {
      putRequest(`/disks`, data, resolve, null, reject);
    }
  });

  // Creation request succeeded
  createItemPromise.then(data => {
    uploadManager.conveySuccess(name);
    if (typeof success_cb === "function") success_cb(data);
  });

  // Creation request failed
  createItemPromise.catch(() => {
    uploadManager.conveyFail(name);
    if (typeof error_cb === "function") error_cb();
  });
}

function _uploadFile(file, destination, networkPartnerId, civilizationId, success_cb, error_cb) {
  let data = {
    files: [],
    folders: []
  };

  if (file.dropBoxLink || file.googleFileId) {
    // Dropbox and Google just get passed through
    data.files.push(file);
    _createItem(data, file.name, networkPartnerId, civilizationId, success_cb, error_cb);
  } else {
    // If the device is uploading directly, first read native file object
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // Wait for the file object to be read
    reader.onload = () => {
      // The result needs to be cleaned before it can be sent (removes the initial "data:*/*;base64,")
      // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
      let result = reader.result.split(",")[1];

      data.files.push({
        name: file.name,
        folderId: destination.id,
        contentType: file.type,
        fileSizeBytes: file.size,
        base64EncodedFile: result
      });

      _createItem(data, file.name, networkPartnerId, civilizationId, success_cb, error_cb);
    };
  }
}

export function uploadFiles(files, destination, success_cb, error_cb) {
  files = [...files];
  for (let i = 0; i < files.length; i++) {
    const f = files[i];
    _uploadFile(f, destination, null, null, success_cb, error_cb);
  }
}

export function uploadNetworkPartnerFiles(files, destination, networkPartnerId, success_cb, error_cb) {
  files = [...files];
  for (let i = 0; i < files.length; i++) {
    const f = files[i];
    _uploadFile(f, destination, networkPartnerId, null, success_cb, error_cb);
  }
}

export function uploadCivilizationPartnerFiles(files, destination, civilizationId, success_cb, error_cb) {
  files = [...files];
  for (let i = 0; i < files.length; i++) {
    const f = files[i];
    _uploadFile(f, destination, null, civilizationId, success_cb, error_cb);
  }
}

export function renameItem(item, newName, userId, success_cb, error_cb) {
  let newItem = { id: item.id, name: newName };
  _modifyItem(item.isFile, newItem, userId, success_cb, error_cb);
}

export function createFolder(contextRoot, newFolderName, userId, success_cb, error_cb) {
  let newItem = { name: newFolderName, parentFolderId: contextRoot.id };
  _modifyItem(false, newItem, userId, success_cb, error_cb);
}

export function previewItemURL(fileId, success_cb) {
  // Get Authenticated URL for previewing the file
  previewRequest(fileId, success_cb);
}

export function downloadItem(item) {
  if (item.isFile || item.fileURL) {
    const fileUrl = item.raw ? item.raw.fileURL : item.fileURL;
    // Quick way to check if the user is on an iOS device and needs special treatment
    let iOS = /^(iPhone|iPad|iPod)/.test(window.navigator.platform);
    let msie = /MSIE|Trident/.test(window.navigator.userAgent);
    if (iOS || msie) {
      window.location.href = process.env.REACT_APP_BC2_API_URL + fileUrl + "?auth_token=" + ls.getAPIToken();
    } else {
      downloadRequest(fileUrl, item.name);
    }
  }
}

export function deleteItem(item, success_cb, error_cb) {
  let data = { files: [], folders: [] };

  if (item.isFile) {
    data.files.push({ id: item.id });
  } else {
    data.folders.push({ id: item.id });
  }

  deleteRequest(`/disks`, data, success_cb, null, error_cb);
}

export function moveItem(item, destination, userId, success_cb, error_cb) {
  let newItem = { id: item.id };
  if (item.isFile) {
    newItem.folderId = destination.id;
  } else {
    newItem.parentFolderId = destination.id;
  }
  _modifyItem(item.isFile, newItem, userId, success_cb, error_cb);
}

export function createOrUpdateArtifact(file, data, success_cb, error_cb) {
  // Only send if data exists
  if (data) {
    const currentDate = new Date();
    const fileData = {
      id: file.id,
      name: file.name.name && file.name.name !== "" ? file.name.name : file.inAppLocalId,
      contentType: "bild/inapp1",
      inAppLocalId: file.inAppLocalId,
      base64EncodedFile: b64EncodeUnicode(JSON.stringify(data)),
      inAppLocalTimeStamp: currentDate.getDate()
    };

    putRequest(`/disks/files/in_app/${file.inAppLocalId}`, fileData, success_cb, null, error_cb);
  }
}

export function loadArtifact(inAppLocalId, success_cb, error_cb) {
  function _success_cb(d) {
    let content = JSON.parse(b64DecodeUnicode(d.base64EncodedContent));
    const docObject = { id: inAppLocalId, value: content };
    ls.setItem(inAppLocalId, docObject);

    // Need to also update the handles for file names
    const handles = driveData.getHandles();
    handles.push({ name: d.name, id: inAppLocalId });
    driveData.setHandles(handles, null);

    if (typeof success_cb === "function") success_cb();
  }

  getRequest(`/disks/files/in_app/${inAppLocalId}`, _success_cb, null, error_cb);
}

export function loadNetworkPartnerDisk(network_partner_id, success_cb, error_cb) {
  getRequest(`/network_partners/${network_partner_id}/disk`, success_cb, null, error_cb);
}

export function loadNetworkPartnerCivilizationDisk(civilization_id, success_cb, error_cb) {
  getRequest(`/network_partners/civilizations/${civilization_id}/disk`, success_cb, null, error_cb);
}
