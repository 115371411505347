import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

const baseURL = process.env.REACT_APP_BC2_API_URL;

const useStyles = makeStyles({
  container: {},
  avatar: props => ({
    width: props.size,
    height: props.size,
    maxWidth: props.size,
    maxHeight: props.size
  }),
  circle: {
    borderRadius: "50%"
  },
  square: {
    borderRadius: "20%"
  },
  image: props => ({
    width: "100%",
    height: "100%",
    overflow: "hidden",
    background: props.color ? props.color : colors.white
  }),
  initials: props => ({
    width: "100%",
    height: "100%",
    overflow: "hidden",
    border: `2px solid ${colors.white}`,
    background: props.color ? props.color : colors.darkerGray,
    color: colors.white,
    fontWeight: "bold",
    fontSize: props.size ? props.size / 2 : 25
  }),
  icon: props => ({
    color: colors.white
  })
});

function Avatar({ size, icon, name, src, srcSize, color, variant, children }) {
  size = size ? size : 50; // default to "50px" size
  variant = variant ? variant : "circle"; // default to "circle" variant
  if (src && typeof src === "string" && src.charAt(0) === "/") {
    src = baseURL + src; // add API baseURL if needed
  }
  const _name = name ? name.trim() : null;
  if (_name && !src) {
    const salt = _name
      .trim()
      .split(" ")
      .map(x => x.charCodeAt(0))
      .reduce((a, b) => a + b);
    const seed = (_name.length + _name.split(" ")[0].length + salt) % 10 || 0;
    color = color ? color : `${colors.avatarColors[seed].backgroundColor}`;
  }
  const cls = useStyles({ size, color });

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.container}>
      <Grid container item xs className={`${cls.avatar}`}>
        {children}
        {!children && src && (
          <img className={`${cls.image} ${cls[variant]}`} src={srcSize ? src + "?size=" + srcSize : src} alt={_name} title={_name} loading="lazy" />
        )}
        {!children && !src && (_name || icon) && (
          <Grid container item justifyContent="center" alignItems="center" className={`${cls.initials} ${cls[variant]}`}>
            {icon && <i className={`${cls.icon} ${icon}`} />}
            {!icon && _name && _name.slice(0, 1).toUpperCase()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Avatar;
