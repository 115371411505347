import React, { useState, useEffect } from "react";
import { colors, SortableList, NormalButton, BTypography, UserAvatar, SupportDialog } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  wrapper: { padding: "0 " },
  avatarName: { paddingLeft: "0.5rem" },
  sectionTitle: { padding: "2rem 0.5rem 1rem 0.5rem" },
  section: { padding: "0 1rem" },
  inheritedUsers: { opacity: "0.6" }
});

function ManageOrganizationRoles({ userRoles, inheritedUserRoles }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [userRoleItems, setUserRoleItems] = useState([]);
  const [inheritedUserRoleItems, setInheritedUserRoleItems] = useState([]);
  const [userName, setUserName] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [updateRoleDialog, setUpdateRoleDialog] = useState(false);

  useEffect(() => {
    if (userRoles && userRoles.length > 0) {
      mapItems(userRoles, setUserRoleItems, true);
    }
    if (inheritedUserRoles && inheritedUserRoles.length > 0) {
      mapItems(inheritedUserRoles, setInheritedUserRoleItems, false);
    }
  }, [userRoles, inheritedUserRoles]);

  function mapItems(roles, setRoles, showButton) {
    // map raw user data into list format
    let items = [];

    let sRoles = roles
      .sort((a, b) => a.visualRole.description.localeCompare(b.visualRole.description))
      .sort((a, b) => a.user.name.localeCompare(b.user.name));
    for (let i = 0; i < sRoles.length; i++) {
      let ur = sRoles[i];
      let org = ur.organizationOverview;
      let role = ur.visualRole;
      let user = ur.user;
      items.push([
        [<UserAvatar size={40} src={user.avatarURL} name={user.name} badgeSrc={user.badgeURL} srcSize="small" sortval={user.name} key={user.name} />],
        role.description,
        org.organizationName,
        <Grid container justifyContent="flex-end">
          <Grid item>
            {showButton && (
              <NormalButton
                onClick={e => {
                  setUserName(user.name);
                  setRoleName(role.description);
                  setOrgName(org.organizationName);
                  setUpdateRoleDialog(true);
                }}
                variant="primary"
                color={colors.darkRed}
                hoverColor={colors.darkerRed}
                labelColor={colors.white}
              >
                Request Removal
              </NormalButton>
            )}
          </Grid>
        </Grid>
      ]);
    }

    setRoles(items);
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid item xs={12} className={cls.sectionTitle}>
        <BTypography variant="h4">Assigned Roles</BTypography>
      </Grid>
      <Grid item xs={12} className={cls.section}>
        <SortableList
          perPage={10}
          filter={filter}
          headers={["Name", "Role", "Organization", ""]}
          items={userRoleItems}
          emptyMessage={
            <BTypography variant="h6">
              {filter ? "No Users match the search criteria." : "There are no assigned roles for this organization."}
            </BTypography>
          }
        />
      </Grid>
      <Grid item xs={12} className={cls.sectionTitle}>
        <BTypography variant="h4">Inherited Roles</BTypography>
      </Grid>
      <Grid item xs={12} className={`${cls.section} ${cls.inheritedUsers}`}>
        <SortableList
          perPage={10}
          filter={filter}
          headers={["Name", "Role", "Organization", ""]}
          items={inheritedUserRoleItems}
          emptyMessage={
            <BTypography variant="h6">
              {filter ? "No Users match the search criteria." : "There are no inherited roles for this organization."}
            </BTypography>
          }
        />
      </Grid>
      <SupportDialog
        open={updateRoleDialog}
        closeDialog={() => {
          setUpdateRoleDialog(false);
        }}
        options={[`I would like to remove ${userName}'s '${roleName}' role from '${orgName}'.`]}
      />
    </Grid>
  );
}

export default ManageOrganizationRoles;
