import React from "react";
import { access } from "bild-utils";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { Chip, Avatar, CircularProgress } from "@mui/material";

const useStyles = makeStyles({
  chip: {
    margin: "0.17rem 0.25rem",
    "&:hover, &:focus": {
      opacity: "0.75",
      backgroundColor: "white !important" // Point of annoyance. BackgroundColor is set on .MuiChip-outlined
      // but won't budge even if set there without !important.
    }
  },
  avatar: { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgrey", marginLeft: "-1px !important" },
  icon: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none"
  },
  label: { "&:hover, &:focus": { textDecoration: "underline" } },

  attachChip: {
    borderStyle: "dashed",
    borderColor: "#168463",
    "&:hover": {
      backgroundColor: "#E3F1EE !important",
      borderStyle: "solid",
      opacity: "1"
    }
  },
  attachAvatar: { backgroundColor: "#168463" },
  attachIcon: { color: "#FFF" },
  attachLabel: { color: "#168463", "&:hover, &:focus": { textDecoration: "none" } }
});

export default function ArtifactChip({ attachButton, file, viewDocument, viewPreview, onAttachIntent, loading, ...props }) {
  const classes = useStyles();

  attachButton = !!attachButton;
  const name = attachButton ? "Attach Artifact" : access(file, "name") || "";

  function _click() {
    if (attachButton) {
      if (typeof onAttachIntent === "function") onAttachIntent();
    } else {
      if (file.inAppLocalId) {
        viewDocument(file.inAppLocalId);
      } else {
        viewPreview(file);
      }
    }
  }

  let avatarContent;
  if (!loading) {
    const ext = access(name.match(/\.[a-z0-9]+$/i), "[0]");
    let faFile;
    if (attachButton) faFile = "fa-paperclip";
    else if (ext === ".pdf") faFile = "fa-file-pdf";
    else if (ext === ".doc" || ext === ".docx") faFile = "fa-file-word";
    else if (ext === ".odt" || ext === ".ott" || ext === ".txt" || ext === ".rtf") faFile = "fa-file-alt";
    else if (ext === ".pdf") faFile = "fa-file-pdf";
    else if (ext === ".jpg" || ext === ".jpeg" || ext === ".png") faFile = "fa-file-image";
    else if (ext === ".mp3" || ext === ".wav") faFile = "fa-file-audio";
    else if (ext === ".xlsx" || ext === ".xls" || ext === ".xml" || ext === ".ods") faFile = "fa-file-spreadsheet";
    else if (ext === ".ppt" || ext === ".pptx" || ext === ".odp") faFile = "fa-file-powerpoint";
    else faFile = "fa-file";

    avatarContent = <i className={`fal ${faFile} ${clsx(classes.icon, { [classes.attachIcon]: attachButton })}`} />;
  } else avatarContent = <CircularProgress variant="indeterminate" size={20} style={{ color: "white" }} />;

  const avatar = <Avatar className={clsx(classes.avatar, { [classes.attachAvatar]: attachButton })}>{avatarContent}</Avatar>;

  return (
    <Chip
      avatar={avatar}
      label={name}
      onClick={_click}
      clickable={true}
      variant="outlined"
      classes={{
        root: clsx(classes.chip, { [classes.attachChip]: attachButton }),
        label: clsx(classes.label, { [classes.attachLabel]: attachButton }),
        outlined: classes.outlined
      }}
      {...props}
    />
  );
}
