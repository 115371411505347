import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Blink, colors } from "bild-ui";
import { daysElapsed } from "bild-utils";
import { Typography } from "@mui/material";
import Bbutton from "presentational/input/bbutton.js";
import SortButton from "presentational/input/sortButton.js";
import List from "presentational/lists/list.js";

const useStyles = makeStyles({
  buttonWrapper: { width: "100%", textAlign: "right" },
  link: { color: colors.blue },
  danger: { color: colors.red }
});

function ReviewDelinquent({ afName, reviews }) {
  const [state, setState] = useState({
    student: "off",
    org: "off",
    competency: "off",
    date: "off"
  });
  const classes = useStyles();
  const today = Date.parse(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    if (reviews) sortReview("date");
  }, [reviews]);

  function sortReview(val) {
    let newState = { student: "off", org: "off", competency: "off", date: "off" };
    newState[val] = "ascending";

    reviews.sort((a, b) => a[val] - b[val]);
    if (val === "student") reviews.sort((a, b) => a.user.name.localeCompare(b.user.name));
    if (val === "org") reviews.sort((a, b) => a.organization.organizationName.localeCompare(b.organization.organizationName));
    if (val === "competency") reviews.sort((a, b) => a.reviewCompetencies[0].name.localeCompare(b.reviewCompetencies[0].name));
    if (val === "date")
      reviews.sort(
        (a, b) =>
          new Date(a.reviewCompetencies[0].competencyProof.lastSelfAssessedAt) - new Date(b.reviewCompetencies[0].competencyProof.lastSelfAssessedAt)
      );

    if (state[val] === "ascending") {
      newState[val] = "descending";
      reviews.reverse();
    }

    setState(newState);
  }

  if (reviews) {
    const listHeadings = [
      <Typography variant="h6">
        Student
        <SortButton
          value={state.student}
          onChange={() => {
            sortReview("student");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Organization
        <SortButton
          value={state.org}
          onChange={() => {
            sortReview("org");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Competency
        <SortButton
          value={state.competency}
          onChange={() => {
            sortReview("competency");
          }}
        />
      </Typography>,
      <Typography variant="h6">
        Last Self Assessment
        <SortButton
          value={state.date}
          onChange={() => {
            sortReview("date");
          }}
        />
      </Typography>,
      ""
    ];

    let listItems = [];
    for (let i = 0; i < reviews.length; i++) {
      let r = reviews[i];
      for (let j = 0; j < r.reviewCompetencies.length; j++) {
        let rc = r.reviewCompetencies[j];
        let danger = daysElapsed(Date.parse(rc.competencyProof.lastSelfAssessedAt), today) >= 40 ? true : false;
        listItems.push([
          <Typography variant="body1">{r.user.name}</Typography>,
          <Blink dst="organization-users" organization_id={r.organization.organizationId} className={`${classes.link} default-button`}>
            <Typography variant="body1">{r.organization.organizationName}</Typography>
          </Blink>,
          <Typography variant="body1">{ReactHtmlParser(rc.name)}</Typography>,
          <Typography variant="body1" className={danger ? classes.danger : ""}>
            {danger ? (
              <>
                <i className="fas fa-exclamation-square" /> &nbsp;
              </>
            ) : (
              ""
            )}
            {rc.competencyProof.lastSelfAssessedAt}
          </Typography>,
          <div className={classes.buttonWrapper}>
            <Bbutton variant="submit" dst={"course"} course_id={rc.course.id} competency_proof_id={rc.competencyProof.id}>
              View Assessments
            </Bbutton>
          </div>
        ]);
      }
    }

    return <List title={`${afName ? afName + "'s" : ""} Delinquent Validation Reviews`} headings={listHeadings} items={listItems} />;
  } else {
    return null;
  }
}

export default ReviewDelinquent;
