import isEqual from "lodash.isequal";
import { getRequest, putRequest } from "../core/comm.js";
import { setItem, getItem } from "../core/localStorage.js";

//// ENROLLMENT
export function loadOverview(enrollment_id, success_cb, error_cb) {
  getRequest(`/enrollments/${enrollment_id}/overview/`, success_cb, null, error_cb);
}

export function loadStatement(enrollment_id, success_cb, error_cb) {
  getRequest(`/enrollments/${enrollment_id}/statement`, success_cb, null, error_cb);
}

export function loadDashboard(enrollment_id, success_cb, error_cb) {
  getRequest(`/enrollments/${enrollment_id}/dashboard/`, success_cb, null, error_cb);
}

const enrollment_dashboard_key = "enrollment_dashboard";

export function setDashboards(dashes) {
  setItem(enrollment_dashboard_key, dashes);
}

export function getDashboards(enrollment_ids) {
  const dashes = getItem(enrollment_dashboard_key);
  if (
    dashes &&
    isEqual(
      enrollment_ids.sort((a, b) => a - b),
      dashes.sort((a, b) => a.id - b.id).map(d => d.id)
    )
  ) {
    return dashes;
  } else {
    setDashboards(null);
    return null;
  }
}

export function loadDashboards(enrollment_ids, success_cb, error_cb) {
  function _loadForId(index, prev_dashes) {
    if (enrollment_ids.length < 1) {
      setDashboards([]);
      success_cb([]);
    } else if (index < enrollment_ids.length - 1) {
      loadDashboard(
        enrollment_ids[index],
        d => {
          const dashes = d.enabled ? [d, ...prev_dashes] : prev_dashes;
          _loadForId(index + 1, dashes);
        },
        error_cb
      );
    } else if (index < enrollment_ids.length) {
      loadDashboard(
        enrollment_ids[index],
        d => {
          const dashes = d.enabled ? [d, ...prev_dashes] : prev_dashes;
          setDashboards(dashes);
          success_cb(dashes);
        },
        error_cb
      );
    }
  }

  _loadForId(0, []);
}

export function loadCourseSubstitutes(enrollment_id, course_list_id, success_cb, error_cb) {
  getRequest(`/enrollments/${enrollment_id}/course_lists/${course_list_id}/substitutes`, success_cb, null, error_cb);
}

export function switchCourse(enrollment_id, course_list_id, data, success_cb, error_cb) {
  putRequest(`/enrollments/${enrollment_id}/course_lists/${course_list_id}/substitutes`, data, success_cb, null, error_cb);
}
