import React from "react";
import { colors, NormalButton, SortableList, Blink, PathProgress, UserAvatar, FilledCircleProgress, ProgramBadge, PopTip } from "bild-ui";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { downloadRequest } from "bild-data/core/comm";

const useStyles = makeStyles({
  certificate: { paddingLeft: "1rem", },
});

export default function UserPathList({ paths, filter, perPage }) {
  const cls = useStyles();

  return (
    <SortableList
      perPage={perPage ? perPage : 10}
      filter={filter}
      headers={["Status", "Program", "", ""]}
      spacing={[1,5,4,2]}
      itemPadding={"0.5rem 1rem"}
      items={paths
        .filter(x => {
          if (filter.length > 0) {
            return (
              x.title.toLowerCase().includes(filter.toLowerCase())
            );
          } else {
            return 1;
          }
        }
        )
        .sort((a,b) => b.active - a.active || b.title.localeCompare(a.title))
        .map((x, i) => {
          const color = x.programColor.webFrontendValue;
          const status = x.completed ? "Completed" : x.active ? "Active" : "Inactive";
          return ([
            [<PopTip variant="body1" sortval={status} key={status} text={status}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <FilledCircleProgress
                    total={1}
                    started={x.completed}
                    completed={x.active}
                    color={x.completed ? color : x.active ? colors.lightGreen : colors.lightGray}
                  />
                </Grid>
              </Grid>
            </PopTip>],
            [
              <UserAvatar
                size={30}
                icon={"fas fa-badge-check"}
                color={color}
                name={x.title}
                sortval={x.title}
                key={`a${i}`}
              />
            ],
            <>
              {x.completed && (
                <Grid container item xs={12} alignItems={"center"}>
                  {x.badges && JSON.parse(x.badges).map((b, i) => (
                    <Grid item xs key={i}>
                      <ProgramBadge color={color} badgeURL={b.imageURL} completed={x.completed} key={i}/>
                    </Grid>
                  ))}
                  {x.completed && x.certificates && x.certificates.map((c, j) => (
                    <Grid item className={cls.certificate} key={j}>
                      <NormalButton
                        variant={"secondary"}
                        color={colors.white}
                        labelColor={color}
                        borderColor={color}
                        hoverLabelColor={colors.white}
                        hoverColor={color}
                        onClick={() => {
                          downloadRequest(c.downloadURL, c.name);
                        }}
                      >
                        <i className="fas fa-download" />
                      </NormalButton>
                    </Grid>
                  ))}
                </Grid>
              )}
              {!x.completed && (<PathProgress
                totalCompletedCoreSteps={x.totalCompletedCoreSteps}
                totalInProgressCoreSteps={x.totalInProgressCoreSteps}
                totalCoreSteps={x.totalCoreSteps}
                totalCompletedSteps={x.totalCompletedSteps}
                totalInProgressSteps={x.totalInProgressSteps}
                totalSteps={x.totalSteps}
              />)}
            </>,
            <Grid container justifyContent="flex-end">
              <Grid item>
                <NormalButton
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  component={Blink}
                  dst="equip-user-path"
                  user_path_id={x.id}
                >
                  View &nbsp; <i className="fas fa-chevron-right" />
                </NormalButton>
              </Grid>
            </Grid>
          ])
        })
      }
      emptyMessage={filter ? "No programs match the filter." : "No programs currently assigned."}
    />
  );
}