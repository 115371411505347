import React from "react";
import { makeStyles } from "@mui/styles";

import { Divider, Grid, TextField } from "@mui/material";

import { MarkButton, BlockButton } from ".";
import { FontFamilySelect, FontSizeSelect } from ".";
import { colors } from "bild-ui";

const useStyles = makeStyles({
  container: { boxShadow: "0 1px 1px grey" },
  containerInner: { background: colors.white, padding: "0.25rem 0" },
  leftItem: { paddingLeft: "1rem" },
  fileNameWrapper: { textAlign: "right", paddingRight: "0.5rem" },
});

function Toolbar({ marks = [], blocks = [], withFonts, name, saved, onChangeName }) {
  const classes = useStyles();

  function _changeName(name) {
    if (typeof onChangeName === "function") onChangeName(name);
  }

  const markButtons = marks.map((m, i) => <MarkButton mark={m} key={i} />);
  const blockButtons = blocks.map((b, i) => <BlockButton block={b} key={i} />);

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" className={classes.containerInner}>
        <Grid item xs className={classes.leftItem}>
          {saved ? (
            <>
              <i className="fal fa-check" />
              {" All changes saved."}
            </>
          ) : (
            <>
              <i className="fal fa-spinner-third fa-spin" />
              {" Saving..."}
            </>
          )}
        </Grid>
        <Grid item container xs={6} alignItems="center">
          {markButtons}
          {blockButtons}
          {!!withFonts && (
            <>
              <FontFamilySelect />
              <FontSizeSelect />
            </>
          )}
        </Grid>
        <Grid item xs className={classes.fileNameWrapper}>
          <TextField
            inputProps={{ style: { textAlign: "right" } }}
            value={name}
            onChange={e => _changeName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
}

export default Toolbar;
