import React, { useState, useRef } from "react";
import { BTypography, Avatar, NormalButton, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { timeElapsed } from "bild-utils/general";

const useStyles = makeStyles({
  authorShown: { padding: "0 5px", overflow: "visible", transition: "width 100ms, padding 100ms" },
  authorHidden: { padding: 0, width: 0, overflow: "hidden", transition: "width 100ms, padding 100ms" },
  content: { padding: "2px 5px 5px 5px" },
  time: { padding: "2px 5px 5px 5px" },
  inputContainer: { marginTop: 5 },
  textFieldContainer: { paddingTop: "0.4em" },
  textField: { margin: "0 5px", "& *": { fontSize: "0.75rem" } }
});

function EvaluationComment({ user, value, createdAt, isInput, isDiscrete, onAddComment, pending }) {
  const classes = useStyles();
  const contentRef = useRef();
  const [inputKey, setInputKey] = useState(0);
  const [focused, setFocused] = useState(false);

  function _addComment() {
    const value = contentRef.current;
    if (value && value.length > 0 && typeof onAddComment === "function") onAddComment({ user, value, pending: true });
    contentRef.current = "";
    setInputKey(inputKey + 1);
  }

  const createdAtTime = new Date(createdAt).getTime();
  const elapsed = timeElapsed(createdAtTime, Date.now());

  let rightContent;
  if (isInput) {
    rightContent = (
      <Grid item xs className={classes.textFieldContainer}>
        <TextField
          margin="dense"
          size="small"
          placeholder="Write something!"
          className={classes.textField}
          fullWidth
          key={inputKey}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <NormalButton
                  onClick={_addComment}
                  variant="simple"
                  labelColor={!!isDiscrete && !focused ? colors.lightGray : colors.black }
                >
                  <i className="fas fa-long-arrow-right" />
                </NormalButton>
              </InputAdornment>
            )
          }}
          inputProps={{
            onKeyPress: e => {
              if (e.key === "Enter") _addComment();
            },
            onFocus: e => setFocused(true),
            onBlur: e => setFocused(false)
          }}
          onChange={e => (contentRef.current = e.target.value)}
        />
      </Grid>
    );
  } else {
    let elapsedComponent;
    if (pending) {
      elapsedComponent = <i className="fal fa-spinner-third fa-spin" />;
    } else {
      elapsedComponent = createdAt ? elapsed : "";
    }

    rightContent = (
      <>
        <Grid item xs className={classes.content}>
          <BTypography variant="body2">{value}</BTypography>
        </Grid>
        <Grid item className={classes.time}>
          <BTypography variant="body2">{elapsedComponent}</BTypography>
        </Grid>
      </>
    );
  }

  return (
    <Grid container className={classes.inputContainer} alignItems="center">
      <Grid item className={!isDiscrete || focused ? classes.authorShown : classes.authorHidden}>
        <Avatar size={35} src={user.avatarURL} name={user.name} srcSize="small" />
      </Grid>
      {rightContent}
    </Grid>
  );
}

export default EvaluationComment;
