import React from "react";

import FileSourceButton from "./fileSourceButton.js";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

function SourceChoiceDialog({ open, onClose, onChooseFolio, onChooseComputer, onChooseGoogle, onChooseDropbox, classes }) {
  return (
    <Dialog open={open === true} onClose={onClose}>
      <DialogContent>
        <Grid container>
          <Grid item style={{ minWidth: "250px", padding: "20px 0px" }}>
            <FileSourceButton label="Attach from your Folio." variant="folio" onClick={onChooseFolio} />

            <FileSourceButton label="Attach from your device." variant="computer" onClick={onChooseComputer} />

            <FileSourceButton label="Attach from Google Drive." variant="google" onClick={onChooseGoogle} />

            <FileSourceButton label="Attach from Dropbox." variant="dropbox" onClick={onChooseDropbox} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SourceChoiceDialog;
