import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  circle: props => ({
    paddingRight: "0.25rem",
    color: props.color,
    fontSize: "0.8rem"
  }),
  started: {
    opacity: "0.25"
  }
});

function FilledCircleProgress({ color, completed, started, total, justify}) {
  const cls = useStyles({ color });

  let progress = [];

  for (let i = 0; i < total; i++) {
    let name = "fal fa-circle";
    if (i < completed) {
      name = "fas fa-circle";
    }
    if (i >= completed && i < started) {
      name = `fas fa-circle ${cls.started}`;
    }
    progress.push(
      <Grid item key={i}>
        <i className={`${name} ${cls.circle}`} />
      </Grid>
    );
  }

  return <Grid container justifyContent={justify ? justify : "flex-start"}>{progress}</Grid>;
}

export default FilledCircleProgress;
