import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Blink } from "bild-ui";
import { Grid, Typography, List, ListItem, Hidden, Button } from "@mui/material";

// COMPETENCY PROOF
function CompetencyProof({ competencies, reviewType }) {

  function getCompetencyProofs() {
    const proofs = [];

    proofs.push(
      <ListItem key="00" className="review-header">
        <Grid container>
          <Grid item sm={5} xs={4}>
            <Typography variant="subtitle1">Competency</Typography>
          </Grid>
          <Hidden smDown>
            <Grid item sm={7} xs={8}>
              <Typography variant="subtitle1">Last Updated</Typography>
            </Grid>
          </Hidden>
        </Grid>
      </ListItem>
    );

    if (competencies) {
      for (let i = 0; i < competencies.length; i++) {
        const cmp = competencies[i];
        const p = cmp.competencyProof;
        const crs = cmp.course;

        proofs.push(
          <ListItem className="review-item" key={i}>
            <Grid container>
              <Grid item sm={5} xs={12}>
                <Typography>{cmp.name ? ReactHtmlParser(cmp.name) : ReactHtmlParser(cmp.description)}</Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography>{p.updatedAt}</Typography>
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                className="review-action"
                component={Blink}
                dst={"course"}
                course_id={crs.id}
                competency_proof_id={p.id}
                return_review_type={reviewType}
              >
                <Button className="view-course-button" style={{ textTransform: "capitalize" }}>
                  Conduct {reviewType} Review
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        );
      }
    }

    if (competencies.length < 1) {
      proofs.push(
        <ListItem key="1" className="review-empty">
          <Grid container>
            <Grid item md={12} xs={12}>
              <Typography variant="body1">Nothing left to review</Typography>
            </Grid>
          </Grid>
        </ListItem>
      );
    }

    return <List disablePadding>{proofs}</List>;
  }

  return <div className="review-list">{getCompetencyProofs()}</div>;
}

export default CompetencyProof;
