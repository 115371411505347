import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography, Grid } from "@mui/material";

import { Tile } from ".";
import { colors } from "bild-ui";

const useStyles = makeStyles({
  container: { height: "100%", width: "100%" },
  header: { width: "100%", borderBottom: "1px solid black", padding: "0.25rem 2rem" },
  pageTitle: { color: colors.bildBlue },
  tilesContainer: { padding: "1rem" }
});

function BildDrive({ handles, onCreateDocument, onGoToDocument, onDeleteDocument }) {
  const classes = useStyles();

  function _createNewDoc() {
    if (typeof onCreateDocument === "function") onCreateDocument();
  }

  function _goToDocument(handle) {
    if (typeof onGoToDocument === "function") onGoToDocument(handle);
  }

  function _deleteDocument(handle) {
    if (typeof onDeleteDocument === "function") onDeleteDocument(handle.id);
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.pageTitle}>
          <strong>BILD</strong> Drive
        </Typography>
      </div>

      <Grid container className={classes.tilesContainer}>
        {handles.map((h, i) => (
          <Tile {...h} onClick={() => _goToDocument(h)} onDeleteIntent={() => _deleteDocument(h)} key={i} />
        ))}
        <Tile createDocument onClick={_createNewDoc} />
      </Grid>
    </div>
  );
}

export default BildDrive;
