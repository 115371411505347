import { Editor, Element, Transforms, Descendant, Range } from "slate";
import { jsx } from "slate-hyperscript";
import { constants } from ".";

export type LinkElement = { type: "link", url: string, children: Descendant[] };

export const toggleMark = (editor, format) => {
  //// meb converted for slate 0.58
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isMarkActive = (editor, format) => {
  //// meb converted for slate 0.58
  try {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (e) {
    console.log(e);
  }
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = constants.LIST_TYPES.includes(format);
  const isAlign = constants.TEXT_ALIGN_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => constants.LIST_TYPES.includes(!Editor.isEditor(n) && Element.isElement(n) && n.type),
    split: true
  });
  let newProperties
  if (isList) {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format
    };
  }
  if (isAlign) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isBlockActive = (editor, format) => {
  try {
    const { selection } = editor
    if (!selection) return false  

    const blockType = constants.TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type';

    const [match] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n[blockType] === format
    });

    return !!match;
  } catch (e) {
    console.log(e);
  }
};

export const toggleLink = editor => {
  if (!isLinkActive(editor)) {
    const url = window.prompt("Enter the URL of the link:");
    if (!url) return;
    insertLink(editor, url);
  } else {
    removeLink(editor);
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const removeLink = editor => {
  if (editor.selection) {
    unwrapLink(editor);
  }
};

export const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link"
  });
  return !!link;
};

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link"
  });
};

export const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : []
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const htmlDeserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
    parent = el.childNodes[0];
  }
  const children = Array.from(parent.childNodes)
    .map(htmlDeserialize)
    .flat();

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (constants.ELEMENT_TAGS[nodeName]) {
    const attrs = constants.ELEMENT_TAGS[nodeName](el);
    return jsx("element", attrs, children);
  }

  if (constants.TEXT_TAGS[nodeName]) {
    const attrs = constants.TEXT_TAGS[nodeName](el);
    return children.map(child => jsx("text", attrs, child));
  }

  return children;
};

export const setFontFamily = (editor, fontFamily) => {
  Editor.addMark(editor, "fontFamily", fontFamily);
};

export const isFontFamilyActive = (editor, fontFamily) => {
  //// meb removed to work with slate 0.58
  return isMarkActive(editor, "fontFamily");
};

export const setFontSize = (editor, fontSize) => {
  Editor.addMark(editor, "fontSize", fontSize);
};

export const isFontSizeActive = (editor, fontSize) => {
  //// meb removed to work with slate 0.58
  return isMarkActive(editor, "fontSize");
};
