import React, { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { userPathData, userData } from "bild-data";
import queryString from "query-string";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import UserPath from "./userPath.js";

function UserPathLoader() {
  const location = useLocation();
  const { user_path_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userPath, setUserPath] = useState({});
  const [user, setUser] = useState({});
  const [groups, setGroups] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [pcolor, setPcolor] = useState("#000");
  const [defaultTab, setDefaultTab] = useState(null);
  const [defaultGroup, setDefaultGroup] = useState(null);
  const [defaultItem, setDefaultItem] = useState(null);

  // Get data needed for the page
  useEffect(() => {
    const sParams = queryString.parse(location.search);
    // Set default tab, group, and/or item to open on page load
    if (sParams && sParams.bc) {
      let tval = sParams.bc.split("-");
      setDefaultTab(tval[0] ? Number(tval[0]) : null);
      setDefaultGroup(tval[1] ? Number(tval[1]) : null);
      setDefaultItem(tval[2] ? Number(tval[2]) : null);
    }

    function _setPath(data) {
      setPageTitle(data.title);
      setUserPath(data);
      setPcolor(data.programColor.webFrontendValue);
      setGroups(data.learningGroups);
      setObjectives(data.objectives);
      // Check if any core items exist, otherwise go to All Tab
      if (data.learningGroups.map(x => x.userPathItems).flat().filter(x => x.required).length < 1) {
        setDefaultTab(0);
      }
      setLoading(false);
    }
    userPathData.load(user_path_id, _setPath, e => {
      setError(e.response.data.error);
    });
    setUser(userData.getOverview().user);
  }, []);

  // Show the loading screen until everything is ready
  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <UserPath
      userPath={userPath}
      user={user}
      groups={groups}
      objectives={objectives}
      color={pcolor}
      backgroundURL={userPath.backgroundImageURL}
      defaultTab={defaultTab}
      defaultGroup={defaultGroup}
      defaultItem={defaultItem}
    />
  );
}

export default UserPathLoader;
