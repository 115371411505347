import React, { useState, useEffect } from "react";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import ShepherdingCoaches from "./shepherdingCoaches.js";
import { userData } from "bild-data/index.js";

export default function ShepherdingCoachesLoader({}) {
  const [loading, setLoading] = useState(true);
  const [coaches, setCoaches] = useState([]);
  

  useEffect(()=>{
    setPageTitle("MCCEE Shepherding Coaches");

    function _addCoach(d) {
      setCoaches(old => [...old, d]);
    }

    // NOTE: Hardcoded list of MCCEE Coaches for now
    const coachIds = [11, 16, 19, 24, 27, 360, 563];
    for (let i=0; i<coachIds.length; i++) {
      userData.loadUserProfile(coachIds[i], _addCoach);
    }

  },[]);

  useEffect(()=>{
    if (coaches.length >= 6) {
      setLoading(false);
    }
  },[coaches])

  if (loading) return <LoadingScreen />;
  return (
    <BaseWrapper>
      <ShepherdingCoaches
        coaches={coaches}
      />
    </BaseWrapper>
  );
}