import React, { useState } from "react";
import { Blink } from "bild-ui";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "./user.css";

function Register({ handleSubmit, username, ...props }) {
  const [form, setForm] = useState({ password: "", confirm_password: "", remember_me: false });
  const [error, setError] = useState({});

  function _handleSubmit() {
    if (_validateForm()) {
      handleSubmit(form);
    }
  }

  function _validateForm() {
    if (form.password.length < 8) {
      setError({ password: "Please enter a password that is at least 8 characters long" });
      return false;
    } else if (form.password === username) {
      setError({ password: "Passwords cannot match your username" });
      return false;
    } else if (!/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(form.password) && !/[A-Z0-9]/.test(form.password)) {
      setError({ password: "Passwords must contain at least one special character or capital letter" });
      return false;
    } else if (form.confirm_password.length < 8) {
      setError({ confirm_password: "Please confirm your password" });
      return false;
    } else if (form.password !== form.confirm_password) {
      setError({ password: "Passwords do not match", confirm_password: "Passwords do not match" });
      return false;
    } else {
      setError({});
      return true;
    }
  }

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className="register">
      <Grid item xs={12} className="register-box">
        <form onSubmit={_handleSubmit}>
          <Grid container direction="column" justifyContent="space-between">
            <Grid item xs={12} className="form-item-container logo-container">
              <img src="/bild_cloud_login_logo.png" alt="bc-login-logo" className="logo" />
            </Grid>
            <Grid item xs={12} className="form-item-container">
              <Typography variant="subtitle1" className="form-subtitle">
                Welcome! Please complete the steps below to finish setting up your BILD Cloud account.
              </Typography>
            </Grid>
            <Grid item xs={12} className="form-item-container">
              <FormControl fullWidth>
                <FormLabel>Email</FormLabel>
                <OutlinedInput className="form-item" id="username" fullWidth value={username} disabled />
              </FormControl>
            </Grid>
            <Grid item xs={12} className="form-item-container">
              <FormControl fullWidth error={error.password ? true : false}>
                <FormLabel>Choose Your Password</FormLabel>
                <OutlinedInput
                  className="form-item"
                  id="password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={form.password}
                  onChange={e => {
                    _handleChange("password", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                  autoFocus
                />
                {error.password && <FormHelperText>{error.password}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} className="form-item-container">
              <FormControl fullWidth error={error.confirm_password ? true : false}>
                <FormLabel>Confirm Password</FormLabel>
                <OutlinedInput
                  className="form-item"
                  id="confirm_password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={form.confirm_password}
                  onChange={e => {
                    _handleChange("confirm_password", e.target.value);
                  }}
                  onKeyDown={_handleKeyDown}
                />
                {error.confirm_password && <FormHelperText>{error.confirm_password}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} className="form-item-container">
              <Button variant="contained" className="form-item submit-button" onClick={_handleSubmit} onKeyDown={_handleKeyDown}>
                Create your account
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} component={Blink} dst="policy" policy="privacy_policy" target="_blank" className="form-item-container support-link">
                  View Privacy Policy
                </Grid>
                <Grid item xs={6} component={Blink} dst="policy" policy="terms_of_use" target="_blank" className="form-item-container support-link">
                  View Terms of Service
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Register;
