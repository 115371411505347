import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  pageError: {
    marginTop: "50px",
    textAlign: "center",
    bildIconCloud: {
      width: "200px"
    }
  }
});

function PageUnauthorizedScreen({ error }) {
  const cls = useStyles();
  return (
    <Grid container className={cls.pageError} justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h5">
          <i className="fad fa-lock-alt fa-4x" />
        </Typography>
        <br />
        <Typography variant="h5">You are not currently authorized to view this content.</Typography>
      </Grid>
    </Grid>
  );
}

export default PageUnauthorizedScreen;
