import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: { padding: "0.5rem 0" },
  errors: { backgroundColor: colors.lightRed, padding: "1rem" },
  list: { padding: "0", margin: "0" },
  error: { color: colors.red, margin: "0 1rem" }
});

function FormErrors({ errors }) {
  const cls = useStyles();

  return (
    <Grid container className={cls.container}>
      {errors && Object.keys(errors).length > 0 && (
        <Grid item xs={12} className={cls.errors}>
          <ul className={cls.list}>
            {Object.values(errors).map((e, i) => (
              <li key={i} className={cls.error}>
                {e}
              </li>
            ))}
          </ul>
        </Grid>
      )}
    </Grid>
  );
}

export default FormErrors;
