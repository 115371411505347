import React, { useState } from "react";
import { toaster } from "presentational/toasts/toasts.js";

import { FormAutocompleteField } from "bild-ui";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function AddUserRoleDialog({ open, user, roles, organizations, onClose, onSubmit, onSuccess, classes }) {
  let [newRole, setNewRole] = useState({ roleEnum: -1, organizationId: -1 });

  function _validateRole() {
    if (newRole.roleEnum !== -1 && newRole.organizationId !== -1) {
      let role = roles[newRole.roleEnum];
      let organization = organizations.find(x => {
        return x.organizationId === newRole.organizationId;
      });
      onSubmit(
        user,
        organization,
        role,
        data => {
          _onClose();
          setNewRole({});
          onSuccess(data);
          toaster.success("User Role Added!");
        },
        data => {
          _onClose();
          toaster.error("Adding User Role Failed.");
        }
      );
    }
  }

  function _onClose() {
    setNewRole({ roleEnum: -1, organizationId: -1 });
    onClose(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        _onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent className={classes.dialog}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="h6">Add User Role for {user.name}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.fieldWrapper}>
            <FormAutocompleteField
              label="Organization"
              value={newRole.organizationId}
              setValue={x => {
                setNewRole({ ...newRole, organizationId: x });
              }}
              items={organizations.map(x => {
                return { id: x.organizationId, name: x.organizationName };
              })}
              required={true}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldWrapper}>
            <FormAutocompleteField
              label="Role"
              value={newRole.roleEnum}
              setValue={x => {
                setNewRole({ ...newRole, roleEnum: x });
              }}
              items={
                roles
                  ? Object.keys(roles)
                      .sort((a, b) => a.localeCompare(b))
                      .map((x, i) => {
                        return { id: x, name: x };
                      })
                  : []
              }
              required={true}
            />
          </Grid>
          <Grid item className={classes.fieldWrapper}>
            <Button
              className="default-submit-button"
              onClick={() => {
                _validateRole();
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddUserRoleDialog;
