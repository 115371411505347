import React, { useState } from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  tile: {
    width: "100%",
    height: "100%",
    border: `1px solid ${colors.lightGray}`,
    borderRadius: "4px",
    "&:hover": { cursor: "pointer" }
  },
  segment: { padding: "0.5rem", textAlign: "center", "&:hover": { cursor: "pointer", background: colors.lightGray } },
  top: { borderBottom: `1px solid ${colors.lightGray}` }
});

// Hover Tiles are tiles that have an initial passed state and then on hover (or touch) moves to a second state with two passed in states with actions
function HoverTile({ initial, top, bottom, topAction, bottomAction }) {
  const classes = useStyles();
  const [step, setStep] = useState(0);

  return (
    <Grid
      container
      item
      xs={12}
      onMouseEnter={() => {
        setStep(1);
      }}
      onMouseLeave={() => {
        setStep(0);
      }}
      className={classes.tile}
      justifyContent="center"
      alignItems="stretch"
    >
      {step === 0 && (
        <Grid
          container
          item
          xs={12}
          onClick={() => {
            setStep(1);
          }}
          className={classes.segment}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>{initial}</Grid>
        </Grid>
      )}
      {step === 1 && (
        <>
          <Grid container item xs={12} onClick={topAction} className={`${classes.segment} ${classes.top}`} justifyContent="center" alignItems="center">
            <Grid item>{top}</Grid>
          </Grid>
          <Grid container item xs={12} onClick={bottomAction} className={classes.segment} justifyContent="center" alignItems="center">
            <Grid item>{bottom}</Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default HoverTile;
