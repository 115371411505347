import React, { useState, useEffect } from "react";
import { setPageTitle } from "bild-utils";
import { EquipWrapper, LoadingScreen, colors } from "bild-ui";
import { equipReviewData, userData } from "bild-data/index.js";
import ShepherdsReview from "./shepherdsReview";

export default function ShepherdsReviewLoader({}) {
  const [loading, setLoading] = useState(true);
  const [actionItems, setActionItems] = useState([]);
  const [shepherds, setShepherds] = useState([]);
  const [assessmentData, setAssessmentData] = useState([]);

  useEffect(()=>{
    setPageTitle("Shepherds Review");

    function _setData(d) {
      const aItems = d.actionItems;
      const sheps = d.shepherdUsers;
      const aData = d.assessmentTotals;
      let counts = [];
      let oldests = [];
      // Initializae Counts
      for (let i=0; i<sheps.length; i++) {
        const su = sheps[i];
        counts[su.id] = 0;
        oldests[su.id] = "";
      }
      // Add shepherd waiting counts to totals
      for (let i=0; i<aItems.length; i++) {
        const ai = aItems[i];
        const shepId = ai.userLearningItem.shepherd.id;
        const cDate = ai.userLearningItem.updatedAt;
        counts[shepId] = counts[shepId] + 1;
        if (oldests[shepId] === "") {
          oldests[shepId] = cDate;
        } else {
          const ddiff = new Date(cDate) - new Date(oldests[shepId])
          if (ddiff < 0) {
            oldests[shepId] = cDate;
          }
        }
      }
      // Add totals outstandingAssessmentsCount to shepherds
      setShepherds(sheps.map(x => {
        return {...x, outstandingAssessmentsCount: counts[x.id], oldestWaitingDate: oldests[x.id]}
      }));
      setActionItems(aItems);
      setAssessmentData(aData);
      setLoading(false);
    }

    const userOverview = userData.getOverview();
    const userPrefs = userData.getUserPreferences();
    if (userData.checkAdminDemoUser && userPrefs.demoMode && userOverview.primaryOrganization && userOverview.primaryOrganization.organizationId) {
      equipReviewData.getOrganizationShepherdsReview(userOverview.primaryOrganization.organizationId, _setData);
    } else {
      equipReviewData.getShepherdsReview(_setData);
    }

  },[]);

  if (loading) return <LoadingScreen />;
  return (
    <EquipWrapper>
      <ShepherdsReview
        actionItems={actionItems}
        shepherds={shepherds}
        assessmentData={assessmentData}
      />
    </EquipWrapper>
  );
}