import React from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  menuIcon: {
    fontSize: "1.1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "middle",
    textAlign: "center",
    color: "black"
  },
  invisible: {
    fontSize: "1.1rem",
    width: "1.2rem",
    height: "1.2rem",
    lineHeight: "1.2rem",
    verticalAlign: "middle",
    textAlign: "center",
    visibility: "hidden"
  },
  menuButton: {
    padding: "0.4rem",
    margin: "-0.2rem"
  }
});

function MenuButton({ variant, onClick }) {
  const classes = useStyles();

  let iconClass, disabled;
  switch (variant) {
    case "invisible":
      iconClass = `far fa-ellipsis-h ${classes.invisible}`;
      disabled = true;
      break;
    default:
      iconClass = `far fa-ellipsis-h ${classes.menuIcon}`;
      disabled = false;
      break;
  }

  function _onClick(e) {
    e.stopPropagation();
    if (onClick) {
      onClick(e.currentTarget);
    }
  }

  return (
    <IconButton
      disabled={disabled}
      onClick={_onClick}
      className={classes.menuButton}
      size="large">
      <i className={iconClass} />
    </IconButton>
  );
}

export default MenuButton;
