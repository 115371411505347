import React from "react";
import { makeStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import { colors, Blink, BTypography, NormalButton, FormTextField, Progress } from "bild-ui";

const useStyles = makeStyles({
  header: { padding: "0.5rem" },
  headerItem: { padding: "0 0.5rem" },
  enrollmentsWrapper: { padding: "0.5rem 1rem" },
  enrollments: { backgroundColor: colors.white, paddingBottom: "2rem" },
  enrollmentWrapper: { minHeight: "5rem", padding: "1rem" },
  enrollment: {
    borderRadius: "4px",
    padding: `1rem`,
    backgroundColor: colors.white,
    border: `0.1rem solid ${colors.antiochPurple}`
  },
  status: {},
  active: { fontSize: "1.25rem", color: colors.darkGreen, fontWeight: "bold" },
  title: {
    fontWeight: "bold",
    padding: "1rem 0 0 0",
    color: colors.antiochPurple
  },
  subtitle: {
    fontWeight: "bold",
    padding: "0 0 1rem 0",
    color: colors.antiochPurple
  },
  footer: { padding: "1rem 0 0 0" }
});

function UserAdminAntioch({ user, overview }) {
  const cls = useStyles();

  return (
    <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
      <Grid container item justifyContent="flex-end" alignItems="center" className={cls.header}>
        <Grid item xs className={cls.headerItem}>
          <BTypography variant="h4">Enrollments</BTypography>
        </Grid>
        <Grid item className={cls.headerItem}>
          <NormalButton
            component={Blink}
            dst="user-admin-competencies"
            user_id={user.id}
            variant="secondary"
            color={colors.white}
            borderColor={colors.bildBlue}
            hoverColor={colors.bildBlue}
            labelColor={colors.bildBlue}
          >
            Competency Proofs
          </NormalButton>
        </Grid>
        <Grid item className={cls.headerItem}>
          <NormalButton
            component={Blink}
            dst="user-admin-courses"
            user_id={user.id}
            variant="secondary"
            color={colors.white}
            borderColor={colors.bildBlue}
            hoverColor={colors.bildBlue}
            labelColor={colors.bildBlue}
          >
            Courses
          </NormalButton>
        </Grid>
        <Grid item className={cls.headerItem}>
          <NormalButton
            component={Blink}
            dst="user-audit-antioch-courses"
            user_id={user.id}
            variant="secondary"
            color={colors.white}
            borderColor={colors.bildBlue}
            hoverColor={colors.bildBlue}
            labelColor={colors.bildBlue}
          >
            Audit Courses
          </NormalButton>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12} className={cls.enrollmentsWrapper}>
          {overview.enrollments.map(x => (
            <Grid container item sm={6} xs={12} className={cls.enrollmentWrapper} key={x.id}>
              <Grid container item xs={12} className={cls.enrollment}>
                <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                  <Grid item className={`${x.status === "Active" ? cls.active : ""} ${cls.status}`}>
                    {x.status}
                  </Grid>
                  <Grid item>CRM: {x.enrollmentNumber}</Grid>
                </Grid>
                <Grid item xs={12} className={cls.title}>
                  <BTypography variant="h5">{x.programName}</BTypography>
                </Grid>
                <Grid item xs={12} className={cls.subtitle}>
                  <BTypography variant="body1">Enrollment ID: {x.id}</BTypography>
                </Grid>
                <Grid item xs={12}>
                  <BTypography variant="subtitle1">
                    {x.organization.organizationName} ({x.organization.organizationId})
                  </BTypography>
                </Grid>
                <Grid item xs={6}>
                  <BTypography variant="body1">
                    LM: {x.clUser.name} ({x.clUser.id})
                  </BTypography>
                </Grid>
                <Grid item xs={6}>
                  <BTypography variant="body1">
                    AF: {x.afUser.name} ({x.afUser.id})
                  </BTypography>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.footer}>
                  <Grid item>
                    <NormalButton
                      component={Blink}
                      dst="enrollment"
                      enrollment_id={x.id}
                      variant="secondary"
                      color={colors.white}
                      borderColor={colors.bildGray}
                      hoverColor={colors.bildGray}
                      labelColor={colors.bildGray}
                    >
                      View &nbsp; <span className="fas fa-chevron-right" />
                    </NormalButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserAdminAntioch;
