import React from "react";
import { makeStyles } from "@mui/styles";

import LeftMenuItem from "./leftMenuItem.js";

import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

const useStyles = makeStyles({
  container: { backgroundColor: "#2A3137", overflow: "hidden", scrollbarWidth: "none" },
  logo: { height: "34px", width: "auto" },
  divider: { backgroundColor: "white" },
  listItem: {
    whiteSpace: "nowrap",
    borderLeft: "5px solid transparent",
    "& *": { color: "#AAA", fontWeight: "bold" },
    "& .fal": { fontWeight: "300 !important" },
    "&:hover *": { color: "white" }
  },
  hidden: { visibility: "hidden" },
  menuHeader: { padding: "0.4rem 0 0.5rem 1.1rem", height: "47px", width: "250px" }
});

function LeftMenu({ onClose, onToggleMini, sections, open, mobile, mini, root, className, ...props }) {
  const classes = useStyles();

  const sectionComponents = [];
  if (sections) {
    let i;
    for (i = 0; i < sections.length; i++) {
      sectionComponents.push(<Divider className={classes.divider} key={2 * i} />);
      const headerComponents = [];
      const headers = sections[i].headers;
      for (let j = 0; j < headers.length; j++) {
        headerComponents.push(<LeftMenuItem header={headers[j]} mini={mini} key={j} />);
      }
      sectionComponents.push(<List key={2 * i + 1}>{headerComponents}</List>);
    }
  }

  return (
    <Drawer
      variant={mobile ? "temporary" : "permanent"}
      anchor="left"
      open={mobile ? open : true}
      classes={{ paper: [className, classes.container].join(" ") }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      onClose={onClose}
      {...props}
    >
      <Grid container justifyContent="flex-start" alignItems="center" className={classes.menuHeader}>
        <img id="bc2-logo-l" className={classes.logo} src="/bild_menu_logo.png" alt="bc2-logo-l" />
      </Grid>

      {sectionComponents}
    </Drawer>
  );
}

export default LeftMenu;
