import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

import { Editable, Slate } from "slate-react";

import { Grid } from "@mui/material";

import { ArtifactToolbar, Leaf, Element, useFullPageReader } from ".";

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "100%",
    border: `1px solid ${colors.gray}`,
    borderRadius: "1px",
    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2), inset 0 0 2px rgba(0, 0, 0, 0.1)"
  },
  editable: { height: "100%", padding: "1rem" }
});

function ArtifactReader({ document, handle }) {
  const cls = useStyles();
  const editor = useFullPageReader();

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  return (
    <>
      <Slate editor={editor} value={document.value}>
        <Grid container className={cls.container}>
          <Grid item xs={12}>
            <ArtifactToolbar name={handle.name} dummyMode={true} />
            <Editable id="bild-reader" readOnly renderElement={renderElement} renderLeaf={renderLeaf} className={cls.editable} />
          </Grid>
        </Grid>
      </Slate>
    </>
  );
}

export default ArtifactReader;
