import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { BTypography, NormalButton, colors, SortableList, Blink } from "bild-ui";
import NetworkPartnerOrganizationDialog from "./networkPartnerOrganizationDialog";
import NetworkPartnerOrganizationRemoveDialog from "./networkPartnerOrganizationRemoveDialog";

const useStyles = makeStyles({
  header: { padding: "0 0.25rem 0.5rem 0.25rem" },
  sectionInner: { minHeight: "20rem", maxHeight: "25rem", overflowY: "auto", },
  removeButtonWrapper: { paddingRight: "0.25rem" }
});

export default function NetworkPartnerOrganizations({ partner, organizations, allOrganizations, updatePartner }) {
  const cls = useStyles();
  const [orgRows, setOrgRows] = useState([]);
  const [filteredAllOrgs, setFilteredAllOrgs] = useState(allOrganizations);
  const [newOrgDialog, setNewOrgDialog] = useState(false);
  const [confirmRemoveOrgDialog, setConfirmRemoveOrgDialog] = useState(false);
  const [confirmRemoveOrg, setConfirmRemoveOrg] = useState({});

  useEffect(()=>{
    if (organizations) {
      setOrgRows(organizations.map(x=>{
        return [
          x.organizationName,
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item className={cls.removeButtonWrapper}>
              <NormalButton
                variant="primary"
                color={colors.white}
                borderColor={colors.red}
                hoverColor={colors.darkRed}
                labelColor={colors.red}
                onClick={()=>{
                  setConfirmRemoveOrg({ id: x.organizationId, name: x.organizationName, partnerName: partner.name });
                  setConfirmRemoveOrgDialog(true);
                }}
              >
                <i className="fas fa-times" />
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton
                component={Blink}
                dst="manage-organization"
                organization_id={x.organizationId}
                variant="primary"
                color={colors.white}
                borderColor={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.bildBlue}
              >
                <i className="fad fa-sign-out" />
              </NormalButton>
            </Grid>
          </Grid>
        ];
      }));
    } else {
      setOrgRows([]);
    }

  },[organizations])

  useEffect(()=>{
    if (organizations) {
      setFilteredAllOrgs(allOrganizations.filter(x => !organizations.some(y => y.organizationId === x.id)));
    } else {
      setFilteredAllOrgs(allOrganizations);
    }
  },[organizations, allOrganizations]);

  return (
    <>
      <Grid container  item xs={12} justifyContent="flex-start" alignItems="center">
        <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.header}>
          <Grid item xs>
            <BTypography variant="h5">Partner Organizations</BTypography>
          </Grid>
          <Grid item>
            <NormalButton
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              onClick={()=>{setNewOrgDialog(true)}}
            >
              <i className="far fa-plus" />
            </NormalButton>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${cls.sectionInner}`}>
          <SortableList
            headers={["Name",""]}
            items={orgRows}
            itemPadding={"0.25rem 0.5rem"}
            emptyMessage={"No organizations for this partner."}
          />
        </Grid>
      </Grid>
      <NetworkPartnerOrganizationDialog open={newOrgDialog} onClose={()=>{setNewOrgDialog(false)}} allOrganizations={filteredAllOrgs} updatePartner={updatePartner} />
      <NetworkPartnerOrganizationRemoveDialog
        open={confirmRemoveOrgDialog}
        onClose={()=>{setConfirmRemoveOrgDialog(false)}}
        org={confirmRemoveOrg}
        updatePartner={updatePartner}
      />
    </>
  );
}