import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { colors, BTypography, UserAvatar, Fireworks, NormalButton, PopTip, SortableList } from "bild-ui";
import { useWidth } from "bild-utils";

const useStyles = makeStyles({
  container: { padding: "1rem", height: "100%" },
  heading: { padding: "1rem", background: colors.white, borderRadius: "0.25rem" },
  section: { padding: "0.25rem", maxHeight: "80vh", },
  leaderboard: {
    textAlign: "center",
    background: colors.white,
    borderRadius: "0.25rem",
    padding: "1rem 0 3rem 0",
  },
  features: {
    textAlign: "center",
    background: colors.white,
    borderRadius: "0.25rem",
  },
  header: { padding: "1rem 0" },
  placedSpot: {
    margin: "0.5rem",
    padding: "1rem",
    color: colors.white,
    textAlign: "center",
    borderRadius: "1rem",
  },
  firstPlace: {
    boxShadow: "6px 6px 0px #a2a3a5",
    background: "rgb(89,79,255)",
    background: "linear-gradient(90deg, rgba(89,79,255,1) 0%, rgba(9,121,102,1) 34%, rgba(6,160,134,1) 63%, rgba(0,212,255,1) 100%)",
    color: colors.lightYellow,
  },
  secondPlace: { boxShadow: "2px 2px 0px #a2a3a5", background: colors.bildBlue },
  thirdPlace: { boxShadow: "1px 1px 0px #a2a3a5", background: colors.darkGray },
  partyButton: { zIndex: "1000", fontSize: "2rem" },
  points: { fontWeight: "bold", fontSize: "2rem" },
  listUser: { textAlign: "left", paddingLeft: "1rem" },
});

export default function BetaTesterLeaderboard({ users, features }) {
  const cls = useStyles();
  const [u1, setU1] = useState({});
  const [u2, setU2] = useState({});
  const [u3, setU3] = useState({});
  const [party, setParty] = useState(false);
  const w = useWidth();

  useEffect(()=>{
    if (users) {
      let _users = users.map(x => {
        let u = x;
        u.points = 0;
        return u;
      });
      for (let i=0; i<features.length; i++) {
        let f = features[i];
        let u = _users.find(x => x.id === f.userId);
        u.points += f.points;
      }
      _users.sort((a,b) => b.points - a.points);

      setU1(_users[0]);
      setU2(_users[1]);
      setU3(_users[2]);
    }
  },[users]);

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" direction={`${w === "xs"} ? "row" : "column"`} className={cls.container}>
      <Grid container item justifyContent="flex-start" alignItems="flex-start">
        <Grid container item xs={12} className={cls.section}>
          <Grid item xs={12} className={cls.heading}>
            <BTypography variant="h2">BETA Tester Leaderboard</BTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-start" alignItems="stretch">
        <Grid container item sm={6} xs={12} className={cls.section} justifyContent="flex-start" alignItems="stretch">
          <Grid container item xs={12} justifyContent="center" alignItems="stretch" className={cls.leaderboard}>
            <Grid item xs={12} className={cls.header}>
              <BTypography variant="h3"><b>Leaderboard</b></BTypography>
            </Grid>
            <Grid container item xs={11} className={`${cls.placedSpot} ${cls.firstPlace}`} justifyContent="center" alignItems="center">
              <Grid item><UserAvatar size={100} src={u1.avatarURL} name={u1.name} badgeSrc={u1.badgeURL} nameVariant="h3"/></Grid>
              <Grid item><NormalButton variant="simple" onClick={()=>{setParty(!party)}} className={`${party ? "fa-spin" : ""} ${cls.partyButton}`}>🥇🎉</NormalButton></Grid>
              <Grid item className={cls.points}>{u1.points}</Grid>
            </Grid>
            <Grid container item xs={8} className={`${cls.placedSpot} ${cls.secondPlace}`} justifyContent="center" alignItems="center">
              <Grid item>
                <UserAvatar size={60} src={u2.avatarURL} name={u2.name} badgeSrc={u2.badgeURL} nameVariant="h5" />
              </Grid>
              <Grid item>&nbsp;🥈&nbsp;</Grid>
              <Grid item className={cls.points}>{u2.points}</Grid>
            </Grid>
            <Grid container item xs={5} className={`${cls.placedSpot} ${cls.thirdPlace}`} justifyContent="center" alignItems="center">
              <Grid item>
                <UserAvatar size={30} src={u3.avatarURL} name={u3.name} badgeSrc={u3.badgeURL} nameVariant="subtitle1" />
              </Grid>
              <Grid item>&nbsp;🥉&nbsp;</Grid>
              <Grid item className={cls.points}>{u3.points}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={6} xs={12} className={cls.section} justifyContent="center" alignItems="stretch">
          <Grid container item xs={12} justifyContent="center" alignItems="stretch" className={cls.features}>
            <Grid item xs={12} className={cls.header}>
              <BTypography variant="h3"><b>Features/Bugs</b></BTypography>
            </Grid>
            <Grid item xs={12}>
              <SortableList
                perPage={10}
                headers={["Description", "User", "Date", "Points"]}
                items={features.map(x => {
                  let u = users.filter(y => y.id === x.userId)[0];
                  return [
                    x.name,
                    [<Grid sortval={u.name} key={u.name} className={cls.listUser}><UserAvatar size={25} src={u.avatarURL} name={u.name} badgeSrc={u.badgeURL}  srcSize="small" /></Grid>],
                    x.date,
                    x.points
                  ]
                })}
                spacing={[5,3,2,2]}
                defaultSortCol={2}
                defaultSortDir={2}
                itemPadding={"0.2rem"}
                fixedHeight={"50vh"}
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      {party && (<Fireworks></Fireworks>)}
    </Grid>
  );
}
