import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, FormHelperText } from "@mui/material";
import { colors, BTypography, NormalButton, FormTextField, FormField, CountrySelect } from "bild-ui";

const useStyles = makeStyles({
  specialWrapper: { padding: "1rem 0 2rem 0" },
  submitButton: { margin: "0.5rem", padding: "1rem" },
  formWrapper: { padding: "0.5rem", borderRadius: "0.25rem", background: colors.offWhite },
  fieldWrapper: {
    padding: "0.5rem",
    " & input, & .MuiAutocomplete-inputRoot ": {
      background: colors.white
    }
  }
});

function UserAdminProfile({ user, userProfile, setEmailOpen, overview, setOrgOpen, handleSubmit }) {
  const cls = useStyles();
  const [form, setForm] = useState({
    id: user.id,
    fullName: userProfile.fullName,
    partnerName: userProfile.partnerName,
    name: userProfile.name,
    phone: userProfile.phone,
    addressLine1: userProfile.addressLine1,
    addressLine2: userProfile.addressLine2,
    city: userProfile.city,
    state: userProfile.state,
    zip: userProfile.zip,
    country: userProfile.country
  });

  function _handleChange(field, value) {
    setForm({
      ...form,
      [field]: value
    });
  }

  function _handleSubmit() {
    // NOTE: May want validation in the future
    handleSubmit(form);
  }

  function makeField(label, field) {
    return (
      <Grid item sm={6} xs={12} className={cls.fieldWrapper}>
        <FormTextField
          label={label}
          placeholder=" "
          value={form[field]}
          setValue={newValue => {
            _handleChange(field, newValue);
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} className={cls.specialWrapper}>
        <Grid container item sm={6} xs={12} justifyContent="center" alignItems="flex-end" className={cls.fieldWrapper}>
          <Grid item xs>
            <FormTextField label="Username/Email:" value={user.username} disabled={true} />
          </Grid>
          <NormalButton
            component={Grid}
            item
            variant="primary"
            color={colors.bildBlue}
            labelColor={colors.white}
            hoverColor={colors.darkBildBlue}
            className={cls.submitButton}
            onClick={() => {
              setEmailOpen(true);
            }}
          >
            Change
          </NormalButton>
        </Grid>
        <Grid container item sm={6} xs={12} justifyContent="center" alignItems="flex-end" className={cls.fieldWrapper}>
          <Grid item xs>
            <FormTextField
              label="Primary Organization:"
              value={overview.primaryOrganization && overview.primaryOrganization.organizationName}
              disabled={true}
            />
          </Grid>
          <NormalButton
            component={Grid}
            item
            variant="primary"
            color={colors.bildBlue}
            labelColor={colors.white}
            hoverColor={colors.darkBildBlue}
            className={cls.submitButton}
            onClick={() => {
              setOrgOpen(true, 1, overview.primaryOrganization);
            }}
          >
            Change
          </NormalButton>
        </Grid>
        <Grid container item sm={6} xs={12} justifyContent="center" alignItems="flex-end" className={cls.fieldWrapper}>
          <Grid item xs>
            <FormTextField
              label="MCCEE Cohort Organization:"
              value={userProfile.mcceeCohortOrganization && userProfile.mcceeCohortOrganization.organizationName}
              disabled={true}
            />
          </Grid>
          <NormalButton
            component={Grid}
            item
            variant="primary"
            color={colors.bildBlue}
            labelColor={colors.white}
            hoverColor={colors.darkBildBlue}
            className={cls.submitButton}
            onClick={() => {
              setOrgOpen(true, 2, userProfile.mcceeCohortOrganization ? userProfile.mcceeCohortOrganization : {});
            }}
          >
            Change
          </NormalButton>
        </Grid>
        <Grid container item sm={6} xs={12} justifyContent="center" alignItems="flex-end" className={cls.fieldWrapper}>
          <Grid item xs>
            <FormTextField
              label="BILD Primary Organization:"
              value={userProfile.bildPrimaryOrganization && userProfile.bildPrimaryOrganization.organizationName}
              disabled={true}
            />
          </Grid>
          <NormalButton
            component={Grid}
            item
            variant="primary"
            color={colors.bildBlue}
            labelColor={colors.white}
            hoverColor={colors.darkBildBlue}
            className={cls.submitButton}
            onClick={() => {
              setOrgOpen(true, 3, userProfile.bildPrimaryOrganization ? userProfile.bildPrimaryOrganization : {});
            }}
          >
            Change
          </NormalButton>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.formWrapper}>
        {makeField("Formal Name (Name on Certificate)", "fullName")}
        {makeField("Partner Name (Name on Certificate)", "partnerName")}
        {makeField("Name", "name")}
        {makeField("Phone", "phone")}
        {makeField("Address Line 1", "addressLine1")}
        {makeField("Address Line 2", "addressLine2")}
        {makeField("City", "city")}
        {makeField("State / Region", "state")}
        {makeField("Postal Code", "zip")}
        <Grid item sm={6} xs={12} className={cls.fieldWrapper}>
          <FormField
            label="Country"
            child={
              <CountrySelect
                value={form.country}
                setValue={d => {
                  setForm({ ...form, country: d });
                }}
              />
            }
          />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
          <Grid item>
            <NormalButton
              variant="primary"
              color={colors.bildBlue}
              labelColor={colors.white}
              hoverColor={colors.darkBildBlue}
              className={cls.submitButton}
              onClick={_handleSubmit}
            >
              Update Profile
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserAdminProfile;
