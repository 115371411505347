import { createTheme } from "@mui/material/styles";

const fontFamilies = "proxima-nova, Helvetica, Arial, sans-serif";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    }
  },
  typography: {
    allVariants: {
      fontFamily: `${fontFamilies}`
    },
    useNextVariants: true,
    fontFamily: `${fontFamilies}`,
    fontSize: 14,
    button: {
      fontSize: "1rem",
      fontWeight: "700",
      lineHeight: "1.5em",
      textTransform: "none"
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0 !important"
        },
        input: {
          padding: "1rem 0.75rem"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: "1rem 4rem 1rem 1rem !important"
        }
      }
    },
    MuiMenuItem: {
      fontFamily: `${fontFamilies}`
    },
    MuiButtonBase: {
      root: {
        fontFamily: `${fontFamilies}`,
      },
      defaultProps: {
        disableRipple: true
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFF",
          fontSize: "1rem",
          fontFamily: `${fontFamilies}`,
          textTransform: "none",
          "&.link-button": {
            color: "#616161",
            padding: "0",
            lineHeight: "unset",
            minHeight: "0",
            fontWeight: "normal",
            "&:hover": {
              textDecoration: "underline",
              backgroundColor: "transparent"
            }
          },
          "&:hover": {
            backgroundColor: "#0000000a",
          },
          "&.button2": {
            fontSize: "1.15rem",
            fontWeight: "bold"
          }
        }
      },
      defaultProps: {
        disableRipple: true
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "transparent"
        },
      },
      defaultProps: {
        TabIndicatorProps: { style: { backgroundColor: 'transparent' } },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "6px 12px !important",
          minWidth: "160px",
          opacity: "0.6",
          '&.Mui-selected': {
            opacity: "1",
            color: "#000000de",
          }
        },
        textColorInherit: {
          opacity: ".4"
        },
        defaultProps: {
          disableRipple: true
        },
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#717171",
          color: "#FFF"
        },
        colorSecondary: {
          backgroundColor: "#c70000"
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: "1rem 1.5rem"
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem"
        },
        label: {
          fontSize: "1rem"
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "#006dc3"
          },
          "&.Mui-active": {
            color: "#2196f3",
            fontWeight: "bold"
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
       colorSecondary: {
          "&$checked": {
            color: "#006DC3"
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        avatar: {
          height: "32px !important",
          width: "32px !important",
          fontSize: "1rem !important",
          marginLeft: "-1px"
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontSize: "7rem",
            lineHeight: "1.14286em"
          }
        },
        {
          props: { variant: 'h2' },
          style: {
            fontSize: "3.5rem",
            lineHeight: "1.30357em"
          }
        },
        {
          props: { variant: 'h3' },
          style: {
            fontSize: "2.8125rem",
            lineHeight: "1.13333em"
          }
        },
        {
          props: { variant: 'h4' },
          style: {
            lineHeight: "1.20588em",
            fontSize: "1.429rem",
            fontWeight: "300",
          }
        },
        {
          props: { variant: 'h5' },
          style: {
            fontSize: "1.429rem",
            fontWeight: "700",
            lineHeight: "1.35417em"
          }
        },
        {
          props: { variant: 'h6' },
          style: {
            fontSize: "1.143rem",
            fontWeight: "700",
            lineHeight: "1.16667em"
          }
        },
        {
          props: { variant: 'subtitle1' },
          style: {
            fontSize: "1rem",
            fontWeight: "700",
            lineHeight: "1.5em"
          }
        },
        {
          props: { variant: 'body2' },
          style: {
            fontSize: "1rem",
            fontWeight: "400",
            lineHeight: "1.5em"
          }
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: "1rem",
            fontWeight: "400",
            lineHeight: "1.5em"
          }
        },
        {
          props: { variant: 'caption' },
          style: {
            fontSize: "0.714rem",
            fontWeight: "400",
            lineHeight: "1.375em"
          }
        },
        {
          props: { variant: 'button' },
          style: {
            fontSize: "1rem",
            fontWeight: "700",
            lineHeight: "1.5em",
            textTransform: "none"
          },
        },
      ],
    }
  },
});


export default theme;
