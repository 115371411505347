import React, { useEffect, useState } from "react";
import { checkAdmin } from "bild-data/user";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { Grid, Dialog } from "@mui/material";
import { colors, UserAvatar, BTypography, NormalButton, FilterTextField, SortableList, PopTip, SupportDialog } from "bild-ui";

const isAdmin = checkAdmin();

const useStyles = makeStyles({
  header: {
    background: colors.white,
    padding: "1rem",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 100
  },
  body: { padding: "0rem" },
  title: { paddingLeft: "0.25rem" },
  avatarName: { paddingLeft: "0.25rem" },
  reissueButton: { paddingLeft: "1rem" },
  importedNote: { padding: "0 1.15rem 0 2.1rem", fontWeight: "bold", color: colors.darkPurple },
  confirmDialog: { padding: "1rem" },
  confirmButton: { margin: "0.5rem 0", padding: "1rem 2rem" },
  confirmTitle: { paddingLeft: "3rem" },
  certTitle: { padding: "1rem 2rem" },
  certSubTitle: { fontStyle: "italic", padding: "0.25rem 0 1rem 0" }
});

function AllUserCertificates({ certificates, removeCert, processBatchCertificates, hideHeader, hideUser, perPage }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [certRows, setCertRows] = useState([]);
  const [confirmUserCert, setConfirmUserCert] = useState({});
  const [supportCertName, setSupportCertName] = useState(null);

  useEffect(() => {
    setCertRows(
      certificates
        .filter(x => {
          if (filter.length > 0) {
            return (
              x.name.toLowerCase().includes(filter.toLowerCase()) ||
              x.user.name.toLowerCase().includes(filter.toLowerCase()) ||
              x.completedOn.toLowerCase().includes(filter.toLowerCase())
            );
          } else {
            return 1;
          }
        })
        .map(x => [
          x.completedOn,
          [<UserAvatar size={40} src={x.user.avatarURL} name={x.user.name} srcSize="small" sortval={x.user.name} key={x.user.name} badgeSrc={x.user.badgeURL}  />],
          [
            <Grid container sortval={x.name} alignItems="center" key={"00"}>
              <Grid item>
                <BTypography variant="body1" className={cls.title}>
                  {x.name}
                </BTypography>
              </Grid>
            </Grid>
          ],
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              {x.processed && !x.processingError && (
                <NormalButton
                  onClick={() => {
                    downloadRequest(x.downloadURL, x.name);
                  }}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                >
                  <i className="fas fa-download" />
                </NormalButton>
              )}
              {x.processed && x.processingError && (
                <PopTip text="There was an error processing this certificate. Please contact support.">
                  <NormalButton
                    variant="primary"
                    color={colors.darkRed}
                    hoverColor={colors.darkerRed}
                    labelColor={colors.white}
                    onClick={()=>{setSupportCertName(x.name)}}
                  >
                    <i className="far fa-exclamation-triangle" />
                  </NormalButton>
                </PopTip>
              )}
              {!x.processed && (
                <BTypography variant="body1">
                  Download link will <br /> be available soon.
                </BTypography>
              )}
            </Grid>
            {isAdmin && !x.manuallyImported && (
              <Grid item className={cls.reissueButton}>
                <NormalButton
                  variant="secondary"
                  color={colors.darkOrange}
                  hoverColor={colors.darkOrange}
                  labelColor={colors.white}
                  onClick={() => {
                    setConfirmUserCert(x);
                  }}
                >
                  <i className="fas fa-recycle" />
                </NormalButton>
              </Grid>
            )}
            {isAdmin && x.manuallyImported && (
              <PopTip text={"Manually Imported Certificates cannot be re-issued."}>
                <Grid item className={cls.importedNote}>
                  <i className="fad fa-exclamation-square fa-2x" />
                </Grid>
              </PopTip>
            )}
          </Grid>
        ])
    );
  }, [certificates, filter]);

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.wrapper}>
      {!hideHeader && (
        <Grid container item xs={12} className={cls.header} alignItems="center" justifyContent="space-between">
          <Grid item sm={6} xs={12}>
            <BTypography variant="h4">All User Certificates</BTypography>
          </Grid>
          <Grid container item sm={6} xs={12} alignItems="center" justifyContent="flex-end">
            {isAdmin && processBatchCertificates && (
              <Grid item>
                <NormalButton
                  variant="secondary"
                  color={colors.darkerPurple}
                  hoverColor={colors.darkPurple}
                  labelColor={colors.white}
                  onClick={processBatchCertificates}
                >
                  Generate Batch &nbsp; <i className="fas fa-oven" />
                </NormalButton>
              </Grid>
            )}
            <Grid item>
              <FilterTextField
                value={filter}
                placeholder="Filter"
                onChange={e => setFilter(e.target.value)}
                onClear={() => {
                  setFilter("");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} className={cls.body}>
        <SortableList
          perPage={perPage ? perPage : 10}
          filter={filter}
          headers={["Date", "User", "Certificate", ""]}
          items={certRows}
          itemPadding={"0.5rem 1rem"}
          spacing={hideUser ? null : [2, 4, 4, 2]}
          defaultSortCol={0}
          defaultSortDir={2}
          emptyMessage={
            <>
              No Certificates, yet! <span className="fad fa-file-certificate" />
            </>
          }
          hiddenColumns={hideUser ? [1] : null}
        />
      </Grid>
      <Dialog
        open={confirmUserCert.id ? true : false}
        onClose={() => {
          setConfirmUserCert({});
        }}
        maxWidth="md"
      >
        <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
          <Grid container item xs={12}>
            {confirmUserCert.user && (
              <Grid container justifyContent="flex-start" alignItems="center">
                <UserAvatar size={40} src={confirmUserCert.user.avatarURL} name={confirmUserCert.user.name} badgeSrc={confirmUserCert.user.badgeURL}  srcSize="small" nameVariant="h6" />
                <Grid item>
                  <BTypography variant="h6" className={cls.confirmTitle}>
                    {confirmUserCert.name}
                  </BTypography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <BTypography variant="body1" className={cls.certSubTitle}>
              Void certificate and generate a new one using current template.
            </BTypography>
          </Grid>
          <Grid container item xs={12} alignItems="center" justifyContent="space-evenly">
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="secondary"
                color={colors.white}
                labelColor={colors.gray}
                borderColor={colors.gray}
                hoverColor={colors.darkGray}
                className={cls.confirmButton}
                onClick={() => {
                  setConfirmUserCert({});
                }}
              >
                Cancel
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="primary"
                color={colors.darkRed}
                labelColor={colors.white}
                hoverColor={colors.darkerRed}
                className={cls.confirmButton}
                onClick={() => {
                  removeCert(confirmUserCert.id);
                  setConfirmUserCert({});
                }}
              >
                Confirm Reissue &nbsp; <i className="fas fa-recycle" />
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <SupportDialog
        open={supportCertName}
        closeDialog={() => {
          setSupportCertName(null);
        }}
        options={[`I am experiencing a problem with a certificate: ${supportCertName}.`]}
      />
    </Grid>
  );
}

export default AllUserCertificates;
