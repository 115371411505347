import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { colors } from 'bild-ui';
import { useWidth } from 'bild-utils';

const useStyles = makeStyles({
  viewer: { background: colors.darkGray, overflow: "auto" },
  pdfPageCanvas: { padding: "1rem 0.5rem" },
  pdfTextLayer: {},
  hidden: { display: "none" }
});


export default function PdfViewer({ url, onDocLoad }) {
  const cls = useStyles();
  const viewerRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const [pdfjsLib, setPdfjsLib] = useState(null);
  const [pdfjsWorker, setPdfjsWorker] = useState(null);

  useEffect(() => {
    // NOTE: PDFJS has some significant issues and needs to be loaded differently than other libraries. If it is imported normally the entire app does not load in Safari 14 
    import("pdfjs-dist/build/pdf")
      .then((module) => {
        setPdfjsLib(module);
      })
      .catch((err) => {
        console.log(err);
      });

    import("pdfjs-dist/build/pdf.worker.entry")
      .then((module) => {
        setPdfjsWorker(module);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function _onDocLoad() {
    setLoaded(true);
    onDocLoad();
  }

  useEffect(() => {

    if (pdfjsLib) {
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      function renderPage(pdf, pageNumber, canvas, textLayer) {
        pdf.getPage(pageNumber).then(function(page) {
          let viewport = page.getViewport({ scale: 2 }); // Keep at 2 for sharper document
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvas.style.height = "100%";
          canvas.style.width = "100%";

          page.render({canvasContext: canvas.getContext('2d'), viewport: viewport}).promise.then(function() {
            return page.getTextContent();
          }).then(function(textContent) {
            // NOTE: meb 06192023 -- COMMENTED OUT FOR NOW
            // // Assign CSS to the textLayer element
            // textLayer.style.left = canvas.offsetLeft + 'px';
            // textLayer.style.top = canvas.offsetTop + 'px';
            // textLayer.style.height = canvas.offsetHeight + 'px';
            // textLayer.style.width = canvas.offsetWidth + 'px';
      
            // // Pass the data to the method for rendering of text over the pdf canvas.
            // pdfjsLib.renderTextLayer({
            //   textContentSource: textContent,
            //   pageIndex: page.pageIndex,
            //   container: textLayer,
            //   viewport: viewport,
            //   textDivs: [],
            //   enhanceTextSelection: true,
            // });
          });
        });
      }

      if (viewerRef && viewerRef.current) {
        pdfjsLib.getDocument(url).promise.then(function(pdf) {
          for(let page = 1; page <= pdf._pdfInfo.numPages; page++) {
            let wrapper = document.createElement('div');
            wrapper.className = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12";
            let canvas = document.createElement('canvas');
            canvas.className = cls.pdfPageCanvas;
            wrapper.appendChild(canvas);
            let textLayer = document.createElement('div');
            textLayer.className = cls.pdfTextLayer;
            // wrapper.appendChild(textLayer);
            viewerRef.current.appendChild(wrapper);
            renderPage(pdf, page, canvas, textLayer);
          }
          _onDocLoad();
        });
      }
    }
  }, [url, viewerRef, pdfjsLib]);

  return (
    <Grid container item xs={12} className={!loaded ? cls.hidden : ""}>
      <Grid container item xs={12} ref={viewerRef} className={cls.viewer} alignItems="center" justifyContent="center"/>
    </Grid>
  );
}
