import React, { useState, useEffect } from "react";
import { colors, SortableList, List, Blink, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Select, MenuItem } from "@mui/material";

const useStyles = makeStyles({
  task: { border: `2px dashed ${colors.veryLightGreen}`, borderRadius: "4px", padding: "1rem", background: colors.extremelyLightGreen },
  header: { fontWeight: "bold" },
  row: { padding: "0.25rem 1rem" },
  label: { textAlign: "right", paddingRight: "1rem" },
  input: {
    width: "100%",
    minHeight: "3.5rem",
    padding: "0 1rem",
    background: colors.white,
    border: `1px solid ${colors.black}`,
    borderRadius: "4px"
  },
  textarea: {
    padding: "1rem"
  }
});

function FundraisingTask({ defDueDate, defPurpose, defNote, updateTask, setIsValid }) {
  const cls = useStyles();
  const [date, setDate] = useState("");
  const [purpose, setPurpose] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    // check validity of fields
    if (typeof setIsValid === "function") {
      if ((date !== "" && purpose !== "" && note !== "") || (date === "" && purpose === "" && note === "")) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
    // Update parent fields on change
    _updateTask();
  }, [date, purpose, note]);

  function _clear() {
    setDate("");
    setPurpose("");
    setNote("");
  }

  function _updateTask() {
    let task = { date: date, note: note, purpose: purpose };
    updateTask(task);
  }

  return (
    <Grid container item xs={12} className={cls.task}>
      <Grid container item xs={12} className={`${cls.row}`}>
        <Grid item xs>
          <BTypography variant="h5" className={cls.header}>
            Optional New Task
          </BTypography>
        </Grid>
        <Grid item>
          <NormalButton
            onClick={_clear}
            variant="secondary"
            color={colors.white}
            hoverColor={colors.darkerGray}
            labelColor={colors.darkerGray}
            borderColor={colors.darkerGray}
          >
            <i className="fal fa-redo" /> &nbsp; reset
          </NormalButton>
        </Grid>
      </Grid>
      <Grid container item md={6} sm={12} xs={12} className={`${cls.row}`}>
        <Grid item sm={4} xs={12} className={cls.label}>
          <BTypography variant="h6">Task Due Date</BTypography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <input
            className={cls.input}
            type="date"
            value={date}
            onChange={e => {
              setDate(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item md={6} sm={12} xs={12} className={`${cls.row}`}>
        <Grid item sm={4} xs={12} className={cls.label}>
          <BTypography variant="h6">Task Purpose</BTypography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Select
            variant="outlined"
            value={purpose}
            onChange={e => {
              setPurpose(e.target.value);
            }}
            className={cls.input}
          >
            <MenuItem value="ImpactCultivation">Impact/Cultivation</MenuItem>
            <MenuItem value="Solicitation">Solicitation</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={`${cls.row}`}>
        <Grid item sm={4} xs={12} className={cls.label}>
          <BTypography variant="h6">Task Note</BTypography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <textarea
            className={`${cls.input} ${cls.textarea}`}
            value={note}
            onChange={e => {
              setNote(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FundraisingTask;
