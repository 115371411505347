import React, { useState, useEffect } from "react";
import { ENUMS } from "bild-data";
import { colors, BTypography, NormalButton, AssessButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid, TextField, Select, MenuItem } from "@mui/material";

const useStyles = makeStyles({
  row: { padding: "1rem 0", justifyContent: "center" },
  label: { paddingRight: "1rem" },
  textfield: { width: "100%", background: colors.veryLightGray },
  select: { width: "100%" }
});

function AssessmentChangeLevelDialog({ open, onClose, userLearningItem, changeLevel }) {
  const cls = useStyles();
  const [newLevel, setNewLevel] = useState("");
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (newLevel !== "") {
      let minLevelValue = _getLevelValue(userLearningItem.minimumCompletionLevel.id);
      let newLevelValue = _getLevelValue(newLevel);
      if (newLevelValue >= minLevelValue) {
        setReady(true);
      }
    } else {
      setReady(false);
    }
  }, [newLevel]);

  function _changeLevel() {
    if (ready) {
      changeLevel(userLearningItem.id, newLevel);
      setNewLevel("");
      onClose();
    }
  }

  function _getLevelValue(id) {
    let val = 0;
    let levels = Object.keys(ENUMS.COMPLETION_LEVELS);
    for (let i = 0; i < levels.length; i++) {
      let level = ENUMS.COMPLETION_LEVELS[levels[i]];
      if (level.ID === id) {
        val = level.VALUE;
      }
    }
    return val;
  }

  if (open) {
    function getLevels() {
      let levels = Object.keys(ENUMS.COMPLETION_LEVELS);
      let items = [];
      for (let i = 0; i < levels.length; i++) {
        let level = ENUMS.COMPLETION_LEVELS[levels[i]];
        let disabled = userLearningItem.completionLevel.id === level.ID || _getLevelValue(userLearningItem.minimumCompletionLevel.id) > level.VALUE;
        items.push(
          <MenuItem value={level.ID} key={i} disabled={disabled}>
            {level.NAME}
          </MenuItem>
        );
      }
      return items;
    }

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <BTypography variant="h5">Completion Level</BTypography>
            </Grid>
            <Grid container item xs={12} className={cls.row}>
              <Grid item xs={3} className={cls.label}>
                Minimum
              </Grid>
              <Grid item xs>
                <TextField disabled={true} value={userLearningItem.minimumCompletionLevel.name} variant="outlined" className={cls.textfield} />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.row}>
              <Grid item xs={3} className={cls.label}>
                Current
              </Grid>
              <Grid item xs>
                <TextField disabled={true} value={userLearningItem.completionLevel.name} variant="outlined" className={cls.textfield} />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.row}>
              <Grid item xs={3} className={cls.label}>
                Change to Level
              </Grid>
              <Grid item xs>
                <Select
                  variant="outlined"
                  value={newLevel}
                  onChange={e => {
                    setNewLevel(e.target.value);
                  }}
                  className={cls.select}
                >
                  {getLevels()}
                </Select>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={cls.row}>
              <AssessButton enabled={ready} text={"Change Level"} onClick={_changeLevel} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export default AssessmentChangeLevelDialog;
