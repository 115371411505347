import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const useStyles = makeStyles({
  closeButton: {
    color: "#f50057"
  },
  submitButton: {
    color: "#3f51b5"
  }
});

function AdmissionsCommendDialog({ availablePrograms, open, onSubmit, onCancel }) {
  const cls = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [degreeId, setDegreeId] = useState(-1);
  const [commended, setCommended] = useState(false);

  function checkDisabled() {
    const validName = name && name.length > 0;
    const rfc2822Address = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
    const validEmail = email && email.length > 0 && email.match(rfc2822Address);

    if (validName && validEmail && degreeId && degreeId >= 0) {
      return false;
    } else {
      return true;
    }
  }

  function submit() {
    if (onSubmit && !checkDisabled()) {
      onSubmit(name, email, degreeId);
    }
  }

  const programOptions = [
    <option value={-1} key={-1}>
      Select a degree...
    </option>
  ];
  const degreeList = availablePrograms;
  for (let i = 0; i < degreeList.length; i++) {
    const d = degreeList[i];
    programOptions.push(
      <option value={d.programId} key={d.programId}>
        {d.programClassName}
      </option>
    );
  }

    return (
      <Dialog
        open={open === true}
        onClose={onCancel}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Commend a Student</DialogTitle>
        <DialogContent>
          <Grid container wrap="wrap" justifyContent="space-around">
            <Grid item md={4} xs={12} className="dialog-field">
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                variant="outlined"
                value={name}
                disabled={commended}
                onChange={e => setName(e.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item md={4} xs={12} className="dialog-field">
              <TextField
                label="Email"
                fullWidth
                margin="dense"
                variant="outlined"
                value={email}
                disabled={commended}
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={12} className="dialog-field">
              <TextField
                select
                label="Degree Program"
                fullWidth
                value={degreeId}
                SelectProps={{
                  native: true
                }}
                margin="dense"
                variant="outlined"
                onChange={e => setDegreeId(e.target.value)}
              >
                {programOptions}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={cls.closeButton} onClick={onCancel}>
            Cancel
          </Button>
          <Button className={cls.submitButton} disabled={checkDisabled()} onClick={submit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default AdmissionsCommendDialog;
