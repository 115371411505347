import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { courseData, diskData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import AuditAntiochCourse from "./auditAntiochCourse";
import { loadAdminUser } from "bild-data/admin";
import queryString from "query-string";

export default function AuditAntiochCourseLoader({}) {
  const { course_id, user_id, competency_set_id } = useParams();
  const userOverview = userData.getOverview();
  const userId = user_id || userOverview.user.id;
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(null);
  const [callingUser, setCallingUser] = useState(userData.getOverview() || {});
  const [docId, setDocId] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [competencyProofId, setCompetencyProofId] = useState(null);

  useEffect(() => {
    setPageTitle("Audit Antioch Course");
    const searchParams = queryString.parse(location.search);
    if (searchParams && searchParams.competency_proof_id) {
      setCompetencyProofId(parseInt(searchParams.competency_proof_id));
    }

    function _setData(d) {
      setCourse(d);
      setLoading(false);
    }

    courseData.loadFromUserAndCompetencySet(course_id, user_id, competency_set_id, _setData, e => {
      setError(e.response.data.error);
    });
    
    // Load user_id and then Load files/folders from disk API
    userData.loadOverview(
      o => setCallingUser(o.user),
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  useEffect(() => {
    if (!user) {
      loadAdminUser(userId, d => {
        setUser(d.user);
      });
    }
  }, [userId, user]);

  function _setDocId(id) {
    setDocId(id);
    if (id) {
      // always pull new data and don't trust the local copy
      setFileLoading(true);
      diskData.loadArtifact(
        id,
        data => {
          setFileLoading(false);
        },
        e => {
          console.log("Unable to download file from server.");
          setFileLoading(false);
        }
      );
    }
  }

  if (!course || loading || error) return <LoadingScreen error={error} />;
  return (
    <AuditAntiochCourse
      callingUser={callingUser}
      studentUser={user}
      course={course}
      docId={docId}
      setDocId={_setDocId}
      fileLoading={fileLoading}
      scrollToCompetencyProofId={competencyProofId}
    />
  );
}
