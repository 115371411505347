import React, { useEffect, useState } from "react";
import { setPageTitle } from "bild-utils";
import { loadUserRecords } from "bild-data/user";
import { LoadingScreen } from "bild-ui";
import UserRecords from "./userRecords.js";

function UserRecordsLoader({}) {
  setPageTitle("Academic Records");
  const [records, setRecords] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    function _setRecords(data) {
      setRecords(data.records);
    }

    loadUserRecords(_setRecords, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (!records || error) return <LoadingScreen error={error} />;
  return <UserRecords records={records} />;
}

export default UserRecordsLoader;
