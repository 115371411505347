import React, { useState, useEffect } from "react";
import { colors, BTypography, NormalButton, CountrySelect, FormField, FormTextField, FormAutocompleteField } from "bild-ui";
import { AddUserDialogLoader } from "modules";
import { isValidEmail } from "bild-utils";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Grid, Button, MenuItem, TextField } from "@mui/material";

const useStyles = makeStyles({
  wrapper: { paddingBottom: "1rem" },
  title: { paddingBottom: "0.5rem" },
  field: { width: "100%" },
  hintWrapper: { paddingTop: "1rem" },
  hint: { fontStyle: "italic", paddingRight: "0.5rem" },
  tab: { minHeight: "50vh" },
  buttonWrapper: { paddingTop: "1rem" },
  button: { padding: "1rem 2rem" },
  cancelButton: { border: `1px solid ${colors.lightGray}` },
  errors: { backgroundColor: colors.lightRed, marginBottom: "0.5rem", padding: "0.5rem" },
  error: { color: colors.red },
  errorBullet: { fontSize: ".3rem", padding: "0 0.5rem 0.5rem 0" },
  addressField: { paddingRight: "0.5rem" },
  submitWrapper: { paddingTop: "1rem" },
  submit: { padding: "1rem 0" }
});

function AddOrganizationDialog({ open, closeDialog, orgs, users, setUsers, createOrganization, organizationTypes }) {
  const cls = useStyles();
  const [state, setState] = useState({
    errors: [],
    name: "",
    parentOrg: null,
    primaryUser: null,
    organizationType: null,
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: ""
  });
  const [userDialog, setUserDialog] = useState(false);
  const [defaultOrg, setDefaultOrg] = useState(null);
  const [tab, setTab] = useState(0);

  function _createOrganization() {
    // First check everything is in order, and then createUser
    let newError = [];

    // Check form for mistakes
    if (state.name === "" || state.name === null) {
      newError.push({ pos: 0, val: "Name needs to be filled in." });
    }
    if (state.parentOrg === "" || state.parentOrg === null) {
      newError.push({ pos: 1, val: "Parent Organization needs to be selected." });
    }
    if (state.primaryUser === "" || state.primaryUser === null) {
      newError.push({ pos: 2, val: "Primary User needs to be selected." });
    }
    if (state.city === "" || state.city === null) {
      newError.push({ pos: 5, val: "City needs to be filled in." });
    }
    if (state.state === "" || state.state === null) {
      newError.push({ pos: 6, val: "State/Province needs to be filled in." });
    }
    if (state.country === "" || state.country === null) {
      newError.push({ pos: 8, val: "Country needs to be filled in." });
    }

    // Check if the basics are valid
    if (newError.length <= 0) {
      // Create/Update the user
      createOrganization(state);
      _reset();
      closeDialog();
    } else {
      setState({ ...state, errors: newError });
    }
  }

  function _reset() {
    setState({
      errors: [],
      name: "",
      parentOrg: null,
      primaryUser: null,
      organizationType: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      phone: ""
    });
    setTab(0);
    setDefaultOrg(null);
  }

  function _cancel() {
    _reset();
    closeDialog();
  }

  function _setUsers(data) {
    setUserDialog();
    setUsers(data);
  }

  return (
    <>
      <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth={true}>
        <DialogContent>
          <Grid container justifyContent="flex-start" alignItems="center" className={cls.wrapper} direction="column">
            <Grid container item xs={12} justifyContent="flex-start" alignItems="center" direction="row">
              <Grid item xs={12} className={cls.title}>
                <BTypography variant="h3">Add Organization</BTypography>
              </Grid>
              {state.errors.length > 0 && (
                <Grid item xs={12} className={cls.errors}>
                  {state.errors.map((x, i) => (
                    <Grid container item xs={12} key={i} className={cls.error} justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        <i className={`${cls.errorBullet} fas fa-circle`} />
                      </Grid>
                      <Grid item>{x.val}</Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            {tab === 0 && (
              <Grid container item xs={12} className={cls.tab} justifyContent="flex-start" alignItems="center" direction="column">
                <FormTextField
                  label="Name"
                  value={state.name}
                  setValue={x => {
                    setState({ ...state, name: x });
                  }}
                  error={state.errors.some(x => x.pos === 0)}
                  required={true}
                />
                <FormAutocompleteField
                  label="Parent Organization"
                  value={state.parentOrg}
                  setValue={x => {
                    setState({ ...state, parentOrg: x });
                    setDefaultOrg(x);
                  }}
                  items={orgs.map(x => {
                    return { id: x.organizationId, name: x.organizationName };
                  })}
                  error={state.errors.some(x => x.pos === 1)}
                  required={true}
                />
                <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.hintWrapper}>
                  <Grid item xs>
                    <BTypography className={cls.hint}>
                      If you need to create a new user for this new organization, please create them by clicking "Create User" and then select them in
                      the list below.
                    </BTypography>
                  </Grid>
                  <Grid item>
                    <NormalButton
                      onClick={() => {
                        setUserDialog(true);
                      }}
                      variant="simple"
                      color={colors.bildBlue}
                      labelColor={colors.white}
                    >
                      Create User
                    </NormalButton>
                  </Grid>
                </Grid>
                <FormAutocompleteField
                  label="Primary User"
                  value={state.primaryUser}
                  setValue={x => {
                    setState({ ...state, primaryUser: x });
                  }}
                  items={users}
                  error={state.errors.some(x => x.pos === 2)}
                  required={true}
                  showAvatar={true}
                  loading={users.length < 1}
                />
                {organizationTypes && organizationTypes.length > 0 && (
                  <FormAutocompleteField
                    label="Organization Type"
                    value={state.organizationType}
                    setValue={x => {
                      setState({ ...state, organizationType: x });
                    }}
                    items={organizationTypes.map(x => {
                      return { id: x.ID, name: x.NAME };
                    })}
                    required={true}
                  />
                )}
              </Grid>
            )}
            {tab === 1 && (
              <Grid container item xs={12} className={cls.tab}>
                <Grid item xs={12} className={cls.title}>
                  <BTypography variant="h5">Mailing Address</BTypography>
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormTextField
                    label="Address Line 1"
                    value={state.addressLine1}
                    setValue={x => {
                      setState({ ...state, addressLine1: x });
                    }}
                  />
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormTextField
                    label="Address Line 2"
                    value={state.addressLine2}
                    setValue={x => {
                      setState({ ...state, addressLine2: x });
                    }}
                  />
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormTextField
                    label="City"
                    value={state.city}
                    setValue={x => {
                      setState({ ...state, city: x });
                    }}
                    error={state.errors.some(x => x.pos === 5)}
                    required={true}
                  />
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormTextField
                    label="State/Province"
                    value={state.state}
                    setValue={x => {
                      setState({ ...state, state: x });
                    }}
                    error={state.errors.some(x => x.pos === 6)}
                    required={true}
                  />
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormTextField
                    label="Postal Code"
                    value={state.zip}
                    setValue={x => {
                      setState({ ...state, zip: x });
                    }}
                  />
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormField
                    label="Country"
                    error={state.errors.some(x => x.pos === 8)}
                    required={true}
                    child={
                      <CountrySelect
                        value={state.country}
                        setValue={d => {
                          setState({ ...state, country: d });
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid container item sm={6} xs={12} className={cls.addressField}>
                  <FormTextField
                    label="Phone Number"
                    value={state.phone}
                    setValue={x => {
                      setState({ ...state, phone: x });
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {tab === 0 && (
              <Grid container item xs={12} justifyContent="flex-end" className={cls.buttonWrapper}>
                <Grid item>
                  <NormalButton
                    className={cls.button}
                    variant="secondary"
                    color={colors.white}
                    hoverColor={colors.darkerGray}
                    labelColor={colors.darkerGray}
                    borderColor={colors.darkerGray}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Mailing Address &nbsp; <i className="fal fa-arrow-right" />
                  </NormalButton>
                </Grid>
              </Grid>
            )}
            {tab === 1 && (
              <Grid container item xs={12} justifyContent="flex-start" className={cls.buttonWrapper}>
                <Grid item>
                  <NormalButton
                    className={cls.button}
                    variant="secondary"
                    color={colors.white}
                    hoverColor={colors.darkerGray}
                    labelColor={colors.darkerGray}
                    borderColor={colors.darkerGray}
                    onClick={() => {
                      setTab(0);
                    }}
                  >
                    <i className="fal fa-arrow-left" /> &nbsp; Back
                  </NormalButton>
                </Grid>
              </Grid>
            )}
            {tab === 1 && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center" direction="row" className={cls.submitWrapper}>
                <Grid item xs={12}>
                  <NormalButton
                    fullWidth
                    onClick={_createOrganization}
                    variant="simple"
                    color={colors.bildBlue}
                    labelColor={colors.white}
                    className={cls.submit}
                  >
                    Create Organization
                  </NormalButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <AddUserDialogLoader
        open={userDialog}
        onClose={() => {
          setUserDialog(false);
        }}
        defaultOrg={defaultOrg}
        success_cb={_setUsers}
      />
    </>
  );
}

export default AddOrganizationDialog;
