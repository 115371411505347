import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: { background: colors.white, padding: "1rem", minHeight: "100%" }
});

function BaseWrapper({ children }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default BaseWrapper;
