import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";

import BreadCrumbs from "./components/breadCrumbs.js";
import SearchBar from "presentational/input/searchBar.js";
import FileActionButton from "./components/fileActionButton.js";
import FolioDialog from "./components/folioDialog.js";
import FolioList from "./components/folioList.js";
import ContextMenu from "./components/contextMenu.js";
import UploadProgressModal from "./components/uploadProgressModal.js";
import FolioSelectorDialog from "./components/folioSelectorDialog.js";

import { DropboxFilePicker, GoogleDriveFilePicker } from "bild-vendors";

import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Disk from "./utils/disk.js";
import { useSearchRoot, useContextMenu, useRenameDialog, useAddFolderDialog, useMoveDialog } from "./utils/folioHooks";

import { useWidth } from "bild-utils";
import { PreviewFileDialog } from "modules/index.js";

const useStyles = makeStyles({
  container: props => ({
    position: "relative",
    height: "100%",
    padding: `${props.fullWidth ? "" : "1rem"}`,
    overflow: "hidden"
  }),
  containerSmall: {
    position: "relative",
    height: "100%",
    padding: "0",
    overflow: "scroll"
  },
  crumbsHeader: {
    marginBottom: "0.5rem"
  },
  verticalLeftContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "1rem"
  },
  verticalLeftContainerSmall: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "0.5rem"
  },
  fileListContainer: {
    flex: "1 1 auto",
    overflow: "auto"
  },
  fileListContainerSmall: {
    flex: "1 1 auto",
    paddingBottom: "200px"
  },
  verticalRightContainer: {
    "&::-webkit-overflow-scrolling": "touch",
    backgroundColor: "#FFF",
    padding: "1rem 0 0 0"
  },
  fileActionsContainer: {
    paddingTop: "1rem",
  },
  fileAction: {
    marginBottom: "0.5rem"
  },
  searchBar: {
    margin: "0 0.5rem 0 0",
    width: "100%"
  }
});

function Folio({ diskArrays, onUploadFiles, onRenameItem, onCreateFolder, onPreviewIntent, onDownloadItem, onDeleteItem, onMoveItem, hideMenu, previewDocId, previewDocLoading, previewFile, rootBreadcrumbTitle, fullWidth }) {
  const classes = useStyles({ fullWidth });

  // Build data structures from raw props.
  const { folders, files } = diskArrays || { folders: [], files: [] };
  const disk = useMemo(() => new Disk(folders, files), [folders, files]);

  // Custom hook to manage current root, and crumbs.
  const [contextRoot, setRoot, searchTerm, setSearchTerm, crumbs] = useSearchRoot(disk);
  const { folders: searchedFolders, files: searchedFiles } = disk.search(searchTerm, contextRoot);

  // Manage contextual menu.
  const [item, contextMenuProps, openContextMenu] = useContextMenu();

  // Manage rename-item dialog.
  const [renameItemDialogProps, onRenameIntent] = useRenameDialog(contextRoot, item, onRenameItem);
  // Manage add-new-folder dialog.
  const [addFolderDialogProps, onAddFolderIntent] = useAddFolderDialog(contextRoot, onCreateFolder);
  const dialogProps = renameItemDialogProps.open ? renameItemDialogProps : addFolderDialogProps;
  const showDialog = dialogProps.open === true;

  const [moveDialogProps, onMoveIntent] = useMoveDialog(item, onMoveItem);
  // We can't pass objects around on events, so we use id and type to lookup items to move
  function _onMoveItem(type, id, destination) {
    const item = type === "file" ? disk.fileIDLookup.get(Number(id)) : disk.folderIDLookup.get(Number(id));
    if (onMoveItem) {
      onMoveItem(item, destination);
    }
  }

  const w = useWidth();
  const xs = w === "xs";

  return (
    <Grid container className={xs ? classes.containerSmall : classes.container}>
      <Grid item xs className={xs ? classes.verticalLeftContainerSmall : classes.verticalLeftContainer}>
        <div>
          <Grid container justifyContent="space-between">
            <Grid item>
              <BreadCrumbs crumbs={crumbs} onGoToFolder={setRoot} onMoveItem={_onMoveItem} rootTitle={rootBreadcrumbTitle} />
            </Grid>

            {!hideMenu && (
              <Hidden mdUp>
                <Grid item>
                  <SearchBar className={classes.searchBar} onChange={setSearchTerm} value={searchTerm || ""} />
                </Grid>
                <Grid item xs={12}>
                  <FileActionButton onClick={onAddFolderIntent} variant="add-folder" />
                  <FileActionButton onUploadFiles={onUploadFiles} contextRoot={contextRoot} variant="upload-file" />
                  <GoogleDriveFilePicker onUploadFiles={onUploadFiles} contextRoot={contextRoot} />
                  <DropboxFilePicker onUploadFiles={onUploadFiles} contextRoot={contextRoot} />
                </Grid>
              </Hidden>
            )}
          </Grid>
        </div>

        <FolioList
          folders={searchedFolders}
          files={searchedFiles}
          contextRoot={contextRoot}
          onChangeDirectory={setRoot}
          onUploadIntent={onUploadFiles}
          onOpenContextMenu={openContextMenu}
          onMoveItem={_onMoveItem}
          scrollable={!xs}
          className={xs ? classes.fileListContainerSmall : classes.fileListContainer}
        />
      </Grid>

      {!hideMenu && (
        <Hidden mdDown>
          <Grid container item md={3} sm={4} xs={12} className={classes.verticalRightContainer} direction={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Grid item>
              <SearchBar className={classes.searchBar} onChange={setSearchTerm} value={searchTerm || ""} />
            </Grid>

            <Grid container item xs className={classes.fileActionsContainer} direction={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Grid item>
                <FileActionButton onClick={onAddFolderIntent} variant="add-folder" />
              </Grid>
              <Grid item>
                <FileActionButton onUploadFiles={onUploadFiles} contextRoot={contextRoot} variant="upload-file" />
              </Grid>
              <Grid item>
                <GoogleDriveFilePicker onUploadFiles={onUploadFiles} contextRoot={contextRoot} />
              </Grid>
              <Grid item>
                <DropboxFilePicker onUploadFiles={onUploadFiles} contextRoot={contextRoot} />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      )}
      <ContextMenu
        onRenameIntent={onRenameIntent}
        onPreviewIntent={onPreviewIntent}
        onDownloadIntent={onDownloadItem}
        onMoveIntent={onMoveIntent}
        onDeleteIntent={onDeleteItem}
        {...contextMenuProps}
      />
      {showDialog && <FolioDialog contextRoot={contextRoot} {...dialogProps} />}
      {moveDialogProps.open && <FolioSelectorDialog diskArrays={diskArrays} folderSelector {...moveDialogProps} />}
      <PreviewFileDialog
        currentUser
        docId={previewDocId}
        fileLoading={previewDocLoading}
        previewFile={previewFile}
        setCurrentUser={()=>{onPreviewIntent(null)}}
        setDocId={()=>{onPreviewIntent(null)}}
        setPreviewFile={()=>{onPreviewIntent(null)}}
      />
      <UploadProgressModal />
    </Grid>
  );
}

export default Folio;
