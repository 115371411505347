import React, { useState, useEffect } from "react";
import { BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import ArtifactTiles from "equip/artifact/artifactTiles.js";
import AssessmentCard from "./components/assessmentCard.js";

const useStyles = makeStyles({});

function Assessment({ pathItemId, artifacts, history, rubric, setDocId, submitScore, onCancel }) {
  const cls = useStyles();
  const [pos, setPos] = useState(history && history.length > 0 ? 0 : -1);

  useEffect(() => {
    setPos(history && history.length > 0 ? 0 : -1);
  }, [history]);

  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <AssessmentCard
            currentRubric={rubric}
            artifacts={artifacts}
            history={history}
            pos={pos}
            setPos={setPos}
            submitScore={submitScore}
            onCancel={onCancel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Assessment;
