import React from "react";

import { Grid, Typography } from "@mui/material";
import { ENUMS } from "bild-data";

function CompetencyProofRoles({ competencies }) {
  // TODO: Antioch FE ?? -- Get titles from the API (st, cl, af) for course
  let roles = [ENUMS.ROLES.STUDENT.NAME, ENUMS.ROLES.LOCAL_MENTOR.NAME, ENUMS.ROLES.ASSOCIATE_FACULTY.NAME];

  if (competencies.some(c => c.assessmentFlow.id === ENUMS.ASSESSMENT.FLOWS.AS_STAFF.ID)) roles = [];

  const xs = 12 / roles.length;
  const components = roles.map((r, i) => (
    <Grid item xs={xs} key={i}>
      <Typography variant="subtitle1" align="center">
        {r}
      </Typography>
    </Grid>
  ));

  return (
    <Grid container alignItems="center">
      {components}
    </Grid>
  );
}

export default CompetencyProofRoles;
