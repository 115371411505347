export function convertNetworkTreeJSONToCSV(data) {
  let csvData =
    "GRT,PRT,Network,Local Organization,Type,Users w/ Program,Users w/ Progress,Users w/ 50% Progress,Users w/ Lead/Entrust Program,Users w/ Certificate,Shepherd Assessments Waiting,Total Users w/ Program,Total Users w/ Progress,Total Users w/ 50% Progress,Total Users w/ Lead/Entrust Program,Total Users w/ Certificate,Total Shepherd Assessments Waiting\n";
  let rows = [];
  // Map json structure to raw csv rows
  for (let i = 0; i < data.length; i++) {
    let d = data[i];
    rows.push(convertNetworkTreeNodeJSONToCSV(d));
  }

  // Map raw rows to csv formatted rows
  rows = rows.flat();
  for (let i = 0; i < rows.length; i++) {
    let r = rows[i];
    r = r.replace(/null/g, "");
    csvData += r + "\n";
  }

  return csvData;
}

function convertNetworkTreeNodeJSONToCSV(data, grt = null, prt = null, network = null, local = null) {
  let rows = [];
  // Override any grt/prt/network with current data based on type
  if (data.type === "GRT Organization") grt = '"' + data.organization.organizationName + '"';
  if (data.type === "PRT Organization") prt = '"' + data.organization.organizationName + '"';
  if (data.type === "Network Organization") network = '"' + data.organization.organizationName + '"';
  if (data.type === "Local Organization") local = '"' + data.organization.organizationName + '"';
  // Map current level
  rows.push(mapNetworkNodeJSONToCSV(data, grt, prt, network, local));
  // Map any children
  if (data.children && data.children.length > 0) {
    for (let i = 0; i < data.children.length; i++) {
      let child = data.children[i];
      rows.push(convertNetworkTreeNodeJSONToCSV(child, grt, prt, network, local));
    }
  }

  // return flattened rows
  return rows.flat();
}

function mapNetworkNodeJSONToCSV(data, grt = null, prt = null, network = null, local = null) {
  let row = grt + "," + prt + "," + network + "," + local + ",";

  row += data.type + ",";
  row += data.eqpUsersWithAnyProgram + ",";
  row += data.eqpUsersWithAnyProgress + ",";
  row += data.eqpUsersWith50PercentProgress + ",";
  row += data.eqpUsersWithLeadOrEntrustProgram + ",";
  row += data.eqpUsersWithAnyCertificate + ",";
  row += data.eqpShepherdAssessmentsWaiting + ",";
  row += data.subEqpUsersWithAnyProgram + ",";
  row += data.subEqpUsersWithAnyProgress + ",";
  row += data.subEqpUsersWith50PercentProgress + ",";
  row += data.subEqpUsersWithLeadOrEntrustProgram + ",";
  row += data.subEqpUsersWithAnyCertificate + ",";
  row += data.subEqpShepherdAssessmentsWaiting;

  return row;
}
