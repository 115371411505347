import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";

const useStyles = makeStyles({
  badge: props => ({
    color: colors.white,
    background: props.defaultBackground,
    display: "inline-flex",
    lineHeight: "1",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "center",
    padding: "3px 6px",
    height: "20px",
    minWidth: "20px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: "800",
    borderRadius: "10px",
    fontSize: "0.75rem"
  })
});

function BBadge({ backgroundColor, content }) {
  let defaultBackground = backgroundColor ? backgroundColor : colors.gray;
  const cls = useStyles({ defaultBackground });

  return <span className={cls.badge}>{content}</span>;
}

export default BBadge;
