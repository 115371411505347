import React from "react";
import { colors, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

// With help from https://www.freecodecamp.org/news/css-only-pie-chart/
const useStyles = makeStyles({
  pie: props => ({
    position: "relative",
    aspectRatio: 1,
    display: "inline-grid",
    placeContent: "center",
    "&:before": {
      content: '""',
      position: "absolute",
      borderRadius: "50%",
      inset: 0,
      background: `conic-gradient(${props.color} calc(${props.percentage}*1%),#0000 0)`,
      "-webkit-mask": `radial-gradient(farthest-side,#0000 calc(99% - 10px),${colors.white} calc(100% - 10px))`,
      mask: `radial-gradient(farthest-side,#0000 calc(99% - 10px),${colors.white} calc(100% - 10px))`
    }
  }),
  title: props => ({
    fontWeight: "bold",
    color: props.color,
    zIndex: "20",
    textAlign: "center"
  })
});

function PieProgress({ value, total, label }) {
  const v1 = value ? Math.ceil(value / 1000000) : 1;
  const v2 = total ? Math.ceil(total / 1000000) : 1;
  const percentage = Math.ceil((v1 / v2) * 100);
  const color = percentage >= 100 ? colors.darkRed : percentage >= 90 ? colors.orange : colors.bildBlue;
  const cls = useStyles({ percentage, color });

  return (
    <Grid container className={cls.wrapper}>
      <Grid item xs={12} className={cls.pie}>
        <BTypography variant="h4" className={cls.title}>
          {percentage}%
        </BTypography>
        {v1}/{v2}
        {label}
      </Grid>
    </Grid>
  );
}

export default PieProgress;
