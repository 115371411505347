import React from "react";
import { colors, Blink, NormalButton } from "bild-ui";
import { Grid, Divider, Tabs, Tab, TextField, Typography, InputAdornment } from "@mui/material";
import "./organizations.css";

// ORGANIZATION TABS
function OrganizationTabs({ tab, canViewAdmissions, organizationId, organizationName, updateTab, filterUsers, filterValue, showReports }) {

  return (
    <Grid container alignItems="center">
      <Grid item sm={7} xs={12}>
        <Typography variant="h5" gutterBottom className="page-headline">
          {organizationName}
        </Typography>
        {showReports && (
          <>
            <Blink dst={"reports-organization-student-progress"} organization_id={organizationId}>
              <NormalButton variant="simple" labelColor={colors.black}>
                <i className="fal fa-clipboard-user" /> &nbsp; Student Progress
              </NormalButton>
            </Blink>
            <Blink dst={"reports-organization-module-progress"} organization_id={organizationId}>
              <NormalButton variant="simple" labelColor={colors.black}>
                <i className="fal fa-clipboard-list-check" /> &nbsp; Module Progress
              </NormalButton>
            </Blink>
          </>
        )}
      </Grid>
      <Grid item sm={5} xs={12}>
        <TextField
          margin="dense"
          variant="outlined"
          value={filterValue}
          onChange={e => {
            filterUsers(e);
          }}
          style={{ margin: 0 }}
          fullWidth
          InputProps={{
            style: { backgroundColor: "white", borderRadius: "4px" },
            inputProps: { placeholder: "Search..." },
            endAdornment: (
              <InputAdornment position="end">
                <i className="fal fa-search" />
              </InputAdornment>
            )
          }}
          className="search-bar"
        />
      </Grid>
      <Grid item xs={12} className="organization-tabs">
        <Divider />
        <Tabs value={tab} onChange={(e, v) => updateTab(v)} variant="scrollable" scrollButtons={false}>
          <Tab key={0} value={0} label={<Typography variant="subtitle1">Users</Typography>} disableRipple />
          {canViewAdmissions && <Tab key={1} value={1} label={<Typography variant="subtitle1">Admissions</Typography>} disableRipple />}
        </Tabs>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default OrganizationTabs;
