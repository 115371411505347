import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { BTypography, FormAutocompleteField, FormField, FormTextField, NormalButton, SupportDialog, UserAvatar, colors } from "bild-ui";
import { ENUMS } from "bild-data";
import { getEnumSectionAsArray, getInputDateFromBackendDate } from "bild-utils";


const useStyles = makeStyles({
  date: {
    padding: "1rem 0.75rem",
    borderRadius: "0.4rem",
    border: `0.1rem solid ${colors.lightGray}`,
  },
  footer: { padding: "0 1rem 1rem 1rem" }
});

export default function NetworkPartnerStatusDialog ({ open, onClose, status, updatePartner }) {
  const cls = useStyles();
  const [form, setForm] = useState({});
  const [removeStatusDialog, setRemoveStatusDialog] = useState(false);
  const types = getEnumSectionAsArray(ENUMS.NETWORK_PARTNERS.STATUSES) || [];

  useEffect(()=>{
    if (status) {
      setForm({
        "statusId": status.statusId,
        "subject": status.noteSubject,
        "note": status.note,
        "date": getInputDateFromBackendDate(status.statusDate)
      });
    }
  },[status])

  function _isReady() {
    if (status) {
      let d = getInputDateFromBackendDate(status.statusDate);
      if (form && (status.statusId === form.statusId && status.noteSubject === form.subject && status.note === form.note && d === form.date)) {
        return false;
      }
    }
    
    if (form && (form.statusId && form.statusId > 0) && (form.subject && form.subject.length > 0) && (form.note && form.note.length > 0) && (form.date && form.date.length > 0)) {
      return true;
    }
    
    return false;
  }

  function _updatePartner() {
    if (_isReady()) {
      let sendData = {
        "status": {
          "statusId": form.statusId,
          "noteSubject": form.subject,
          "note": form.note,
          "statusDate": form.date + " 00:00:00"
        },
      };
      if (status && status.id) {
        sendData.status.id = status.id;
      }
      updatePartner(sendData);
      _onClose();
    }
  }

  function _onClose() {
    setForm({});
    onClose();
  }

  return (
    <Dialog open={open} onClose={_onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <BTypography variant="h5">{status ? "Update " : "Add a New "} Status</BTypography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormAutocompleteField
              label="Status Type"
              value={form.statusId || ''}
              setValue={x => {
                setForm({...form, "statusId": x});
              }}
              items={types.map(x => {return {"id": x.ID, "name": x.NAME}})}
              required
            />
          </Grid>
          <Grid container item xs={12}>
            <FormTextField
              label={"Subject"}
              value={form.subject || ''}
              setValue={x => {
                setForm({...form, "subject": x});
              }}
              required
            />
          </Grid>
          <Grid container item xs={12}>
            <FormTextField
              label={"Note"}
              value={form.note || ''}
              multiline
              rows={6}
              setValue={x => {
                setForm({...form, "note": x});
              }}
              required
            />
          </Grid>
          <Grid container item xs={12}>
            <FormField
              label={"Date"}
              child={
                <input
                  type="date"
                  value={form.date || ''}
                  onChange={x => {
                    setForm({...form, "date": x.target.value});
                  }}
                  className={cls.date}
                />
              }
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center" className={cls.footer}>
          <Grid item>
            {status && status.reportingUser && (
              <UserAvatar src={status.reportingUser.avatarURL} name={status.reportingUser.name} badgeSrc={status.reportingUser.badgeURL} />
            )}
          </Grid>
          {status && (
            <Grid item>
              <NormalButton
                variant="primary"
                color={colors.lightRed}
                labelColor={colors.darkRed}
                borderColor={colors.darkRed}
                hoverColor={colors.darkerRed}
                onClick={()=>{setRemoveStatusDialog(true)}}
              >
                Remove
              </NormalButton>
            </Grid>
          )}
          <Grid item>
            <NormalButton
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              disabled={!_isReady()}
              onClick={_updatePartner}
            >
              {status ? "Update" : "Create"}
            </NormalButton>
          </Grid>
        </Grid>
      </DialogActions>
      <SupportDialog
        open={removeStatusDialog}
        closeDialog={() => {
          setRemoveStatusDialog(false);
        }}
        options={[`I would like to remove status with id: '${status ? status.statusId : ""}' and subject: '${status ? status.noteSubject : ""}'.`]}
      />
    </Dialog>
  );
}