import React, { useEffect, useState } from "react";
import { colors, BTypography, SortableList, UserAvatar, NormalButton, FilterTextField, ProgramAvatar } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  header: {
    background: colors.white,
    padding: "1rem",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 100
  },
  body: { padding: "0rem" },
  title: { paddingLeft: "0.25rem" }
});

function PreviewPaths({ paths, pathId, setPathId }) {
  const cls = useStyles();
  const [filter, setFilter] = useState("");
  const [pathRowItems, setPathRowItems] = useState([]);
  const [filteredPaths, setFilteredPaths] = useState([]);

  // Set LEFT/RIGHT ARROW keybinds for navigation
  useEffect(() => {
    const keyPressHandler = e => {
      if (pathId != null) {
        let currentIndex = filteredPaths.map(fc => fc.id).indexOf(pathId);
        if (currentIndex > 0 && e.key === "ArrowLeft") {
          setPathId(filteredPaths[currentIndex - 1].id);
        } else if (currentIndex < filteredPaths.length - 1 && e.key === "ArrowRight") {
          setPathId(filteredPaths[currentIndex + 1].id);
        }
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [pathId]);

  useEffect(() => {
    // Set filteredCertificates seperately from RowItems
    const fp = paths.filter(x => {
      if (filter.length > 0) {
        return x.name.toLowerCase().includes(filter.toLowerCase()) || (x.subtitle && x.subtitle.toLowerCase().includes(filter.toLowerCase()));
      } else {
        return 1;
      }
    });
    setFilteredPaths(fp);
    setPathRowItems(
      fp.map(x => [
        [
          <ProgramAvatar
            size={30}
            icon={"fas fa-badge-check"}
            color={x.programColor.webFrontendValue}
            name={x.name}
            subtitle={x.subtitle}
            showSubtitle={true}
            nameVariant="body1"
            subtitleVariant="body1"
            sortval={x.name}
            key={x.name}
          />
        ],
        <Grid container justifyContent="flex-end">
          <Grid item>
            <NormalButton
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              onClick={() => {
                setPathId(x.id);
              }}
            >
              Preview &nbsp; <i className="fas fa-chevron-right" />
            </NormalButton>
          </Grid>
        </Grid>
      ])
    );
  }, [paths, filter]);

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} alignItems="center" justifyContent="space-between">
        <Grid item sm={6} xs={12}>
          <BTypography variant="h4">Available Programs</BTypography>
        </Grid>
        <Grid item>
          <FilterTextField
            value={filter}
            placeholder="Filter"
            onChange={e => setFilter(e.target.value)}
            onClear={() => {
              setFilter("");
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <SortableList perPage={10} filter={filter} headers={["Program", ""]} items={pathRowItems} itemPadding={"0.5rem 1rem"} spacing={[8, 4]} />
      </Grid>
    </Grid>
  );
}

export default PreviewPaths;
