import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { loadUserLogin, userLogin } from "bild-data/user";
import queryString from "query-string";

import SupportTicketDialog from "presentational/dialogs/supportTicketDialog.js";
import ForgotPasswordDialog from "presentational/dialogs/forgotPasswordDialog.js";

import Login from "./login.js";
import { toaster } from "presentational/toasts/toasts.js";

// USER LOADER
function UserLoader() {
  const location = useLocation();
  const [supportDialog, setSupportDialog] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);

  useEffect(()=>{
    // Prep Login page
    setPageTitle("Log In");
    const searchParams = queryString.parse(location.search);
    if (searchParams) {
      if (searchParams.error) {
        toaster.error(searchParams.error);
      } else if (searchParams.support_request) {
        setSupportDialog(true);
      } else if (searchParams.support_password) {
        setPasswordDialog(true);
      }
    }
    loadUserLogin(location.pathname, location.pathname);
  },[]);

  return (
    <>
      <Login handleSubmit={userLogin} handleSupportRequest={()=>{setSupportDialog(true)}} handleForgotPassword={()=>{setPasswordDialog(true)}} />
      <SupportTicketDialog
        open={supportDialog}
        closeDialog={() => {
          setSupportDialog(false);
        }}
      />
      <ForgotPasswordDialog
        open={passwordDialog}
        closeDialog={() => {
          setPasswordDialog(false);
        }}
      />
    </>
  );
}

export default UserLoader;
