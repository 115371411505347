import React, { useState, useEffect } from "react";
import { colors, SortableList, List, Blink, NormalButton, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Select, MenuItem, TextField, Checkbox } from "@mui/material";
import FundraisingPhones from "./components/fundraisingPhones.js";
import FundraisingInteraction from "./components/fundraisingInteraction.js";
import FundraisingTask from "./components/fundraisingTask.js";

const useStyles = makeStyles({
  header: { paddingLeft: "1rem" },
  row: { padding: "0.25rem 1rem" },
  taskNoteRow: { paddingTop: "1rem" },
  taskNoteWrapper: { padding: "0.25rem 1.25rem 0.25rem 0.75rem" },
  taskNote: { background: colors.veryLightGray, border: `1px solid ${colors.gray}`, borderRadius: "4px", padding: "0.75rem 1rem" },
  backButton: { borderRadius: "4px", border: `1px solid ${colors.darkGray}`, marginLeft: "1rem", padding: "1rem" },
  select: { width: "100%" },
  label: { textAlign: "right", paddingRight: "1rem" },
  textarea: { width: "100%", minHeight: "8rem" },
  required: { color: colors.red, fontSize: "1.5rem" },
  disabled: { opacity: "0.5" },
  submitWrapper: { justifyContent: "flex-end" },
  task: { padding: "0.25rem" },
  submit: { padding: "2rem" }
});

function FundraisingCompleteTask({ constituent, task, completeTask }) {
  const cls = useStyles();
  const [phonesToRemove, setPhonesToRemove] = useState([]);
  const [phonesValid, setPhonesValid] = useState(false);
  const [interaction, setInteraction] = useState(null);
  const [interactionValid, setInteractionValid] = useState(false);
  const [newTask, setNewTask] = useState(null);
  const [newTaskValid, setNewTaskValid] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    // validate all fields are set properly
    if (interactionValid && newTaskValid && phonesValid) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [interactionValid, newTaskValid, phonesValid]);

  function _completeTask() {
    // If enabled, run update
    if (isEnabled) {
      let taskToAdd = newTask && newTask.date !== "" ? newTask : null;
      completeTask(task.note, interaction, taskToAdd, phonesToRemove);
    }
  }

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <NormalButton variant="simple" labelColor={colors.darkGray} className={cls.backButton} component={Blink} dst="fundraising">
            <i className={`fas fa-arrow-left`} /> &nbsp; Cancel
          </NormalButton>
        </Grid>
        <Grid item xs className={cls.header}>
          <BTypography variant="h3">
            {constituent.name} - <i>Complete Task</i>
          </BTypography>
          <BTypography variant="h6">{constituent.groupName}</BTypography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cls.row}>
        <FundraisingPhones
          phones={constituent.phones}
          phonesToRemove={phonesToRemove}
          setPhonesToRemove={setPhonesToRemove}
          setIsValid={setPhonesValid}
        />
      </Grid>
      <Grid container item xs={12} className={cls.taskNoteRow} alignItems="center">
        <Grid item md={2} sm={3} xs={12} className={cls.label}>
          <BTypography variant="h6">Task Note</BTypography>
        </Grid>
        <Grid item md={10} sm={9} xs={12} className={cls.taskNoteWrapper}>
          <BTypography variant="body1" className={cls.taskNote}>
            {task.note}
          </BTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.task}>
        <FundraisingInteraction updateInteraction={setInteraction} setIsValid={setInteractionValid} />
      </Grid>
      <Grid container item md={10} sm={8} xs={12} className={cls.task}>
        <FundraisingTask updateTask={setNewTask} setIsValid={setNewTaskValid} />
      </Grid>
      <Grid container item md={2} sm={4} xs={12} className={`${cls.row} ${cls.submitWrapper}`}>
        <Grid item>
          <NormalButton
            disabled={!isEnabled}
            onClick={_completeTask}
            variant="simple"
            color={colors.green}
            labelColor={colors.white}
            className={`${cls.submit} ${!isEnabled ? cls.disabled : ""}`}
          >
            Complete Task
          </NormalButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FundraisingCompleteTask;
