import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { setPageTitle } from "bild-utils";
import { loadAdminUserCourse, loadAdminUser } from "bild-data/admin";

import LoadingScreen from "presentational/screens/loadingScreen.js";
import UserAdminCourse from "containers/admin/userAdminCourse.js";
import { userData } from "bild-data";

function UserAdminCourseLoader({}) {
  const { user_id } = useParams();
  const userOverview = userData.getOverview();
  const userId = user_id || userOverview.user.id;
  let [state, setState] = useState({ isLoading: true, filter: null });
  let [user, setUser] = useState(null);

  useEffect(() => {
    setPageTitle("User Course Admin");

    if (!state.courseGroups && state.isLoading) {
      // Get All Course Work
      loadAdminUserCourse(
        userId,
        data => {
          let totals = {
            courses: { filter: null, value: 0 },
            complete: { filter: "complete", value: 0 }
          };
          for (let i = 0; i < data.length; i++) {
            let courses = data[i].courses;
            for (let j = 0; j < courses.length; j++) {
              totals.courses.value++;
              if (courses[j].complete) totals.complete.value++;
            }
          }
          setState({ ...state, isLoading: false, orgCourseGroups: data, courseGroups: data, totals: totals });
        },
        data => {
          setState({ ...state, isLoading: false, pageError: data });
        }
      );
    }
  }, [userId, state]);

  useEffect(() => {
    if (!user) {
      // Get UserOverview
      loadAdminUser(userId, data => {
        setUser(data.user);
      });
    }
  }, [userId, user]);

  function filterCourses(filter) {
    // Deep copy of courses so we can manipulate this without losing data
    let courseGroups = JSON.parse(JSON.stringify(state.orgCourseGroups));

    // Iterate over a full course group structure, removing whatever does not match the course filter
    // CourseGroup -> Courses
    let removeGroups = [];
    for (let i = 0; i < courseGroups.length; i++) {
      let courses = courseGroups[i].courses;
      let removeCourses = [];
      for (let j = 0; j < courses.length; j++) {
        if (filter !== null && !courses[j].complete) removeCourses.push([j]);
      }
      // If we will remove all courses, remove this courseGroup. Then remove the courses in reverse order
      if (courses.length === removeCourses.length) removeGroups.push([i]);
      removeCourses.reverse().forEach(x => {
        courseGroups[i].courses.splice(x, 1);
      });
    }
    // Finally remove marked courseGroups in reverse order
    removeGroups.reverse().forEach(x => {
      courseGroups.splice(x, 1);
    });

    setState({ ...state, courseGroups: courseGroups, filter: filter });
  }

  if (state.isLoading || state.pageError) {
    return <LoadingScreen error={state.pageError} />;
  } else {
    return (
      <UserAdminCourse user={user} courseGroups={state.courseGroups} totals={state.totals} filterCourses={filterCourses} filter={state.filter} />
    );
  }
}

export default UserAdminCourseLoader;
