import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { colors, LoadingScreen } from "bild-ui";

const useStyles = makeStyles({
  container: {
    position: "relative !important"
  },
  overlay: props => ({
    position: "absolute !important",
    zIndex: "100",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: props.loading ? colors.lightGray + "50" : ""
  })
});

function LoadingWrapper({ loading, children }) {
  const cls = useStyles({ loading });

  return (
    <Grid container className={cls.container} justifyContent="center" alignItems="center">
      {loading && (
        <div className={cls.overlay}>
          <LoadingScreen />
        </div>
      )}
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default LoadingWrapper;
