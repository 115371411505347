import React from "react";
import { diskData, driveData } from "bild-data";
import { colors, BTypography } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  body: { padding: "0" },
  fileWrapper: { paddingTop: "1rem", backgroundColor: colors.white },
  file: { border: `1px solid ${colors.darkerGray}`, justifyContent: "center" },
  fileInner: {
    padding: "0.5rem",
    justifyContent: "center",
    "&:hover": { filter: "brightness(0.9)", cursor: "pointer", backgroundColor: colors.white }
  },
  fileWrapperSlim: { paddingTop: "0.25rem !important", marginRight: "0.25rem" },
  fileInnerSlim: { padding: "0.25rem !important", height: "100%" },
  icon: { paddingRight: "1rem" },
  remove: { padding: "0.5rem", color: colors.darkRed, "&:hover": { filter: "brightness(0.9)", cursor: "pointer", backgroundColor: colors.white } },
  create: { padding: "0.25rem 1rem", borderRadius: "20px", background: colors.green, color: colors.white },
  createText: { paddingLeft: "0.5rem" },
  artifactIcon: { color: colors.lightBlue },
  artifactItem: { color: colors.darkerGray },
});

function ArtifactTiles({ artifacts, viewDocument, viewPreview, remove, editable, slim }) {
  const cls = useStyles();

  function localFileName(artifact) {
    let name = artifact.name;
    let inAppId = artifact.inAppLocalId;
    try {
      return !inAppId ? name : (inAppId === name ? (driveData.getHandle(inAppId).name ? driveData.getHandle(inAppId).name : "~Untitled~") : name);
    } catch (e) {
      return name ? name : "~Untitled~";
    }
  }



  return (
    <Grid container>
      <Grid container item xs={12} className={cls.body} justifyContent="flex-start" alignItems="stretch">
        {artifacts.map((a, i) => {
          // File Icon
          let typeIcon = "file"
          const ct = a.contentType;
          if (ct.substring(0, 6) === "image/") {
            typeIcon = "image-polaroid";
          } else if (ct.substring(0, 6) === "video/") {
            typeIcon = "film";
          } else if (ct.substring(0, 6) === "audio/") {
            typeIcon = "waveform";
          } else if (ct.substring(0, 5) === "bild/") {
            typeIcon = "pencil";
          } else if (ct === "application/msword" || ct === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            typeIcon = "file-word"
          } else if (ct === "application/vnd.ms-excel" || ct === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            typeIcon = "file-excel"
          } else if (ct === "application/vnd.ms-powerpoint" || ct === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
            typeIcon = "file-powerpoint"
          } else if (ct === "application/pdf") {
            typeIcon = "file-pdf"
          }

          return (
            <Grid container item xs={slim ? true : 12} className={`${slim ? cls.fileWrapperSlim : ""} ${cls.fileWrapper}`} key={a.id}>
              <Grid container item xs={12} className={cls.file} key={a.id} alignItems="center">
                <Grid
                  container
                  item
                  xs
                  alignItems="center"
                  className={`${slim ? cls.fileInnerSlim : ""} ${cls.fileInner}`}
                  onClick={() => {
                    if (a.inAppLocalId) {
                      viewDocument(a.inAppLocalId);
                    } else {
                      a.isFile = true;
                      viewPreview(a);
                    }
                  }}
                >
                  <Grid item className={cls.icon}>
                    <i className={`fal fa-${typeIcon} fa-2x fa-fw ${cls.artifactIcon}`} />
                  </Grid>
                  <Grid item xs>
                    <BTypography variant="body1" className={cls.artifactItem}>
                      {localFileName(a)}
                    </BTypography>
                  </Grid>
                </Grid>
                {editable && (
                  <Grid
                    item
                    className={cls.remove}
                    onClick={() => {
                      remove([{ id: a.id }]);
                    }}
                  >
                    <i className="fal fa-2x fa-times-square" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default ArtifactTiles;
