import React, { useEffect } from "react";
import { setPageTitle, updateURL } from "bild-utils";
import { userData } from "bild-data";
import { userLogin } from "bild-data/user";
import Home from "./home.js";

// HOME LOADER
export default function HomeLoader({}) {
  const userOverview = userData.getOverview();
  const userPreferences = userData.getUserPreferences();

  useEffect(()=>{
    setPageTitle("Home");
  },[]);

  function _userLogin(form) {
    userLogin(
      form,
      (e) => {
        // If the login fails for any reason, redirect to the login page with generic error
        updateURL("/login?error=The email address or password you entered is incorrect. Please try again or contact support.");
      }
    );
  }

  return (<Home userOverview={userOverview} userPreferences={userPreferences} handleLogin={_userLogin} />);
}
