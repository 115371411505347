import React, { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import classes from "../classes.js";
import ProgramOrderGroup from "./programOrderGroup.js";

// PROGRAM DASHBOARD
function ProgramsDashboard({ program, forceExpand }) {
  const [yearsExpanded, setYearsExpanded] = useState(false);
  const [courseGroupColumns, setCourseGroupColumns] = useState([]);

  useEffect(()=>{
    let p = program.programOrderGroup;
    let groups = [];

    for (var i = 0; i < p.length; i++) {
      let phase = p[i].courseGroups;
      for (var j = 0; j < phase.length; j++) {
        if (groups.indexOf(phase[j].name) === -1) {
          groups.push(phase[j].name);
        }
      }
    }

    setCourseGroupColumns(groups);
  },[program]);

  function programOrderGroups() {
    const programOrderGroup = program.programOrderGroup;
    const components = [];

    const expanded = forceExpand || yearsExpanded;

    // TODO: Antioch FE ?? -- Dashboard add active/inactive flags for the different years
    if (programOrderGroup) {
      for (var i = 0; i < programOrderGroup.length; i++) {
        components.push(
          <ProgramOrderGroup
            expanded={i === 0 ? true : expanded}
            groupColumns={courseGroupColumns}
            programOrderGroup={programOrderGroup[i]}
            enrollmentId={program.id}
            key={i}
          />
        );
      }
    }

    return components;
  }

  // Only return dashboard when the program object is loaded
  if (program) {
    return (
      <div className={classes.programContainer}>
        {programOrderGroups()}
        {!forceExpand && (
          <>
            <Divider className={classes.negativeMarginDivider} />
            <Button onClick={()=>{setYearsExpanded(!yearsExpanded)}} className={classes.expandButton}>
              <i className={`fal fa-chevron-${yearsExpanded ? "up" : "down"}`} />
              <span>&nbsp; View All Years</span>
            </Button>
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default ProgramsDashboard;
