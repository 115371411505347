import React from "react";

import { EbookstoreReader } from "bild-vendors";

function Reader({ documentId, documentAuthToken, language, page, downloadable }) {
  return (
    <EbookstoreReader documentId={documentId} documentAuthToken={documentAuthToken} language={language} page={page} downloadable={downloadable} />
  );
}

export default Reader;
