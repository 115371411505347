import React from "react";
import { getUsername, getMasqueradeInfo } from "bild-data/core/localStorage";
import { getOverview } from "bild-data/user";

import "./vendor.css";
// INTERCOM WIDGET
function IntercomWidget({}) {
  const path = window.location.pathname;
  if (path.substring(0, 11) === "/masquerade" || getMasqueradeInfo()) {
    // If this user is masquerading or about to be, don't show anything
  } else {
    var APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
    // This is lifted from the intercom docs ( https://developers.intercom.com/installing-intercom/docs/basic-javascript )
    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
    // Set user's email if we have them
    // TODO: this email should be getting set at the userOverview in the BE
    let email = getUsername() ? getUsername() : null;
    let userId = getOverview() ? getOverview().user.id : null;
    // Start up the messenger only if we have an email and user_id
    if (email && userId) {
      window.Intercom("boot", {
        app_id: APP_ID,
        email: email,
        user_id: userId
      });
    }
  }

  return null;
}

export default IntercomWidget;
