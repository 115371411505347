import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, BTypography } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  legend: {
    background: colors.white,
    padding: "0.25rem",
    border: `1px solid ${colors.lightGray}`,
    borderRadius: "4px"
  },
  title: { padding: "0.25rem", fontWeight: "bold", minWidth: "4rem" },
  key: { padding: "0.25rem" },
  icon: { fontSize: "1.5rem" },
  paddedIcon: { padding: "0 0.15rem" },
  name: { paddingLeft: "0.25rem", fontWeight: "bold" }
});

function Legend({ keys, title, spacing }) {
  const cls = useStyles();
  const space = spacing ? spacing : 6;

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={cls.legend}>
      {title && (
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item>
            <BTypography variant="subtitle2" className={cls.title}>
              {title}
            </BTypography>
          </Grid>
        </Grid>
      )}
      <Grid container item direction="row">
        {keys.map((x, i) => (
          <Grid container justifyContent="flex-start" alignItems="center" item sm={space} xs={12} className={cls.key} key={i}>
            <Grid item>
              <i className={`${cls.icon} ${x.icon ? x.icon : `fas fa-square ${cls.paddedIcon}`}`} style={{ color: x.color }} />
            </Grid>
            <Grid item xs className={cls.name}>
              {x.name}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Legend;
