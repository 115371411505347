import { getRequest, putRequest } from "bild-data/core/comm.js";
// TODO: PLACEHOLDER UNTIL BE is up
import { localStorage as ls } from "bild-data";

//// USER LEARNING ITEM
// export function load(user_learning_item_id, success_cb, error_cb) {
//   return getRequest(`/learning_items/${user_path_item_id}`, success_cb, null, error_cb);
// }

export function loadFromUserPathItem(user_path_item_id, success_cb, error_cb) {
  return getRequest(`/learning_items/user_path_items/${user_path_item_id}`, success_cb, null, error_cb);
}

export function attachArtifact(userLearningItemId, userPathItemId, attachments, success_cb, error_cb) {
  let data = {
    id: userLearningItemId,
    userPathItemId: userPathItemId,
    files: attachments
  };
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

export function removeArtifact(userLearningItemId, userPathItemId, attachments, success_cb, error_cb) {
  let data = {
    id: userLearningItemId,
    userPathItemId: userPathItemId,
    filesToRemove: attachments
  };
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

export function submitAssessment(userLearningItemId, userPathItemId, assessment, success_cb, error_cb) {
  let data = {
    id: userLearningItemId,
    userPathItemId: userPathItemId,
    competency: {
      assessments: [assessment]
    }
  };
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

export function submitEvaluation(userLearningItemId, userPathItemId, evaluation, success_cb, error_cb) {
  let data = {
    id: userLearningItemId,
    userPathItemId: userPathItemId,
    evaluation
  };
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

export function submitReport(userLearningItemId, userPathItemId, data, success_cb, error_cb) {
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

export function submitCompletion(userLearningItemId, data, success_cb, error_cb) {
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

export function sumbitChangeLevel(userLearningItemId, userPathItemId, completionLevelId, success_cb, error_cb) {
  let data = {
    id: userLearningItemId,
    userPathItemId: userPathItemId,
    newCompletionLevel: { id: completionLevelId }
  };
  putRequest(`/learning_items/${userLearningItemId}`, data, success_cb, null, error_cb);
}

//// DISCUSSION
export function getComments(user_learning_item_id, success_cb, error_cb) {
  success_cb(ls.getItem(`bild-comments-${user_learning_item_id}`) || []);
}

export function saveComment(user_learning_item_id, name, value, success_cb, error_cb) {
  const createdAt = new Date().getTime();
  const id = `bild-comment-${createdAt}`;
  const commentObj = {
    value,
    name: name,
    createdAt,
    id
  };

  const comments = ls.getItem(`bild-comments-${user_learning_item_id}`) || [];
  comments.push(commentObj);
  ls.setItem(`bild-comments-${user_learning_item_id}`, comments);

  success_cb(ls.getItem(`bild-comments-${user_learning_item_id}`) || []);
}

export function deleteComment(user_learning_item_id, comment_id, success_cb, error_cb) {
  const comments = ls.getItem(`bild-comments-${user_learning_item_id}`) || [];
  const filteredComments = comments.filter(c => c.id !== comment_id);
  ls.setItem(`bild-comments-${user_learning_item_id}`, filteredComments);

  success_cb(ls.getItem(`bild-comments-${user_learning_item_id}`) || []);
}
