import React, { useState, useRef } from "react";

import { Typography } from "@mui/material";

import ArtifactChip from "./artifactChip.js";
import AttachArtifactDialog from "containers/folio/attachArtifactDialog.js";

import { hideIntercom, showIntercom } from "bild-utils";
import { courseData } from "bild-data";
import { toaster } from "presentational/toasts/toasts.js";

export default function ArtifactsFlow({ groupColor, competencyId, artifacts, canUpdateArtifacts, callingUserId, userId, onCompetencyChange, viewDocument, viewPreview }) {
  const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const count = useRef(0);

  function _attach(selectedFiles) {
    setUploading(true);
    const plural = selectedFiles.length > 1 ? "s" : "";

    courseData.attachArtifact(
      competencyId,
      callingUserId,
      selectedFiles,
      data => {
        setFileSelectorOpen(false);
        setUploading(false);
        showIntercom();
        toaster.success("Artifact" + plural + " successfully attached.");

        if (typeof onCompetencyChange === "function") onCompetencyChange(data);
      },
      () => {
        setUploading(false);
        showIntercom();
      }
    );
  }

  function _remove(artifactId) {
    // Format artifact id into proper json format
    const artifact = [{ id: artifactId }];

    courseData.removeArtifact(competencyId, callingUserId, artifact, data => {
      toaster.success("Artifact successfully removed.");
      if (typeof onCompetencyChange === "function") onCompetencyChange(data);
    });
  }

  return (
    <>
      <Typography variant="h6" style={{ color: groupColor }} gutterBottom>
        Artifacts
      </Typography>

      {canUpdateArtifacts && (
        <ArtifactChip
          attachButton
          loading={uploading}
          onAttachIntent={() => {
            setFileSelectorOpen(true);
            count.current += 1;
            hideIntercom();
          }}
        />
      )}

      {artifacts.map((f, i) => (
        <ArtifactChip
          file={f}
          key={i}
          onDelete={canUpdateArtifacts ? () => _remove(f.id) : undefined}
          viewDocument={viewDocument}
          viewPreview={viewPreview}
        />
      ))}

      {canUpdateArtifacts && (
        <AttachArtifactDialog
          userId={userId}
          open={fileSelectorOpen}
          openCount={count.current}
          onAttach={_attach}
          onClose={() => {
            setFileSelectorOpen(false);
            showIntercom();
          }}
        />
      )}
    </>
  );
}
