import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import "./assessmentStatus.css";

// Can be memoized
function AssessmentStatus({ icons, status, groupColor }) {
  let iconComponents;
  if (icons) {
    let components = [];

    for (var i = 0; i < icons.length; i++) {
      const icon = icons[i];
      const frontendValues = icon.webFrontendValue.split(" ");

      let iconElement;
      let iconWidth = 4;
      if (frontendValues.includes("fa-double")) {
        iconWidth = 8;
      } else if (frontendValues.includes("fa-triple")) {
        iconWidth = 12;
      }

      let index;
      switch (true) {
        case frontendValues.includes("fa-check-circle"):
          index = frontendValues.indexOf("fa-check-circle");
          frontendValues[index] = "fa-check";
          iconElement = <i className={"fal green " + frontendValues.join(" ")} />;
          break;
        case frontendValues.includes("fa-times-circle"):
          index = frontendValues.indexOf("fa-times-circle");
          frontendValues[index] = "fa-times";
          iconElement = <i className={"fal " + frontendValues.join(" ")} />;
          break;
        case frontendValues.includes("fa-sync"):
          iconElement = <i className={"fal " + icon.webFrontendValue} />;
          break;
        default:
          iconElement = <i className={"fal " + icon.webFrontendValue} />;
      }

      components.push(
        <Grid item xs={iconWidth} className="assessment-icon-container" style={{ position: "relative" }} key={i}>
          <span className={"assessment-icon"}>{iconElement}</span>
        </Grid>
      );
    }

    iconComponents = components;
  }

  let statusComponent;
  if (status) {
    statusComponent = (
      <div className="assessment-status-text">
        <Typography variant="subtitle1" align="center" style={{ color: groupColor }}>
          <i className="fal fa-history" />
          {status}
        </Typography>
      </div>
    );
  }

  if (iconComponents && statusComponent) {
    return (
      <Grid container className="assessment-status-component">
        <Grid item container alignItems="center" className="assessment-icons">
          {iconComponents}
        </Grid>
        <Grid item container className="assessment-status-container">
          {statusComponent}
        </Grid>
      </Grid>
    );
  } else if (iconComponents) {
    return (
      <Grid container className="assessment-status-component">
        <Grid item container alignItems="center" className="assessment-icons">
          {iconComponents}
        </Grid>
      </Grid>
    );
  } else if (statusComponent) {
    return (
      <Grid container className="assessment-status-component">
        <Grid item container className="assessment-status-container">
          {statusComponent}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

export default AssessmentStatus;
