import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, Expander, NetworkNodeDetails, SideTreeNode, TreeFooter } from "bild-ui";
import { Grid, Popper, ClickAwayListener } from "@mui/material";

const useStyles = makeStyles({
  sideTree: {
    overflow: "auto",
    "& table": {
      padding: "0.25rem"
    },
    "& tr": {
      padding: "0.25rem"
    }
  },
  hasChildren: {
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)"
    }
  },
  subGroup: {
    borderLeft: `2px solid transparent`,
    borderRadius: "10px"
  },
  shrinkNodeWrapper: {
    padding: "1rem 0.5rem",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  shrinkNode: {
    maxWidth: "8rem",
    textAlign: "center"
  },
  shrinkNodeInner: {
    border: `2px solid ${colors.bildBlue}`,
    padding: "0.5rem 0",
    background: colors.white,
    color: colors.bildBlue,
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  brt: { borderColor: `${colors.brt} !important` },
  grt: { borderColor: `${colors.grt} !important` },
  prt: { borderColor: `${colors.prt} !important` },
  network: { borderColor: `${colors.network} !important` },
  localorganization: { borderColor: `${colors.localorganization} !important` },
  // Scale Levels
  trunk: { transformOrigin: "left", transform: "scale(1)" },
  scale0: { transform: "scale(1)" },
  scale1: { transform: "scale(0.80)" },
  scale2: { transform: "scale(0.60)" },
  scale3: { transform: "scale(0.50)" },
  scale4: { transform: "scale(0.40)" },
  scale5: { transform: "scale(0.35)" },
  popper: {
    maxWidth: "25rem",
    background: colors.white,
    borderRadius: "4px",
    padding: "0rem"
  }
});

function TreeDiagram({ data, scalable, legend }) {
  const cls = useStyles();
  const [showNodes, setShowNodes] = useState([]);
  const [showShrunk, setShowShrunk] = useState([]);
  const [scaleLevel, setScaleLevel] = useState(0);
  const [popperData, setPopperData] = useState({});
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // re-render on showNodes or showShrunk change
  }, [showNodes, showShrunk]);

  function _togglePopperData(event, data, color) {
    setAnchorEl(event.currentTarget);
    setPopperData({ ...data, color: color });
    setOpen(true);
  }

  function _toggleNode(id) {
    if (showNodes.some(x => x === id)) {
      setShowNodes(showNodes.filter(x => x !== id));
    } else {
      setShowNodes([...showNodes, id]);
    }
  }

  function _toggleShrunkNode(id) {
    if (showShrunk.some(x => x === id)) {
      setShowShrunk(showShrunk.filter(x => x !== id));
    } else {
      setShowShrunk([...showShrunk, id]);
    }
  }

  function getTree(parent, nodes, depth) {
    let limbs = [];
    let pid = parent.organization.organizationId;
    for (let i = 0; i < nodes.length; i++) {
      let n = nodes[i];
      let oid = n.organization.organizationId;
      let nodeClass = n.type.toLowerCase().replace(/\s+/g, "");
      let showAllChildren = showShrunk.some(x => x === pid) ? true : false;
      let hideChildren = showNodes.some(x => x === oid) ? true : false;
      let hasChildren = n.children && n.children.length > 0;
      let isSubLimb = depth > 1 && i >= 3 && !hasChildren;
      // Decide if this is a main limb or a subLimb
      if (depth < 2 || hasChildren || showAllChildren || (depth > 1 && i < 3)) {
        if (isSubLimb && i % 3 === 0) {
          limbs.push(<div className={cls.subLimbBranch}></div>);
        }
        limbs.push(
          <tbody key={oid}>
            <tr>
              <SideTreeNode data={n} hideChildren={hideChildren} toggleNode={_toggleNode} togglePopperData={_togglePopperData} />
              {hasChildren && (
                <Expander showFirst={true} expandIn={!hideChildren}>
                  <td>
                    <table className={`${cls.subGroup} ${cls[nodeClass]}`}>{getTree(n, n.children, depth + 1)}</table>
                  </td>
                </Expander>
              )}
            </tr>
          </tbody>
        );
      }
      // If this has subLimbs, add a show/hide button
      if ((!showAllChildren && isSubLimb && i === 3) || (showAllChildren && i === nodes.length - 1)) {
        limbs.push(
          <div key={"extra"} className={cls.shrinkNodeWrapper}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={`${cls.shrinkNode}`}
              onClick={() => {
                _toggleShrunkNode(pid);
              }}
            >
              <Grid container item xs={12} className={`${cls.shrinkNodeInner}`} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <i className={`fas fa-chevron-${showAllChildren ? "up" : "down"}`}></i>
                  &nbsp;
                  {showAllChildren ? `Hide` : `Show`} ({nodes.length - 3})
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
    return limbs;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item className={cls.sideTree}>
        <table className={`${cls.trunk} ${cls[`scale${scaleLevel}`]}`}>{getTree(data[0], data, 0)}</table>
      </Grid>
      <Grid container item xs={12}>
        <TreeFooter legend={legend} scalable={scalable} scaleLevel={scaleLevel} setScaleLevel={setScaleLevel} />
      </Grid>
      <Popper open={open} anchorEl={anchorEl} placement="left-start" className={cls.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
          mouseEvent="onMouseDown"
        >
          <Grid container item xs={12}>
            <NetworkNodeDetails data={popperData} />
          </Grid>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
}

export default TreeDiagram;
