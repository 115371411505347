import React, { useState } from "react";
import { useSlate } from "slate-react";
import { makeStyles } from "@mui/styles";

import { Select, MenuItem } from "@mui/material";

import { setFontSize } from "..";
import { constants, isFontSizeActive } from "..";
const { fontSizes } = constants;

const useStyles = makeStyles({
  select: { marginLeft: "0.25rem" }
});

function FontSizeSelect() {
  const classes = useStyles();
  const editor = useSlate();

  const [size, setSize] = useState(fontSizes[4]);
  function _changeFontSize(e) {
    const newFontSize = e.target.value;
    setSize(newFontSize);

    setFontSize(editor, newFontSize); // send command to editor
  }

  const activeSize = fontSizes.find(fs => isFontSizeActive(editor, fs)) || size;

  return (
    <Select value={activeSize} onChange={_changeFontSize} className={classes.select}>
      {fontSizes.map((fs, i) => (
        <MenuItem value={fs} key={i}>
          <span>{fs}</span>
        </MenuItem>
      ))}
    </Select>
  );
}

export default FontSizeSelect;
