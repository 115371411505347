//// GENERAL METHODS

// In case we need it.
const cookie_prefix = "bild_cloud_";

function setCookie(item_key, json_item) {
  const date = new Date(Date.now() + 2592000000); // 30 days from now
  const expire_date = date.toUTCString();

  document.cookie = `${cookie_prefix}${item_key}=${encodeURIComponent(json_item)};expires=${expire_date};path=/`;
}

function getCookie(item_key) {
  const cookie_key = `${cookie_prefix}${item_key}`;
  let cookie_value = undefined;

  const cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    const c = cookies[i].split("=");
    if (c[0].trim() === cookie_key) {
      cookie_value = c[1].trim();
      break;
    }
  }

  if (Boolean(cookie_value)) {
    try {
      return decodeURIComponent(cookie_value);
    } catch {
      return undefined;
    }
  } else return undefined;
}

function deleteCookie(item_key) {
  const date = new Date(Date.now() - 1);
  const expire_date = date.toUTCString();

  document.cookie = `${cookie_prefix}${item_key}=;expires=${expire_date};path=/`;
}

// Pulled from MDN
function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

const localStorageAvailable = storageAvailable("localStorage");

export function setItem(item_key, value) {
  const json_value = JSON.stringify(value);

  if (localStorageAvailable) {
    window.localStorage.setItem(item_key, json_value);
  } else {
    setCookie(item_key, json_value);
  }
}

export function getItem(item_key) {
  let json_item = undefined;

  if (localStorageAvailable) {
    json_item = window.localStorage.getItem(item_key);
  } else {
    json_item = getCookie(item_key);
  }

  if (Boolean(json_item)) {
    try {
      return JSON.parse(json_item);
    } catch {
      return undefined;
    }
  } else return undefined;
}

export function removeItem(item_key) {
  if (localStorageAvailable) {
    window.localStorage.removeItem(item_key);
  } else {
    deleteCookie(item_key);
  }
}

//// ~GENERAL METHODS

//// END-USER METHODS

const digest_token_key = "digest_token";
export function setDigestToken(digest_token) {
  setItem(digest_token_key, digest_token);
}
export function getDigestToken() {
  return getItem(digest_token_key);
}
export function deleteDigestToken() {
  removeItem(digest_token_key);
}

const API_token_key = "API_token";
export function setAPIToken(API_token) {
  // TODO remove "Bearer " from this call once backend does not need it
  setItem(API_token_key, "Bearer " + API_token);
}
export function getAPIToken() {
  return getItem(API_token_key);
}
export function deleteAPIToken() {
  removeItem(API_token_key);
}

const user_info_key = "user_info";
export function setUserInfo(user_info) {
  setItem(user_info_key, user_info);
}
export function getUserInfo() {
  return getItem(user_info_key);
}
export function deleteUserInfo() {
  removeItem(user_info_key);
}

const USERNAME_KEY = "username";
export function setUsername(username) {
  setItem(USERNAME_KEY, username);
}
export function getUsername() {
  return getItem(USERNAME_KEY);
}
export function deleteUsername() {
  removeItem(USERNAME_KEY);
}

const user_preferences_key = "user_preferences";
export function setUserPreferences(user_preferences) {
  setItem(user_preferences_key, user_preferences);
}
export function getUserPreferences() {
  return getItem(user_preferences_key);
}
export function deleteUserPreferences() {
  removeItem(user_preferences_key);
}

const MASQUERADE_KEY = "masquerade_info";
export function setMasqueradeInfo(masquerade_info) {
  setItem(MASQUERADE_KEY, masquerade_info);
}
export function getMasqueradeInfo() {
  return getItem(MASQUERADE_KEY);
}
export function deleteMasqueradeInfo() {
  removeItem(MASQUERADE_KEY);
}

const INTERCOM_STATE_KEY = "intercom-state";
export function deleteIntercomState() {
  removeItem(INTERCOM_STATE_KEY);
}

const CONTRACT_KEY = "contract_status";
export function setContractStatus(contract_status) {
  setItem(CONTRACT_KEY, contract_status);
}
export function getContractStatus() {
  return getItem(CONTRACT_KEY);
}
export function deleteContractStatus() {
  removeItem(CONTRACT_KEY);
}

const CONTRACT_CHECK_KEY = "contract_last_checked";
export function setContractLastChecked(contract_last_checked) {
  setItem(CONTRACT_CHECK_KEY, contract_last_checked);
}
export function getContractLastChecked() {
  return getItem(CONTRACT_CHECK_KEY);
}
export function deleteContractLastChecked() {
  removeItem(CONTRACT_CHECK_KEY);
}

const MESSAGES_CHECK_KEY = "messages_last_checked";
export function setMessagesLastChecked(messages_last_checked) {
  setItem(MESSAGES_CHECK_KEY, messages_last_checked);
}
export function getMessagesLastChecked() {
  return getItem(MESSAGES_CHECK_KEY);
}
export function deleteMessagesLastChecked() {
  removeItem(MESSAGES_CHECK_KEY);
}

const FLASH_KEY = "flash_messages";
export function setFlashMessages(flash_messages) {
  setItem(FLASH_KEY, flash_messages);
}
export function addFlashMessage(flash_message) {
  let messages = getFlashMessages();
  if (messages && messages.length > 0) {
    messages.push(flash_message);
  } else {
    messages = [flash_message];
  }
  setFlashMessages(messages);
}
export function getFlashMessages() {
  return getItem(FLASH_KEY);
}
export function deleteFlashMessages() {
  removeItem(FLASH_KEY);
}

const DOCS_TO_UPLOAD_KEY = "bild-doc-upload-queue";
export function setDocumentUploadQueue(documentUploadQueue) {
  setItem(DOCS_TO_UPLOAD_KEY, documentUploadQueue);
}
export function getDocumentUploadQueue() {
  return getItem(DOCS_TO_UPLOAD_KEY);
}
export function deleteDocumentUploadQueue() {
  removeItem(DOCS_TO_UPLOAD_KEY);
}

const FILE_DOWNLOAD_STATUS_KEY = "file_download_status";
export function getFileDownloadStatus() {
  return getItem(FILE_DOWNLOAD_STATUS_KEY);
}
export function initializeFileDownloadStatus() {
  setItem(FILE_DOWNLOAD_STATUS_KEY, []);
  // IMPORTANT need to trigger a new "event" so eventListener can be called
  window.dispatchEvent(new Event("storage"));
}
export function addToFileDownloadStatus(id, name) {
  let statuses = getItem(FILE_DOWNLOAD_STATUS_KEY);
  let newFile = { id: id, name: name };
  statuses.push(newFile);
  setItem(FILE_DOWNLOAD_STATUS_KEY, statuses);
  // IMPORTANT need to trigger a new "event" so eventListener can be called
  window.dispatchEvent(new Event("storage"));
}
export function removeFromFileDownloadStatus(id) {
  let statuses = getItem(FILE_DOWNLOAD_STATUS_KEY);
  for (let i = 0; i < statuses.length; i++) {
    if (Number(statuses[i].id) === id) {
      statuses = statuses.filter(x => Number(statuses[i].id) !== x.id);
      break;
    }
  }
  setItem(FILE_DOWNLOAD_STATUS_KEY, statuses);
  // IMPORTANT need to trigger a new "event" so eventListener can be called
  window.dispatchEvent(new Event("storage"));
}
//// ~END-USER METHODS
