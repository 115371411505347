// Hide intercom messanger (useful when it interfears with bottom, left screen)
export function hideIntercom() {
  if (window.Intercom) {
    window.Intercom("update", { hide_default_launcher: true });
  }
}

// Show intercom messanger
export function showIntercom() {
  if (window.Intercom) {
    window.Intercom("update", { hide_default_launcher: false });
  }
}

// Open intercom messanger
export function openIntercom() {
  if (window.Intercom) {
    window.Intercom("show");
  }
}
