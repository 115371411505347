import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Button, MenuItem, Select } from "@mui/material";
import { BTypography, Blink, FilterTextField } from "bild-ui";

import "./vendor.css";

const useStyles = makeStyles({
  library: {
    background: "#fff",
    padding: "1rem"
  },
  categoryTitle: {
    clear: "both",
    width: "100%",
    marginTop: "15px",
    marginBottom: "10px"
  },
  additionalCategoryTitle: {
    clear: "both",
    width: "100%",
    paddingTop: "8rem",
    marginBottom: "10px",
    borderBottom: "2px solid #000"
  },
  betaMessage: {
    color: "#cc8400",
    background: "#fff6e6",
    border: "1px solid #cc8400",
    borderRadius: "4px",
    padding: ".5rem",
    textAlign: "center",
    marginTop: "1rem"
  },
  betaMessageButton: {
    cursor: "pointer",
    color: "#cc8400",
    "& i:hover": {
      opacity: ".7"
    }
  },
  languageSelect: {
    backgroundColor: "#FFF",
    borderRadius: "4px"
  },
  languageSelectInner: {
    padding: "0.75rem 2rem !important",
    minWidth: "5rem",
    textAlign: "right",
  },
  filterWrapper: {
    padding: "0.5rem 0"
  },
  resourceTitle: {
    overflow: "hidden",
    height: "135px"
  },
  libraryBooks: {
    padding: "0",
    minHeight: "40vh"
  },
  libraryBook: {
    listStyle: "none",
    border: "1px solid #888",
    padding: "10px",
    width: "130px",
    height: "180px",
    float: "left",
    marginRight: "10px",
    display: "block",
    wordBreak: "break-word",
    marginBottom: "10px",
    color: "#fff",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "11px",
    "&:hover": {
      cursor: "pointer",
      opacity: ".8"
    }
  }
});

function EbookstoreLibrary({ books, language }) {
  let cls = useStyles();
  const [languages, setLanguages] = useState([]);
  const [localLanguage, setLocalLanguage] = useState(language ? language : "English");
  const [sortedBooks, setSortedBooks] = useState([]);
  const [filter, setFilter] = useState("");
  const [visibleBooks, setVisibleBooks] = useState(0);
  const [showMessage, setShowMessage] = useState(Math.random() >= 0.66);

  useEffect(()=>{
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }
    
    let uniqueLanguages = books.map(x => x.language).filter(onlyUnique);
    setLanguages(uniqueLanguages);
  },[]);

  useEffect(()=>{
    // TODO API needs to return sorting, otherwise we are reverting to very sketchy sort criteria
    const _sortedBooks = books
      .filter(x => !filter || filter.length < 2 || (filter.length >= 2 && ((x.title && x.title.toLowerCase().includes(filter.toLowerCase())) || (x.category && x.category.toLowerCase().includes(filter.toLowerCase())))))
      .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
      .sort((a, b) => (a.category > b.category ? 1 : -1))
      .sort((a, b) => (a.category === "The Story" ? -1 : 1))
      .sort((a, b) => (a.category === "Teaching The First Principles" ? -1 : 1))
      .sort((a, b) => (a.category === "TREN e-docs: India" && b.category === "TREN e-docs: Theological Education" ? 1 : 0))
      .sort((a, b) => (a.category === "TREN e-docs: Theological Education" && b.category === "TREN e-docs: India" ? -1 : 0))
    ;
      
    setSortedBooks(_sortedBooks);
    setVisibleBooks(_sortedBooks.filter(x => x.language && x.language.toLowerCase() === localLanguage.toLowerCase()).length);
  },[books, filter]);

  useEffect(()=>{
    setVisibleBooks(sortedBooks.filter(x => x.language && x.language.toLowerCase() === localLanguage.toLowerCase()).length);
  },[localLanguage]);

  function getLanguages() {
    let items = [];

    for (let i = 0; i < languages.length; i++) {
      const item = languages[i];
      items.push(
        <MenuItem value={item} key={i}>
          {item}
        </MenuItem>
      );
    }

    return (
      <Select value={languages && languages.length > 0 ? localLanguage : ""} onChange={(e) => {setLocalLanguage(e.target.value)}} className={cls.languageSelect} classes={{ select: cls.languageSelectInner }}>
        {items}
      </Select>
    );
  }

  function getBooks() {
    let _books = [];
    let categories = [];

    for (let i = 0; i < sortedBooks.length; i++) {
      const book = sortedBooks[i];
      const category = book.category;

      if (book.language && book.language.toLowerCase() === localLanguage.toLowerCase()) {
        // Add an additional resources tag to the start of TREN docs
        if (i > 0 && sortedBooks[i].category.includes("TREN e-docs") && !sortedBooks[i - 1].category.includes("TREN e-docs")) {
          _books.push(
            <h2 key="additional-resources" className={cls.additionalCategoryTitle}>
              Additional Resources
            </h2>
          );
        }
        if (!categories.includes(category)) {
          categories.push(category);
          _books.push(
            <h4 key={category} className={cls.categoryTitle}>
              {category}
            </h4>
          );
        }

        let content = "";
        let background = `url(${book.thumbnailUrl})`;
        if (book.thumbnailUrl === null || book.thumbnailUrl === "null") {
          content = <div className={cls.resourceTitle}>{book.title}</div>;
          background = "linear-gradient(180deg, rgba(47,34,7,1) 0%, rgba(68,46,29,1) 13%, rgba(68,51,29,0.86) 100%)";
        }

        _books.push(
          <Grid
            container
            item
            xs
            component={Blink}
            dst={"library"}
            book_id={book.id}
            language_code={book.language}
            style={{ background: `${background}` }}
            className={cls.libraryBook}
            key={i}
          >
            <li>{content}</li>
          </Grid>
        );
      }
    }

    return _books;
  }

  // TODO: Destroy this file with fire and salt, this is a port of canvas code and is hobbled together
  return (
    <Grid container>
      <Grid item xs={12} className={cls.library}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid container item xs={8} alignItems="center" justifyContent="flex-start" className={cls.libraryTitle}>
            <Grid item className={cls.filterWrapper}>{getLanguages()}</Grid>
            <Grid item className={cls.filterWrapper}>
              <FilterTextField
                value={filter}
                placeholder="Search"
                onChange={e => setFilter(e.target.value)}
                onClear={() => {
                  setFilter("");
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={4} alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Blink href="https://store.bild.org" target="_blank">
                <Button className="default-submit-button">Shop the BILD Store</Button>
              </Blink>
            </Grid>
          </Grid>
        </Grid>
        {showMessage && (
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Grid container className={cls.betaMessage} alignItems="center" justifyContent="space-evenly">
                  <Grid item xs>
                    <Typography variant="body1">
                      You are currently viewing the Beta version of BILD Resources. We anticipate releasing a fuller featured version of electronic
                      BILD resources as a paid option in the future.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      className={cls.betaMessageButton}
                      onClick={() => {
                        setShowMessage(false);
                      }}
                    >
                      <i className="fal fa-times" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <ul className={cls.libraryBooks}>
          {filter && visibleBooks < 1 && (
            <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} className={cls.libraryBooks}>
              <Grid item>
                <i><BTypography variant="h6">No resources match the search criteria for this language.</BTypography></i>
              </Grid>
            </Grid>
          )}
          {getBooks()}
        </ul>
        
      </Grid>
    </Grid>
  );
}

export default EbookstoreLibrary;
