import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { fundraisingData } from "bild-data";
import { setPageTitle, updateURL } from "bild-utils";
import { toaster } from "presentational/toasts/toasts.js";
import { LoadingScreen, SuccessScreen, BaseWrapper } from "bild-ui";
import FundraisingConstituent from "./fundraisingConstituent.js";

function FundraisingConstituentLoader({}) {
  const { fundraising_item_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [final, setFinal] = useState(false);
  const [constituent, setConstituent] = useState(false);
  const [fundraisingItemId, setFundraisingItemId] = useState(0);

  useEffect(() => {
    setPageTitle("Fundraising Constituent");
    setFundraisingItemId(fundraising_item_id);

    function _setData(m) {
      setConstituent(m);
      setLoading(false);
    }

    fundraisingData.getFundraisingConstituent(fundraising_item_id, _setData);
  }, []);

  function updateConstituent(interaction, task, phonesToRemove) {
    let data = {
      callAttemptTypeToAdd: interaction.type
    };
    if (interaction.comm && interaction.comm.length > 0) {
      data["interactionToAdd"] = { communication: interaction.comm, note: interaction.note };
    }
    if (phonesToRemove && phonesToRemove.length > 0) {
      data["phonesToRemove"] = phonesToRemove.map(p => {
        return { bloomerangPhoneId: p.id, number: p.number, note: p.note };
      });
    }
    if (task) {
      data["taskToAdd"] = { dueDate: task.date, note: task.note, purpose: task.purpose };
    }
    setLoading(true);
    fundraisingData.updateFundraisingConstituent(fundraisingItemId, data, success_cb);
  }

  function success_cb(data, _success_cb) {
    setConstituent(data);
    toaster.success("Constituent Updated! Redirecting to list...");
    setLoading(false);
    setFinal(true);

    setTimeout(() => {
      updateURL("/fundraising");
    }, 3000);
  }

  if (loading) return <LoadingScreen />;
  if (final) return <SuccessScreen message={"Constituent Updated!"} />;
  return (
    <BaseWrapper>
      <FundraisingConstituent constituent={constituent} updateConstituent={updateConstituent} />
    </BaseWrapper>
  );
}

export default FundraisingConstituentLoader;
