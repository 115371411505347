import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import FileIcon from "presentational/icons/fileIcon.js";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import dropBase from "./dragDrop.js";

const useStyles = makeStyles({
  invisible: { display: "none" },
  folderIcon: { marginLeft: "0", marginBottom: "5px" },
  typo: { color: "white" },

  container: { position: "relative" },
  bordered: { borderRadius: "4px", border: "2px dashed lightgrey", backgroundColor: "rgba(0,0,0,0.01)" },
  overlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: "0px",
    border: "4px solid #06367B",
    backgroundColor: "rgba(0, 100, 255, 0.32)"
  },

  hiddenOverlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: "0px",
    border: "4px solid #06367B",
    backgroundColor: "rgba(0, 100, 255, 0.32)"
  },

  paper: {
    textAlign: "center",
    display: "inline-block",
    backgroundColor: "#06367B",
    color: "white",
    padding: "1rem 1rem 0.5rem 1rem",
    borderRadius: "0px"
  }
});

function FileDropZone({ children, contextRoot, onUploadIntent, hidden, className, disabled }) {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  hidden = hidden === true;

  // Sets listeners on body to prevent browser default behaviour.
  useEffect(() => {
    dropBase.setup();
    return dropBase.clear;
  }, []);

  // If disabled, return now, no need to compute further.
  if (disabled) return <div className={className}>{children}</div>;

  // Drag callbacks, expect files, do not react to internal-drag
  function _onDragEnter(e) {
    e.preventDefault();
    if (![...e.dataTransfer.types].includes("bcfe internal-drag")) setActive(true);
  }
  function _onDragLeave(e) {
    e.preventDefault();
    setActive(false);
  }
  function _onDrop(e) {
    setActive(false);
    if (onUploadIntent && ![...e.dataTransfer.types].includes("bcfe internal-drag")) onUploadIntent(e.dataTransfer.files, contextRoot);
  }

  const [containerClasses, overlayClasses, paperClasses] = [[className], [], []];

  // Conditionally add classes for either hidden or not, active or not
  containerClasses.push(classes.container);
  if (!hidden) containerClasses.push(classes.bordered);
  if (hidden) overlayClasses.push(active ? classes.hiddenOverlay : classes.invisible);
  else overlayClasses.push(active ? classes.overlay : classes.invisible);
  paperClasses.push(classes.paper);

  return (
    <div onDragEnter={_onDragEnter} className={containerClasses.join(" ")}>
      {children}

      {active && (
        <div className={overlayClasses.join(" ")} onDragLeave={_onDragLeave} onDrop={_onDrop}>
          <Paper className={paperClasses.join(" ")}>
            <Typography className={classes.typo}>Drag files to upload them to:</Typography>
            {contextRoot && (
              <Typography className={classes.typo}>
                <FileIcon variant="folder" className={classes.folderIcon} /> {contextRoot.name}
              </Typography>
            )}
          </Paper>
        </div>
      )}
    </div>
  );
}

export default FileDropZone;
