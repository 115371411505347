import React from "react";
import { makeStyles } from "@mui/styles";

import { Divider, Grid } from "@mui/material";

import { MarkButton, BlockButton, SendButton } from ".";
import { colors } from "bild-ui";

const useStyles = makeStyles({
  container: {},
  button: {
    padding: "0",
    margin: "0",
    display: "inline-block",
    height: "100%",
    backgroundColor: colors.green,
    borderRadius: 0,
    "& *": { color: "white" },
    "&:hover": { backgroundColor: colors.darkGreen }
  }
});

function CommentToolbar({ marks = [], blocks = [], onSendIntent }) {
  const classes = useStyles();

  const markButtons = marks.map((m, i) => <MarkButton mark={m} key={i} />);
  const blockButtons = blocks.map((b, i) => <BlockButton block={b} key={i} />);

  return (
    <div className={classes.container}>
      <Divider style={{ backgroundColor: "grey" }} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {markButtons}
          {blockButtons}
        </Grid>
        {onSendIntent && (
          <Grid item>
            <SendButton onClick={onSendIntent} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CommentToolbar;
