import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { toaster } from "presentational/toasts/toasts.js";
import { FormTextField, FormAutocompleteField, NormalButton } from "bild-ui";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
  labelWrapper: { paddingTop: "1rem", paddingRight: "1rem" },
  fieldWrapper: { paddingTop: "1rem", paddingLeft: "1rem" },
  badgeContent: { padding: "0.25rem", color: "#9d9d9d" },
  dialog: { padding: "1rem" }
});

function ChangeOrganizationDialog({ open, currentOrg, orgType, overview, organizations, onClose, onSubmit, onSuccess }) {
  const cls = useStyles();
  let [newOrg, setNewOrg] = useState({ organizationId: -1, organizationName: null });

  function _validateOrg() {
    if (newOrg.organizationId !== -1 && newOrg.organizationId !== currentOrg.organizationId) {
      let organization = organizations.find(x => {
        return x.organizationId === newOrg.organizationId;
      });
      let userData = {
        id: overview.user.id
      };
      if (orgType === 1) {
        // Primary Organization
        userData["primaryOrganization"] = { organizationId: organization.organizationId, organizationName: organization.organizationName };
      } else if (orgType === 2) {
        // MCCEE Cohort Organization
        userData["mcceeCohortOrganization"] = { organizationId: organization.organizationId, organizationName: organization.organizationName };
      } else if (orgType === 3) {
        // BILD Primary Organization
        userData["bildPrimaryOrganization"] = { organizationId: organization.organizationId, organizationName: organization.organizationName };
      }

      onSubmit(
        userData.id,
        userData,
        data => {
          _onClose();
          onSuccess(data);
          toaster.success("User Organization Updated!");
        },
        data => {
          _onClose();
          toaster.error("Updating User Organization Failed.");
        }
      );
    }
  }

  function _onClose() {
    setNewOrg({ organizationId: -1, organizationName: null });
    onClose();
  }

  return (
    <Dialog open={open} onClose={_onClose} maxWidth="md" fullWidth>
      <DialogContent className={cls.dialog}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="h6">Change {orgType===1 ? "Primary" : orgType===2 ? "MCCEE Cohort" : orgType===3 ? "BILD Primary" : ""} Organization for {overview.user.name}</Typography>
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormTextField label="Current Organization" value={currentOrg.organizationName} disabled={true} />
          </Grid>
          <Grid item xs={12} className={cls.fieldWrapper}>
            <FormAutocompleteField
              label="New Organization"
              value={newOrg.organizationId}
              setValue={x => {
                setNewOrg({ ...newOrg, organizationId: x });
              }}
              items={organizations.map(x => {
                return { id: x.organizationId, name: x.organizationName };
              })}
              required={true}
            />
          </Grid>
          <Grid item className={cls.fieldWrapper}>
            <NormalButton
              className="default-submit-button"
              onClick={() => {
                _validateOrg();
              }}
            >
              Change
            </NormalButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ChangeOrganizationDialog;
