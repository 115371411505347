import React, { useState } from "react";
import { Grid, Typography, Divider, Tabs, Tab } from "@mui/material";
import EnrollmentContent from "./components/enrollmentContent.js";
import DashboardProgram from "containers/dashboard/components/dashboardProgram.js";
import CourseSwitchDialog from "containers/courseSubstitute/courseSwitchDialog.js";
import ContentWrapper from "presentational/wrappers/contentWrapper.js";
import classes from "./classes.js";
import "../dashboard/dashboard.css";
import "./enrollment.css";

// TRANSCRIPT
function Enrollment({ programReviewStatus, programDashboard, setProgramReviewStatus }) {
  const tabOptions = ["Overview", "Transcript", "Dashboard"];
  const [activeTab, setActiveTab] = useState(tabOptions[0]);
  const [switchCourseDialog, setSwitchCourseDialog] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isNewCourse, setIsNewCourse] = useState(null);
  

  function onTabChange(tab) {
    if (activeTab !== tab && tabOptions.includes(tab)) {
      setActiveTab(tab);
    }
  }

  function renderTabs() {
    const tabs = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const t = tabOptions[i];
      const label = <Typography variant="subtitle1">{t}</Typography>;
      tabs.push(<Tab key={i} value={t} label={label} disableRipple />);
    }

    return (
      <Tabs value={activeTab} onChange={(e, v) => onTabChange(v)} variant="scrollable" scrollButtons={false}>
        {tabs}
      </Tabs>
    );
  }

  function genSwitchCourse(course, isNewCourse) {
    setSwitchCourseDialog(true);
    setSelectedCourse(course);
    setIsNewCourse(isNewCourse);
  }

  function toggleSwitchCourse() {
    setSwitchCourseDialog(!switchCourseDialog);
    setSelectedCourse(null);
  }

  return (
    <div className={classes.container}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.header}>
        <Grid item>
          <Typography variant="h5" align="left" gutterBottom>
            {programReviewStatus.programClassName}
          </Typography>
          <Typography variant="subtitle1" align="left">
            {programReviewStatus.user.name}
          </Typography>
        </Grid>
        <Grid item className="logo">
          <img alt="School Logo" crop="fill" height="51" src={process.env.PUBLIC_URL + programReviewStatus.schoolImage} width="217" />
        </Grid>
      </Grid>

      <Divider />

      {renderTabs()}

      <Divider className={classes.mainDivider} />

      {activeTab !== tabOptions[2] && (
        <EnrollmentContent
          programClassName={programReviewStatus.programClassName}
          enrollmentId={programReviewStatus.id}
          statusItemHeaders={programReviewStatus.statusItemHeaders}
          courseGroups={programReviewStatus.courseGroups}
          contentType={activeTab}
          programDashboard={programDashboard}
          genSwitchCourse={genSwitchCourse}
        />
      )}

      {activeTab === tabOptions[2] && (
        <ContentWrapper variant="flat" noPadding>
          <DashboardProgram forceExpand program={programDashboard} />
        </ContentWrapper>
      )}

      <CourseSwitchDialog
        open={switchCourseDialog}
        toggleSwitchCourse={toggleSwitchCourse}
        enrollmentId={programReviewStatus.id}
        course={selectedCourse}
        programId={programReviewStatus.programId}
        setProgramReviewStatus={setProgramReviewStatus}
        isNewCourse={isNewCourse}
      />
    </div>
  );
}

export default Enrollment;
