import React from "react";
import { makeStyles } from "@mui/styles";

import { Typography, Grid, Button, Dialog, DialogContent } from "@mui/material";

import { colors } from "bild-ui";

const useStyles = makeStyles({
  container: { textAlign: "center" },
  content: { padding: "10px 0 25px 0" },
  reassessTitle: {
    color: colors.blue,
    "& .fa-circle": { fontSize: "3.5rem" }
  },
  validateTitle: { color: colors.green },
  iconCircle: { fontSize: "3rem", lineHeight: "4rem" },
  gridItem: { padding: "7px 0px" },
  problemHint: {
    border: `1px solid ${colors.orange}`,
    background: "#f4f4f4",
    padding: "15px 5px",
    borderRadius: "5px",
    textAlign: "left",
    "& i": {
      color: colors.orange,
      paddingRight: "10px",
      paddingLeft: "4px"
    }
  },
  caption: { opacity: 0.5 },
  confirmAgreeButton: {
    padding: "4px 16px",
    marginTop: "10px",
    width: "90%",
    "& *": {
      color: "#fff"
    },
    backgroundColor: colors.green,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: colors.green
    }
  },
  confirmCancelButton: {
    padding: "4px 16px",
    marginTop: "10px",
    width: "90%",
    "& *": { color: "#fff" },
    backgroundColor: colors.red,
    "&:hover": {
      opacity: "0.7",
      backgroundColor: colors.red
    }
  },
  disabledButton: {
    border: `1px solid ${colors.green}`,
    backgroundColor: "white",
    "& span": { color: colors.green }
  }
});

function SubmitReviewDialog({ open, direction, icon, title, message, caption, inSubmission, onSubmit, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.container} maxWidth="sm" fullWidth>
      <DialogContent className={classes.content}>
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.gridItem}>
            <span className={`fa-stack fa-2x ${direction === "validate" ? classes.validateTitle : classes.reassessTitle}`}>
              <i className={`fas fa-circle fa-stack-2x ${classes.iconCircle}`} />
              <i className={"fal fa-" + icon + " fa-stack-1x fa-inverse"} />
            </span>
          </Grid>
          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Typography variant="h5" className={direction === "validate" ? classes.validateTitle : classes.reassessTitle}>
              {title}
            </Typography>
          </Grid>
          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Typography variant="subtitle1">{message}</Typography>
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridItem}>
            <Button disabled={inSubmission} className={`${classes.confirmAgreeButton} ${inSubmission && classes.disabledButton}`} onClick={onSubmit}>
              Yes
            </Button>
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridItem}>
            <Button disabled={inSubmission} className={classes.confirmCancelButton} onClick={onClose}>
              No
            </Button>
          </Grid>
          <Grid item md={9} xs={12} className={classes.gridItem}>
            <Typography variant="body1" className={classes.caption}>
              {caption}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SubmitReviewDialog;
