import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles({
  tooltip: props => ({
    background: props.backgroundColor ? props.backgroundColor : colors.white,
    color: props.textColor ? props.textColor : colors.bildGray,
    fontSize: "1rem",
    fontWeight: "bold",
    padding: "1rem",
    border: `2px solid ${colors.lightGray}`,
    maxWidth: "50rem",
  }),
  tooltipArrow: props => ({
    "&:before": {
      border: `1px solid ${colors.lightGray}`
    },
    color: props.backgroundColor ? props.backgroundColor : colors.white
  }),
  popperWrapper: { "&:hover": { cursor: "pointer" } }
});

function PopTip({ text, backgroundColor, textColor, arrowPlacement, disableArrow, disabled, delay, popperProps, children }) {
  const cls = useStyles({ backgroundColor, textColor });

  if (disabled) return children;
  return (
    <Tooltip
      title={text}
      arrow={!disableArrow}
      placement={arrowPlacement ? arrowPlacement : "top"}
      classes={{ tooltip: cls.tooltip, arrow: cls.tooltipArrow }}
      PopperProps={popperProps ? popperProps : {}}
      enterDelay={delay ? delay : 100}
      
    >
      <span className={cls.popperWrapper}>
        {children}
      </span>
    </Tooltip>
  );
}

export default PopTip;
