import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { BTypography, FormAutocompleteField, NormalButton, colors } from "bild-ui";

export default function NetworkPartnerOrganizationDialog ({ open, onClose, allOrganizations, updatePartner }) {
  const [newOrgId, setNewOrgId] = useState(null);

  function _updatePartner() {
    if (newOrgId) {
      updatePartner({
        "organizations": [{ "organizationId": newOrgId }]
      });
      setNewOrgId(null);
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <BTypography variant="h5">Add Organization to Network Partner</BTypography>
      </DialogTitle>
      <DialogContent>
        <FormAutocompleteField
          label="Organization"
          value={newOrgId}
          setValue={x => {
            setNewOrgId(x);
          }}
          items={allOrganizations}
          required={true}
        />
      </DialogContent>
      <DialogActions>
        <NormalButton
          variant="primary"
          color={colors.bildBlue}
          hoverColor={colors.darkBildBlue}
          labelColor={colors.white}
          disabled={!newOrgId}
          onClick={_updatePartner}
        >
          Add
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
}