import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, Expander, NetworkNodeDetails, TreeNode, TreeFooter } from "bild-ui";
import { Grid, Popper, ClickAwayListener } from "@mui/material";
import { ENUMS } from "bild-data";

const useStyles = makeStyles({
  // Huge thanks to https://codepen.io/philippkuehn/pen/QbrOaN
  tree: props => ({
    overflowY: "auto",
    maxHeight: props.maxContainerHeight ? props.maxContainerHeight : "65vh",
    overflowX: "auto",
    padding: "0 1rem",
    transition: "all 1s ease",
    "& ul": {
      position: "relative",
      padding: "2vh 0",
      top: "1px",
      whiteSpace: "nowrap",
      textAlign: "center"
    },
    "& ul::after": { content: "''", display: "table", clear: "both" },
    "& ul ul::before": {
      content: "''",
      position: "absolute",
      top: "0",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`,
      width: "0",
      height: "2vh"
    }
    // HOVER CODE
    // "& li span:hover, & li span:hover + ul li span": {
    //   background: colors.bildBlue,
    //   color: colors.white,
    //   border: `1px solid ${colors.lightBlue}`
    // },
    // "& li span:hover + ul li::after, & li span:hover + ul li::before, & li span:hover + ul::before, & li span:hover + ul ul::before": {
    //   borderColor: colors.lightBlue
    // }
  }),

  limb: {
    display: "table-cell",
    verticalAlign: "top",
    textAlign: "center",
    listStyleType: "none",
    position: "relative",
    padding: "2vh 0.5rem 0 0.5rem",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: "0",
      right: "50%",
      borderTop: `1px solid ${colors.gray}`,
      width: "50%",
      height: "2vh"
    },
    "&::after": {
      right: "auto",
      left: "50%",
      borderLeft: `1px solid ${colors.gray}`
    },
    "&:only-child::after, &:only-child::before": {
      display: "none"
    },
    "&:only-child": { paddingTop: "0" },
    "&:first-child::before, &:last-child::after": {
      border: "0 none"
    },
    "&:last-child::before": {
      borderRight: `1px solid ${colors.gray}`,
      borderRadius: "0 5px 0 0"
    },
    "&:first-child::after": { borderRadius: "5px 0 0 0" }
  },
  lastLimb: {
    "&::before": {
      borderRadius: "0 5px 0 0",
      borderLeft: `none !important`
    },
    "&::after": {
      borderTop: "none !important",
      borderRadius: "0 5px 0 0",
      left: "auto",
      right: "50%",
      borderLeft: "0",
      borderRight: "1px solid #bdbdbd"
    }
  },
  subLimb: {
    display: "table-cell",
    verticalAlign: "top",
    textAlign: "center",
    listStyleType: "none",
    position: "relative",
    padding: "1vh 0.5rem 0 0.5rem"
  },
  subLimbBranch: {},
  noChildrenLimb: {
    padding: "2vh 0.5rem 0 0.5rem !important",
  },
  shrinkNodeInner: {
    border: `2px solid ${colors.bildBlue}`,
    padding: "0.5rem 0",
    background: colors.white,
    color: colors.bildBlue,
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  shrinkNodeWrapper: {
    padding: "1rem 0.5rem",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  shrinkNode: {
    maxWidth: "8rem"
  },
  hasChildren: {
    padding: "0.25rem 0.5rem",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.95)",
      background: colors.veryLightGray
    }
  },
  // Scale Levels
  trunk: { transformOrigin: "top left", transform: "scale(1)" },
  scale0: { transform: "scale(1)" },
  scale1: { transform: "scale(0.80)" },
  scale2: { transform: "scale(0.60)" },
  scale3: { transform: "scale(0.50)" },
  scale4: { transform: "scale(0.40)" },
  scale5: { transform: "scale(0.35)" },
  popper: {
    maxWidth: "25rem",
    background: colors.white,
    borderRadius: "4px",
    padding: "0rem"
  }
});

function TreeDiagram({ data, scalable, defaultScale, legend, highlightId, maxContainerHeight }) {
  const cls = useStyles({ maxContainerHeight });
  const [showNodes, setShowNodes] = useState([]);
  const [showShrunk, setShowShrunk] = useState([]);
  const [scaleLevel, setScaleLevel] = useState(defaultScale ? defaultScale : 0);
  const [popperData, setPopperData] = useState({});
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hiddenTypeKeys, setHiddenTypeKeys] = useState([
    ENUMS.LOCAL_ENTITY_TYPES.GRT.NAME,
    ENUMS.LOCAL_ENTITY_TYPES.PRT.NAME,
    ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_3.NAME,
    ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_2.NAME,
    ENUMS.LOCAL_ENTITY_TYPES.NETWORK_LEVEL_1.NAME,
    ENUMS.LOCAL_ENTITY_TYPES.LOCAL_ORGANIZATION.NAME
  ]);

  useEffect(() => {
    // re-render on showNodes or showShrunk change
  }, [showNodes, showShrunk]);

  // Set HiddenTypeKeys only on load, and only once
  useEffect(() => {
    let typeKeys = [];
    function findTypeKeys(node) {
      let children = node.children;
      if (children) {
        for (let i=0; i<children.length; i++) {
          findTypeKeys(children[i]);
        }
      }

      typeKeys.push(node.type);
    }
    findTypeKeys(data[0]);
    let foundKeys = Array.from(new Set(typeKeys));

    setHiddenTypeKeys(foundKeys);
  }, []);

  function _togglePopperData(event, data, color) {
    setAnchorEl(event.currentTarget);
    setPopperData({ ...data, color: color });
    setOpen(true);
  }

  function _toggleNode(id) {
    if (showNodes.some(x => x === id)) {
      setShowNodes(showNodes.filter(x => x !== id));
    } else {
      setShowNodes([...showNodes, id]);
    }
  }

  function _toggleShrunkNode(id) {
    if (showShrunk.some(x => x === id)) {
      setShowShrunk(showShrunk.filter(x => x !== id));
    } else {
      setShowShrunk([...showShrunk, id]);
    }
  }

  function getTree(parent, nodes, depth) {
    let limbs = [];
    let pid = parent.organization.organizationId;
    let sectionCounts = [];
    for (let i = 0; i < nodes.length; i++) {
      let n = nodes[i];
      // Section Count per depth, currently unused
      if (!sectionCounts[n.type]) {
        sectionCounts[n.type] = 1;
      } else {
        sectionCounts[n.type] += 1;
      }
      let oid = n.organization.organizationId;
      let showAllChildren = showShrunk.some(x => x === pid) ? true : false;
      let hideChildren = showNodes.some(x => x === oid) ? true : false;
      let hasChildren = n.children && n.children.length > 0;
      // If the top-most layer only has a single child then don't treat it as the true "parent" and set the depth to 1 less than current
      let realDepth = data[0].children.length > 1 ? depth : depth - 1;
      let isSubLimb = realDepth > 1 && i >= 3 && !hasChildren;
      let highlightNode = highlightId && highlightId === oid ? true : false;
      // Decide if this is a main limb or a subLimb
      if (realDepth < 2 || hasChildren || showAllChildren || (realDepth > 1 && i < 3)) {
        if (isSubLimb && i % 3 === 0) {
          limbs.push(<div className={cls.subLimbBranch}></div>);
        }
        limbs.push(
          <li
            key={oid}
            className={`${realDepth > 1 && nodes.length >= 3 && i === 2 && !hasChildren ? cls.lastLimb : ""} ${isSubLimb ? cls.subLimb : cls.limb} ${!hasChildren ? cls.noChildrenLimb : ""}`}
          >
            <TreeNode data={n} hideChildren={hideChildren} toggleNode={_toggleNode} togglePopperData={_togglePopperData} highlightNode={highlightNode}/>
            {hasChildren && (
              <Expander showFirst={true} expandIn={!hideChildren}>
                <ul>{getTree(n, n.children, realDepth + 1)}</ul>
              </Expander>
            )}
          </li>
        );
      }
      // If this has subLimbs, add a show/hide button
      if ((!showAllChildren && isSubLimb && i === 3) || (showAllChildren && i === nodes.length - 1)) {
        limbs.push(
          <div key={"extra"} className={cls.shrinkNodeWrapper}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              className={`${cls.shrinkNode}`}
              onClick={() => {
                _toggleShrunkNode(pid);
              }}
            >
              <Grid container item className={`${cls.shrinkNodeInner}`} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <i className={`fas fa-chevron-${showAllChildren ? "up" : "down"}`}></i>
                  &nbsp;
                  {showAllChildren ? `Hide` : `Show`} ({nodes.length - 3})
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
    return limbs;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item className={cls.tree}>
        <ul className={`${cls.trunk} ${cls[`scale${scaleLevel}`]}`}>{getTree(data[0], data, 0)}</ul>
      </Grid>
      <Grid container item xs={12}>
        <TreeFooter legend={legend} scalable={scalable} scaleLevel={scaleLevel} setScaleLevel={setScaleLevel} hiddenTypeKeys={hiddenTypeKeys} />
      </Grid>
      <Popper open={open} anchorEl={anchorEl} placement="right-start" className={cls.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
          mouseEvent="onMouseDown"
        >
          <Grid container item xs={12}>
            <NetworkNodeDetails data={popperData} />
          </Grid>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
}

export default TreeDiagram;
