import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { BTypography, NormalButton, colors, SortableList, PopTip } from "bild-ui";
import { ENUMS } from "bild-data";
import { getAbbreviatedDate, getEnumByKeyValue } from "bild-utils";
import NetworkPartnerStatusDialog from "./networkPartnerStatusDialog";

const useStyles = makeStyles({
  header: { padding: "0 0.25rem 0.5rem 0.25rem" },
  sectionInner: { minHeight: "20rem", maxHeight: "25rem", overflowY: "auto", },
  status: { fontWeight: "bold", textAlign: "center" },
  greenStatus: { color: colors.darkGreen },
  yellowStatus: { color: colors.darkerYellow },
  redStatus: { color: colors.darkRed },
});

export default function NetworkPartnerStatuses({ partner, statuses, updatePartner }) {
  const cls = useStyles();
  const [statusRows, setStatusRows] = useState([]);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDetails, setStatusDetails] = useState(null);

  useEffect(()=>{
    if (statuses) {
      setStatusRows(statuses.map(x=>{
        return [
          [
            <BTypography className={`${cls.status} ${_getStatusClass(x)}`} sortval={x.statusId} key={"00"}>
              <PopTip text={getEnumByKeyValue(ENUMS.NETWORK_PARTNERS.STATUSES, "ID", x.statusId, "NAME")}>
                <i className={"fad fa-circle fa-swap-opacity"} />
              </PopTip>
            </BTypography>
          ],
          getAbbreviatedDate(x.statusDate),
          [
            <BTypography className={cls.statusSubject} sortval={x.noteSubject} key={"11"}>
              {x.noteSubject}
            </BTypography>
          ],
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <NormalButton
                variant="micro"
                color={colors.offWhite}
                borderColor={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.darkBildBlue}
                className={cls.detailButton}
                onClick={()=>{
                  _openStatusDialog(x);
                }}
              >
                <i className="far fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
        ];
      }));
    } else {
      setStatusRows([]);
    }
  },[statuses])

  function _openStatusDialog(s) {
    setStatusDialog(true);
    setStatusDetails(s);
  }

  function _closeStatusDialog() {
    setStatusDialog(false);
    setStatusDetails(null);
  }

  function _getStatusClass(s) {
    if (s) {
      return s.statusId === 1 ? cls.greenStatus : (s.statusId === 2 ? cls.yellowStatus : (s.statusId === 3 ? cls.redStatus : ""));
    }
  }

  return (
    <>
      <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.header}>
        <Grid item xs>
          <BTypography variant="h5">All Statuses</BTypography>
        </Grid>
        <Grid item>
          <NormalButton
            variant="primary"
            color={colors.bildBlue}
            hoverColor={colors.darkBildBlue}
            labelColor={colors.white}
            onClick={()=>{
              setStatusDialog(true);
            }}
          >
            <i className="far fa-plus" />
          </NormalButton>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${cls.sectionInner} ${cls.sectionDiagram}`}>
        <SortableList
          headers={[" ", "Date", "Subject", ""]}
          items={statusRows}
          spacing={[1,4,6,1]}
          itemPadding={"0.25rem 0.5rem"}
          emptyMessage={"No statuses for this partner."}
          defaultSortCol={1}
          defaultSortDir={1}
        />
      </Grid>
      <NetworkPartnerStatusDialog open={statusDialog} onClose={_closeStatusDialog} status={statusDetails} updatePartner={updatePartner} />
    </>
  );
}