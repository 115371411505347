import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { managerData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import OrgUserCertificates from "./orgUserCertificates.js";

function OrgUserCertificatesLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [org, setOrg] = useState({});
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    function _setData(d) {
      setPageTitle("Organization User Certificates");
      setOrg(d.organization);
      setCertificates(d.certificates);
      setLoading(false);
    }

    managerData.getManagedOrganizationUserCertificates(organization_id, _setData, e => {
      setError(e.response.data.error);
    });
  }, []);

  if (loading || error) return <LoadingScreen error={error} />;
  return <OrgUserCertificates organization={org} certificates={certificates} />;
}

export default OrgUserCertificatesLoader;
