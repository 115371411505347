import React, { useState, useEffect } from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import MessageMenu from "./messageMenu.js";
import MessageBlock from "./messageBlock.js";
import CreateChannelDialog from "./createChannelDialog.js";
import UpdateChannelDialog from "./updateChannelDialog.js";

const useStyles = makeStyles({
  containerWrapper: { height: "100%", minHeight: "75vh" },
  menuWrapper: { backgroundColor: colors.lightGray },
  messagesWrapper: { backgroundColor: colors.white },
  messages: {}
});

function Messages({ user, channels, startChannelId, setChannelId, recipients, createMessage, createChannel, updateChannel, readChannel, isLoadingMessages, isMessageSending }) {
  const cls = useStyles();
  const [currentChannelId, setCurrentChannelId] = useState(startChannelId ? startChannelId : channels[0] ? channels[0].id : null);
  const [dialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const currentChannel = channels && channels.length > 0 && currentChannelId ? channels.filter(x => x.id === currentChannelId)[0] : null;

  useEffect(() => {
    if (startChannelId) {
      setCurrentChannelId(startChannelId);
    }
  }, [startChannelId]);

  useEffect(() => {
    if (setChannelId) {
      setChannelId(currentChannelId);
    }
  }, [currentChannelId]);

  function _setCurrentChannelId(id) {
    // If this new channel is unread, mark it as read
    if (!channels.filter(x => x.id === id)[0].read) {
      readChannel(id);
    }
    setCurrentChannelId(id);
  }

  function _openChannel(m) {
    createChannel("", m, 1);
  }

  function _createMessage(m) {
    createMessage(currentChannelId, m, 1);
  }

  return (
    <Grid container item xs={12} className={cls.containerWrapper} direction="row" justifyContent="flex-start" alignItems="stretch">
      <Grid container item lg={2} md={3} xs={3} justifyContent="flex-start" alignItems="stretch" className={cls.menuWrapper}>
        <MessageMenu
          channels={channels}
          currentChannel={currentChannelId}
          setCurrentChannel={_setCurrentChannelId}
          newChannel={() => {
            setDialog(true);
          }}
        />
      </Grid>
      <Grid container item lg={10} md={9} xs={9} justifyContent="flex-start" alignItems="flex-start" className={cls.messagesWrapper}>
        <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start" className={cls.messages}>
          {currentChannel && (
            <MessageBlock
              user={user}
              channel={currentChannel}
              messages={currentChannel.messages}
              openChannel={_openChannel}
              createMessage={_createMessage}
              updateChannel={() => {
                setEditDialog(true);
              }}
              isLoadingMessages={isLoadingMessages}
              isMessageSending={isMessageSending}
            />
          )}
        </Grid>
      </Grid>
      <CreateChannelDialog
        open={dialog}
        closeDialog={() => {
          setDialog(false);
        }}
        user={user}
        recipients={recipients}
        createChannel={createChannel}
      />
      <UpdateChannelDialog
        open={editDialog}
        closeDialog={() => {
          setEditDialog(false);
        }}
        user={user}
        channel={currentChannel}
        recipients={recipients}
        updateChannel={updateChannel}
      />
    </Grid>
  );
}

export default Messages;
