import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, BTypography, NormalButton, FormTextField, FormNumberField, Avatar } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import DataReportForm from "./components/dataReportForm.js";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    container: { background: colors.white, padding: "1.5rem" },
    completionWrapper: {
      padding: "0 1rem 1rem 1rem",
      fontWeight: "bold",
      color: colors.white
    },
    completionCheck: {
      padding: "0.5rem 0.25rem 0.5rem 1rem",
      background: colors.green
    },
    completion: {
      padding: "0.5rem 1rem 0.5rem 0.25rem",
      background: colors.green
    },
    posWrapper: { padding: "0 1rem" },
    formWrapper: { padding: "1rem" },
    form: { maxWidth: "600px" },
    buttonWrapper: { padding: "0 1rem 1rem 1rem" }
  })
);

function DataReport({ completed, userLearningItem, submitReport, color }) {
  const cls = useStyles();
  const [formData, setFormData] = useState({
    str1Value: "",
    str2Value: "",
    str3Value: "",
    str4Value: "",
    str5Value: "",
    num1Value: "",
    num2Value: "",
    num3Value: "",
    num4Value: "",
    num5Value: ""
  });
  const [entryPos, setEntryPos] = useState(userLearningItem.dataReport.entries && userLearningItem.dataReport.entries.length > 0 ? 0 : -1);
  const [savedPos, setSavedPos] = useState(false);

  useEffect(() => {
    if (savedPos) {
      setEntryPos(entryPos + 1);
      setSavedPos(false);
    }
    setFormData({
      str1Value: "",
      str2Value: "",
      str3Value: "",
      str4Value: "",
      str5Value: "",
      num1Value: "",
      num2Value: "",
      num3Value: "",
      num4Value: "",
      num5Value: ""
    });
  }, [userLearningItem, completed]);

  function _setEntryPos(newPos) {
    if (newPos === -1 && entryPos > 0) {
      setEntryPos(entryPos - 1);
    } else if (newPos === 1 && entryPos < userLearningItem.dataReport.entries.length) {
      setEntryPos(entryPos + 1);
    }
  }

  function _submitReport(data) {
    setSavedPos(true);
    submitReport(data);
  }

  return (
    <Grid container className={cls.container}>
      <Grid container item xs={12} className={cls.completionWrapper}>
        {completed && (
          <Grid container item justifyContent="flex-end" alignItems="stretch">
            <Grid item className={cls.completionCheck}>
              <i className="fas fa-check" />
            </Grid>
            <Grid item className={cls.completion}>
              <BTypography variant="h6">Completed</BTypography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} className={cls.posWrapper} justifyContent="space-between" alignItems="center">
        <Grid item>
          {entryPos <= userLearningItem.dataReport.entries.length - 2 && (
            <NormalButton
              onClick={() => {
                setEntryPos(entryPos + 1);
              }}
              variant="secondary"
              color={colors.white}
              labelColor={color}
              hoverColor={color}
              borderColor={color}
            >
              <i className="fal fa-long-arrow-left" />
              <BTypography>Previous ({userLearningItem.dataReport.entries.length - entryPos - 1})</BTypography>
            </NormalButton>
          )}
        </Grid>
        <Grid item>
          {entryPos === 0 && (
            <NormalButton
              onClick={() => {
                setEntryPos(entryPos - 1);
              }}
              variant="primary"
              color={colors.bildBlue}
              hoverColor={colors.darkBildBlue}
              labelColor={colors.white}
              borderColor={colors.bildBlue}
            >
              <BTypography>Create New</BTypography>
            </NormalButton>
          )}
          {entryPos > 0 && (
            <NormalButton
              onClick={() => {
                setEntryPos(entryPos - 1);
              }}
              variant="secondary"
              color={colors.white}
              hoverColor={color}
              labelColor={color}
              borderColor={color}
            >
              <BTypography>Next ({entryPos})</BTypography>
              <i className="fal fa-long-arrow-right" />
            </NormalButton>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.formWrapper}>
        <Grid item xs={12} className={cls.form}>
          <DataReportForm
            form={userLearningItem.dataReport}
            color={color}
            oldEntry={userLearningItem.dataReport.entries[entryPos]}
            formData={formData}
            setFormData={setFormData}
            submitReport={_submitReport}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DataReport;
