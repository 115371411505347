import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import Dialog from "@mui/material/Dialog";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

import { uploadManager } from "bild-utils";

const useStyles = makeStyles({
  dialog: { padding: "1rem" },
  popper: { position: "absolute !important", bottom: "0 !important", right: "0 !important", top: "initial !important", left: "initial !important" },
  paper: { margin: "1rem", minWidth: "250px" },
  progressItem: { borderBottom: "1px solid lightgrey", padding: "1rem", "&:last-child": { borderBottom: "none" } },
  progressComplete: { borderRadius: "50vh", fontSize: "27px", height: "40px", width: "40px", lineHeight: "40px", textAlign: "center" },
  progressFailed: { border: "2px solid #ff0000", color: "#ff0000" },
  progressSuccess: { border: "2px solid #138c21", color: "#138c21" }
});

function ProgressItem({ indicator, onDismiss }) {
  const classes = useStyles();

  const status = indicator.status;
  let indicatorIcon;
  if (status === "active") indicatorIcon = <CircularProgress variant="indeterminate" />;
  else if (status === "success") {
    indicatorIcon = <Icon className={`fal fa-check ${classes.progressComplete} ${classes.progressSuccess}`} />;
  } else if (status === "fail") {
    indicatorIcon = <Icon className={`fal fa-times ${classes.progressComplete} ${classes.progressFailed}`} />;
  }

  return (
    <Fade in={status === "active"} timeout={{ enter: 1000, exit: 5000 }}>
      <Paper className={classes.paper}>
        <div className={classes.progressItem}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h6">{indicatorIcon}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Uploading
              </Typography>
              <Typography variant="body1" gutterBottom>
                {indicator.name}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Fade>
  );
}

function UploadProgressModal({ uploadIndicators, variant }) {
  const classes = useStyles();
  const [indicators, setIndicators] = useState([]);
  useEffect(() => {
    uploadManager.onChange = () => {
      setIndicators([...uploadManager.getIndicators()]);
    };
  }, []);

  const indicatorComponents = [];
  for (let i = 0; i < indicators.length; i++) {
    const indic = indicators[i];
    indicatorComponents.push(<ProgressItem indicator={indic} key={indic.name} />);
  }

  const open = indicators.length > 0;

  if (variant === "dialog") {
    return (
      <Dialog keepMounted open={open} className={classes.dialog}>
        {indicatorComponents}
      </Dialog>
    );
  } else {
    return (
      <Popper open={open} className={classes.popper}>
        {indicatorComponents}
      </Popper>
    );
  }
}

export default UploadProgressModal;
