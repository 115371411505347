// https://material-ui.com/layout/use-media-query/
// When hook stable, should use
import { useState, useEffect } from "react";

export default function useWidth() {
  const breakpoints = new Map([["sm", 600], ["md", 960], ["lg", 1280], ["xl", 1920]]);
  function getBreakpoint() {
    const w =
      [...breakpoints.keys()].reverse().reduce((output, key) => {
        const matches = breakpoints.get(key) < window.innerWidth;

        return !output && matches ? key : output;
      }, null) || "xs";

    return w;
  }

  const [width, setWidth] = useState(getBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      const width = getBreakpoint();
      setWidth(width);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}
