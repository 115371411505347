import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { Grid, Input, ClickAwayListener } from "@mui/material";

import { toaster } from "presentational/toasts/toasts.js";
import { updateURL } from "bild-utils";

const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "calc(100% - 2px)",
    marginTop: "1px"
  },
  searchButton: {
    cursor: "pointer",
    position: "absolute",
    top: "0",
    right: "0",
    width: "47px",
    height: "100%",
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)" },
    "& i": { color: "white", transition: "color 0.1s" }
  },
  searchButtonOpen: {
    cursor: "pointer",
    position: "absolute",
    top: "0",
    right: "0",
    width: "47px",
    height: "100%",
    backgroundColor: "white",
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)" },
    "& i": { color: "black", transition: "color 0.1s" }
  },
  inputRootOpen: {
    backgroundColor: "white",
    margin: "0",
    height: "100%",
    width: "230px",
    transition: "background-color 0.1s ease-out, width 0.1s ease-in"
  },
  inputRoot: {
    backgroundColor: "transparent",
    margin: "0",
    height: "100%",
    width: "47px",
    transition: "width 0.1s ease-out, background-color 0.1s ease-out"
  },
  input: { height: "100%", boxSizing: "border-box", padding: "0 52px 0 0.75rem" }
});

function ExpandingFieldSearchButton({ inputRef, onChange, children, ...props }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const listenClickAway = useRef(false);
  const searchTerm = useRef(null);

  // Set CTRL + SHIFT keybind for searchBar shortcut
  useEffect(() => {
    const keyPressHandler = e => {
      if (e.ctrlKey === true && e.shiftKey === true) {
        setOpen(true);
        inputRef.current.focus();
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [inputRef]);

  function _open() {
    setTimeout(() => {
      listenClickAway.current = true;
      if (onChange) onChange(searchTerm.current);
    }, 200);
    setOpen(true);
  }
  function _close() {
    listenClickAway.current = false;
    if (onChange) onChange(null);
    setOpen(false);
  }

  function _onSubmit(searchTerm) {
    if (searchTerm.length === 0 || (isNaN(searchTerm) && searchTerm.length < 2)) {
      toaster.error("Searches must be at least 1 number or 2 characters long.");
    } else {
      updateURL("/search/" + searchTerm);
    }
  }

  function _onClick(e) {
    if (!open && inputRef.current) {
      inputRef.current.focus();
    }

    if (open && searchTerm.current) {
      _onSubmit(searchTerm.current);
      return;
    }

    if (!open) _open();
    else _close();
  }

  function _onChange(e) {
    const val = e.target ? e.target.value : null;

    if (val !== "" && searchTerm.current !== val) searchTerm.current = val;
    else searchTerm.current = null;

    if (onChange) onChange(searchTerm.current);
  }

  function _detectEnterKey(e) {
    if (e.keyCode === 13 && searchTerm.current) _onSubmit(searchTerm.current);
  }

  function _onClickAway(e) {
    if (open && listenClickAway.current) _close();
  }

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={_onClickAway}>
        <div className={classes.container}>
          <Input
            margi="dense"
            disableUnderline
            inputProps={{ className: classes.input, ref: inputRef }}
            className={open ? classes.inputRootOpen : classes.inputRoot}
            placeholder={open ? "Search..." : ""}
            onChange={_onChange}
            onKeyDown={_detectEnterKey}
          />

          <Grid container justifyContent="center" alignItems="center" className={open ? classes.searchButtonOpen : classes.searchButton} onClick={_onClick}>
            <i className={`fad fa-search fa-lg`} />
          </Grid>

          {children}
        </div>
      </ClickAwayListener>
    </React.Fragment>
  );
}

export default ExpandingFieldSearchButton;
