import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import ReactHtmlParser from "react-html-parser";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Typography, Divider } from "@mui/material";
import SortButton from "presentational/input/sortButton.js";
import Bbutton from "presentational/input/bbutton.js";

const useStyles = makeStyles({
  table: { minWidth: "100%" },
  divider: { margin: "0.5rem 0 1rem 0" },
  tableHeader: { backgroundColor: colors.lightGray, fontWeight: "bold", padding: "0.75rem" },
  filterWrapper: { textAlign: "right" },
  container: { maxHeight: "90vh" }
});

function TrainingModulesReview({ trainingModules, trainee }) {
  const classes = useStyles();
  const [rows, setRows] = useState(null);
  const [important, setImportant] = useState(true);
  const [sort, setSort] = useState([]);

  function createData(id, userId, name, important, selfAssessed, failed, passed, date, totals2018, assessments2018, totals2007, assessments2007) {
    return { id, userId, name, important, selfAssessed, failed, passed, date, totals2018, assessments2018, totals2007, assessments2007 };
  }

  function sortRows(field) {
    let newRows = [...rows];
    let newSort = [];
    if (!sort[field] || sort[field] === "descending") {
      newRows.sort((a, b) => a[field].localeCompare(b[field]));
      newSort[field] = "ascending";
    } else {
      newRows.sort((a, b) => b[field].localeCompare(a[field]));
      newSort[field] = "descending";
    }
    setRows(newRows);
    setSort(newSort);
  }

  useEffect(() => {
    let rs = [];
    for (let i = 0; i < trainingModules.length; i++) {
      let tm = trainingModules[i];
      rs.push(
        createData(
          tm.trainingModule.id,
          tm.trainee.id,
          tm.trainingModule.name,
          tm.trainingModule.important,
          tm.selfAssessed ? "Y" : "",
          tm.failedAssessments,
          tm.passedAssessments,
          tm.approvalDate,
          tm.competenciesTotal2018,
          tm.competenciesAssessed2018,
          tm.competenciesTotal2007,
          tm.competenciesAssessed2007
        )
      );
    }
    setRows(rs);
  }, [trainingModules]);

  if (rows) {
    return (
      <Grid container>
        <Grid container item xs={12} className={classes.header} justifyContent="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h5">{trainee}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.filterWrapper}>
            <label>
              Important <Checkbox checked={important} onChange={() => setImportant(!important)} />
            </label>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}></TableCell>
                  <TableCell className={classes.tableHeader}>
                    <Typography variant="subtitle1">
                      Training Module
                      <SortButton
                        value={sort["name"] ? sort["name"] : "descending"}
                        onChange={() => {
                          sortRows("name");
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    <Typography variant="subtitle1">
                      Self Completed
                      <SortButton
                        value={sort["selfAssessed"] ? sort["selfAssessed"] : "descending"}
                        onChange={() => {
                          sortRows("selfAssessed");
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="right">
                    <Typography variant="subtitle1">Returned Assessments</Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="right">
                    <Typography variant="subtitle1">Confirmed Assessments</Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="right">
                    <Typography variant="subtitle1">Module Training Completed</Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="right">
                    <Typography variant="subtitle1">2018 Competencies Assessed</Typography>
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="right">
                    <Typography variant="subtitle1">2007 Competencies Assessed</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  if (important && !row.important) {
                    return null;
                  } else {
                    return (
                      <TableRow hover key={row.name}>
                        <TableCell component="th" scope="row">
                          <Bbutton variant="submit" dst="training_module_review" user_id={row.userId} training_module_id={row.id}>
                            <i className={`fal fa-chevron-right`} />
                          </Bbutton>
                        </TableCell>
                        <TableCell align="left"><Typography variant="body1">{ReactHtmlParser(row.name)}</Typography></TableCell>
                        <TableCell align="center"><Typography variant="body1">{row.selfAssessed}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="body1">{row.failed}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="body1">{row.passed}</Typography></TableCell>
                        <TableCell align="center"><Typography variant="body1">{row.date}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="body1">{row.totals2018 ? `${row.assessments2018} / ${row.totals2018}` : ""}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="body1">{row.totals2007 ? `${row.assessments2007} / ${row.totals2007}` : ""}</Typography></TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

export default TrainingModulesReview;
