import React, { useEffect, useState } from "react";
import { colors, BTypography, CircleButton } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip } from "@mui/material";

const useStyles = makeStyles({
  row: { borderBottom: `1px solid ${colors.lightGray}` },
  description: { padding: "0.5rem" },
  criteriaRow: {
    padding: "1rem 0rem",
    backgroundSize: "60% 100%",
    backgroundPositionX: "50%",
    backgroundRepeat: "no-repeat"
  }
});

function EquipCriteria({ id, name, description, updateScore, readOnly, defaultScore }) {
  const cls = useStyles();
  const [val, setVal] = useState(null);

  useEffect(() => {
    setVal(defaultScore);
  }, [defaultScore]);

  function _setVal(newVal) {
    if (!readOnly) {
      setVal(newVal);
      updateScore(id, newVal);
    }
  }

  const spacing = 6;

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center" className={cls.row}>
      <Grid item xs={7} className={cls.description}>
        {description}
      </Grid>
      <Grid container item xs={5} className={cls.criteriaRow}>
        <Grid item xs={spacing}>
          <CircleButton clicked={val === false} color={colors.orange} icon="check" onClick={() => _setVal(false)} enabled={!readOnly} />
        </Grid>
        <Grid item xs={spacing}>
          <CircleButton clicked={val === true} color={colors.green} icon="check" onClick={() => _setVal(true)} enabled={!readOnly} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EquipCriteria;
