import React from "react";
import { colors, NormalButton, BTypography } from "bild-ui";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Grid, Card, CardContent } from "@mui/material";
import EquipRubric from "equip/rubric/equipRubric.js";
import AssessmentCardUser from "equip/assessment/components/assessmentCardUser.js";

const useStyles = makeStyles((theme: useTheme) =>
  createStyles({
    header: { paddingBottom: "1rem", minHeight: "6rem" }
  })
);

function AssessmentCard({ currentRubric, artifacts, history, pos, setPos, submitScore, onCancel }) {
  const cls = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        {history && history.length > 0 && (
          <Grid container justifyContent="space-between" className={cls.header}>
            <Grid container item xs={3} justifyContent="flex-start" alignItems="center">
              {pos <= history.length - 2 && (
                <NormalButton
                  onClick={() => {
                    setPos(pos + 1);
                  }}
                  variant="secondary"
                  color={colors.white}
                  hoverColor={colors.lightBlue}
                  labelColor={colors.lightBlue}
                  borderColor={colors.lightBlue}
                >
                  <i className="fal fa-long-arrow-left" />
                  <BTypography>Previous ({history.length - pos - 1})</BTypography>
                </NormalButton>
              )}
            </Grid>
            {history && history[pos] && (
              <Grid item xs={6}>
                <AssessmentCardUser history={history[pos]} />
              </Grid>
            )}
            <Grid container item xs={3} justifyContent="flex-end" alignItems="center">
              {pos === 0 && (
                <NormalButton
                  onClick={() => {
                    setPos(pos - 1);
                  }}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  borderColor={colors.bildBlue}
                >
                  <BTypography>Create New</BTypography>
                </NormalButton>
              )}
              {pos > 0 && (
                <NormalButton
                  onClick={() => {
                    setPos(pos - 1);
                  }}
                  variant="secondary"
                  color={colors.white}
                  hoverColor={colors.lightBlue}
                  labelColor={colors.lightBlue}
                  borderColor={colors.lightBlue}
                >
                  <BTypography>Next ({pos})</BTypography>
                  <i className="fal fa-long-arrow-right" />
                </NormalButton>
              )}
            </Grid>
          </Grid>
        )}
        <EquipRubric
          rubric={pos >= 0 ? history[pos].rubric : currentRubric}
          readOnly={pos >= 0}
          hasFiles={artifacts && artifacts.length > 0}
          history={pos >= 0 ? history[pos] : null}
          onSubmit={submitScore}
        />
        <Grid container>
          <Grid item>
            {onCancel && (
              <NormalButton
                onClick={() => {
                  onCancel(false);
                }}
                className={cls.button}
              >
                <BTypography>Cancel</BTypography>
              </NormalButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AssessmentCard;
