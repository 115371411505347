import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  backBarWrapper: {
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: "100",
    backgroundColor: colors.veryLightGray
  },
  backBar: {
    position: "relative",
    height: "0.25rem"
  },
  bar: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    bottom: "0",
    transition: "transform 0.2s linear",
    transformOrigin: "left"
  },
  subBar1: props => ({
    zIndex: "101",
    backgroundColor: props.val1Color ? `${props.val1Color}60` : colors.veryLightGreen,
    opacity: "0.75",
  }),
  subBar2: props => ({
    zIndex: "101",
    backgroundColor: props.val2Color ? `${props.val2Color}60` : colors.veryLightBlue,
    opacity: "0.75",
  }),
  bar1: props => ({
    zIndex: "102",
    backgroundColor: props.val1Color ? props.val1Color : colors.green,
  }),
  bar2: props => ({
    zIndex: "102",
    backgroundColor: props.val2Color ? props.val2Color : colors.blue,
  })
});

export default function ThinDualProgress({ val1, subVal1, val1Color, val2, subVal2, val2Color }) {
  const cls = useStyles({ val1Color, val2Color });

  return (
    <Grid container className={cls.backBarWrapper}>
      <Grid container item xs={12} className={cls.backBar}>
        {subVal1 !== null && (
          <Grid
            item
            xs={12}
            className={`${cls.bar} ${cls.subBar1}`}
            style={{ transform: `translateX(-${100 - (parseInt(subVal1) > 0 ? parseInt(subVal1) : -1)}%)` }}
          >
            &nbsp;
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={`${cls.bar} ${cls.bar1}`}
          style={{ transform: `translateX(-${100 - (parseInt(val1) > 0 ? parseInt(val1) : -1)}%)` }}
        >
          &nbsp;
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.backBar}>
        {subVal2 !== null && (
          <Grid
            item
            xs={12}
            className={`${cls.bar} ${cls.subBar2}`}
            style={{ transform: `translateX(-${100 - (parseInt(subVal2) > 0 ? parseInt(subVal2) : -1)}%)` }}
          >
            &nbsp;
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={`${cls.bar} ${cls.bar2}`}
          style={{ transform: `translateX(-${100 - (parseInt(val2) > 0 ? parseInt(val2) : -1)}%)` }}
        >
          &nbsp;
        </Grid>
      </Grid>
    </Grid>
  );
}
