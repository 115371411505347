import React from "react";
import { makeStyles } from "@mui/styles";
import { Blink } from "bild-ui";
import ContentWrapper from "presentational/wrappers/contentWrapper.js";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Tooltip, Popper, List, ListItemButton } from "@mui/material";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0"
  },
  hidden: {
    display: "none"
  },
  warningTooltip: {
    backgroundColor: "#ffc8cb",
    color: "#000",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "20px",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  }
});

function ContextMenu({ open, onClose, onPreviewIntent, onDownloadIntent, onRenameIntent, onMoveIntent, onDeleteIntent, item, anchorEl, x, y }) {
  const classes = useStyles();

  function _close() {
    if (onClose) onClose();
  }

  function _preview() {
    _close();
    if (onPreviewIntent) onPreviewIntent(item);
  }

  function _download() {
    _close();
    if (onDownloadIntent) onDownloadIntent(item);
  }

  function _rename() {
    _close();
    if (onRenameIntent) onRenameIntent(item);
  }

  function _move() {
    _close();
    if (onMoveIntent) onMoveIntent(item);
  }

  function _delete() {
    _close();
    if (onDeleteIntent) onDeleteIntent(item);
  }

  let deleteButton = (
    <ListItemButton dense onClick={_delete}>
      Delete
    </ListItemButton>
  );

  if (item && !item.deletable) {
    deleteButton = (
      <Tooltip
        disableFocusListener
        disableTouchListener
        classes={{ tooltip: classes.warningTooltip }}
        title="This file is attached to a competency proof and can only be deleted after it has been unattached."
      >
        <span>
          <ListItemButton dense onClick={_delete} disabled>
            Delete
          </ListItemButton>
        </span>
      </Tooltip>
    );
  }

  let editButton = "";

  if (item && item.raw && item.raw.inAppLocalId) {
    editButton = (
      <ListItemButton dense component={Blink} dst={"equip-drive"} in_app_local_id={item.raw.inAppLocalId}>
        Edit
      </ListItemButton>
    );
  }

  let previewButton = (
    <ListItemButton dense onClick={_preview}>
      Preview
    </ListItemButton>
  );

  let downloadButton = (
    <ListItemButton dense onClick={_download}>
      Download
    </ListItemButton>
  );

  if (item && item.isFolder || (item && item.raw && item.raw.inAppLocalId)) {
    previewButton = "";
    downloadButton = "";
  }

  return (
    <Popper open={open === true} anchorEl={anchorEl}>
      <ClickAwayListener onClickAway={_close} mouseEvent="onMouseDown">
        <div>
          <ContentWrapper noPadding>
            <List dense>
              {editButton}
              {previewButton}
              {downloadButton}
              <ListItemButton dense onClick={_rename}>
                Rename
              </ListItemButton>
              <ListItemButton dense onClick={_move}>
                Move
              </ListItemButton>
              {deleteButton}
            </List>
          </ContentWrapper>
        </div>
      </ClickAwayListener>
    </Popper>
  );
}

export default ContextMenu;
