import React, { useState, useEffect } from "react";
import { courseData } from "bild-data";
import { makeStyles } from "@mui/styles";
import { colors } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";

import { Grid, Button, Checkbox, Typography } from "@mui/material";
import AfTrainingQuestions from "./afTrainingQuestions.js";
import BTextField from "presentational/input/bTextField.js";

const useStyles = makeStyles({
  trainingSection: {
    border: `3px dashed ${colors.darkGray}`,
    padding: "1rem",
    margin: "1rem 0"
  },
  questionSection: {
    margin: "1rem 0"
  },
  buttonWrapper: {
    padding: "1rem"
  },
  button: {
    width: "calc(100% - 2rem)",
    margin: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    color: "white"
  },
  passButton: {
    backgroundColor: colors.green,
    "&:hover": {
      backgroundColor: colors.darkGreen
    }
  },
  rejectButton: {
    backgroundColor: colors.red,
    "&:hover": {
      backgroundColor: colors.darkRed
    }
  }
});

function AfTraining({
  submitIntent,
  rubric,
  competencyProof,
  trainingAssessment,
  isAfTrainee,
  isAfCoach,
  previousTrainingAssessmentId,
  setPreviousTrainingAssessmentId
}) {
  const classes = useStyles();
  const [answers, setAnswers] = useState([]);
  const [coachComment, setCoachComment] = useState("");
  const [goodExample, setGoodExample] = useState(false);
  const [subLoading, setSubLoading] = useState(true);

  useEffect(() => {
    // Only try to set the training assessment if it has not been set
    if (!previousTrainingAssessmentId) {
      function _setPreviousTrainingAssessmentId(data) {
        if (data.trainingAssessment) {
          setPreviousTrainingAssessmentId(data.trainingAssessment.id);
        }
      }
      // Only make this call once per page load
      if (subLoading) {
        setSubLoading(!subLoading);
        courseData.loadCurrentTraineeAssessment(competencyProof.id, _setPreviousTrainingAssessmentId);
      }
    }
  }, [competencyProof, subLoading, setSubLoading, previousTrainingAssessmentId, setPreviousTrainingAssessmentId]);

  useEffect(() => {
    // Load traineeQuestions from API
    if (isAfTrainee) {
      courseData.loadAfTraineeQuestions(x => {
        const questions = x.traineeQuestions;
        setAnswers(
          questions.map(q => {
            return { id: q.id, description: q.description, required: q.required, response: "" };
          })
        );
      });
    } else {
      if (trainingAssessment) {
        setAnswers(JSON.parse(trainingAssessment.traineeQuestionsData));
      }
    }
  }, [isAfTrainee, trainingAssessment]);

  function _submitTrainee() {
    if (answers.some(x => x.required === true && x.response === "")) {
      toaster.error("Please answer all required questions.");
    } else {
      submitIntent({
        traineeQuestionsData: JSON.stringify(answers)
      });
    }
  }

  function _submitCoach(status) {
    if (coachComment === "") {
      toaster.error("Please leave a comment for the trainee.");
    } else {
      submitIntent({
        trainingAssessmentId: trainingAssessment.id,
        coachHasAccepted: status,
        coachComment: coachComment,
        goodExample: goodExample
      });
    }
  }

  return (
    <Grid container className={classes.trainingSection}>
      {isAfCoach && (
        <Grid item xs={12}>
          <BTextField
            title="Trainee Comment For Student:"
            readOnly={true}
            value={trainingAssessment ? trainingAssessment.assessments[0].comment : ""}
          />
        </Grid>
      )}
      <Grid item xs={12} className={classes.questionSection}>
        <AfTrainingQuestions answers={answers} setAnswers={setAnswers} readOnly={isAfCoach} />
      </Grid>
      {isAfCoach && (
        <>
          <Grid item xs={12}>
            <BTextField title="Reviewer Comment:" onChange={setCoachComment} required={true} />
          </Grid>
          <Grid item xs={12}>
            <label>
              <Checkbox
                onChange={() => {
                  setGoodExample(!goodExample);
                }}
                checked={goodExample}
              />
              <Typography variant="h6" display="inline">
                Flag as exemplary review of competency
              </Typography>
            </label>
          </Grid>
        </>
      )}
      <Grid container item xs={12} className={classes.buttonWrapper}>
        {isAfTrainee && (
          <Grid item xs={12}>
            <Button onClick={_submitTrainee} className={`${classes.button} ${classes.passButton}`}>
              Submit Assessment
            </Button>
          </Grid>
        )}
        {isAfCoach && (
          <>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  _submitCoach(true);
                }}
                className={`${classes.button} ${classes.passButton}`}
              >
                Confirm Assessment
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  _submitCoach(false);
                }}
                className={`${classes.button} ${classes.rejectButton}`}
              >
                Revision Needed
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default AfTraining;
