import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { reviewData, userData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { BaseWrapper, LoadingScreen } from "bild-ui";
import TrainingAssessment from "./components/trainingAssessment.js";

function TrainingAssessmentLoader({}) {
  const { training_assessment_id } = useParams();
  const [error, setError] = useState(null);
  const [trainingCompetency, setTrainingCompetency] = useState(null);
  const [callingUser, setCallingUser] = useState(null);

  useEffect(() => {
    setPageTitle("Training Assessment Review");
    reviewData.loadAfTrainingAssessment(training_assessment_id, setTrainingCompetency, e => {
      setError(e.response.data.error);
    });
    userData.loadOverview(
      o => setCallingUser(o.user),
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  if (!trainingCompetency || !callingUser || error) return <LoadingScreen error={error} />;
  return (
    <BaseWrapper>
      <TrainingAssessment trainingCompetency={trainingCompetency} callingUser={callingUser} />
    </BaseWrapper>
  );
}

export default TrainingAssessmentLoader;
