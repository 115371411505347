import React from "react";
import BTextField from "presentational/input/bTextField.js";

function QuestionField({ answer, setAnswer, readOnly }) {
  function _setAnswer(value) {
    answer.response = value;
    setAnswer(answer);
  }

  return <BTextField title={answer.description} value={answer.response} required={answer.required} onChange={_setAnswer} readOnly={readOnly} />;
}

export default QuestionField;
