import React from "react";
import { colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  tile: {
    width: "100%",
    height: "100%",
    border: `1px solid ${colors.lightGray}`,
    borderRadius: "4px",
    "&:hover": { cursor: "pointer", background: colors.lightGray }
  },
  segment: { padding: "0.5rem", textAlign: "center" },
  subText: { textOverflow: "ellipsis", width: "100%", display: "block", overflowWrap: "anywhere" }
});

function Tile({ initial, subText, action }) {
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12} className={classes.tile} justifyContent="center" alignItems="stretch">
        <Grid container item xs={12} onClick={action} className={classes.segment} justifyContent="center" alignItems="flex-end">
          <Grid item>{initial}</Grid>
        </Grid>
        <Grid container item xs={12} className={`${classes.segment} ${classes.subText}`}>
          {subText}
        </Grid>
      </Grid>
    </>
  );
}

export default Tile;
