import React from "react";
import { Blink, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";

import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  chip: { margin: "0.17rem 0.25rem", color: `${colors.black} !important` },
  chipAvatar: { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgrey" },
  chipIcon: { width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },

  attachChip: {
    margin: "0.17rem 0.25rem",
    borderStyle: "dashed",
    borderColor: "#168463",
    "&:hover": {
      backgroundColor: "#E3F1EE !important",
      borderStyle: "solid"
    }
  },
  attachAvatar: { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#168463" },
  attachIcon: { width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#FFF" },
  attachLabel: { color: "#168463" },

  dragChip: { margin: "0.17rem 0.25rem", border: "none" },
  dragIcon: { width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
  dragAvatar: { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgrey" },

  displayChip: { margin: "0.17rem 0.25rem", border: "none" },
  displayIcon: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.4rem",
    color: "#76C4FF"
  },
  displayAvatar: { display: "flex", alignItems: "center", justifyContent: "center", background: "none" },
  type: { color: `${colors.black} !important` },
  confirmChip: { margin: "0.17rem 0.25rem", borderColor: "#006DC3" },
  confirmAvatar: { display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "lightgrey" },
  confirmIcon: { width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }
});

function FolioDisplayItem({ name, type, item, variant, onClick, onDelete, link, className, avatarContent }) {
  const classes = useStyles();

  function _fileIconClass() {
    if (type === "folder" || (item && item.isFolder)) {
      return "fas fa-folder";
    } else {
      return "fas fa-file";
    }
  }

  function _name() {
    return name || (item && item.name) || "";
  }

  let iconClass, avatarClass, chipClass, label;
  switch (variant) {
    case "drag":
      [iconClass, avatarClass, chipClass, label] = [
        `${_fileIconClass()} ${classes.dragIcon}`,
        classes.dragAvatar,
        classes.dragChip,
        <Typography variant="body1" className={classes.type}>
          {_name()}
        </Typography>
      ];
      break;
    case "display":
      [iconClass, avatarClass, chipClass, label] = [
        `${_fileIconClass()} ${classes.displayIcon}`,
        classes.displayAvatar,
        classes.displayChip,
        <Typography variant="body1" className={classes.type}>
          {_name()}
        </Typography>
      ];
      break;
    case "attach-artifact":
      [iconClass, avatarClass, chipClass, label] = [
        `fal fa-paperclip ${classes.attachIcon}`,
        classes.attachAvatar,
        classes.attachChip,
        <Typography variant="body2" className={classes.attachLabel}>
          {_name()}
        </Typography>
      ];
      break;
    case "download-artifact":
      [avatarClass, chipClass, avatarContent, label] = [
        classes.chipAvatar,
        classes.chip,
        <i className={`${_fileIconClass()} ${classes.chipIcon}`} />,
        <Typography variant="body2" className={classes.type}>
          {_name()}
        </Typography>
      ];
      break;
    case "confirm":
      [iconClass, avatarClass, chipClass, label] = [
        `${_fileIconClass()} ${classes.chipIcon}`,
        classes.confirmAvatar,
        classes.confirmChip,
        <Typography variant="body2" className={classes.type}>
          {_name()}
        </Typography>
      ];
      break;
    default:
      [iconClass, avatarClass, chipClass, label] = [
        `${_fileIconClass()} ${classes.chipIcon}`,
        classes.chipAvatar,
        classes.chip,
        <Typography variant="body2" className={classes.type}>
          {_name()}
        </Typography>
      ];
      break;
  }

  let avatar = <Avatar className={avatarClass}>{avatarContent ? avatarContent : <i className={iconClass} />}</Avatar>;

  return (
    <Chip
      avatar={avatar}
      label={label}
      onDelete={onDelete}
      onClick={onClick}
      clickable={onClick ? true : false}
      variant="outlined"
      className={chipClass}
    />
  );
}

export default FolioDisplayItem;
