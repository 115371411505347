import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import { Grid, Dialog } from "@mui/material";
import { colors, Blink, BTypography, NormalButton, FormTextField, UserAvatar, PathProgress, FormAutocompleteField } from "bild-ui";
import AllUserCertificates from "modules/certificates/userCertificates/allUserCertificates.js";
import { UserPathThumbnail } from "equip";

const useStyles = makeStyles({
  headWrapper: {
    padding: "0.5rem 1rem"
  },
  head: {
    padding: "1rem",
    backgroundColor: colors.white
  },
  name: {
    fontWeight: "700"
  },
  pathsWrapper: {
    padding: "0.5rem 1rem"
  },
  paths: {
    backgroundColor: colors.white
  },
  pathWrapper: {
  },
  removableWrapper: {
    border: `1px solid ${colors.lightGray}`
  },
  shepherdChangeButton: {
    padding: "0.5rem 0 0 0.5rem",
  },
  refreshButton: {
    padding: "0.5rem 0 0 0.5rem",
  },
  removeButton: {
    padding: "0.5rem 1rem 0 0.5rem",
  },
  shepherdWrapper: {
    padding: "1rem 0"
  },
  shepherdArrow: {
    padding: "0 1rem"
  },
  path: {
    borderRadius: "4px",
    padding: `1rem`,
    backgroundColor: colors.white
  },
  pathTitle: {
    lineHeight: "2rem",
    fontWeight: "bold",
    paddingBottom: "1rem",
    "& sup": {
      lineHeight: "1rem"
    }
  },
  shepherdAvatar: { padding: "0 0.25rem 0 0.5rem" },
  progress: { padding: "0.5rem 1rem 0 0" },
  certificates: { padding: "2rem" },
  icon: {
    paddingRight: "0.5rem"
  },
  confirmDialog: { padding: "1rem" },
  confirmButton: { margin: "0.5rem 0", padding: "1rem 2rem" }
});

function UserAdminEquip({ user, userPaths, allUsers, updateUserPathShepherd, refreshUserPath, removeUserPath, certificates, removeCert }) {
  const cls = useStyles();
  const [confirmUserPath, setConfirmUserPath] = useState({});
  const [confirmShepherd, setConfirmShepherd] = useState({});

  return (
    <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
      <Grid item xs={12}>
        <BTypography variant="h5">Programs</BTypography>
      </Grid>
      <Grid container item xs={12} className={cls.pathsWrapper}>
        <Grid container item xs={12} className={cls.paths}>
          {userPaths.map(x => {
            let icon = "fal fa-badge-check";
            let color = x.programColor.webFrontendValue;
            let removable = x && !x.completed;
            return (
              <Grid container item sm={6} xs={12} className={`${cls.pathWrapper} ${removable ? cls.removableWrapper : ""}`} key={x.id}>
                <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-end">
                  {removable && (
                    <>
                      <Grid item className={cls.shepherdChangeButton}>
                        <NormalButton
                          component={Grid}
                          item
                          variant="primary"
                          color={colors.blue}
                          labelColor={colors.white}
                          hoverColor={colors.darkBlue}
                          onClick={() => {
                            setConfirmShepherd({ userPathId: x.id, name: x.title, color: color, icon: icon, oldShepherdUser: x.shepherdUser, shepherdUserId: null });
                          }}
                        >
                          <span className="fad fa-clipboard-user" /> &nbsp; Change Shepherd
                        </NormalButton>
                      </Grid>
                      {!x.active && (
                        <Grid item className={cls.refreshButton}>
                          <NormalButton
                            component={Grid}
                            item
                            variant="primary"
                            color={colors.darkGreen}
                            labelColor={colors.white}
                            hoverColor={colors.darkerGreen}
                            onClick={() => {
                              refreshUserPath(x.id);
                            }}
                          >
                            <span className="fad fa-sync-alt" /> &nbsp; Refresh
                          </NormalButton>
                        </Grid>
                      )}
                      <Grid item className={cls.removeButton}>
                        <NormalButton
                          component={Grid}
                          item
                          variant="primary"
                          color={colors.darkRed}
                          labelColor={colors.white}
                          hoverColor={colors.darkerRed}
                          onClick={() => {
                            setConfirmUserPath({ id: x.id, name: x.title, color: color, icon: icon });
                          }}
                        >
                          <span className="fas fa-times" /> &nbsp; Remove
                        </NormalButton>
                      </Grid>
                    </>
                  )}
                </Grid>
                <UserPathThumbnail path={x} color={color} showFull/>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BTypography variant="h5">Certificates</BTypography>
      </Grid>
      <Grid container item xs={12} className={cls.certificates}>
        <AllUserCertificates certificates={certificates} hideHeader={true} removeCert={removeCert} />
      </Grid>
      <Dialog
        open={confirmUserPath.id ? true : false}
        onClose={() => {
          setConfirmUserPath({});
        }}
      >
        <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
          <Grid item>
            <BTypography variant="h6" className={cls.pathTitle} style={{ color: confirmUserPath.color }}>
              <i className={`fal ${confirmUserPath.icon} ${cls.icon}`} />
              {ReactHtmlParser(confirmUserPath.name)}
            </BTypography>
          </Grid>
          <Grid item>
            <NormalButton
              component={Grid}
              item
              variant="primary"
              color={colors.darkRed}
              labelColor={colors.white}
              hoverColor={colors.darkerRed}
              className={cls.confirmButton}
              onClick={() => {
                removeUserPath(confirmUserPath.id);
                setConfirmUserPath({});
              }}
            >
              Confirm Removal
            </NormalButton>
          </Grid>
          <Grid item>
            <NormalButton
              component={Grid}
              item
              variant="secondary"
              color={colors.white}
              labelColor={colors.gray}
              borderColor={colors.gray}
              hoverColor={colors.darkGray}
              className={cls.confirmButton}
              onClick={() => {
                setConfirmUserPath({});
              }}
            >
              Cancel
            </NormalButton>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={confirmShepherd.userPathId ? true : false}
        onClose={() => {
          setConfirmShepherd({});
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
          <Grid item>
            <BTypography variant="h6" className={cls.pathTitle} style={{ color: confirmShepherd.color }}>
              <i className={`fal ${confirmShepherd.icon} ${cls.icon}`} />
              {ReactHtmlParser(confirmShepherd.name)}
            </BTypography>
          </Grid>
          <Grid container item xs={12} direction="row" justifyContent={"space-around"} alignItems={"center"} className={cls.shepherdWrapper}>
            <Grid item>
              <UserAvatar size={30} src={confirmShepherd.oldShepherdUser && confirmShepherd.oldShepherdUser.avatarURL} name={confirmShepherd.oldShepherdUser && confirmShepherd.oldShepherdUser.name} nameVariant="h3" badgeSrc={confirmShepherd.oldShepherdUser && confirmShepherd.oldShepherdUser.badgeURL} />
            </Grid>
            <Grid item className={cls.shepherdArrow}>
              <i className="fal fa-arrow-right fa-2x" />
            </Grid>
            <Grid item xs>
              <FormAutocompleteField value={confirmShepherd.shepherdUserId} setValue={(x)=>{setConfirmShepherd({...confirmShepherd, shepherdUserId: x})}} items={allUsers} showAvatar={true} />
            </Grid>
          </Grid>
          <Grid item>
            <NormalButton
              component={Grid}
              item
              variant="primary"
              color={colors.bildBlue}
              labelColor={colors.white}
              hoverColor={colors.darkBildBlue}
              className={cls.confirmButton}
              onClick={() => {
                updateUserPathShepherd(confirmShepherd.userPathId, confirmShepherd.shepherdUserId);
                setConfirmShepherd({});
              }}
              disabled={!confirmShepherd.shepherdUserId}
            >
              Confirm Change Shepherd
            </NormalButton>
          </Grid>
          <Grid item>
            <NormalButton
              component={Grid}
              item
              variant="secondary"
              color={colors.white}
              labelColor={colors.gray}
              borderColor={colors.gray}
              hoverColor={colors.darkGray}
              className={cls.confirmButton}
              onClick={() => {
                setConfirmShepherd({});
              }}
            >
              Cancel
            </NormalButton>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
}

export default UserAdminEquip;
