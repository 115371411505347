import { putRequest } from "bild-data/core/comm.js";

export function createSupportTicket(data, success_cb, error_cb) {
  putRequest(
    `/help/support_ticket`,
    data,
    data => {
      if (typeof success_cb === "function") success_cb(data);
    },
    null,
    e => {
      if (typeof error_cb === "function") error_cb(e);
    }
  );
}
