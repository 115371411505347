import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { colors, BTypography } from "bild-ui";
import { MessagesDialogLoader, PreviewFileDialog } from "modules";
import { makeStyles, createStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import UserLearningItemSegment from "../user-learning-item/components/userLearningItemSegment.js";
import AttachArtifactDropdown from "equip/artifact/attachArtifactDropdown.js";
import ArtifactWorkflow from "equip/artifact/artifactWorkflow.js";
import AssessmentDropdown from "equip/assessment/components/assessmentDropdown.js";
import Assessment from "equip/assessment/assessment.js";
import CheckmarkCompletion from "./components/checkmarkCompletion.js";
import AssociatedResource from "./components/associatedResource.js";
import CompetencyMessagePicker from "./components/competencyMessagePicker.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: { paddingBottom: "2rem" },
    col1: {
      paddingRight: "1rem",
      [theme.breakpoints.down('md')]: {
        paddingRight: "0"
      }
    },
    col2: {},
    block: { marginTop: "1rem", backgroundColor: colors.white },
    segment: { width: "100%" },
    "@keyframes fadeIn": { from: { opacity: 0 }, to: { opacity: 1 } },
    artifacts: { padding: "1rem 0" },
    artifact: { animationName: "$fadeIn", animationDuration: "1s", animationTimingFunction: "linear" },
    attachArtifact: { paddingTop: "1rem" },
    checkoff: { paddingTop: "1rem" }
  })
);

function Competency({
  userPathItemId,
  userLearningItem,
  changeLevel,
  completed,
  editable,
  createDocument,
  attach,
  remove,
  user,
  docId,
  setDocId,
  fileLoading,
  submitScore,
  markCompleted,
  color
}) {
  const cls = useStyles();
  const [messages, setMessages] = useState(false);
  const [channelUsers, setChannelUsers] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const hasRubric = userLearningItem.rubric;

  function _submitCompleted() {
    markCompleted();
  }

  function _openMessage(users) {
    setChannelUsers(users);
    setMessages(true);
  }

  const addFileButton = (
    <AttachArtifactDropdown user={user} viewDocument={setDocId} createDocument={createDocument} attach={attach} color={color} editable={editable} />
  );

  const changeLevelButton = <AssessmentDropdown user={user} userLearningItem={userLearningItem} editable={editable} changeLevel={changeLevel} />;

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item md={9} sm={12} className={cls.col1}>
        <Grid container item xs={12} className={cls.block}>
          <Grid item xs={12} className={cls.segment}>
            <UserLearningItemSegment icon="fal fa-hammer fa-2x" title="Project" color={color} rightButton={addFileButton}>
              <>
                <BTypography variant="body1">{ReactHtmlParser(userLearningItem.project)}</BTypography>
                <Grid container item xs={12} className={cls.artifacts}>
                  <ArtifactWorkflow
                    userLearningItemId={userLearningItem.id}
                    artifacts={userLearningItem.files}
                    docId={docId}
                    setDocId={setDocId}
                    previewFile={null}
                    setPreviewFile={setPreviewFile}
                    remove={remove}
                    editable={editable}
                    fileLoading={fileLoading}
                  />
                </Grid>
                <Grid container item xs={12} className={`${cls.segment} ${cls.assessment}`} justifyContent="center" alignItems="center">
                  {!hasRubric && (
                    <Grid item xs={12} className={cls.checkoff}>
                      <CheckmarkCompletion completed={completed} submitCompleted={_submitCompleted} editable={editable} />
                    </Grid>
                  )}
                </Grid>
              </>
            </UserLearningItemSegment>
            {hasRubric && (
              <UserLearningItemSegment icon="fal fa-check-circle fa-2x" title="Assessment" color={color} rightButton={changeLevelButton}>
                <Assessment
                  userLearningItemId={userLearningItem.id}
                  history={userLearningItem.competency && userLearningItem.competency.assessments ? userLearningItem.competency.assessments : []}
                  artifacts={userLearningItem.files}
                  docId={docId}
                  rubric={userLearningItem.rubric}
                  setDocId={setDocId}
                  submitScore={submitScore}
                />
              </UserLearningItemSegment>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item md={3} sm={12} className={cls.col2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid container item xs={12} direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} className={cls.block}>
            <AssociatedResource userLearningItem={userLearningItem} color={color} />
          </Grid>
          <Grid item xs={12} className={cls.block}>
            <UserLearningItemSegment icon="fal fa-comments-alt fa-2x" title="Discussion" color={color}>
              <CompetencyMessagePicker userLearningItem={userLearningItem} openMessage={_openMessage} />
            </UserLearningItemSegment>
          </Grid>
        </Grid>
      </Grid>
      <MessagesDialogLoader
          open={messages}
          onClose={() => {
            setMessages(false);
          }}
          openMessageUsers={channelUsers}
          anchors={[{ userLearningItemId: userLearningItem.id, userPathItemId: userPathItemId, name: userLearningItem.name }]}
        />
      <PreviewFileDialog
        currentUser={user}
        previewFile={previewFile}
        fileLoading={fileLoading}
        setPreviewFile={setPreviewFile}
      />
    </Grid>
  );
}

export default Competency;
