import React, { useState, useRef, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { access } from "bild-utils";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { Grid, Collapse, Typography, Divider } from "@mui/material";

import ArtifactsFlow from "./artifactsFlow/artifactsFlow.js";
import AssessmentInfo from "./assessmentInfo/assessmentInfo.js";
import Bbutton from "presentational/input/bbutton.js";
import Rubric from "./rubric/rubric.js";
import AssessmentHistoryFlowButton from "./assessmentHistoryFlow/assessmentHistoryFlowButton.js";
import Comment from "./comments/comment.js";
import CommentField from "./comments/commentField.js";
import CreditManager from "./creditManager/creditManager";
import SubmitFlowButton from "./submitFlow/submitFlowButton.js";
import AfTrainingHistoryQuestions from "./afTraining/afTrainingHistoryQuestions.js";

import makeDecisions from "./logic/decisions.js";
import { PopTip, colors } from "bild-ui";
import { PreviewFileDialog } from "modules/index.js";

const useStyles = makeStyles({
  container: { marginBottom: "1rem", borderRadius: "0 0 10px 10px" },
  upperContainer: {
    padding: "1rem",
    borderTop: "1px solid lightgrey",
    borderLeft: "1px solid lightgrey",
    borderRight: "1px solid lightgrey",
    borderRadius: "10px 10px 0 0"
  },
  descriptionContainer: { paddingRight: "1rem" },
  instructionsContainer: { paddingBottom: "1rem" },
  middleContainer: {
    height: "100%",
    padding: "0 1rem",
    borderLeft: "1px solid lightgrey",
    borderRight: "1px solid lightgrey"
  },
  bottomContainer: {
    padding: "0 1rem",
    borderBottom: "1px solid lightgrey",
    borderLeft: "1px solid lightgrey",
    borderRight: "1px solid lightgrey",
    borderRadius: "0 0 10px 10px",
    height: "2.5rem",
    color: "white",
    cursor: "pointer"
  },
  readOnlyBottomContainer: {
    padding: "none",
    cursor: "unset",
    height: "1rem"
  },
  divider: {
    marginBottom: "1rem"
  },
  commentTitle: { marginTop: "1.5rem" },
  commentBottomDivider: { marginBottom: "1.5rem", marginBlockEnd: "1px" },
  submitButton: {
    backgroundColor: colors.green,
    width: "calc(100% - 2rem)",
    margin: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    color: "white",
    "&:hover": {
      backgroundColor: colors.green
    }
  },
  previousTrainingButton: { marginBottom: "1rem", backgroundColor: colors.red, "& > span": { color: colors.white } }
});

function prepareCompetencyData(preData, setTrainingAssessment) {
  let data;
  if (preData.competencyWithCourse) {
    data = preData.competencyWithCourse;
    if (typeof setTrainingAssessment === "function") setTrainingAssessment(preData.trainingAssessment);
  } else {
    data = preData;
  }
  const {
    name,
    description,
    instructions,
    shortInstructions,
    rubric,
    id,
    additionalResources,
    competencyProof,
    assessmentFlowIcons,

    assessmentFlowId,
    proof
  } = {
    ...(data || {}),
    assessmentFlowId: access(data, "assessmentFlow.id"),
    proof: {
      id: access(data, "competencyProof.id"),
      files: access(data, "competencyProof.files") || [],
      status: access(data, "competencyProof.status"),
      assessments: access(data, "competencyProof.assessments") || [],
      showCreditsGiven: !!access(data, "competencyProof.showCreditsGiven"),
      creditsGiven: access(data, "competencyProof.creditsGiven"),
      stCanModifyFiles: !!access(data, "competencyProof.stCanModifyFiles"),
      canAssignCredits: !!access(data, "competencyProof.canAssignCredits"),
      variableMinCredits: access(data, "competencyProof.variableMinCredits"),
      variableMaxCredits: access(data, "competencyProof.variableMaxCredits"),
      waitingOn: { ...access(data, "competencyProof.waitingOn") }
    }
  };

  return {
    name,
    description,
    instructions,
    shortInstructions,
    rubric,
    id,
    additionalResources,
    competencyProof,
    assessmentFlowIcons,

    assessmentFlowId,
    proof
  };
}

function Competency({
  course,
  competency: propsCompetency,
  enrollmentId,
  studentId,
  trainingAssessment,
  setTrainingAssessment,
  trainingCriteria,
  callingUser,
  docId,
  setDocId,
  fileLoading,
  userRoles,
  scrollTo,
  auditView
}) {
  const classes = useStyles();

  const [competency, _internalSetCompetency] = useState(prepareCompetencyData(propsCompetency, setTrainingAssessment));
  const setCompetency = c => _internalSetCompetency(prepareCompetencyData(c, setTrainingAssessment));

  const compId = (propsCompetency || {}).id;
  useEffect(() => setCompetency(propsCompetency), [compId]);

  const { name, description, instructions, shortInstructions, rubric, id, assessmentFlowId, assessmentFlowIcons, additionalResources, proof } = competency || {};
  const { color, assessmentLevelId } = course || {};

  const [expanded, setExpanded] = useState(false || scrollTo);

  const [score, setScore] = useState({
    title: "Not Enough Criteria Selected",
    message: "You have not made selections on the required number of criteria.",
    hintMessage: "To complete assessment, selections must be made for all Required Criteria plus the required number of Additional Critera.",
    caption: "Please make all needed selections and resubmit."
  });

  const [credits, setCredits] = useState(null);
  const [showCreditsHint, setShowCreditsHint] = useState(false);

  const commentRef = useRef(null);
  const commentAssessments = proof.assessments.filter(a => a.comment);

  const [previousTrainingAssessmentId, setPreviousTrainingAssessmentId] = useState(null);

  const [previewFile, setPreviewFile] = useState(null);

  // Automatic scroll to and open
  const containerRef = useRef();
  useEffect(() => {
    if (scrollTo && containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();
      document.getElementById("root").scrollTo({
        top: top - 60, // account for top nav height
        left: 0,
        behavior: "smooth"
      });
      containerRef.current.style = "box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;";
    }
  }, []); // eslint-disable-line

  const {
    expandable,
    expandToggleText,
    expandToggleColor,
    readOnly,
    canUpdateArtifacts,
    hasFiles,
    canAssess,
    isStudent,
    showTrainingHistory,
    showFullTrainingHistory,
    waitingOnTrainingRole,
    isAfTrainee,
    isAfCoach,
    isAssociateFaculty,
    creditsInRange,
    creditsValid,
    assignsCredits,
    showCredits
  } = makeDecisions(userRoles, course, competency, expanded, credits, score, trainingAssessment, auditView);

  return (
    <div className={classes.container} ref={containerRef}>
      <Grid container className={classes.upperContainer}>
        {canAssess && isAfTrainee && previousTrainingAssessmentId && (
          <Grid container item xs={12} justifyContent="flex-end">
            <Grid item>
              <Bbutton
                variant="big"
                dst={"training_assessment"}
                training_assessment_id={previousTrainingAssessmentId}
                className={classes.previousTrainingButton}
                target="_blank"
              >
                View Related Coach Review
              </Bbutton>
            </Grid>
          </Grid>
        )}
        <Grid item lg={9} md={8} sm={7} xs={12} className={classes.descriptionContainer}>
          <Typography variant="body2" gutterBottom>
            {shortInstructions ? ReactHtmlParser(shortInstructions) : ReactHtmlParser(description)}
            {shortInstructions && (
              <>
                &nbsp;
                <PopTip text={ReactHtmlParser(description)}>
                  <i className="fal fa-info-circle"/>
                </PopTip>
              </>
            )}
          </Typography>

          <ArtifactsFlow
            groupColor={color}
            competencyId={id}
            artifacts={proof.files}
            canUpdateArtifacts={canUpdateArtifacts}
            callingUserId={callingUser.id}
            onCompetencyChange={c => {
              setCompetency(c);
              // Make sure state is unexpanded if user removes artifacts.
              if (!hasFiles) setExpanded(false);
            }}
            viewDocument={setDocId}
            viewPreview={setPreviewFile}
          />
        </Grid>

        <Grid item lg={3} md={4} sm={5} xs={12}>
          <AssessmentInfo
            competency={competency}
            additionalResources={additionalResources}
            instructions={instructions}
            rubric={rubric}
            courseId={course.id}
            competencyId={id}
            competencyProofId={competency.proof.id}
            enrollmentId={enrollmentId ? enrollmentId : course.enrollmentId}
            proofStatus={proof.status}
            assessmentFlowId={assessmentFlowId}
            assessmentFlowIcons={assessmentFlowIcons}
            assessmentLevelId={assessmentLevelId}
            callingUserId={callingUser.id}
            studentId={studentId}
            lmUser={course.localMentorUser}
            afUser={course.associateFacultyUser}
            groupColor={color}
          />
        </Grid>
      </Grid>

      {expandable && (
        <Collapse in={expanded}>
          <div className={classes.middleContainer}>
            <Divider className={classes.divider} />
            <Rubric
              rubric={rubric}
              competencyId={`new-${id}`}
              onChange={setScore}
              submissionCount={0}
              assessmentLevel={assessmentLevelId}
              groupColor={color}
              userCanAssess={canAssess}
              userIsStudent={isStudent}
              showTrainingHistory={showTrainingHistory}
              userIsAssociateFaculty={isAssociateFaculty}
              assessmentFlowId={assessmentFlowId}
              waitingOnTrainingRole={waitingOnTrainingRole}
              trainingCriteria={trainingCriteria}
            />

            <CreditManager
              {...proof}
              assignsCredits={assignsCredits}
              showCredits={showCredits}
              credits={credits}
              creditsValid={creditsValid}
              showCreditsHint={showCreditsHint}
              onChange={e => setCredits(e.target.value)}
              groupColor={color}
              trainingAssessment={trainingAssessment}
            />

            {canAssess && !isAfCoach && <CommentField groupColor={color} commentRef={commentRef} />}

            {proof.assessments.length > 0 && (
              <AssessmentHistoryFlowButton
                assessments={proof.assessments}
                competencyName={name}
                viewDocument={setDocId}
                viewPreview={setPreviewFile}
              />
            )}

            {commentAssessments.length > 0 && (
              <>
                <Typography variant="h6" style={{ color: color }} className={classes.commentTitle} gutterBottom>
                  Comments
                </Typography>
                {commentAssessments.map((a, i) => (
                  <Comment {...a} key={i} />
                ))}
                <Divider className={classes.commentBottomDivider} />
              </>
            )}

            {canAssess && (
              <SubmitFlowButton
                isStudent={isStudent}
                canAssess={canAssess}
                assignsCredits={assignsCredits}
                creditsInRange={creditsInRange}
                onShowCreditsHint={() => setShowCreditsHint(true)}
                callingUser={callingUser}
                competency={competency}
                score={score}
                commentRef={commentRef}
                credits={credits}
                onCompetencyChange={setCompetency}
                rubric={rubric}
                trainingAssessment={trainingAssessment}
                waitingOnTrainingRole={waitingOnTrainingRole}
                isAfTrainee={isAfTrainee}
                isAfCoach={isAfCoach}
                previousTrainingAssessmentId={previousTrainingAssessmentId}
                setPreviousTrainingAssessmentId={setPreviousTrainingAssessmentId}
              />
            )}

            {!canAssess && showTrainingHistory && trainingAssessment && (
              <AfTrainingHistoryQuestions trainingAssessment={trainingAssessment} showFullTrainingHistory={showFullTrainingHistory} />
            )}
          </div>
        </Collapse>
      )}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={clsx(classes.bottomContainer, { [classes.readOnlyBottomContainer]: readOnly })}
        style={{ backgroundColor: expandToggleColor, cursor: expandable ? "" : "unset" }}
        component={Typography}
        variant="subtitle1"
        onClick={() => (expandable ? setExpanded(!expanded) : null)}
      >
        {!readOnly && expandToggleText}
      </Grid>

      <PreviewFileDialog
        currentUser={competency.competencyProof && competency.competencyProof.user}
        docId={docId}
        setDocId={setDocId}
        fileLoading={fileLoading}
        previewFile={previewFile}
        setPreviewFile={setPreviewFile}
      />
    </div>
  );
}

export default Competency;
