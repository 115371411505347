import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import { Container, Divider, Typography } from "@mui/material";

import { CommentEditor, Comment } from "modules";
import { localStorage as ls } from "bild-data";

const useStyles = makeStyles({
  container: { padding: "5rem 1rem" },
  div: { margin: "2rem -0.75rem", backgroundColor: "black" }
});

function getComments() {
  return ls.getItem("bild-comments") || [];
}

function saveComment(value) {
  const createdAt = new Date().getTime();
  const id = `bild-comment-${createdAt}`;
  const commentObj = {
    value,
    name: "Christopher Smith",
    createdAt,
    id
  };

  const comments = getComments();
  comments.push(commentObj);
  ls.setItem("bild-comments", comments);

  return getComments();
}

function deleteComment(id) {
  const comments = getComments();
  const filteredComments = comments.filter(c => c.id !== id);
  ls.setItem("bild-comments", filteredComments);

  return getComments();
}

function CommentsDemo() {
  const classes = useStyles();
  const [comments, setComments] = useState(getComments());

  const _saveComment = value => setComments(saveComment(value));
  const _deleteComment = id => setComments(deleteComment(id));

  return (
    <Container maxWidth="sm" className={classes.container}>
      <CommentEditor onSend={_saveComment} />
      <Divider className={classes.div} />
      <Typography variant="h5" gutterBottom>
        Comments
      </Typography>
      {comments.map((c, i) => (
        <Comment {...c} onDeleteIntent={_deleteComment} key={i}></Comment>
      ))}
    </Container>
  );
}

export default CommentsDemo;
