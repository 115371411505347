import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { Editable, Slate } from "slate-react";
import { Transforms, Text, Editor } from "slate";

import { Grid } from "@mui/material";

import { CommentToolbar, Leaf, Element, constants, useCommentEditor } from ".";

const useStyles = makeStyles({
  container: { minHeight: "5rem", width: "100%", borderRadius: "4px", border: "1px solid darkgrey", backgroundColor: "white" },
  editableContainer: { padding: "0.75rem", maxHeight: "8rem" },
  editable: { maxHeight: "7rem", overflowY: "auto" }
});

function AssessmentCommentEditor({ value, setValue, setReady }) {
  const classes = useStyles();
  const editor = useCommentEditor();

  useEffect(() => {
    // Need to initialize on pageLoad and if the value is cleared in parent
    if (value === null || value === "") {
      if (editor) {
        // Loop through all children and delete them
        editor.children.map(item => {
          Transforms.delete(editor, { at: [0] })
        })
        // Reset children to initialValue
        editor.children = constants.initialValue;
        // Need to reset the selector to the very start, otherwise it used to blow up
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 }
        });
        // Reset the value so we can use it for verifying blank messages are not sent
        setValue(constants.initialValue);
      }
    } else {
      // Scan all paragraphs to see how many "real" characters are present
      let realCharCount = 0;
      for (let i=0; i<value.length; i++) {
        let v1 = value[i] ? value[i].children : [];
        for (let j=0; j<v1.length; j++) {
          let v2 = v1[j];
          realCharCount += v2.text ? v2.text.trim().length : 0;
        }
      }

      // Only set as ready if there is at least one real character (no spaces/enter/etc)
      if ((JSON.stringify(value) === JSON.stringify(constants.initialValue)) || (realCharCount === 0)) {
        setReady(false);
      } else {
        setReady(true);
      }
    }
  }, [value]);

  const onKeyDown = (event, editor) => {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    // key shortcuts
    switch (event.key) {
      // bold
      case "b": {
        event.preventDefault();
        toggleMark(editor, "bold");
        break;
      }
      // italics
      case "i": {
        event.preventDefault();
        toggleMark(editor, "italic");
        break;
      }
      // underline
      case "u": {
        event.preventDefault();
        toggleMark(editor, "underline");
        break;
      }
    }
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} s />, []);
  if (value) {
    return (
      <Slate editor={editor} value={value} onChange={newValue => setValue(newValue)}>
        <Grid container className={classes.container} direction="column" wrap="nowrap">
          <Grid item xs className={classes.editableContainer}>
            <Editable
              id="bild-assessment-comment-editor"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              className={`${classes.editable} notranslate`}
              onKeyDown={e => {
                onKeyDown(e, editor);
              }}
            />
          </Grid>
          <Grid item>
            <CommentToolbar marks={constants.marks} blocks={constants.blocks} />
          </Grid>
        </Grid>
      </Slate>
    );
  } else {
    return <></>;
  }
}

export default AssessmentCommentEditor;
