import React from "react";
import { makeStyles } from "@mui/styles";
import { colors, Chip } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  container: {}
});

function ChipList({ members, icon, spacing }) {
  const cls = useStyles({});
  let space = spacing ? spacing : 4;

  return (
    <Grid container alignItems="center" justifyContent="flex-start" className={cls.container}>
      {members.map((m, i) => (
        <Grid item xs={space} key={i}>
          <Chip member={m} icon={icon} />
        </Grid>
      ))}
    </Grid>
  );
}

export default ChipList;
