import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { colors, Legend, ZoomButtons } from "bild-ui";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  subItem: {
    padding: "0.5rem 0.25rem",
    minWidth: "15rem"
  }
});

function OrgGroupsFooter({ legend, level3Name, level2Name, level1Name, scalable, scaleLevel, setScaleLevel, showLevels }) {
  const cls = useStyles();
  const [legendKeys, setLegendKeys] = useState([]);

  useEffect(()=>{
    let lk = [];
    if (level3Name && showLevels.includes(3)) {
      lk.push({ name: level3Name, color: colors.level3Group });
    }
    if (level2Name && showLevels.includes(2)) {
      lk.push({ name: level2Name, color: colors.level2Group });
    }
    if (level1Name && showLevels.includes(1)) {
      lk.push({ name: level1Name, color: colors.level1Group });
    }
    setLegendKeys(lk);
  },[level3Name, level2Name, level1Name, showLevels])

  return (
    <Grid container item alignItems="stretch" justifyContent="flex-start">
      {legend && (
        <>
          <Grid item className={cls.subItem}>
            <Legend
              title="Group Types"
              keys={legendKeys}
            />
          </Grid>
          <Grid item className={cls.subItem}>
            <Legend
              title="People With Programs"
              keys={[
                { name: "1-25", color: colors.black, icon: "bfont bf-fire-1" },
                { name: "25-100", color: colors.black, icon: "bfont bf-fire-2" },
                { name: "100-500", color: colors.black, icon: "bfont bf-fire-3" },
                { name: "500+", color: colors.black, icon: "bfont bf-fire-4" }
              ]}
            />
          </Grid>
          <Grid item className={cls.subItem}>
            <Legend
              title="People With Progress"
              keys={[
                { name: "0-25", color: "#48a7ff", icon: "fal fa-circle" },
                { name: "26-100", color: "#fbc512", icon: "fal fa-circle" },
                { name: "100-500", color: "#f18000", icon: "fal fa-circle" },
                { name: "500+", color: "#f32535", icon: "fal fa-circle" }
              ]}
            />
          </Grid>
        </>
      )}
      {scalable && (
        <Grid container item xs justifyContent="flex-end">
          <Grid item className={cls.subItem}>
            <ZoomButtons scale={scaleLevel} setScale={setScaleLevel} max={5} min={0} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default OrgGroupsFooter;
