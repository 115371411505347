import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import { Paper, Typography, Button, Popover, Popper, ClickAwayListener, Input, Select } from "@mui/material";

const useStyles = makeStyles({
  descriptionText: { maxWidth: "400px", padding: "1rem" },
  paper: { minWidth: "300px", marginTop: "5px", padding: "20px", textAlign: "left", border: "2px solid #d2d2d2" },
  infoButton: {
    padding: "0px",
    minWidth: "12px",
    minHeight: "12px",
    paddingBottom: "3px",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: "0.7"
    }
  },
  infoIcon: { paddingLeft: "5px" },
  competencySets: { width: "100%", border: "2px solid #d2d2d2", padding: "5px", marginTop: "5px", borderRadius: "5px" }
});

function DesriptionPopover({ open, anchorEl, onClose, classes }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "center", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "right" }}
    >
      <Typography className={classes.descriptionText} variant="body2">
        Competency Sets are specific groups of competencies that are associated with a particular course. Students must complete each competency in a
        group to demonstrate competency for a course.
      </Typography>
      <Typography className={classes.descriptionText} variant="body2">
        Competency Sets are included in each version of the Antioch School Handbook. Students wishing to switch competency sets for a specific course
        may do so.
      </Typography>
    </Popover>
  );
}

function SwitchCompetencySetPopper({ open, anchor, onClose, groupColor, competencySetId, competencySets, onSwitchIntent }) {
  const classes = useStyles();
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  // TODO: Antioch FE 2.0.3.1 -- This should be coming from the availableCompetencySets API
  let options = [];
  if (competencySets)
    options = competencySets.map((cs, i) => (
      <option value={cs.id} key={i}>
        {cs.handbookName}
      </option>
    ));

  return (
    <Popper id="competency-set-popper" anchorEl={anchor} placement="bottom-end" open={open}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1">
            Competency Set
            <Button onClick={e => setPopoverAnchor(e.currentTarget)} className={classes.infoButton}>
              <i className={`fal fa-info-circle ${classes.infoIcon}`} style={{ color: groupColor }} />
            </Button>
          </Typography>

          <Select
            native
            value={competencySetId}
            className={classes.competencySets}
            onChange={e => {
              if (onSwitchIntent) onSwitchIntent(e.target.value);
            }}
            input={<Input name="competency-set" disableUnderline={true} />}
          >
            {options}
          </Select>

          <DesriptionPopover open={!!popoverAnchor} anchorEl={popoverAnchor} onClose={() => setPopoverAnchor(null)} classes={classes} />
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

export default SwitchCompetencySetPopper;
