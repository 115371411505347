import React from "react";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import LoadingScreen from "presentational/screens/loadingScreen.js";

function LoadingDialog({ open, closeDialog, classes, ...props }) {
  return (
    <Dialog open={open} onClose={closeDialog} fullScreen style={{ opacity: ".5" }}>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item xs={12}>
            <LoadingScreen />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingDialog;
