import React from "react";
import { Grid } from "@mui/material";

import "./fireworks.css";

function Fireworks({ children }) {
  return (
    <>
      <div className="fireworks-wrapper">
        <div className="firework-start" />
        <div className="firework-end" />
      </div>
      {children}
    </>
  );
}

export default Fireworks;
