import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, CircularProgress } from "@mui/material";
import { PageErrorScreen, PageUnauthorizedScreen, colors, BTypography } from "bild-ui";

const useStyles = makeStyles({
  loadingScreen: { height: "100%", padding: "2rem" },
  title: { color: colors.bildBlue, paddingTop: "2rem", textAlign: "center" }
});

function LoadingScreen({ error, title, size }) {
  const cls = useStyles();
  const defaultSize = size ? size : 100;
  if (error) {
    switch (error.errorCode) {
      case "401":
        return <PageUnauthorizedScreen error={error} />;
      default:
        return <PageErrorScreen error={error} />;
    }
  } else {
    return (
      <Grid container className={cls.loadingScreen} justifyContent="center" alignItems="center">
        <Grid container item justifyContent="center" alignItems="center" xs={12}>
          <Grid item>
            <CircularProgress size={defaultSize} />
          </Grid>
          {title && (
            <Grid item xs={12}>
              <BTypography variant="h3" className={cls.title}>
                {title}
              </BTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default LoadingScreen;
