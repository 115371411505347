/* ENUMS used by the BackEnd (com.bild.api.visual.*) and FrontEnd */
const ENUMS = {
  ROLES: {
    LOCAL_MENTOR: {
      ID: 1,
      NAME: "Local Mentor",
      PREFIX: "LM"
    },
    ASSOCIATE_FACULTY: {
      ID: 5,
      NAME: "Associate Faculty",
      PREFIX: "AF"
    },
    STUDENT: {
      ID: 10,
      NAME: "Student",
      PREFIX: "ST"
    },
    STUDENT_LOCAL_MENTOR: {
      ID: 11,
      NAME: "Student/Local Mentor",
      PREFIX: "STLM"
    },
    CL_MAX: {
      ID: 12,
      NAME: "All Student Local Mentor",
      PREFIX: "LMM"
    },
    SEE_ALL_STUDENTS: {
      ID: 13,
      NAME: "See All Students",
      PREFIX: "SAS"
    },
    MASQUERADER: {
      ID: 14,
      NAME: "User Masquerader",
      PREFIX: "MASQUERADE"
    },
    ADMIN_SEARCHER: {
      ID: 16,
      NAME: "Admin Searcher",
      PREFIX: "AS_SEARCH"
    },
    COMMENDER: {
      ID: 15,
      NAME: "AS Student Commender",
      PREFIX: "COMMENDER"
    },
    SIMA_STAFF: {
      ID: 17,
      NAME: "SIMA Staff",
      PREFIX: "AS"
    },
    SIMA_REVIEWER: {
      ID: 18,
      NAME: "SIMA Story Reviewer",
      PREFIX: "ASR"
    },
    ADMIN: {
      ID: 19,
      NAME: "Admin",
      PREFIX: "ADMIN"
    },
    VIEW_ONLY: {
      ID: 20,
      NAME: "View Only",
      PREFIX: "VIEW_ONLY"
    },
    AF_COACH: {
      ID: 21,
      NAME: "Associate Faculty Coach",
      PREFIX: "AFC"
    },
    AF_TRAINEE: {
      ID: 22,
      NAME: "Associate Faculty Trainee",
      PREFIX: "AFT"
    },
    MENTOR: {
      ID: 23,
      NAME: "Mentor",
      PREFIX: "MENTOR"
    },
    RTM: {
      ID: 24,
      NAME: "Resource Team Member",
      PREFIX: "RTM"
    },
    STAFF: {
      ID: 25,
      NAME: "Staff",
      PREFIX: "STAFF"
    },
    FUNDRAISER: {
      ID: 26,
      NAME: "Fundraiser",
      PREFIX: "FUND"
    },
    API_USER: {
      ID: 27,
      NAME: "API User",
      PREFIX: "API_USER"
    },
    BETA_TESTER: {
      ID: 28,
      NAME: "BETA Tester",
      PREFIX: "BETA"
    },
    INVITATIONAL_PROGRAM_STAFF: {
      ID: 29,
      NAME: "Invitational Program Staff",
      PREFIX: "INV_PROG_STAFF"
    },
    MCCEE_NETWORK_LEADER: {
      ID: 30,
      NAME: "MCCEE Network Leader",
      PREFIX: "MCCEE_NET_LEAD"
    },
    MCCEE_COHORT_PARTICIPANT: {
      ID: 31,
      NAME: "MCCEE Cohort Participant",
      PREFIX: "MCCEE_COH_PART"
    },
    ADMIN_DEMO: {
      ID: 32,
      NAME: "Admin Demo User",
      PREFIX: "ADMIN_DEMO"
    }
  },
  EQUIP_ROLES: {
    USER: {
      ID: 1,
      NAME: "User",
      ACCESS_RESTRICTED: true,
      SELF_REVIEW: true,
      ADDITIONAL_REVIEW: false,
      VALIDATION_REVIEW: false,
      IMPLICIT: true,
      EXPLICIT: false
    },
    ADMIN: {
      ID: 2,
      NAME: "Admin",
      ACCESS_RESTRICTED: true,
      SELF_REVIEW: false,
      ADDITIONAL_REVIEW: true,
      VALIDATION_REVIEW: true,
      IMPLICIT: true,
      EXPLICIT: true
    },
    LEADER: {
      ID: 3,
      NAME: "Leader",
      ACCESS_RESTRICTED: true,
      SELF_REVIEW: false,
      ADDITIONAL_REVIEW: true,
      VALIDATION_REVIEW: true,
      IMPLICIT: true,
      EXPLICIT: true
    },
    MENTOR: {
      ID: 4,
      NAME: "Mentor",
      ACCESS_RESTRICTED: true,
      SELF_REVIEW: false,
      ADDITIONAL_REVIEW: true,
      VALIDATION_REVIEW: false,
      IMPLICIT: false,
      EXPLICIT: true
    },
    TEACHER: {
      ID: 5,
      NAME: "Teacher",
      ACCESS_RESTRICTED: false,
      SELF_REVIEW: false,
      ADDITIONAL_REVIEW: false,
      VALIDATION_REVIEW: true,
      IMPLICIT: false,
      EXPLICIT: true
    },
    SHARE_GROUP_USER: {
      ID: 6,
      NAME: "Share Group User",
      ACCESS_RESTRICTED: false,
      SELF_REVIEW: false,
      ADDITIONAL_REVIEW: false,
      VALIDATION_REVIEW: false,
      IMPLICIT: false,
      EXPLICIT: false
    }
  },
  ASSESSMENT: {
    FLOWS: {
      ST_PATH: {
        ID: 1,
        DESCRIPTION: "Student then Local Mentor then Associate Faculty"
      },
      STCL_PATH: {
        ID: 2,
        DESCRIPTION: "Student then Associate Faculty"
      },
      AS_STAFF: {
        ID: 3,
        DESCRIPTION: "Antioch School Staff Mark Complete"
      },
      AS_STORY: {
        ID: 4,
        DESCRIPTION: "Antioch School Staff reviews Stories"
      }
    },
    STATUSES: {
      ST_WAIT: {
        STATUS: "st_wait",
        DESCRIPTION: "Waiting on Student"
      },
      CL_WAIT: {
        STATUS: "cl_wait",
        DESCRIPTION: "Waiting on Local Mentor"
      },
      AF_WAIT: {
        STATUS: "af_wait",
        DESCRIPTION: "Waiting on Associate Faculty"
      },
      STCL_WAIT: {
        STATUS: "stcl_wait",
        DESCRIPTION: "Waiting on Student-Local Mentor"
      },
      STCL_REASSESS: {
        STATUS: "stcl_reassess",
        DESCRIPTION: "Waiting on Student-Local Mentor to reassess"
      },
      ST_REASSESS: {
        STATUS: "st_reassess",
        DESCRIPTION: "Waiting on Student to reassess"
      },
      CL_REASSESS: {
        STATUS: "cl_reassess",
        DESCRIPTION: "Waiting on Local Mentor to reassess"
      },
      AF_REASSESS: {
        STATUS: "af_reassess",
        DESCRIPTION: "Waiting on Associate Faculty to reassess"
      },
      COMPLETE: {
        STATUS: "complete",
        DESCRIPTION: "Complete"
      },
      SIMA_STORY_WAIT: {
        STATUS: "sima_story_wait",
        DESCRIPTION: "Waiting on Antioch School"
      },
      SIMA_STORY_REASSESS: {
        STATUS: "sima_story_reassess",
        DESCRIPTION: "Waiting on Antioch School to reassess"
      },
      SIMA_STAFF_WAIT: {
        STATUS: "sima_staff_wait",
        DESCRIPTION: "Waiting on Antioch School"
      },
      AF_COACH_WAIT: {
        STATUS: "af_coach_wait",
        DESCRIPTION: "Waiting on Associate Faculty"
      },
      AF_COACH_REASSESS: {
        STATUS: "af_coach_reassess",
        DESCRIPTION: "Waiting on Associate Faculty to reassess"
      }
    }
  },
  COMPLETION_LEVELS: {
    BASIC: {
      ID: 1,
      NAME: "Foundations",
      VALUE: 1
    },
    ADVANCED: {
      ID: 2,
      NAME: "Leading Others",
      VALUE: 3
    },
    MASTERY: {
      ID: 3,
      NAME: "Entrusting Leaders",
      VALUE: 5
    }
  },
  LOCAL_ENTITY_TYPES: {
    BRT: {
      ID: 1,
      NAME: "BILD Resource Team"
    },
    GRT: {
      ID: 2,
      NAME: "GRT"
    },
    PRT: {
      ID: 3,
      NAME: "PRT"
    },
    NETWORK_LEVEL_3: {
      ID: 4,
      NAME: "Network of Networks"
    },
    NETWORK_LEVEL_2: {
      ID: 5,
      NAME: "Network"
    },
    NETWORK_LEVEL_1: {
      ID: 6,
      NAME: "Local Network"
    },
    LOCAL_ORGANIZATION: {
      ID: 7,
      NAME: "Local Organization"
    }
  },
  NETWORK_PARTNERS: {
    STATUSES: {
      GREEN: {
        ID: 1,
        NAME: "GREEN"
      },
      YELLOW: {
        ID: 2,
        NAME: "YELLOW"
      },
      RED: {
        ID: 3,
        NAME: "RED"
      }
    },
  }
};

Object.freeze(ENUMS);

export default ENUMS;
