import React, { useState } from "react";
import { downloadRequest } from "bild-data/core/comm";
import { makeStyles } from "@mui/styles";
import { Grid, Badge } from "@mui/material";
import { colors, UserAvatar, BTypography, NormalButton, PopTip, SupportDialog } from "bild-ui";

const useStyles = makeStyles({
  container: { padding: "1rem" },
  body: { padding: "1rem", background: colors.white },
  heading: { paddingLeft: "0.5rem" },
  section: { padding: "1rem 0" },
  certificates: { padding: "2rem 1rem 0 1rem" },
  certificate: { padding: "1rem 0" },
  completed: { padding: "0 1rem" },
  icon: { padding: "0 1rem 0 0", color: colors.red },
  emptyMessage: { padding: "1rem", textAlign: "center", fontStyle: "italic" }
});

function UserCertificates({ user, certificates }) {
  const cls = useStyles();
  const [supportCertName, setSupportCertName] = useState(null);

  function getCertificates() {
    let cs = certificates.map(x => (
      <Grid container item xs={12} className={cls.certificate} justifyContent="center" alignItems="center">
        <Grid item className={cls.icon}>
          <i className="fal fa-file-pdf fa-3x" />
        </Grid>
        <Grid item xs>
          <BTypography variant="h5">{x.name}</BTypography>
        </Grid>
        <Grid item className={cls.completed}>
          <BTypography variant="body1">
            Completed on <b>{x.completedOn}</b>
          </BTypography>
        </Grid>
        <Grid item>
          {x.processed && !x.processingError && (
            <Badge invisible={x.userNotified} color={"secondary"} badgeContent={<b>NEW</b>}>
              <NormalButton
                onClick={() => {
                  downloadRequest(x.downloadURL, x.name);
                }}
                variant="primary"
                color={colors.bildBlue}
                labelColor={colors.white}
              >
                Download
              </NormalButton>
            </Badge>
          )}
          {x.processed && x.processingError && (
                <PopTip text="There was an error processing this certificate. Please contact support.">
                  <NormalButton
                    variant="primary"
                    color={colors.darkRed}
                    hoverColor={colors.darkerRed}
                    labelColor={colors.white}
                    onClick={()=>{setSupportCertName(x.name)}}
                  >
                    <i className="far fa-exclamation-triangle" />
                  </NormalButton>
                </PopTip>
              )}
          {!x.processed && (
            <BTypography variant="body1">
              Download link will <br /> be available soon.
            </BTypography>
          )}
        </Grid>
      </Grid>
    ));

    if (cs.length < 1) {
      cs = (
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item className={cls.emptyMessage}>
            <BTypography variant="h6">No Completed Certificates</BTypography>
          </Grid>
        </Grid>
      );
    }

    return cs;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={cls.container}>
      <Grid container item xs={12} className={cls.body}>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
          <Grid item>
            <UserAvatar size={75} name={user.name + " Certificates"} src={user.avatarURL} badgeSrc={user.badgeURL} nameVariant="h3" />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={cls.certificates}>
          {getCertificates()}
        </Grid>
      </Grid>
      <SupportDialog
        open={supportCertName}
        closeDialog={() => {
          setSupportCertName(null);
        }}
        options={[`I am experiencing a problem with a certificate: ${supportCertName}.`]}
      />
    </Grid>
  );
}

export default UserCertificates;
